// @flow

import React from 'react';
import MultiSelect, {
  MultiValueLabel,
  Menu,
  Option,
  type ValueType
} from './MultiSelectInput';
import { components } from 'react-select';
import injectSheet from '../../style/injectSheet';
import { type ThemeType, type StylesheetClasses } from '../../style/ThemeTypes';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const Style = (theme: ThemeType) => {
  return {
    sortingHelper: {
      // forces cursor to remain in pointer while dragging over text inputs
      pointerEvents: 'auto !important',
      zIndex: theme.zIndex.dragging
    },
    individualElement: { cursor: 'pointer' }
  };
};

const SortableMultiValue = injectSheet(Style)(
  SortableElement(props => {
    const onMouseDown = e => {
      // prevents the menu from opening/closing when selecting a value to drag
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { onMouseDown };
    return (
      <div>
        <components.MultiValue
          className={props.classes.individualElement}
          innerProps={innerProps}
          {...props}
        />
      </div>
    );
  })
);

export function arrayMove(
  array: ValueType,
  from: number,
  to: number
): Object[] {
  array = array || [];
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiSelect = SortableContainer(MultiSelect);

export type SortableMultiSelectInputProps = {
  onSortEnd: (...args: Array<ValueType>) => mixed,
  onChange?: (
    {
      currentTarget: {
        id?: string,
        label: Array<string>,
        name?: string,
        value: ValueType
      }
    },
    ?ValueType,
    ?ValueType
  ) => mixed,
  individualElement: string,
  classes: StylesheetClasses,
  defaultValue?: ValueType,
  value: ValueType
};
type State = {
  value: ValueType
};

export class EnhancedSortableMultiSelect extends React.Component<
  SortableMultiSelectInputProps,
  State
> {
  constructor(props: Object) {
    super(props);

    const defaultProp: ValueType = this.props.defaultValue
      ? this.props.defaultValue
      : [];
    if (Array.isArray(defaultProp)) {
      const filteredArray = defaultProp.filter(function(el) {
        return el.value != null;
      });
      this.state = {
        value: filteredArray
      };
    } else {
      this.state = {
        value: defaultProp
      };
    }
  }

  handleSortEnd = ({
    oldIndex,
    newIndex
  }: {
    oldIndex: number,
    newIndex: number
  }) => {
    const { onChange } = this.props;
    const newValue = arrayMove(this.state.value, oldIndex, newIndex);
    this.setState({ value: newValue });
    if (typeof onChange === 'function') {
      onChange(
        {
          currentTarget: {
            label: newValue.map(label => label.label),
            value: newValue.map(value => value.value)
          }
        },
        newValue,
        this.props.defaultValue
      );
    }
  };

  handleChange = (
    targetValues: {
      currentTarget: {
        id?: string,
        label: Array<string>,
        name?: string,
        value: ValueType
      }
    },
    valueProp: ValueType,
    defaultValue: ValueType
  ) => {
    this.setState({ value: valueProp });
    if (this.props.onChange) {
      this.props.onChange(targetValues, valueProp, defaultValue);
    }
  };

  render() {
    const { onChange, classes, onSortEnd, ...props } = this.props;
    return (
      <SortableMultiSelect
        className={classes.individualElement}
        onChange={this.handleChange}
        axis="xy"
        onSortEnd={this.handleSortEnd}
        distance={4}
        helperClass={classes.sortingHelper}
        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        components={{
          MultiValue: SortableMultiValue,
          MultiValueLabel,
          Menu,
          Option
        }}
        value={this.state.value}
        {...props}
      />
    );
  }
}
EnhancedSortableMultiSelect.displayName = 'SortableMultiSelect';

export default injectSheet(Style)(EnhancedSortableMultiSelect);
