// @flow

import * as React from 'react';
import userIconUrl from '@catalytic/catalytic-icons/lib/icons/user.svg';
import { Avatar } from '@catalytic/catalytic-ui';

type Props = {|
  id: string,
  image?: string,
  title: string
|};

const UserAvatar = ({ id, image, title }: Props) => (
  <Avatar circular id={id} image={image || userIconUrl} title={title} />
);
UserAvatar.displayName = 'UserAvatar';

export default UserAvatar;
