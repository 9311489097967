// @flow

const getFieldValue = (values?: any, type?: string): any => {
  let value = values;

  // Handle array type field value
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return null;
    }

    if (value.length === 1) {
      // For fields using MultiSelectInput that have no value, react-select will insert
      // an input with value '', we're setting that to null here (no field value), otherwise
      // we'll end up saving the field value as [''] causing us to render an empty option
      // as the field value.
      // Reference:
      //   - https://github.com/catalyticlabs/triage/issues/6639
      //   - https://github.com/catalyticlabs/qa/issues/165
      if (type === 'ARRAY' && value[0] === '') {
        return null;
      }

      if (type !== 'ARRAY') {
        value = value[0];
      }
    }
  }

  // Handle boolean, integer, number, and object type field values
  if (
    typeof value === 'string' &&
    (type === 'BOOLEAN' ||
      type === 'INTEGER' ||
      type === 'NUMBER' ||
      type === 'OBJECT')
  ) {
    try {
      value = JSON.parse(value);
    } catch (e) {}
  }

  return value;
};

export default getFieldValue;
