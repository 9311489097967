// @flow

import classNames from 'classnames';
import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';

const Style = (theme: ThemeType) => ({
  root: {
    margin: `${theme.functions.toRem(theme.variables.lineMargin)} 0`,
    border: 0,
    height: 0,
    borderBottom: `1px solid ${theme.colors.lightGrey}`
  }
});

type Props = InjectSheetProvidedProps &
  HTMLHRElement & {
    className?: string
  };

const Line = ({ classes, className, theme, ...props }: Props): Node => (
  <hr className={classNames(classes.root, className)} {...props} />
);

Line.displayName = 'Line';
export default injectSheet(Style)(Line);
