// @flow

import * as React from 'react';
import { useIntl } from 'react-intl';
import { Prompt as PromptComponent } from 'react-router-dom';

type Props = {|
  when?: boolean
|};

const Prompt = ({ when }: Props) => {
  const intl = useIntl();

  return (
    <PromptComponent
      when={when}
      message={intl.formatMessage({
        id: 'prompt',
        defaultMessage:
          'Your last action is still processing. Are you sure you want to go to another page?'
      })}
    />
  );
};

export default Prompt;
