// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';

import {
  injectSheet,
  type InjectSheetProvidedProps,
  ListItem,
  ListItemIcon,
  ListItemHeader
} from '@catalytic/catalytic-ui';
import Search from './SearchTypeIcon';
import { type Type } from './SearchTypes';

const Style = () => ({
  root: {
    alignItems: 'center',
    background: 'transparent',
    padding: '.75rem 0',
    textAlign: 'left',
    width: '100%'
  },
  header: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

export type SearchCategoryListItemProps = {
  children: Node,
  type?: Type
};

export type Props = InjectSheetProvidedProps & SearchCategoryListItemProps;

const SearchCategoryListItem = ({
  children,
  classes,
  className,
  theme,
  type,
  ...props
}: Props): Node => (
  <ListItem
    {...props}
    className={classNames(classes.root, className)}
    component="button"
  >
    <ListItemIcon>
      <Search className={classes.icon} type={((type: any): Type)} />
    </ListItemIcon>
    <ListItemHeader className={classes.header}>{children}</ListItemHeader>
  </ListItem>
);

SearchCategoryListItem.displayName = 'SearchCategoryListItem';

export default injectSheet(Style)(SearchCategoryListItem);
