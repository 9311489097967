// @flow

import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import { ExtraSmallText } from '../../Text/Text';
import { PinGlyph } from '@catalytic/catalytic-icons/lib/glyphs';
import Grid from '../../Grid/Grid.js';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';

const Style = (theme: ThemeType) => ({
  name: {
    backgroundColor: theme.colors.lightGrey,
    borderRadius: theme.variables.borderRadiusSmall,
    color: theme.colors.pebbleGrey
  },
  description: {
    color: theme.colors.battleshipGrey,
    display: 'block'
  },
  pin: {
    color: theme.colors.battleshipGrey,
    marginLeft: '.25rem',
    fontSize: '.75rem'
  }
});

type Props = InjectSheetProvidedProps &
  HTMLDivElement & {
    className?: string,
    description?: string,
    isPinned?: boolean,
    menu?: Node,
    name: string
  };

const FieldHandlebar = ({
  classes,
  className,
  description,
  isPinned,
  menu,
  name,
  theme,
  ...props
}: Props): Node => (
  <div className={className} {...props}>
    <Grid container wrap="nowrap" justify="space-between">
      <Grid item>
        <ExtraSmallText className={classes.name}>{name}</ExtraSmallText>
        {isPinned ? <PinGlyph className={classes.pin} /> : null}
      </Grid>
      <Grid item>{menu}</Grid>
    </Grid>
    {description && (
      <ExtraSmallText className={classes.description}>
        {description}
      </ExtraSmallText>
    )}
  </div>
);

FieldHandlebar.displayName = 'FieldHandlebar';
export default injectSheet(Style)(FieldHandlebar);
