// @flow

import React, {
  createRef,
  PureComponent,
  type ComponentType,
  type ElementRef
} from 'react';
import { compose, setDisplayName, withProps } from 'recompose';
import Textarea from 'react-textarea-autosize';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import type {
  ThemeType,
  InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import InputRequired from '../InputRequired/InputRequired';

const DISPLAY_NAME = 'TextareaInput';

const Style = (theme: ThemeType) => {
  return {
    root: {
      position: 'relative'
    },
    input: {
      ...theme.mixins.inputStyle,
      verticalAlign: 'top',
      resize: 'none',
      '&:required:invalid': {
        paddingRight: theme.variables.inputRequiredPaddingRight
      },
      '&:required:valid': {
        ...theme.mixins.inputPadding,
        '& ~ $required': {
          display: 'none'
        }
      }
    },
    displayErrors: {
      '&:valid': theme.mixins.inputSuccess,
      '&:invalid': theme.mixins.inputError
    },
    success: theme.mixins.inputSuccess,
    error: theme.mixins.inputError,
    required: {}
  };
};

export type TextareaInputType = InjectSheetProvidedProps &
  HTMLTextAreaElement & {
    displayErrors: boolean,
    hasError: boolean,
    hasRequired: boolean,
    hasSuccess: boolean,
    hasValidation?: boolean,
    inputClassName?: string,
    inputRef?: ElementRef<*>,
    setDisplayErrorsState?: (displayErrors: boolean) => mixed,
    setLoadingState?: (loading: boolean) => mixed
  };

class TextareaInput extends PureComponent<TextareaInputType> {
  static displayName = DISPLAY_NAME;

  static defaultProps = {
    autoComplete: 'off',
    disabled: false,
    displayErrors: false,
    hasError: false,
    hasRequired: true,
    hasSuccess: false,
    readOnly: false,
    required: false,
    useCacheForDOMMeasurements: true
  };

  render() {
    const {
      classes,
      className,
      disabled,
      displayErrors,
      hasError,
      hasRequired,
      hasSuccess,
      hasValidation,
      inputClassName,
      name,
      readOnly,
      required,
      setDisplayErrorsState,
      setLoadingState,
      theme,
      title,
      ...props
    } = this.props;

    return (
      <div className={classNames(classes.root, className)} title={title}>
        <Textarea
          className={classNames(
            classes.input,
            {
              [classes.displayErrors]: displayErrors,
              [classes.error]: hasError,
              [classes.success]: hasSuccess
            },
            inputClassName
          )}
          data-name={name}
          data-testid={name || 'textarea-input'}
          disabled={disabled || readOnly}
          {...{ name, readOnly, required, ...props }}
        />
        <InputRequired
          className={classes.required}
          {...{ disabled, hasRequired, readOnly, required }}
        />
      </div>
    );
  }
}

// TODO: Type props to EnhancedTextareaInput
type EnhancedProps = Object;

const EnhancedTextareaInput: ComponentType<EnhancedProps> = compose(
  setDisplayName(DISPLAY_NAME),
  injectSheet(Style),
  withProps(({ inputRef }) => ({ inputRef: inputRef || createRef() }))
)(TextareaInput);

export default EnhancedTextareaInput;
