// @flow

import toString from './toString';

const Blob = window.Blob;

if (Blob && !Blob.prototype[toString]) {
  Blob.prototype[toString] = 'Blob';
}

export default Blob;
