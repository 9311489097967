// @flow

import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DateTimeInput,
  InfoBar,
  InfoBarItem,
  InfoBarItemTitle
} from '@catalytic/catalytic-ui';
import debounce from '../utils/debounce';
import { UserGroupQuery } from '../User/UserSelectTypeDefs';
import UserSelect, { toSelectOption } from '../User/UserSelect';
import { makeStyles } from '@material-ui/styles';
import { type Actor } from '../User/ActorTypes';

export const SELECT_DISPLAY_NAME = 'MultiSelectInput';

const useStyles = makeStyles(theme => {
  const placeHolderValues = {
    color: theme.colors.black,
    fontSize: '0.9375rem'
  };
  return {
    snoozeInfo: {
      '&::placeholder': placeHolderValues,
      '&::-webkit-input-placeholder': placeHolderValues,
      '&:-ms-input-placeholder': placeHolderValues,
      '&::-ms-input-placeholder': placeHolderValues,
      '&::-moz-placeholder': placeHolderValues,
      '&:-moz-placeholder': placeHolderValues,
      height: '2rem',
      paddingTop: '0',
      paddingRight: '1rem',
      paddingBottom: '0',
      paddingLeft: '1rem',
      fontSize: '0.9375rem',
      borderRadius: '.25rem'
    },
    infoBar: {
      padding: '0.5rem 0'
    },
    infoBarItem: {
      marginRight: '0.75rem',
      [theme.breakpoints.gte.tablet.portrait]: {
        marginBottom: 0
      }
    },
    input: {
      ...theme.mixins.inputResponsiveDropDown
    }
  };
});

export const Assign = gql`
  mutation Assign($input: AssignTaskInput!) {
    assignTask(input: $input) {
      node {
        id
        assignedTo {
          id
          displayName
        }
      }
    }
  }
`;
export const Snooze = gql`
  mutation Snooze($input: SnoozeTaskInput!) {
    snoozeTask(input: $input) {
      node {
        id
        status
        delayStartUntil
      }
    }
  }
`;
type Props = {
  assignedTo?: Actor,
  deadline?: string,
  delayStartUntil?: string,
  id: string,
  viewerCanSnooze?: boolean,
  viewerCanReassign?: boolean
};
export const TaskMeta = ({
  assignedTo,
  deadline,
  delayStartUntil,
  id,
  viewerCanReassign,
  viewerCanSnooze
}: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const locale = intl?.locale;
  const [dateError, setDateError] = useState(false);
  return (
    <>
      {assignedTo && (
        <InfoBar className={classes.infoBar}>
          <InfoBarItem className={classes.infoBarItem}>
            <InfoBarItemTitle>
              <FormattedMessage id="meta.assigned" defaultMessage="Assigned" />
            </InfoBarItemTitle>
            <Mutation mutation={Assign} key={assignedTo?.id}>
              {assignMutation => (
                <UserSelect
                  className={classes.input}
                  query={UserGroupQuery}
                  disabled={!viewerCanReassign}
                  defaultValue={toSelectOption(assignedTo)}
                  embedded
                  isClearable={false}
                  isMulti={false}
                  menuPlacement="bottom"
                  name="assignee"
                  onChange={actor => {
                    assignMutation({
                      variables: {
                        input: {
                          id: id,
                          username: actor.currentTarget.value[0]
                        }
                      },
                      optimisticResponse: {
                        assignTask: {
                          node: {
                            id: id,
                            assignedTo: {
                              id: actor.currentTarget.value[0],
                              displayName: actor.currentTarget.label[0],
                              __typename: 'Actor'
                            },
                            __typename: 'Task'
                          },
                          __typename: 'AssignTaskPayload'
                        },
                        __typename: 'Mutation'
                      }
                    });
                  }}
                />
              )}
            </Mutation>
          </InfoBarItem>
          <InfoBarItem className={classes.infoBarItem}>
            <InfoBarItemTitle>
              <FormattedMessage id="meta.snooze" defaultMessage="Snooze" />
            </InfoBarItemTitle>
            <Mutation mutation={Snooze}>
              {snoozeTask => {
                const valid = current => {
                  if (!deadline) {
                    return new Date(current) > Date.now();
                  } else {
                    return (
                      new Date(current) < new Date(deadline) &&
                      new Date(current) > Date.now()
                    );
                  }
                };
                return (
                  <DateTimeInput
                    className={classes.input}
                    closeOnSelect
                    dateFormat="L"
                    defaultValue={delayStartUntil}
                    disabled={
                      !viewerCanSnooze ||
                      // Checks if task is due today or past due
                      (deadline
                        ? new Date(deadline).setHours(0, 0, 0, 0) <=
                          new Date().setHours(0, 0, 0, 0)
                        : false)
                    }
                    embedded
                    hasError={dateError}
                    locale={locale}
                    inputClassName={classes.snoozeInfo}
                    isValidDate={valid}
                    timeFormat={false}
                    name="snooze"
                    onChange={e => {
                      if (e.currentTarget.value !== '') {
                        if (!valid(e.currentTarget.value)) {
                          setDateError(true);
                        } else {
                          setDateError(false);
                          debounce(
                            () => {
                              return snoozeTask({
                                variables: {
                                  input: {
                                    id: id,
                                    delay: new Date(
                                      e.currentTarget.value
                                    ).toISOString()
                                  }
                                },
                                optimisticResponse: {
                                  __typename: 'Mutation',
                                  snoozeTask: {
                                    node: {
                                      id: id,
                                      status: 'snoozed',
                                      delayStartUntil: new Date(
                                        e.currentTarget.value
                                      ).toISOString(),
                                      __typename: 'Task'
                                    },
                                    __typename: 'SnoozeTaskPayload'
                                  }
                                }
                              });
                            },
                            { key: `${id}:taskId` }
                          );
                        }
                      }
                    }}
                    placeholder={intl.formatMessage({
                      id: 'meta.placeholder',
                      defaultMessage: 'Select a snooze date'
                    })}
                  />
                );
              }}
            </Mutation>
          </InfoBarItem>
        </InfoBar>
      )}
    </>
  );
};

export default TaskMeta;
