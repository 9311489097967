// @flow

import * as React from 'react';

// Code logic in this file mostly copied from https://github.com/reach/reach-ui/blob/master/packages/utils/src/index.tsx

type Ref = React.Ref<any> | void;

// Passes or assigns an arbitrary value to a ref function or object.
export function assignRef(ref: Ref, value: any) {
  if (ref == null) return;
  if (typeof ref === 'function') {
    ref(value);
  } else if (typeof ref === 'object') {
    ref.current = value;
  }
}

// Passes or assigns a value to multiple refs (typically a DOM node).
export function useForkedRef(...refs: Array<Ref>) {
  return React.useMemo(() => {
    if (refs.every(ref => ref == null)) {
      return null;
    }
    return (node: any) => {
      refs.forEach(ref => {
        assignRef(ref, node);
      });
    };
  }, refs); // eslint-disable-line react-hooks/exhaustive-deps
}
