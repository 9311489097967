// @flow
/* eslint-disable no-use-before-define */

import {
  JSONType,
  type JSONSchema
} from '@catalytic/json-schema-validator-catalytic-web';
import { type View } from '@catalytic/view';
import either from 'ramda/src/either';
import equals from 'ramda/src/equals';
import { type Node, type NodeType } from '../shared/NodeTypes';
import { type Actor } from '../User/ActorTypes';

export type FieldContext = {
  displayName: ?string,
  id: string,
  fields: Array<Field>,
  __typename: NodeType
};
export type FieldEnum = Array<any>;

export const FORMAT = {
  ACTOR: 'ACTOR',
  APP: 'APP',
  CODE: 'CODE',
  CODE_CSS: 'CODE_CSS',
  CODE_HTML: 'CODE_HTML',
  CODE_JAVASCRIPT: 'CODE_JAVASCRIPT',
  CODE_PYTHON: 'CODE_PYTHON',
  CONDITION: 'CONDITION',
  CONTEXT: 'CONTEXT',
  CONTEXT_TYPE: 'CONTEXT_TYPE',
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
  EMAIL: 'EMAIL',
  FILE: 'FILE',
  JSON: 'JSON',
  OBJECT: 'OBJECT',
  PASSWORD: 'PASSWORD',
  TABLE: 'TABLE',
  TIMEZONE: 'TIMEZONE'
};

export type FieldFormat = $Keys<typeof FORMAT>;

export const PREDICTIVE_MODEL_TRAINING_CONFIDENCE = {
  SUSPICIOUSLY_HIGH: 'SUSPICIOUSLY_HIGH',
  VERY_HIGH: 'VERY_HIGH',
  HIGH: 'HIGH',
  MODERATE: 'MODERATE',
  LOW: 'LOW'
};

export type FieldPredictiveModelTrainingConfidence = $Keys<
  typeof PREDICTIVE_MODEL_TRAINING_CONFIDENCE
>;

export const PREDICTIVE_MODEL_TRAINING_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  FAILED: 'FAILED',
  PENDING: 'PENDING'
};

export type FieldPredictiveModelTrainingStatus = $Keys<
  typeof PREDICTIVE_MODEL_TRAINING_STATUS
>;

export const PREDICTIVE_MODEL_TYPE = {
  BINARY: 'BINARY',
  CATEGORICAL: 'CATEGORICAL',
  REGRESSION: 'REGRESSION'
};

export type FieldPredictiveModelType = $Keys<typeof PREDICTIVE_MODEL_TYPE>;

export type FieldPredictiveModelTraining = {
  confidence?: FieldPredictiveModelTrainingConfidence,
  id: string,
  status: FieldPredictiveModelTrainingStatus
};

export type FieldPredictiveModel = {
  displayName?: string,
  id: string,
  training?: FieldPredictiveModelTraining,
  type: FieldPredictiveModelType
};

export const TYPE = {
  ARRAY: 'ARRAY',
  BOOLEAN: 'BOOLEAN',
  INTEGER: 'INTEGER',
  NUMBER: 'NUMBER',
  OBJECT: 'OBJECT',
  STRING: 'STRING'
};

export type FieldType = $Keys<typeof JSONType>;

export type FieldTypeFormat = {
  displayOnly?: boolean,
  format: ?FieldFormat,
  hasContentMediaType?: boolean,
  hasEnum?: boolean,
  type: FieldType
};

export const VISIBILITY_TYPE = Object.freeze({
  PUBLIC: 'PUBLIC',
  INTERNAL: 'INTERNAL',
  CONFIDENTIAL: 'CONFIDENTIAL',
  HIGHLY_CONFIDENTIAL: 'HIGHLY_CONFIDENTIAL'
});

export type FieldVisibilityType = $Values<typeof VISIBILITY_TYPE>;

export const isConfidential = equals(VISIBILITY_TYPE.CONFIDENTIAL);
export const isHighlyConfidential = equals(VISIBILITY_TYPE.HIGHLY_CONFIDENTIAL);
export const allowsUserConfiguration = either(
  isConfidential,
  isHighlyConfidential
);

export type FieldVisibility = {
  type: FieldVisibilityType,
  users: Array<Actor> | null,
  retroactive?: boolean
};

export type Field = {
  __typename: string,
  condition: ?string,
  context: ?Node,
  description: ?string,
  displayName: string,
  displayOnly: boolean,
  enum: ?FieldEnum,
  example: ?string,
  format: ?FieldFormat,
  generated?: boolean,
  id: string,
  isOutputFieldPrefix?: boolean,
  items: ?Field,
  name: string,
  predictiveModel?: ?FieldPredictiveModel,
  readOnly?: boolean,
  reference: ?Field,
  refType?: FieldType,
  required: boolean,
  runtime?: boolean,
  schema?: Object,
  type: FieldType,
  value: ?any,
  viewConfiguration?: View<>,
  viewerRead: boolean,
  visibility?: FieldVisibility
};

export type FieldVisibilityInput = {
  type: FieldVisibilityType,
  users?: ?Array<string>,
  retroactive?: ?boolean
};

export type FieldInput = {
  condition?: string,
  description?: string,
  displayName?: string,
  displayOnly?: boolean,
  enum?: Array<any>,
  example?: string,
  format?: FieldFormat,
  id?: string,
  items?: { enum: Array<any>, type: FieldType },
  name?: string,
  predictiveModel?: ?FieldPredictiveModel,
  readOnly?: boolean,
  reference: ?Field,
  required?: boolean,
  schema?: Object,
  type?: FieldType,
  value?: any,
  viewConfiguration?: View<>,
  viewerRead: boolean,
  visibility?: FieldVisibilityInput
};

export type FieldConfigurationV2Input = {
  condition: ?string,
  id?: string,
  name: ?string,
  predictiveModel: { inputs?: Array<string> },
  schema: JSONSchema<>,
  type?: FieldType,
  visibility: FieldVisibilityInput,
  view: Object
};

export type Inputs = Array<{ id: string, value: any }>;

export type JSONValue =
  | string
  | boolean
  | number
  | null
  | { [key: string]: JSONValue }
  | Array<JSONValue>;
