// @flow

// Using pixels here, since we are dealing with physical pixels
// offered by a screen.
export const devices = {
  mobile: {
    // Based on iPhone XS Max
    landscape: 896,
    portrait: 414
  },
  // Based on iPad
  tablet: {
    landscape: 1024,
    portrait: 768
  }
};

export default {
  tablet: `@media screen and (min-width: ${devices.mobile.landscape}px)`,
  desktop: `@media screen and (min-width: ${devices.tablet.landscape}px)`,
  // Greater than or equal to breakpoints used for queries including and beyond a
  // device profile. For example, in order to apply a query on all devices matching or
  // with a screen greater than mobile landscape one would use
  // `theme.breakpoints.gte.mobile.landscape`.
  gte: {
    mobile: {
      landscape: `@media screen and (min-width: ${devices.mobile.landscape}px)`,
      portrait: `@media screen and (min-width: ${devices.mobile.portrait}px)`
    },
    tablet: {
      landscape: `@media screen and (min-width: ${devices.tablet.landscape}px)`,
      portrait: `@media screen and (min-width: ${devices.tablet.portrait}px)`
    }
  },
  // Greater than breakpoints used for queries one pixel beyond a
  // device profile. For example, in order to apply a query on all
  // devices with a screen greater than mobile landscape one would
  // use. `theme.breakpoints.gt.mobile.landscape`.
  gt: {
    mobile: {
      landscape: `@media screen and (min-width: ${devices.mobile.landscape +
        1}px)`,
      portrait: `@media screen and (min-width: ${devices.mobile.portrait +
        1}px)`
    },
    tablet: {
      landscape: `@media screen and (min-width: ${devices.tablet.landscape +
        1}px)`,
      portrait: `@media screen and (min-width: ${devices.tablet.portrait +
        1}px)`
    }
  },
  // Less than or equal to breakpoints used for queries including and within a
  // device profile. For example, in order to apply a query on all devices matching or
  // with a screen less than mobile landscape one would use
  // `theme.breakpoints.lte.mobile.landscape`.
  lte: {
    mobile: {
      landscape: `@media screen and (max-width: ${devices.mobile.landscape}px)`,
      portrait: `@media screen and (max-width: ${devices.mobile.portrait}px)`
    },
    tablet: {
      landscape: `@media screen and (max-width: ${devices.tablet.landscape}px)`,
      portrait: `@media screen and (max-width: ${devices.tablet.portrait}px)`
    }
  },
  // Less than breakpoints used for queries one pixel within a
  // device profile. For example, in order to apply a query on all
  // devices with a screen less than mobile landscape one would
  // use. `theme.breakpoints.lt.mobile.landscape`.
  lt: {
    mobile: {
      landscape: `@media screen and (max-width: ${devices.mobile.landscape -
        1}px)`,
      portrait: `@media screen and (max-width: ${devices.mobile.portrait -
        1}px)`
    },
    tablet: {
      landscape: `@media screen and (max-width: ${devices.tablet.landscape -
        1}px)`,
      portrait: `@media screen and (max-width: ${devices.tablet.portrait -
        1}px)`
    }
  }
};
