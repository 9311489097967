// @flow

import React, { type ElementType } from 'react';
import classNames from 'classnames';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../style/ThemeTypes';
import injectSheet from '../style/injectSheet';

const Style = (theme: ThemeType) => ({
  root: {
    padding: '1.125rem 1rem',
    '& + &': {
      borderLeft: `1px solid ${theme.colors.lightGrey}`
    }
  },
  alignCenter: {
    textAlign: 'center'
  },
  alignLeft: {
    textAlign: 'left'
  },
  alignRight: {
    textAlign: 'right'
  }
});

type Props = InjectSheetProvidedProps & {
  /**
   * Cell text alignment
   */
  align: string,
  /**
   * Component to be used as code renderer
   */
  component: ElementType
};

function MarkdownTableCell(props: Props) {
  const {
    align,
    classes,
    className: classNameProp,
    component: Component = 'td',
    theme,
    ...other
  } = props;

  const className = classNames(
    classes.root,
    {
      [classes.alignCenter]: align === 'center',
      [classes.alignLeft]: align === 'left',
      [classes.alignRight]: align === 'right'
    },
    classNameProp
  );

  return <Component {...{ ...other, className }} />;
}

MarkdownTableCell.displayName = 'MarkdownTableCell';

export default injectSheet(Style)(MarkdownTableCell);
