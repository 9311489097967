// @flow

import React, { type ElementType } from 'react';
import classNames from 'classnames';
import { Monospace } from '../Text/Text';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../style/ThemeTypes';
import injectSheet from '../style/injectSheet';

const Style = (theme: ThemeType) => ({
  root: {
    ...theme.typography.monospace,
    ...theme.typography.breakWord,
    border: `1px solid ${theme.colors.lightGrey}`,
    borderRadius: theme.variables.borderRadius,
    padding: '1rem',
    margin: '1rem 0'
  }
});

type Props = InjectSheetProvidedProps & {
  /**
   * Component to be used as code renderer
   */
  component: ElementType,
  /**
   * Component to be used as code wrapper
   */
  wrapperComponent: ElementType
};

function MarkdownCode(props: Props) {
  const {
    classes,
    className: classNameProp,
    component: Component,
    theme,
    wrapperComponent: Wrapper,
    ...other
  } = props;
  const className = classNames(classes.root, classNameProp);
  const componentProps = Object.assign({}, other);
  delete componentProps.wrapperComponent;

  return (
    <Wrapper className={className}>
      <Component {...componentProps} />
    </Wrapper>
  );
}

MarkdownCode.displayName = 'MarkdownCode';
MarkdownCode.defaultProps = {
  component: Monospace,
  wrapperComponent: 'pre'
};

export default injectSheet(Style)(MarkdownCode);
