// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';
/* Docs: https://github.com/fkhadra/react-toastify/ */
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';
import injectSheet from '../../style/injectSheet';
import infoFilledCeruleanGlyphUrl from '@catalytic/catalytic-icons/lib/glyphs/info-filled-cerulean.svg';
import successFilledGlyphUrl from '@catalytic/catalytic-icons/lib/glyphs/success-filled.svg';
import warningFilledGlyphUrl from '@catalytic/catalytic-icons/lib/glyphs/warning-filled.svg';
import errorFilledGlyphUrl from '@catalytic/catalytic-icons/lib/glyphs/error-filled.svg';
import { Header4, SmallText } from '../../Text/Text';
import { FormattedMessage } from 'react-intl';

const LIB = 'Toastify__toast';
export const ToastStyle = (theme: ThemeType) => ({
  root: {
    zIndex: theme.zIndex.toast,
    minWidth: '25rem',
    [`& .${LIB}`]: {
      padding: '1rem 1.875rem 1rem 3.16rem',
      color: theme.colors.pebbleGrey,
      borderRadius: theme.variables.borderRadiusSmall,
      borderLeftWidth: '.525rem',
      borderStyle: 'solid',
      backgroundColor: theme.colors.white,
      backgroundPosition: '.75rem 1.25rem',
      backgroundRepeat: 'no-repeat',
      borderColor: theme.colors.cerulean,
      backgroundImage: `url(${infoFilledCeruleanGlyphUrl})`,
      backgroundSize: '1.75rem'
    },
    [`& .${LIB}--error`]: {
      borderColor: theme.colors.dullRed,
      backgroundImage: `url(${errorFilledGlyphUrl})`
    },
    [`& .${LIB}--success`]: {
      borderColor: theme.colors.olive,
      backgroundImage: `url(${successFilledGlyphUrl})`
    },
    [`& .${LIB}--warning`]: {
      borderColor: theme.colors.canary,
      backgroundImage: `url(${warningFilledGlyphUrl})`
    }
  }
});

export const TOAST_TYPE = {
  error: 'error',
  info: 'info',
  success: 'success',
  warn: 'warn'
};

export const TOAST_POSITION = {
  bottomCenter: 'bottom-center',
  bottomLeft: 'bottom-left',
  bottomRight: 'bottom-right',
  topCenter: 'top-center',
  topLeft: 'top-left',
  topRight: 'top-right'
};

const defaultOptions = {
  closeButton: false,
  position: TOAST_POSITION.topRight,
  newestOnTop: true,
  draggablePercent: 15
};

type Props = InjectSheetProvidedProps & {
  children: Node,
  opener?: Node,
  type: 'error' | 'info' | 'success' | 'warn'
};

type ToastMessageProps = {
  title: Node,
  message: Node
};

const ToastMessage = ({ title, message }: ToastMessageProps): Node => (
  <>
    <Header4>{title}</Header4>
    <SmallText>{message}</SmallText>
  </>
);
ToastMessage.displayName = 'ToastMessage';

const notification = (
  type: string,
  message: Node,
  title?: Node,
  options: Object = {}
) => {
  const mergedOptions = { ...defaultOptions, ...options };

  switch (type) {
    case TOAST_TYPE.error:
      return toast.error(
        <ToastMessage
          title={
            title || (
              <FormattedMessage id="toast.title.error" defaultMessage="Error" />
            )
          }
          message={message}
        />,
        mergedOptions
      );
    case TOAST_TYPE.info:
      return toast.info(
        <ToastMessage
          title={
            title || (
              <FormattedMessage id="toast.title.info" defaultMessage="Info" />
            )
          }
          message={message}
        />,
        mergedOptions
      );
    case TOAST_TYPE.success:
      return toast.success(
        <ToastMessage
          title={
            title || (
              <FormattedMessage
                id="toast.title.success"
                defaultMessage="Success"
              />
            )
          }
          message={message}
        />,
        mergedOptions
      );
    case TOAST_TYPE.warn:
      return toast.warn(
        <ToastMessage
          title={
            title || (
              <FormattedMessage
                id="toast.title.warning"
                defaultMessage="Warning"
              />
            )
          }
          message={message}
        />,
        mergedOptions
      );
  }
};

export const Toast = ({
  children,
  classes,
  className,
  opener,
  theme,
  type,
  ...props
}: Props): Node => {
  const hasChildren = !!children;

  return (
    <>
      {opener && (
        <>
          <span
            role="presentation"
            data-testid="opener"
            onClick={() => {
              notification(type, children);
            }}
          >
            {opener}
          </span>
          <ToastContainer
            className={classNames(classes.root, className)}
            {...props}
          />
        </>
      )}
      {!opener && hasChildren && notification(type, children) && (
        <ToastContainer
          className={classNames(classes.root, className)}
          {...props}
        />
      )}
      {!opener && !hasChildren && (
        <ToastContainer
          className={classNames(classes.root, className)}
          {...props}
        />
      )}
    </>
  );
};

Toast.displayName = 'Toast';
Toast.defaultProps = {
  type: TOAST_TYPE.info
};
export default injectSheet(ToastStyle)(Toast);
export const showToast = notification;
export { toast } from 'react-toastify';
