/* eslint-disable prettier/prettier */

export { default as addGlyphUrl, ReactComponent as AddGlyph } from './add.svg';
export { default as attachGlyphUrl, ReactComponent as AttachGlyph } from './attach.svg';
export { default as backGlyphUrl, ReactComponent as BackGlyph } from './back.svg';
export { default as backMobileGlyphUrl, ReactComponent as BackMobileGlyph } from './back-mobile.svg';
export { default as boldGlyphUrl, ReactComponent as BoldGlyph } from './bold.svg';
export { default as calendarGlyphUrl, ReactComponent as CalendarGlyph } from './calendar.svg';
export { default as checkboxCheckedGlyphUrl, ReactComponent as CheckboxCheckedGlyph } from './checkbox-checked.svg';
export { default as checkboxCircleCheckedGlyphUrl, ReactComponent as CheckboxCircleCheckedGlyph } from './checkbox-circle-checked.svg';
export { default as checkboxCircleEmptyGlyphUrl, ReactComponent as CheckboxCircleEmptyGlyph } from './checkbox-circle-empty.svg';
export { default as checkboxCircleProgressGlyphUrl, ReactComponent as CheckboxCircleProgreessGlyph } from './checkbox-circle-progress.svg';
export { default as checkboxEmptyGlyphUrl, ReactComponent as CheckboxEmptyGlyph } from './checkbox-empty.svg';
export { default as checklistGlyphUrl, ReactComponent as ChecklistGlyph } from './checklist.svg';
export { default as chevronGlyphUrl, ReactComponent as ChevronGlyph } from './chevron.svg';
export { default as clearGlyphUrl, ReactComponent as ClearGlyph } from './clear.svg';
export { default as closeGlyphUrl, ReactComponent as CloseGlyph } from './close.svg';
export { default as commentGlyphUrl, ReactComponent as CommentGlyph } from './comment.svg';
export { default as commentsGlyphUrl, ReactComponent as CommentsGlyph } from './comments.svg';
export { default as completeGlyphUrl, ReactComponent as CompleteGlyph } from './complete.svg';
export { default as copyToClipboardGlyphUrl, ReactComponent as CopyToClipboardGlyph } from './copy-to-clipboard.svg';
export { default as directionGlyphUrl, ReactComponent as DirectionGlyph } from './direction.svg';
export { default as downloadGlyphUrl, ReactComponent as DownloadGlyph } from './download.svg';
export { default as dropdownGlyphUrl, ReactComponent as DropdownGlyph } from './dropdown.svg';
export { default as editGlyphUrl, ReactComponent as EditGlyph } from './edit.svg';
export { default as emailGlyphUrl, ReactComponent as EmailGlyph } from './email.svg';
export { default as errorFilledGlyphUrl, ReactComponent as ErrorFilledGlyph } from './error-filled.svg';
export { default as eyeDisabledGlyphUrl, ReactComponent as EyeDisabledGlyph } from './eye-disabled.svg';
export { default as eyeGlyphUrl, ReactComponent as EyeGlyph } from './eye.svg';
export { default as filesGlyphUrl, ReactComponent as FilesGlyph } from './files.svg';
export { default as filterGlyphUrl, ReactComponent as FilterGlyph } from './filter.svg';
export { default as flowchartGlyphUrl, ReactComponent as FlowchartGlyph } from './flowchart.svg';
export { default as gridGlyphUrl, ReactComponent as GridGlyph } from './grid.svg';
export { default as groupGlyphUrl, ReactComponent as GroupGlyph } from './group.svg';
export { default as helpFilledGlyphUrl, ReactComponent as HelpFilledGlyph } from './help-filled.svg';
export { default as helpGlyphUrl, ReactComponent as HelpGlyph } from './help.svg';
export { default as imageGlyphUrl, ReactComponent as ImageGlyph } from './image.svg';
export { default as infoFilledCeruleanGlyphUrl, ReactComponent as InfoFilledCeruleanGlyph } from './info-filled-cerulean.svg';
export { default as infoFilledGlyphUrl, ReactComponent as InfoFilledGlyph } from './info-filled.svg';
export { default as infoGlyphUrl, ReactComponent as InfoGlyph } from './info.svg';
export { default as italicGlyphUrl, ReactComponent as ItalicGlyph } from './italic.svg';
export { default as linkGlyphUrl, ReactComponent as LinkGlyph } from './link.svg';
export { default as listGlyphUrl, ReactComponent as ListGlyph } from './list.svg';
export { default as listOrderedGlyphUrl, ReactComponent as ListOrderedGlyph } from './list-ordered.svg';
export { default as listUnorderedGlyphUrl, ReactComponent as ListUnorderedGlyph } from './list-unordered.svg';
export { default as markdownGlyphUrl, ReactComponent as MarkdownGlyph } from './markdown.svg';
export { default as menuGlyphUrl, ReactComponent as MenuGlyph } from './menu.svg';
export { default as moreGlyphUrl, ReactComponent as MoreGlyph } from './more.svg';
export { default as openPrevEditorGlyphUrl, ReactComponent as OpenPrevEditorGlyph } from './open-prev-editor.svg';
export { default as overflowGlyphUrl, ReactComponent as OverflowGlyph } from './overflow.svg';
export { default as pasteGlyph, ReactComponent as PasteGlyph } from './paste.svg';
export { default as pinGlyphUrl, ReactComponent as PinGlyph } from './pin.svg';
export { default as privacyUrl, ReactComponent as PrivacyGlyph } from './privacy.svg';
export { default as reassignGlyphUrl, ReactComponent as ReassignGlyph } from './reassign.svg';
export { default as removeGlyphUrl, ReactComponent as RemoveGlyph } from './remove.svg';
export { default as reopenGlyphUrl, ReactComponent as ReopenGlyph } from './reopen.svg';
export { default as runGlyphUrl, ReactComponent as RunGlyph } from './run.svg';
export { default as searchGlyphUrl, ReactComponent as SearchGlyph } from './search.svg';
export { default as settingsGlyphUrl, ReactComponent as SettingsGlyph } from './settings.svg';
export { default as slideLeftGlyphUrl, ReactComponent as SlideLeftGlyph } from './slide-left.svg';
export { default as slideRightGlyphUrl, ReactComponent as SlideRightGlyph } from './slide-right.svg';
export { default as snoozeGlyphUrl, ReactComponent as SnoozeGlyph } from './snooze.svg';
export { default as sortGlyphUrl, ReactComponent as SortGlyph } from './sort.svg';
export { default as starCheckedGlyphUrl, ReactComponent as StarCheckedGlyph } from './star-checked.svg';
export { default as starGlyphUrl, ReactComponent as StarGlyph } from './star.svg';
export { default as subtractGlyphUrl, ReactComponent as SubtractGlyph } from './subtract.svg';
export { default as successFilledGlyphUrl, ReactComponent as SuccessFilledGlyph } from './success-filled.svg';
export { default as userGlyphUrl, ReactComponent as UserGlyph } from './user.svg';
export { default as warningFilledGlyphUrl, ReactComponent as WarningFilledGlyph } from './warning-filled.svg';
export { default as warningGlyphUrl, ReactComponent as WarningGlyph } from './warning.svg';
export { default as zoomGlyphUrl, ReactComponent as ZoomGlyph } from './zoom.svg';
