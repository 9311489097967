// @flow

import * as React from 'react';
import useClickAway from 'react-use/lib/useClickAway';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import SearchInput from '../Input/SearchInput/SearchInput';

const useStyles = makeStyles({
  searchInput: {
    padding: 0,
    width: '18rem !important',
    backgroundColor: 'inherit !important'
  }
});

const MasterDetailSearchInput = (props: Props) => {
  const ref = React.useRef(null);
  // Support optional chaining (https://github.com/facebook/flow/issues/4303)
  // $FlowIgnore
  useClickAway(ref, () => ref?.current?.blur?.());
  // Omit props that Atlaskit passes that are not valid element attributes
  const {
    children,
    className,
    component,
    dangerouslySetInnerHTML,
    dataset,
    draggableProps,
    index,
    onClick,
    onMouseDown,
    onMouseUp,
    placeholder,
    spacing,
    styles,
    text,
    ...other
  } = props;
  const classes = useStyles();

  return (
    <SearchInput
      className={classNames(className, classes.searchInput)}
      inputRef={ref}
      onMouseDown={event => {
        // Stop the event from bubbling to Atlaskit `InteractionStateManager` component
        // (https://bitbucket.org/atlassian/atlaskit-mk-2/src/be4cdd0b1f9566847c274d0d05b9549881e0ae4a/packages/core/navigation-next/src/components/presentational/InteractionStateManager/index.js#lines-22)
        event.stopPropagation();

        if (typeof onMouseDown === 'function') onMouseDown(event);
      }}
      onMouseUp={event => {
        // Stop the event from bubbling to Atlaskit `InteractionStateManager` component
        // (https://bitbucket.org/atlassian/atlaskit-mk-2/src/be4cdd0b1f9566847c274d0d05b9549881e0ae4a/packages/core/navigation-next/src/components/presentational/InteractionStateManager/index.js#lines-17)
        event.stopPropagation();

        if (typeof onMouseUp === 'function') onMouseUp(event);
      }}
      placeholder={placeholder}
      {...other}
    />
  );
};
MasterDetailSearchInput.displayName = 'MasterDetailSearchInput';

export default MasterDetailSearchInput;
