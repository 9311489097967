// @flow

import colors from './colors';
import mixins from './mixins';
import typography from './typography';

const globalStyles = {
  '@global': {
    /* Reset
    /* ----------------------------------- */
    'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video': {
      margin: 0,
      padding: 0,
      border: 0,
      verticalAlign: 'baseline'
    },
    'blockquote, q': {
      quotes: 'none'
    },
    'blockquote:before, blockquote:after, q:before, q:after': {
      content: '""',
      fallbacks: {
        content: 'none'
      }
    },
    '*, *:before, *:after': {
      boxSizing: 'inherit'
    },
    'b, strong': {
      fontWeight: 'bold'
    },
    'i, em, dfn': {
      fontStyle: 'italic'
    },
    'sub, sup': {
      ...typography.extraSmallText,
      position: 'relative',
      lineHeight: 0,
      verticalAlign: 'baseline'
    },
    sup: {
      top: '-.5rem'
    },
    sub: {
      bottom: '-.25rem'
    },
    'svg:not(:root)': {
      overflow: 'hidden'
    },
    mark: {
      backgroundColor: colors.canary
    },
    'code, kbd, pre, samp': typography.monospace,
    'button, input, optgroup, select, textarea': {
      margin: 0,
      color: 'inherit',
      font: 'inherit'
    },
    button: {
      overflow: 'visible',
      padding: 0,
      border: 'none',
      backgroundColor: colors.white
    },
    'button, select': {
      textTransform: 'none'
    },
    'button, html input[type="button"], input[type="reset"], input[type="submit"]': {
      cursor: 'pointer',

      appearance: 'button'
    },
    'button::-moz-focus-inner, input::-moz-focus-inner': {
      padding: 0,
      border: 0
    },
    '[role="button"]': {
      cursor: 'pointer'
    },
    input: {
      // Depends on the user agent. Desktop browsers (including Firefox) use a default value of roughly `1.2`, depending on the element's `font-family`.
      // https://developer.mozilla.org/en-US/docs/Web/CSS/line-height
      lineHeight: 'normal'
    },
    'input[type="checkbox"], input[type="radio"]': {
      boxSizing: 'border-box',
      padding: 0
    },
    'input[type="search"]': {
      appearance: 'textfield'
    },
    'input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration': {
      appearance: 'none'
    },
    legend: {
      padding: 0,
      border: 0
    },
    table: {
      borderSpacing: 0,
      borderCollapse: 'collapse'
    },
    'td, th': {
      padding: 0
    },
    // Hide clear button in IE 10+ and Microsoft Edge
    // https://developer.mozilla.org/en-US/docs/Web/CSS/::-ms-clear
    '::-ms-clear': {
      display: 'none'
    },
    // Hide password reveal button in IE 10+
    // https://developer.mozilla.org/en-US/docs/Web/CSS/::-ms-reveal
    '::-ms-reveal': {
      display: 'none'
    },
    /* Base Styles
    /* ----------------------------------- */
    'html, body': {
      height: '100%'
    },
    html: {
      boxSizing: 'border-box',

      '-ms-text-size-adjust': '100%',
      '-webkit-text-size-adjust': '100%',

      fontSize: '100%',

      '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)'
    },
    body: {
      ...typography.base,
      fontStyle: 'normal',
      background: colors.white,
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      '-moz-font-feature-settings': '"liga" on'
    },
    hr: {
      position: 'relative',
      display: 'block',
      width: '100%',
      margin: '2.5rem 0 3.5rem',
      padding: 0,
      height: '1px',
      border: 0
    },
    'audio, canvas, iframe, img, svg, video': {
      verticalAlign: 'middle'
    },
    fieldset: {
      margin: 0,
      padding: 0,
      border: 0
    },
    textarea: {
      overflow: 'auto',
      resize: 'vertical'
    },
    'p, ul, ol, dl, blockquote': {
      margin: 0
    },
    'ol, ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0
    },
    'ol ol, ul ul, ul ol, ol ul': {
      margin: 0
    },
    ul: {
      listStyle: 'none'
    },
    ol: {
      listStyle: 'decimal'
    },
    li: {
      margin: 0
    },
    dt: {
      float: 'left',
      margin: '0 20px 0 0',
      width: '120px',
      fontWeight: 500,
      textAlign: 'right'
    },
    dd: {
      margin: '0 0 5px 0',
      textAlign: 'left'
    },
    blockquote: {
      margin: '1.5rem 0',
      padding: '0 0 0 1rem',
      borderLeft: `0.25rem solid ${colors.lightGrey}`
    },
    'blockquote p': {
      margin: '.8rem 0'
    },
    'blockquote small': {
      display: 'inline-block',
      margin: '.8rem 0 .8rem 1.5rem',
      opacity: 0.8
    },
    /* Quotation marks */
    'blockquote small:before': {
      content: '"\\2014 \\00A0"'
    },
    'blockquote cite': {
      fontWeight: 'bold'
    },
    'blockquote cite a': {
      fontWeight: 'normal'
    },
    a: {
      ...mixins.link
    },
    img: {
      maxWidth: '100%',
      border: 0
    },
    '#root': {
      '-webkit-overflow-scrolling': 'touch'
    },
    /* Internet Explorer 10+ */
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      '*, *:before, *:after': {
        transition: 'none !important'
      }
    },
    /* Electron */
    'html[data-userAgent*="Electron"]': {
      // This style will hide the loader when running within Cypress electron browser.
      // The loader interferes with snapshots.
      '& canvas, & iframe': {
        display: 'none !important'
      }
    }
  }
};

export default globalStyles;
