// @flow

import is from 'ramda/src/is';
import { type JSONSchema } from '@catalytic/json-schema-validator-catalytic-web';
import { createValidation } from '../Hook/useValidation';

const isBlob = is(window.Blob);
const isFileSchemaAndBlobValue = (schema?: JSONSchema<>, value?: any) =>
  schema?.refType === 'FILE' && isBlob(value);
const isFileSchemaAndEmptyStringValue = (schema?: JSONSchema<>, value?: any) =>
  schema?.refType === 'FILE' && value === '';

const getErrorMessage = ({
  contextValues,
  name,
  schema: schemaProp,
  value: valueProp
}: {
  contextValues?: { [string]: any },
  name: string,
  schema?: JSONSchema<>,
  value?: any
}) => {
  if (
    schemaProp === undefined ||
    // If the value is null or an empty string, we don't care about the error
    // message (like "Must be string"). Our forms will already apply the error
    // styling for required fields and the data type error message is not
    // meaningful to users.
    valueProp === null ||
    valueProp === '' ||
    // Since json-schema-validator considers file blobs to be invalid
    // for file fields and there's no immediate value to supporting them,
    // we skip file blob validation for now.
    isFileSchemaAndBlobValue(schemaProp, valueProp) ||
    // When a file is removed from a file input, the field's value get's
    // set to an empty string. We still want to ignore the schema error
    // and just let the existing form validation handle the required field.
    isFileSchemaAndEmptyStringValue(schemaProp, valueProp)
  ) {
    return;
  }

  let schema;
  let value;
  if (contextValues === undefined) {
    schema = schemaProp;
    value = valueProp;
  } else {
    schema = { properties: { [name]: schemaProp }, type: 'object' };
    value = { ...contextValues, [name]: valueProp };
  }
  const fieldSchemaValidation = createValidation({
    schema,
    value
  });
  const fieldSchemaValidationErrors = (
    fieldSchemaValidation.errors || []
  ).filter(error => {
    const [, index] = (error.dataPath || '').split('/');
    return name === index;
  });
  const schemaValidation = createValidation({
    schema: schemaProp,
    value: valueProp
  });
  const schemaValidationErrors = schemaValidation.errors || [];
  const validationErrors =
    [...fieldSchemaValidationErrors, ...schemaValidationErrors] || [];
  const error = [...(validationErrors || [])].pop();
  const errorMessage = error?.message;

  return errorMessage;
};

export default getErrorMessage;
