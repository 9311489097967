// @flow

import React from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';

const Style = (theme: ThemeType) => {
  return {
    root: {
      display: 'inline-flex',
      fontSize: '2.45rem',
      margin: '.25rem 1rem .15rem 0',
      '& $root': {
        margin: 0
      },
      '& svg': {
        verticalAlign: 'top'
      },
      // Match the 1em height/width we use when loading our own svg icons
      '& img': {
        height: '1em',
        width: '1em'
      }
    },
    colorScheme: theme.mixins.colorScheme,
    invertScheme: theme.mixins.invertScheme
  };
};

type Props = InjectSheetProvidedProps &
  HTMLSpanElement & {
    colorScheme?: string,
    invertScheme?: boolean
  };

const Icon = ({
  classes,
  className,
  theme,
  colorScheme,
  invertScheme,
  ...props
}: Props) => (
  <span
    className={classNames(
      classes.root,
      {
        [invertScheme ? classes.invertScheme : classes.colorScheme]: true
      },
      className
    )}
    data-color={colorScheme || undefined}
    {...props}
  />
);

Icon.defaultProps = {
  invertScheme: false
};

Icon.displayName = 'Icon';
export default injectSheet(Style)(Icon);
