// @flow

import React, { type ElementType } from 'react';
import classNames from 'classnames';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../style/ThemeTypes';
import injectSheet from '../style/injectSheet';

const Style = (theme: ThemeType) => ({
  root: {
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    backgroundColor: theme.colors.pastelGrey
  }
});

type Props = InjectSheetProvidedProps & {
  /**
   * Component to be used as the heading
   */
  component: ElementType
};

function MarkdownRule(props: Props) {
  const {
    classes,
    className: classNameProp,
    component: Component,
    theme,
    ...other
  } = props;
  const className = classNames(classes.root, classNameProp);

  return <Component {...{ ...other, className }} />;
}

MarkdownRule.displayName = 'MarkdownRule';
MarkdownRule.defaultProps = {
  component: 'hr'
};

export default injectSheet(Style)(MarkdownRule);
