// @flow

import * as React from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';

type Props = InjectSheetProvidedProps & {
  children?: React.Node,
  'data-testid'?: string,
  forwardedRef: React.Ref<'div'>
};

const Style = (theme: ThemeType) => ({
  root: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.gte.tablet.portrait]: {
      width: 'auto'
    },
    '& > *': {
      marginLeft: '0.5rem',
      '&:first-child': {
        marginLeft: 0
      }
    }
  }
});

const PageHeaderButtonGroup = ({
  children,
  classes,
  className,
  'data-testid': dataTestId,
  forwardedRef
}: Props) => (
  <div
    className={classNames(classes.root, className)}
    data-testid={dataTestId}
    ref={forwardedRef}
  >
    {children}
  </div>
);
PageHeaderButtonGroup.displayName = 'PageHeaderButtonGroup';

const EnhancedPageHeaderButtonGroup = injectSheet(Style)(PageHeaderButtonGroup);

const EnhancedPageHeaderButtonGroupWithRef = React.forwardRef<
  Object,
  React.Ref<*>
>((props, ref) => (
  <EnhancedPageHeaderButtonGroup {...props} forwardedRef={ref} />
));
EnhancedPageHeaderButtonGroupWithRef.displayName =
  'EnhancedPageHeaderButtonGroupWithRef';

export default EnhancedPageHeaderButtonGroupWithRef;
