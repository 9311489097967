// @flow

import {
  compose,
  mapProps,
  setDisplayName,
  withStateHandlers,
  type HOC
} from 'recompose';
import injectSheet from '../../style/injectSheet';

export type TabsProps = {
  activeTabClassName: string,
  setActiveTabIndex: (activeTabIndex: number) => mixed,
  tabClassName: string
};

const withTabs: HOC<*, Object> = compose(
  injectSheet(
    { tab: { display: 'none' }, activeTab: { display: 'block' } },
    { withTheme: false }
  ),
  setDisplayName('withTabs'),
  withStateHandlers(({ activeTabIndex = 0 }) => ({ activeTabIndex }), {
    setActiveTabIndex: () => activeTabIndex => ({ activeTabIndex })
  }),
  mapProps(({ classes, ...props }) => ({
    activeTabClassName: classes.activeTab,
    tabClassName: classes.tab,
    ...props
  }))
);

export default withTabs;
