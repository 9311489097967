// @flow

import React, { createRef, PureComponent } from 'react';
import { Query, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { defineMessages, injectIntl } from 'react-intl';
import { compose, withProps } from 'recompose';
import RPath from 'ramda/src/path';
import {
  FileInput,
  injectSheet,
  type FileInputType,
  type InjectSheetProvidedProps,
  type ThemeType
} from '@catalytic/catalytic-ui';
import { getFileUrl } from './FieldHelpers';
import { type Field as FieldType } from './FieldTypes';
import isAllowedFileUploadSize from './isAllowedFileUploadSize';
import { NODE_TYPE, type NodeType } from '../shared/NodeTypes';
import Prompt from '../shared/Prompt/Prompt';
import TopBarLoader from '../Loading/TopBarLoader';
import { FILE as FILE_LABEL } from '../const/label';
import logError from '../utils/logError';
import isUUID from '../utils/uuid';

const Messages = defineMessages({
  filePlaceholder: {
    id: 'field.filePlaceholder',
    defaultMessage: 'No file chosen'
  }
});

export const Style = (theme: ThemeType) => ({
  error: theme.mixins.inputErrorMessage
});

const FileFragment = gql`
  fragment FileFragment on File {
    filename
    id
    url
  }
`;

type Mutation =
  | 'addFile'
  | 'addActorFile'
  | 'addContextFile'
  | 'addContextTypeFile'
  | 'addStepFile'
  | 'addTaskFile';

export const DEFAULT_MUTATION = NODE_TYPE.TEAM;

export const MUTATION: { [key: NodeType]: Mutation } = {
  [DEFAULT_MUTATION]: 'addFile',
  [NODE_TYPE.ACTOR]: 'addActorFile',
  [NODE_TYPE.CONTEXT]: 'addContextFile',
  [NODE_TYPE.CONTEXT_TYPE]: 'addContextTypeFile',
  [NODE_TYPE.STEP]: 'addStepFile',
  [NODE_TYPE.TASK]: 'addTaskFile'
};

export const ADD_FILE = (
  mutation: Mutation,
  variables: { id: string, files: Array<File> }
) => {
  const { files } = variables || {};
  const mutations = {
    addActorFile: {
      mutation: gql`
        ${FileFragment}

        mutation AddActorFile($id: ID!, $files: [Upload]!) {
          addActorFile(input: { id: $id, files: $files }) {
            files {
              id
              ...FileFragment
            }
          }
        }
      `,
      variables
    },
    addContextFile: {
      mutation: gql`
        ${FileFragment}

        mutation AddContextFile($id: ID!, $files: [Upload]!) {
          addContextFile(input: { id: $id, files: $files }) {
            files {
              id
              ...FileFragment
            }
          }
        }
      `,
      variables
    },
    addContextTypeFile: {
      mutation: gql`
        ${FileFragment}

        mutation AddContextTypeFile($id: ID!, $files: [Upload]!) {
          addContextTypeFile(input: { id: $id, files: $files }) {
            files {
              id
              ...FileFragment
            }
          }
        }
      `,
      variables
    },
    addStepFile: {
      mutation: gql`
        ${FileFragment}

        mutation AddStepFile($id: ID!, $files: [Upload]!) {
          addStepFile(input: { id: $id, files: $files }) {
            files {
              id
              ...FileFragment
            }
          }
        }
      `,
      variables
    },
    addTaskFile: {
      mutation: gql`
        ${FileFragment}

        mutation AddTaskFile($id: ID!, $files: [Upload]!) {
          addTaskFile(input: { id: $id, files: $files }) {
            files {
              id
              ...FileFragment
            }
          }
        }
      `,
      variables
    },
    addFile: {
      mutation: gql`
        ${FileFragment}

        mutation AddFile($files: [Upload]!) {
          addFile(input: { files: $files }) {
            files {
              id
              ...FileFragment
            }
          }
        }
      `,
      variables: { files }
    }
  };

  return mutations[mutation] || mutations[MUTATION[DEFAULT_MUTATION]];
};

export const FILE_QUERY = gql`
  ${FileFragment}

  query File($id: ID!) {
    file(id: $id) {
      id
      ...FileFragment
    }
  }
`;

type Props = FieldType &
  FileInputType &
  InjectSheetProvidedProps & {
    client: { mutate: (options: Object) => Promise<Object> },
    data: Object,
    filename: ?string,
    intl: Object,
    maxFileSize?: number,
    onDrop?: (event: SyntheticEvent<HTMLInputElement>) => void,
    onFileUpload?: (Array<any>) => void,
    url: ?string
  };

type State = {
  filename: ?string,
  hasError: boolean,
  inputError: ?string,
  mutating: boolean,
  url: ?string,
  value: any
};

export class FileField extends PureComponent<Props, State> {
  static displayName = 'FileField';

  static defaultProps = {
    filename: null,
    hasError: false,
    url: null,
    value: ''
  };

  state = {
    filename: this.props.filename || null,
    hasError: this.props.hasError || false,
    inputError: null,
    mutating: false,
    url: this.props.url || null,
    value: this.props.value || ''
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (...args: Array<any>) => {
    const [event, isAcceptable = true] = args;
    const { value: valueProp } = event?.currentTarget || {};
    const {
      accept,
      id,
      intl,
      maxFileSize,
      name,
      onChange,
      onFileUpload
    } = this.props;
    const unvalidatedValue = Array.isArray(valueProp)
      ? valueProp.map(({ value }) => value)
      : valueProp;
    const fileSizeLimits = maxFileSize
      ? { fileSize: maxFileSize, nonCsvFileSize: maxFileSize }
      : undefined;
    const value = Array.isArray(unvalidatedValue)
      ? unvalidatedValue.filter(file =>
          isAllowedFileUploadSize(file, fileSizeLimits)
        )
      : unvalidatedValue;

    if (this._isMounted) {
      this.setState({
        filename: null,
        hasError: false,
        inputError: null,
        mutating: true,
        url: null,
        value: null
      });
    }

    if (onFileUpload && value && value.length) {
      const uploadedFiles = Array.isArray(value) ? value.slice() : [value];
      onFileUpload(uploadedFiles);
    }

    let inputError;

    if (
      unvalidatedValue.length > 0 &&
      value.length !== unvalidatedValue.length
    ) {
      if (maxFileSize) {
        inputError = intl.formatMessage({
          id: 'file.tooLarge.custom',
          defaultMessage: 'The file was larger than the limit.'
        });
      } else {
        inputError = intl.formatMessage({
          id: 'file.tooLarge.default',
          defaultMessage: 'Files must be under 1 GB.'
        });
      }
    } else if (accept && !isAcceptable) {
      inputError = intl.formatMessage(
        {
          id: 'files.accept',
          defaultMessage:
            '{count, plural, one {Files must be of the following type: {accept}} other {Files must be of the following types: {accept}}}'
        },
        { accept, count: accept.split(',').length }
      );
    }

    if (Array.isArray(value) && value.length > 0) {
      const { client, context } = this.props;
      const { __typename, id: contextId } = context || {};
      const mutation = MUTATION[__typename] || MUTATION[DEFAULT_MUTATION];

      return client
        .mutate(ADD_FILE(mutation, { id: contextId, files: value }))
        .then(({ data }) => {
          const files = RPath([mutation, 'files'], data);
          const [{ filename, id: value, url } = {}] = files || [];

          if (this._isMounted) {
            this.setState({
              filename,
              hasError: false,
              inputError: null,
              mutating: false,
              url,
              value
            });
          }

          if (onChange) {
            return onChange({
              currentTarget: {
                filename,
                id,
                name,
                url,
                value
              }
            });
          }
        })
        .catch(error => {
          logError(error);

          const filename = null;
          const url = null;
          const value = '';

          if (this._isMounted) {
            this.setState({
              filename,
              hasError: true,
              inputError: null,
              mutating: false,
              url,
              value
            });
          }

          if (onChange) {
            return onChange({
              currentTarget: {
                filename,
                id,
                name,
                url,
                value
              }
            });
          }
        });
    } else {
      const filename = null;
      const url = null;
      const value = '';

      if (this._isMounted) {
        this.setState({
          filename,
          hasError: !!inputError,
          inputError,
          mutating: false,
          url,
          value
        });
      }

      if (onChange) {
        return onChange({
          currentTarget: {
            filename,
            id,
            name,
            url,
            value
          }
        });
      }
    }
  };

  // Disable drop event due to lack of library support
  // Remove this event listener once drag and drop is supported
  // https://github.com/catalyticlabs/product/issues/4984
  handleDrop = (event: SyntheticEvent<HTMLInputElement>) => {
    event.stopPropagation();

    if (this.props.onDrop) {
      return this.props.onDrop(event);
    }
  };

  render() {
    const {
      classes,
      client,
      context,
      data,
      enum: enumProp,
      filename: filenameProp,
      hasError: hasErrorProp,
      intl,
      items,
      onChange,
      onDrop,
      onFileUpload,
      schema,
      title,
      url: urlProp,
      value: valueProp,
      ...props
    } = this.props;
    const {
      filename: filenameState,
      hasError,
      inputError,
      mutating,
      value
    } = this.state;
    const filename = filenameState || filenameProp;

    return (
      <>
        <Prompt when={mutating} />
        {value && typeof value === 'string' && isUUID(value) ? (
          <Query
            errorPolicy="all"
            fetchPolicy="network-only"
            query={FILE_QUERY}
            // Sometimes file permissions forbid a user from querying a recently uploaded file.
            // So, following a file mutation do not re-query a file to get the filename, etc.
            skip={filename && typeof filename === 'string'}
            variables={{ id: value }}
          >
            {({ loading, data }) => {
              const { file } = data || {};
              const id = file?.id || value;
              const name = file?.filename || filename || FILE_LABEL;
              const preview = file?.url || getFileUrl(value);
              const files = [{ id, name, value: { preview } }];

              if ((loading || mutating) && !file) {
                return (
                  <>
                    <TopBarLoader />
                    <FileInput
                      defaultValue={value}
                      hasError={hasError}
                      loading
                      placeholder={intl.formatMessage(Messages.filePlaceholder)}
                      {...props}
                    />
                  </>
                );
              }

              return (
                <FileInput
                  defaultValue={id}
                  files={files}
                  hasError={hasError}
                  onChange={this.handleChange}
                  placeholder={intl.formatMessage(Messages.filePlaceholder)}
                  {...props}
                />
              );
            }}
          </Query>
        ) : (
          <>
            {mutating && <TopBarLoader />}
            <FileInput
              defaultValue={value}
              hasError={hasError}
              loading={mutating}
              onChange={this.handleChange}
              placeholder={intl.formatMessage(Messages.filePlaceholder)}
              {...props}
            />
          </>
        )}
        {inputError && <div className={classes.error}>{inputError}</div>}
      </>
    );
  }
}

export default compose(
  injectIntl,
  injectSheet(Style),
  withApollo,
  withProps(({ inputRef }) => ({ inputRef: inputRef || createRef() }))
)(FileField);
