// @flow

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Markdown } from '@catalytic/catalytic-ui';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.functions.toRem(theme.variables.inputMarginBottom)
  }
}));

type Props = {|
  name: string,
  value: any
|};

const MarkdownView = ({ name, value }: Props) => {
  const classes = useStyles();

  if (value === undefined || value === null || value === '') {
    return null;
  }

  return (
    <div
      className={classes.root}
      data-id={name} // Required for scroll invalid input into view logic tied to `WebformView` component
      data-name={name} // Required for scroll invalid input into view logic tied to `WebformView` component
    >
      <Markdown value={value} />
    </div>
  );
};
MarkdownView.displayName = 'MarkdownView';

export default MarkdownView;
