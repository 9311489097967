// @flow

import { Observable } from 'apollo-link';
import { ErrorLink } from 'apollo-link-error';
// $FlowIgnore[untyped-type-import]
import { type ErrorInfo } from 'apollo-errors';
import Rany from 'ramda/src/any';
import is from 'ramda/src/is';

export type Errors = {
  forward: (observer: Observable) => any,
  graphQLErrors: [ErrorInfo],
  networkError: Object | void
};

export default function createUnauthorizedLink(
  handler: (errors: Errors) => {}
) {
  return new ErrorLink((errors: Errors) => {
    const { graphQLErrors = [], networkError = {}, forward } = errors;
    const isString = is(String);

    if (
      Rany(error => error.message === 'Unauthorized')(graphQLErrors) ||
      (isString(networkError) && networkError.includes('401')) ||
      networkError.statusCode === 401
    ) {
      forward(
        new Observable(observer => {
          const runner = async () => {
            try {
              const results = await handler(errors);
              if (results !== undefined) observer.next(results);
              observer.complete();
            } catch (e) {
              observer.error(e);
            }
          };
          return runner();
        })
      );
    }
  });
}
