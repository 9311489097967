// flow

export default {
  clientSearches: {
    nodes: [],
    pageInfo: {
      endCursor: null,
      hasNextPage: false,
      __typename: 'ClientPageInfo'
    },
    totalCount: 0,
    __typename: 'ClientSearchConnection'
  }
};
