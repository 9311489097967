// @flow

import React, { type Node } from 'react';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../style/ThemeTypes';
import injectSheet from '../style/injectSheet';

const Style = (theme: ThemeType) => ({
  root: {
    borderTop: '1px solid transparent',
    '&:first-child': {
      borderTopColor: theme.colors.pastelGrey
    },
    '&:nth-child(even)': {
      '&, & td': {
        backgroundColor: theme.colors.ghostWhite
      }
    }
  }
});

type Props = InjectSheetProvidedProps & {
  /**
   * Child nodes
   */
  children?: Node
};

function MarkdownTableRow(props: Props): Node {
  const { children, classes } = props;

  return <tr className={classes.root}>{children}</tr>;
}

MarkdownTableRow.displayName = 'MarkdownTableRow';

export default injectSheet(Style)(MarkdownTableRow);
