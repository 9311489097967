// @flow

import * as React from 'react';
import { compose, setDisplayName, withProps } from 'recompose';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { ReactComponent as InfoGlyph } from '@catalytic/catalytic-icons/lib/glyphs/info.svg';
import { WebformViewConsumer } from '../../View/WebformView/WebformView';
import { WAIT as DEBOUNCE_WAIT } from '../../const/debounce';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';
import Menu from '../../Component/Menu/Menu';
import getErrorMessage from '../../utils/getErrorMessage';
import getFieldValue from '../../utils/getFieldValue';
import nextInputID from '../../utils/nextInputID';

const DISPLAY_NAME = 'Input';

const Style = (theme: ThemeType) => {
  const inputMarginTop = {
    '& + $input': {
      marginTop: theme.functions.toRem(theme.variables.inputMarginTop)
    }
  };

  return {
    root: {
      ...theme.mixins.breakWord,
      marginBottom: theme.functions.toRem(theme.variables.inputMarginBottom),
      backgroundColor: theme.colors.white,
      textAlign: 'left'
    },
    errorMargin: {
      marginBottom: 0
    },
    label: {
      ...theme.typography.baseText,
      ...inputMarginTop,
      display: 'block',
      color: theme.colors.black,
      fontWeight: 700
    },
    labelWithTooltip: {
      display: 'inline'
    },
    description: {
      ...theme.typography.smallText,
      ...inputMarginTop
    },
    placeholder: {
      ...theme.typography.smallText,
      ...inputMarginTop,
      color: theme.colors.slateGrey
    },
    errorMessage: {
      ...theme.mixins.inputErrorMessage,
      ...inputMarginTop
    },
    input: theme.mixins.inputMargin,
    openerContainer: {
      verticalAlign: 'bottom'
    },
    helpOpener: {
      color: theme.colors.battleshipGrey,
      fontSize: '1.375rem',
      marginLeft: '0.25rem'
    },
    tooltipContainer: {
      display: 'block',
      ...inputMarginTop
    },
    tooltipLabel: theme.typography.header5,
    tooltip: {
      ...theme.mixins.breakWord,
      padding: '1rem',
      textAlign: 'left'
    }
  };
};

export type Props = InjectSheetProvidedProps &
  HTMLInputElement & {
    children?: React.Node,
    description?: React.Node,
    descriptionClassName?: string,
    displayErrors: boolean,
    error?: Error | React.Element<any>,
    errorMessage?: string,
    hasError: boolean,
    // Toggles validation logic tied to `field-restrictions` feature flag
    hasValidation: boolean,
    inputClassName?: string,
    inputElementClassName?: string,
    label?: string,
    labelClassName?: string,
    onBlur?: ({
      currentTarget: { id: string, name: string, value: string }
    }) => mixed,
    onChange?: ({
      currentTarget: { id: string, name: string, value: string }
    }) => mixed,
    placeholderClassName?: string,
    schema?: Object,
    showDescriptionInTooltip?: boolean,
    value?: any
  };

type State = { errorMessage?: string };

class Input extends React.PureComponent<Props, State> {
  static displayName = DISPLAY_NAME;

  static defaultProps = {
    disabled: false,
    displayErrors: false,
    hasError: false,
    hasValidation: false,
    readOnly: false,
    required: false
  };

  state = {
    errorMessage:
      getErrorMessage({
        name: this.props.name,
        schema: this.props.schema,
        value: this.props.value
      }) || this.props.errorMessage
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorMessage !== prevProps.errorMessage) {
      this.setErrorMessage(this.props.value);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setErrorMessage = value => {
    const { errorMessage: errorMessageProp, name, schema, type } = this.props;
    const fieldValue = getFieldValue(value, type);
    const errorMessage =
      getErrorMessage({
        name,
        schema,
        value: fieldValue
      }) || errorMessageProp;

    if (this._isMounted) {
      this.setState({ errorMessage });
    }
  };

  debouncedSetErrorMessage = debounce(value => {
    this.setErrorMessage(value);
  }, DEBOUNCE_WAIT);

  handleBlur = event => {
    const { currentTarget } = event || {};
    const { value } = currentTarget || {};
    const { onBlur } = this.props;

    this.setErrorMessage(value);

    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  };

  handleChange = (...args) => {
    const [event] = args;
    const { currentTarget } = event || {};
    const { value } = currentTarget || {};
    const { onChange } = this.props;

    this.debouncedSetErrorMessage(value);

    if (typeof onChange === 'function') {
      onChange(...args);
    }
  };

  render() {
    const {
      children,
      classes,
      className,
      description,
      descriptionClassName,
      disabled: disabledProp,
      displayErrors: displayErrorsProp,
      error: errorProp,
      errorMessage: errorMessageProp,
      hasError: hasErrorProp,
      hasValidation,
      id,
      inputClassName,
      inputElementClassName,
      label,
      labelClassName,
      name,
      onBlur,
      onChange,
      placeholder,
      placeholderClassName,
      readOnly: readOnlyProp,
      required,
      showDescriptionInTooltip = false,
      theme,
      type,
      ...other
    } = this.props;
    const { errorMessage } = this.state;

    return children ? (
      <WebformViewConsumer>
        {({
          disabled,
          displayErrors,
          readOnly,
          setDisplayErrorsState,
          setLoadingState
        } = {}) => {
          const error =
            errorProp instanceof Error
              ? errorProp.message
              : errorProp ||
                ((displayErrorsProp || displayErrors) && hasValidation
                  ? errorMessage
                  : errorProp);

          return (
            <div
              className={classNames(
                classes.root,
                { [classes.errorMargin]: error },
                className
              )}
              data-id={name}
              data-testid="input"
              data-error={hasValidation ? errorMessage : undefined}
            >
              {showDescriptionInTooltip && (
                <div className={classes.tooltipContainer}>
                  {label && (
                    <label
                      className={classNames(
                        classes.label,
                        classes.labelWithTooltip,
                        labelClassName
                      )}
                      data-testid="inline-input-label"
                      htmlFor={
                        !disabledProp && !disabled && !readOnlyProp && !readOnly
                          ? id
                          : undefined
                      }
                      id={`${id}-label`}
                    >
                      {label}
                    </label>
                  )}
                  {description && (
                    <Menu
                      openerClassName={classes.openerContainer}
                      opener={<InfoGlyph className={classes.helpOpener} />}
                    >
                      <div className={classes.tooltip}>
                        <label
                          className={classes.tooltipLabel}
                          data-testid="input-tooltip-label"
                        >
                          {label}
                        </label>
                        <div
                          className={classNames(
                            classes.description,
                            descriptionClassName
                          )}
                          data-testid="input-tooltip-description"
                          id={`${id}-description`}
                        >
                          {description}
                        </div>
                        <div
                          className={classNames(
                            classes.placeholder,
                            placeholderClassName
                          )}
                          data-testid="input-tooltip-placeholder"
                        >
                          {placeholder}
                        </div>
                      </div>
                    </Menu>
                  )}
                </div>
              )}
              {!showDescriptionInTooltip && (
                <>
                  {label && (
                    <label
                      className={classNames(classes.label, labelClassName)}
                      data-testid="input-label"
                      htmlFor={
                        !disabledProp && !disabled && !readOnlyProp && !readOnly
                          ? id
                          : undefined
                      }
                      id={`${id}-label`}
                    >
                      {label}
                    </label>
                  )}
                  {description && (
                    <div
                      className={classNames(
                        classes.description,
                        descriptionClassName
                      )}
                      data-testid="input-description"
                      id={`${id}-description`}
                    >
                      {description}
                    </div>
                  )}
                  {placeholder && (
                    <div
                      className={classNames(
                        classes.placeholder,
                        placeholderClassName
                      )}
                      data-testid="input-placeholder"
                    >
                      {placeholder}
                    </div>
                  )}
                </>
              )}
              {children &&
                React.Children.map(children, child => {
                  return React.isValidElement(child)
                    ? React.cloneElement(child, {
                        ...other,
                        'aria-describedby': description
                          ? `${id}-description`
                          : undefined,
                        ...(error ? { 'aria-invalid': true } : {}),
                        'aria-labelledby': label ? `${id}-label` : undefined,
                        className: classNames(
                          classes.input,
                          inputClassName,
                          child.props.className
                        ),
                        disabled:
                          disabledProp || disabled || child.props.disabled,
                        displayErrors:
                          displayErrorsProp ||
                          displayErrors ||
                          child.props.displayErrors,
                        hasError:
                          hasErrorProp ||
                          Boolean(error) ||
                          child.props.hasError,
                        hasValidation:
                          hasValidation || child.props.hasValidation,
                        id: id || child.props.id,
                        inputClassName: classNames(
                          inputElementClassName,
                          child.props.inputClassName
                        ),
                        name: name || child.props.name,
                        onBlur: event => {
                          this.handleBlur(event);

                          if (child.props.onBlur) {
                            child.props.onBlur(event);
                          }
                        },
                        onChange: (...args) => {
                          const [event] = args;
                          this.handleChange(event);

                          if (child.props.onChange) {
                            child.props.onChange(...args);
                          }
                        },
                        readOnly:
                          readOnlyProp || readOnly || child.props.readOnly,
                        required: required || child.props.required,
                        setDisplayErrorsState,
                        setLoadingState
                      })
                    : child;
                })}
              {error && (
                <div
                  className={classes.errorMessage}
                  data-testid="error-message"
                >
                  {error}
                </div>
              )}
            </div>
          );
        }}
      </WebformViewConsumer>
    ) : null;
  }
}

export default compose(
  injectSheet(Style),
  setDisplayName(DISPLAY_NAME),
  withProps(({ id = nextInputID() }) => ({ id }))
)(Input);
