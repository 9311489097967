// @flow

import { ApolloLink } from 'apollo-link';
import { urlToRegexp } from '@catalytic/url-to-regexp';

export const WEBHOOK_ID_CONTEXT_KEY = 'x-webhook-id';
export const WEBHOOK_NAME_CONTEXT_KEY = 'x-webhook-name';

export type SetContextMiddlewareConfiguration = {
  key?: string,
  map?: (values: Array<string>) => string,
  url: string
};

export default function createSetContextMiddleware(
  configuration: SetContextMiddlewareConfiguration
) {
  const {
    key = WEBHOOK_ID_CONTEXT_KEY,
    map = ([value]: Array<string>): string => value,
    url
  } = configuration;
  const pattern = urlToRegexp(url);
  return new ApolloLink((operation, forward) => {
    try {
      if (typeof window.location.href === 'string') {
        const [, ...values] =
          pattern.exec(window.location.href.split('?')[0]) || [];
        if (values.length) {
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              [key]: map(values)
            }
          }));
        }
      }
    } catch (e) {}

    return forward(operation);
  });
}
