// @flow

import React, { type ElementRef } from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';

const Style = (theme: ThemeType) => {
  const INPUT_PLACEHOLDER_VALUE = {
    ...theme.typography.extraSmallText,
    color: theme.colors.ashGrey,
    cursor: 'text'
  };

  return {
    root: {
      position: 'relative'
    },
    input: {
      ...theme.mixins.inputStyle,
      ...theme.mixins.truncate,
      ...theme.typography.extraSmallText,
      paddingLeft: theme.variables.inputPadding,
      borderRadius: theme.variables.borderRadiusSmall,
      '&::placeholder': INPUT_PLACEHOLDER_VALUE,
      '&::-webkit-input-placeholder': INPUT_PLACEHOLDER_VALUE,
      '&:-ms-input-placeholder': INPUT_PLACEHOLDER_VALUE,
      '&::-ms-input-placeholder': INPUT_PLACEHOLDER_VALUE,
      '&::-moz-placeholder': INPUT_PLACEHOLDER_VALUE,
      '&:-moz-placeholder': INPUT_PLACEHOLDER_VALUE
    }
  };
};

export type SearchInputType = InjectSheetProvidedProps &
  HTMLInputElement & {
    inputClassName?: string,
    inputRef?: ElementRef<*>,
    intl: Object,
    placeholder: string
  };

const SearchInput = ({
  classes,
  className,
  disabled,
  inputClassName,
  inputRef: ref,
  intl,
  placeholder,
  readOnly,
  theme,
  title,
  ...props
}: SearchInputType) => {
  return (
    <div className={classNames(classes.root, className)} title={title}>
      <input
        className={classNames(classes.input, inputClassName)}
        data-testid="search-input"
        disabled={disabled || readOnly}
        placeholder={placeholder}
        {...{ readOnly, ref, ...props }}
      />
    </div>
  );
};
SearchInput.displayName = 'SearchInput';
SearchInput.defaultProps = {
  autoComplete: 'off',
  disabled: false,
  readOnly: false,
  type: 'search'
};

const EnhancedSearchInput = compose(
  injectIntl,
  injectSheet(Style)
)(SearchInput);
EnhancedSearchInput.displayName = 'SearchInput';

export default EnhancedSearchInput;
