// @flow

import * as React from 'react';
import { useIntl } from 'react-intl';
import { Input, MultiSelectInput } from '@catalytic/catalytic-ui';
import { operatorMessages } from './ConditionMessages';

type Props = {
  className?: string,
  disabled: boolean,
  onChange: string => void,
  value: string
};

export function StepConditionOperator(props: Props) {
  const { className, disabled, onChange, value } = props;
  const intl = useIntl();
  const options = Object.entries(operatorMessages).map(([key, message]) => ({
    value: key,
    label: intl.formatMessage(message)
  }));
  return (
    <Input className={className} data-testid="condition-operator">
      <MultiSelectInput
        data-testid="condition-operator"
        defaultValue={options.find(o => o.value === value)}
        disabled={disabled}
        embedded
        isClearable={false}
        onChange={e => {
          onChange(e.currentTarget.value[0]);
        }}
        options={options}
      />
    </Input>
  );
}
StepConditionOperator.displayName = 'StepConditionOperator';

export default StepConditionOperator;
