// @flow

import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import path from 'ramda/src/path';
import groupIconUrl from '@catalytic/catalytic-icons/lib/icons/group.svg';
import {
  injectSheet,
  type InjectSheetProvidedProps,
  type ThemeType
} from '@catalytic/catalytic-ui';
import classNames from 'classnames';

export const Style = (theme: ThemeType) => ({
  root: {
    height: '3.5rem',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${groupIconUrl})`
  },
  avatar: {
    height: '2.325rem',
    margin: '.325rem',
    minWidth: '2.325rem',
    backgroundSize: 'cover',
    borderRadius: '50%',
    border: `1px solid ${theme.colors.lightGrey}`,
    [theme.breakpoints.desktop]: {
      margin: '.325rem 0.825rem'
    }
  }
});

export type Props = InjectSheetProvidedProps & {
  id: string
};

export const SearchListItemAvatarQuery = gql`
  query SearchListItemAvatar($id: String!) {
    actor(id: $id) {
      id
      thumbnailUrl
    }
  }
`;

const getUrl = data => path(['actor', 'thumbnailUrl'], data);

const style = data => {
  const url = getUrl(data);
  return url
    ? {
        backgroundImage: `url(${url})`
      }
    : {};
};

export const SearchListItemAvatar = ({ classes, className, id }: Props) => (
  <Query
    fetchPolicy="cache-and-network"
    query={SearchListItemAvatarQuery}
    variables={{ id }}
  >
    {({ data }) => (
      <div
        className={classNames(classes.root, className, {
          [classes.avatar]: !!getUrl(data)
        })}
        style={style(data)}
      />
    )}
  </Query>
);

SearchListItemAvatar.displayName = 'SearchListItemAvatar';

export default injectSheet(Style)(SearchListItemAvatar);
