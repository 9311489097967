// @flow

import * as React from 'react';
import { Banner } from '@catalytic/catalytic-ui';
import { FormattedMessage } from 'react-intl';

export function RunTestModeBanner() {
  return (
    <Banner fixedPosition>
      <FormattedMessage
        id="run.test.mode.description"
        defaultMessage="Workflow Test Mode"
      />
    </Banner>
  );
}
RunTestModeBanner.displayName = 'RunTestModeBanner';

export default RunTestModeBanner;
