// @flow

import classNames from 'classnames';
import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import { Header3 } from '../../Text/Text';

const Style = (theme: ThemeType) => {
  return {
    root: {
      color: theme.colors.black,
      marginBottom: '.1rem'
    }
  };
};

type Props = InjectSheetProvidedProps & HTMLElement;

const ListItemHeader = ({
  classes,
  className,
  theme,
  ...props
}: Props): Node => (
  <Header3 className={classNames(classes.root, className)} {...props} />
);
ListItemHeader.displayName = 'ListItemHeader';

export default injectSheet(Style)(ListItemHeader);
