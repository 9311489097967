// @flow

import * as React from 'react';
import {
  MarkdownInput as MarkdownInputComponent,
  showToast,
  TOAST_TYPE,
  type MarkdownInputType as MarkdownInputComponentType
} from '@catalytic/catalytic-ui';
import RPath from 'ramda/src/path';
import { useApolloClient } from 'react-apollo';
import { FormattedMessage, useIntl } from 'react-intl';
import { NODE_TYPE, type Node } from '../NodeTypes';
import { buildFieldOptions } from '../../Field/FieldHelpers';
import { ADD_FILE, MUTATION } from '../../Field/FileField';
import useStepContext from '../../Step/StepContext';
import logError from '../../utils/logError';

export const uploadFileFactory = ({
  client,
  context
}: {
  client: { mutate: (options: Object) => Promise<Object> },
  context: ?Node
}) => (value: Array<File>) => {
  const { __typename, id: contextId } = context || {};
  const mutation = MUTATION[__typename] || MUTATION[NODE_TYPE.TEAM];
  return client
    .mutate(ADD_FILE(mutation, { id: contextId, files: value }))
    .then(({ data }) => {
      const files = RPath([mutation, 'files'], data);
      const file = (files || [])[0];
      return {
        ...file,
        url: new URL(file.url, window.location.origin).href
      };
    })
    .catch(error => {
      logError(error);
      showToast(
        TOAST_TYPE.error,
        <FormattedMessage
          id="MarkdownInput.uploadFile.error"
          defaultMessage="There was a problem uploading the file."
        />
      );
    });
};

// $Diff is used to specify as inputs everything from the catalytic-ui
// component EXCEPT fieldOptions, since that comes from the step
// context.
type Props = $Diff<MarkdownInputComponentType, { fieldOptions?: any }> & {
  context: ?Node
};
function MarkdownInput(props: Props) {
  const {
    className,
    context,
    defaultValue,
    disabled,
    displayErrors,
    hasError,
    hasRequired,
    id,
    inputClassName,
    name,
    onChange,
    placeholder,
    readOnly,
    required
  } = props;

  const client = useApolloClient();
  const intl = useIntl();
  const stepContext = useStepContext();
  const uploadFile = uploadFileFactory({ client, context });

  let fieldOptions = [];
  // MarkdownInput only offers fields in the context of Step Detail
  if (stepContext) {
    const { fieldsByContext } = stepContext;

    fieldOptions = buildFieldOptions({
      fieldsByContext,
      intl
    });
  }

  return (
    <MarkdownInputComponent
      {...{
        className,
        defaultValue,
        disabled,
        displayErrors,
        fieldOptions,
        hasError,
        hasRequired,
        id,
        inputClassName,
        name,
        onChange,
        placeholder,
        readOnly,
        required,
        uploadFile
      }}
    />
  );
}
MarkdownInput.displayName = 'MarkdownInput';

export default MarkdownInput;
