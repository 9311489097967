// @flow

import gql from 'graphql-tag';

export const WebhookListItemFragment = gql`
  fragment WebhookListItemFragment on Webhook {
    app {
      icon
      id
    }
    context {
      ... on ContextType {
        displayName
        id
      }
    }
    description
    displayName
    id
  }
`;
