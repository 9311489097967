// @flow

import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.colors.black,
    display: 'flex',
    alignItems: 'center',
    zIndex: theme.zIndex.nav,
    position: 'fixed',
    top: 0,
    height: theme.functions.toRem(theme.variables.navHeight),
    minWidth: '20rem', // 320px
    width: '100%',
    backgroundColor: theme.colors.navBackground,
    borderBottom: '1px solid #cbd5e0',
    // Be mindful of Banner component styles
    paddingLeft: '.75rem',
    paddingRight: '.75rem',
    [theme.breakpoints.gte.mobile.portrait]: {
      paddingLeft: '1rem',
      paddingRight: '1rem'
    }
  }
}));

type Props = {
  children: React.Node
};

const Nav = ({ children }: Props) => {
  const classes = useStyles();

  return <nav className={classes.root}>{children}</nav>;
};
Nav.displayName = 'Nav';

export default Nav;
