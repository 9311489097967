// @flow

import gql from 'graphql-tag';

export const AppQuery = gql`
  query AppQuery($id: ID!) {
    app(id: $id) {
      description
      displayName
      enabled
      icon
      id
      helpDocumentPath {
        id
        markdown
      }
      visibleToUsers {
        id
        displayName
        thumbnailUrl
      }
    }
  }
`;

export const AppLatestVersionQuery = gql`
  query AppLatestVersionQuery($input: AppLatestVersionQueryInput!) {
    appLatestVersion(input: $input) {
      description
      displayName
      enabled
      icon
      id
      helpDocumentPath {
        id
        markdown
      }
      visibleToUsers {
        id
        displayName
        thumbnailUrl
      }
    }
  }
`;

export const AppListItemFragment = gql`
  fragment AppListItemFragment on App {
    description
    displayName
    icon
    id
  }
`;

const ModuleFragment = gql`
  fragment ModuleFragment on Module {
    id
    displayName
  }
`;

export const ModuleListQuery = gql`
  ${ModuleFragment}
  query ModuleListQuery {
    modules(isAvailable: true) {
      nodes {
        id
        ...ModuleFragment
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const FrequentlyUsedAppsQuery = gql`
  ${AppListItemFragment}
  query FrequentlyUsedAppsQuery {
    frequentlyUsedApps {
      nodes {
        id
        ...AppListItemFragment
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const RecentlyUsedAppsQuery = gql`
  ${AppListItemFragment}
  query RecentlyUsedAppsQuery {
    recentlyUsedApps {
      nodes {
        id
        ...AppListItemFragment
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const WebAPIAppModulesQuery = gql`
  query WebAPIAppModulesQuery {
    search_v2(
      filter: [
        { type: APP, property: APP_NAME, operator: EQ, value: "web-api" }
      ]
      first: 100
      sort: [{ key: DISPLAY_NAME, direction: ASC }]
      type: APP
    ) {
      nodes {
        id
        ... on App {
          modules {
            nodes {
              id
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_APP_PERMISSIONS = gql`
  mutation updateAppPermissions($input: UpdateAppPermissionsInput!) {
    updateAppPermissions(input: $input)
  }
`;
