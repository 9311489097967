// @flow

import React, { type ElementType } from 'react';
import {
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
  Header6
} from '../Text/Text';
import { type InjectSheetProvidedProps } from '../style/ThemeTypes';

import injectSheet from '../style/injectSheet';

const Style = () => ({
  root: {
    margin: '1rem 0 .5rem'
  }
});

type Props = InjectSheetProvidedProps & {
  /**
   * Component to be used as the heading
   */
  component: ElementType,
  /**
   * Nodes to be placed in the confirmation action container.
   */
  depth: number,
  /**
   * Raw markdown used to generate the element
   */
  raw: string
};

function MarkdownHeading(props: Props) {
  const { classes, component, depth, ...other } = props;

  let Component = component;
  switch (depth) {
    case 1:
      Component = Header1;
      break;
    case 2:
      Component = Header2;
      break;
    case 3:
      Component = Header3;
      break;
    case 4:
      Component = Header4;
      break;
    case 5:
      Component = Header5;
      break;
    case 6:
      Component = Header6;
      break;
    default:
      Component = Header1;
  }

  return (
    <Component
      className={classes.root}
      {...{ ...other, component: `h${depth}` }}
    />
  );
}

MarkdownHeading.displayName = 'MarkdownHeading';
MarkdownHeading.defaultProps = {
  depth: 1
};

export default injectSheet(Style)(MarkdownHeading);
