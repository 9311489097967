// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SmallListItem } from '@catalytic/catalytic-ui';
import taskTransparentIconUrl from '@catalytic/catalytic-icons/lib/icons/task-transparent.svg';

type SkeletonListItemProps = {|
  displayName: string | React.Element<typeof FormattedMessage>
|};

const SkeletonListItem = ({ displayName }: SkeletonListItemProps) => (
  <SmallListItem
    displayName={displayName}
    iconSrc={taskTransparentIconUrl}
    skeleton
  />
);
SkeletonListItem.displayName = 'SkeletonListItem';

export default SkeletonListItem;
