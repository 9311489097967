// @flow

import React, { type Node } from 'react';

type Props = {
  /**
   * Child nodes
   */
  children?: Node
};

export default function MarkdownListItem({ children }: Props): Node {
  return <li>{children}</li>;
}

MarkdownListItem.displayName = 'MarkdownListItem';
