// @flow

import React, { type ComponentType, type Node } from 'react';
import {
  Route,
  type ContextRouter,
  type LocationShape
} from 'react-router-dom';
import { compose, branch, fromRenderProps, renderComponent } from 'recompose';
import { format } from 'url';
import { SessionConsumer } from '../Session/SessionContext';
import { LOGOUT } from '../const/path';

export type AuthenticatedRouteProps = {
  children?: Node,
  component: ComponentType<*>
};

export function AuthenticatedRoute(props: AuthenticatedRouteProps) {
  const { component: Component, ...other } = props;

  return (
    <Route
      render={(props: ContextRouter) => <Component {...props} />}
      {...other}
    />
  );
}

AuthenticatedRoute.displayName = 'AuthenticatedRoute';

const browserRedirect = (authPath: string) => {
  window.location = authPath;
  return null;
};

type RedirectRouteProps = {
  /**
   * Router path to used for authentication
   */
  authPath?: string,
  component: ComponentType<*>,
  location: LocationShape,
  redirect?: (authPath: string) => Node
};

function RedirectRoute(props: RedirectRouteProps) {
  const {
    authPath = LOGOUT,
    component,
    redirect = browserRedirect,
    location,
    ...other
  } = props;

  const referrer = (location.pathname || '') + (location.search || '');

  const authUrl = format({
    pathname: authPath,
    query: {
      referrer
    }
  });

  return (
    <Route {...other} location={location} component={() => redirect(authUrl)} />
  );
}

RedirectRoute.displayName = 'RedirectRoute';

const EnhancedAuthenticatedRoute = compose(
  // TODO: Type the function returned by `fromRenderProps`
  // $FlowIgnore
  fromRenderProps((SessionConsumer: any), ({ isLoggedIn }) => ({
    isLoggedIn
  })),
  branch(({ isLoggedIn }) => !isLoggedIn, renderComponent(RedirectRoute))
)(AuthenticatedRoute);

export default EnhancedAuthenticatedRoute;
