// @flow

type KeyboardEventHandler = (SyntheticKeyboardEvent<any>) => any;
type WhenEnterKey = KeyboardEventHandler => KeyboardEventHandler;
// Conditionally applies a handler function when Enter key is pressed.
// Pair with events like `onKeyDown`.
const whenEnterKey: WhenEnterKey = handler => event =>
  event.keyCode === 13 ? handler(event) : event;

export default whenEnterKey;
