// @flow

import * as React from 'react';
import { Checkbox } from '@atlaskit/checkbox';
import { makeStyles } from '@material-ui/styles';
import compose from 'ramda/src/compose';
import setPath from 'ramda/src/assocPath';
import mixins from '../../style/mixins';
import colors from '../../style/colors';
import useControlled from '../../Hook/useControlled';

const useStyles = makeStyles(theme => ({
  hidden: { display: 'none' },
  label: {
    ...theme.typography.smallText,
    fontSize: '.875rem' // 14px
  }
}));

const HiddenInput = (props: { name: string, value: mixed }) => {
  const classes = useStyles();
  return <input {...props} className={classes.hidden} readOnly />;
};

type CheckboxInputV2Props = {
  'aria-describedby'?: string,
  'aria-labelledby'?: string,
  defaultValue?: boolean,
  disabled?: boolean,
  displayErrors?: boolean,
  hasError?: boolean,
  id?: string,
  label?: string,
  name?: string,
  onChange?: (
    {
      currentTarget: { id?: string, name?: string, value: boolean }
    },
    SyntheticEvent<HTMLInputElement>,
    Object
  ) => mixed,
  readOnly?: boolean,
  required?: boolean,
  value?: boolean
};

const CheckboxInputV2 = ({
  'aria-describedby': ariaDescribedby,
  'aria-labelledby': ariaLabelledby,
  defaultValue = false,
  disabled,
  displayErrors,
  hasError,
  id,
  label,
  name,
  onChange,
  readOnly,
  required,
  value: valueProp
}: CheckboxInputV2Props) => {
  const classes = useStyles();
  const [value, setValueIfUncontrolled] = useControlled({
    value: valueProp,
    defaultValue,
    name: 'CheckboxInputV2'
  });
  const handleChange = React.useCallback(
    (...args: Array<any>) => {
      if (!readOnly) {
        const [event] = args;
        const value = event?.currentTarget?.checked;

        setValueIfUncontrolled(value);

        if (typeof onChange === 'function') {
          onChange(
            {
              currentTarget: { id, name, value }
            },
            ...args
          );
        }
      }
    },
    [id, name, onChange, readOnly, setValueIfUncontrolled]
  );

  return (
    <>
      <Checkbox
        defaultChecked={defaultValue}
        id={id}
        isChecked={value}
        isDisabled={disabled}
        isInvalid={displayErrors && ((required && !value) || hasError)}
        isRequired={required}
        label={
          typeof label === 'string' ? (
            <span className={classes.label}>{label}</span>
          ) : (
            undefined
          )
        }
        onChange={handleChange}
        // Reference: https://atlaskit.atlassian.com/packages/design-system/checkbox/example/theming
        theme={(current, props) => {
          const themeTokens = current(props);
          return compose(
            setPath(['icon', 'borderColor', 'invalid'], colors.error),
            setPath(['icon', 'borderColor', 'invalidAndChecked'], colors.error),
            setPath(['requiredIndicator', 'textColor', 'rest'], colors.error),
            setPath(
              ['label', 'textColor', 'disabled'],
              mixins.inputDisabled.color
            )
          )(themeTokens);
        }}
        value={value}
      />
      {typeof name === 'string' && name.length > 0 && (
        <HiddenInput
          aria-describedby={ariaDescribedby}
          aria-labelledby={ariaLabelledby}
          name={name}
          value={value}
        />
      )}
    </>
  );
};

export default CheckboxInputV2;
