// @flow

import classNames from 'classnames';
import React from 'react';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';

const Style = (theme: ThemeType) => ({
  root: {
    ...theme.mixins.infoBarLabel
  }
});

type Props = InjectSheetProvidedProps & HTMLElement;

const InfoBarItemTitle = ({ classes, className, theme, ...props }: Props) => (
  <span className={classNames(classes.root, className)} {...props} />
);

InfoBarItemTitle.displayName = 'InfoBarItemTitle';

export default injectSheet(Style)(InfoBarItemTitle);
