// @flow

import merge from 'ramda/src/mergeDeepRight';

import * as Search from '../Search/SearchSchema';
import * as Store from '../Store/StoreSchema';
import GraphQLTypeDefs from './GraphQLTypeDefs';

export const { defaults, resolvers, typeDefs } = [Search, Store].reduce(
  (schema, { defaults = {}, resolvers = {}, typeDefs = '' }) => ({
    defaults: merge(schema.defaults, defaults),
    resolvers: merge(schema.resolvers, resolvers),
    typeDefs: `${schema.typeDefs}

${typeDefs}`
  }),
  { defaults: {}, resolvers: {}, typeDefs: GraphQLTypeDefs }
);
