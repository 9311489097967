// @flow

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { ReactComponent as ListUnorderedGlyph } from '@catalytic/catalytic-icons/lib/glyphs/list-unordered.svg';
import { ReactComponent as GridGlyph } from '@catalytic/catalytic-icons/lib/glyphs/grid.svg';
import FilterSortItem from './FilterSortItem';
import { type ThemeType } from '../../style/ThemeTypes';

export const COLLECTION_DISPLAY_MODE = {
  LIST: 'LIST',
  GRID: 'GRID'
};
export type CollectionDisplayMode = $Values<typeof COLLECTION_DISPLAY_MODE>;

const useStyles = makeStyles((theme: ThemeType) => {
  return {
    root: {
      marginBottom: '.5rem',
      [theme.breakpoints.gte.tablet.portrait]: {
        display: 'inline-flex',
        marginBottom: 0,
        marginLeft: 'auto',
        marginRight: 0,
        lineHeight: '2rem' // This line-height should equal the height of the Filter/Sort/Search input element.
      }
    },
    button: {
      marginLeft: '.75rem',
      '&:first-child': {
        marginLeft: 0
      }
    },
    icon: {
      color: theme.colors.pastelGrey,
      fontSize: '1.125rem'
    },
    selectedIcon: {
      color: theme.colors.scienceBlue
    }
  };
});

type Props = {
  onChange?: (mode: CollectionDisplayMode) => void,
  defaultValue?: string
};

const FilterSortCollectionDisplayMode = (props: Props) => {
  const { defaultValue = COLLECTION_DISPLAY_MODE.LIST, onChange } = props;
  const classes = useStyles();

  const setMode = (sortMode: CollectionDisplayMode) => {
    if (typeof onChange === 'function') {
      onChange(sortMode);
    }
  };

  return (
    <FilterSortItem className={classes.root}>
      <button
        className={classes.button}
        onClick={() => setMode(COLLECTION_DISPLAY_MODE.LIST)}
      >
        <ListUnorderedGlyph
          className={classNames(classes.icon, {
            [classes.selectedIcon]:
              defaultValue === COLLECTION_DISPLAY_MODE.LIST
          })}
          data-testid="display-mode-list"
        />
      </button>
      <button
        className={classes.button}
        onClick={() => setMode(COLLECTION_DISPLAY_MODE.GRID)}
      >
        <GridGlyph
          className={classNames(classes.icon, {
            [classes.selectedIcon]:
              defaultValue === COLLECTION_DISPLAY_MODE.GRID
          })}
          data-testid="display-mode-grid"
        />
      </button>
    </FilterSortItem>
  );
};
FilterSortCollectionDisplayMode.displayName = 'FilterSortCollectionDisplayMode';

export default FilterSortCollectionDisplayMode;
