// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CANARY,
  OLIVE,
  DULL_RED,
  CERULEAN,
  MINT,
  PORPOISE,
  PEBBLE_GREY,
  Badge
} from '@catalytic/catalytic-ui';
import { type Status } from './StatusTypes';

export const STATUS = {
  aborted: {
    color: MINT,
    message: (
      <FormattedMessage defaultMessage="Ended" id="task.status.aborted" />
    )
  },
  completed: {
    color: OLIVE,
    message: (
      <FormattedMessage defaultMessage="Completed" id="task.status.completed" />
    )
  },
  failed: {
    color: DULL_RED,
    message: (
      <FormattedMessage defaultMessage="Failed" id="task.status.failed" />
    )
  },
  rejected: {
    color: DULL_RED,
    message: (
      <FormattedMessage defaultMessage="Rejected" id="task.status.rejected" />
    )
  },
  running: {
    color: CANARY,
    message: (
      <FormattedMessage defaultMessage="Assigned" id="task.status.running" />
    )
  },
  skipped: {
    color: MINT,
    message: (
      <FormattedMessage defaultMessage="Skipped" id="task.status.skipped" />
    )
  },
  delayed: {
    color: PORPOISE,
    message: (
      <FormattedMessage defaultMessage="Delayed" id="task.status.delayed" />
    )
  },
  snoozed: {
    color: PORPOISE,
    message: (
      <FormattedMessage defaultMessage="Snoozed" id="task.status.snoozed" />
    )
  },
  waiting: {
    color: CERULEAN,
    message: (
      <FormattedMessage defaultMessage="Pending" id="task.status.waiting" />
    )
  }
};

export const toStatusMessage = (status?: Status) => {
  if (status === undefined) return '';
  return STATUS[status].message || '';
};
export const toStatusColor = (status?: Status) => {
  if (status === undefined) return PEBBLE_GREY;
  return STATUS[status].color || PEBBLE_GREY;
};

export const StatusBadge = ({
  customStatus,
  status,
  ...props
}: {
  customStatus?: string,
  status: Status
}) => {
  return (
    <Badge colorScheme={toStatusColor(status)} {...props}>
      {customStatus || toStatusMessage(status)}
    </Badge>
  );
};

export default StatusBadge;
