// @flow

import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import useWindowSize from 'react-use/lib/useWindowSize';
import Main from '../Layout/Main/Main';
import { ModalViewContext } from '../View/ModalView/ModalView';
import { devices } from '../style/breakpoints';
import { type ThemeType } from '../style/ThemeTypes';

const useStyles = makeStyles((theme: ThemeType) => {
  return {
    container: {
      marginTop: '1rem'
    },
    root: {
      position: 'relative',
      top: theme.variables.navHeight,
      height: ({ modalViewContext }) =>
        modalViewContext
          ? `calc(100vh - ${theme.functions.toRem(
              theme.variables.modalTop +
                theme.variables.modalMobilePadding +
                theme.variables.button
            )})`
          : `calc(100vh - ${theme.functions.toRem(theme.variables.navHeight)})`,
      [theme.breakpoints.desktop]: {
        height: ({ modalViewContext }) =>
          modalViewContext
            ? `calc(100vh - ${theme.functions.toRem(
                theme.variables.modalTop +
                  theme.variables.modalDesktopPadding +
                  theme.variables.button
              )})`
            : `calc(100vh - ${theme.functions.toRem(
                theme.variables.navHeight
              )})`
      }
    },
    main: {
      position: 'relative',
      paddingTop: 0,
      paddingBottom: 0
    }
  };
});

type Props = {
  children: React.Node
};

export const MasterDetailContent = ({ children }: Props) => {
  const modalViewContext = React.useContext(ModalViewContext);
  const classes = useStyles({ modalViewContext });
  const { width } = useWindowSize();
  const shouldRenderMasterDetailRoot = width > devices.mobile.landscape;

  if (!shouldRenderMasterDetailRoot) {
    return (
      <Main>
        <div className={classes.container}>{children}</div>
      </Main>
    );
  }

  return (
    <div className={classes.root} data-testid="master-detail-content-root">
      <Main className={classes.main}>{children}</Main>
    </div>
  );
};
MasterDetailContent.displayName = 'MasterDetailContent';

export default MasterDetailContent;
