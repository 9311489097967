import { branch, compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import Rpath from 'ramda/src/path';

export const withRouteParam = param => {
  return withProps(props => {
    return {
      [param]: Rpath(['match', 'params', param])(props)
    };
  });
};

export const withIDParam = compose(withRouter, withRouteParam('id'));

export const composeIfHasID = (...funcs) =>
  compose(
    withIDParam,
    branch(({ id }) => !!id, compose(...funcs))
  );
