// @flow

const HANDLEBAR_PATTERN = '{{([a-zA-Z0-9-_.]+)}}';

export const isHandlebar = (v: string) =>
  new RegExp(`^${HANDLEBAR_PATTERN}$`, 'i').test(v);
export const includesHandlebar = (v: string) =>
  new RegExp(HANDLEBAR_PATTERN, 'i').test(v);
export const parseHandlebarReference = (v: string): string => {
  if (!isHandlebar(v)) {
    return v;
  }
  const matches = v.match(new RegExp(HANDLEBAR_PATTERN)) || [];
  return matches[1];
};
