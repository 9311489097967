// @flow

import pbx from '@catalytic/pushbot-expression';
import { List, Map } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  EXPRESSION_TYPE,
  STATEMENT_OPERATOR,
  type AddressedStatement,
  type ExpressionType,
  type DeconstructedStatement,
  type StatementMap
} from './ConditionTypes';

const createOr = () => Map({ and: List() });

const createAnd = (() => {
  let next = 0;
  return value => Map({ value, id: ++next });
})();

// Parses expressions without throwing errors
const parse = value => {
  try {
    return pbx.parse(value);
  } catch (e) {}
};

// Ignore Visitor
function ignore(path) {
  this.traverse(path);
}

type Node = any;

const expressionToNode = (expression: string): Node =>
  typeof expression === 'string' ? parse(expression) : expression;

const nodeToStatementMap = (node: Node): StatementMap => {
  let statementMap = Map({ or: List() });

  const ors = [createOr()];

  const inOr = (path, fn) => {
    const parentOr = pbx.types.namedTypes.PBXLogicalOrExpression.check(
      path.parentPath.node
    );
    if (parentOr) ors.push(createOr());
    fn();
    if (parentOr) {
      const nextOr = ors.pop();
      if (nextOr.get('and').size === 0) return;
      statementMap = statementMap.update('or', or => or.push(nextOr));
    }
  };

  function push(path) {
    inOr(path, () => {
      const or = ors.pop();
      ors.push(
        or.update('and', and => and.push(createAnd(pbx.print(path.node).code)))
      );
    });
    return false;
  }

  pbx.types.visit(node, {
    visitPBXBinaryExpression: push,
    visitPBXExpressionStatement: ignore,
    visitPBXFunctionExpression: push,
    visitPBXIdentifier: push,
    visitPBXLiteral: push,
    visitPBXLogicalAndExpression(path) {
      inOr(path, () => this.traverse(path));
    },
    visitPBXLogicalOrExpression(path) {
      inOr(path, () => this.traverse(path));
    },
    visitPBXMemberExpression: push,
    visitPBXProgram: ignore,
    visitPBXUnaryFunctionExpression: push,
    visitPBXUnaryNotExpression: push,
    visitPBXUnarySignExpression: push
  });

  const nextOr = ors.pop();

  if (nextOr.get('and').size !== 0) {
    statementMap = statementMap.update('or', or => or.push(nextOr));
  }

  return statementMap;
};

export const expressionToStatementMap = (expression: string): StatementMap =>
  nodeToStatementMap(expressionToNode(expression));

export const statementMapToExpression = (
  statementMap: StatementMap
): string => {
  const addressedStatements = statementMapToFlattenedStatements(statementMap);
  const node = flattenedStatementsToNode(
    addressedStatements.map(s => ({
      value: s.value,
      operator: s.isLastAnd ? STATEMENT_OPERATOR.OR : STATEMENT_OPERATOR.AND
    }))
  );
  return node !== undefined ? pbx.print(node).code : '';
};

export const deconstructedStatementToExpression = ({
  type,
  primary,
  secondary,
  tertiary
}: {
  type: ExpressionType,
  primary: string,
  secondary: string,
  tertiary: string
}): string => {
  let expression;
  switch (type) {
    case EXPRESSION_TYPE.BINARY:
      expression = `${primary} ${secondary} ${tertiary}`;
      break;
    case EXPRESSION_TYPE.SIMPLE:
      expression = primary;
      break;
    case EXPRESSION_TYPE.IS:
      expression = `${secondary}(${primary})`;
      break;
    case EXPRESSION_TYPE.FUNCTION:
      expression = `${secondary}(${primary}, ${tertiary})`;
      break;
    default:
      expression = `${primary} ${secondary} ${tertiary}`;
  }
  return expression.replace(/\\/g, '\\\\');
};

export const expressionToDeconstructedStatement = (expression: string) => {
  const node = typeof expression === 'string' ? parse(expression) : expression;

  let statementMap: Map<string, Object> = Map({});

  const update = (key, value) => {
    if (value === undefined) return;
    statementMap = statementMap.set(
      key,
      (typeof value === 'string' ? value : pbx.print(value).code).replace(
        /\\\\/g,
        '\\'
      )
    );
  };

  if (!node) {
    update('type', EXPRESSION_TYPE.SIMPLE);
    update('primary', expression);
    return statementMap;
  }

  const pushFields = (type, primary, secondary, tertiary) =>
    function(path) {
      update('type', type);
      update('primary', path.node[primary]);
      update('secondary', path.node[secondary]);
      update('tertiary', path.node[tertiary]);
      return false;
    };

  const pushBinary = pushFields(
    EXPRESSION_TYPE.BINARY,
    'left',
    'operator',
    'right'
  );
  const pushExpression = pushFields(
    EXPRESSION_TYPE.FUNCTION,
    'left',
    'operator',
    'right'
  );
  const pushUnaryFunctionExpression = pushFields(
    EXPRESSION_TYPE.IS,
    'argument',
    'operator'
  );

  function pushUnaryNotExpression(path) {
    this.traverse(path);
    update(
      'secondary',
      `${path.node.operator}${statementMap.get('secondary')}`
    );
    return false;
  }

  function push(path) {
    update('type', EXPRESSION_TYPE.SIMPLE);
    update('primary', path.node);
    return false;
  }

  pbx.types.visit(node, {
    visitPBXBinaryExpression: pushBinary,
    visitPBXExpressionStatement: ignore,
    visitPBXFunctionExpression: pushExpression,
    visitPBXIdentifier: push,
    visitPBXLiteral: push,
    visitPBXLogicalAndExpression: ignore,
    visitPBXLogicalOrExpression: ignore,
    visitPBXMemberExpression: push,
    visitPBXProgram: ignore,
    visitPBXUnaryFunctionExpression: pushUnaryFunctionExpression,
    visitPBXUnaryNotExpression: pushUnaryNotExpression,
    visitPBXUnarySignExpression: push
  });

  return statementMap;
};

export const statementMapToFlattenedStatements = (
  statementMap: StatementMap
): Array<AddressedStatement> => {
  return statementMap.toJS().or.reduce(
    (o, { and = [] } = {}, i, or) => [
      ...o,
      ...and.map(({ value, id }, j, and) => ({
        isFirst: i === 0 && j === 0,
        id,
        or: i,
        and: j,
        isLastAnd: j === and.length - 1,
        isOnly: or.length * and.length === 1,
        value
      }))
    ],
    []
  );
};

const flattenedStatementsToNode = (
  flattenedStatements: Array<{ value: string, operator?: string }>
): Node => {
  let pointer = 0;
  const combinedContext = {
    or: []
  };
  flattenedStatements.forEach((statement, index) => {
    if (index === 0) {
      combinedContext.or.push({ and: [{ value: statement.value }] });
    } else {
      const lastStatement = flattenedStatements[index - 1];
      const lastOperator = lastStatement.operator;
      if (lastOperator === STATEMENT_OPERATOR.AND) {
        combinedContext.or[pointer].and.push({ value: statement.value });
      } else {
        pointer++;
        combinedContext.or.push({ and: [{ value: statement.value }] });
      }
    }
  });
  const expression = combinedContext.or
    .map(({ and }) =>
      and
        .filter(({ value }) => !isNil(value))
        .map(({ value }) => value)
        .join(` ${STATEMENT_OPERATOR.AND} `)
    )
    .filter(v => !isEmpty(v))
    .join(` ${STATEMENT_OPERATOR.OR} `);
  return expressionToNode(expression);
};

export const flattenedStatementsToStatementMap = (
  flattenedStatements: Array<{ value: string, operator?: string }>
): StatementMap => {
  const node = flattenedStatementsToNode(flattenedStatements);
  return node !== undefined ? nodeToStatementMap(node) : Map({ or: List() });
};

export const inferStatementType = (
  statement: DeconstructedStatement
): ExpressionType => {
  const { primary, secondary } = statement;
  const tertiary = 'true';
  const types = Object.keys(EXPRESSION_TYPE);
  let k;
  let next;

  const deReStatement = statement =>
    expressionToDeconstructedStatement(
      deconstructedStatementToExpression(statement)
    );

  while ((k = types.shift())) {
    next = deReStatement({
      type: EXPRESSION_TYPE[k],
      primary,
      secondary,
      tertiary
    }).get('type');
    if (next !== EXPRESSION_TYPE.SIMPLE) return (next: any);
  }

  return EXPRESSION_TYPE.SIMPLE;
};

export const removeExpressionFromStatementMap = (
  statementMap: StatementMap,
  i: number,
  j: number
): StatementMap => {
  let orStatements = statementMap.get('or');
  const or = orStatements.get(i) || createOr();
  let and = or.get('and');
  and = and.splice(j, 1);
  orStatements =
    and.size !== 0
      ? orStatements.splice(i, 1, or.set('and', and))
      : orStatements.splice(i, 1);
  return statementMap.set('or', orStatements);
};

export const spliceExpression = (
  statementMap: StatementMap,
  i: number,
  j: number,
  offset: number = 0,
  expression?: string
): StatementMap => {
  let orStatements = statementMap.get('or');
  const or = orStatements.get(i) || createOr();
  let and = or.get('and');
  and = and.splice(j, offset, createAnd(expression));
  orStatements =
    and.size !== 0
      ? orStatements.splice(i, 1, or.set('and', and))
      : orStatements.splice(i, 1);

  return statementMap.set('or', orStatements);
};

export const updateStatementMap = (
  statementMap: StatementMap,
  expression: string,
  i: number,
  j: number
): StatementMap => {
  let orStatements = statementMap.get('or');
  const or = orStatements.get(i) || createOr();
  const and = or.get('and');
  orStatements = orStatements.splice(
    i,
    1,
    or.set('and', and.splice(j, 1, and.get(j).set('value', expression)))
  );
  return statementMap.set('or', orStatements);
};

const validateExpression = (expression: string): Array<string> =>
  (pbx.parse(expression, { tolerant: true }).errors || []).map(e => e.message);

export const validateFlattenedStatements = (
  statements: Array<{ value: string, operator?: string }>
): Array<string> => {
  return [].concat(...statements.map(s => validateExpression(s.value)));
};

export const expressionTypeUsesSecondTerm = (type: ExpressionType): boolean =>
  type === EXPRESSION_TYPE.BINARY || type === EXPRESSION_TYPE.FUNCTION;
