// @flow

import * as React from 'react';
import { type Step } from './StepTypes';
import { type Field, type FieldContext } from '../Field/FieldTypes';

type Context = {|
  availableFields: Array<Field>,
  fieldsByContext: Array<FieldContext>,
  currentStepPath: string,
  fields: Array<Field>,
  step: Step,
  steps: Array<Step>
|};

type Props = {|
  children: React.Node,
  value: Context
|};

const StepContext = React.createContext<Context | void>();

const useStepContext = () => React.useContext(StepContext);

export const StepProvider = ({ children, value }: Props) => (
  <StepContext.Provider value={value}>{children}</StepContext.Provider>
);

export const StepConsumer = StepContext.Consumer;

export default useStepContext;
