// @flow

export const ABORTED: 'aborted' = 'aborted';
export const COMPLETED: 'completed' = 'completed';
export const DELAYED: 'delayed' = 'delayed';
export const FAILED: 'failed' = 'failed';
export const REJECTED: 'rejected' = 'rejected';
export const RUNNING: 'running' = 'running';
export const SKIPPED: 'skipped' = 'skipped';
export const SNOOZED: 'snoozed' = 'snoozed';
export const WAITING: 'waiting' = 'waiting';

export type Status =
  | typeof ABORTED
  | typeof COMPLETED
  | typeof FAILED
  | typeof REJECTED
  | typeof RUNNING
  | typeof SNOOZED
  | typeof DELAYED
  | typeof WAITING
  | typeof SKIPPED;

export type CompletedStatus =
  | typeof ABORTED
  | typeof COMPLETED
  | typeof FAILED
  | typeof SKIPPED
  | typeof REJECTED;

export const STATUSES: Array<Status> = [
  ABORTED,
  COMPLETED,
  DELAYED,
  FAILED,
  REJECTED,
  RUNNING,
  SKIPPED,
  SNOOZED,
  WAITING
];

export const COMPLETED_STATUS: Array<CompletedStatus> = [
  ABORTED,
  COMPLETED,
  FAILED,
  SKIPPED,
  REJECTED
];

export const DELAYED_STATUS: Array<typeof DELAYED> = [DELAYED];
export const WAITING_STATUS: Array<typeof WAITING> = [WAITING];
export const IN_PROGRESS_STATUS: Array<typeof RUNNING> = [RUNNING];

export const hasStatus = (status?: Status) =>
  typeof status === 'string' && status.trim().length !== 0;
export const isCompleted = (status?: Status) =>
  hasStatus(status) && COMPLETED_STATUS.indexOf(status) !== -1;
export const isDelayed = (status?: Status) =>
  hasStatus(status) && DELAYED_STATUS.indexOf(status) !== -1;
export const isInProgress = (status?: Status) =>
  hasStatus(status) && IN_PROGRESS_STATUS.indexOf(status) !== -1;
export const isSnoozed = (status?: Status) =>
  hasStatus(status) && status === SNOOZED;
export const isWaiting = (status?: Status) =>
  hasStatus(status) && WAITING_STATUS.indexOf(status) !== -1;
