// @flow

import * as React from 'react';
import { useIntl } from 'react-intl';
import { Input, MultiSelectInput } from '@catalytic/catalytic-ui';
import { statementOperatorMessages } from './ConditionMessages';
import { type StatementOperator } from './ConditionTypes';

type StepConditionStatementOperatorProps = {
  className?: string,
  disabled: boolean,
  onChange: StatementOperator => void,
  value: StatementOperator,
  visible: boolean
};

export function StepConditionStatementOperator(
  props: StepConditionStatementOperatorProps
) {
  const { className, disabled, onChange, value, visible } = props;
  const intl = useIntl();
  if (!visible) {
    return <div />; // Placeholder node to preserve alignment
  }

  const options = Object.entries(statementOperatorMessages).map(
    ([key, value]) => ({
      value: key,
      label: intl.formatMessage(value)
    })
  );
  return (
    <Input className={className} data-testid="condition-statement-operator">
      <MultiSelectInput
        data-testid="condition-statement-operator"
        defaultValue={options.find(o => o.value === value)}
        disabled={disabled}
        embedded
        isClearable={false}
        onChange={e => {
          onChange(e.currentTarget.value[0]);
        }}
        options={options}
      />
    </Input>
  );
}
StepConditionStatementOperator.displayName = 'StepConditionStatementOperator';

export default StepConditionStatementOperator;
