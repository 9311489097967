// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CANARY, OLIVE, DULL_RED, Badge } from '@catalytic/catalytic-ui';
import { type Process } from './ProcessTypes';
import { isWorkflowApprovalPending } from './ProcessUtils';

export const StatusBadge = ({ process }: { process: Process }): React.Node => {
  const isPublishedVersion =
    process?.isActive || process?.id === process?.ancestorID;
  const isArchived = process?.isArchived;
  const isVersionApprovalPending = isWorkflowApprovalPending(process);
  const colorScheme = React.useMemo(() => {
    if (isArchived) {
      return DULL_RED;
    }

    return isPublishedVersion ? OLIVE : CANARY;
  }, [isPublishedVersion, isArchived]);

  return (
    <Badge compact colorScheme={colorScheme} data-testid="process-status-badge">
      {isArchived && !isVersionApprovalPending && (
        <FormattedMessage
          id="process.status.badge.archived"
          defaultMessage="Archived"
        />
      )}
      {isPublishedVersion && !isArchived && (
        <FormattedMessage
          id="process.status.badge.published"
          defaultMessage="Published"
        />
      )}
      {isVersionApprovalPending && (
        <FormattedMessage
          id="process.status.badge.pending"
          defaultMessage="Pending"
        />
      )}
      {!isPublishedVersion && !isVersionApprovalPending && !isArchived && (
        <FormattedMessage
          id="process.status.badge.draft"
          defaultMessage="Draft"
        />
      )}
    </Badge>
  );
};

export default StatusBadge;
