// @flow

import gql from 'graphql-tag';

export const PredictiveModelFragment = gql`
  fragment PredictiveModelFragment on PredictiveModel {
    displayName
    id
    output
    table {
      node {
        displayName
        id
      }
    }
    training {
      confidence
      id
      inputs
      status
    }
  }
`;

export const PredictiveModelQuery = gql`
  ${PredictiveModelFragment}

  query PredictiveModelQuery($id: ID!) {
    predictiveModel(id: $id) {
      ...PredictiveModelFragment
      id
    }
  }
`;

export const PredictiveModelsQuery = gql`
  ${PredictiveModelFragment}

  query PredictiveModelsQuery($after: String, $first: Int = 100) {
    search_v2: predictiveModels(after: $after, first: $first) {
      nodes {
        ...PredictiveModelFragment
        id
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

export const CreatePredictiveModelMutation = gql`
  ${PredictiveModelFragment}

  mutation CreatePredictiveModelMutation($input: CreatePredictiveModelInput!) {
    createPredictiveModel(input: $input) {
      ...PredictiveModelFragment
      id
    }
  }
`;

export const RemovePredictiveModelMutation = gql`
  ${PredictiveModelFragment}

  mutation RemovePredictiveModelMutation($id: ID!) {
    removePredictiveModel(id: $id) {
      ...PredictiveModelFragment
      id
    }
  }
`;

export const RemoveFieldPredictiveModelMutation = gql`
  ${PredictiveModelFragment}

  mutation RemoveFieldPredictiveModelMutation($fieldID: ID!) {
    removeFieldPredictiveModel(field: $fieldID) {
      ...PredictiveModelFragment
      id
    }
  }
`;
