// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import MultiSelectInput, {
  type OptionsType,
  type ValueType
} from '../../Input/MultiSelectInput/MultiSelectInput';
import { type ThemeType } from '../../style/ThemeTypes';

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    marginBottom: '.5rem',
    [theme.breakpoints.gte.tablet.portrait]: {
      display: 'inline-block',
      marginRight: '.75rem',
      marginBottom: 0
    }
  },
  label: {
    ...theme.typography.smallText,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.75rem',
    padding: '1rem .75rem .5rem',
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
    [theme.breakpoints.gte.tablet.portrait]: {
      ...theme.mixins.infoBarLabel,
      borderBottom: 'none',
      padding: 0
    }
  },
  input: {
    ...theme.mixins.inputResponsiveDropDown
  }
}));

type Props = {
  'data-testid'?: string,
  children?: React.Node,
  className?: string,
  disabled?: boolean,
  label?: string | React.Element<typeof FormattedMessage>,
  onChange?: (
    {
      currentTarget: {
        id?: string,
        label: React.Node,
        name?: string,
        value: ValueType
      }
    },
    ValueType,
    OptionsType
  ) => mixed,
  options?: OptionsType,
  placeholder?: string,
  value?: ValueType
};

const FilterSortItem = ({
  'data-testid': dataTestId,
  children,
  className,
  disabled,
  label,
  onChange,
  options,
  placeholder,
  value
}: Props) => {
  const classes = useStyles();
  const id =
    label && typeof label === 'string'
      ? `filter-sort-item-${label}`
      : undefined;

  return (
    <div className={classNames(classes.root, className)}>
      {label && (
        <label className={classes.label} id={id}>
          {label}
        </label>
      )}
      {children ? (
        React.Children.map(children, child => {
          return React.isValidElement(child)
            ? React.cloneElement(child, {
                'aria-labelledby': id,
                className: classNames(classes.input, child.props.className)
              })
            : child;
        })
      ) : (
        <MultiSelectInput
          aria-labelledby={id}
          className={classes.input}
          data-testid={dataTestId}
          disabled={disabled}
          embedded
          isClearable={false}
          isSearchable={false}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          value={value}
        />
      )}
    </div>
  );
};
FilterSortItem.displayName = 'FilterSortItem';

export default FilterSortItem;
