// @flow

import { JSONRefType } from '@catalytic/json-schema-validator-catalytic-web';
import { NodeType } from '@catalytic/view';
import {
  ACTOR as ACTOR_LABEL,
  CHECKBOX as CHECKBOX_LABEL,
  CODE as CODE_LABEL,
  CONTEXT as CONTEXT_LABEL,
  CONTEXT_TYPE as CONTEXT_TYPE_LABEL,
  DATE as DATE_LABEL,
  DATE_TIME as DATE_TIME_LABEL,
  EMAIL as EMAIL_LABEL,
  FILE as FILE_LABEL,
  FILE_TABLE as FILE_TABLE_LABEL,
  GROUP as GROUP_LABEL,
  INSTRUCTIONS as INSTRUCTIONS_LABEL,
  INTEGER as INTEGER_LABEL,
  MULTIPLE_SELECTION as MULTIPLE_SELECTION_LABEL,
  NUMBER as NUMBER_LABEL,
  PASSWORD as PASSWORD_LABEL,
  REFERENCE as REFERENCE_LABEL,
  SELECTION as SELECTION_LABEL,
  TABLE as TABLE_LABEL,
  TEXT as TEXT_LABEL,
  TEXT_AREA as TEXT_AREA_LABEL,
  TOGGLE_SWITCH as TOGGLE_SWITCH_LABEL
} from '../const/label';

const getViewNodeTypeLabel = (
  type: string,
  refType?: string,
  displayOnly?: boolean
): string => {
  if (type === NodeType.REFERENCE_VIEW && refType) {
    return (
      {
        [JSONRefType.ACTOR]: ACTOR_LABEL,
        [JSONRefType.CONTEXT]: CONTEXT_LABEL,
        [JSONRefType.CONTEXT_TYPE]: CONTEXT_TYPE_LABEL,
        [JSONRefType.DATA_TABLE]: TABLE_LABEL,
        [JSONRefType.FILE]: FILE_LABEL
      }[refType] || TEXT_LABEL
    );
  }

  if (type === NodeType.TEXT_AREA_VIEW) {
    return displayOnly ? INSTRUCTIONS_LABEL : TEXT_AREA_LABEL;
  }

  return (
    {
      [NodeType.CHECKBOX_VIEW]: CHECKBOX_LABEL,
      [NodeType.CODE_VIEW]: CODE_LABEL,
      [NodeType.DATE_TIME_VIEW]: DATE_TIME_LABEL,
      [NodeType.DATE_VIEW]: DATE_LABEL,
      [NodeType.EMAIL_VIEW]: EMAIL_LABEL,
      [NodeType.FILE_REFERENCE_VIEW]: FILE_LABEL,
      [NodeType.FILE_TABLE_REFERENCE_VIEW]: FILE_TABLE_LABEL,
      [NodeType.GROUP_VIEW]: GROUP_LABEL,
      [NodeType.INTEGER_VIEW]: INTEGER_LABEL,
      [NodeType.MULTIPLE_SELECTION_VIEW]: MULTIPLE_SELECTION_LABEL,
      [NodeType.NUMBER_VIEW]: NUMBER_LABEL,
      [NodeType.PASSWORD_VIEW]: PASSWORD_LABEL,
      [NodeType.REFERENCE_VIEW]: REFERENCE_LABEL,
      [NodeType.SELECTION_VIEW]: SELECTION_LABEL,
      [NodeType.TABLE_VIEW]: TABLE_LABEL,
      [NodeType.TEXT_AREA_VIEW]: TEXT_AREA_LABEL,
      [NodeType.TEXT_VIEW]: TEXT_LABEL,
      [NodeType.TOGGLE_SWITCH_VIEW]: TOGGLE_SWITCH_LABEL
    }[type] || TEXT_LABEL
  );
};

export default getViewNodeTypeLabel;
