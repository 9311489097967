// @flow

/* eslint-disable react/display-name */

import * as React from 'react';
import { useIntl } from 'react-intl';
import activeElement from 'dom-helpers/activeElement';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import type { ThemeType } from '../../style/ThemeTypes';
import InputRequired from '../InputRequired/InputRequired';
import useControlled from '../../Hook/useControlled';
import omit from 'ramda/src/omit';
import { useForkedRef } from '../../utils/ref';

type NumberValue = '' | number;

export type NumberInputProps = {
  autoComplete?: string,
  children?: React.Node,
  displayErrors?: boolean,
  embedded?: boolean,
  // Presentational Intl format options object
  format?: Object,
  hasError?: boolean,
  hasRequired?: boolean,
  hasSuccess?: boolean,
  hasValidation?: boolean,
  inputClassName?: string,
  inputRef?: React.ElementRef<*>,
  disabled?: boolean,
  readOnly?: boolean,
  onBlur?: ({
    currentTarget: { id: string, name: string, value: NumberValue }
  }) => mixed,
  onChange?: ({
    currentTarget: { id: string, name: string, value: NumberValue }
  }) => mixed,
  onFocus?: ({
    currentTarget: { id: string, name: string, value: NumberValue }
  }) => mixed,
  onWheel?: ({
    currentTarget: { id: string, name: string, value: NumberValue }
  }) => mixed,
  setDisplayErrorsState?: (displayErrors: boolean) => mixed,
  setLoadingState?: (loading: boolean) => mixed
};

export type NumberInputType = $Diff<HTMLInputElement, NumberInputProps> &
  NumberInputProps;

export const toNumber = (value: string): NumberValue => {
  return value === '' ? value : Number(value);
};

const toEventShape = (
  event: SyntheticEvent<HTMLInputElement>,
  props: Object
) => ({
  currentTarget: {
    id: props.id,
    name: props.name,
    value: toNumber(event.currentTarget.value)
  }
});

const useStyles = makeStyles((theme: ThemeType) => {
  const editInput = {
    ...theme.mixins.inputStyle,
    ...theme.mixins.truncate,
    '&:required:invalid': {
      paddingRight: theme.variables.inputRequiredPaddingRight
    },
    '&:focus:required:invalid, &:required:valid': {
      ...theme.mixins.inputPadding,
      '& ~ $required': {
        display: 'none'
      }
    },
    /// https://css-tricks.com/numeric-inputs-a-comparison-of-browser-defaults/
    // Remove controls from Safari and Chrome
    '&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0 // Removes leftover margin
    },
    // Remove controls from Firefox
    '&[type=number]': {
      '-moz-appearance': 'textfield'
    },
    readOnly: {
      '&[readonly]': {
        '&, &:focus, &:hover': theme.mixins.inputReadOnly
      }
    },
    disabled: {
      '&:disabled': {
        '&, &:focus, &:hover': theme.mixins.inputDisabled
      }
    }
  };
  const viewInput = omit(['&[readonly]'])(editInput);
  return {
    root: {
      position: 'relative'
    },
    hidden: { display: 'none' },
    editInput,
    viewInput,
    displayErrors: {
      '&:valid': theme.mixins.inputSuccess,
      '&:invalid': theme.mixins.inputError
    },
    success: theme.mixins.inputSuccess,
    error: theme.mixins.inputError,
    required: {}
  };
});

const NumberInput = function NumberInput({
  defaultValue,
  value: valueProp,
  autoComplete = 'off',
  disabled = false,
  displayErrors = false,
  embedded = false,
  format = null,
  hasError = false,
  hasRequired = true,
  hasSuccess = false,
  readOnly = false,
  required = false,
  className,
  customValidityPattern,
  hasValidation,
  inputClassName: inputClassNameProp,
  inputRef: inputRefProp,
  name,
  onBlur,
  onChange,
  onFocus,
  onWheel,
  placeholder,
  ref: unusedRefProp,
  setDisplayErrorsState,
  setLoadingState,
  theme,
  title,
  type,
  ...props
}: NumberInputType) {
  /*
   * State Managment
   */
  const [userIntent, setUserIntent] = React.useState<'editing' | 'viewing'>(
    'viewing'
  );
  const [value, setValueIfUncontrolled] = useControlled({
    value: valueProp,
    defaultValue,
    name: 'NumberInput'
  });

  /*
   * Style and Intl
   */
  const intl = useIntl();
  const classes = useStyles();
  const editInputClassName = classNames(
    classes.editInput,
    {
      [classes.displayErrors]: displayErrors,
      [classes.error]: hasError,
      [classes.success]: hasSuccess,
      [classes.disabled]: disabled,
      [classes.readOnly]: readOnly
    },
    inputClassNameProp
  );
  const viewInputClassName = classNames(
    classes.viewInput,
    {
      [classes.displayErrors]: displayErrors,
      [classes.error]: hasError,
      [classes.success]: hasSuccess,
      [classes.disabled]: disabled,
      [classes.readOnly]: readOnly
    },
    inputClassNameProp
  );

  /*
   * Event Handlers
   */
  const handleBlur = React.useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      setUserIntent('viewing');
      if (typeof onBlur === 'function')
        onBlur(toEventShape(event, { id: props.id, name }));
    },
    [onBlur, props.id, name]
  );
  const handleFocus = React.useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      if (!readOnly) setUserIntent('editing');
      if (typeof onFocus === 'function')
        onFocus(toEventShape(event, { id: props.id, name }));
    },
    [onFocus, props.id, name, readOnly]
  );
  const handleChange = React.useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      setValueIfUncontrolled(event.currentTarget.value);
      if (typeof onChange === 'function')
        onChange(toEventShape(event, { id: props.id, name }));
    },
    [onChange, setValueIfUncontrolled, props.id, name]
  );
  const handleWheel = React.useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      if (event.currentTarget === activeElement()) {
        event.currentTarget.blur();
      }

      if (typeof onWheel === 'function') {
        return onWheel(toEventShape(event, { id: props.id, name }));
      }
    },
    [onWheel, props.id, name]
  );

  /*
   * Effects
   */
  const editInputRef = React.useCallback(
    editInput => {
      if (
        userIntent === 'editing' &&
        editInput != null &&
        editInput !== activeElement()
      )
        editInput.focus();
    },
    [userIntent]
  );
  const ref = useForkedRef(inputRefProp, editInputRef);

  return (
    <div className={classNames(classes.root, className)} title={title}>
      <input
        autoComplete={autoComplete}
        ref={ref}
        className={classNames(editInputClassName, {
          [classes.hidden]: userIntent !== 'editing'
        })}
        data-name={name}
        data-testid="number-input"
        disabled={disabled || readOnly}
        key="edit"
        onBlur={handleBlur}
        onChange={handleChange}
        onWheel={handleWheel}
        placeholder={placeholder}
        type="number"
        value={value ?? ''}
        name={name}
        readOnly={readOnly}
        required={required}
        {...props}
      />
      {userIntent === 'viewing' && (
        <input
          type="text"
          className={viewInputClassName}
          readOnly={readOnly}
          disabled={disabled}
          onFocus={handleFocus}
          key="view"
          placeholder={placeholder}
          defaultValue={
            (typeof value === 'string' && value.length > 0) ||
            typeof value === 'number'
              ? format != null && Object.keys(format).length > 0
                ? intl.formatNumber(Number(value), format)
                : value
              : ''
          }
          required={required}
        />
      )}
      <InputRequired
        className={classes.required}
        {...{ disabled, embedded, hasRequired, readOnly, required }}
      />
    </div>
  );
};

export default NumberInput;
