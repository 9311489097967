// @flow

import React from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import {
  injectSheet,
  type InjectSheetProvidedProps,
  SmallText,
  type ThemeType
} from '@catalytic/catalytic-ui';

import SearchTypeIcon from './SearchTypeIcon';
import SearchTypeTitle from './SearchTypeTitle';
import { type Type } from './SearchTypes';

type Props = InjectSheetProvidedProps & { intl: Object, type: Type };

const Style = (theme: ThemeType) => ({
  root: {
    background: theme.colors.ghostWhite,
    border: `1px solid ${theme.colors.lightGrey}`,
    borderRadius: '0.25rem',
    display: 'inline-block',
    padding: '0.4rem 0.5rem'
  },
  icon: {
    marginRight: '0.325em'
  }
});

const SearchCategoryChip = ({
  classes,
  className,
  intl,
  theme,
  type,
  ...props
}: Props) => (
  <div {...props} className={classNames(classes.root, className)}>
    <SearchTypeIcon className={classes.icon} type={type} />
    <SmallText>
      <SearchTypeTitle type={type} />
    </SmallText>
  </div>
);

SearchCategoryChip.displayName = 'SearchCategoryChip';

export default compose(injectIntl, injectSheet(Style))(SearchCategoryChip);
