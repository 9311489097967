// @flow
import Blob from './blob'; // eslint-disable-line no-unused-vars
import File from './file'; // eslint-disable-line no-unused-vars
import FormData, { FormDataPolyfill } from './formdata'; // eslint-disable-line no-unused-vars

if (window.FormData === FormDataPolyfill) {
  const _send = window.XMLHttpRequest && window.XMLHttpRequest.prototype.send;
  const _fetch = window.Request && window.fetch;

  if (_send) {
    window.XMLHttpRequest.prototype.send = function(data) {
      if (data instanceof FormDataPolyfill) {
        const blob = FormDataPolyfill.blob(data);
        this.setRequestHeader('Content-Type', blob.type);
        _send.call(this, blob);
      } else {
        _send.call(this, data);
      }
    };
  }

  if (_fetch) {
    const _fetch = window.fetch;

    window.fetch = function(input, init) {
      if (init && init.body && init.body instanceof FormDataPolyfill) {
        init.body = FormDataPolyfill.blob(init.body);
      }

      return _fetch(input, init);
    };
  }
}

export default window.FormData;
