// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SmallListItem } from '@catalytic/catalytic-ui';
import taskTransparentIconUrl from '@catalytic/catalytic-icons/lib/icons/task-transparent.svg';

type ListItemProps = {|
  backgroundColor: string,
  description: string | React.Element<typeof FormattedMessage>,
  displayName: string | React.Element<typeof FormattedMessage>,
  read: boolean
|};

export const ListItem = ({
  backgroundColor,
  description,
  displayName,
  read
}: ListItemProps) => (
  <SmallListItem
    backgroundColor={backgroundColor}
    description={description}
    displayName={displayName}
    iconSrc={taskTransparentIconUrl}
    unread={!read}
  />
);
ListItem.displayName = 'ListItem';

export default ListItem;
