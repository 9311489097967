// @flow

import * as React from 'react';
import { branch, compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import {
  AsyncPaginate,
  SmallText,
  type OptionType,
  type ValueType
} from '@catalytic/catalytic-ui';
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { type Process } from './ProcessTypes';
import { fieldValueIsEmpty } from '../Field/FieldHelpers';
import { WAIT as DEBOUNCE_WAIT } from '../const/debounce';
import selectQueryPolicies from '../const/selectQueryPolicies';

const ProcessItemFragment = gql`
  fragment ProcessItemFragment on ContextType {
    ancestorID
    displayName
    id
    isActive
    workspaceID
  }
`;

export const ProcessQuery = gql`
  ${ProcessItemFragment}
  query Process($query: String, $after: String) {
    search_v2(
      filter: [
        { property: ARCHIVED, type: CONTEXT_TYPE, operator: EQ, value: "false" }
      ]
      first: 100
      after: $after
      query: $query
      sort: [{ key: DISPLAY_NAME, direction: ASC }]
      type: CONTEXT_TYPE
    ) {
      nodes {
        id
        ...ProcessItemFragment
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const SingleProcessQuery = gql`
  ${ProcessItemFragment}
  query ProcessQuery($id: ID!) {
    contextType(id: $id) {
      id
      ...ProcessItemFragment
    }
  }
`;

export const toSelectOption = (process: Process): OptionType => ({
  label: process.displayName,
  // Use ancestorID if the process is the active version, otherwise use its ID
  value: process.isActive !== false ? process.ancestorID : process.id
});

type Props = {
  className?: string,
  client: { query: (options: Object) => Promise<Object> },
  defaultValue?: ValueType | string,
  error?: Object,
  filteredNodesCallback?: (node: Object) => boolean,
  loading?: boolean,
  isMulti?: boolean,
  name?: string,
  process?: Process
};

export function ProcessSelect({
  className,
  client,
  defaultValue: defaultValueProp,
  error,
  filteredNodesCallback = () => true,
  isMulti = true,
  loading,
  process,
  ...other
}: Props) {
  if (loading) {
    return (
      <div className={className}>
        <SmallText>
          <FormattedMessage id="field.loading" defaultMessage="Loading..." />
        </SmallText>
      </div>
    );
  }
  const defaultValue = process
    ? toSelectOption(process)
    : fieldValueIsEmpty(defaultValueProp)
    ? undefined
    : { label: defaultValueProp, value: defaultValueProp };
  const loadOptions = async (query, loadedOptions) => {
    const after = loadedOptions?.length;
    let response;
    try {
      response = await client.query({
        ...selectQueryPolicies,
        query: ProcessQuery,
        variables: {
          query,
          after: typeof after === 'number' ? String(after) : after
        }
      });
    } catch (error) {
      response = {};
    }
    // eslint-disable-next-line camelcase
    const nodes = response?.data?.search_v2?.nodes || [];
    return {
      options: nodes.filter(filteredNodesCallback).map(toSelectOption),
      // eslint-disable-next-line camelcase
      hasMore: response?.data?.search_v2?.pageInfo.hasNextPage || false
    };
  };

  return (
    <AsyncPaginate
      className={className}
      debounceTimeout={DEBOUNCE_WAIT}
      defaultOptions
      defaultValue={defaultValue}
      isMulti={isMulti}
      loadOptions={loadOptions}
      placeholder={
        <FormattedMessage
          id="process.select.placeholder"
          defaultMessage="Select a Workflow..."
        />
      }
      {...other}
    />
  );
}
ProcessSelect.displayName = 'ProcessSelect';

type EnhancedProps = {
  className?: string,
  defaultValue?: ValueType | string,
  isMulti?: boolean,
  name?: string
};

const EnhancedProcessSelect: React.ComponentType<EnhancedProps> = compose(
  withApollo,
  // If a default value is provided to the input, fetch the process display name.
  branch(
    ({ defaultValue }) => {
      return defaultValue && typeof defaultValue === 'string';
    },
    graphql(SingleProcessQuery, {
      options: ({ defaultValue }) => {
        return {
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
          variables: { id: defaultValue }
        };
      },
      props: ({ data }) => {
        const { loading, error, contextType: process } = data || {};

        return { loading, error, process };
      }
    })
  )
)(ProcessSelect);

export default EnhancedProcessSelect;
