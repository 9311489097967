// @flow

import * as React from 'react';
// https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
import AceEditor from 'react-ace';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import 'ace-builds';
if (process.env.NODE_ENV !== 'test') {
  // skip webpack references when testing to make it easier for jest to find the extension modules
  require('ace-builds/webpack-resolver');
}
/* eslint-disable import/first */
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-textmate';
/* eslint-enable import/first */

const langTools = require('ace-builds/src-noconflict/ext-language_tools');

const useStyles = makeStyles(theme => {
  return {
    ...theme,
    root: {
      // note: this will only be undefined during testing as the full component
      // cannot load in headless unit testing due to dynamic imports limitations in jest.
      border: theme?.colors?.blueGrey
        ? `1px solid ${theme.colors.blueGrey}`
        : '',
      borderRadius: theme?.variables?.borderRadius || '0.875rem',
      overflow: 'hidden'
    }
  };
});

type CodeLanguages = 'javascript' | 'html' | 'css' | 'python';

const useHiddenStyles = makeStyles(() => ({
  hidden: { display: 'none' }
}));
const HiddenInput = (props: { name: string, value: any }) => {
  const classes = useHiddenStyles();
  // be sure to use textarea as target input to sync value to, since code will predominantly be multi-line
  return <textarea {...props} className={classes.hidden} readOnly />;
};

export type FieldSuggestion = {
  id: string,
  source?: string,
  display: string,
  type: React.Node
};

type Props = {
  className?: string,
  disabled?: boolean,
  /**  this should be a UNIQUE id */
  id: string,
  name: string,
  title: string,
  description?: string,
  required?: boolean,
  defaultValue?: any,
  onEditorLoad?: (...args: Array<any>) => any,
  onBlur?: (...args: Array<any>) => any,
  onChange?: ({
    currentTarget: {
      id?: string,
      name?: string,
      required?: boolean,
      value: string
    }
  }) => void,
  onFocus?: (...args: Array<any>) => any,
  title?: string | React.Element<typeof FormattedMessage>,
  type?: string,
  /** language can also be inferred from a `schema: type` */
  language?: CodeLanguages,
  /** how high in (pixels or %) to format the editor  */
  editorHeight?: string,
  /** how wide in (pixels or %) to format the editor */
  editorWidth?: string,
  /** how many lines to display in the editor by default, ignored if there is a value/defaultValue  */
  editorMinLines?: number,
  /** whether to enable autocompletion features. default: true */
  editorAutoComplete?: boolean,
  /** Data: field reference data for autocompletion */
  data: Array<FieldSuggestion>,
  readOnly?: boolean,
  format?: string
};

/**
 * Checks whether a format has been passed and has the
 * expected format of `code:<language>`, and whether
 * that language is one of the supported types.
 * Defaults to `javascript`.
 * @returns String type CodeLanguage
 */
export const getLanguageFromFormat: (
  format?: string
) => CodeLanguages = format => {
  const defaultValue = 'javascript';
  if (!format) {
    return defaultValue;
  }

  const values = format ? format.split(':') : [];
  const languages: Array<CodeLanguages> = [
    'javascript',
    'css',
    'html',
    'python'
  ];

  if (
    values?.length === 1 ||
    !values[1] ||
    languages.indexOf(values[1]) === -1
  ) {
    return defaultValue;
  }

  return (values[1]: any);
};

/**
 * Parses a data field list and returns Field Formula-formatted data options.
 * This is specific to Ace editor configuration.
 * @param {Array} data - field data
 */
export const useGetFieldCompleter = (data: Array<FieldSuggestion>) => {
  // $FlowIgnore
  const getCompletions = (editor, session, pos, prefix, callback) => {
    // try to guess fields access vs global state
    const completions = data.map(field => ({
      name: field.display,
      value:
        field.id && field.id.includes('.') ? field.id : `fields["${field.id}"]`,
      caption: `${field.id}: ${field.display} ${
        field.source ? `(${field.source})` : ''
      }`,
      meta: field.source
    }));
    callback(null, completions);
  };

  return React.useCallback(getCompletions, [data]);
};

export const setCompleters = (langTools: any, fieldCompleter: Function) => {
  if (langTools) {
    langTools.setCompleters([
      {
        exactMatch: true,
        getCompletions: fieldCompleter
      }
    ]);
  }
};

export const unsetCompleters = (langTools: any) => {
  if (langTools) {
    langTools.setCompleters([]);
  }
};

const CodeInput = ({
  className,
  disabled,
  id,
  name,
  onEditorLoad = () => {},
  onChange,
  onBlur,
  onFocus,
  title,
  language,
  defaultValue,
  editorHeight = '300px',
  editorWidth = '100%',
  editorAutoComplete = true,
  editorMinLines = 5,
  data,
  format
}: Props) => {
  const [codeText, setCodeText] = React.useState(defaultValue);

  const editor = React.createRef();
  const classes = useStyles();
  const languageType = React.useMemo(
    () => language || getLanguageFromFormat(format),
    [format, language]
  );
  const fieldCompleter = useGetFieldCompleter(data);

  const handleEditorLoad = React.useCallback(() => {
    setCompleters(langTools, fieldCompleter);

    onEditorLoad();
  }, [onEditorLoad, fieldCompleter]);

  React.useEffect(() => {
    // unload field-specific completers on unmount
    return () => {
      unsetCompleters(langTools);
    };
  }, []);

  // Sync with HiddenInput until such a time as we have access to an input with the raw text value directly
  // from the AceEditor component.
  const handleChange = newValue => {
    setCodeText(newValue);
    if (typeof onChange === 'function') {
      // Parameter types of an unknown function are unknown
      // (https://github.com/facebook/flow/issues/7702)
      // $FlowIgnore
      onChange(
        {
          currentTarget: {
            id,
            name,
            value: newValue
          }
        },
        newValue
      );
    }
  };

  return (
    <div
      className={classNames(classes.root, className)}
      title={title}
      data-id={name}
      data-testid={'CodeInput'}
    >
      <AceEditor
        ref={editor}
        mode={languageType}
        theme="textmate"
        className={classes.aceEditor}
        onLoad={handleEditorLoad}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        name={id}
        setOptions={{
          enableBasicAutocompletion: editorAutoComplete,
          enableLiveAutocompletion: editorAutoComplete
        }}
        readOnly={disabled}
        editorProps={{ $blockScrolling: true }}
        height={editorHeight}
        width={editorWidth}
        minLines={editorMinLines}
        defaultValue={defaultValue}
      />
      <HiddenInput name={name} value={codeText} />
    </div>
  );
};

export default React.memo<Props>(CodeInput);
