// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import { BLUE_GREY } from '../../style/colors';

const Style = (theme: ThemeType) => {
  return {
    root: {
      background: theme.colors.blueGrey,
      borderRadius: theme.variables.borderRadius,
      padding: '1.25rem 1rem',
      minHeight: '4rem'
    },
    colorScheme: theme.mixins.colorScheme,
    invertScheme: theme.mixins.invertScheme,
    paneContents: {
      ...theme.typography.baseText,
      color: theme.colors.black
    }
  };
};

type Props = InjectSheetProvidedProps & {
  children: Node,
  colorScheme?: string,
  invertScheme?: boolean
};

const Pane = ({
  children,
  classes,
  className,
  colorScheme,
  disabled,
  displayErrors,
  hasError,
  hasValidation,
  inputClassName,
  invertScheme,
  readOnly,
  setDisplayErrorsState,
  setLoadingState,
  theme,
  ...props
}: Props): Node => (
  <div
    className={classNames(
      classes.root,
      {
        [invertScheme ? classes.invertScheme : classes.colorScheme]: true
      },
      className
    )}
    data-color={colorScheme || undefined}
    {...props}
  >
    <div className={classes.paneContents}>{children}</div>
  </div>
);

Pane.defaultProps = {
  colorScheme: BLUE_GREY
};

Pane.displayName = 'Pane';
export default injectSheet(Style)(Pane);
