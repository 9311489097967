// @flow

import React, { type ElementType, type Node } from 'react';
import classNames from 'classnames';
import { type InjectSheetProvidedProps } from '../style/ThemeTypes';
import injectSheet from '../style/injectSheet';

const Style = () => {
  return {
    root: {
      overflow: 'auto'
    },
    table: {
      width: '100%',
      margin: '1rem 0'
    }
  };
};

type Props = InjectSheetProvidedProps & {
  /**
   * Child nodes
   */
  children: Node,
  /**
   * Component to be used as code renderer
   */
  component: ElementType
};

function MarkdownTable(props: Props) {
  const {
    children,
    classes,
    className: classNameProp,
    component: Component = 'table',
    theme,
    ...other
  } = props;
  const className = classNames(classes.table, classNameProp);

  return (
    <div className={classes.root}>
      <Component {...{ ...other, className }}>
        <tbody>{children}</tbody>
      </Component>
    </div>
  );
}

MarkdownTable.displayName = 'MarkdownTable';

export default injectSheet(Style)(MarkdownTable);
