// @flow

import React from 'react';
import { create, type Jss } from 'jss';
import {
  jssPreset,
  StylesProvider as JssStylesProvider
} from '@material-ui/styles';

export const jss = create(jssPreset());

type Props = {
  jss: Jss
};

const StylesProvider = (props: Props) => {
  return <JssStylesProvider {...props} />;
};
StylesProvider.displayName = 'StylesProvider';
StylesProvider.defaultProps = { jss };

export default StylesProvider;
