// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import AtlaskitPageHeader from '@atlaskit/page-header';
import { makeStyles, useTheme } from '@material-ui/styles';
import useMeasure from 'react-use/lib/useMeasure';
import classNames from 'classnames';
import Line from '../Line/Line';
import TabList from '../Tabs/TabList';
import Grid from '../../Grid/Grid';
import { Header1 } from '../../Text/Text';
import { ModalViewContext } from '../../View/ModalView/ModalView';
import { BLACK, SCIENCE_BLUE } from '../../style/colors';
import { type ThemeType } from '../../style/ThemeTypes';
import PageHeaderButtonGroup from './PageHeaderButtonGroup';

const useStyles = makeStyles((theme: ThemeType) => {
  const rowLayoutBreakpoint = theme.breakpoints.gte.tablet.portrait;
  const stickyHeaderStyles = {
    zIndex: theme.zIndex.modal,
    position: 'absolute',
    marginRight: '-.5rem',
    marginLeft: '-.5rem',
    paddingRight: '.5rem', // Offset margin-right
    paddingLeft: '.5rem', // Offset margin-left
    backgroundColor: theme.colors.white,
    right: theme.functions.toRem(theme.variables.modalMobilePadding),
    left: theme.functions.toRem(theme.variables.modalMobilePadding)
  };
  return {
    actionsWrapperElement: {
      alignSelf: 'center',
      paddingLeft: 0,
      [theme.breakpoints.lt.tablet.portrait]: {
        width: '100%'
      }
    },
    badge: {
      display: 'block',
      '& > *': {
        verticalAlign: 'middle'
      },
      [rowLayoutBreakpoint]: {
        display: 'inline-flex',
        alignItems: 'center',
        marginRight: '.5rem',
        '& > *': {
          verticalAlign: 'baseline' // Reset vertical-align
        }
      }
    },
    button: {
      flexGrow: 1
    },
    favorite: {
      display: 'inline-flex',
      alignItems: 'center',
      marginRight: '.5rem',
      '& > *': {
        verticalAlign: 'middle'
      },
      [rowLayoutBreakpoint]: {
        '& > *': {
          verticalAlign: 'baseline' // Reset vertical-align
        }
      }
    },
    header: {
      ...theme.mixins.breakWord,
      [rowLayoutBreakpoint]: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 0,
        height: '2.25rem'
      }
    },
    hidden: {
      display: 'none'
    },
    line: props =>
      props.disableStickyHeader === 'mobile'
        ? { [rowLayoutBreakpoint]: { marginBottom: 0 } }
        : { marginBottom: 0 },
    pageHeader: {
      // Reset Outer styles in the context of a modal
      // https://bitbucket.org/atlassian/atlaskit-mk-2/src/be4cdd0b1f9566847c274d0d05b9549881e0ae4a/packages/core/page-header/src/PageHeader/styled.ts?at=master#lines-18
      marginTop: '-24px'
    },
    shadowDiv: props =>
      props.disableStickyHeader === 'mobile'
        ? {
            // Must be inverse of rowLayoutBreakpoint
            [theme.breakpoints.lte.tablet.portrait]: { display: 'none' }
          }
        : {},
    stickyHeader: props => {
      return {
        ...(props.disableStickyHeader === 'mobile'
          ? { [rowLayoutBreakpoint]: stickyHeaderStyles }
          : stickyHeaderStyles),
        [theme.breakpoints.desktop]: {
          right: theme.functions.toRem(theme.variables.modalDesktopPadding),
          left: theme.functions.toRem(theme.variables.modalDesktopPadding)
        }
      };
    },
    subTitle: {
      ...theme.mixins.breakWord,
      minWidth: 0,
      // Reset BottomBarWrapper styles
      // https://bitbucket.org/atlassian/atlaskit-mk-2/src/be4cdd0b1f9566847c274d0d05b9549881e0ae4a/packages/core/page-header/src/PageHeader/styled.ts?at=master#lines-55
      marginTop: '-16px'
    },

    subTitlePadding: { paddingBottom: '1rem' },
    subTitleText: {
      ...theme.typography.smallText,
      color: theme.colors.pebbleGrey
    },
    title: {
      marginRight: '.5rem',
      [rowLayoutBreakpoint]: {
        ...theme.mixins.truncate
      }
    },
    titleContainerElement: {
      [rowLayoutBreakpoint]: {
        flexGrow: 1,
        flexBasis: 0
      }
    }
  };
});

const addClassToParentElement = (node, className: string) => {
  // Support optional chaining (https://github.com/facebook/flow/issues/4303)
  // $FlowIgnore
  return node?.parentElement?.classList.add(className);
};

type BreadcrumbType = React.Element<any> | boolean | null; // typeof Breadcrumb
type ButtonInputType = React.Element<any> | null; // typeof ButtonInput
type MenuType = React.Element<any>; // typeof Menu
// It would be nice if...
// ```
// type SubTitle =
// | string
// | React.Element<typeof FormattedMessage>
// | React.Element<typeof Markdown>;
// ```
// but `StepListHeader.js` and `GalleryDetail.js` requires a `React.Node` type
type SubTitle = React.Node;
type Title =
  | string
  | React.Element<typeof FormattedMessage>
  | React.Element<typeof Link>;

type Props = {|
  badge?: React.Node,
  breadcrumb?: BreadcrumbType,
  buttons?: ButtonInputType | Array<ButtonInputType>,
  children?: React.Node,
  disableModalBreadcrumb?: boolean,
  disableStickyHeader?: boolean | 'mobile',
  favorite?: React.Node,
  includeLine?: boolean,
  menu?: MenuType,
  subTitle?: SubTitle,
  subTitleClassName?: string,
  tabs?: React.Element<typeof TabList>,
  title?: Title
|};

function PageHeader({
  badge,
  breadcrumb,
  buttons,
  children,
  disableModalBreadcrumb = false,
  disableStickyHeader,
  favorite,
  includeLine: includeLineProp = false,
  menu,
  subTitle,
  subTitleClassName,
  tabs: tabsProp,
  title
}: Props) {
  const classes = useStyles({ disableStickyHeader });
  const theme = useTheme();
  const modalViewContext = React.useContext(ModalViewContext);
  const shouldApplyStickyHeaderStyles =
    typeof disableStickyHeader === 'boolean'
      ? !disableStickyHeader
      : modalViewContext;
  const shouldRenderBreadcrumb = !disableModalBreadcrumb || !modalViewContext;
  const includeLine = includeLineProp ?? (modalViewContext && !tabsProp);
  const lineClassName = classNames({
    [classes.line]: shouldApplyStickyHeaderStyles
  });
  const [ref, { height }] = useMeasure();
  const [primaryButtonProp, secondaryButtonProp] = Array.isArray(buttons)
    ? buttons
    : [buttons];
  const primaryButton =
    primaryButtonProp != null
      ? React.cloneElement(primaryButtonProp, {
          className: classes.button,
          colorScheme: SCIENCE_BLUE
        })
      : null;
  const secondaryButton =
    primaryButton && secondaryButtonProp != null
      ? React.cloneElement(secondaryButtonProp, {
          className: classes.button,
          invertScheme: true,
          colorScheme: BLACK
        })
      : null;
  const tabs =
    tabsProp != null
      ? React.cloneElement(tabsProp, {
          className: lineClassName
        })
      : null;
  const actionsElement = React.useCallback(
    node => {
      addClassToParentElement(node, classes.actionsWrapperElement);
    },
    [classes]
  );
  const childrenElement = React.useCallback(
    node => {
      addClassToParentElement(node, classes.titleContainerElement);
    },
    [classes]
  );

  return (
    <>
      <div
        className={
          shouldApplyStickyHeaderStyles ? classes.stickyHeader : undefined
        }
        data-testid="page-header"
        ref={ref}
      >
        <div className={modalViewContext ? classes.pageHeader : undefined}>
          <AtlaskitPageHeader
            actions={
              (primaryButton || menu) && (
                <PageHeaderButtonGroup
                  data-testid="page-header-button-group"
                  ref={actionsElement}
                >
                  {primaryButton}
                  {secondaryButton}
                  {menu}
                </PageHeaderButtonGroup>
              )
            }
            bottomBar={
              (subTitle || children) && (
                <Grid container direction="column">
                  {subTitle && (
                    <Grid
                      className={classNames({
                        [classes.subTitlePadding]: Boolean(children)
                      })}
                      item
                      xs
                      md={11}
                      zeroMinWidth
                    >
                      <div className={classes.subTitle}>
                        <div
                          className={classNames(
                            classes.subTitleText,
                            subTitleClassName
                          )}
                        >
                          {subTitle}
                        </div>
                      </div>
                    </Grid>
                  )}
                  <div>{children}</div>
                </Grid>
              )
            }
            breadcrumbs={shouldRenderBreadcrumb ? breadcrumb : undefined}
            disableTitleStyles
            truncateTitle={false}
          >
            <div className={classes.hidden} ref={childrenElement} />
            <Header1 className={classes.header}>
              {title && (
                <span
                  className={classes.title}
                  title={typeof title === 'string' ? title : undefined}
                >
                  {title}
                </span>
              )}
              {favorite && <span className={classes.favorite}>{favorite}</span>}
              {badge && <span className={classes.badge}>{badge}</span>}
            </Header1>
          </AtlaskitPageHeader>
        </div>
        {includeLine && <Line className={lineClassName} />}
        {tabs}
      </div>
      {shouldApplyStickyHeaderStyles && (
        // Shadow `div` pushes down modal content
        // when page header is fixed
        <div
          className={classes.shadowDiv}
          role="presentation"
          style={{ height: height + parseInt(theme.variables.base) }}
        />
      )}
    </>
  );
}
PageHeader.displayName = 'PageHeader';

export default PageHeader;
