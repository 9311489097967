// @flow

import React, { PureComponent, type ElementRef } from 'react';
import Switch from 'react-switch';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import colors, { OLIVE } from '../../style/colors';
import variables from '../../style/variables';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';

const BOX_SHADOW =
  '0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 0 0 0.5px rgba(0, 0, 0, 0.1)';
const NOOP = () => {};

const Style = (theme: ThemeType) => {
  return {
    root: {
      '& .react-switch-bg': {
        border: `1px solid ${theme.colors.pastelGrey}`
      }
    },
    checked: {
      '& .react-switch-bg': {
        border: 'none'
      }
    },
    inputShadow: {
      display: 'none'
    }
  };
};

export type SwitchInputType = InjectSheetProvidedProps &
  HTMLInputElement & {
    colorScheme?: string,
    hasValidation?: boolean,
    inputClassName?: string,
    inputRef?: ElementRef<*>,
    onChange?: ({
      currentTarget: { id: string, name: string, value: boolean }
    }) => mixed,
    setDisplayErrorsState?: (displayErrors: boolean) => mixed,
    setLoadingState?: (loading: boolean) => mixed
  };

type State = {
  checked: boolean
};

class SwitchInput extends PureComponent<SwitchInputType, State> {
  static displayName = 'SwitchInput';

  static defaultProps = {
    activeBoxShadow: BOX_SHADOW,
    boxShadow: BOX_SHADOW,
    checked: false,
    checkedIcon: false,
    colorScheme: OLIVE,
    disabled: false,
    offColor: colors.pastelGrey,
    offHandleColor: colors.white,
    onHandleColor: colors.white,
    uncheckedIcon: false
  };

  state = {
    checked: this.props.checked
  };

  componentDidUpdate(prevProps) {
    if (this.props.checked !== prevProps.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  handleChange = (value: boolean) => {
    const { id, name, onChange } = this.props;

    this.setState({ checked: value });

    if (onChange) {
      return onChange({ currentTarget: { id, name, value } });
    }
  };

  render() {
    const {
      checked: checkedProp,
      classes,
      className,
      colorScheme,
      inputClassName,
      hasValidation,
      height = variables.inputSwitchHeight,
      inputRef: ref,
      name,
      onChange,
      required,
      setDisplayErrorsState,
      setLoadingState,
      theme,
      width = variables.inputSwitchWidth,
      id,
      ...props
    } = this.props;
    const { checked } = this.state;

    return (
      <div className={className}>
        <Switch
          className={classNames(
            classes.root,
            { [classes.checked]: checked },
            inputClassName
          )}
          onChange={this.handleChange}
          onColor={colorScheme ? colors[colorScheme] : colors.black}
          {...{ checked, height, ref, width, id, ...props }}
        />
        <input
          data-testid={`switch-input-${id}`}
          className={classes.inputShadow}
          data-name={name}
          onChange={NOOP}
          value={checked}
          {...{ name, required }}
        />
      </div>
    );
  }
}

export default injectSheet(Style)(SwitchInput);
