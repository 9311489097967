// @flow

import React, {
  createRef,
  PureComponent,
  type ComponentType,
  type ElementRef
} from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { compose, setDisplayName, withProps } from 'recompose';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import classNames from 'classnames';
import { ReactComponent as ErrorIcon } from '@catalytic/catalytic-icons/lib/icons/error.svg';
import { ExtraSmallText } from '../../Text/Text';
import { COLORS, SCIENCE_BLUE } from '../../style/colors';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';
import InputRequired from '../InputRequired/InputRequired';
import { withModalViewContext } from '../../View/ModalView/ModalView';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_VALUE = '';
const DISPLAY_NAME = 'DateTimeInput';
const NOOP = () => {};
export const formatDate = (value: string, format: boolean): string => {
  const [date] = format ? [value] : value.split('T');

  return date;
};
export const getDate = (value: string): string => {
  const date = new Date(value);

  // Detect an invalid date.
  // https://stackoverflow.com/a/1353711
  return value &&
    Object.prototype.toString.call(date) === '[object Date]' &&
    !isNaN(date)
    ? date.toISOString()
    : DEFAULT_VALUE;
};
const messages = {
  error: {
    defaultMessage: 'Invalid Date',
    id: 'datetime.error'
  }
};
const definedMessages = defineMessages(messages);

const Style = (theme: ThemeType) => {
  const keys = Object.keys(COLORS);
  const ACTIVE_COLOR = theme.colors.black;

  // https://github.com/YouCanBookMe/react-datetime/blob/master/css/react-datetime.css
  return {
    root: {
      '@global': {
        'table, thead, tbody, tfoot, tr, th, td': {
          verticalAlign: 'middle'
        }
      },
      position: 'relative',
      '& .rdtPicker': {
        background: theme.colors.white,
        borderColor: theme.colors.blueGrey,
        boxShadow: `0 .25rem .5rem ${theme.colors.pastelGreyAlpha}`,
        zIndex: ({ modalViewContext }) =>
          `${
            modalViewContext ? theme.zIndex.modal : theme.zIndex.menu
          } !important`
      },
      '& .rdtPicker td.rdtDay:hover, & .rdtPicker td.rdtHour:hover, & .rdtPicker td.rdtMinute:hover, & .rdtPicker td.rdtSecond:hover, & .rdtPicker .rdtTimeToggle:hover': {
        background: theme.colors.lightGrey
      },
      '& .rdtPicker td.rdtOld, & .rdtPicker td.rdtNew': {
        color: theme.colors.battleshipGrey
      },
      '& .rdtPicker td.rdtToday:before': {
        borderBottomColor: ACTIVE_COLOR
      },
      '& .rdtPicker td.rdtActive, & .rdtPicker td.rdtActive:hover': {
        backgroundColor: ACTIVE_COLOR,
        color: theme.colors.white,
        textShadow: 'none'
      },
      '& .rdtPicker td.rdtActive.rdtToday:before': {
        borderBottomColor: theme.colors.white
      },
      '& .rdtPicker td.rdtDisabled, & .rdtPicker td.rdtDisabled:hover': {
        color: theme.colors.battleshipGrey
      },
      '& .rdtPicker td span.rdtOld': {
        color: theme.colors.battleshipGrey
      },
      '& .rdtPicker td span.rdtDisabled, & .rdtPicker td span.rdtDisabled:hover': {
        color: theme.colors.battleshipGrey
      },
      '& .rdtPicker th': {
        borderBottomColor: theme.colors.white
      },
      '& .rdtPicker th.rdtDisabled, & .rdtPicker th.rdtDisabled:hover': {
        color: theme.colors.battleshipGrey
      },
      '& .rdtPicker thead tr:first-child th:hover': {
        background: theme.colors.lightGrey
      },
      '& .rdtPicker tfoot': {
        borderTopColor: theme.colors.white
      },
      '& .rdtPicker button:hover': {
        backgroundColor: theme.colors.lightGrey
      },
      '& td.rdtMonth:hover, & td.rdtYear:hover': {
        background: theme.colors.lightGrey
      },
      '& .rdtCounter .rdtBtn:hover': {
        background: theme.colors.lightGrey
      }
    },
    input: theme.mixins.inputStyle,
    readOnly: {
      '&[readonly]': {
        '&, &:focus, &:hover': theme.mixins.inputReadOnly
      }
    },
    disabled: {
      '&:disabled': {
        '&, &:focus, &:hover': theme.mixins.inputDisabled
      }
    },
    success: theme.mixins.inputSuccess,
    error: theme.mixins.inputError,
    errorMessage: theme.mixins.inputInlineErrorMessage,
    errorIcon: theme.mixins.inputInlineErrorIcon,
    container: {
      position: 'relative'
    },
    required: {},
    inputShadow: {
      display: 'none',
      '&:required:valid': {
        '& ~ $required': {
          display: 'none'
        }
      }
    },
    colorScheme: keys.reduce((accumulator, color) => {
      const hex = COLORS[color];

      return {
        ...accumulator,
        [`&[data-color="${color}"]`]: {
          '& .rdtPicker td.rdtToday:before': {
            borderBottomColor: hex
          },
          '& .rdtPicker td.rdtActive, & .rdtPicker td.rdtActive:hover': {
            backgroundColor: hex
          }
        }
      };
    }, {}),
    embeddedInput: theme.mixins.inputEmbedded
  };
};

export type DateTimeInputType = InjectSheetProvidedProps &
  HTMLInputElement & {
    closeOnSelect: boolean,
    colorScheme?: string,
    /*
    | Moment.js   | Intl.DateTimeFormat                                     | en-US             | de-DE            |
    |-----------  |-------------------------------------------------------  |-----------------  |----------------  |
    | YYYY-MM-DD  |                                                         | 2020-01-01        |                  |
    | l           | { year: 'numeric', month: 'numeric', day: 'numeric' }   | 1/1/2020          | 1.1.2020         |
    | L           | { year: 'numeric', month: '2-digit', day: '2-digit' }   | 01/01/2020        | 01.01.2020       |
    | ll          | { year: 'numeric', month: 'short', day: 'numeric' }     | Jan 1, 2020       | 1. Jan. 2020     |
    | LL          | { year: 'numeric', month: 'long', day: 'numeric' }      | January 1, 2020   | 1. Januar 2020   |
    */
    dateFormat?: 'YYYY-MM-DD' | 'l' | 'L' | 'll' | 'LL',
    dateTimeRef?: ElementRef<*>,
    displayErrors: boolean,
    embedded?: boolean,
    hasError: boolean,
    hasRequired: boolean,
    hasSuccess: boolean,
    hasValidation?: boolean,
    inputClassName?: string,
    inputRef?: ElementRef<*>,
    intl: Object,
    isValidDate?: (current: any, selected: any) => boolean,
    locale?: string,
    modalViewContext: boolean,
    onBlur?: ({
      currentTarget: { id: string, name: string, value: any }
    }) => mixed,
    onChange?: ({
      currentTarget: { id: string, name: string, value: any }
    }) => mixed,
    setDisplayErrorsState?: (displayErrors: boolean) => mixed,
    setLoadingState?: (loading: boolean) => mixed,
    /*
    | Moment.js   | Intl.DateTimeFormat                                     | en-US             | de-DE            |
    |-----------  |-------------------------------------------------------  |-----------------  |----------------  |
    | h:mm A      | { hour: 'numeric', minute: 'numeric', hour12: true }    | 1:30 PM           |                  |
    | HH:mm       | { hour: 'numeric', minute: 'numeric', hour12: true }    | 13:30             |                  |
    */
    timeFormat?: false | 'h:mm A' | 'HH:mm',
    utc: boolean
  };

type State = {
  defaultValue: string
};

class DateTimeInput extends PureComponent<DateTimeInputType, State> {
  static displayName = DISPLAY_NAME;

  static defaultProps = {
    autoComplete: 'off',
    closeOnSelect: false, // https://github.com/YouCanBookMe/react-datetime/issues/550,
    colorScheme: SCIENCE_BLUE,
    dateFormat: DEFAULT_DATE_FORMAT,
    disabled: false,
    embedded: false,
    hasError: false,
    hasRequired: true,
    hasSuccess: false,
    isValidDate: () => true,
    readOnly: false,
    required: false,
    timeFormat: true,
    utc: false
  };

  state = {
    defaultValue: getDate(this.props.defaultValue)
  };

  handleBlur = () => {
    const { id, name, onBlur, timeFormat } = this.props;
    const { defaultValue } = this.state;

    if (onBlur) {
      const formattedDefaultValue = formatDate(
        defaultValue,
        Boolean(timeFormat)
      );

      return onBlur({
        currentTarget: { id, name, value: formattedDefaultValue }
      });
    }
  };

  handleChange = (value: string) => {
    const { id, name, onChange, timeFormat } = this.props;
    const defaultValue = getDate(value);

    this.setState({ defaultValue });

    if (onChange) {
      const formattedDefaultValue = formatDate(
        defaultValue,
        Boolean(timeFormat)
      );

      return onChange({
        currentTarget: { id, name, value: formattedDefaultValue }
      });
    }
  };

  renderInput = ({
    className,
    disabled,
    embedded,
    hasRequired,
    inputClassName,
    inputRef: ref,
    name,
    readOnly,
    required,
    value,
    ...props
  }: DateTimeInputType) => {
    const { classes, intl, timeFormat } = this.props;
    const { defaultValue: defaultValueProp } = this.state;
    const defaultValue = formatDate(defaultValueProp, Boolean(timeFormat));
    const hasError = value && !defaultValue;

    if (
      ref &&
      typeof ref === 'object' &&
      ref.current &&
      typeof ref.current === 'object' &&
      typeof ref.current.setCustomValidity === 'function'
    ) {
      ref.current.setCustomValidity(
        hasError ? intl.formatMessage(definedMessages.error) : ''
      );
    }

    return (
      <div className={classes.container}>
        <input
          className={classNames(
            {
              [classes.error]: hasError,
              [classes.embeddedInput]: embedded,
              [classes.readOnly]: readOnly,
              [classes.disabled]: disabled
            },
            className
          )}
          data-testid={name || 'date-time-input'}
          disabled={disabled}
          readOnly={readOnly}
          ref={ref}
          value={value}
          {...props}
        />
        <input
          className={classes.inputShadow}
          data-name={name}
          name={name}
          onChange={NOOP}
          required={required}
          value={defaultValue}
        />
        {!hasError && (
          <InputRequired
            className={classes.required}
            disabled={disabled}
            hasRequired={hasRequired}
            readOnly={readOnly}
            required={required}
          />
        )}
        {hasError && !embedded && (
          <div
            className={classes.errorMessage}
            data-id={`${DISPLAY_NAME}__indicators`}
          >
            <ExtraSmallText>
              <FormattedMessage {...messages.error} />
            </ExtraSmallText>
            <ErrorIcon className={classes.errorIcon} />
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      classes,
      className,
      closeOnSelect,
      colorScheme,
      dateFormat,
      dateTimeRef,
      defaultValue: defaultValueProp,
      disabled,
      displayErrors,
      hasError,
      hasSuccess,
      hasValidation,
      inputClassName,
      intl,
      isValidDate,
      locale,
      modalViewContext,
      name,
      onBlur,
      onChange,
      readOnly,
      required,
      setDisplayErrorsState,
      setLoadingState,
      theme,
      timeFormat,
      title,
      utc,
      ...props
    } = this.props;
    const { defaultValue } = this.state;
    const hasValue = defaultValue && defaultValue.length > 0;
    const isRequired = required && !hasValue;

    return (
      <div
        className={classNames(classes.root, classes.colorScheme, className)}
        data-color={colorScheme || undefined}
        tabIndex="-1"
        title={title}
      >
        <DateTime
          closeOnSelect={closeOnSelect}
          dateFormat={dateFormat}
          defaultValue={defaultValue ? new Date(defaultValue) : undefined}
          inputProps={{
            'data-name': name,
            disabled: disabled || readOnly,
            className: classNames(
              classes.input,
              {
                [classes.success]: hasSuccess || (displayErrors && !isRequired),
                [classes.error]: hasError || (displayErrors && isRequired)
              },
              inputClassName
            ),
            name,
            readOnly,
            required,
            ...props
          }}
          isValidDate={isValidDate}
          locale={locale}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          ref={dateTimeRef}
          renderInput={this.renderInput}
          timeFormat={timeFormat}
          utc={utc || !timeFormat}
        />
      </div>
    );
  }
}

// TODO: Type props to EnhancedDateTimeIput component
type EnhancedProps = Object;

const EnhancedDateTimeIput: ComponentType<EnhancedProps> = compose(
  setDisplayName(DISPLAY_NAME),
  withModalViewContext,
  injectIntl,
  injectSheet(Style),
  withProps(({ dateTimeRef, inputRef }) => ({
    dateTimeRef: dateTimeRef || createRef(),
    inputRef: inputRef || createRef()
  }))
)(DateTimeInput);

export default EnhancedDateTimeIput;
