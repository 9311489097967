// @flow

import * as React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import type { ThemeType } from '../../style/ThemeTypes';
import InputRequired from '../InputRequired/InputRequired';
import Copy from '../Copy/Copy';
import { useForkedRef } from '../../utils/ref';
import { TextInputProvider } from './TextInputContext';

const useStyles = makeStyles((theme: ThemeType) => {
  return {
    root: {
      position: 'relative'
    },
    input: {
      ...theme.mixins.inputStyle,
      ...theme.mixins.truncate,
      '&:required:invalid': {
        paddingRight: theme.variables.inputRequiredPaddingRight
      },
      '&:focus:required:invalid, &:required:valid': {
        ...theme.mixins.inputPadding,
        '& ~ $required': {
          display: 'none'
        }
      }
    },
    hasChildren: {
      paddingRight: theme.variables.inputRequiredPaddingRight,
      '&:required:invalid': {
        paddingRight: theme.variables.inputRequiredPaddingRight
      },
      '&:focus:required:invalid, &:required:valid': {
        paddingRight: theme.variables.inputRequiredPaddingRight
      }
    },
    displayErrors: {
      '&:valid': theme.mixins.inputSuccess,
      '&:invalid': theme.mixins.inputError
    },
    success: theme.mixins.inputSuccess,
    error: theme.mixins.inputError,
    required: {},
    embedded: {
      '& input': {
        border: `1px solid ${theme.colors.lightGrey}`,
        borderRadius: theme.variables.borderRadiusSmall,
        backgroundColor: theme.colors.ghostWhite,
        margin: 0,
        '&:hover, &:focus, &:focus-within': {
          backgroundColor: theme.colors.ghostWhite,
          border: `1px solid ${theme.colors.lightGrey}`
        }
      }
    },
    embeddedInput: {
      ...theme.typography.smallText,
      height: '2rem',
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      lineHeight: '1.43rem'
    }
  };
});

// Here $Diff prevents creating impossible `children` type
// https://flow.org/en/docs/types/intersections/#toc-impossible-intersection-types
export type TextInputType = $Diff<
  HTMLInputElement,
  {
    children: React.Element<typeof Copy>
  }
> & {
  children?: React.Element<typeof Copy>,
  displayErrors?: boolean,
  embedded?: boolean,
  hasError?: boolean,
  hasRequired?: boolean,
  hasSuccess?: boolean,
  hasValidation?: boolean,
  inputClassName?: string,
  inputRef?: React.Ref<'input'>,
  setDisplayErrorsState?: (displayErrors: boolean) => mixed,
  setLoadingState?: (loading: boolean) => mixed
};
function TextInput(props: TextInputType) {
  const {
    children,
    className,
    disabled,
    displayErrors,
    embedded,
    hasError,
    hasRequired,
    hasSuccess,
    hasValidation,
    inputClassName,
    inputRef: inputRefProp,
    name,
    readOnly,
    required,
    setDisplayErrorsState,
    setLoadingState,
    title,
    ...other
  } = props;
  const classes = useStyles();
  const ownRef = React.useRef(null);
  const ref = useForkedRef(inputRefProp, ownRef);
  const hasChildren = children !== undefined;

  return (
    <div
      className={classNames(
        classes.root,
        {
          [classes.embedded]: embedded
        },
        className
      )}
      title={title}
    >
      <input
        className={classNames(
          classes.input,
          {
            [classes.displayErrors]: displayErrors,
            [classes.embeddedInput]: embedded,
            [classes.error]: hasError,
            [classes.hasChildren]: hasChildren,
            [classes.success]: hasSuccess
          },
          inputClassName
        )}
        data-name={name}
        data-testid={name || 'text-input'}
        disabled={disabled}
        ref={ref}
        {...{ name, readOnly, required }}
        {...other}
      />
      {!hasChildren && (
        <InputRequired
          className={classes.required}
          {...{
            disabled,
            embedded,
            hasRequired,
            readOnly,
            required
          }}
        />
      )}
      {hasChildren && (
        <TextInputProvider value={{ inputRef: ownRef }}>
          {children}
        </TextInputProvider>
      )}
    </div>
  );
}
TextInput.displayName = 'TextInput';
TextInput.defaultProps = {
  autoComplete: 'off',
  disabled: false,
  displayErrors: false,
  embedded: false,
  hasError: false,
  hasRequired: true,
  hasSuccess: false,
  readOnly: false,
  required: false,
  type: 'text'
};

export default React.memo<TextInputType>(TextInput);
