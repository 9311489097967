// @flow

import mime from 'mime-types';
import uniq from 'ramda/src/uniq';

const whenString = (stringExpectingFunc: string => string) => (
  maybeString?: null | string
) =>
  typeof maybeString === 'string'
    ? stringExpectingFunc(maybeString)
    : maybeString;

export const toMimeTypeList = whenString((fileExtensionList: string) =>
  uniq(
    fileExtensionList
      .split(',')
      .map(fileExtension => mime.lookup(fileExtension.trim()))
      .filter(Boolean)
  ).join(',')
);
export const toDefaultFileExtensionList = whenString((mimeTypeList: string) =>
  uniq(
    mimeTypeList
      .split(',')
      .map(mimeType => mime.extension(mimeType.trim()))
      .filter(Boolean)
      .map(mimeType => '.' + mimeType)
  ).join(',')
);
export const toFullFileExtensionList = whenString((mimeTypeList: string) =>
  uniq(
    mimeTypeList
      .split(',')
      .reduce((fullList, mimeType) => {
        const fullMapOfFileExtensionsForMimeType =
          mime.extensions[mimeType.trim()] || [];
        return [...fullList, ...fullMapOfFileExtensionsForMimeType];
      }, [])
      .filter(Boolean)
      .map(mimeType => '.' + mimeType)
  ).join(',')
);
