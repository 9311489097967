// @flow

import React, { type ElementType } from 'react';
import classNames from 'classnames';
import { type InjectSheetProvidedProps } from '../style/ThemeTypes';
import injectSheet from '../style/injectSheet';

const Style = {
  root: {
    fontWeight: 'bold'
  }
};

type Props = InjectSheetProvidedProps & {
  /**
   * Component to be used as the heading
   */
  component: ElementType
};

function MarkdownEmbolden(props: Props) {
  const {
    classes,
    className: classNameProp,
    component: Component,
    theme,
    ...other
  } = props;
  const className = classNames(classes.root, classNameProp);

  return <Component {...{ ...other, className }} />;
}

MarkdownEmbolden.displayName = 'MarkdownEmbolden';
MarkdownEmbolden.defaultProps = {
  component: 'strong'
};

export default injectSheet(Style)(MarkdownEmbolden);
