// @flow

import * as React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { areEqual } from 'react-window';
import classNames from 'classnames';
import path from 'path';
import { makeStyles, useTheme } from '@material-ui/styles';
import { type CSS } from '@catalytic/catalytic-ui';
import ListItem from './NavbarNotificationsListItem';
import SkeletonListItem from './NavbarNotificationsSkeletonListItem';
import { type Nodes } from './NavbarNotificationsTypes';
import { TASKS_V2 as TASKS_V2_PATH } from '../const/path';
import { toStatusColor } from '../shared/Status/StatusBadge';
import isUUID from '../utils/uuid';

const useStyles = makeStyles({
  link: {
    '&:active': {
      opacity: 1
    }
  },
  noop: {
    cursor: 'default' // not-allowed
  }
});

type RowProps = {
  data: {
    nodes: Nodes,
    updateNotification: ({ id: string }) => Promise<void | {
      [key: string]: any
    }>
  },
  index: number,
  style: CSS
};

const Row = React.memo<RowProps>(
  ({ data: { nodes, updateNotification }, index, style }: RowProps) => {
    const classes = useStyles();
    const location = useLocation();
    const theme = useTheme();
    const itemData = nodes[index];
    const instanceDisplayName =
      itemData?.contextReference?.node?.context?.displayName || '';
    const notificationCreatedDate = itemData?.createdDate;
    const notificationDescription = itemData?.description || '';
    const notificationDisplayName = itemData?.displayName || '';
    const notificationId = itemData?.id;
    const notificationRead = itemData?.read;
    const taskDisplayName = itemData?.contextReference?.node?.displayName || '';
    const taskId = itemData?.contextReference?.node?.id;
    const taskStatus = itemData?.contextReference?.node?.status;
    const description = instanceDisplayName || notificationDescription;
    const displayName = taskDisplayName || notificationDisplayName;
    // Set task icon background color based on task status.
    const backgroundColor = theme.colors[toStatusColor(taskStatus)];

    // If a notification has a valid task ID, render a list item.
    // Note, task ID's are not always valid UUID.
    if (taskId && typeof taskId === 'string') {
      const pathname = path.join(TASKS_V2_PATH, taskId);

      return (
        <div style={style}>
          <Link
            className={classes.link}
            to={{ pathname, state: { background: location } }}
            onClick={() => {
              if (!notificationRead) {
                updateNotification({ id: notificationId });
              }
            }}
          >
            <ListItem
              backgroundColor={backgroundColor}
              description={
                <FormattedMessage
                  defaultMessage="Assigned {createdDate} | {description}"
                  id="navbarNotifications.assigned"
                  values={{
                    createdDate: (
                      <FormattedDate value={notificationCreatedDate} />
                    ),
                    description
                  }}
                />
              }
              displayName={displayName}
              read={notificationRead}
            />
          </Link>
        </div>
      );
    }

    // If a notification does not have a valid task ID, render a list item.
    // Note, this list item will not link anywhere, however, we use an anchor
    // tag to maintain the same HTML structure for all list items.
    if (
      notificationId &&
      typeof notificationId === 'string' &&
      isUUID(notificationId)
    ) {
      return (
        <div style={style}>
          <a
            className={classNames(classes.link, {
              [classes.noop]: notificationRead
            })}
            href={`#notification-${notificationId}`}
            onClick={event => {
              event.preventDefault();

              if (!notificationRead) {
                updateNotification({ id: notificationId });
              }
            }}
          >
            <ListItem
              backgroundColor={backgroundColor}
              description={
                <FormattedMessage
                  defaultMessage="Assigned {createdDate} | {description}"
                  id="navbarNotifications.assigned"
                  values={{
                    createdDate: (
                      <FormattedDate value={notificationCreatedDate} />
                    ),
                    description: notificationDescription
                  }}
                />
              }
              displayName={notificationDisplayName}
              read={notificationRead}
            />
          </a>
        </div>
      );
    }

    // Render a skeleton list item.
    return (
      <div style={style}>
        <SkeletonListItem
          displayName={
            <FormattedMessage
              defaultMessage="Loading..."
              id="navbarNotifications.loading"
            />
          }
        />
      </div>
    );
  },
  areEqual
);
Row.displayName = 'Row';

export default Row;
