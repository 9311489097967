// @flow

import * as React from 'react';

type Context = {|
  inputRef: React.Ref<'input'>
|};

type Props = {|
  children?: React.Node,
  value: Context
|};

const TextInputContext = React.createContext<Context | void>();

const useTextInputContext = () => React.useContext(TextInputContext);

export const TextInputProvider = ({ children, value }: Props) => (
  <TextInputContext.Provider value={value}>
    {children}
  </TextInputContext.Provider>
);

export const TextInputConsumer = TextInputContext.Consumer;

export default useTextInputContext;
