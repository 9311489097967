// @flow

import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import AvatarV2 from '../AvatarV2/AvatarV2';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.header5,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    backgroundColor: 'transparent',
    color: theme.colors.black,
    '&:hover, &:focus, &:active': {
      color: theme.colors.battleshipGrey
    },
    [theme.breakpoints.gte.mobile.portrait]: {
      width: '2.75rem',
      height: '2.75rem'
    }
  }
}));

type Props = {
  children:
    | React.Element<typeof AvatarV2>
    | React.Element<'img'>
    | React.Element<'svg'>
    | null,
  className?: string,
  onClick?: (event: SyntheticInputEvent<HTMLButtonElement>) => mixed,
  tabIndex?: number
};

const NavButton = ({ children, className, onClick, tabIndex }: Props) => {
  const classes = useStyles();

  return (
    <button
      className={classNames(classes.root, className)}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};
NavButton.displayName = 'NavButton';

export default NavButton;
