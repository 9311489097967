// @flow

import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { Mutation, Query } from 'react-apollo';
import {
  useHistory,
  useLocation,
  withRouter,
  type ContextRouter
} from 'react-router-dom';
import path from 'path';
import { TASKS_V2 as TASKS_V2_PATH } from '../const/path';
import gql from 'graphql-tag';
import DetailBadge from '../shared/DetailBadge/DetailBadge';
import {
  FormattedMessage,
  defineMessages,
  useIntl,
  FormattedDate
} from 'react-intl';
import {
  ButtonInput,
  getFieldValue,
  Header2,
  injectSheet,
  Input,
  Markdown,
  Menu,
  MenuItem,
  PageHeader,
  showToast,
  TOAST_TYPE,
  WebformView,
  type InjectSheetProvidedProps,
  type ThemeType
} from '@catalytic/catalytic-ui';
import FailedTaskActionConfiguration from './FailedTaskActionConfiguration';
import ExpressionContext from '../Expression/ExpressionContext';
import Field from '../FieldV2/Field';
import { fieldValueIsEmpty, getFieldInputs } from '../Field/FieldHelpers';
import {
  FORMAT,
  type Field as FieldType,
  type Inputs
} from '../Field/FieldTypes';
import TopBarLoader from '../Loading/TopBarLoader';
import {
  type Status,
  isCompleted,
  RUNNING
} from '../shared/Status/StatusTypes';
import debounce from '../utils/debounce';
import PromiseQueue from '../shared/PromiseQueue/PromiseQueue';
import Prompt from '../shared/Prompt/Prompt';
import TaskMeta from './TaskMeta';
import { type OnTaskCompleted } from './TaskDetail';
import { type Task } from './TaskTypes';
import logError from '../utils/logError';
import RunLink from '../Run/RunLink';
import { type Run } from '../Run/RunTypes';
import { type Actor } from '../User/ActorTypes';
import withAnalytics, {
  type Analytics,
  DEFAULT_ANALYTICS
} from '../Analytics/withAnalytics';
import { TYPE as SEARCH_TYPE } from '../Search/SearchTypes';

const FIELD_SET = [];

// This copy is hard-coded in the instructions for Fix tasks, but is no longer
// accurate. The screen uses this to omit the outdated portion of the
// instructions for Fix tasks and add its own relevant instructions.
const FIX_TASK_DESCRIPTION_INSTRUCTIONS =
  'Please edit the field value in the task, select **Retry task now** from the dropdown and select **Complete** to retry the task. If you would rather bypass this task, select **Skip task** and then select **Complete**.';

const FIX_TASK_PROCEED_FIELD_NAME_PREFIX = 'retry-or-skip-fix';

const RETRY_OR_SKIP_VALUE_SKIP = 'Skip task';

export const Style = (theme: ThemeType) => ({
  // We render fix task descriptions via the Markdown component to allow
  // special formatting. This overrides the default Markdown text styles for
  // those so the text matches the common smallText styles.
  fixTaskDescription: {
    '& div': theme.typography.smallText
  },
  subTitle: theme.mixins.truncate,
  sectionSpacer: {
    paddingTop: '1rem'
  }
});

export const StatusQuery = gql`
  query StatusQuery($id: ID!) {
    task(id: $id) {
      id
      status
    }
  }
`;

export const UpdateFieldValue = gql`
  mutation UpdateFieldValue($input: UpdateFieldValueInput!) {
    updateFieldValue(input: $input) {
      id
      value
    }
  }
`;

export const Complete = gql`
  mutation Complete($input: CompleteTaskInput!) {
    completeTask(input: $input) {
      node {
        id
        status
      }
    }
  }
`;

export const ReopenTask = gql`
  mutation ReopenTask($input: ReopenTaskInput!) {
    reopenTask(input: $input) {
      node {
        id
      }
    }
  }
`;
export const StartTask = gql`
  mutation StartTask($input: StartTaskInput!) {
    startTask(input: $input) {
      node {
        id
        status
      }
    }
  }
`;

const messages = defineMessages({
  confirmReopen: {
    id: 'confirmReopen',
    defaultMessage:
      'Reopening this step will also reopen any previously completed tasks and actions that were dependent on it. Do you want to proceed?',
    description: 'Confirmation box text when reopening a step'
  },
  confirmForceComplete: {
    id: 'confirmForceComplete',
    defaultMessage:
      'This step is still pending. A Force Complete will attempt to complete the step immediately by ignoring all conditions and dependencies—any fields are submitted as is. This can affect an entire instance. Do you want to proceed?',
    description: 'Confirmation box text when forcing a step to complete'
  },
  confirmForceStart: {
    id: 'confirmForceStart',
    defaultMessage:
      'This step is still pending. A Force Start will attempt to start the step immediately by ignoring all conditions and dependencies. This can affect an entire instance. Do you want to proceed?',
    description: 'Confirmation box text when forcing a step to start'
  }
});

type MenuButtonProps = {
  disabled: boolean,
  id: string
};

const ReOpenMenuButton = ({ disabled, id }: MenuButtonProps) => {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  return (
    <Mutation
      mutation={ReopenTask}
      onCompleted={result => {
        history.replace(
          path.join(TASKS_V2_PATH, result?.reopenTask?.node?.id),
          location.state
        );
      }}
    >
      {reopenTask => (
        <MenuItem
          disabled={disabled}
          onClick={() => {
            const result = confirm(intl.formatMessage(messages.confirmReopen));
            if (result) {
              reopenTask({
                __typename: 'Mutation',
                variables: {
                  input: {
                    id: id
                  }
                }
              });
            }
          }}
        >
          <span>
            <FormattedMessage id="action.reopen" defaultMessage="Reopen" />
          </span>
        </MenuItem>
      )}
    </Mutation>
  );
};

const ForceStartButton = ({ disabled, id }: MenuButtonProps) => {
  const intl = useIntl();
  return (
    <Mutation
      mutation={StartTask}
      refetchQueries={() => ['Notifications', 'TaskQuery']}
    >
      {startTask => (
        <MenuItem
          disabled={disabled}
          onClick={() => {
            const result = confirm(
              intl.formatMessage(messages.confirmForceStart)
            );
            if (result) {
              startTask({
                __typename: 'Mutation',
                variables: {
                  input: {
                    id: id
                  }
                },
                optimisticResponse: {
                  startTask: {
                    node: {
                      id: id,
                      status: RUNNING,
                      __typename: 'Task'
                    },
                    __typename: 'StartTaskPayload'
                  },
                  __typename: 'Mutation'
                }
              });
            }
          }}
        >
          <span>
            <FormattedMessage id="action.start" defaultMessage="Force Start" />
          </span>
        </MenuItem>
      )}
    </Mutation>
  );
};
const ForceCompleteButton = ({ disabled, id }: MenuButtonProps) => {
  const intl = useIntl();
  return (
    <Mutation
      mutation={Complete}
      refetchQueries={() => ['Notifications', 'TaskQuery']}
    >
      {completeTask => (
        <MenuItem
          disabled={disabled}
          onClick={() => {
            const result = confirm(
              intl.formatMessage(messages.confirmForceComplete)
            );
            if (result) {
              completeTask({
                variables: {
                  input: {
                    id: id
                  }
                },
                optimisticResponse: {
                  completeTask: {
                    node: {
                      id: id,
                      status: 'completed',
                      viewerCanReopen: true,
                      __typename: 'Task'
                    },
                    __typename: 'CompleteTaskPayload'
                  },
                  __typename: 'Mutation'
                }
              });
            }
          }}
        >
          <span>
            <FormattedMessage
              id="action.force.complete"
              defaultMessage="Force Complete"
            />
          </span>
        </MenuItem>
      )}
    </Mutation>
  );
};

type Props = InjectSheetProvidedProps &
  ContextRouter & {
    __typename: 'Task',
    analytics: Analytics,
    assignedTo?: Actor,
    context: Run,
    deadline?: string,
    delayStartUntil?: string,
    description?: string,
    displayName?: string,
    fieldValues?: { [string]: any },
    id: string,
    inputs: Array<FieldType>,
    loading: boolean,
    onTaskCompleted?: OnTaskCompleted,
    relatedFailedTask?: ?Task,
    startDate?: string,
    status: Status,
    viewerCanComplete: boolean,
    viewerCanOpen: boolean,
    viewerCanReassign: boolean,
    viewerCanReopen: boolean,
    viewerCanSnooze: boolean
  };

type State = {
  expressionContextUpdating: boolean,
  isSkipping: boolean,
  sessionValues: Inputs,
  shouldStopPolling: boolean
};

export class TaskWebform extends PureComponent<Props, State> {
  static displayName = 'TaskWebform';
  static defaultProps = {
    analytics: DEFAULT_ANALYTICS
  };

  formRef = null;

  state = {
    expressionContextUpdating: false,
    isSkipping: false,
    sessionValues: [],
    shouldStopPolling: false
  };

  componentDidCatch(error: Error) {
    // Catch errors to Rollbar instead of crashing the entire page
    logError(new Error(`[Component-caught error]: ${error.toString()}`), {
      useWarning: true
    });
  }

  render() {
    const {
      __typename,
      analytics,
      assignedTo,
      classes,
      context: run,
      context: {
        id: contextId,
        owner,
        status: contextStatus,
        testMode = null
      } = {},
      deadline,
      delayStartUntil,
      description: descriptionProp,
      displayName,
      fieldValues,
      id: taskId,
      inputs: inputsProp,
      loading: webformLoading,
      location,
      onTaskCompleted,
      relatedFailedTask,
      startDate,
      status: taskStatus,
      viewerCanComplete,
      viewerCanOpen,
      viewerCanReassign,
      viewerCanReopen,
      viewerCanSnooze
    } = this.props;
    const {
      expressionContextUpdating,
      isSkipping,
      sessionValues,
      shouldStopPolling
    } = this.state;
    const pathname =
      location?.state?.background?.pathname || location?.pathname || '/';
    let inputs = inputsProp || [];

    const isFixTask =
      relatedFailedTask != null && relatedFailedTask?.configuration;

    // Hide the instructions hard-coded in the fix task description
    const description = isFixTask
      ? (descriptionProp || '').replace(FIX_TASK_DESCRIPTION_INSTRUCTIONS, '')
      : descriptionProp;

    // Move fix task fields other than the retry or skip prompt into the action
    // configuration section so the user can manage them in one place
    let retryOrSkipField;
    let fixTaskInputs = [];
    const hasFixTaskRetryOrSkipField =
      (inputs[0]?.name || '').indexOf(FIX_TASK_PROCEED_FIELD_NAME_PREFIX) === 0;
    if (isFixTask && hasFixTaskRetryOrSkipField) {
      retryOrSkipField = inputs[0];
      fixTaskInputs = inputs.slice(1);
      inputs = [];
    }

    const values = (inputs || []).map((input: FieldType) => {
      const { id, name, type, value: valueProp } = input;

      // Support optional chaining (https://github.com/facebook/flow/issues/4303)
      // $FlowIgnore
      const sessionValue = sessionValues?.find(data => data?.id === id)?.value;
      const value = getFieldValue(
        sessionValue !== undefined ? sessionValue : valueProp,
        type
      );

      return { id, name, value };
    });
    const contextValues = (values || []).reduce((accumulator, currentValue) => {
      accumulator[currentValue?.name] = currentValue?.value;
      return accumulator;
    }, {});
    const onChange = () => {
      this.setState({
        expressionContextUpdating: false
      });

      if (this.formRef) {
        const formData = new FormData(this.formRef); // eslint-disable-line no-undef
        const data = getFieldInputs({
          formData,
          inputs
        });

        this.setState({
          sessionValues: data
        });
      }
    };

    return (
      <Mutation
        mutation={UpdateFieldValue}
        onError={err => {
          const ERR = new Error(`[Webform Mutation] ${err}`);
          logError(ERR);
        }}
      >
        {(updateFieldValue, { loading: mutationLoading }) => {
          return (
            <Mutation
              mutation={Complete}
              // Refetch task status and permissions after completing a task.
              // Refetch unread notifications after completing a task.
              refetchQueries={() =>
                process.env.NODE_ENV !== 'test'
                  ? ['UnreadNotifications', 'TaskQuery']
                  : // Clicking Complete button in packages/catalytic-web/src/Tasks/TaskWebform.test.js
                    // errors when trying to resolve these refetchQueries. I wasn't
                    // able to figure out how to handle, so skipping the
                    // refetchQueries in test.
                    []
              }
            >
              {(completeTask, { loading: submitLoading, error }) => {
                if (error) logError(error, { silenceInTest: false });
                const buttonDisabled =
                  !assignedTo ||
                  !viewerCanComplete ||
                  webformLoading ||
                  submitLoading ||
                  isCompleted(taskStatus);
                const buttonLoading =
                  webformLoading ||
                  mutationLoading ||
                  submitLoading ||
                  expressionContextUpdating;
                return (
                  <>
                    {mutationLoading && (
                      <Prompt
                        when={mutationLoading && !isCompleted(taskStatus)}
                      />
                    )}
                    {contextId && (
                      <ExpressionContext
                        context={contextId}
                        fetchPolicy="cache-and-network"
                      >
                        {({ contextLoading, evaluate, update }) => {
                          // Make fields readOnly if task/run isComplete or
                          // viewerCanComplete is falsy
                          const readOnly =
                            isCompleted(taskStatus) ||
                            isCompleted(contextStatus) ||
                            viewerCanComplete === false;

                          return (
                            <PromiseQueue>
                              {({ add }) => {
                                const handleSubmit = (options?: {
                                  skipFixTask?: boolean
                                }) => async () => {
                                  const { skipFixTask } = options || {};
                                  if (skipFixTask) {
                                    const input = {
                                      id: retryOrSkipField.id,
                                      value: RETRY_OR_SKIP_VALUE_SKIP
                                    };
                                    this.setState({ isSkipping: true });
                                    add('update', () =>
                                      updateFieldValue({
                                        variables: {
                                          input
                                        },
                                        optimisticResponse: {
                                          __typename: 'Mutation',
                                          updateFieldValue: {
                                            __typename: 'Field',
                                            ...input
                                          }
                                        }
                                      })
                                    );
                                  }
                                  add('complete', () =>
                                    completeTask({
                                      variables: {
                                        input: {
                                          id: taskId
                                        }
                                      },
                                      optimisticResponse: {
                                        completeTask: {
                                          node: {
                                            id: taskId,
                                            status: 'completed',
                                            viewerCanReopen: true,
                                            __typename: 'Task'
                                          },
                                          __typename: 'CompleteTaskPayload'
                                        },
                                        __typename: 'Mutation'
                                      }
                                    }).then(() => {
                                      showToast(
                                        TOAST_TYPE.success,
                                        <FormattedMessage
                                          id="task.complete.success"
                                          defaultMessage="Task completed! {link}"
                                          values={{
                                            link: <RunLink run={run} />
                                          }}
                                        />
                                      );

                                      this.setState({
                                        shouldStopPolling: true
                                      });

                                      if (
                                        typeof onTaskCompleted === 'function'
                                      ) {
                                        onTaskCompleted();
                                      }

                                      if (
                                        typeof analytics?.track === 'function'
                                      ) {
                                        analytics.track('completeTask', {
                                          category: SEARCH_TYPE.TASK,
                                          label: pathname
                                        });
                                      }
                                    })
                                  );
                                };
                                return (
                                  <WebformView
                                    disabled={contextLoading}
                                    hasSubmit={false}
                                    onSubmit={handleSubmit()}
                                    ref={el => (this.formRef = el)}
                                  >
                                    <PageHeader
                                      badge={
                                        <Query
                                          fetchPolicy="network-only"
                                          pollInterval={15000} // 15 seconds
                                          query={StatusQuery}
                                          variables={{ id: taskId }}
                                          skip={isCompleted(taskStatus)}
                                        >
                                          {({ data, stopPolling }) => {
                                            // Stop polling if the component tells us to. This is necessary
                                            // because Query isn't smart enough to stop polling on its own
                                            // when the component is unmounted after completing the task.
                                            if (shouldStopPolling) {
                                              stopPolling();
                                            }
                                            const status = data?.task?.status;
                                            return status ? (
                                              <DetailBadge status={status} />
                                            ) : null;
                                          }}
                                        </Query>
                                      }
                                      disableModalBreadcrumb
                                      disableStickyHeader="mobile"
                                      includeLine
                                      title={displayName}
                                      buttons={
                                        isFixTask ? (
                                          [
                                            <ButtonInput
                                              loading={
                                                !isSkipping && buttonLoading
                                              }
                                              disabled={buttonDisabled}
                                              id={taskId}
                                              key="button-retry-task"
                                              type="submit"
                                            >
                                              <FormattedMessage
                                                id="action.complete.retry"
                                                defaultMessage="Retry Action"
                                              />
                                            </ButtonInput>,
                                            <ButtonInput
                                              loading={
                                                isSkipping && buttonLoading
                                              }
                                              disabled={buttonDisabled}
                                              id={taskId}
                                              key="button-skip-task"
                                              onClick={handleSubmit({
                                                skipFixTask: true
                                              })}
                                            >
                                              <FormattedMessage
                                                id="action.complete.skip"
                                                defaultMessage="Skip Action"
                                              />
                                            </ButtonInput>
                                          ]
                                        ) : (
                                          <ButtonInput
                                            loading={buttonLoading}
                                            disabled={buttonDisabled}
                                            id={taskId}
                                            key="button-complete-task"
                                            type="submit"
                                          >
                                            <FormattedMessage
                                              id="action.complete"
                                              defaultMessage="Complete"
                                            />
                                          </ButtonInput>
                                        )
                                      }
                                      subTitle={
                                        <div className={classes.subTitle}>
                                          {startDate ? (
                                            <>
                                              <FormattedMessage
                                                id="assign.task"
                                                defaultMessage="Assigned"
                                              />{' '}
                                              <FormattedDate
                                                value={startDate}
                                                year="numeric"
                                                month="long"
                                                day="2-digit"
                                              />
                                            </>
                                          ) : null}
                                          {deadline ? (
                                            <>
                                              {' | '}
                                              <FormattedMessage
                                                id="deadline.date"
                                                defaultMessage="Due Date:"
                                              />{' '}
                                              <FormattedDate
                                                value={deadline}
                                                year="numeric"
                                                month="long"
                                                day="2-digit"
                                              />
                                              {' | '}
                                            </>
                                          ) : startDate ? (
                                            <span> {' | '} </span>
                                          ) : null}
                                          <RunLink prefix={null} run={run} />
                                        </div>
                                      }
                                      menu={
                                        <Menu placement="bottom-end">
                                          <ReOpenMenuButton
                                            disabled={
                                              webformLoading || !viewerCanReopen
                                            }
                                            id={taskId}
                                          />
                                          <ForceStartButton
                                            disabled={
                                              webformLoading || !viewerCanOpen
                                            }
                                            id={taskId}
                                          />
                                          <ForceCompleteButton
                                            disabled={
                                              webformLoading ||
                                              !!assignedTo ||
                                              isCompleted(taskStatus) ||
                                              isCompleted(contextStatus)
                                            }
                                            id={taskId}
                                          />
                                        </Menu>
                                      }
                                    >
                                      <TaskMeta
                                        assignedTo={assignedTo}
                                        deadline={deadline}
                                        delayStartUntil={delayStartUntil}
                                        id={taskId}
                                        viewerCanReassign={viewerCanReassign}
                                        viewerCanSnooze={viewerCanSnooze}
                                      />
                                    </PageHeader>
                                    {isFixTask && (
                                      <>
                                        <Header2
                                          className={classes.sectionSpacer}
                                        >
                                          <FormattedMessage
                                            id="task.error.header"
                                            defaultMessage="Issue Details"
                                          />
                                        </Header2>
                                        <Input
                                          className={classes.fixTaskDescription}
                                        >
                                          <Markdown value={description} />
                                        </Input>
                                      </>
                                    )}
                                    {!isFixTask && description && (
                                      <Input>
                                        <Markdown value={description} />
                                      </Input>
                                    )}
                                    {contextLoading ? (
                                      <TopBarLoader /> // Loading conditional fields
                                    ) : (
                                      <>
                                        {inputs.map((input: FieldType) => {
                                          const {
                                            condition,
                                            context,
                                            displayOnly,
                                            format,
                                            id,
                                            name,
                                            required,
                                            type,
                                            value: valueProp,
                                            ...props
                                          } = input;

                                          // Support optional chaining (https://github.com/facebook/flow/issues/4303)
                                          // $FlowIgnore
                                          const value = values?.find(
                                            data => data?.id === id
                                          )?.value;

                                          const handleChange = (
                                            ...args: Array<any>
                                          ) => {
                                            const [{ currentTarget }] = args;
                                            const { value: valueProp } =
                                              currentTarget || {};
                                            const value = getFieldValue(
                                              valueProp,
                                              type
                                            );

                                            // Update conditional fields
                                            debounce(
                                              () => {
                                                update(name, value);
                                              },
                                              {
                                                key: `${id}:update`
                                              }
                                            );

                                            if (!expressionContextUpdating) {
                                              this.setState({
                                                expressionContextUpdating: true
                                              });
                                            }

                                            debounce(() => onChange(...args));

                                            // Required fields
                                            if (
                                              required &&
                                              fieldValueIsEmpty(value)
                                            ) {
                                              return;
                                            }

                                            // Update fields
                                            debounce(
                                              () =>
                                                add('update', () =>
                                                  updateFieldValue({
                                                    variables: {
                                                      input: { id, value }
                                                    },
                                                    optimisticResponse: {
                                                      __typename: 'Mutation',
                                                      updateFieldValue: {
                                                        __typename: 'Field',
                                                        id,
                                                        value
                                                      }
                                                    }
                                                  })
                                                ),
                                              { key: `${id}:updateFieldValue` }
                                            );
                                          };

                                          // Conditional fields
                                          if (
                                            typeof condition === 'string' &&
                                            evaluate(condition) === false
                                          ) {
                                            if (FIELD_SET.includes(id)) {
                                              // Update fields (except Table fields and Instructional fields)
                                              if (
                                                format !== FORMAT.TABLE &&
                                                !displayOnly
                                              ) {
                                                debounce(
                                                  () =>
                                                    add('update', () =>
                                                      updateFieldValue({
                                                        variables: {
                                                          input: {
                                                            id,
                                                            value: null
                                                          }
                                                        },
                                                        optimisticResponse: {
                                                          __typename:
                                                            'Mutation',
                                                          updateFieldValue: {
                                                            __typename: 'Field',
                                                            id,
                                                            value: null
                                                          }
                                                        }
                                                      })
                                                    ),
                                                  {
                                                    key: `${id}:updateFieldValue`
                                                  }
                                                );
                                              }

                                              // Remove field from field set
                                              FIELD_SET.splice(
                                                FIELD_SET.indexOf(id),
                                                1
                                              );
                                            }

                                            // Don't render an input in the form
                                            // since the field's condition is
                                            // false.
                                            return;
                                          }

                                          if (!FIELD_SET.includes(id)) {
                                            // Add field to field set
                                            FIELD_SET.push(id);
                                          }

                                          return (
                                            <Field
                                              key={id}
                                              context={{
                                                __typename,
                                                id: taskId
                                              }}
                                              contextValues={{
                                                ...fieldValues,
                                                ...contextValues
                                              }}
                                              onChange={handleChange}
                                              {...{
                                                displayOnly,
                                                format,
                                                id,
                                                name,
                                                readOnly,
                                                required,
                                                type,
                                                value,
                                                ...props
                                              }}
                                            />
                                          );
                                        })}
                                        {isFixTask &&
                                          relatedFailedTask &&
                                          viewerCanComplete && (
                                            <FailedTaskActionConfiguration
                                              className={classes.sectionSpacer}
                                              contextId={contextId}
                                              failedTask={relatedFailedTask}
                                              fixTaskInputs={fixTaskInputs}
                                              ownerID={owner?.id}
                                              promiseQueueAdd={add}
                                              taskId={taskId}
                                              taskStatus={taskStatus}
                                              testMode={testMode}
                                              updateFieldValue={
                                                updateFieldValue
                                              }
                                            />
                                          )}
                                      </>
                                    )}
                                  </WebformView>
                                );
                              }}
                            </PromiseQueue>
                          );
                        }}
                      </ExpressionContext>
                    )}
                  </>
                );
              }}
            </Mutation>
          );
        }}
      </Mutation>
    );
  }
}

export default compose(
  withAnalytics,
  withRouter,
  injectSheet(Style)
)(TaskWebform);
