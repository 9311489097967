// @flow
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import classNames from 'classnames';
import { Mutation, Query, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import {
  ButtonInput,
  FormattedRelative,
  Header5,
  injectSheet,
  ListItem,
  ListItemBody,
  ListTitle,
  ListTitleHeader,
  ListView,
  Markdown,
  MentionInput,
  SCIENCE_BLUE,
  SmallText,
  WebformView,
  type InjectSheetProvidedProps,
  type ThemeType
} from '@catalytic/catalytic-ui';
import CommentAvatar from './CommentAvatar';
import userIconUrl from '@catalytic/catalytic-icons/lib/icons/user.svg';
import Rpath from 'ramda/src/path';
import { RunCommentFragment } from '../Run/RunTypeDefs';
import logError from '../utils/logError';

const Messages = defineMessages({
  addAComment: {
    id: 'rundetailcomments.addAComment',
    defaultMessage: 'Add a comment, type @ to mention and notify team members'
  }
});

export const Style = (theme: ThemeType) => {
  return {
    root: {
      position: 'relative',
      margin: '2.25rem 0'
    },
    mentionInput: {
      minHeight: '5.5rem'
    },
    time: {
      color: theme.colors.pebbleGrey,
      marginLeft: '.25rem'
    },
    button: {
      margin: '1rem 0'
    },
    loading: {
      color: theme.colors.scienceBlue
    },
    error: {
      color: theme.colors.dullRed
    }
  };
};

export type Props = InjectSheetProvidedProps & {
  intl: Object,
  id: string,
  client: { query: (options: Object) => Promise<Object> }
};

const NEW = gql`
  mutation AddComment($input: AddCommentInput!) {
    addComment(input: $input) {
      comment {
        id
        body
      }
    }
  }
`;

export const CommentsQuery = gql`
  ${RunCommentFragment}

  query CommentsQuery($id: String!) {
    context(id: $id) {
      id
      comments(first: 100) {
        totalCount
        nodes {
          id
          ...RunCommentFragment
        }
      }
    }
  }
`;

export const ViewerQuery = gql`
  query ViewerQuery {
    viewer {
      id
      fullName
      nickname
      thumbnailUrl
    }
  }
`;

export const ActorQuery = gql`
  query ActorQuery($query: String) {
    search_v2(
      type: [ACTOR]
      filter: [
        { property: IS_DEACTIVATED, type: ACTOR, operator: EQ, value: "false" }
        { property: IS_INTEGRATION, type: ACTOR, operator: EQ, value: "false" }
        { property: IS_GROUP, type: ACTOR, operator: EQ, value: "false" }
      ]
      query: $query
      sort: [{ key: DISPLAY_NAME, direction: ASC }]
    ) {
      edges {
        node {
          id
          displayName
          ... on Linkable {
            ... on Actor {
              email
              thumbnailUrl
            }
          }
        }
      }
    }
  }
`;

export const RunComments = ({
  className,
  classes,
  client,
  id,
  intl
}: Props) => {
  const [commentText, setCommentText] = React.useState<string>('');
  const clearCommentText = () => setCommentText('');

  if (!id) {
    return null;
  }
  return (
    <ListView className={classNames(classes.root, className)}>
      <ListTitle>
        <ListTitleHeader>
          <FormattedMessage
            id="rundetailcomments.header"
            defaultMessage="Comments"
          />
        </ListTitleHeader>
      </ListTitle>

      <Query
        errorPolicy="all"
        fetchPolicy="network-only"
        query={CommentsQuery}
        variables={{ id }}
      >
        {({ data }) => {
          const comments = Rpath(['context', 'comments', 'nodes'])(data);

          return (
            <>
              {(comments || []).map(
                comment =>
                  comment && (
                    <ListItem key={comment.id}>
                      <CommentAvatar
                        image={
                          (comment.actor && comment.actor.thumbnailUrl) ||
                          userIconUrl
                        }
                        title={comment.actor && comment.actor.nickname}
                      />
                      <ListItemBody>
                        {comment.actor && (
                          <strong>{comment.actor.fullName}</strong>
                        )}
                        {comment.createdAt && (
                          <SmallText className={classes.time}>
                            <FormattedRelative value={comment.createdAt} />
                          </SmallText>
                        )}
                        <Markdown value={comment.body} />
                      </ListItemBody>
                    </ListItem>
                  )
              )}
            </>
          );
        }}
      </Query>
      <WebformView hasSubmit={false}>
        <Query
          query={ActorQuery}
          fetchPolicy="cache-and-network"
          errorPolicy="all"
        >
          {({ data, error }) => {
            logError(error);

            const userList = Rpath(['search_v2', 'edges'])(data);
            if (error || !userList) return false;
            return (
              <Query query={ViewerQuery}>
                {({ data: { viewer = {} } = {} }) => {
                  const { thumbnailUrl, nickname } = viewer;
                  return (
                    <Mutation mutation={NEW} refetchQueries={['CommentsQuery']}>
                      {(addComment, { loading, error }) => {
                        return (
                          <ListItem>
                            <CommentAvatar
                              image={thumbnailUrl || userIconUrl}
                              title={nickname}
                            />
                            <ListItemBody>
                              <MentionInput
                                data={(search, callback) =>
                                  client
                                    .query({
                                      errorPolicy: 'all',
                                      query: ActorQuery,
                                      variables: {
                                        query: search || ''
                                      }
                                    })
                                    .then(({ data }) => {
                                      const error = Rpath(['error'])(data);
                                      logError(error);
                                      const userList = Rpath([
                                        'search_v2',
                                        'edges'
                                      ])(data);
                                      if (error || !userList) return [];

                                      // Create "expected shape" mentionList object
                                      const mentionList = Object.keys(
                                        userList
                                      ).map(e => {
                                        const user = userList[e].node;
                                        return {
                                          display: user.displayName,
                                          id: user.id,
                                          email: user.email,
                                          icon: user.thumbnailUrl || userIconUrl
                                        };
                                      });
                                      return mentionList;
                                    })
                                    .then(callback)
                                }
                                onChange={event =>
                                  setCommentText(event.currentTarget.value)
                                }
                                mentionInputClassName={classes.mentionInput}
                                placeholder={intl.formatMessage(
                                  Messages.addAComment
                                )}
                                value={commentText}
                              />
                              <ButtonInput
                                compact
                                colorScheme={SCIENCE_BLUE}
                                onClick={() => {
                                  // Disallow empty comments
                                  if (commentText.trim().length > 0) {
                                    addComment({
                                      __typename: 'Mutation',
                                      variables: {
                                        input: {
                                          context: { id },
                                          body: commentText
                                        }
                                      }
                                    });
                                  }
                                  clearCommentText();
                                }}
                                className={classes.button}
                              >
                                <FormattedMessage
                                  id="rundetailcomments.action.comment"
                                  defaultMessage="Add comment"
                                />
                              </ButtonInput>
                              {loading && (
                                <Header5 className={classes.loading}>
                                  <FormattedMessage
                                    id="comments.loading"
                                    defaultMessage="Loading..."
                                  />
                                </Header5>
                              )}
                              {error && (
                                <Header5 className={classes.error}>
                                  <FormattedMessage
                                    id="rundetail.comment.error"
                                    defaultMessage="There was a problem saving your comment."
                                  />
                                </Header5>
                              )}
                            </ListItemBody>
                          </ListItem>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </WebformView>
    </ListView>
  );
};
RunComments.displayName = 'RunComments';

export default compose(injectIntl, injectSheet(Style), withApollo)(RunComments);
