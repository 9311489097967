// @flow

import React from 'react';
import {
  InputV2,
  InputConsumer,
  CheckboxInputV2
} from '@catalytic/catalytic-ui';
import { types } from '@catalytic/react-view';
import useFieldContext from './FieldContext';
import { useHandleChange } from './ViewHelpers';

const CheckboxView = ({
  node: getNode,
  schema: getSchema,
  setValue,
  value
}: types.CheckboxView) => {
  const schema = getSchema();
  const fieldContext = useFieldContext();
  const node = getNode();
  const {
    className,
    contextValues,
    description,
    disabled,
    displayErrors,
    displayName,
    example,
    fieldName,
    hasValidation,
    id,
    label,
    name,
    onChange,
    readOnly,
    type
  } = fieldContext || {};
  const handleChange = useHandleChange({ onChange, setValue, type });

  return (
    <InputV2
      className={className}
      contextValues={contextValues}
      description={description}
      disabled={disabled}
      displayErrors={displayErrors}
      example={example}
      fieldName={fieldName}
      hasValidation={hasValidation}
      id={id}
      name={name}
      onChange={handleChange}
      readOnly={readOnly}
      schema={schema}
      title={displayName}
      type={type}
      value={value}
    >
      <InputConsumer>
        {props => (
          <CheckboxInputV2
            defaultValue={value}
            id={id}
            label={node.label}
            name={name}
            placeholder={label}
            required={schema?.nullable === false}
            value={value}
            {...props}
          />
        )}
      </InputConsumer>
    </InputV2>
  );
};
CheckboxView.displayName = 'CheckboxView';

export default React.memo<types.CheckboxView>(CheckboxView);
