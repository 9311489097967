// @flow

import classNames from 'classnames';
import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import { type InjectSheetProvidedProps } from '../../style/ThemeTypes';

const Style = {
  root: {
    padding: '1rem 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '.5rem'
  }
};

type Props = InjectSheetProvidedProps;

const ListTitle = ({ classes, className, theme, ...props }: Props): Node => (
  <div className={classNames(classes.root, className)} {...props} />
);

ListTitle.displayName = 'ListTitle';
export default injectSheet(Style)(ListTitle);
