// @flow

import breakpoints from './breakpoints';
import colors from './colors';
import functions from './functions';
import typography from './typography';
import variables from './variables';

const INPUT_PADDING = {
  paddingTop: functions.toRem(variables.inputPaddingTop),
  paddingRight: functions.toRem(variables.inputPaddingRight),
  paddingBottom: functions.toRem(variables.inputPaddingBottom),
  paddingLeft: functions.toRem(variables.inputPaddingLeft)
};
const INPUT_READONLY_VALUE = {
  backgroundColor: colors.blueGrey,
  boxShadow: 'none',
  color: colors.battleshipGrey,
  // readonly styles should override disabled styles
  '-webkit-text-fill-color': colors.battleshipGrey,
  '-webkit-opacity': 1,
  cursor: 'text',
  pointerEvents: 'auto'
};
const INPUT_DISABLE_VALUE = {
  ...INPUT_READONLY_VALUE,
  color: colors.battleshipGrey,
  // Safari overrides font color for disabled inputs
  // https://stackoverflow.com/questions/262158/disabled-input-text-color
  '-webkit-text-fill-color': colors.battleshipGrey,
  '-webkit-opacity': 1,
  cursor: 'default', // not-allowed
  pointerEvents: 'none'
};
const INPUT_FOCUS_VALUE = {
  borderColor: colors.blueGrey,
  backgroundColor: colors.blueGrey,
  boxShadow: 'none'
};
const INPUT_PLACEHOLDER_VALUE = {
  ...typography.baseText,
  color: colors.battleshipGrey,
  cursor: 'text'
};
const VERTICAL_ALIGN_VALUE = {
  // Requires relatively positioned parent element
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)'
};
const COLOR_KEYS = Object.keys(colors);
const SELECT_DISPLAY_NAME = 'MultiSelectInput';

const mixins = {
  /* Avatar
  /* ----------------------------------- */
  circle: {
    borderRadius: '50%',
    height: '2.125em',
    width: '2.125em'
  },
  /* Collection View
  /* ----------------------------------- */
  galleryGrid: {
    '@media (min-width: 490px)': {
      width: 'calc(100% / 2)'
    },
    '@media (min-width: 794px)': {
      width: 'calc(100% / 3)'
    },
    '@media (min-width: 1020px)': {
      width: 'calc(100% / 4)'
    },
    '@media (min-width: 1340px)': {
      width: 'calc(100% / 5)'
    },
    '@media (min-width: 1828px)': {
      width: 'calc(100% / 6)'
    },
    '@media (min-width: 2240px)': {
      width: 'calc(100% / 7)'
    }
  },
  /* Link
  /* ----------------------------------- */
  link: {
    color: colors.textLink,
    backgroundColor: 'transparent',
    textDecoration: 'none',
    '&:active': {
      opacity: 0.8,
      textDecoration: 'none'
    }
  },
  unsetLink: {
    color: colors.black,
    '&:active': {
      opacity: 1
    }
  },
  /* Modal View
  /* ----------------------------------- */
  bodyOpen: {
    overflow: 'hidden'
  },
  /* Info Bar
  /* ----------------------------------- */
  infoBarLabel: {
    ...typography.extraSmallText,
    fontSize: '0.625rem',
    fontWeight: 500,
    letterSpacing: '.0625rem',
    marginBottom: '.25rem',
    display: 'block',
    textTransform: 'uppercase',
    color: colors.pebbleGrey
  },
  /* Inputs
  /* ----------------------------------- */
  inputStyle: {
    ...typography.baseText,
    ...INPUT_PADDING,
    appearance: 'none', // Reset the default appearance
    borderRadius: variables.borderRadius,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.blueGrey,
    backgroundColor: colors.blueGrey,
    boxShadow: 'none',
    boxSizing: 'border-box',
    width: '100%',
    position: 'relative',
    cursor: 'text',
    '&:focus, &:hover': INPUT_FOCUS_VALUE,
    '&[readonly]': {
      '&, &:focus, &:hover': INPUT_READONLY_VALUE
    },
    '&:disabled': {
      '&, &:focus, &:hover': INPUT_DISABLE_VALUE
    },
    '&::placeholder': INPUT_PLACEHOLDER_VALUE,
    '&::-webkit-input-placeholder': INPUT_PLACEHOLDER_VALUE,
    '&:-ms-input-placeholder': INPUT_PLACEHOLDER_VALUE,
    '&::-ms-input-placeholder': INPUT_PLACEHOLDER_VALUE,
    '&::-moz-placeholder': INPUT_PLACEHOLDER_VALUE,
    '&:-moz-placeholder': INPUT_PLACEHOLDER_VALUE
  },
  inputContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    marginLeft: '-.125rem',
    padding: 0,
    minHeight: '2.25rem',
    '-webkit-overflow-scrolling': 'touch',
    position: 'relative',
    boxSizing: 'border-box'
  },
  inputDisabled: INPUT_DISABLE_VALUE,
  inputEmpty: {
    ...typography.baseText,
    padding: 0,
    color: colors.battleshipGrey,
    textAlign: 'center'
  },
  inputEmbedded: {
    ...typography.smallText,
    height: '2rem',
    paddingTop: '.25rem',
    paddingBottom: '.25rem',
    lineHeight: '1.43rem',
    border: `1px solid ${colors.lightGrey}`,
    borderRadius: variables.borderRadiusSmall,
    backgroundColor: colors.ghostWhite,
    margin: 0,
    '&:hover, &:focus, &:focus-within': {
      backgroundColor: colors.ghostWhite,
      border: `1px solid ${colors.lightGrey}`
    }
  },
  inputError: {
    '&, &:focus, &:hover': {
      borderColor: `${colors.dullRed} !important`,
      boxShadow: `inset 0 0 0 1px ${colors.dullRed} !important`
    }
  },
  inputErrorMessage: {
    ...typography.extraSmallText,
    display: 'block',
    marginBottom: '.375rem',
    color: colors.dullRed,
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  },
  inputInlineErrorMessage: {
    ...typography.baseText,
    ...VERTICAL_ALIGN_VALUE,
    position: 'absolute',
    right: '.938rem',
    display: 'flex',
    alignItems: 'center',
    color: colors.dullRed
  },
  inputInlineErrorIcon: {
    flex: 'none',
    marginLeft: '.5rem'
  },
  inputFocus: INPUT_FOCUS_VALUE,
  inputMenu: {
    borderColor: colors.blueGrey,
    backgroundColor: colors.white,
    boxShadow: `0 .25rem .5rem ${colors.pastelGreyAlpha}`
  },
  inputMultiLabel: {
    ...typography.smallText,
    color: colors.black,
    borderRadius: variables.borderRadiusSmall,
    padding: '.188rem .5rem',
    boxSizing: 'border-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minHeight: '1.625rem'
  },
  inputMultiLabelDisabled: {
    color: colors.battleshipGrey
  },
  inputMultiPadding: {
    paddingTop: functions.toRem(variables.inputMultiPaddingTop),
    paddingBottom: functions.toRem(variables.inputMultiPaddingBottom)
  },
  inputMultiRemove: {
    color: colors.black,
    alignItems: 'center',
    borderRadius: variables.borderRadiusSmall,
    display: 'flex',
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:focus, &:hover': {
      backgroundColor: 'transparent',
      color: colors.black
    },
    '& svg': {
      display: 'inline-block',
      fill: colors.pastelGrey,
      lineHeight: 1,
      stroke: colors.pastelGrey,
      strokeWidth: 0
    }
  },
  inputMultiRemoveDisabled: {
    backgroundColor: 'transparent',
    color: colors.battleshipGrey
  },
  inputMultiValue: {
    backgroundColor: colors.ghostWhite,
    border: `1px solid ${colors.lightGrey}`,
    borderRadius: variables.borderRadiusSmall,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '.188rem',
    marginRight: '.375rem',
    marginBottom: '.188rem',
    marginLeft: 0,
    boxSizing: 'border-box',
    maxWidth: '100%'
  },
  inputMargin: {
    marginRight: `-${functions.toRem(variables.inputMarginRight)}`,
    marginLeft: `-${functions.toRem(variables.inputMarginLeft)}`
  },
  inputPadding: INPUT_PADDING,
  inputPlaceholder: INPUT_PLACEHOLDER_VALUE,
  inputReadOnly: INPUT_READONLY_VALUE,
  inputRequired: {
    ...VERTICAL_ALIGN_VALUE,
    ...typography.extraSmallText,
    right: functions.toRem(variables.inputPaddingLeft),
    color: colors.battleshipGrey,
    cursor: 'default',
    pointerEvents: 'none'
  },
  inputSuccess: {
    // Disabling valid input highlighting per
    // https://github.com/catalyticlabs/product/issues/5074
    // '&, &:focus, &:hover': {
    //   borderColor: `${colors.navyGreen} !important`
    // }
  },
  // These styles allow SwitchInput to be positioned in the top-right corner of the Input component
  // e.g. <Input className="inputSwitchContainer"><SwitchInput className="inputSwitch" /></Input>
  inputSwitchContainer: {
    position: 'relative',
    paddingRight: functions.toRem(variables.inputSwitchWidth + variables.base)
  },
  inputSwitch: {
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: '0 !important' // Override Input component margin-top styles
  },
  inputResponsiveDropDown: {
    [`& .${SELECT_DISPLAY_NAME}__control`]: {
      flexWrap: 'nowrap',
      paddingRight: '.75rem',
      paddingLeft: '.75rem',
      borderColor: colors.blueGrey,
      backgroundColor: colors.blueGrey,
      '&:hover, &:focus, &:focus-within': {
        borderColor: colors.blueGrey,
        backgroundColor: colors.blueGrey
      }
    },
    // These styles allow MultiSelectInput to auto-size based on selection
    [`& .${SELECT_DISPLAY_NAME}__single-value`]: {
      [breakpoints.gte.tablet.portrait]: {
        maxWidth: '9.5rem', // 152px based on "All Team Members" option
        minWidth: '2.375rem', // 38px to supplement "Any" option
        position: 'relative',
        top: 'auto',
        transform: 'none'
      }
    },
    [`& .${SELECT_DISPLAY_NAME}__value-container`]: {
      flexBasis: 'auto',
      flexWrap: 'nowrap',
      height: 0 // This hack fixes vertical-alignment issues in IE11
    },
    [`& .${SELECT_DISPLAY_NAME}__single-value-container`]: {
      display: 'flex',
      minWidth: 0
    },
    [`& .${SELECT_DISPLAY_NAME}__menu`]: {
      [breakpoints.gte.tablet.portrait]: {
        maxWidth: '21.875rem', // 350px based on the size of the `NavbarUser.js` menu
        minWidth: '100%',
        width: 'auto'
      }
    },
    '& input': {
      borderColor: colors.blueGrey,
      backgroundColor: colors.blueGrey,
      '&:hover, &:focus, &:focus-within': {
        borderColor: colors.blueGrey,
        backgroundColor: colors.blueGrey
      }
    }
  },
  /* Forms
  /* ----------------------------------- */
  formButton: {
    padding: '.625rem 1rem',
    width: '100%',
    borderRadius: variables.borderRadius
  },
  formMarginBottom: {
    marginBottom: '6.25rem',
    [breakpoints.tablet]: {
      marginBottom: '7.5rem'
    }
  },
  /* View All
  /* ----------------------------------- */
  viewAll: {
    ...typography.header6,
    backgroundColor: colors.blueGrey,
    borderTop: `1px solid ${colors.pastelGrey}`,
    color: colors.pebbleGrey,
    letterSpacing: '-0.012rem',
    padding: '0.75rem'
  },
  /* Disabled
  /* ----------------------------------- */
  disabled: {
    opacity: 0.5,
    outline: 'none',
    cursor: 'default', // not-allowed
    pointerEvents: 'none',
    filter: 'grayscale(100%)',
    backgroundColor: 'inherit',
    '&:active, &:focus, &:hover': {
      backgroundColor: 'inherit'
    }
  },
  /* Truncate
  /* ----------------------------------- */
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  /* Break word
   * https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
  /* ----------------------------------- */
  breakWord: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'none'
  },
  /* Vertical Align
  /* ----------------------------------- */
  verticalAlign: VERTICAL_ALIGN_VALUE,
  /* Visually Hidden
   *
   * Only display content to screen readers
   * See: https://a11yproject.com/posts/how-to-hide-content/
   * See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
  /* ----------------------------------- */
  visuallyHidden: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    border: 0
  },
  /* Color Scheme
  /* ----------------------------------- */
  colorScheme: {
    ...COLOR_KEYS.reduce((accumulator, color) => {
      const hex = colors[color];

      return {
        ...accumulator,
        [`&[data-color="${color}"]`]: {
          borderColor: hex,
          backgroundColor: hex,
          color: colors.white
        }
      };
    }, {})
  },
  /* Invert Scheme
  /* ----------------------------------- */
  invertScheme: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.pastelGrey}`,
    ...COLOR_KEYS.reduce((accumulator, color) => {
      const hex = colors[color];

      return {
        ...accumulator,
        [`&[data-color="${color}"]`]: {
          color: hex
        }
      };
    }, {})
  }
};

export default mixins;
