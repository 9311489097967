// @flow

import is from 'ramda/src/is';
import uniq from 'lodash/uniq';
import assignmentIconUrl, {
  ReactComponent as AssignmentIcon
} from '@catalytic/catalytic-icons/lib/icons/assignment.svg';
import browserIconUrl, {
  ReactComponent as BrowserIcon
} from '@catalytic/catalytic-icons/lib/icons/browser.svg';
import cognitiveIconUrl, {
  ReactComponent as CognitiveIcon
} from '@catalytic/catalytic-icons/lib/icons/cognitive.svg';
import conditionalIconUrl, {
  ReactComponent as ConditionalIcon
} from '@catalytic/catalytic-icons/lib/icons/conditional.svg';
import commentsIconUrl, {
  ReactComponent as CommentsIcon
} from '@catalytic/catalytic-icons/lib/icons/comments.svg';
import dataDeletionIconUrl, {
  ReactComponent as DataDeletionIcon
} from '@catalytic/catalytic-icons/lib/icons/data-deletion.svg';
import dataTableIconUrl, {
  ReactComponent as DataTableIcon
} from '@catalytic/catalytic-icons/lib/icons/data-table.svg';
import dateIconUrl, {
  ReactComponent as DateIcon
} from '@catalytic/catalytic-icons/lib/icons/date.svg';
import emailIconUrl, {
  ReactComponent as EmailIcon
} from '@catalytic/catalytic-icons/lib/icons/email.svg';
import eyeIconUrl, {
  ReactComponent as EyeIcon
} from '@catalytic/catalytic-icons/lib/icons/eye.svg';
import fileIconUrl, {
  ReactComponent as FileIcon
} from '@catalytic/catalytic-icons/lib/icons/file.svg';
import handlebarsIconUrl, {
  ReactComponent as HandlebarsIcon
} from '@catalytic/catalytic-icons/lib/icons/handlebars.svg';
import headingIconUrl, {
  ReactComponent as HeadingIcon
} from '@catalytic/catalytic-icons/lib/icons/heading.svg';
import imagesIconUrl, {
  ReactComponent as ImagesIcon
} from '@catalytic/catalytic-icons/lib/icons/images.svg';
import invoicesIconUrl, {
  ReactComponent as InvoicesIcon
} from '@catalytic/catalytic-icons/lib/icons/invoices.svg';
import languageIconUrl, {
  ReactComponent as LanguageIcon
} from '@catalytic/catalytic-icons/lib/icons/language.svg';
import listIconUrl, {
  ReactComponent as ListIcon
} from '@catalytic/catalytic-icons/lib/icons/list.svg';
import locationIconUrl, {
  ReactComponent as LocationIcon
} from '@catalytic/catalytic-icons/lib/icons/location.svg';
import iteratorIconUrl, {
  ReactComponent as IteratorIcon
} from '@catalytic/catalytic-icons/lib/icons/iterator.svg';
import noteIconUrl, {
  ReactComponent as NoteIcon
} from '@catalytic/catalytic-icons/lib/icons/note.svg';
import numbersIconUrl, {
  ReactComponent as NumbersIcon
} from '@catalytic/catalytic-icons/lib/icons/numbers.svg';
import personCardIconUrl, {
  ReactComponent as PersonCardIcon
} from '@catalytic/catalytic-icons/lib/icons/person-card.svg';
import runIconUrl, {
  ReactComponent as RunIcon
} from '@catalytic/catalytic-icons/lib/icons/run.svg';
import scheduledIconUrl, {
  ReactComponent as ScheduledIcon
} from '@catalytic/catalytic-icons/lib/icons/scheduled.svg';
import sentimentIconUrl, {
  ReactComponent as SentimentIcon
} from '@catalytic/catalytic-icons/lib/icons/sentiment.svg';
import sftpDownloadIconUrl, {
  ReactComponent as SftpDownloadIcon
} from '@catalytic/catalytic-icons/lib/icons/sftp-download.svg';
import sftpUploadIconUrl, {
  ReactComponent as SftpUploadIcon
} from '@catalytic/catalytic-icons/lib/icons/sftp-upload.svg';
import ssoIconUrl, {
  ReactComponent as SsoIcon
} from '@catalytic/catalytic-icons/lib/icons/sso.svg';
import systemNotificationIconUrl, {
  ReactComponent as SystemNotificationIcon
} from '@catalytic/catalytic-icons/lib/icons/system-notification.svg';
import textIconUrl, {
  ReactComponent as TextIcon
} from '@catalytic/catalytic-icons/lib/icons/text.svg';
import triggerIconUrl, {
  ReactComponent as TriggerIcon
} from '@catalytic/catalytic-icons/lib/icons/trigger.svg';
import webApiIconUrl, {
  ReactComponent as WebApiIcon
} from '@catalytic/catalytic-icons/lib/icons/web-api.svg';
import webformIconUrl, {
  ReactComponent as WebformIcon
} from '@catalytic/catalytic-icons/lib/icons/webform.svg';
import webhookIconUrl, {
  ReactComponent as WebhookIcon
} from '@catalytic/catalytic-icons/lib/icons/webhook.svg';
import workflowIconUrl, {
  ReactComponent as WorkflowIcon
} from '@catalytic/catalytic-icons/lib/icons/workflow.svg';
import wwwIconUrl, {
  ReactComponent as WwwIcon
} from '@catalytic/catalytic-icons/lib/icons/www.svg';
import xmlIconUrl, {
  ReactComponent as XmlIcon
} from '@catalytic/catalytic-icons/lib/icons/xml.svg';
import automationAnywhereIconUrl, {
  ReactComponent as AutomationAnywhereIcon
} from '@catalytic/catalytic-icons/lib/integrations/automation-anywhere.svg';
import awsIconUrl, {
  ReactComponent as AwsIcon
} from '@catalytic/catalytic-icons/lib/integrations/aws.svg';
import azureBlobStorageIconUrl, {
  ReactComponent as AzureBlobStorageIcon
} from '@catalytic/catalytic-icons/lib/integrations/azure-blob-storage.svg';
import azureIconUrl, {
  ReactComponent as AzureIcon
} from '@catalytic/catalytic-icons/lib/integrations/azure.svg';
import autoDeskIconUrl, {
  ReactComponent as AutoDeskIcon
} from '@catalytic/catalytic-icons/lib/integrations/autodesk.svg';
import boxIconUrl, {
  ReactComponent as BoxIcon
} from '@catalytic/catalytic-icons/lib/integrations/box.svg';
import circleCIIconUrl, {
  ReactComponent as CircleCIIcon
} from '@catalytic/catalytic-icons/lib/integrations/circleci.svg';
import coupaIconUrl, {
  ReactComponent as CoupaIcon
} from '@catalytic/catalytic-icons/lib/integrations/coupa.svg';
import csvIconUrl, {
  ReactComponent as CsvIcon
} from '@catalytic/catalytic-icons/lib/integrations/csv.svg';
import dayforceIconUrl, {
  ReactComponent as DayforceIcon
} from '@catalytic/catalytic-icons/lib/integrations/dayforce.svg';
import docusignIconUrl, {
  ReactComponent as DocusignIcon
} from '@catalytic/catalytic-icons/lib/integrations/docusign.svg';
import dropboxIconUrl, {
  ReactComponent as DropboxIcon
} from '@catalytic/catalytic-icons/lib/integrations/dropbox.svg';
import ephesoftIconUrl, {
  ReactComponent as EphesoftIcon
} from '@catalytic/catalytic-icons/lib/integrations/ephesoft.svg';
import excelIconUrl, {
  ReactComponent as ExcelIcon
} from '@catalytic/catalytic-icons/lib/integrations/excel.svg';
import fieldglassIconUrl, {
  ReactComponent as FieldglassIcon
} from '@catalytic/catalytic-icons/lib/integrations/fieldglass.svg';
import fullContactIconUrl, {
  ReactComponent as FullContactIcon
} from '@catalytic/catalytic-icons/lib/integrations/full-contact.svg';
import githubIconUrl, {
  ReactComponent as GithubIcon
} from '@catalytic/catalytic-icons/lib/integrations/github.svg';
import gmailIconUrl, {
  ReactComponent as GmailIcon
} from '@catalytic/catalytic-icons/lib/integrations/gmail.svg';
import googleAnalyticsIconUrl, {
  ReactComponent as GoogleAnalyticsIcon
} from '@catalytic/catalytic-icons/lib/integrations/google-analytics.svg';
import googleBigQueryIconUrl, {
  ReactComponent as GoogleBigQueryIcon
} from '@catalytic/catalytic-icons/lib/integrations/google-bigquery.svg';
import googleCalendarIconUrl, {
  ReactComponent as GoogleCalendarIcon
} from '@catalytic/catalytic-icons/lib/integrations/google-calendar.svg';
import googleDriveIconUrl, {
  ReactComponent as GoogleDriveIcon
} from '@catalytic/catalytic-icons/lib/integrations/google-drive.svg';
import i9AdvantageIconUrl, {
  ReactComponent as I9AdvantageIcon
} from '@catalytic/catalytic-icons/lib/integrations/i9-advantage.svg';
import jiraIconUrl, {
  ReactComponent as JiraIcon
} from '@catalytic/catalytic-icons/lib/integrations/jira.svg';
import jobDivaIconUrl, {
  ReactComponent as JobDivaIcon
} from '@catalytic/catalytic-icons/lib/integrations/jobdiva.svg';
import leverIconUrl, {
  ReactComponent as LeverIcon
} from '@catalytic/catalytic-icons/lib/integrations/lever.svg';
import microsoftDynamicsIconUrl, {
  ReactComponent as MicrosoftDynamicsIcon
} from '@catalytic/catalytic-icons/lib/integrations/microsoft-dynamics.svg';
import netsuiteIconUrl, {
  ReactComponent as NetsuiteIcon
} from '@catalytic/catalytic-icons/lib/integrations/netsuite.svg';
import oktaIconUrl, {
  ReactComponent as OktaIcon
} from '@catalytic/catalytic-icons/lib/integrations/okta.svg';
import oracleSalesCloudIconUrl, {
  ReactComponent as OracleSalesCloudIcon
} from '@catalytic/catalytic-icons/lib/integrations/oracle-sales-cloud.svg';
import outlookIconUrl, {
  ReactComponent as OutlookIcon
} from '@catalytic/catalytic-icons/lib/integrations/outlook.svg';
import paylocityIconUrl, {
  ReactComponent as PaylocityIcon
} from '@catalytic/catalytic-icons/lib/integrations/paylocity.svg';
import pdfIconUrl, {
  ReactComponent as PdfIcon
} from '@catalytic/catalytic-icons/lib/integrations/pdf.svg';
import pipedriveIconUrl, {
  ReactComponent as PipedriveIcon
} from '@catalytic/catalytic-icons/lib/integrations/pipedrive.svg';
import powerBiIconUrl, {
  ReactComponent as PowerBiIcon
} from '@catalytic/catalytic-icons/lib/integrations/powerbi.svg';
import powerPointIconUrl, {
  ReactComponent as PowerPointIcon
} from '@catalytic/catalytic-icons/lib/integrations/power-point.svg';
import prismaIconUrl, {
  ReactComponent as PrismaIcon
} from '@catalytic/catalytic-icons/lib/integrations/prisma.svg';
import quickbooksIconUrl, {
  ReactComponent as QuickbooksIcon
} from '@catalytic/catalytic-icons/lib/integrations/quickbooks.svg';
import s3IconUrl, {
  ReactComponent as S3Icon
} from '@catalytic/catalytic-icons/lib/integrations/s3.svg';
import salesforceIconUrl, {
  ReactComponent as SalesforceIcon
} from '@catalytic/catalytic-icons/lib/integrations/salesforce.svg';
import sapSuccessFactorsIconUrl, {
  ReactComponent as SapSuccessFactorsIcon
} from '@catalytic/catalytic-icons/lib/integrations/sap-success-factors.svg';
import sharepointIconUrl, {
  ReactComponent as SharepointIcon
} from '@catalytic/catalytic-icons/lib/integrations/sharepoint.svg';
import serviceNowIconUrl, {
  ReactComponent as ServiceNowIcon
} from '@catalytic/catalytic-icons/lib/integrations/service-now.svg';
import slackIconUrl, {
  ReactComponent as SlackIcon
} from '@catalytic/catalytic-icons/lib/integrations/slack.svg';
import sterlingIconUrl, {
  ReactComponent as SterlingIcon
} from '@catalytic/catalytic-icons/lib/integrations/sterling.svg';
import stripeIconUrl, {
  ReactComponent as StripeIcon
} from '@catalytic/catalytic-icons/lib/integrations/stripe.svg';
import trelloIconUrl, {
  ReactComponent as TrelloIcon
} from '@catalytic/catalytic-icons/lib/integrations/trello.svg';
import uiPathIconUrl, {
  ReactComponent as UiPathIcon
} from '@catalytic/catalytic-icons/lib/integrations/uipath.svg';
import wordIconUrl, {
  ReactComponent as WordIcon
} from '@catalytic/catalytic-icons/lib/integrations/word.svg';
import workdayIconUrl, {
  ReactComponent as WorkdayIcon
} from '@catalytic/catalytic-icons/lib/integrations/workday.svg';
import wrikeIconUrl, {
  ReactComponent as WrikeIcon
} from '@catalytic/catalytic-icons/lib/integrations/wrike.svg';
import youTubeIconUrl, {
  ReactComponent as YouTubeIcon
} from '@catalytic/catalytic-icons/lib/integrations/youtube.svg';
import zendeskIconUrl, {
  ReactComponent as ZendeskIcon
} from '@catalytic/catalytic-icons/lib/integrations/zendesk.svg';
import zoomIconUrl, {
  ReactComponent as ZoomIcon
} from '@catalytic/catalytic-icons/lib/integrations/zoom.svg';
import { CERULEAN, CANARY } from '@catalytic/catalytic-ui';
import { TYPE as FIELD_TYPE, type Field } from '../Field/FieldTypes';

export function appNameToIcon(appName: ?string, appID: string) {
  if (is(String, appID) && !appName) {
    appName = appID.split('/')[0];
  }
  switch (appName) {
    case 'assign':
      return { icon: AssignmentIcon, iconUrl: assignmentIconUrl, color: null };
    case 'automation-anywhere':
      return {
        icon: AutomationAnywhereIcon,
        iconUrl: automationAnywhereIconUrl,
        color: null
      };
    case 'aws':
      return { icon: AwsIcon, iconUrl: awsIconUrl, color: null };
    case 'azure':
      return { icon: AzureIcon, iconUrl: azureIconUrl, color: null };
    case 'azure-blobstorage':
      return {
        icon: AzureBlobStorageIcon,
        iconUrl: azureBlobStorageIconUrl,
        color: null
      };
    case 'autodesk':
      return { icon: AutoDeskIcon, iconUrl: autoDeskIconUrl, color: null };
    case 'box':
      return { icon: BoxIcon, iconUrl: boxIconUrl, color: null };
    case 'circleci':
      return { icon: CircleCIIcon, iconUrl: circleCIIconUrl, color: null };
    case 'browser-automation':
      return { icon: BrowserIcon, iconUrl: browserIconUrl, color: null };
    case 'contacts':
      return { icon: PersonCardIcon, iconUrl: personCardIconUrl, color: null };
    case 'cognitive':
      return { icon: CognitiveIcon, iconUrl: cognitiveIconUrl, color: null };
    case 'coupa':
      return { icon: CoupaIcon, iconUrl: coupaIconUrl, color: null };
    case 'csv':
      return { icon: CsvIcon, iconUrl: csvIconUrl, color: null };
    case 'dates':
      return { icon: DateIcon, iconUrl: dateIconUrl, color: null };
    case 'dayforce':
      return { icon: DayforceIcon, iconUrl: dayforceIconUrl, color: null };
    case 'docusign':
      return { icon: DocusignIcon, iconUrl: docusignIconUrl, color: null };
    case 'dropbox':
      return { icon: DropboxIcon, iconUrl: dropboxIconUrl, color: null };
    case 'dynamics':
      return {
        icon: MicrosoftDynamicsIcon,
        iconUrl: microsoftDynamicsIconUrl,
        color: null
      };
    case 'email':
      return { icon: EmailIcon, iconUrl: emailIconUrl, color: CERULEAN };
    case 'ephesoft':
      return { icon: EphesoftIcon, iconUrl: ephesoftIconUrl, color: null };
    case 'excel':
      switch (appID) {
        case 'excel/start-batch/v4':
          return {
            icon: IteratorIcon,
            iconUrl: iteratorIconUrl,
            color: null
          };
        default:
          return { icon: ExcelIcon, iconUrl: excelIconUrl, color: null };
      }
    case 'field':
      return { icon: HandlebarsIcon, iconUrl: handlebarsIconUrl, color: null };
    case 'fields':
      return { icon: HandlebarsIcon, iconUrl: handlebarsIconUrl, color: null };
    case 'fieldglass':
      return { icon: FieldglassIcon, iconUrl: fieldglassIconUrl, color: null };
    case 'files':
      return { icon: FileIcon, iconUrl: fileIconUrl, color: null };
    case 'fullcontact':
      switch (appID) {
        case 'fullcontact/company-lookup/v1':
          return {
            icon: FullContactIcon,
            iconUrl: fullContactIconUrl,
            color: null
          };
        default:
          return {
            icon: PersonCardIcon,
            iconUrl: personCardIconUrl,
            color: null
          };
      }
    case 'github':
      return { icon: GithubIcon, iconUrl: githubIconUrl, color: null };
    case 'gmail':
      return { icon: GmailIcon, iconUrl: gmailIconUrl, color: null };
    case 'google':
      switch (appID) {
        case 'google/detect-language/v1':
          return { icon: LanguageIcon, iconUrl: languageIconUrl, color: null };
        case 'google/sentiment/v1':
          return {
            icon: SentimentIcon,
            iconUrl: sentimentIconUrl,
            color: null
          };
        case 'google/vision/v1':
        case 'google/vision/v2':
          return { icon: EyeIcon, iconUrl: eyeIconUrl, color: null };
        default:
          return {
            icon: GoogleDriveIcon,
            iconUrl: googleDriveIconUrl,
            color: null
          };
      }
    case 'google-analytics':
      return {
        icon: GoogleAnalyticsIcon,
        iconUrl: googleAnalyticsIconUrl,
        color: null
      };
    case 'google-api':
      switch (appID) {
        case 'google-api/detect-language/v1':
          return { icon: LanguageIcon, iconUrl: languageIconUrl, color: null };
        case 'google-api/sentiment/v1':
          return {
            icon: SentimentIcon,
            iconUrl: sentimentIconUrl,
            color: null
          };
        case 'google-api/vision/v1':
        case 'google-api/vision/v2':
          return { icon: EyeIcon, iconUrl: eyeIconUrl, color: null };
        default:
          return {
            icon: GoogleDriveIcon,
            iconUrl: googleDriveIconUrl,
            color: null
          };
      }
    case 'google-bigquery':
      return {
        icon: GoogleBigQueryIcon,
        iconUrl: googleBigQueryIconUrl,
        color: null
      };
    case 'google-calendar':
      return {
        icon: GoogleCalendarIcon,
        iconUrl: googleCalendarIconUrl,
        color: null
      };
    case 'i9-advantage':
      return {
        icon: I9AdvantageIcon,
        iconUrl: i9AdvantageIconUrl,
        color: null
      };
    case 'images':
      return { icon: ImagesIcon, iconUrl: imagesIconUrl, color: null };
    case 'invoices':
      return { icon: InvoicesIcon, iconUrl: invoicesIconUrl, color: null };
    case 'jira':
      return { icon: JiraIcon, iconUrl: jiraIconUrl, color: null };
    case 'jobdiva':
      return { icon: JobDivaIcon, iconUrl: jobDivaIconUrl, color: null };
    case 'lever':
      return { icon: LeverIcon, iconUrl: leverIconUrl, color: null };
    case 'list':
      return { icon: ListIcon, iconUrl: listIconUrl, color: null };
    case 'manual':
      return { icon: AssignmentIcon, iconUrl: assignmentIconUrl, color: null };
    case 'mediaocean-prisma':
      return { icon: PrismaIcon, iconUrl: prismaIconUrl, color: null };
    case 'netsuite':
      return { icon: NetsuiteIcon, iconUrl: netsuiteIconUrl, color: null };
    case 'numbers':
      return { icon: NumbersIcon, iconUrl: numbersIconUrl, color: null };
    case 'okta':
      return { icon: OktaIcon, iconUrl: oktaIconUrl, color: null };
    case 'outlook':
      return { icon: OutlookIcon, iconUrl: outlookIconUrl, color: null };
    case 'oracle-sales-cloud':
      return {
        icon: OracleSalesCloudIcon,
        iconUrl: oracleSalesCloudIconUrl,
        color: null
      };
    case 'parse':
      return { icon: LocationIcon, iconUrl: locationIconUrl, color: null };
    case 'paylocity':
      return { icon: PaylocityIcon, iconUrl: paylocityIconUrl, color: null };
    case 'pdf':
      return { icon: PdfIcon, iconUrl: pdfIconUrl, color: null };
    case 'pipedrive':
      return { icon: PipedriveIcon, iconUrl: pipedriveIconUrl, color: null };
    case 'powerbi':
      return { icon: PowerBiIcon, iconUrl: powerBiIconUrl, color: null };
    case 'powerpoint':
      return { icon: PowerPointIcon, iconUrl: powerPointIconUrl, color: null };
    case 'pushbot':
      switch (appID) {
        case 'pushbot/delete-run/v1':
        case 'pushbot/prevent-file-deletion/v1':
        case 'pushbot/prevent-table-deletion/v1':
          return {
            icon: DataDeletionIcon,
            iconUrl: dataDeletionIconUrl,
            color: null
          };
        case 'pushbot/make-prediction/v1':
        case 'pushbot/set-text-field/v1':
        case 'pushbot/set-decimal-value/v1':
        case 'pushbot/set-integer-value/v1':
        case 'pushbot/calculate/v1':
        case 'pushbot/calculate/v2':
          return {
            icon: HandlebarsIcon,
            iconUrl: handlebarsIconUrl,
            color: null
          };
        case 'pushbot/reopen/v3':
          return {
            icon: IteratorIcon,
            iconUrl: iteratorIconUrl,
            color: null
          };
        case 'pushbot/subprocess/v1':
        case 'pushbot/subprocess/v2':
        case 'pushbot/conditional-block/v2':
          return {
            icon: ConditionalIcon,
            iconUrl: conditionalIconUrl,
            color: null
          };
        case 'pushbot/builder-heading/v1':
          return { icon: HeadingIcon, iconUrl: headingIconUrl, color: null };
        case 'pushbot/builder-note/v1':
          return { icon: NoteIcon, iconUrl: noteIconUrl, color: null };
        default:
          return { icon: WorkflowIcon, iconUrl: workflowIconUrl, color: null };
      }
    case 'quickbooks':
      return { icon: QuickbooksIcon, iconUrl: quickbooksIconUrl, color: null };
    case 's3':
      return { icon: S3Icon, iconUrl: s3IconUrl, color: null };
    case 'sap-successfactors':
      return {
        icon: SapSuccessFactorsIcon,
        iconUrl: sapSuccessFactorsIconUrl,
        color: null
      };
    case 'service-now':
      return { icon: ServiceNowIcon, iconUrl: serviceNowIconUrl, color: null };
    case 'slack':
      return { icon: SlackIcon, iconUrl: slackIconUrl, color: null };
    case 'salesforce':
      return { icon: SalesforceIcon, iconUrl: salesforceIconUrl, color: null };
    case 'scheduled':
      return {
        icon: ScheduledIcon,
        iconUrl: scheduledIconUrl,
        color: CERULEAN
      };
    case 'sftp':
      switch (appID) {
        case 'sftp/upload/v1':
        case 'sftp/upload/v2':
          return {
            icon: SftpUploadIcon,
            iconUrl: sftpUploadIconUrl,
            color: null
          };
        case 'sftp/download/v1':
        case 'sftp/download/v2':
          return {
            icon: SftpDownloadIcon,
            iconUrl: sftpDownloadIconUrl,
            color: null
          };
        default:
          return {
            icon: SftpDownloadIcon,
            iconUrl: sftpDownloadIconUrl,
            color: null
          };
      }
    case 'singleSignOn':
      return { icon: SsoIcon, iconUrl: ssoIconUrl, color: null };
    case 'sharepoint':
      return { icon: SharepointIcon, iconUrl: sharepointIconUrl, color: null };
    case 'sterling':
      return { icon: SterlingIcon, iconUrl: sterlingIconUrl, color: null };
    case 'stripe':
      return { icon: StripeIcon, iconUrl: stripeIconUrl, color: null };
    case 'trello':
      return { icon: TrelloIcon, iconUrl: trelloIconUrl, color: null };
    case 'sms':
      return { icon: CommentsIcon, iconUrl: commentsIconUrl, color: null };
    case 'system':
      switch (appID) {
        case 'system/notification-access-request/v1':
        case 'system/notification-batch-skipped-instances/v1':
        case 'system/notification-daily-summary/v1':
        case 'system/notification-find-teams/v1':
        case 'system/notification-invite-accepted/v1':
        case 'system/notification-invite/v1':
        case 'system/notification-invited-to-group/v1':
        case 'system/notification-new-active-training/v1':
        case 'system/notification-run-canceled/v1':
        case 'system/notification-run-comment/v1':
        case 'system/notification-run-completed/v1':
        case 'system/notification-run-due-soon/v1':
        case 'system/notification-task-assigned/v1':
        case 'system/notification-task-canceled/v1':
        case 'system/notification-task-deadline-removed/v1':
        case 'system/notification-task-deadline-updated/v1':
        case 'system/notification-task-rejected/v1':
        case 'system/notification-task-reminder/v1':
        case 'system/notification-task-skipped/v1':
        case 'system/notification-tell-owner-task-reassigned/v1':
        case 'system/notification-weekly-team-summary/v1':
          return {
            icon: SystemNotificationIcon,
            iconUrl: systemNotificationIconUrl,
            color: null
          };
        default:
          return { icon: WorkflowIcon, iconUrl: workflowIconUrl, color: null };
      }
    case 'table':
      return { icon: DataTableIcon, iconUrl: dataTableIconUrl, color: null };
    case 'tables':
      switch (appID) {
        case 'tables/start-batch/v4':
          return {
            icon: IteratorIcon,
            iconUrl: iteratorIconUrl,
            color: null
          };
        default:
          return {
            icon: DataTableIcon,
            iconUrl: dataTableIconUrl,
            color: null
          };
      }
    case 'text':
      return { icon: TextIcon, iconUrl: textIconUrl, color: null };
    case 'triggers':
      return { icon: TriggerIcon, iconUrl: triggerIconUrl, color: CANARY };
    case 'uipath':
      return { icon: UiPathIcon, iconUrl: uiPathIconUrl, color: null };
    case 'web':
      return { icon: WwwIcon, iconUrl: wwwIconUrl, color: null };
    case 'web-api':
      return { icon: WebApiIcon, iconUrl: webApiIconUrl, color: null };
    case 'webhook':
      return { icon: WebhookIcon, iconUrl: webhookIconUrl, color: CERULEAN };
    case 'webform':
      return { icon: WebformIcon, iconUrl: webformIconUrl, color: null };
    case 'word':
      return { icon: WordIcon, iconUrl: wordIconUrl, color: null };
    case 'workday':
      return { icon: WorkdayIcon, iconUrl: workdayIconUrl, color: null };
    case 'wrike':
      return { icon: WrikeIcon, iconUrl: wrikeIconUrl, color: null };
    case 'xml':
      return { icon: XmlIcon, iconUrl: xmlIconUrl, color: null };
    case 'youtube':
      return { icon: YouTubeIcon, iconUrl: youTubeIconUrl, color: null };
    case 'zendesk':
      return { icon: ZendeskIcon, iconUrl: zendeskIconUrl, color: null };
    case 'zoom':
      return { icon: ZoomIcon, iconUrl: zoomIconUrl, color: null };
    default:
      return { icon: RunIcon, iconUrl: runIconUrl, color: null };
  }
}

export const appActionFromAppID = (appIDNullable: ?string) => {
  const appID = appIDNullable || '';
  return appID.split('/')[1];
};

export const appNameFromAppID = (appIDNullable: ?string) => {
  const appID = appIDNullable || '';
  return appID.includes('/') ? appID.slice(0, appID.indexOf('/')) : appID;
};

export const appVersionFromAppID = (appIDNullable: ?string) => {
  const appID = appIDNullable || '';
  return appID.split('/')[2];
};

export const getCredentialsFromFormData = ({
  formData,
  exclude,
  inputs
}: {
  formData: FormData,
  exclude: Array<string>,
  inputs: Array<Field>
}): Array<{
  key: string,
  value: any
}> => {
  const keys = formData ? Array.from(formData.keys()) : [];
  return (
    uniq(keys)
      // there will be form data that we don't want to submit under the credentials property
      .filter(key => !exclude.includes(key))
      .reduce((acc, key) => {
        const input = inputs.find(i => i.name === key);
        let value;
        if (input && input.type === FIELD_TYPE.ARRAY) {
          value = formData.getAll(key);
        } else {
          value = formData.get(key);
        }

        acc.push({
          key,
          value
        });
        return acc;
      }, [])
  );
};
