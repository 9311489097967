// @flow

import * as React from 'react';
import { getFieldValue } from '@catalytic/catalytic-ui';
import { JSONType } from '@catalytic/json-schema-validator-catalytic-web';
import { type DateTimeFormat } from '@catalytic/view';

type HandleBlurProps = {
  onBlur: (...args: Array<any>) => any,
  setValue: (value: any) => void,
  type?: $Keys<typeof JSONType>
};

export const useHandleBlur = ({ onBlur, setValue, type }: HandleBlurProps) =>
  React.useCallback(
    (...args: Array<any>) => {
      const [event] = args;
      const value = event?.currentTarget?.value;
      const fieldValue = getFieldValue(value, type);

      // Broadcast field value change to view tree
      setValue(fieldValue);

      if (typeof onBlur === 'function') {
        onBlur(...args);
      }
    },
    [onBlur, setValue, type]
  );

type HandleChangeProps = {
  onChange: (...args: Array<any>) => any,
  setValue: (value: any) => void,
  type?: $Keys<typeof JSONType>
};

export const useHandleChange = ({
  onChange,
  setValue,
  type
}: HandleChangeProps) =>
  React.useCallback(
    (...args: Array<any>) => {
      const [event] = args;
      const value = event?.currentTarget?.value;
      const fieldValue = getFieldValue(value, type);

      // Broadcast field value change to view tree
      setValue(fieldValue);

      if (typeof onChange === 'function') {
        onChange(...args);
      }
    },
    [onChange, setValue, type]
  );

// Intl.DateTimeFormat
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat

// Moment.js - Locale aware formats
// https://momentjs.com/docs/#/parsing/string-format/

/*
| Moment.js   | Intl.DateTimeFormat                                     | en-US             | de-DE            |
|-----------  |-------------------------------------------------------  |-----------------  |----------------  |
| l           | { year: 'numeric', month: 'numeric', day: 'numeric' }   | 1/1/2020          | 1.1.2020         |
| L           | { year: 'numeric', month: '2-digit', day: '2-digit' }   | 01/01/2020        | 01.01.2020       |
| ll          | { year: 'numeric', month: 'short', day: 'numeric' }     | Jan 1, 2020       | 1. Jan. 2020     |
| LL          | { year: 'numeric', month: 'long', day: 'numeric' }      | January 1, 2020   | 1. Januar 2020   |
*/

type LocaleAwareFormat = 'l' | 'L' | 'll' | 'LL';

export const toLocaleAwareDateFormat = ({
  year,
  month,
  day
}: DateTimeFormat): LocaleAwareFormat => {
  const dateFormat = JSON.stringify({ year, month, day });

  switch (dateFormat) {
    case '{"year":"numeric","month":"numeric","day":"numeric"}':
      return 'l';
    case '{"year":"numeric","month":"2-digit","day":"2-digit"}':
      return 'L';
    case '{"year":"numeric","month":"short","day":"numeric"}':
      return 'll';
    case '{"year":"numeric","month":"long","day":"numeric"}':
      return 'LL';
    default:
      return 'l';
  }
};

// Note: The resulting time format is not internationalized,
// but we have tried to map the format to be as close as possible.
// https://github.com/YouCanBookMe/react-datetime/issues/431

/*
| Moment.js   | Intl.DateTimeFormat                                     | en-US             | de-DE            |
|-----------  |-------------------------------------------------------  |-----------------  |----------------  |
| h:mm A      | { hour: 'numeric', minute: 'numeric', hour12: true }    | 1:30 PM           |                  |
| HH:mm       | { hour: 'numeric', minute: 'numeric', hour12: true }    | 13:30             |                  |
*/

type TimeFormat = 'h:mm A' | 'HH:mm';

export const toMomentDateTimeFormat = ({
  hour,
  minute,
  hour12
}: DateTimeFormat): TimeFormat => {
  const dateFormat = JSON.stringify({ hour, minute, hour12 });

  switch (dateFormat) {
    case '{"hour":"numeric","minute":"numeric","hour12":true}':
      return 'h:mm A';
    case '{"hour":"numeric","minute":"numeric","hour12":false}':
      return 'HH:mm';
    default:
      return 'h:mm A';
  }
};
