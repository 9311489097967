// @flow

import React, { type ElementType } from 'react';
import classNames from 'classnames';
import createBreakpoints, { keys as breakpointKeys } from './createBreakpoints';
import variables from '../style/variables';
import injectSheet from '../style/injectSheet';
import { type InjectSheetProvidedProps } from '../style/ThemeTypes';

const BASE = variables.base;
const GUTTERS = [0, 8, 16, 24, 32, 40];
const GRID_SIZES = ['auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const generateGrid = (globalStyles, breakpoint) => {
  const styles = {};

  GRID_SIZES.forEach(size => {
    const key = `grid-${breakpoint}-${String(size)}`;

    if (size === true) {
      // For the auto layouting
      styles[key] = {
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: '100%'
      };
      return;
    }

    if (size === 'auto') {
      styles[key] = {
        flexBasis: 'auto',
        flexGrow: 0,
        maxWidth: 'none'
      };
      return;
    }

    // Only keep 6 significant numbers.
    const width = `${Math.round((size / 12) * 10e6) / 10e4}%`;

    // Close to the bootstrap implementation:
    // https://github.com/twbs/bootstrap/blob/8fccaa2439e97ec72a4b7dc42ccc1f649790adb0/scss/mixins/_grid.scss#L41
    styles[key] = {
      flexBasis: width,
      flexGrow: 0,
      maxWidth: width
    };
  });

  // No need for a media query for the first size.
  if (breakpoint === 'xs') {
    Object.assign(globalStyles, styles);
  } else {
    globalStyles[createBreakpoints({}).up(breakpoint)] = styles;
  }
};

const generateGutter = breakpoint => {
  const styles = {};

  GUTTERS.forEach((spacing, index) => {
    if (index === 0) {
      // Skip the default style.
      return;
    }

    styles[`spacing-${breakpoint}-${spacing}`] = {
      margin: `${-spacing / 2 / BASE}rem`,
      width: `calc(100% + ${spacing / BASE}rem)`,
      '& > $item': {
        padding: `${spacing / 2 / BASE}rem`
      }
    };
  });

  return styles;
};

/**
 * Default CSS values
 * flex: '0 1 auto',
 * flexDirection: 'row',
 * alignItems: 'flex-start',
 * flexWrap: 'nowrap',
 * justifyContent: 'flex-start'
 */
export const Style = () => ({
  /* Styles applied to the root element if `container={true}`. */
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  /* Styles applied to the root element if `item={true}`. */
  item: {
    boxSizing: 'border-box',
    margin: 0 // For instance, it's useful when used with a `figure` element.
  },
  /* Styles applied to the root element if `zeroMinWidth={true}`. */
  zeroMinWidth: {
    minWidth: 0
  },
  /* Styles applied to the root element if `direction="column"`. */
  'direction-xs-column': {
    flexDirection: 'column'
  },
  /* Styles applied to the root element if `direction="column-reverse"`. */
  'direction-xs-column-reverse': {
    flexDirection: 'column-reverse'
  },
  /* Styles applied to the root element if `direction="rwo-reverse"`. */
  'direction-xs-row-reverse': {
    flexDirection: 'row-reverse'
  },
  /* Styles applied to the root element if `wrap="nowrap"`. */
  'wrap-xs-nowrap': {
    flexWrap: 'nowrap'
  },
  /* Styles applied to the root element if `wrap="reverse"`. */
  'wrap-xs-wrap-reverse': {
    flexWrap: 'wrap-reverse'
  },
  /* Styles applied to the root element if `alignItems="center"`. */
  'align-items-xs-center': {
    alignItems: 'center'
  },
  /* Styles applied to the root element if `alignItems="flex-start"`. */
  'align-items-xs-flex-start': {
    alignItems: 'flex-start'
  },
  /* Styles applied to the root element if `alignItems="flex-end"`. */
  'align-items-xs-flex-end': {
    alignItems: 'flex-end'
  },
  /* Styles applied to the root element if `alignItems="baseline"`. */
  'align-items-xs-baseline': {
    alignItems: 'baseline'
  },
  /* Styles applied to the root element if `alignContent="center"`. */
  'align-content-xs-center': {
    alignContent: 'center'
  },
  /* Styles applied to the root element if `alignContent="flex-start"`. */
  'align-content-xs-flex-start': {
    alignContent: 'flex-start'
  },
  /* Styles applied to the root element if `alignContent="flex-end"`. */
  'align-content-xs-flex-end': {
    alignContent: 'flex-end'
  },
  /* Styles applied to the root element if `alignContent="space-between"`. */
  'align-content-xs-space-between': {
    alignContent: 'space-between'
  },
  /* Styles applied to the root element if `alignContent="space-around"`. */
  'align-content-xs-space-around': {
    alignContent: 'space-around'
  },
  /* Styles applied to the root element if `justify="center"`. */
  'justify-xs-center': {
    justifyContent: 'center'
  },
  /* Styles applied to the root element if `justify="flex-end"`. */
  'justify-xs-flex-end': {
    justifyContent: 'flex-end'
  },
  /* Styles applied to the root element if `justify="space-between"`. */
  'justify-xs-space-between': {
    justifyContent: 'space-between'
  },
  /* Styles applied to the root element if `justify="space-around"`. */
  'justify-xs-space-around': {
    justifyContent: 'space-around'
  },
  /* Styles applied to the root element if `justify="space-evenly"`. */
  'justify-xs-space-evenly': {
    justifyContent: 'space-evenly'
  },
  ...generateGutter('xs'),
  ...breakpointKeys.reduce((accumulator, key) => {
    // Use side effect over immutability for better performance.
    generateGrid(accumulator, key);
    return accumulator;
  }, {})
});

type GridSizes = false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;  // eslint-disable-line prettier/prettier

type Props = InjectSheetProvidedProps & {
  /**
   * Defines the `align-content` style property.
   * It's applied for all screen sizes.
   */
  alignContent: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around',  // eslint-disable-line prettier/prettier
  /**
   * Defines the `align-items` style property.
   * It's applied for all screen sizes.
   */
  alignItems: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline',
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: ElementType,
  /**
   * If `true`, the component will have the flex *container* behavior.
   * You should be wrapping *items* with a *container*.
   */
  container: boolean,
  /**
   * Defines the `flex-direction` style property.
   * It is applied for all screen sizes.
   */
  direction: 'row' | 'row-reverse' | 'column' | 'column-reverse',
  /**
   * If `true`, the component will have the flex *item* behavior.
   * You should be wrapping *items* with a *container*.
   */
  item: boolean,
  /**
   * Defines the `justify-content` style property.
   * It is applied for all screen sizes.
   */
  justify: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly',  // eslint-disable-line prettier/prettier
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `lg` breakpoint and wider screens if not overridden.
   */
  lg: GridSizes,
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `md` breakpoint and wider screens if not overridden.
   */
  md: GridSizes,
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `sm` breakpoint and wider screens if not overridden.
   */
  sm: GridSizes,
  /**
   * Defines the space between the type `item` component.
   * It can only be used on a type `container` component.
   */
  spacing: 0 | 8 | 16 | 24 | 32 | 40,
  /**
   * Defines the `flex-wrap` style property.
   * It's applied for all screen sizes.
   */
  wrap: 'nowrap' | 'wrap' | 'wrap-reverse',
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `xl` breakpoint and wider screens.
   */
  xl: GridSizes,
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for all the screen sizes with the lowest priority.
   */
  xs: GridSizes,
  /**
   * If `true`, it sets `min-width: 0` on the item.
   * Refer to the limitations section of the documentation to better understand the use case.
   */
  zeroMinWidth: boolean
};

export const Grid = (props: Props) => {
  const {
    alignContent,
    alignItems,
    classes,
    className: classNameProp,
    component: Component,
    container,
    direction,
    item,
    justify,
    lg,
    md,
    sm,
    spacing,
    theme,
    wrap,
    xl,
    xs,
    zeroMinWidth,
    ...other
  } = props;

  const className = classNames(
    {
      [classes.container]: container,
      [classes.item]: item,
      [classes.zeroMinWidth]: zeroMinWidth,
      [classes[`spacing-xs-${String(spacing)}`]]: container && spacing !== 0,
      [classes[`direction-xs-${String(direction)}`]]:
        direction !== Grid.defaultProps.direction,
      [classes[`wrap-xs-${String(wrap)}`]]: wrap !== Grid.defaultProps.wrap,
      [classes[`align-items-xs-${String(alignItems)}`]]:
        alignItems !== Grid.defaultProps.alignItems,
      [classes[`align-content-xs-${String(alignContent)}`]]:
        alignContent !== Grid.defaultProps.alignContent,
      [classes[`justify-xs-${String(justify)}`]]:
        justify !== Grid.defaultProps.justify,
      [classes[`grid-xs-${String(xs)}`]]: xs !== false,
      [classes[`grid-sm-${String(sm)}`]]: sm !== false,
      [classes[`grid-md-${String(md)}`]]: md !== false,
      [classes[`grid-lg-${String(lg)}`]]: lg !== false,
      [classes[`grid-xl-${String(xl)}`]]: xl !== false
    },
    classNameProp
  );

  return <Component className={className} data-testid="grid" {...other} />;
};

Grid.displayName = 'Grid';
Grid.defaultProps = {
  alignContent: 'stretch',
  alignItems: 'stretch',
  component: 'div',
  container: false,
  direction: 'row',
  item: false,
  justify: 'flex-start',
  lg: false,
  md: false,
  sm: false,
  spacing: 0,
  wrap: 'wrap',
  xl: false,
  xs: false,
  zeroMinWidth: false
};

export default injectSheet(Style)(Grid);
