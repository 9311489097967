// @flow

import React, { type Node } from 'react';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import path from 'path';
import { compose, type HOC } from 'recompose';
import { Link, withRouter, type ContextRouter } from 'react-router-dom';
import userIconUrl from '@catalytic/catalytic-icons/lib/icons/user.svg';
import {
  Clamp,
  ListItem,
  injectSheet,
  ListItemHeader,
  ListItemBody,
  Avatar,
  CERULEAN,
  Badge,
  type InjectSheetProvidedProps,
  type ThemeType
} from '@catalytic/catalytic-ui';
import { USERS } from '../const/path';

const BADGE_WIDTH = 52;
const BADGE_MARGIN_LEFT = 12;

export const ActorListItemFragment = gql`
  fragment ActorListItemFragment on Actor {
    email
    id
    displayName
    isTeamAdmin
    isTeamOwner
    thumbnailUrl
  }
`;
export const Style = (theme: ThemeType) => ({
  content: {
    alignItems: 'center',
    display: 'flex',
    minWidth: '100%'
  },
  badge: {
    flex: 'none',
    minWidth: theme.functions.toRem(BADGE_WIDTH),
    height: '1.25rem',
    marginLeft: theme.functions.toRem(BADGE_MARGIN_LEFT),
    verticalAlign: 'middle'
  },
  avatar: {
    flex: 'none',
    margin: '.75rem',
    width: '2rem',
    minWidth: '2rem',
    minHeight: '2rem',
    height: '2rem'
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    minWidth: 0
  },
  displayName: {
    marginRight: `-${theme.functions.toRem(BADGE_WIDTH + BADGE_MARGIN_LEFT)}`,
    paddingRight: theme.functions.toRem(BADGE_WIDTH + BADGE_MARGIN_LEFT),
    verticalAlign: 'middle'
  }
});

type Props = InjectSheetProvidedProps &
  ContextRouter & {
    displayName: string,
    email: string,
    groupDisplayName?: string,
    id: string,
    isTeamAdmin: boolean,
    isTeamOwner: boolean,
    thumbnailUrl: ?string
  };

export const TeamListItem = ({
  classes,
  displayName,
  email,
  groupDisplayName = '',
  id,
  isTeamAdmin,
  isTeamOwner,
  location,
  match,
  thumbnailUrl
}: Props): Node => {
  const prevPath = match?.path || '';
  const prevPathname = location?.pathname || '';

  return (
    <Link
      to={{
        pathname: path.join(USERS, id),
        state: { groupDisplayName, prevPath, prevPathname }
      }}
    >
      <ListItem>
        <div className={classes.content}>
          <Avatar
            image={thumbnailUrl || userIconUrl}
            circular={true}
            className={classes.avatar}
          />
          <div className={classes.text}>
            <ListItemHeader>
              <Clamp clamp={2} className={classes.displayName}>
                {displayName}
              </Clamp>
              {(isTeamAdmin || isTeamOwner) && (
                <Badge
                  compact={true}
                  colorScheme={CERULEAN}
                  className={classes.badge}
                >
                  <FormattedMessage id="team.badge" defaultMessage="Admin" />
                </Badge>
              )}
            </ListItemHeader>
            <ListItemBody>{email}</ListItemBody>
          </div>
        </div>
      </ListItem>
    </Link>
  );
};

TeamListItem.displayName = 'TeamListItem';

// TOOD: Type props passed to component
type EnhancedProps = Object;

const enhance: HOC<*, EnhancedProps> = compose(withRouter, injectSheet(Style));

export default enhance(TeamListItem);
