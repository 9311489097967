// @flow

import ascend from 'ramda/src/ascend';
import composeP from 'ramda/src/composeP';
import descend from 'ramda/src/descend';
import prop from 'ramda/src/prop';
import sortWith from 'ramda/src/sortWith';

export const readFragment = (
  fragment: Object,
  type: string,
  input?: Function | string,
  output?: Function | string
) => (root: any, input: any, context: any) => {
  const id =
    typeof input === 'function'
      ? input(input)
      : typeof input === 'string'
      ? input[input]
      : input.id;
  const results = context.cache.readFragment({
    fragment,
    id: `${type}:${id}`
  });
  return typeof output === 'function' ? output(results) : results;
};

export const readQuery = (query: Object, map?: Function) => (
  root: any,
  input: any,
  context: any
) => {
  const results = context.cache.readQuery({ query, variables: input });
  return typeof map === 'function' ? map(results) : results;
};

export const writeData = (typename: string, map?: Function) => (
  root: any,
  input: any,
  context: any
) => {
  const { id } = input;

  const name = typeof id === 'string' ? `${typename}:${id}` : typename;
  const data = {
    __typename: typename,
    ...input
  };

  context.cache.writeData({
    data: typeof map === 'function' ? map(data) : { [name]: data }
  });
  return data;
};

const passthroughResolver = (
  root: any,
  args: any,
  request: any // eslint-disable-line no-unused-vars
): Promise<any> => Promise.resolve(args);

// Maps additional top level values to input
export function withArgs(
  mapArgs: (root: any, args: any, context: any, next: any) => any,
  resolver?: Function
) {
  return async (root: any, args: any, context: any): Promise<any> => {
    const next = await (resolver || passthroughResolver)(root, args, context);
    const results = await Promise.resolve(mapArgs(root, args, context, next));
    return results;
  };
}

// Fork stack and add the results to the context
export function fork(resolver: Function, name?: string) {
  return async (root: any, input: any, context: any): Promise<any> => {
    const next = await (resolver || passthroughResolver)(root, input, context);
    if (typeof name === 'string') {
      context[name] = next;
    }
    return input;
  };
}

const wrapResolver = (resolver: Function) => (options: Array<any>) => {
  const [root, args, ...others] = options;
  return Promise.resolve(resolver(root, args, ...others)).then(nextArgs => [
    root,
    nextArgs,
    ...others
  ]);
};

export function compose(...resolvers: Array<Function>) {
  const resolver = composeP(...resolvers.map(wrapResolver).reverse());
  return (root: any, args: any, context: any) => {
    return resolver([root, args, context]).then(([, args]) =>
      Promise.resolve(args)
    );
  };
}

const createSortBy = (
  sort: Array<{ key: string, direction?: 'asc' | 'desc' }>
) => {
  return sortWith(
    sort.map(({ key, direction }) =>
      (direction || 'asc').toLowerCase() === 'desc'
        ? descend(prop(key))
        : ascend(prop(key))
    )
  );
};

export const connection = (resolver: Function, defaults: Object) =>
  withArgs((root, inputs, context, connection) => {
    const { after: nextAfter, first = 50, sort } = { ...defaults, ...inputs };
    const { nodes: nextNodes } = connection;
    const totalCount = (nextNodes || []).length;
    const after = parseInt(nextAfter || 0);

    let nodes = (nextNodes || []).slice();

    // Sort the nodes if needed
    if (Array.isArray(sort)) {
      nodes = createSortBy(sort)(nodes);
    }

    // Determine end cursor
    const endCursor = totalCount
      ? Math.min(nodes.length - 1, after + first)
      : null;
    const hasNextPage = nodes.length - 1 !== endCursor;

    // Limit results
    nodes = nodes.slice(after, after + first);

    const pageInfo = {
      endCursor: endCursor !== null ? endCursor.toString() : endCursor,
      hasNextPage,
      __typename: 'ClientPageInfo'
    };

    return { nodes, pageInfo, totalCount, __typename: 'ClientCache' };
  }, resolver);
