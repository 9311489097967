// @flow

import React, { type ComponentType } from 'react';
import { withStyles } from '@material-ui/styles';
import { getDisplayName } from '@material-ui/utils';
import theme from './theme';
import type { CSS, Creator } from './ThemeTypes';

// Alias `injectSheet` for backwards compatibility.
export const injectSheet = (
  stylesOrCreator: CSS | Creator,
  options: {} = {}
) => (WrappedComponent: ComponentType<*>) => {
  // Convention: Wrap the Display Name for Easy Debugging
  // https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
  class WithInjectSheet extends React.Component<*> {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  WithInjectSheet.displayName = `WithInjectSheet(${getDisplayName(
    WrappedComponent
  )})`;

  return withStyles(stylesOrCreator, {
    defaultTheme: theme,
    withTheme: true,
    ...options
  })(WithInjectSheet);
};

export default injectSheet;
