// @flow

import toString from './toString';
import Blob from './blob';

export function FilePolyfill(data: any, name: any, options: Object): Blob {
  const blob = new Blob(data, options);
  const date =
    options && undefined !== options.lastModified // eslint-disable-line no-void
      ? new Date(options.lastModified)
      : new Date();

  Object.defineProperties(blob, {
    name: {
      value: name
    },
    lastModifiedDate: {
      value: date
    },
    lastModified: {
      value: +date
    },
    toString: {
      value() {
        return '[object File]';
      }
    }
  });

  if (toString) {
    Object.defineProperty(blob, toString, {
      value: 'File'
    });
  }

  return blob;
}

let File = window.File;
try {
  new File([], ''); // eslint-disable-line no-new
} catch (a) {
  File = FilePolyfill;
}

if (toString) {
  try {
    File.prototype[toString] = 'File';
  } catch (e) {}
}

export default File;
