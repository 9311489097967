// @flow

import * as React from 'react';
import { useQuery } from 'react-apollo';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import path from 'path';
import { getFieldValue, Header2, SmallText } from '@catalytic/catalytic-ui';
import { parse as parseURI } from '@catalytic/uri';
import { makeStyles } from '@material-ui/styles';
import { type Task } from './TaskTypes';
import { PROCESS as PROCESS_PATH, STEPS as STEPS_PATH } from '../const/path';
import { buildFieldOptions, fieldValueIsEmpty } from '../Field/FieldHelpers';
import { type Field as FieldType } from '../Field/FieldTypes';
import InlineLoader from '../Loading/InlineLoader';
import { ContextFieldsQuery } from '../Run/RunTypeDefs';
import { RUN_TEST_MODE, type RunTestMode } from '../Run/RunTypes';
import { NODE_TYPE } from '../shared/NodeTypes';
import { type Add } from '../shared/PromiseQueue/PromiseQueue';
import { type Status, isInProgress } from '../shared/Status/StatusTypes';
import StepConfigurationInput from '../Step/StepConfigurationInput';
import {
  getColumnInput,
  getFileColumnsInputs,
  getTableIDs,
  INPUT_CHAIN_SELECT_SELECTION_TYPE,
  linkedConfigurationInputValues,
  STEP_INPUT_VALUES_KEYS,
  valueToString,
  type StepInputValues
} from '../Step/StepHelpers';
import debounce from '../utils/debounce';
import { isHandlebar, parseHandlebarReference } from '../utils/handlebar';
import useViewerContext from '../Viewer/ViewerContext';

const useStyles = makeStyles({
  fixTaskConfiguration: {
    marginTop: '1rem'
  }
});

export const resolveStepInputHandlebarReferences = (
  stepInputValues: StepInputValues,
  fields: Array<FieldType>
): StepInputValues => {
  return stepInputValues.map(({ name, value }) => {
    if (isHandlebar(value || '')) {
      const resolvedValue = fields.find(
        f => f.name === parseHandlebarReference(value || '')
      )?.value;
      if (resolvedValue) {
        return {
          name,
          value: resolvedValue
        };
      }
    }
    return { name, value };
  });
};

export const FailedTaskActionConfiguration = (props: {
  className?: string,
  contextId: string,
  failedTask: Task,
  fixTaskInputs: Array<FieldType>,
  ownerID?: string,
  promiseQueueAdd: Add,
  taskId: string,
  taskStatus: Status,
  testMode: ?RunTestMode,
  updateFieldValue: (options?: Object) => Promise<any>
}) => {
  const {
    className,
    contextId,
    failedTask,
    fixTaskInputs,
    ownerID,
    promiseQueueAdd,
    taskId,
    taskStatus,
    testMode,
    updateFieldValue
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  const configuration = failedTask.configuration || [];

  const appID = failedTask.appID || '';
  const stepID = failedTask.step?.id || '';
  const parsedStepURI = stepID ? parseURI(stepID) : {};
  const isActiveFixTask = isInProgress(taskStatus) && !!configuration;

  const { id: viewerID } = useViewerContext() || {};

  const viewerCanEditFixTask =
    (ownerID && ownerID === viewerID) || !!failedTask.step;

  const { data: fieldData = {}, loading: fieldsLoading } = useQuery(
    ContextFieldsQuery,
    {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      skip: !isActiveFixTask || !viewerCanEditFixTask,
      variables: {
        id: contextId
      }
    }
  );
  const fields = fieldData?.context?.fields?.nodes || [];

  const [stepInputValues, setStepInputValues] = React.useState(
    linkedConfigurationInputValues(configuration, '')
  );

  // Do not render if this isn't an active fix task or the task does not have
  // any configuration options
  if (!isActiveFixTask) {
    return null;
  }

  // Prevent access if the user is neither the instance owner nor has access
  // to the Workflow configuration (based on the presence of 'step')
  if (!viewerCanEditFixTask) {
    return null;
  }

  if (fieldsLoading) {
    return <InlineLoader />;
  }

  const fieldOptions = buildFieldOptions({
    fieldsByContext: [
      {
        displayName: 'Instance',
        id: contextId,
        fields,
        __typename: 'Context'
      }
    ],
    intl
  });

  const stepInputResolvedValues = resolveStepInputHandlebarReferences(
    stepInputValues,
    fields
  );

  const handleChange = (input: FieldType) => (
    event: SyntheticEvent<HTMLInputElement>
  ) => {
    const { id, name, required, type } = input;
    const value = getFieldValue(event.currentTarget.value, type);

    // Update stepInputValues state for related table and
    // spreadsheet column inputs
    if (STEP_INPUT_VALUES_KEYS.includes(name)) {
      debounce(
        () => {
          const newStepInputValues = (stepInputValues || []).map(input =>
            name === input.name
              ? {
                  ...input,
                  value: value || ''
                }
              : input
          );
          setStepInputValues(newStepInputValues);
        },
        {
          key: 'stepInputValuesChanged'
        }
      );
    }

    // Required fields
    if (required && fieldValueIsEmpty(value)) {
      return;
    }

    const { isColumnInput, isMulti } = getColumnInput({
      appID,
      input,
      inputs: configuration,
      name
    });
    const valueStr = valueToString({
      isMulti,
      isColumnInput,
      selectionType: INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM,
      type,
      value
    });

    // Update fields
    debounce(
      () =>
        promiseQueueAdd('update', () =>
          updateFieldValue({
            variables: {
              input: {
                id,
                value: valueStr
              }
            },
            optimisticResponse: {
              __typename: 'Mutation',
              updateFieldValue: {
                __typename: 'Field',
                id,
                value: valueStr
              }
            }
          })
        ),
      {
        key: `${id}:updateFieldValue`
      }
    );
  };

  return (
    <div className={className}>
      <Header2>
        <FormattedMessage
          id="task.failed.action.configuration.header"
          defaultMessage="Fix this Issue"
        />
      </Header2>
      <p>
        <SmallText>
          <FormattedMessage
            id="task.failed.action.configuration.description"
            defaultMessage="Update the action's configuration below to try and correct the issue. When ready, select {retryActionButton}. Or, to try and skip the action completely, select {skipActionButton}. Changes will only affect this instance, and will not update the Workflow itself."
            values={{
              retryActionButton: (
                <strong>
                  <FormattedMessage
                    id="task.failed.action.configuration.retryAction.label"
                    defaultMessage="Retry Action"
                  />
                </strong>
              ),
              skipActionButton: (
                <strong>
                  <FormattedMessage
                    id="task.failed.action.configuration.skipAction.label"
                    defaultMessage="Skip Action"
                  />
                </strong>
              )
            }}
          />
        </SmallText>
      </p>
      <p>
        <SmallText>
          {testMode !== RUN_TEST_MODE.TASK && (
            <FormattedMessage
              defaultMessage="Your Workflow may need to be updated to avoid this issue in the future."
              id="task.failed.action.configuration.edit.step"
            >
              {txt => (
                <>
                  {txt}{' '}
                  <Link
                    rel="noopener noreferrer"
                    target="_blank"
                    to={path.join(
                      PROCESS_PATH,
                      parsedStepURI.context || '',
                      STEPS_PATH,
                      parsedStepURI.id || ''
                    )}
                  >
                    <FormattedMessage
                      id="task.failed.action.configuration.edit.step.link"
                      defaultMessage="Open Action in Builder."
                    />
                  </Link>
                </>
              )}
            </FormattedMessage>
          )}
        </SmallText>
      </p>
      <div className={classes.fixTaskConfiguration}>
        {[...fixTaskInputs, ...configuration].map((input: FieldType) => {
          const { id, name } = input;

          const { fileColumnsFields, tableIDsFields } = getColumnInput({
            appID,
            input,
            inputs: configuration,
            name
          });
          const fileColumnsInputs = getFileColumnsInputs({
            fields: fileColumnsFields,
            prefix: '',
            values: stepInputResolvedValues
          });
          const tableIDs = getTableIDs({
            fields: tableIDsFields,
            prefix: '',
            values: stepInputResolvedValues
          });

          return (
            <StepConfigurationInput
              appID={appID}
              context={{
                __typename: NODE_TYPE.TASK,
                id: taskId
              }}
              disabled={false}
              fieldOptions={fieldOptions}
              fields={fields}
              fileColumnsInputs={fileColumnsInputs}
              input={input}
              isInline={false}
              key={id}
              onChange={handleChange(input)}
              supportsInlineWorkflow={false}
              tableIDs={tableIDs}
            />
          );
        })}
      </div>
    </div>
  );
};
FailedTaskActionConfiguration.displayName = 'FailedTaskActionConfiguration';

export default FailedTaskActionConfiguration;
