// @flow

import React from 'react';
import { compose } from 'recompose';
import { injectIntl, FormattedMessage } from 'react-intl';

import { TYPE, type Type } from './SearchTypes';

type Props = { intl: Object, type: Type };

const SearchTypeTitle = ({ type, ...props }: Props) => (
  <span {...props}>
    {type === TYPE.ACTOR ? (
      <FormattedMessage
        id="searchTypeTitle.contextType"
        defaultMessage="Users"
      />
    ) : type === TYPE.CONTEXT_TYPE ? (
      <FormattedMessage
        id="searchTypeTitle.contextType"
        defaultMessage="Workflows"
      />
    ) : type === TYPE.CONTEXT ? (
      <FormattedMessage
        id="searchTypeTitle.context"
        defaultMessage="Instances"
      />
    ) : type === TYPE.DATA_TABLE ? (
      <FormattedMessage
        id="searchTypeTitle.dataTable"
        defaultMessage="Tables"
      />
    ) : type === TYPE.HELP ? (
      <FormattedMessage id="searchTypeTitle.dataTable" defaultMessage="Help" />
    ) : (
      <FormattedMessage id="searchTypeTitle.task" defaultMessage="Task" />
    )}
  </span>
);

SearchTypeTitle.displayName = 'SearchTypeTitle';

export default compose(injectIntl)(SearchTypeTitle);
