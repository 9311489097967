// @flow

import React from 'react';
import ThemeProvider from '../style/ThemeProvider';
import theme from '../style/theme';
import { type ThemeType } from '../style/ThemeTypes';

type Props = {
  theme: ThemeType
};

const MockedThemeProvider = (props: Props) => {
  return <ThemeProvider {...props} />;
};
MockedThemeProvider.displayName = 'MockedThemeProvider';
MockedThemeProvider.defaultProps = { theme };

export default MockedThemeProvider;
