// @flow

import classNames from 'classnames';
import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import { type InjectSheetProvidedProps } from '../../style/ThemeTypes';
import { SmallText } from '../../Text/Text';

const Style = () => {
  return {
    root: {
      background: 'transparent',
      verticalAlign: 'bottom'
    }
  };
};

type Props = InjectSheetProvidedProps & {
  children?: Node
};

const ListItemGroupHeaderButton = ({
  classes,
  className,
  theme,
  ...props
}: Props): Node => (
  <SmallText
    component="button"
    className={classNames(classes.root, className)}
    {...props}
  />
);

ListItemGroupHeaderButton.displayName = 'ListItemGroupHeaderButton';

export default injectSheet(Style)(ListItemGroupHeaderButton);
