// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import compose from 'ramda/src/compose';
import mergeLeft from 'ramda/src/mergeLeft';
import { makeStyles } from '@material-ui/styles';
import AvatarV2 from '../AvatarV2/AvatarV2';
import Clamp from '../../Clamp/Clamp';
import Grid from '../../Grid/Grid';
import { ExtraSmallText, SmallText } from '../../Text/Text';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    padding: '1rem',
    height: '15rem',
    border: `1px solid ${theme.colors.lightGrey}`,
    borderRadius: theme.variables.borderRadiusSmall,
    boxShadow: `0px 1px 2px 0px ${theme.colors.lightGreyAlpha}`,
    textAlign: 'left'
  },
  avatar: {
    '&, &:active, &:focus, &:hover': {
      marginBottom: '1rem',
      padding: 0,
      width: '2.5rem',
      height: '2.5rem',
      borderRadius: 0
    }
  },
  displayName: {
    display: 'block',
    marginBottom: '0.5rem',
    width: '100%',
    fontWeight: '700'
  },
  description: {
    display: 'block',
    marginBottom: '0.5rem',
    width: '100%',
    color: theme.colors.pebbleGrey
  },
  footer: {
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    height: '2rem',
    padding: '0 1rem',
    backgroundColor: theme.colors.blueGrey,
    color: theme.colors.pebbleGrey,
    lineHeight: '2rem'
  },
  footerContainer: {
    padding: '1rem 1rem 2.5rem'
  }
}));

type MenuType = React.Element<any>; // typeof Menu

type Props = {|
  backgroundColor?: string,
  children?: React.Node,
  className?: string,
  description?: string | React.Element<typeof FormattedMessage>,
  displayName: string | React.Element<typeof FormattedMessage>,
  favorite?: React.Node,
  footer?: React.Node,
  isFavorite?: boolean,
  menu?: MenuType,
  src: string
|};

const Card = ({
  backgroundColor,
  children,
  className,
  description,
  displayName,
  favorite,
  footer,
  menu,
  src
}: Props) => {
  const classes = useStyles();

  return (
    <Grid
      className={classNames(
        classes.container,
        {
          [classes.footerContainer]: footer
        },
        className
      )}
      container
    >
      <Grid container direction="column" item xs zeroMinWidth>
        <Grid container>
          <Grid item xs>
            <AvatarV2
              appearance="square"
              borderColor="transparent"
              className={classes.avatar}
              component={({ className, children }) => (
                <span className={className}>{children}</span>
              )}
              size="large"
              src={src}
              theme={(current, props) =>
                compose(
                  mergeLeft({
                    backgroundColor: backgroundColor || 'transparent',
                    borderRadius: 0,
                    dimensions: {
                      width: 'auto',
                      height: 'auto'
                    }
                  }),
                  current
                )(props)
              }
            />
          </Grid>
          {(favorite || menu) && (
            <Grid container item justify="flex-end" xs>
              {favorite && <Grid item>{favorite}</Grid>}
              {menu && <Grid item>{menu}</Grid>}
            </Grid>
          )}
        </Grid>
        <SmallText className={classes.displayName}>
          <Clamp clamp={2}>{displayName}</Clamp>
        </SmallText>
        {description && (
          <ExtraSmallText className={classes.description}>
            <Clamp clamp={4}>{description}</Clamp>
          </ExtraSmallText>
        )}
        {children}
        {footer && (
          <ExtraSmallText className={classes.footer}>
            <Clamp clamp={1}>{footer}</Clamp>
          </ExtraSmallText>
        )}
      </Grid>
    </Grid>
  );
};
Card.displayName = 'Card';

export default Card;
