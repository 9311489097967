// @flow

import { UnifiedNumberFormat } from '@formatjs/intl-unified-numberformat';
import { LOCALE } from '../const/locale';

UnifiedNumberFormat.__addLocaleData(
  require('@formatjs/intl-unified-numberformat/dist/locale-data/en.json') // locale-data for en
);

export function unifiedNumberFormat({ value, ...options }: { value: number }) {
  return new UnifiedNumberFormat(LOCALE, options).format(value);
}

export default unifiedNumberFormat;
