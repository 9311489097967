// @flow

import gqlForClientStore from 'graphql-tag';

// Disable GraphQL linting for apollo-link-state until this issue is
// resolved:
// https://github.com/apollographql/eslint-plugin-graphql/pull/117
/* eslint-disable graphql/template-strings */
export const UpdateStoreRecord = gqlForClientStore`
  mutation UpdateStoreRecord($input: StoreRecordInput!) {
    updateStoreRecord(input: $input) @client {
      createdDate
      id
      value
    }
  }
`;

// `eslint-plugin-graphql` throws a type error while linting this
// fragment so `gql` is aliased as `gqlForClientStore`, effectively
// opting the tag out of linting. We may need to configure an additional
// schema for the `graphql/required-fields` rule.
// https://github.com/apollographql/eslint-plugin-graphql/#additional-schemas-or-tags
export const StoreRecordFragment = gqlForClientStore`
  fragment StoreRecordFragment on StoreRecord {
    createdDate
    id
    value
  }
`;

export const StoreRecord = gqlForClientStore`
  query StoreRecord($id: String!) {
    storeRecord(id: $id) @client {
      createdDate
      id
      value
    }
  }
`;
/* eslint-enable graphql/template-strings */
