// @flow

import * as React from 'react';
import omitPath from 'ramda/src/dissocPath';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ModalView from '@catalytic/catalytic-ui/dist/View/ModalView/ModalView';
import { makeStyles } from '@material-ui/styles';

const omitBackgroundState = omitPath(['state', 'background']);

const useStyles = makeStyles({
  link: {
    '&:active': {
      opacity: 1
    }
  }
});

export function RouterConnectedModal({
  children,
  ...other
}: {
  children: React.Node
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  return (
    <ModalView
      {...other}
      width="large"
      isOpen
      onRequestClose={() => {
        history.goBack();
      }}
      openElement={
        <Link
          className={classes.link}
          replace
          to={omitBackgroundState(location)}
        />
      }
    >
      {children}
    </ModalView>
  );
}

export default RouterConnectedModal;
