// @flow

import compose from 'ramda/src/compose';
import equals from 'ramda/src/equals';
import { type JSONSchema } from '@catalytic/json-schema-validator-catalytic-web';
import { NodeType, type View } from '@catalytic/view';
import {
  FORMAT,
  TYPE,
  type Field,
  type FieldInput,
  type FieldTypeFormat
} from './FieldTypes';
import getViewNodeTypeLabel from '../utils/getViewNodeTypeLabel';

/*
 * Constants and utilities shared by forms that configure Fields.
 */

const ARRAY = {
  type: TYPE.ARRAY,
  format: null,
  displayOnly: false
};
const BOOLEAN = {
  type: TYPE.BOOLEAN,
  format: null,
  displayOnly: false
};
const STRING = {
  type: TYPE.STRING,
  format: null,
  displayOnly: false,
  hasEnum: false
};
const STRING_CHOICE = {
  type: TYPE.STRING,
  format: null,
  displayOnly: false,
  hasEnum: true
};
const STRING_CODE = {
  type: TYPE.STRING,
  format: FORMAT.CODE,
  displayOnly: false
};
const STRING_CODE_CSS = {
  type: TYPE.STRING,
  format: FORMAT.CODE_CSS,
  displayOnly: false
};
const STRING_CODE_HTML = {
  type: TYPE.STRING,
  format: FORMAT.CODE_HTML, // eslint-disable-line xss/no-mixed-html
  displayOnly: false
};
const STRING_CODE_JAVASCRIPT = {
  type: TYPE.STRING,
  format: FORMAT.CODE_JAVASCRIPT,
  displayOnly: false
};
const STRING_CODE_PYTHON = {
  type: TYPE.STRING,
  format: FORMAT.CODE_PYTHON,
  displayOnly: false
};
const STRING_DISPLAY_ONLY = {
  type: TYPE.STRING,
  format: null,
  displayOnly: true
};
const STRING_FILE = {
  type: TYPE.STRING,
  format: FORMAT.FILE,
  displayOnly: false
};
const STRING_FILE_TABLE = {
  type: TYPE.STRING,
  format: FORMAT.TABLE,
  displayOnly: false,
  hasContentMediaType: true
};
const STRING_CONDITION = {
  type: TYPE.STRING,
  format: FORMAT.CONDITION,
  displayOnly: false
};
const STRING_CONTEXT_TYPE = {
  type: TYPE.STRING,
  format: FORMAT.CONTEXT_TYPE,
  displayOnly: false
};
const STRING_TABLE = {
  type: TYPE.STRING,
  format: FORMAT.TABLE,
  displayOnly: false,
  hasContentMediaType: false
};
const STRING_TIMEZONE = {
  type: TYPE.STRING,
  format: FORMAT.TIMEZONE,
  displayOnly: false
};
const ARRAY_FILE = {
  type: TYPE.ARRAY,
  format: FORMAT.FILE,
  displayOnly: false
};

const COLUMN_BINDING_IDENTIFIER = 'columns';

const fieldToFieldTypeFormat = (field: Field | FieldInput): FieldTypeFormat => {
  const {
    displayOnly = false,
    enum: enumeration,
    format = null,
    schema,
    type = TYPE.STRING
  } = field;
  const fieldTypeFormat: FieldTypeFormat = {
    displayOnly,
    format,
    type
  };

  // `hasEnum` identifies STRING type fields that have any number of
  // enum values set. That way we know to render the FieldConfigurationView
  // Type input with the option for "Choose One" rather than "Text".
  if (type === TYPE.STRING && format === null && displayOnly === false) {
    fieldTypeFormat.hasEnum =
      Array.isArray(enumeration) && enumeration.length > 0;
  }

  // `hasContentMediaType` identifies STRING type fields that have any
  // contentMediaType value set. That way we know to render the FieldConfigurationView
  // Type input with the option for "Multiple File" rather than "Table".
  if (
    type === TYPE.STRING &&
    format === FORMAT.TABLE &&
    displayOnly === false
  ) {
    fieldTypeFormat.hasContentMediaType =
      typeof schema?.contentMediaType === 'string';
  }

  return fieldTypeFormat;
};

export const createTypeFormatIdentifierForField = (field: Field): string =>
  createTypeFormatIdentifier(fieldToFieldTypeFormat(field));

export const createTypeFormatIdentifier = ({
  type,
  format,
  displayOnly,
  hasEnum,
  hasContentMediaType
}: FieldTypeFormat): string =>
  JSON.stringify({ type, format, displayOnly, hasEnum, hasContentMediaType });

const createIsFieldType = compose(equals, createTypeFormatIdentifier);
export const isArrayType = createIsFieldType(ARRAY);
export const isBooleanType = createIsFieldType(BOOLEAN);
export const isFileType = createIsFieldType(STRING_FILE);
export const isFileTableType = createIsFieldType(STRING_FILE_TABLE);
export const isConditionType = createIsFieldType(STRING_CONDITION);
export const isProcessType = createIsFieldType(STRING_CONTEXT_TYPE);
export const isStringChoiceType = createIsFieldType(STRING_CHOICE);
export const isStringCodeType = (typeFormatId: string) =>
  createIsFieldType(STRING_CODE)(typeFormatId) ||
  createIsFieldType(STRING_CODE_CSS)(typeFormatId) ||
  createIsFieldType(STRING_CODE_HTML)(typeFormatId) || // eslint-disable-line xss/no-mixed-html
  createIsFieldType(STRING_CODE_JAVASCRIPT)(typeFormatId) ||
  createIsFieldType(STRING_CODE_PYTHON)(typeFormatId);
export const isStringDisplayOnlyType = createIsFieldType(STRING_DISPLAY_ONLY);
export const isStringTimezoneType = createIsFieldType(STRING_TIMEZONE);
export const isStringType = createIsFieldType(STRING);
export const isTableType = createIsFieldType(STRING_TABLE);
export const isFileArrayType = createIsFieldType(ARRAY_FILE);

export const usesColumnBinding = (field: Field | FieldInput) => {
  const bindingPieces = (field?.schema?.$binding?.enum || '').split('/');
  return bindingPieces[2] === COLUMN_BINDING_IDENTIFIER;
};

export const isArrayColumnType = (field: Field | FieldInput) => {
  return usesColumnBinding(field) && field?.type === TYPE.ARRAY;
};

export const isColumnType = (field: Field | FieldInput) => {
  return usesColumnBinding(field) && field.type !== TYPE.ARRAY;
};

// These reserved internal names and the displayName->fieldName transformation
// logic was copied from pushbot-api's implementation of the same thing. If
// they get out of sync, it's not the end of the world, but may produce some
// confusion if fields are created with slightly different names than what
// users expected.
const RESERVED_INTERNAL_NAMES = new Set([
  'run',
  'files',
  'fields',
  'thread',
  'status',
  'deadline',
  'category',

  // everything on Object and Array prototypes that's also a valid field name
  // see https://github.com/catalyticlabs/product/issues/2714 for why this is necessary for now
  'constructor',
  'length',
  'join',
  'pop',
  'push',
  'reverse',
  'shift',
  'unshift',
  'slice',
  'splice',
  'sort',
  'filter',
  'some',
  'every',
  'map',
  'reduce',
  'find',
  'fill',
  'includes',
  'entries',
  'keys',
  'concat'
]);

export const displayNameToInternalName = (displayName: string) => {
  if (!displayName) {
    return displayName;
  }
  return displayName
    .trim()
    .toLowerCase()
    .replace(/\W*-{2,}\W*/g, '--')
    .replace(/(?!--)(\W+)/g, '-')
    .replace(/^-+/g, '');
};

export const displayNameToInternalFieldName = (displayName: string) => {
  let internalName = displayNameToInternalName(displayName);

  if (RESERVED_INTERNAL_NAMES.has(internalName)) {
    internalName += '-1';
  }
  return internalName;
};

export const getDefaultViewForField = ({
  viewConfiguration
}: {
  viewConfiguration?: View<>
}): ?string => {
  if (viewConfiguration) {
    const { views } = viewConfiguration;
    if (views.length > 0) {
      return viewConfiguration.views[0].type || null;
    }
  }
  return null;
};

export const getTypeLabelForField = (field: Field | FieldInput): string => {
  const defaultView = getDefaultViewForField(field) || NodeType.TEXT_VIEW;
  const refType = field.schema?.refType;
  const displayOnly = field.schema?.displayOnly;

  return getViewNodeTypeLabel(defaultView, refType, displayOnly);
};

export const restrictionsThatMustBeNumbers: Array<$Keys<JSONSchema<>>> = [
  'maxLength',
  'minLength',
  'maximum',
  'minimum',
  'multipleOf'
];
