// @flow

import React, { type Node } from 'react';
import { getDisplayName } from '@material-ui/utils';
import StylesProvider, { jss } from '../style/StylesProvider';

type Rule = { key: string };

type StyleSheet = { options: { meta: string } };

export const generateClassName = (rule: Rule, styleSheet: StyleSheet) =>
  `${styleSheet?.options?.meta}-${rule?.key}-test`;

type Props = {
  children: Node,
  generateClassName: (rule: Rule, sheet: StyleSheet) => string
};

const MockedStylesProvider = ({ children, intl, ...props }: Props) => {
  // For react-cosmos, get display name from fixture component.
  const displayName = getDisplayName(
    React.Children.only(children)?.props?.fixture?.component
  );

  if (displayName) {
    return (
      <StylesProvider
        {...props}
        generateClassName={(rule: Rule) => `${displayName}-${rule?.key}-test`}
      >
        {children}
      </StylesProvider>
    );
  }

  return <StylesProvider {...props}>{children}</StylesProvider>;
};
MockedStylesProvider.displayName = 'MockedStylesProvider';
MockedStylesProvider.defaultProps = { generateClassName, jss };

export default MockedStylesProvider;
