// @flow

import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { type CSS, type ThemeType } from '../../style/ThemeTypes';

const MARGIN = 1;

const useStyles = makeStyles((theme: ThemeType) => ({
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  spinner: {
    display: 'inline-block',
    margin: theme.functions.toRem(MARGIN),
    width: theme.functions.toRem(parseInt(theme.variables.icon) - MARGIN * 2),
    height: theme.functions.toRem(parseInt(theme.variables.icon) - MARGIN * 2),
    animation: '$rotate 1.1s infinite linear',
    borderRadius: '50%',
    borderWidth: '.125rem',
    borderStyle: 'solid',
    borderTopColor: 'transparent',
    borderRightColor: 'inherit',
    borderBottomColor: 'transparent',
    borderLeftColor: 'inherit',
    backgroundColor: 'transparent'
  }
}));

type Props = {
  className?: string,
  style?: CSS
};

const Spinner = ({ className, style }: Props) => {
  const classes = useStyles();

  return (
    <span className={classNames(classes.spinner, className)} style={style} />
  );
};
Spinner.displayName = 'Spinner';

export default Spinner;
