// @flow

import debounce from 'awesome-debounce-promise';
import { WAIT as DEBOUNCE_WAIT } from '../const/debounce';

const asyncFunctionDebounced = debounce(
  asyncFunction => asyncFunction(),
  DEBOUNCE_WAIT,
  {
    // One distinct debounced function is created per key and added to an internal cache
    // By default, the key is null, which means that all the calls
    // will share the same debounced function
    key: (asyncFunction, { key = null } = {}) => {
      return key;
    },
    // By default, a debounced function will only resolve
    // the last promise it returned
    // Former calls will stay unresolved, so that you don't have
    // to handle concurrency issues in your code
    // Setting this to false means all returned promises will resolve to the last result
    onlyResolvesLast: (asyncFunction, { onlyResolvesLast = true } = {}) => {
      return onlyResolvesLast;
    }
  }
);

export default asyncFunctionDebounced;
