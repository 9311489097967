// @flow

import { Analytics } from '@catalytic/analytics-client';
import { fromRenderProps } from 'recompose';
import { AnalyticsConsumer } from '../Analytics/AnalyticsContext';

export { Analytics };

export type Props = {
  analytics?: Analytics
};

export const DEFAULT_ANALYTICS: Analytics = {
  page: () => {},
  track: () => {}
};

const withAnalytics = fromRenderProps<Props, *, 'analytics', Object>(
  (AnalyticsConsumer: any),
  ({ analytics = DEFAULT_ANALYTICS }) => ({
    analytics:
      process.env.NODE_ENV === 'development' ? DEFAULT_ANALYTICS : analytics
  })
);

export default withAnalytics;
