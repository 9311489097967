// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import FilterSortItem from './FilterSortItem';
import SearchInput from '../../Input/SearchInput/SearchInput';
import { type ThemeType } from '../../style/ThemeTypes';

const useStyles = makeStyles((theme: ThemeType) => {
  const INPUT_PLACEHOLDER_VALUE = {
    ...theme.mixins.inputPlaceholder,
    ...theme.typography.smallText
  };

  return {
    root: {
      marginBottom: '.5rem',
      [theme.breakpoints.gte.tablet.portrait]: {
        marginBottom: 0
      }
    },
    input: {
      paddingTop: '.375rem',
      paddingBottom: '.375rem',
      borderColor: theme.colors.blueGrey,
      backgroundColor: theme.colors.blueGrey,
      '&:hover, &:focus': {
        borderColor: theme.colors.blueGrey,
        backgroundColor: theme.colors.blueGrey
      },
      '&::placeholder': INPUT_PLACEHOLDER_VALUE,
      '&::-webkit-input-placeholder': INPUT_PLACEHOLDER_VALUE,
      '&:-ms-input-placeholder': INPUT_PLACEHOLDER_VALUE,
      '&::-ms-input-placeholder': INPUT_PLACEHOLDER_VALUE,
      '&::-moz-placeholder': INPUT_PLACEHOLDER_VALUE,
      '&:-moz-placeholder': INPUT_PLACEHOLDER_VALUE
    }
  };
});

type Props = {
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => any,
  defaultValue?: string,
  searchKey?: string
};

const FilterSortSearch = ({ defaultValue, onChange, searchKey }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FilterSortItem
        label={
          <FormattedMessage id="filter.search.label" defaultMessage="Filter" />
        }
      >
        <SearchInput
          data-testid="filter-sort-search"
          defaultValue={defaultValue}
          inputClassName={classes.input}
          key={searchKey}
          onChange={onChange}
        />
      </FilterSortItem>
    </div>
  );
};
FilterSortSearch.displayName = 'FilterSortSearch';

export default FilterSortSearch;
