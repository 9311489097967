// @flow

import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import path from 'path';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import {
  Clamp,
  FormattedRelative,
  ListItem,
  ListItemBody,
  ListItemContents,
  ListItemFooter,
  ListItemHeader,
  ListItemIcon,
  type ThemeType
} from '@catalytic/catalytic-ui';
import StatusBadge, {
  toStatusMessage,
  toStatusColor
} from '../shared/Status/StatusBadge';
import {
  isCompleted,
  isSnoozed,
  isInProgress,
  type Status
} from '../shared/Status/StatusTypes';
import { type Task } from './TaskTypes';
import RunLink from '../Run/RunLink';
import { type Run } from '../Run/RunTypes';
import { RUNS as RUNS_PATH, TASKS_V2 as TASKS_V2_PATH } from '../const/path';
import { ReactComponent as AutomatedProcessIcon } from '@catalytic/catalytic-icons/lib/icons/automated-process.svg';
import { ReactComponent as TaskTransparentIcon } from '@catalytic/catalytic-icons/lib/icons/task-transparent.svg';
import { type Field } from '../Field/FieldTypes';
import isUUID from '../utils/uuid';
import { TaskQuery } from './TaskDetail';

const DIVIDER = '|';

/*
 * Utility to transform task description
 *
 * 1. Replace carriage return and line feed with space.
 * 2. Replace multiple spaces with single space.
 * 3. Trim whitespace from both ends of the string.
 */
export const toTaskDescription = (string: string) =>
  string
    .replace(/\n|\r/g, ' ')
    .replace(/\s+/g, ' ')
    .trim();

/*
 * Utilities to find the runID of a subprocess run task
 */
const OUTPUT_FIELD_DELIMITER = '--';
const RUN_FIELD_NAME = 'run-id';
const PUSHBOT_APP_NAME = 'pushbot';
const SUBPROCESS_ACTION = 'subprocess';
const CONDITIONAL_BLOCK_ACTION = 'conditional-block';
const isRunFieldName = (name: ?string) => {
  if (!name || name.indexOf(OUTPUT_FIELD_DELIMITER) < 1) {
    return false;
  }
  const namePieces = name.split(OUTPUT_FIELD_DELIMITER);
  const unprefixed = namePieces[namePieces.length - 1];
  return unprefixed === RUN_FIELD_NAME;
};
const appIDSpportsNestedRunTasks = (appID: string): boolean => {
  const [appName, action] = appID.split('/');
  if (appName === PUSHBOT_APP_NAME && action === SUBPROCESS_ACTION) {
    return true;
  }
  if (appName === PUSHBOT_APP_NAME && action === CONDITIONAL_BLOCK_ACTION) {
    return true;
  }
  return false;
};
const toRunID = ({
  appID,
  inputIDs,
  fields
}: {
  appID?: ?string,
  inputIDs?: ?Array<string>,
  fields?: ?Array<Field>
}): string | null => {
  const supportsNestedRunTasks = appIDSpportsNestedRunTasks(appID || '');
  // It's important to grab the first one as defined in the `inputIDs`
  // list. Otherwise, if there are multiple subprocess tasks in a run,
  // this may pick the run ID field belonging to a different task, and
  // link to the wrong place. The subprocess receives the full set of
  // fields from the parent, and has the chance to update them as it
  // advances. That includes run ID fields from other subprocess
  // actions in the parent.
  const firstRunIDInputID = (inputIDs || []).find(isRunFieldName);
  const runIDInput = firstRunIDInputID
    ? (fields || []).find(field => field.name === firstRunIDInputID)
    : null;
  const runIDReference =
    supportsNestedRunTasks &&
    runIDInput &&
    typeof runIDInput.value === 'string' &&
    isUUID(runIDInput.value || '')
      ? runIDInput.value
      : null;
  return runIDReference;
};

/*
 * Styles
 */
const useStyles = makeStyles((theme: ThemeType) => {
  return {
    badge: {
      display: 'none',
      [theme.breakpoints.gt.mobile.portrait]: {
        display: 'block'
      }
    },
    /* Offset for transparency effect inside the icon */
    listItemIcon: {
      position: 'relative',
      top: '.3rem',
      '& svg': {
        margin: '-.3rem -.125rem'
      }
    }
  };
});

/*
 * GraphQL
 */
export const TaskListItemFragment = gql`
  fragment TaskListItemFragment on Task {
    appID
    context {
      id
      displayName
      status
    }
    displayName
    description(input: { format: TEXT, limit: 500 })
    hasApp
    id
    startDate
    endDate
    deadline
    status
    delayStartUntil
    assignedTo {
      id
      displayName
    }
    inputIDs
  }
`;

/*
 * Component
 */
type Props = {
  ...$Exact<Task>,
  context: Run,
  status: Status,
  fields?: Array<Field>,
  hasApp: boolean
};
type BaseProps = {
  ...$Exact<Props>,
  children?: React.Node,
  client: { query: (options: Object) => Promise<Object> }
};
export const TaskListItemBase = React.memo<BaseProps>(function TaskListItem(
  props: BaseProps
) {
  const {
    __typename,
    appID,
    assignedTo,
    children,
    client,
    context,
    deadline,
    delayStartUntil,
    description,
    displayName,
    endDate,
    fields,
    hasApp,
    id,
    inputIDs,
    startDate,
    status,
    ...other
  } = props;
  const classes = useStyles();
  const location = useLocation();
  const prefetch = React.useCallback(() => {
    client.query({
      errorPolicy: 'all',
      query: TaskQuery,
      variables: { id }
    });
  }, [client, id]);
  const runID = toRunID({ appID, inputIDs, fields });
  const linkProps =
    runID !== null
      ? {
          to: { pathname: path.join(RUNS_PATH, runID) }
        }
      : {
          to: {
            pathname: path.join(TASKS_V2_PATH, id || ''),
            state: {
              background: location
            }
          },
          onMouseOver: prefetch,
          onFocus: prefetch
        };

  return (
    <ListItem>
      {status && (
        <ListItemIcon
          className={classes.listItemIcon}
          colorScheme={hasApp ? null : toStatusColor(status)}
        >
          {hasApp ? <AutomatedProcessIcon /> : <TaskTransparentIcon />}
        </ListItemIcon>
      )}
      <ListItemContents>
        <Link {...linkProps} {...other}>
          {displayName && (
            <ListItemHeader>
              <Clamp clamp={2}>{displayName}</Clamp>
            </ListItemHeader>
          )}
          {description && (
            <ListItemBody className={classes.listItemBody}>
              <Clamp clamp={2}>{toTaskDescription(description)}</Clamp>
            </ListItemBody>
          )}
        </Link>
        <ListItemFooter>
          {assignedTo && (
            <>
              <FormattedMessage
                defaultMessage="Assigned to {assignee} {divider}"
                description="Assigned to"
                id="task.assignee"
                values={{
                  assignee: assignedTo.displayName,
                  divider: DIVIDER
                }}
              />{' '}
            </>
          )}
          {isInProgress(status) && deadline
            ? `Due `
            : isCompleted(status) && endDate
            ? `Completed `
            : isSnoozed(status) && delayStartUntil
            ? `Returns `
            : startDate
            ? `Started `
            : status && toStatusMessage(status)}
          {isInProgress(status) && deadline ? (
            <FormattedRelative value={deadline} />
          ) : isCompleted(status) && endDate ? (
            <FormattedRelative value={endDate} />
          ) : isSnoozed(status) && delayStartUntil ? (
            <FormattedRelative value={delayStartUntil} />
          ) : (
            startDate && <FormattedRelative value={startDate} />
          )}{' '}
          {children}
        </ListItemFooter>
      </ListItemContents>
      <div>
        <StatusBadge status={status} className={classes.badge} />
      </div>
    </ListItem>
  );
});

export const TaskListItem = (withApollo(
  TaskListItemBase
): React.ComponentType<Props>);

export const TaskListItemWithRunLink = (props: Props) => {
  const { context: run } = props;

  return (
    <TaskListItem {...props}>
      <RunLink run={run} />
    </TaskListItem>
  );
};

export default TaskListItem;
