// @flow

import React from 'react';
import { create } from 'jss';
import {
  jssPreset,
  ThemeProvider as JssThemeProvider
} from '@material-ui/styles';
import animationStyle from './animationStyle';
import globalStyle from './globalStyle';
import theme from './theme';
import { type ThemeType } from './ThemeTypes';

// Attach Global stylesheet, reset user-agent styles
create({
  ...jssPreset(),
  insertionPoint: window.document.getElementById('global-css')
})
  .createStyleSheet(globalStyle, { meta: 'Global' })
  .attach();

// Attach Animation stylesheet, keyframe animations used by all components
create({
  ...jssPreset(),
  insertionPoint: window.document.getElementById('animation-css')
})
  .createStyleSheet(animationStyle, { meta: 'Animation' })
  .attach();

type Props = {
  theme: ThemeType
};

const ThemeProvider = (props: Props) => {
  React.useEffect(() => {
    window.document.documentElement.setAttribute(
      'data-userAgent',
      navigator.userAgent
    );
    window.document.documentElement.setAttribute(
      'data-platform',
      navigator.platform
    );
  }, []);

  return <JssThemeProvider {...props} />;
};
ThemeProvider.displayName = 'ThemeProvider';
ThemeProvider.defaultProps = { theme };

export default ThemeProvider;
