// @flow

import React, {
  forwardRef,
  type AbstractComponent,
  type ElementRef,
  type Node
} from 'react';
import classNames from 'classnames';
import {
  injectSheet,
  type InjectSheetProvidedProps
} from '@catalytic/catalytic-ui';

const Style = () => ({
  root: {
    overflowX: 'hidden',
    overflowY: 'auto'
  }
});

type Props = InjectSheetProvidedProps & {
  forwardedRef?: { current: ElementRef<*> | null }
};

const SearchList = ({
  classes,
  className,
  forwardedRef,
  theme,
  ...props
}: Props): Node => (
  <div
    {...props}
    className={classNames(classes.root, className)}
    ref={forwardedRef}
  />
);
SearchList.displayName = 'SearchList';

const EnhancedSearchList: AbstractComponent<
  {
    forwardedRef?: { current: ElementRef<*> | null }
  },
  void
> = injectSheet(Style)(SearchList);

// Flow type checker is satisfied by inline generics notation `forwardRef<Object, void>`
// as per https://medium.com/flow-type/supporting-react-forwardref-and-beyond-f8dd88f35544
// but seems like the es-lint parser can't handle the syntax.
// $FlowIgnore
const EnhancedSearchListWithRef = forwardRef((props, ref) => (
  // Ref prop named `forwardedRef` because if we pass `ref={ref}` here React
  // gives us a reference to the React element representation of the
  // EnhancedSearchList component rather than an instance of the DOM element
  // rendered by SearchList.
  <EnhancedSearchList {...props} forwardedRef={ref} />
));
EnhancedSearchListWithRef.displayName = 'EnhancedSearchListWithRef';

export default EnhancedSearchListWithRef;
