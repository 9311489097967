// @flow

import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    marginLeft: 'auto'
  }
});

type Props = {
  children: React.Node
};

const NavMenu = ({ children }: Props) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};
NavMenu.displayName = 'NavMenu';

export default NavMenu;
