// @flow

import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePageVisibility } from 'react-page-visibility';
import { DateTime } from 'luxon';
import { LOCALE } from '../const/locale';

const YEAR = 'year';
const QUARTER = 'quarter';
const MONTH = 'month';
const WEEK = 'week';
const DAY = 'day';
const HOUR = 'hour';
const MINUTE = 'minute';
const SECOND = 'second';

type Unit =
  | 'year'
  | 'quarter'
  | 'month'
  | 'week'
  | 'day'
  | 'hour'
  | 'minute'
  | 'second';

export const selectUnit = (
  dateString: string,
  options?: {
    padding?: number, // Don't use in combination with {round: false} because the decimal output will include the padding.
    round?: boolean // Rounds down by default.
  }
): ?Unit => {
  // If not a valid date string, return null.
  if (typeof dateString !== 'string') {
    return null;
  }

  const date = DateTime.fromISO(dateString);

  // If not a valid date time, return null.
  if (!date.isValid) {
    return null;
  }

  const dateRelative = date.toRelative(options);

  if (dateRelative.includes(YEAR)) {
    return YEAR;
  }
  if (dateRelative.includes(QUARTER)) {
    return QUARTER;
  }
  if (dateRelative.includes(MONTH)) {
    return MONTH;
  }
  if (dateRelative.includes(WEEK)) {
    return WEEK;
  }
  if (dateRelative.includes(DAY)) {
    return DAY;
  }
  if (dateRelative.includes(HOUR)) {
    return HOUR;
  }
  if (dateRelative.includes(MINUTE)) {
    return MINUTE;
  }
  if (dateRelative.includes(SECOND)) {
    return SECOND;
  }
  return null;
};

// Back off polling frequency over time
export const defaultGetPollInterval = (pollCount: number): number => {
  // Every minute for 30 minutes
  if (pollCount < 30) {
    return 60000;
  }

  // Every 10 minutes after 30 minutes
  return 600000;
};

type Props = {
  getPollInterval?: (pollCount: number) => number,
  locale?: string,
  padding?: number,
  polling?: boolean,
  round?: boolean,
  style?: 'long' | 'short' | 'narrow',
  unit?:
    | 'years'
    | 'quarters'
    | 'months'
    | 'weeks'
    | 'days'
    | 'hours'
    | 'minutes'
    | 'seconds',
  value: string
};

export const FormattedRelative = memo<Props>((props: Props) => {
  const {
    getPollInterval = defaultGetPollInterval,
    locale = LOCALE,
    padding = 0, // Don't use in combination with {round: false} because the decimal output will include the padding.
    polling = false,
    round = true, // Rounds down by default.
    style = 'long',
    unit, // Use a specific unit; if omitted, the method will pick the unit.
    value: dateString
  } = props;
  const isVisible = usePageVisibility();
  const [pollCount, setPollCount] = useState(0);
  const pollInterval = getPollInterval(pollCount);

  // Update relative time based on poll interval.
  useEffect(() => {
    const interval = setInterval(() => {
      if (isVisible && polling) {
        setPollCount(pollCount + 1);
      }
    }, pollInterval);

    return () => clearInterval(interval);
  }, [isVisible, pollCount, polling, pollInterval]);

  // If not a valid date string, return null.
  if (typeof dateString !== 'string') {
    return null;
  }

  const date = DateTime.fromISO(dateString);

  // If not a valid date time, return unformatted absolute date string.
  if (!date.isValid) {
    return <span title={dateString}>{dateString}</span>;
  }

  const now = new Date().toISOString();
  const dateMillis = date.diff(DateTime.fromISO(now)).valueOf();

  // For relative time less than 1 second, return now.
  if (
    typeof dateMillis === 'number' &&
    dateMillis > -1000 &&
    dateMillis < 1000
  ) {
    return (
      <span title={dateString}>
        <FormattedMessage defaultMessage="now" id="formattedRelative.now" />
      </span>
    );
  }

  const dateRelative = date.toRelative({
    locale,
    padding,
    round,
    style,
    unit
  });

  return <span title={dateString}>{dateRelative}</span>;
});
FormattedRelative.displayName = 'FormattedRelative';

export default FormattedRelative;
