// @flow

import classNames from 'classnames';
import React from 'react';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';

const Style = (theme: ThemeType) => {
  return {
    root: {
      flex: '0 auto',
      marginBottom: '1rem',
      minWidth: 0,
      [theme.breakpoints.gte.tablet.portrait]: {
        marginRight: '1rem',
        maxWidth: '18rem'
      },
      [theme.breakpoints.gte.mobile.landscape]: {
        marginRight: '3rem'
      },
      [theme.breakpoints.gte.tablet.landscape]: {
        marginRight: '4rem'
      }
    }
  };
};

type Props = InjectSheetProvidedProps & HTMLElement;

const InfoBarItem = ({ classes, className, theme, ...props }: Props) => (
  <div className={classNames(classes.root, className)} {...props} />
);

InfoBarItem.displayName = 'InfoBarItem';

export default injectSheet(Style)(InfoBarItem);
