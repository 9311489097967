// @flow

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import equals from 'ramda/src/equals';
import objOf from 'ramda/src/objOf';
import omit from 'ramda/src/omit';
import path from 'ramda/src/path';
import camelCase from 'voca/camel_case';
import { compose, mapProps, pure, withProps, type HOC } from 'recompose';

export const VariationQuery = gql`
  query Variation($id: String!, $defaultValue: Boolean) {
    variation(id: $id, defaultValue: $defaultValue) {
      id
      value
    }
  }
`;

type QueryDataProp = { [dataID: string]: { variation?: { value: boolean } } };

type CreateVariationProp = (
  variationID: string,
  dataID: string,
  defaultValue?: boolean
) => (
  props: QueryDataProp
) => {|
  [propName: string]: boolean | void
|};

export const toVariationProp: CreateVariationProp = (
  variationID,
  dataID,
  defaultValue
) =>
  compose(
    objOf(camelCase(variationID)),
    value =>
      // `value` is `undefined` while query is loading.
      value === undefined
        ? defaultValue === undefined
          ? undefined
          : equals(true, defaultValue)
        : equals(true, value),
    path([dataID, 'variation', 'value'])
  );

export const withVariation = (variationID: string, defaultValue?: boolean) => {
  // `dataID` is needed so we don't omit the resulting `{ [propName: string]: boolean }`
  // prop if its camel-cased prop name happens to be identical to its variation id,
  // like in the case of variation `flowchart`.
  const dataID = `data-${variationID}`;

  const variationHOC: HOC<*, Object> = compose(
    // Poll for variation value
    graphql(VariationQuery, {
      name: dataID,
      options: {
        fetchPolicy: 'network-only',
        pollInterval: 1000 * 60 * 60, // 1 hour
        variables: { id: variationID, defaultValue }
      }
    }),
    // Create camel cased key/value pair as variation prop.
    withProps(toVariationProp(variationID, dataID, defaultValue)),
    // Omit nested data object from props.
    mapProps(omit([dataID])),
    // Important: Don't update component unless variation value changed otherwise
    // unneeded re-rendering may occur whereever feature flags are used.
    pure
  );

  return variationHOC;
};

export default withVariation;
