// @flow

import * as React from 'react';
import { Link, type LocationShape } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Clamp from '../../Clamp/Clamp';

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '.5rem'
  },
  breadcrumb: {
    ...theme.mixins.link,
    ...theme.typography.header5,
    display: 'inline-flex',
    alignItems: 'center',
    maxWidth: '100%'
  }
}));

type LinkProps = {|
  'data-testid'?: string,
  children: React.Node,
  onClick?: (...args: Array<any>) => any,
  to?: string | LocationShape
|};

const BreadcrumbLink = ({ to, ...props }: LinkProps) =>
  to === undefined ? <button {...props} /> : <Link {...props} to={to} />;
BreadcrumbLink.displayName = 'BreadcrumbLink';

type Props = {|
  'data-testid'?: string,
  children: string,
  onClick?: (...args: Array<any>) => any,
  to?: string | LocationShape
|};

export const Breadcrumb = ({ children, ...props }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BreadcrumbLink {...props}>
        <span className={classes.breadcrumb}>
          <Clamp clamp={1}>{children}</Clamp>
        </span>
      </BreadcrumbLink>
    </div>
  );
};

export default Breadcrumb;
