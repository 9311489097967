// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SessionConsumer } from '../Session/SessionContext';

type Props = { className?: string };

const NavbarLogout = ({ className }: Props) => (
  <SessionConsumer>
    {({ logout }) => (
      <button className={className} onClick={logout}>
        <FormattedMessage id="navbarLogout" defaultMessage="Sign out" />
      </button>
    )}
  </SessionConsumer>
);
NavbarLogout.displayName = 'NavbarLogout';

export default NavbarLogout;
