// @flow

import React, { PureComponent, type ElementRef } from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import keycode from 'keycode';
import { TwitterPicker } from 'react-color';
import colors, { COLORS } from '../../style/colors';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';
import InputRequired from '../InputRequired/InputRequired';
import { withModalViewContext } from '../../View/ModalView/ModalView';

const Style = (theme: ThemeType) => {
  return {
    root: {
      position: 'relative'
    },
    input: {
      ...theme.mixins.inputStyle,
      ...theme.mixins.truncate,
      paddingLeft: '2.5rem',
      textTransform: 'uppercase',
      '&:required:invalid': {
        paddingRight: theme.variables.inputRequiredPaddingRight
      },
      '&:focus:required:invalid, &:required:valid': {
        ...theme.mixins.inputPadding,
        '& ~ $required': {
          display: 'none'
        }
      },
      '&[readonly]': {
        '&, &:focus, &:hover': theme.mixins.inputReadOnly
      }
    },
    displayErrors: {
      '&:valid': theme.mixins.inputSuccess,
      '&:invalid': theme.mixins.inputError
    },
    success: theme.mixins.inputSuccess,
    error: theme.mixins.inputError,
    required: {},
    readOnly: {
      '&[readonly]': {
        '&, &:focus, &:hover': theme.mixins.inputReadOnly
      }
    },
    disabled: {
      '&:disabled': {
        '&, &:focus, &:hover': theme.mixins.inputDisabled
      }
    },
    color: {
      ...theme.mixins.verticalAlign,
      position: 'absolute',
      left: '.813rem',
      width: '1rem',
      height: '1rem',
      pointerEvents: 'none'
    },
    popover: {
      position: 'absolute',
      zIndex: ({ modalViewContext }) =>
        `${modalViewContext ? theme.zIndex.modal : theme.zIndex.menu}`
    },
    cover: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  };
};

export type ColorInputType = InjectSheetProvidedProps &
  HTMLInputElement & {
    color: string,
    colors: Array<string>,
    displayErrors: boolean,
    hasError: boolean,
    hasRequired: boolean,
    hasSuccess: boolean,
    hasValidation?: boolean,
    inputClassName?: string,
    inputRef?: ElementRef<*>,
    modalViewContext: boolean,
    onBlur?: ({
      currentTarget: { id: string, name: string, value: string }
    }) => mixed,
    onChange?: ({
      currentTarget: { id: string, name: string, value: string }
    }) => mixed,
    open: boolean,
    setDisplayErrorsState?: (displayErrors: boolean) => mixed,
    setLoadingState?: (loading: boolean) => mixed
  };

type State = {
  color: string,
  open: boolean
};

class ColorInput extends PureComponent<ColorInputType, State> {
  static displayName = 'ColorInput';

  static defaultProps = {
    autoComplete: 'off',
    color: colors.black,
    colors: Object.values(COLORS),
    displayErrors: false,
    hasError: false,
    hasRequired: true,
    hasSuccess: false,
    open: false,
    type: 'text'
  };

  state = {
    color: this.props.color,
    open: this.props.open
  };

  handleChangeComplete = ({ hex }) => {
    const { id, name, onChange } = this.props;

    this.setState({ color: hex });

    if (onChange) {
      return onChange({
        currentTarget: { id, name, value: hex }
      });
    }
  };

  handleFocus = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();

    return this.setState({ open: true });
  };

  handleClose = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { id, name, onBlur } = this.props;
    const { color } = this.state;

    this.setState({ open: false });

    if (onBlur) {
      return onBlur({
        currentTarget: { id, name, value: color }
      });
    }
  };

  render() {
    const {
      classes,
      className,
      color: colorProp,
      colors,
      defaultValue,
      disabled,
      displayErrors,
      hasError,
      hasRequired,
      hasSuccess,
      hasValidation,
      inputClassName,
      inputRef: ref,
      modalViewContext,
      name,
      onBlur,
      onChange,
      readOnly,
      required,
      setDisplayErrorsState,
      setLoadingState,
      theme,
      title,
      ...props
    } = this.props;
    const { color, open } = this.state;

    return (
      <div className={classNames(classes.root, className)} title={title}>
        <input
          className={classNames(
            classes.input,
            {
              [classes.displayErrors]: displayErrors,
              [classes.error]: hasError,
              [classes.success]: hasSuccess,
              [classes.disabled]: disabled,
              [classes.readOnly]: readOnly
            },
            inputClassName
          )}
          data-name={name}
          disabled={disabled || readOnly}
          onFocus={this.handleFocus}
          onKeyDown={event => {
            if (keycode(event) === 'esc') {
              return this.handleClose(event);
            }
          }}
          readOnly
          value={color}
          {...{ name, ref, required, ...props }}
        />
        <InputRequired
          className={classes.required}
          {...{ disabled, hasRequired, readOnly, required }}
        />
        <div className={classes.color} style={{ backgroundColor: color }} />
        {open && (
          <div className={classes.popover}>
            <div
              className={classes.cover}
              onClick={this.handleClose}
              onKeyDown={event => {
                if (keycode(event) === 'esc') {
                  return this.handleClose(event);
                }
              }}
              role="button"
              tabIndex={-1}
            />
            <TwitterPicker
              onChangeComplete={this.handleChangeComplete}
              {...{ color, colors }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default compose(withModalViewContext, injectSheet(Style))(ColorInput);
