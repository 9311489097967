// @flow

type File = {
  name: string,
  size?: number,
  type?: string
};

// This should match the corresponding middleware logic in pushbot-api
// https://github.com/catalyticlabs/pushbot-api/blob/01d5d55f2741a808388562ea7022b22eab2dc64a/lib/middleware.js#L18
const DEFAULT_LIMITS = {
  fileSize: 1073741824, // 1 GiB
  nonCsvFileSize: 1073741824 // 1 GiB
};

const CSV_MIME_TYPES = [
  'text/csv',
  'text/x-csv',
  'application/csv',
  'application/x-csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values'
];

const isCsvFile = (file: File): boolean => {
  if (CSV_MIME_TYPES.indexOf((file.type || '').toLowerCase()) >= 0) {
    return true;
  }
  if ((file.name || '').toLowerCase().endsWith('.csv')) {
    return true;
  }
  return false;
};

export const isAllowedFileUploadSize = (
  file: File,
  limits?: { fileSize?: number, nonCsvFileSize?: number } = {}
): boolean => {
  const { fileSize, nonCsvFileSize } = { ...DEFAULT_LIMITS, ...limits };
  // Allow a file if we don't have access to its size or type
  if (file.size === undefined || file.type === undefined) {
    return true;
  }
  if (file.size <= nonCsvFileSize) {
    return true;
  }
  if (isCsvFile(file) && file.size !== undefined && file.size <= fileSize) {
    return true;
  }
  return false;
};

export default isAllowedFileUploadSize;
