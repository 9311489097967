// @flow

import React, { type Node } from 'react';
import gql from 'graphql-tag';
import path from 'path';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import userIconUrl from '@catalytic/catalytic-icons/lib/icons/user.svg';
import {
  Avatar,
  Badge,
  CERULEAN,
  Clamp,
  injectSheet,
  ListItem,
  ListItemBody,
  ListItemHeader,
  type InjectSheetProvidedProps,
  type ThemeType
} from '@catalytic/catalytic-ui';
import { GROUPS as GROUPS_PATH } from '../const/path';

const BADGE_WIDTH = 52;
const BADGE_MARGIN_LEFT = 12;

export const Style = (theme: ThemeType) => ({
  content: {
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  },
  badge: {
    flex: 'none',
    minWidth: theme.functions.toRem(BADGE_WIDTH),
    height: '1.25rem',
    marginLeft: theme.functions.toRem(BADGE_MARGIN_LEFT),
    verticalAlign: 'middle'
  },
  avatar: {
    flex: 'none',
    margin: '.75rem',
    width: '2rem',
    minWidth: '2rem',
    minHeight: '2rem',
    height: '2rem'
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    minWidth: 0
  },
  displayName: {
    marginRight: `-${theme.functions.toRem(BADGE_WIDTH + BADGE_MARGIN_LEFT)}`,
    paddingRight: theme.functions.toRem(BADGE_WIDTH + BADGE_MARGIN_LEFT),
    verticalAlign: 'middle'
  }
});

type Props = InjectSheetProvidedProps & {
  displayName: string,
  id: string,
  isTeamAdmin: boolean,
  isTeamOwner: boolean,
  members: Array<string>,
  thumbnailUrl: ?string
};

export const GroupListItemFragment = gql`
  fragment GroupListItemFragment on Group {
    id
    displayName
    members
    isTeamAdmin
    isTeamOwner
    thumbnailUrl
  }
`;

export const GroupListItem = ({
  classes,
  displayName,
  id,
  isTeamAdmin,
  isTeamOwner,
  members,
  thumbnailUrl
}: Props): Node => {
  const memberCount = (members || []).length;

  return (
    <Link to={path.join(GROUPS_PATH, id)}>
      <ListItem className={classes.listItem}>
        <div className={classes.content}>
          <Avatar
            image={thumbnailUrl || userIconUrl}
            circular={true}
            className={classes.avatar}
          />
          <div className={classes.text}>
            <ListItemHeader>
              <Clamp clamp={2} className={classes.displayName}>
                {displayName}
              </Clamp>
              {(isTeamAdmin || isTeamOwner) && (
                <Badge
                  compact={true}
                  colorScheme={CERULEAN}
                  className={classes.badge}
                >
                  <FormattedMessage id="team.badge" defaultMessage="Admin" />
                </Badge>
              )}
            </ListItemHeader>
            <ListItemBody>
              <FormattedMessage
                id="group.members"
                defaultMessage="{memberCount} members"
                values={{ memberCount }}
              />
            </ListItemBody>
          </div>
        </div>
      </ListItem>
    </Link>
  );
};

GroupListItem.displayName = 'GroupListItem';

export default injectSheet(Style)(GroupListItem);
