// @flow

import variables from './variables';

// See https://github.com/daneden/animate.css/issues/644
export const animationStart = (el => {
  const animation = {
    animation: 'animationstart',
    WebkitAnimation: 'webkitAnimationStart'
  };

  for (const t in animation) {
    if (el.style[t] !== undefined) {
      return animation[t];
    }
  }
})(window.document.createElement('div'));

export const animationEnd = (el => {
  const animation = {
    animation: 'animationend',
    WebkitAnimation: 'webkitAnimationEnd'
  };

  for (const t in animation) {
    if (el.style[t] !== undefined) {
      return animation[t];
    }
  }
})(window.document.createElement('div'));

const animationStyles = {
  '@global': {
    [`@keyframes ${variables.fadeIn}`]: {
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      }
    },
    [`@keyframes ${variables.fadeOut}`]: {
      from: {
        opacity: 1
      },
      to: {
        opacity: 0
      }
    }
  }
};

export default animationStyles;
