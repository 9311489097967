// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as LogoSquareIcon } from '@catalytic/catalytic-icons/lib/icons/logo-square.svg';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '1.5rem',
    height: '1.5rem'
  },
  icon: {
    fontSize: '1.625rem'
  }
});

type Props = {
  to: string
};

const NavLogo = ({ to }: Props) => {
  const classes = useStyles();

  return (
    <Link className={classes.root} to={to}>
      <LogoSquareIcon className={classes.icon} />
    </Link>
  );
};
NavLogo.displayName = 'NavLogo';
NavLogo.defaultProps = {
  to: '/'
};

export default NavLogo;
