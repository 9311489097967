// @flow

export const AUTOMATION_PLAYGROUND =
  'https://www.catalytic.com/automation-playground';
export const CONTACT_US = 'https://www.pagerduty.com/contact-us';
export const HELP =
  process.env.CATALYTIC_HELP_URL || 'https://help.catalytic.com/';
export const TERMS_PRIVACY =
  'https://help.catalytic.com/docs/privacy-security-and-terms-of-use/';
export const WWW = 'https://www.pagerduty.com/catalytic';
