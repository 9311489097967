// @flow

import * as React from 'react';

type PFunc = () => Promise<any>;
type Waiting = [string, PFunc];
export type Add = (key: string, pFunc: PFunc) => void;

export function createAdd() {
  const queue = new Map();
  let promisePending = false;

  const add: Add = function(key, pFunc) {
    if (queue.has(key)) {
      queue.delete(key);
    }

    queue.set(key, pFunc);

    if (!promisePending) dequeue();

    function dequeue() {
      // $FlowIgnore
      const [waiting]: Array<Waiting> = queue.entries();

      if (waiting) {
        const [key, pFunc] = waiting;

        queue.delete(key);
        promisePending = true;
        pFunc().then(() => {
          promisePending = false;
          dequeue();
        });
      }
    }
  };

  return add;
}

// React hook - Can be used instead of render prop component
// eslint-disable-next-line react-hooks/exhaustive-deps
export const usePromiseQueue = () => React.useCallback<Add>(createAdd(), []);

type Props = { children: ({ add: Add }) => React.Node };

// Render prop component - Can be used instead of custom hook
export default function PromiseQueue({ children }: Props) {
  const add = usePromiseQueue();
  return children({ add });
}
PromiseQueue.displayName = 'PromiseQueue';
