// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import ButtonInput from '../../Input/ButtonInput/ButtonInput';

const Style = (theme: ThemeType) => {
  return {
    root: {
      ...theme.typography.header6,
      color: theme.colors.black,
      fontWeight: 500,
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      right: 0,
      minWidth: 0,
      marginTop: '-.15rem',
      [theme.breakpoints.desktop]: {
        marginTop: '.3rem',
        paddingTop: '0.475rem',
        paddingBottom: '0.475rem',
        minWidth: '7.1rem'
      }
    }
  };
};

type Props = InjectSheetProvidedProps & HTMLElement;

export class ListItemButton extends PureComponent<Props> {
  render() {
    const { classes, className, theme, ...props } = this.props;
    return (
      <ButtonInput
        compact
        className={classNames(classes.root, className)}
        {...props}
      />
    );
  }
}
ListItemButton.displayName = 'ListItemButton';
export default injectSheet(Style)(ListItemButton);
