// @flow

import * as React from 'react';
import { Route } from 'react-router-dom';
import { lifecycle } from 'recompose';

// TOOD: Type props to component.
type Props = Object;

const ExternalRedirect: React.ComponentType<Props> = lifecycle({
  UNSAFE_componentWillMount() {
    const { location: { pathname, search } = {}, path } = this.props;
    const p = pathname || path;
    const windowLocation =
      typeof p === 'string'
        ? typeof search === 'string'
          ? p + search
          : p
        : null;

    if (windowLocation) window.location.assign(windowLocation);
  }
})(Route);

ExternalRedirect.displayName = 'ExternalRedirect';

export default ExternalRedirect;
