// @flow

import * as React from 'react';
import TextInput, { type TextInputType } from '../TextInput/TextInput';

export type EmailInputType = TextInputType;

function EmailInput({ name, ...props }: EmailInputType) {
  return (
    <TextInput data-testid={name || 'email-input'} name={name} {...props} />
  );
}
EmailInput.displayName = 'EmailInput';
EmailInput.defaultProps = {
  inputMode: 'email',
  type: 'email'
};

export default React.memo<EmailInputType>(EmailInput);
