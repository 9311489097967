// @flow

import gql from 'graphql-tag';
import { ActorListItemFragment } from '../Team/TeamListItem';
import { DataTableListItemFragment } from '../DataTableV2/DataTableTypeDefs';
import { GroupListItemFragment } from '../Group/GroupListItem';
import { ProcessListItemFragment } from '../Process/ProcessTypeDefs';
import { ProcessVersionListItemFragment } from '../Process/ProcessVersionListItem';
import { RunListItemFragment } from '../Run/RunTypeDefs';
import { AppListItemFragment } from '../App/AppQueries';
import { TaskListItemFragment } from '../Tasks/TaskListItem';
import { WebhookListItemFragment } from '../Trigger/TriggerTypeDefs';

// Disable GraphQL linting for apollo-link-state until this issue is
// resolved:
// https://github.com/apollographql/eslint-plugin-graphql/pull/117
/* eslint-disable graphql/template-strings */
export const AddClientSearch = gql`
  mutation AddClientSearch($query: String!, $type: String) {
    addClientSearch(input: { query: $query, type: $type }) @client {
      createdDate
      id
      type
    }
  }
`;

export const ClientSearch = gql`
  query ClientSearch($id: String!) {
    clientSearch(id: $id) @client {
      createdDate
      id
    }
  }
`;

export const ClientSearches = gql`
  query ClientSearches {
    clientSearches @client {
      nodes {
        createdDate
        id
        type
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
/* eslint-enable graphql/template-strings */

export const TypeSearchFragment = gql`
  fragment TypeSearchFragment on SearchConnectionV2 {
    edges {
      node {
        id
        description
        displayName
        __typename
        ... on Task {
          status
        }
        ... on ActorInterface {
          email
          nickname
        }
      }
      match {
        property
        tokens {
          type
          value
          __typename
        }
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      __typename
    }
    totalCount
    __typename
  }
`;

export const GlobalSearchQuery = gql`
  query GlobalSearch($query: String, $first: Int = 5, $after: String) {
    CONTEXT: search_v2(
      type: [CONTEXT]
      first: $first
      query: $query
      after: $after
      sort: [{ key: START_DATE, direction: DESC }]
    ) {
      ...TypeSearchFragment
    }
    CONTEXT_TYPE: search_v2(
      type: [CONTEXT_TYPE]
      first: $first
      query: $query
      after: $after
      sort: [{ key: CREATED_DATE, direction: DESC }]
    ) {
      ...TypeSearchFragment
    }
    TASK: search_v2(
      type: [TASK]
      first: $first
      query: $query
      after: $after
      sort: [{ key: START_DATE, direction: DESC }]
    ) {
      ...TypeSearchFragment
    }
    DATA_TABLE: search_v2(
      type: [DATA_TABLE]
      first: $first
      query: $query
      after: $after
      sort: [{ key: CREATED_DATE, direction: DESC }]
    ) {
      ...TypeSearchFragment
    }
    ACTOR: search_v2(
      type: [ACTOR]
      first: $first
      query: $query
      after: $after
      sort: [{ key: CREATED_DATE, direction: DESC }]
    ) {
      ...TypeSearchFragment
    }
    HELP: search_v2(
      type: [HELP]
      first: $first
      query: $query
      after: $after
      sort: [{ key: CREATED_DATE, direction: DESC }]
    ) {
      ...TypeSearchFragment
    }
  }

  ${TypeSearchFragment}
`;

export const TypeSearchQuery = gql`
  query TypeSearch(
    $query: String
    $first: Int
    $after: String
    $type: [SearchTypeV2]
  ) {
    search_v2(
      first: $first
      query: $query
      after: $after
      sort: [
        { key: START_DATE, direction: DESC }
        { key: CREATED_DATE, direction: DESC }
      ]
      type: $type
    ) {
      ...TypeSearchFragment
    }
  }

  ${TypeSearchFragment}
`;

export const LocalSearchQuery = gql`
  query LocalSearch(
    $after: String
    $filter: [SearchFilterV2]
    $first: Int = 20
    $options: [SearchOptionV2]
    $query: String
    $sort: [SearchSortOptionV2]
    $type: [SearchTypeV2]
  ) {
    search_v2(
      after: $after
      filter: $filter
      first: $first
      options: $options
      query: $query
      sort: $sort
      type: $type
    ) {
      nodes {
        id
        ...ActorListItemFragment
        ...DataTableListItemFragment
        ...GroupListItemFragment
        ...ProcessListItemFragment
        ...ProcessVersionListItemFragment
        ...RunListItemFragment
        ...TaskListItemFragment
        ...AppListItemFragment
        ...WebhookListItemFragment
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }

  ${DataTableListItemFragment}
  ${ProcessListItemFragment}
  ${ProcessVersionListItemFragment}
  ${RunListItemFragment}
  ${AppListItemFragment}
  ${TaskListItemFragment}
  ${ActorListItemFragment}
  ${GroupListItemFragment}
  ${WebhookListItemFragment}
`;
