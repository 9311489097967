// @flow

import React from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';

const Style = (theme: ThemeType) => {
  return {
    root: {
      display: 'inline-block',
      position: 'relative',
      width: '2.5rem',
      height: '2.5rem'
    },
    avatar: {
      overflow: 'hidden',
      display: 'inline-block',
      width: '100%',
      height: '100%'
    },
    circular: {
      borderRadius: '50%'
    },
    image: {
      maxWidth: '100%'
    },
    badgeCount: {
      fontSize: '.6rem',
      borderRadius: '50%',
      background: theme.colors.white,
      color: theme.colors.pebbleGrey,
      border: `1px solid ${theme.colors.lightGrey}`,
      position: 'absolute',
      top: 0,
      right: '-.25rem',
      width: '1.45rem',
      height: '1.45rem',
      textAlign: 'center',
      display: 'flex',
      alignSelf: 'flex-end',
      lineHeight: '1.5rem',
      fontWeight: 400,
      alignItems: 'center',
      letterSpacing: '-0.006rem',
      justifyContent: 'center'
    },
    badgeCountLarge: {
      backgroundColor: theme.colors.dullRed,
      color: theme.colors.white
    }
  };
};

type Props = InjectSheetProvidedProps &
  HTMLSpanElement & {
    image: string,
    circular?: boolean,
    title: string,
    badgeCount: number,
    avatarClassName: string
  };

const Avatar = ({
  status,
  classes,
  circular,
  image,
  badgeCount,
  className,
  avatarClassName,
  title,
  theme,
  ...props
}: Props) => (
  <span className={classNames(classes.root, className)} {...props}>
    <span
      className={classNames(
        classes.avatar,
        {
          [classes.circular]: circular
        },
        avatarClassName
      )}
    >
      <img src={image} alt={title} title={title} className={classes.image} />
    </span>
    {badgeCount && (
      <span
        className={classNames(classes.badgeCount, {
          [classes.badgeCountLarge]: badgeCount > 99
        })}
      >
        {badgeCount > 99 ? '99+' : badgeCount}
      </span>
    )}
  </span>
);

Avatar.displayName = 'Avatar';
Avatar.defaultProps = {
  circular: true
};

export default injectSheet(Style)(Avatar);
