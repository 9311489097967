// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';
import { type InjectSheetProvidedProps } from '../style/ThemeTypes';
import injectSheet from '../style/injectSheet';

const Style = {
  root: {
    marginTop: '1rem',
    marginLeft: '2rem',
    marginBottom: '1rem'
  },
  ul: {
    listStyle: 'disc',
    '& ul': {
      listStyleType: 'circle',
      marginTop: '.5rem',
      '& ul': {
        listStyleType: 'square'
      }
    }
  }
};

type Props = InjectSheetProvidedProps & {
  /**
   * Child nodes
   */
  children?: Node,
  /**
   * If true, the list is ordered
   */
  ordered: boolean
};

function MarkdownList(props: Props): Node {
  const { children, classes, className: classNameProp, ordered } = props;
  const className = classNames(
    classes.root,
    { [classes.ul]: !ordered },
    classNameProp
  );
  const Component = ordered ? 'ol' : 'ul';

  return <Component className={className}>{children}</Component>;
}

MarkdownList.displayName = 'MarkdownList';

export default injectSheet(Style)(MarkdownList);
