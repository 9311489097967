// @flow

import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose, setDisplayName, withProps } from 'recompose';
import classNames from 'classnames';
import RPath from 'ramda/src/path';
import Select, { components } from 'react-select';
import { type ControlProps } from 'react-select/src/components/Control';
import { type MenuProps } from 'react-select/src/components/Menu';
import { type MultiValueGenericProps } from 'react-select/src/components/MultiValue';
import { type OptionProps } from 'react-select/src/components/Option';
import { type SingleValueProps } from 'react-select/src/components/SingleValue';
import injectSheet from '../../style/injectSheet';
import { SmallText } from '../../Text/Text';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';
import InputRequired from '../InputRequired/InputRequired';
import { withModalViewContext } from '../../View/ModalView/ModalView';

const DEFAULT_VALUE = [];
const NOOP = () => {};
export const SELECT_DISPLAY_NAME = 'MultiSelectInput';

const MENU_MAX_HEIGHT = 300;

const Style = (theme: ThemeType) => {
  return {
    root: {
      position: 'relative'
    },
    input: {
      '&:required:valid': {
        '& ~ $required': {
          display: 'none'
        }
      },
      [`& .${SELECT_DISPLAY_NAME}__clear-indicator`]: {
        display: 'none', // Hides clear indicator button
        padding: '.5rem',
        color: theme.colors.battleshipGrey,
        cursor: 'pointer',
        '&:hover': {
          color: theme.colors.black
        }
      },
      [`& .${SELECT_DISPLAY_NAME}__control`]: {
        ...theme.mixins.inputStyle,
        ...theme.mixins.inputMultiPadding,
        minHeight: 0,
        [`&.${SELECT_DISPLAY_NAME}__control--is-focused`]: theme.mixins
          .inputFocus
      },
      [`& .${SELECT_DISPLAY_NAME}__dropdown-indicator`]: {
        padding: '.5rem',
        color: theme.colors.battleshipGrey,
        cursor: 'pointer',
        '&:hover': {
          color: theme.colors.black
        }
      },
      [`& .${SELECT_DISPLAY_NAME}__indicators`]: {
        marginRight: '-.5rem'
      },
      [`& .${SELECT_DISPLAY_NAME}__indicator-separator`]: {
        backgroundColor: theme.colors.battleshipGrey,
        marginBottom: '.5rem',
        marginTop: '.5rem',
        width: '1px',
        display: 'none'
      },
      [`& .${SELECT_DISPLAY_NAME}__menu`]: {
        ...theme.mixins.inputMenu,
        borderRadius: '.25rem',
        marginTop: '.5rem',
        marginBottom: '.5rem',
        zIndex: ({ modalViewContext }) =>
          `${modalViewContext ? theme.zIndex.modal : theme.zIndex.menu}`
      },
      [`& .${SELECT_DISPLAY_NAME}__menu-list`]: {
        /* react-select has a bug where the maxMenuHeight
         * prop is ignored if menuPlacement is set to auto and
         * the menu opens above the input
         * https://github.com/JedWatson/react-select/issues/3278 */
        maxHeight: theme.functions.toRem(MENU_MAX_HEIGHT),
        paddingTop: 0,
        paddingBottom: 0
      },
      [`& .${SELECT_DISPLAY_NAME}__menu-notice--loading`]: theme.mixins
        .inputEmpty,
      [`& .${SELECT_DISPLAY_NAME}__menu-notice--no-options`]: theme.mixins
        .inputEmpty,
      [`& .${SELECT_DISPLAY_NAME}__multi-value`]: {
        ...theme.mixins.inputMultiValue,
        [`&.${SELECT_DISPLAY_NAME}__multi-value--is-disabled`]: {
          [`& .${SELECT_DISPLAY_NAME}__multi-value__label`]: theme.mixins
            .inputMultiLabelDisabled,
          [`& .${SELECT_DISPLAY_NAME}__multi-value__remove`]: theme.mixins
            .inputMultiRemoveDisabled
        }
      },
      [`& .${SELECT_DISPLAY_NAME}__multi-value__chipContainer`]: {
        minWidth: 0,
        display: 'flex'
      },
      [`& .${SELECT_DISPLAY_NAME}__multi-value__chipIcon`]: {
        '& > *': {
          width: '1rem',
          height: '1rem',
          margin: '.25rem 0 0 .25rem',
          '& img': {
            verticalAlign: 'top'
          }
        }
      },
      [`& .${SELECT_DISPLAY_NAME}__multi-value__optionIcon`]: {
        '& > *': {
          width: '1.25rem',
          height: '1.25rem',
          position: 'relative',
          top: '.2rem',
          marginRight: '.5rem',
          '& img': {
            verticalAlign: 'top'
          }
        }
      },
      [`& .${SELECT_DISPLAY_NAME}__multi-value__label`]: theme.mixins
        .inputMultiLabel,
      [`& .${SELECT_DISPLAY_NAME}__multi-value__remove`]: theme.mixins
        .inputMultiRemove,
      [`& .${SELECT_DISPLAY_NAME}__option`]: {
        ...theme.typography.baseText,
        ...theme.mixins.truncate,
        padding: '.5rem .75rem',
        minHeight: '2.375rem',
        cursor: 'pointer',
        [`&.${SELECT_DISPLAY_NAME}__option--is-focused`]: {
          [`&, &.${SELECT_DISPLAY_NAME}__option--is-selected`]: {
            backgroundColor: theme.colors.blueGrey,
            color: theme.colors.black
          }
        },
        [`&.${SELECT_DISPLAY_NAME}__option--is-selected`]: {
          backgroundColor: theme.colors.white,
          color: theme.colors.black
        },
        '&:active': {
          backgroundColor: theme.colors.blueGrey,
          color: theme.colors.black
        }
      },
      [`& .${SELECT_DISPLAY_NAME}__placeholder`]: theme.mixins.inputPlaceholder,
      [`& .${SELECT_DISPLAY_NAME}__single-value`]: {
        ...theme.typography.baseText,
        color: theme.colors.black,
        [`&.${SELECT_DISPLAY_NAME}__single-value--is-disabled`]: theme.mixins
          .inputMultiLabelDisabled
      },
      [`& .${SELECT_DISPLAY_NAME}__value-container`]: {
        ...theme.mixins.inputContainer,
        overflow: 'hidden'
      },
      [`& .${SELECT_DISPLAY_NAME}__value-container--is-multi.${SELECT_DISPLAY_NAME}__value-container--has-value`]: {
        marginLeft: '-.563rem'
      }
    },
    success: {
      [`& .${SELECT_DISPLAY_NAME}__control`]: {
        [`&, &.${SELECT_DISPLAY_NAME}__control--is-focused`]: theme.mixins
          .inputSuccess
      }
    },
    error: {
      [`& .${SELECT_DISPLAY_NAME}__control`]: {
        [`&, &.${SELECT_DISPLAY_NAME}__control--is-focused`]: theme.mixins
          .inputError
      }
    },
    required: {
      ...theme.mixins.inputRequired,
      right: '1.563rem'
    },
    readOnly: {
      [`& .${SELECT_DISPLAY_NAME}__indicators`]: {
        display: 'none'
      },
      [`& .${SELECT_DISPLAY_NAME}__multi-value`]: {
        [`&.${SELECT_DISPLAY_NAME}__multi-value--is-disabled`]: {
          [`& .${SELECT_DISPLAY_NAME}__multi-value__label, & .${SELECT_DISPLAY_NAME}__multi-value__remove`]: {
            color: theme.colors.black
          },
          [`& .${SELECT_DISPLAY_NAME}__multi-value__remove`]: {
            display: 'none'
          }
        }
      },
      [`& .${SELECT_DISPLAY_NAME}__single-value`]: {
        [`&.${SELECT_DISPLAY_NAME}__single-value--is-disabled`]: {
          color: theme.colors.black
        }
      }
    },
    inputElement: {
      [`&.${SELECT_DISPLAY_NAME}__control`]: {
        cursor: 'pointer'
      }
    },
    inputShadow: {
      display: 'none'
    },
    embedded: {
      [`& .${SELECT_DISPLAY_NAME}__control`]: {
        backgroundColor: theme.colors.ghostWhite,
        border: `1px solid ${theme.colors.lightGrey}`,
        borderRadius: theme.variables.borderRadiusSmall,
        paddingTop: 0,
        paddingBottom: 0,
        '&:hover, &:focus, &:focus-within': {
          backgroundColor: theme.colors.ghostWhite,
          border: `1px solid ${theme.colors.lightGrey}`
        }
      },
      [`& .${SELECT_DISPLAY_NAME}__multi-value__label`]: {
        padding: '.063rem .5rem',
        minHeight: '1.375rem'
      },
      [`& .${SELECT_DISPLAY_NAME}__option`]: {
        ...theme.typography.smallText
      },
      [`& .${SELECT_DISPLAY_NAME}__single-value`]: {
        ...theme.typography.smallText
      },
      [`& .${SELECT_DISPLAY_NAME}__placeholder`]: {
        ...theme.typography.smallText
      },
      [`& .${SELECT_DISPLAY_NAME}__value-container`]: {
        minHeight: '1.875rem'
      },
      [`& .${SELECT_DISPLAY_NAME}__input`]: {
        '& input': {
          margin: 0
        }
      },
      [`& .${SELECT_DISPLAY_NAME}__dropdown-indicator`]: {
        paddingTop: 0,
        paddingBottom: 0
      },
      [`& .${SELECT_DISPLAY_NAME}__multi-value__chipIcon`]: {
        lineHeight: '1rem',
        '& > *': {
          marginTop: '.1875rem'
        }
      },
      '& input': {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    fixedMenuHeight: {
      [`& .${SELECT_DISPLAY_NAME}__menu-list`]: {
        minHeight: theme.functions.toRem(MENU_MAX_HEIGHT)
      }
    },
    menuNotice: {
      display: 'block',
      margin: '.5rem auto',
      paddingTop: '.25rem',
      paddingBottom: '.25rem'
    }
  };
};

export type OptionType = { [string]: any };

export type OptionsType = Array<OptionType>;

export type ValueType = OptionType | OptionsType | null | void;

export type InputActionMeta = { action: string };

export type MultiSelectInputProps = {
  ...HTMLSelectElement,
  'data-testid'?: string,
  autoFocus?: boolean,
  component?: React.ComponentType<*>,
  defaultValue?: ValueType,
  displayErrors?: boolean,
  embedded?: boolean,
  fixedMenuHeight?: boolean,
  hasError?: boolean,
  hasRequired?: boolean,
  hasSuccess?: boolean,
  hasValidation?: boolean,
  inputClassName?: string,
  isDisabled?: boolean,
  isMulti?: boolean,
  isSearchable?: boolean,
  noOptionsMessage?: () => React.Node,
  onBlur?: ({
    currentTarget: {
      id?: string,
      label: React.Node,
      name?: string,
      value: ValueType
    }
  }) => void,
  onChange?: (
    {
      currentTarget: {
        id?: string,
        label: React.Node,
        name?: string,
        value: ValueType
      }
    },
    ValueType,
    OptionsType
  ) => void,
  options?: OptionsType,
  readOnly?: boolean,
  required?: boolean,
  setDisplayErrorsState?: (displayErrors: boolean) => mixed,
  setLoadingState?: (loading: boolean) => mixed,
  value?: ValueType
};

export type MultiSelectInputType = InjectSheetProvidedProps & {
  ...MultiSelectInputProps,
  inputRef: React.ElementRef<*>,
  intl: Object,
  modalViewContext: boolean,
  // Default Props - same as above but no longer optional
  autoComplete: boolean,
  autoFocus: boolean,
  component: React.ComponentType<*>,
  disabled: boolean,
  displayErrors: boolean,
  hasError: boolean,
  hasRequired: boolean,
  hasSuccess: boolean,
  isClearable: boolean,
  isDisabled: boolean,
  isMulti: boolean,
  isSearchable: boolean,
  maxMenuHeight: number,
  menuPlacement: string,
  noOptionsMessage: () => React.Node,
  onPaste?: (event: SyntheticClipboardEvent<HTMLElement>) => mixed,
  options: OptionsType,
  readOnly: boolean,
  required: boolean
};

type State = {
  defaultValue: ValueType
};

export const getIcon = ({ data }: { data: OptionType }) => {
  const { icon } = data || {};
  return icon;
};

export const getTitle = ({
  children,
  data
}: {
  children: React.Node,
  data: OptionType
}) => {
  const { title } = data || {};

  return typeof title === 'string' || typeof title === 'number'
    ? title
    : typeof children === 'string' || typeof title === 'number'
    ? children
    : '';
};

export const ClearIndicator = () => null;
ClearIndicator.displayName = 'ClearIndicator';

const Control = (props: ControlProps) => {
  const {
    children,
    isDisabled,
    isFocused,
    innerProps,
    innerRef,
    menuIsOpen,
    ...other
  } = props;
  const inputClassName = RPath(['selectProps', 'inputClassName'], props);
  const isMulti = RPath(['selectProps', 'isMulti'], props);

  return (
    <components.Control
      className={classNames(inputClassName, {
        [`${SELECT_DISPLAY_NAME}__control--is-multi`]: isMulti
      })}
      innerProps={{ ...innerProps, 'data-testid': 'control', tabIndex: '0' }}
      innerRef={innerRef}
      isDisabled={isDisabled}
      isFocused={isFocused}
      menuIsOpen={menuIsOpen}
      {...other}
    >
      {children}
    </components.Control>
  );
};
Control.displayName = 'Control';

const IndicatorSeparator = () => null;
IndicatorSeparator.displayName = 'IndicatorSeparator';

const LoadingIndicator = () => null;
LoadingIndicator.displayName = 'LoadingIndicator';

export const Menu = ({
  children,
  getPortalPlacement,
  innerProps,
  innerRef,
  maxMenuHeight,
  menuPlacement,
  menuPosition,
  menuShouldScrollIntoView,
  minMenuHeight,
  ...props
}: MenuProps) => (
  <components.Menu
    getPortalPlacement={getPortalPlacement}
    innerProps={{ ...innerProps, 'data-testid': 'menu' }}
    innerRef={innerRef}
    maxMenuHeight={maxMenuHeight}
    menuPlacement={menuPlacement}
    menuPosition={menuPosition}
    menuShouldScrollIntoView={menuShouldScrollIntoView}
    minMenuHeight={minMenuHeight}
    {...props}
  >
    {children}
  </components.Menu>
);
Menu.displayName = 'Menu';

export const MultiValueLabel = (props: MultiValueGenericProps) => {
  const title = getTitle(props);
  const icon = getIcon(props);

  return (
    <div
      className={`${SELECT_DISPLAY_NAME}__multi-value__chipContainer`}
      title={title}
      data-testid="multi-value"
    >
      {icon && (
        <span className={`${SELECT_DISPLAY_NAME}__multi-value__chipIcon`}>
          {icon}
        </span>
      )}
      <components.MultiValueLabel {...props} />
    </div>
  );
};
MultiValueLabel.displayName = 'MultiValueLabel';
export const Option = (props: OptionProps) => {
  const title = getTitle(props);
  const icon = getIcon(props);

  return (
    <div
      title={title}
      data-testid="option"
      data-value={String(props.data.value)}
    >
      <components.Option {...props}>
        {icon && (
          <span className={`${SELECT_DISPLAY_NAME}__multi-value__optionIcon`}>
            {icon}
          </span>
        )}
        {props.data.label}
      </components.Option>
    </div>
  );
};
Option.displayName = 'Option';

export const SingleValue = (props: SingleValueProps) => {
  const title = getTitle(props);
  const icon = getIcon(props);

  return (
    <div
      className={`${SELECT_DISPLAY_NAME}__single-value-container`}
      data-testid="single-value"
      title={title}
    >
      <components.SingleValue {...props}>
        {icon && (
          <span className={`${SELECT_DISPLAY_NAME}__multi-value__optionIcon`}>
            {icon}
          </span>
        )}
        {props.data.label}
      </components.SingleValue>
    </div>
  );
};
SingleValue.displayName = 'SingleValue';

const valueAsArray = value => {
  return value ? (Array.isArray(value) ? value : [value]) : DEFAULT_VALUE;
};

export class MultiSelectInput extends React.PureComponent<
  MultiSelectInputType,
  State
> {
  static displayName = SELECT_DISPLAY_NAME;

  static defaultProps = {
    autoComplete: false,
    autoFocus: false,
    component: Select,
    disabled: false,
    displayErrors: false,
    embedded: false,
    fixedMenuHeight: false,
    hasError: false,
    hasRequired: true,
    hasSuccess: false,
    isClearable: true,
    isDisabled: false,
    isLoading: false,
    isMulti: false,
    isSearchable: true,
    maxMenuHeight: MENU_MAX_HEIGHT,
    menuPlacement: 'auto',
    options: DEFAULT_VALUE,
    readOnly: false,
    required: false
  };

  // This is only a static method for ease of testing
  static getDerivedInitialStateFromProps(props: MultiSelectInputType): State {
    const defaultValueProp = props.defaultValue;
    return {
      defaultValue:
        typeof defaultValueProp !== 'object'
          ? DEFAULT_VALUE
          : Array.isArray(defaultValueProp)
          ? // We're filtering out null values from options and defaultValue
            // so they don't render as empty options or values as a result of
            // https://github.com/catalyticlabs/catalytic-web/pull/2399
            defaultValueProp.filter(
              (option: OptionType) => option.value !== null
            )
          : defaultValueProp
    };
  }

  state = MultiSelectInput.getDerivedInitialStateFromProps(this.props);

  handleBlur = () => {
    const { id, inputRef: ref, name, onBlur } = this.props;

    if (
      ref &&
      typeof ref === 'object' &&
      ref.current &&
      typeof ref.current === 'object'
    ) {
      const defaultValue = valueAsArray(ref.current.state.value);
      const label = defaultValue.map(({ label }) => label);
      const value = defaultValue.map(({ value }) => value);

      if (typeof onBlur === 'function') {
        // Parameter types of an unknown function are unknown
        // (https://github.com/facebook/flow/issues/7702)
        // $FlowIgnore
        onBlur({ currentTarget: { id, label, name, value } });
      }
    }
  };

  handleChange = (valueProp: ValueType) => {
    const { id, name, onChange } = this.props;
    const defaultValue = valueAsArray(valueProp);
    const label = defaultValue.map(({ label }) => label);
    const value = defaultValue.map(({ value }) => value);
    this.setState({ defaultValue });
    if (typeof onChange === 'function') {
      // Parameter types of an unknown function are unknown
      // (https://github.com/facebook/flow/issues/7702)
      // $FlowIgnore
      onChange(
        { currentTarget: { id, label, name, value } },
        valueProp,
        defaultValue
      );
    }
  };

  render() {
    const {
      'data-testid': dataTestID,
      autoFocus,
      classes,
      className,
      component: Component,
      defaultValue: defaultValueProp,
      disabled,
      displayErrors,
      embedded,
      fixedMenuHeight,
      hasError,
      hasRequired,
      hasSuccess,
      hasValidation,
      id,
      inputClassName,
      inputRef: ref,
      intl,
      isDisabled: isDisabledProp,
      isMulti,
      isSearchable,
      modalViewContext,
      name,
      noOptionsMessage,
      onBlur,
      onChange,
      onPaste,
      options: optionsProp,
      readOnly,
      required,
      setDisplayErrorsState,
      setLoadingState,
      theme,
      title,
      ...props
    } = this.props;

    const { defaultValue } = this.state;
    const hasValue = Array.isArray(defaultValue)
      ? defaultValue.length > 0
      : defaultValue !== undefined &&
        defaultValue !== null &&
        defaultValue !== '';
    const isRequired = required && !hasValue;
    // We're filtering out null values from options and defaultValue
    // so they don't render as empty options or values as a result of
    // https://github.com/catalyticlabs/catalytic-web/pull/2399
    const options = Array.isArray(optionsProp)
      ? optionsProp.filter(({ value }) => value !== null)
      : optionsProp;

    return (
      <div
        className={classNames(
          classes.root,
          {
            [classes.embedded]: embedded
          },
          className
        )}
        data-testid={dataTestID || name || 'multi-select-input'}
        onPaste={onPaste}
        title={title}
      >
        <Component
          className={classNames(classes.input, {
            [classes.success]: hasSuccess || (displayErrors && !isRequired),
            [classes.error]: hasError || (displayErrors && isRequired),
            [classes.readOnly]: readOnly,
            [classes.fixedMenuHeight]: fixedMenuHeight
          })}
          classNamePrefix={SELECT_DISPLAY_NAME}
          closeMenuOnSelect={!isMulti}
          components={{
            ClearIndicator,
            Control,
            IndicatorSeparator,
            LoadingIndicator,
            Menu,
            MultiValueLabel,
            Option,
            SingleValue
          }}
          inputClassName={classNames(
            { [classes.inputElement]: !isSearchable },
            inputClassName
          )}
          inputId={id}
          isDisabled={isDisabledProp || disabled || readOnly}
          loadingMessage={() => (
            // noOptionsMessage and loadingMessage should support ReactNode
            // https://github.com/JedWatson/react-select/issues/3511
            // $FlowIgnore
            <SmallText className={classes.menuNotice}>
              <FormattedMessage
                id="multiSelectInput.loading"
                defaultMessage="Loading..."
              />
            </SmallText>
          )}
          noOptionsMessage={
            noOptionsMessage === undefined
              ? () => (
                  <SmallText className={classes.menuNotice}>
                    <FormattedMessage
                      id="multiSelectInput.noOptions"
                      defaultMessage="No options found."
                    />
                  </SmallText>
                )
              : noOptionsMessage
          }
          onBlur={() => {
            setTimeout(this.handleBlur, 0);
          }}
          onChange={this.handleChange}
          openMenuOnFocus={autoFocus}
          styles={{
            // Omit outline style to keep focus outline for keyboard navigation
            control: ({ outline, ...styles }) => styles
          }}
          tabSelectsValue={false}
          {...{
            defaultValue,
            isMulti,
            isSearchable,
            name,
            options,
            ref,
            required,
            ...props
          }}
        />
        {required && (
          <div className={classes.required}>
            <input
              className={classes.inputShadow}
              data-name={name}
              onChange={NOOP}
              required
              value={defaultValue}
            />
            {!hasValue && (
              <InputRequired {...{ disabled, hasRequired, readOnly }} />
            )}
          </div>
        )}
      </div>
    );
  }
}

const EnhancedMultiSelectInput: React.ComponentType<MultiSelectInputProps> = compose(
  setDisplayName(SELECT_DISPLAY_NAME),
  withModalViewContext,
  injectIntl,
  injectSheet(Style),
  withProps(({ inputRef }) => ({ inputRef: inputRef || React.createRef() }))
)(MultiSelectInput);

export default EnhancedMultiSelectInput;
