// @flow

export type CSSNumber = string | number;

export type CSS = {
  [string]: CSS | CSSNumber
};

export type FontRule = {
  fontFamily?: string,
  fontSize?: CSSNumber,
  fontWeight?: CSSNumber,
  letterSpacing?: CSSNumber,
  lineHeight?: CSSNumber,
  textRendering?: string
};

export type Breakpoint = 'mobile' | 'tablet' | 'desktop';
export type Orientation = 'landscape' | 'portrait';

export type Breakpoints = {
  [Breakpoint]: string,
  gte: {
    [Breakpoint]: {
      [Orientation]: string
    }
  },
  gt: {
    [Breakpoint]: {
      [Orientation]: string
    }
  },
  lte: {
    [Breakpoint]: {
      [Orientation]: string
    }
  },
  lt: {
    [Breakpoint]: {
      [Orientation]: string
    }
  }
};

export type Colors = {
  [string]: string
};

export type Functions = {
  [string]: Function
};

export type Mixins = {
  [string]: CSS
};

export const TYPOGRAPHY = {
  BASE: 'base',
  HEADER1: 'header1',
  HEADER2: 'header2',
  HEADER3: 'header3',
  HEADER4: 'header4',
  HEADER5: 'header5',
  HEADER6: 'header6',
  BASETEXT: 'baseText',
  SMALLTEXT: 'smallText',
  EXTRASMALLTEXT: 'extraSmallText',
  EXTRAEXTRASMALLTEXT: 'extraExtraSmallText',
  MONOSPACE: 'monospace'
};

export type typography = $Values<typeof TYPOGRAPHY>;

export type Typography = {
  [typography]: FontRule
};

export type Variables = {
  [string]: CSSNumber
};

export type zIndex = {
  [string]: number
};

export type ThemeType = {
  breakpoints: Breakpoints,
  colors: Colors,
  functions: Functions,
  mixins: Mixins,
  typography: Typography,
  variables: Variables,
  zIndex: zIndex
};

export type StylesheetClasses = {
  [string]: string
};

export type InjectSheetProvidedProps = {
  classes: StylesheetClasses,
  className?: string,
  theme?: ThemeType
};

export type Creator = (theme: ThemeType) => CSS;
