// @flow

import React, { type ElementRef, type Node, type Ref } from 'react';
import path from 'ramda/src/path';
import { FormattedMessage } from 'react-intl';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
  WindowScroller
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import { compose, withStateHandlers } from 'recompose';
import ResizeDetector from 'react-resize-detector/build/withPolyfill';
import classNames from 'classnames';
import {
  Header3,
  injectSheet,
  type InjectSheetProvidedProps,
  ListItemGroupHeader,
  ListItemGroupHeaderButton,
  ListItemGroupHeaderTitle,
  ModalView,
  type ModalViewProps,
  SmallText,
  type ThemeType
} from '@catalytic/catalytic-ui';
import { Mutation, Query } from 'react-apollo';
import SearchCache from './SearchCache';
import SearchCategoryList from './SearchCategoryList';
import SearchCategoryListItem from './SearchCategoryListItem';
import SearchInput from './SearchInput';
import SearchList from './SearchList';
import SearchListItem from './SearchListItem';
import { type Type } from './SearchTypes';
import SearchTypeTitle from './SearchTypeTitle';
import { AddClientSearch, ClientSearches } from './SearchQueries';
import SearchQuery from './SearchQuery';
import { toSearchItemProps } from './SearchTransform';
import Track from '../Analytics/Track';
import { WAIT as DEBOUNCE_WAIT } from '../const/debounce';

const edges = path(['search_v2', 'edges']);
const endCursor = path(['search_v2', 'pageInfo', 'endCursor']);
const pageInfo = path(['search_v2', 'pageInfo']);
const totalCount = path(['search_v2', 'totalCount']);
const clientSearchTotalCount = path(['clientSearches', 'totalCount']);
const clientSearchNodes = path(['clientSearches', 'nodes']);

const globalTotalCount = data =>
  Object.keys(data || {}).reduce((o, k) => o + data[k].totalCount, 0);

const globalCategories = data =>
  Object.keys(data || {}).filter(k => data[k].totalCount !== 0);

const globalCategoryEdge = (type, data) => path([type, 'edges'], data);

const focusInput = (ref: ElementRef<*>) => {
  if (
    ref &&
    typeof ref === 'object' &&
    ref.current &&
    typeof ref.current === 'object' &&
    typeof ref.current.focus === 'function'
  ) {
    ref.current.focus();
  }
};

const getValue = (ref: ElementRef<*>) => path(['current', 'value'], ref);

const hasValue = (ref: ElementRef<*>) => {
  const value = getValue(ref);
  return typeof value === 'string' && value.length !== 0;
};

const scrollElement = (ref: ElementRef<*>) => {
  if (
    ref &&
    typeof ref === 'object' &&
    ref.current &&
    typeof ref.current === 'object' &&
    typeof ref.current.getBoundingClientRect === 'function'
  ) {
    return ref.current;
  } else {
    return window;
  }
};

const updatePosition = (ref: ElementRef<*>) => {
  if (
    ref &&
    typeof ref === 'object' &&
    ref.current &&
    typeof ref.current === 'object' &&
    typeof ref.current.updatePosition === 'function'
  ) {
    ref.current.updatePosition();
  }
};

const scrollListToTop = (ref: ElementRef<*>) => {
  if (
    ref &&
    typeof ref === 'object' &&
    ref.current &&
    typeof ref.current === 'object' &&
    typeof ref.current.scrollTo === 'function'
  ) {
    ref.current.scrollTo(0, 0);
  }
};

const cache = new CellMeasurerCache({
  fixedWidth: true
});

const BATCH_SIZE = 20;

const Style = (theme: ThemeType) => {
  return {
    body: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      paddingBottom: '2em',
      [theme.breakpoints.desktop]: {
        paddingBottom: 0
      }
    },
    scroll: {
      overflowX: 'hidden',
      overflowY: 'auto',
      '-webkitOverflowScrolling': 'touch',
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0,0,0,0)',
        borderRadius: '0.25rem'
      },
      '&::-webkit-scrollbar': {
        background: 'rgba(0,0,0,0)',
        width: '0.5rem'
      },
      '&:hover::-webkit-scrollbar-thumb': {
        background: theme.colors.pastelGreyAlpha
      }
    },
    searchList: {
      marginTop: '0.5rem'
    },
    list: {
      outline: 'none'
    },
    empty: {
      marginBottom: '1rem',
      marginTop: '2rem'
    }
  };
};

type Props = InjectSheetProvidedProps &
  ModalViewProps & {
    children?: Node,
    hasValue?: boolean,
    inputRef: Ref<*>,
    listRef: Ref<*>,
    loading?: boolean,
    onChange: (event: SyntheticEvent<>) => void,
    onClear: (event: SyntheticEvent<>) => void,
    placeholder?: string,
    query: string,
    scrollerRef: Ref<*>,
    setQuery: ({ query?: string, type: Type }) => void,
    type?: Type
  };

const SearchModal = ({
  classes,
  className,
  hasValue,
  inputRef,
  listRef,
  onChange,
  onClear,
  placeholder = 'Search PagerDuty Workflow Automation',
  query,
  scrollerRef,
  setQuery,
  trigger,
  type
}: Props): Node => (
  <ModalView
    className={className}
    onAfterOpen={() => focusInput(inputRef)}
    trigger={trigger}
  >
    {({ hide }) => (
      <Mutation mutation={AddClientSearch}>
        {addClientSearch => (
          <SearchQuery type={type} query={query} fetchPolicy="network-only">
            {({ data, fetchMore, loading }) => (
              <div className={classes.body}>
                <Track action="search" category={type} label={query} />
                <SearchInput
                  loading={loading}
                  inputRef={inputRef}
                  onChange={onChange}
                  onClear={onClear}
                  placeholder={placeholder}
                  type={type}
                  value={query}
                />
                <SearchCache
                  mutation={addClientSearch}
                  query={query}
                  type={type}
                />
                {!hasValue ? (
                  // Nothing in the search box, so show the user that last
                  // items they searched for
                  <Query
                    fetchPolicy="network-only"
                    query={ClientSearches}
                    variables={{ first: BATCH_SIZE }}
                  >
                    {({ data }) =>
                      clientSearchTotalCount(data) ? (
                        <div className={classes.scroll}>
                          <ListItemGroupHeader>
                            <ListItemGroupHeaderTitle>
                              <FormattedMessage
                                id="searchModal.previousSearches"
                                defaultMessage="Previous Searches"
                              />
                            </ListItemGroupHeaderTitle>
                          </ListItemGroupHeader>
                          {clientSearchNodes(data).map(({ id, type }) => (
                            <SearchCategoryListItem
                              key={`previous-${id}`}
                              type={type}
                              onClick={() => {
                                setQuery({ query: id, type });
                              }}
                            >
                              {id}
                            </SearchCategoryListItem>
                          ))}
                          <SearchCategoryList
                            onCategory={type => setQuery({ query: '', type })}
                          />
                        </div>
                      ) : (
                        <SearchCategoryList
                          onCategory={type => setQuery({ query: '', type })}
                        />
                      )
                    }
                  </Query>
                ) : type && totalCount(data) ? (
                  <SearchList
                    className={classNames(classes.scroll, classes.searchList)}
                    ref={listRef}
                  >
                    <ResizeDetector
                      handleHeight={false}
                      onResize={() => cache.clearAll()}
                      refreshMode="debounce"
                      refreshRate={DEBOUNCE_WAIT}
                    />
                    <ListItemGroupHeader>
                      <SearchTypeTitle type={type} />
                    </ListItemGroupHeader>
                    <InfiniteLoader
                      isRowLoaded={({ index }) => !!(edges(data) || [])[index]}
                      minimumBatchSize={BATCH_SIZE}
                      threshold={BATCH_SIZE}
                      loadMoreRows={() =>
                        fetchMore({
                          variables: {
                            after: endCursor(data)
                          },
                          updateQuery: (
                            previousResult,
                            { fetchMoreResult }
                          ) => {
                            const next = edges(fetchMoreResult);
                            return next.length
                              ? {
                                  search_v2: {
                                    ...previousResult.search_v2,
                                    edges: [
                                      ...(edges(previousResult) || []),
                                      ...next
                                    ],
                                    pageInfo: pageInfo(fetchMoreResult)
                                  }
                                }
                              : previousResult;
                          }
                        })
                      }
                      rowCount={totalCount(data)}
                    >
                      {({ onRowsRendered, registerChild }) => (
                        <WindowScroller
                          ref={scrollerRef}
                          scrollElement={scrollElement(listRef)}
                        >
                          {({ height, onChildScroll, scrollTop }) => (
                            <AutoSizer disableHeight>
                              {({ width }) => (
                                <List
                                  autoHeight
                                  className={classes.list}
                                  height={height}
                                  width={width}
                                  onRowsRendered={onRowsRendered}
                                  onScroll={onChildScroll}
                                  scrollTop={scrollTop}
                                  ref={registerChild}
                                  rowRenderer={({
                                    index,
                                    key,
                                    parent,
                                    style
                                  }) => (
                                    <CellMeasurer
                                      key={key}
                                      cache={cache}
                                      parent={parent}
                                      columnIndex={0}
                                      rowIndex={index}
                                    >
                                      <SearchListItem
                                        {...toSearchItemProps(
                                          edges(data)[index]
                                        )}
                                        key={key}
                                        onClick={hide}
                                        style={style}
                                      />
                                    </CellMeasurer>
                                  )}
                                  rowCount={edges(data).length}
                                  deferredMeasurementCache={cache}
                                  rowHeight={cache.rowHeight}
                                />
                              )}
                            </AutoSizer>
                          )}
                        </WindowScroller>
                      )}
                    </InfiniteLoader>
                  </SearchList>
                ) : globalTotalCount(data) ? (
                  <SearchList
                    className={classNames(classes.scroll, classes.searchList)}
                  >
                    {globalCategories(data).map((type, i) => (
                      <React.Fragment key={`category-${type}-${i}`}>
                        <ListItemGroupHeader>
                          <ListItemGroupHeaderTitle>
                            <SearchTypeTitle type={type} />
                          </ListItemGroupHeaderTitle>
                          <ListItemGroupHeaderButton
                            onClick={() => {
                              setQuery({ query, type });
                            }}
                          >
                            <FormattedMessage
                              id="searchModal.viewall"
                              defaultMessage="View All"
                            />
                          </ListItemGroupHeaderButton>
                        </ListItemGroupHeader>
                        {globalCategoryEdge(type, data).map((item, j) => (
                          <SearchListItem
                            {...toSearchItemProps(item)}
                            key={`category-${type}-${i}-${j}`}
                            onClick={hide}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </SearchList>
                ) : !loading ? (
                  <div className={classes.scroll}>
                    <div className={classes.empty}>
                      <Header3>
                        <FormattedMessage
                          id="searchModal.noresults"
                          defaultMessage="No matches found"
                        />
                      </Header3>
                      <SmallText>
                        <FormattedMessage
                          id="searchModal.securityReminder"
                          defaultMessage="Try adjusting your filters or broadening your search to see more results. Some results may be hidden based on permission settings"
                        />
                      </SmallText>
                    </div>
                    <SearchCategoryList
                      onCategory={type => setQuery({ query: '', type })}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </SearchQuery>
        )}
      </Mutation>
    )}
  </ModalView>
);

SearchModal.displayName = 'SearchModal';

export default compose(
  injectSheet(Style),
  withStateHandlers(
    ({
      inputRef = React.createRef(),
      listRef = React.createRef(),
      query = '',
      scrollerRef = React.createRef()
    }) => ({
      hasValue: false,
      inputRef,
      listRef,
      query,
      scrollerRef,
      type: undefined
    }),
    {
      onChange: ({ type, inputRef, listRef, scrollerRef }) => () => {
        // scroll to top on every query change
        scrollListToTop(listRef);
        // update scroller position
        updatePosition(scrollerRef);
        // clear render cache
        cache.clearAll();
        return {
          hasValue: hasValue(inputRef) || !!type,
          query: getValue(inputRef)
        };
      },
      onClear: () => () => {
        cache.clearAll();
        return {
          hasValue: false,
          query: '',
          type: undefined
        };
      },
      setQuery: () => ({ query, type } = {}) => {
        cache.clearAll();
        return {
          hasValue: (query || '') !== '' || !!type,
          query: query || '',
          type
        };
      }
    }
  )
)(SearchModal);
