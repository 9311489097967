// @flow

import * as React from 'react';
import { JSONType } from '@catalytic/json-schema-validator-catalytic-web';
import { type Process } from '../Process/ProcessTypes';
import { type Run } from '../Run/RunTypes';
import { type Step } from '../Step/StepTypes';
import { type Node } from '../shared/NodeTypes';

type Context = {|
  className?: string,
  context?: null | Node | Run | Process | Step,
  contextValues?: { [string]: any },
  description?: null | string | React.Element<typeof FormattedMessage>,
  disabled?: boolean,
  displayErrors?: boolean,
  displayName: string | React.Element<typeof FormattedMessage>,
  embedded?: boolean,
  example?: null | string | React.Element<typeof FormattedMessage>,
  fieldName?: string,
  hasValidation: boolean,
  id: string,
  label?: string,
  name: string,
  onBlur: (...args: Array<any>) => any,
  onChange: (...args: Array<any>) => any,
  readOnly?: boolean,
  required?: boolean,
  type?: $Keys<typeof JSONType>
|};

type Props = {|
  children?: React.Node,
  value: Context
|};

const FieldContext = React.createContext<Context | void>();

const useFieldContext = () => {
  const fieldContext = React.useContext(FieldContext);

  if (fieldContext === undefined) {
    console.warn('Warning: useFieldContext must be used within FieldContext.');
  }

  return fieldContext;
};

export const FieldProvider = ({ children, value }: Props) => (
  <FieldContext.Provider value={value}>{children}</FieldContext.Provider>
);

export const FieldConsumer = FieldContext.Consumer;

export default useFieldContext;
