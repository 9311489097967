// @flow

import React, { type Node, type ElementRef } from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';

const Style = (theme: ThemeType) => {
  return {
    root: {
      ...theme.typography.extraSmallText,
      color: theme.colors.black,
      position: 'relative',
      cursor: 'pointer',
      display: 'flex'
    },
    input: {
      margin: '.2rem .5rem'
    },
    placeholder: {
      color: theme.colors.slateGrey
    }
  };
};

export type CheckboxInputType = InjectSheetProvidedProps & {
  children: Node,
  disabled?: boolean,
  displayErrors?: boolean,
  hasError?: boolean,
  hasValidation?: boolean,
  inputClassName?: string,
  inputRef?: ElementRef<*>,
  name: string,
  placeholder?: string,
  readOnly?: boolean,
  setDisplayErrorsState?: (displayErrors: boolean) => mixed,
  setLoadingState?: (loading: boolean) => mixed
};

const CheckboxInput = ({
  children,
  classes,
  className,
  disabled,
  displayErrors,
  hasError,
  hasValidation,
  inputClassName,
  inputRef: ref,
  name,
  placeholder,
  readOnly,
  setDisplayErrorsState,
  setLoadingState,
  theme,
  ...props
}: CheckboxInputType): Node => {
  return (
    <label className={classNames(classes.root, className)}>
      <input
        className={classNames(classes.input, inputClassName)}
        data-name={name}
        data-testid="Checkbox-input"
        disabled={disabled || readOnly}
        {...{ name, readOnly, ref, ...props }}
      />
      <span>
        {children}
        {placeholder && (
          <div
            className={classes.placeholder}
            data-testid="Checkbox-placeholder"
          >
            {placeholder}
          </div>
        )}
      </span>
    </label>
  );
};

CheckboxInput.displayName = 'CheckboxInput';
CheckboxInput.defaultProps = {
  disabled: false,
  readOnly: false,
  type: 'checkbox'
};

export default injectSheet(Style)(CheckboxInput);
