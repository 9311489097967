// @flow

import * as React from 'react';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import {
  Creatable as CreatableSelect,
  FieldReferenceListItem,
  Input,
  MultiSelectInput,
  TextInput,
  type FieldSuggestion,
  type ThemeType
} from '@catalytic/catalytic-ui';
import { booleanMessages, stepStatusMessages } from './ConditionMessages';
import { displayNameToInternalFieldName } from '../Field/FieldConfigurationHelpers';
import { type FieldContext, type Field } from '../Field/FieldTypes';
import { buildFieldOptions } from '../Field/FieldHelpers';
import { STATUSES, type Status } from '../shared/Status/StatusTypes';
import { type Step } from './StepTypes';

type SelectTermOption = {
  display: string,
  label: string | React.Node,
  value: string
};

const FIELD_TERM_PATTERN = /^fields\["[a-zA-Z0-9-_.]+"\]$/;
const FIELD_TERM_DOT_PATTERN = /^fields\.[a-zA-Z0-9-_.]+$/;
const FIELD_TERM_CAPTURE_PATTERN = /^fields\["([a-zA-Z0-9-_.]+)"\]$/;
const FIELD_TERM_DOT_CAPTURE_PATTERN = /^fields\.([a-zA-Z0-9-_.]+)$/;
const TASK_TERM_PATTERN = /^tasks\["[a-z0-9-]+"\]\["status"\]$/;

export const SELECTION_TYPE_FIELD = 'SELECTION_TYPE_FIELD';
export const SELECTION_TYPE_FIELD_CHOICE = 'SELECTION_TYPE_FIELD_CHOICE';
export const SELECTION_TYPE_TEXT = 'SELECTION_TYPE_TEXT';
export const SELECTION_TYPE_TRUE_OR_FALSE = 'SELECTION_TYPE_TRUE_OR_FALSE';
export const SELECTION_TYPE_ACTION = 'SELECTION_TYPE_ACTION';
export const SELECTION_TYPE_ACTION_STATUS = 'SELECTION_TYPE_ACTION_STATUS';

export const Messages = defineMessages({
  selectionTypeField: {
    id: 'StepConditionTerm.SelectionType.Field',
    defaultMessage: 'Field'
  },
  selectionTypeFieldChoice: {
    id: 'StepConditionTerm.SelectionType.FieldChoice',
    defaultMessage: 'Field choice'
  },
  selectionTypeAction: {
    id: 'StepConditionTerm.SelectionType.Action',
    defaultMessage: 'Action'
  },
  selectionTypeActionStatus: {
    id: 'StepConditionTerm.SelectionType.ActionStatus',
    defaultMessage: 'Action status'
  },
  selectionTypeTrueOrFalse: {
    id: 'StepConditionTerm.SelectionType.TrueOrFalse',
    defaultMessage: 'True or false'
  },
  selectionTypeText: {
    id: 'StepConditionTerm.SelectionType.Text',
    defaultMessage: 'Text'
  }
});

export const getSelectionTypes = ({
  contextFieldEnumOptions,
  intl
}: {
  contextFieldEnumOptions: Array<SelectTermOption>,
  intl: Object
}): Array<{ label: string, value: string }> => {
  const selectionTypeField = {
    label: intl.formatMessage(Messages.selectionTypeField),
    value: SELECTION_TYPE_FIELD
  };
  const selectionTypeFieldChoice = {
    label: intl.formatMessage(Messages.selectionTypeFieldChoice),
    value: SELECTION_TYPE_FIELD_CHOICE
  };
  const selectionTypeText = {
    label: intl.formatMessage(Messages.selectionTypeText),
    value: SELECTION_TYPE_TEXT
  };
  const selectionTypeTrueOrFalse = {
    label: intl.formatMessage(Messages.selectionTypeTrueOrFalse),
    value: SELECTION_TYPE_TRUE_OR_FALSE
  };
  const selectionTypeAction = {
    label: intl.formatMessage(Messages.selectionTypeAction),
    value: SELECTION_TYPE_ACTION
  };
  const selectionTypeActionStatus = {
    label: intl.formatMessage(Messages.selectionTypeActionStatus),
    value: SELECTION_TYPE_ACTION_STATUS
  };
  let selectionTypes = [
    selectionTypeField,
    selectionTypeFieldChoice,
    selectionTypeText,
    selectionTypeTrueOrFalse,
    selectionTypeAction,
    selectionTypeActionStatus
  ];

  if ((contextFieldEnumOptions || []).length === 0) {
    selectionTypes = selectionTypes.filter(
      type => type !== selectionTypeFieldChoice
    );
  }
  return selectionTypes;
};

export const isFieldTerm = (term: string): boolean => {
  return (
    term?.length > 0 &&
    (FIELD_TERM_PATTERN.test(term) || FIELD_TERM_DOT_PATTERN.test(term))
  );
};

export const isFieldEnumOptionTerm = (
  term: string,
  fieldEnumOptions: Array<SelectTermOption>
): boolean => {
  return term?.length > 0 && !!fieldEnumOptions.find(o => o.value === term);
};

const isBooleanTerm = (term: string): boolean => {
  return term?.length > 0 && (term === 'true' || term === 'false');
};

const isTaskTerm = (term: string): boolean => {
  return term?.length > 0 && TASK_TERM_PATTERN.test(term);
};

const isTaskEnumTerm = (term: string): boolean => {
  return term?.length > 0 && STATUSES.map(s => `"${s}"`).includes(term);
};

const nameToFieldTerm = (name: string): string => {
  if (!name || isFieldTerm(name)) {
    return name;
  }
  return `fields["${displayNameToInternalFieldName(name)}"]`;
};

const fieldTermToName = (term: string): ?string => {
  if (!term || !isFieldTerm(term)) {
    return null;
  }
  return (term.match(FIELD_TERM_CAPTURE_PATTERN) ||
    term.match(FIELD_TERM_DOT_CAPTURE_PATTERN) ||
    [])[1];
};

export const stripQuotesForLiterals = (term: string): string => {
  if (term === '"true"' || term === '"false"') {
    return term;
  }
  if (term[0] === '"' && term[term.length - 1] === '"') {
    return term.substring(1, term.length - 1);
  }
  return term;
};

export const addQuotesForLiterals = (term: string): string => {
  if (
    term.indexOf('fields[') === 0 ||
    term.indexOf('fields.') === 0 ||
    term.indexOf('tasks[') === 0 ||
    term.indexOf('tasks.') === 0 ||
    term.indexOf('run[') === 0 ||
    term.indexOf('run.') === 0 ||
    term === 'true' ||
    term === 'false'
  ) {
    return term;
  }
  if (term[0] !== '"') {
    term = '"' + term;
  }
  if (term[term.length - 1] !== '"') {
    term = term + '"';
  }
  if (term === '"') {
    return '""';
  }
  return term;
};

export const pickSelectionType = ({
  fieldEnumOptions,
  term
}: {
  fieldEnumOptions: Array<SelectTermOption>,
  term: string
}): string => {
  if (isTaskTerm(term)) {
    return SELECTION_TYPE_ACTION;
  }
  if (isFieldTerm(term)) {
    return SELECTION_TYPE_FIELD;
  }
  if (isBooleanTerm(term)) {
    return SELECTION_TYPE_TRUE_OR_FALSE;
  }
  if (isTaskEnumTerm(term)) {
    return SELECTION_TYPE_ACTION_STATUS;
  }
  if (isFieldEnumOptionTerm(term, fieldEnumOptions)) {
    return SELECTION_TYPE_FIELD_CHOICE;
  }
  return SELECTION_TYPE_TEXT;
};

export const makeFieldTermOption = (
  field: FieldSuggestion
): SelectTermOption => {
  return {
    display: field.display,
    label: <FieldReferenceListItem {...field} />,
    value: `fields["${field.id}"]`
  };
};

export const makeStepTermOption = (step: Step): SelectTermOption => {
  return {
    display: step.displayName,
    label: step.displayName,
    value: `tasks["${step.name}"]["status"]`
  };
};

export const makeStepStatusOption = (
  status: Status,
  intl: Object
): SelectTermOption => {
  const display = intl.formatMessage(stepStatusMessages[status]);
  return {
    display,
    label: display,
    value: addQuotesForLiterals(status)
  };
};

export const makeBooleanTermOption = (
  term: string,
  intl: Object
): SelectTermOption => {
  const display = intl.formatMessage(booleanMessages[term]);
  return {
    display: display,
    label: display,
    value: term
  };
};

export const getOptionsByType = ({
  contextFieldEnumOptions,
  fieldsByContext,
  intl,
  isProcessNode,
  selectionType,
  steps,
  term
}: {
  contextFieldEnumOptions: Array<SelectTermOption>,
  fieldsByContext?: Array<FieldContext>,
  intl: Object,
  isProcessNode: boolean,
  selectionType: string,
  steps: Array<Step>,
  term: string
}): { [string]: Array<SelectTermOption> } => {
  const fieldOptions = fieldsByContext
    ? buildFieldOptions({
        fieldsByContext,
        intl
      }).map(field => makeFieldTermOption(field))
    : [];

  if (term && isFieldTerm(term) && !fieldOptions.find(o => o.value === term)) {
    const label = term.split('["')[1].split('"]')[0];
    fieldOptions.push({
      display: label,
      label,
      value: term
    });
  }

  if (
    selectionType === SELECTION_TYPE_FIELD_CHOICE &&
    !contextFieldEnumOptions.find(o => o.value === term)
  ) {
    const label = stripQuotesForLiterals(term);
    contextFieldEnumOptions.push({
      display: label,
      label,
      value: term
    });
  }

  const stepOptions = !isProcessNode
    ? steps.map(step => makeStepTermOption(step))
    : [];

  const stepStatusEnumOptions = !isProcessNode
    ? STATUSES.map(status => makeStepStatusOption(status, intl)).sort((a, b) =>
        a.display.localeCompare(b.display)
      )
    : [];

  const booleanOptions = ['true', 'false'].map(term =>
    makeBooleanTermOption(term, intl)
  );
  return {
    SELECTION_TYPE_FIELD: fieldOptions,
    SELECTION_TYPE_FIELD_CHOICE: contextFieldEnumOptions,
    SELECTION_TYPE_TRUE_OR_FALSE: booleanOptions,
    SELECTION_TYPE_TEXT: [],
    SELECTION_TYPE_ACTION: stepOptions,
    SELECTION_TYPE_ACTION_STATUS: stepStatusEnumOptions
  };
};

export const lookUpContextTermEnumOptions = ({
  contextTerm,
  fields
}: {
  contextTerm: string,
  fields: Array<Field>
}): Array<SelectTermOption> => {
  if (!isFieldTerm(contextTerm)) {
    return [];
  }
  const fieldName = fieldTermToName(contextTerm);
  const field = fields.find(f => f.name === fieldName);
  if (field) {
    return (field.schema?.enum || field.schema?.items?.enum || []).map(o => ({
      display: o,
      label: o,
      value: `"${o}"`
    }));
  }
  return [];
};

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  selectionType: {
    flexBasis: '12rem',
    flexGrow: 0
  },
  term: {
    flexBasis: '100%',
    flexGrow: 1,
    paddingTop: '0.5rem',
    [theme.breakpoints.gt.tablet.portrait]: {
      flexBasis: '12rem',
      paddingLeft: '1rem',
      paddingTop: 0
    }
  }
}));

export function StepConditionTerm(props: {
  availableFields: Array<Field>,
  className?: string,
  contextTerm: string,
  fieldsByContext?: Array<FieldContext>,
  disabled: boolean,
  isProcessNode: boolean,
  onChange: string => void,
  steps: Array<Step>,
  value: string
}) {
  const {
    availableFields,
    className,
    contextTerm,
    fieldsByContext,
    disabled,
    isProcessNode,
    onChange,
    steps,
    value
  } = props;

  const classes = useStyles();
  const intl = useIntl();

  const contextFieldEnumOptions = lookUpContextTermEnumOptions({
    contextTerm,
    fields: availableFields
  });

  const selectionTypes = getSelectionTypes({
    contextFieldEnumOptions,
    intl
  });

  const [selectionType, setSelectionType] = React.useState<string>(
    pickSelectionType({
      fieldEnumOptions: contextFieldEnumOptions,
      term: value
    })
  );

  const [term, setTerm] = React.useState<string>(value);

  const optionsByType = getOptionsByType({
    contextFieldEnumOptions,
    fieldsByContext,
    intl,
    isProcessNode,
    selectionType,
    steps,
    term
  });
  const termOptions = optionsByType[selectionType] || [];
  const termItem = termOptions.find(t => t.value === term) || termOptions[0];

  const handleChangeTerm = (newTerm: string) => {
    setTerm(newTerm);
    onChange(newTerm);
  };

  const handleChangeType = (newType: string) => {
    setSelectionType(newType);
    handleChangeTerm(optionsByType[newType][0]?.value || '""');
  };

  return (
    <Input
      className={classNames(classes.root, className)}
      data-testid="condition-term"
    >
      {/* Selection Type */}
      <MultiSelectInput
        className={classes.selectionType}
        data-testid="selection-type"
        disabled={disabled}
        embedded
        isClearable={false}
        onChange={e => {
          handleChangeType(e.currentTarget.value[0]);
        }}
        options={selectionTypes}
        defaultValue={selectionTypes.find(t => t.value === selectionType)}
      />

      {/* Field */}
      {selectionType === SELECTION_TYPE_FIELD && (
        <CreatableSelect
          className={classes.term}
          disabled={disabled}
          embedded
          isClearable={false}
          options={termOptions}
          value={termItem}
          getNewOptionData={term => {
            const value = nameToFieldTerm(term);
            return {
              label: term,
              value
            };
          }}
          getOptionLabel={o => o.display || o.label}
          onChange={e => {
            handleChangeTerm(e.currentTarget.value[0]);
          }}
        />
      )}

      {/* Field choice */}
      {selectionType === SELECTION_TYPE_FIELD_CHOICE && (
        <CreatableSelect
          className={classes.term}
          disabled={disabled}
          embedded
          isClearable={false}
          options={termOptions}
          value={termItem}
          onChange={e => {
            handleChangeTerm(addQuotesForLiterals(e.currentTarget.value[0]));
          }}
        />
      )}

      {/* Text */}
      {selectionType === SELECTION_TYPE_TEXT && (
        <TextInput
          className={classes.term}
          disabled={disabled}
          embedded
          value={stripQuotesForLiterals(term)}
          onChange={e => {
            setTerm(addQuotesForLiterals(e.currentTarget.value));
          }}
          onBlur={e => {
            handleChangeTerm(addQuotesForLiterals(e.currentTarget.value));
          }}
        />
      )}

      {/* True or false */}
      {selectionType === SELECTION_TYPE_TRUE_OR_FALSE && (
        <MultiSelectInput
          className={classes.term}
          disabled={disabled}
          embedded
          isClearable={false}
          options={termOptions}
          value={termItem}
          onChange={e => {
            handleChangeTerm(e.currentTarget.value[0]);
          }}
        />
      )}

      {/* Action */}
      {selectionType === SELECTION_TYPE_ACTION && (
        <MultiSelectInput
          className={classes.term}
          disabled={disabled}
          embedded
          isClearable={false}
          options={termOptions}
          value={termItem}
          onChange={e => {
            handleChangeTerm(e.currentTarget.value[0]);
          }}
        />
      )}

      {/* Action enum */}
      {selectionType === SELECTION_TYPE_ACTION_STATUS && (
        <MultiSelectInput
          className={classes.term}
          disabled={disabled}
          embedded
          isClearable={false}
          options={termOptions}
          value={termItem}
          onChange={e => {
            handleChangeTerm(addQuotesForLiterals(e.currentTarget.value[0]));
          }}
        />
      )}
    </Input>
  );
}
StepConditionTerm.displayName = 'StepConditionTerm';

export default StepConditionTerm;
