// @flow

import React, { type Node } from 'react';
import { Link } from 'react-router-dom';

import {
  Clamp,
  ListItem,
  ListItemContents,
  ListItemIcon,
  ListItemHeader,
  ListItemFooter
} from '@catalytic/catalytic-ui';
import SearchIcon from './SearchIcon';
import Mark from './SearchMark';
import { type SearchToken } from './SearchTransform';
import { type Typename, TYPENAME } from './SearchTypes';

const truncateTerm = (term, index) =>
  term.length > 10 && index === 0 ? `... ${term.slice(-7)}` : term;

export type SearchTokenListProps = {
  id: string,
  tokens: Array<SearchToken>,
  truncate?: boolean
};

export const SearchTokenList = ({
  id,
  tokens,
  truncate = false
}: SearchTokenListProps) => (
  <span>
    {tokens.map(({ type, value }, i) =>
      type === MARK ? (
        <Mark key={`${id}-token-${i}`}>{value}</Mark>
      ) : (
        <span key={`${id}-token-${i}`}>
          {truncate ? truncateTerm(value, i) : value}
        </span>
      )
    )}
  </span>
);

SearchTokenList.displayName = 'SearchTokenList';

export type SearchListItemProps = {
  description: string | Array<SearchToken>,
  displayName: string | Array<SearchToken>,
  id: string,
  status?: string,
  target?: string,
  type: Typename,
  url: string
};

const MARK = 'MARK';

const SearchListItem = ({
  description,
  displayName,
  id,
  status,
  target,
  type,
  url,
  ...props
}: SearchListItemProps): Node => (
  <ListItem
    {...props}
    {...(type === TYPENAME.HELP
      ? { component: 'a', href: url }
      : { component: Link, to: url })}
    rel="noopener noreferrer"
    target={target}
  >
    <ListItemIcon>
      <SearchIcon id={id} status={status} type={type} />
    </ListItemIcon>
    <ListItemContents>
      <ListItemHeader>
        <Clamp clamp={2}>
          {!Array.isArray(displayName) ? (
            displayName
          ) : (
            <SearchTokenList id={id} tokens={displayName} />
          )}
        </Clamp>
      </ListItemHeader>
      <ListItemFooter>
        <Clamp clamp={2}>
          {!Array.isArray(description) ? (
            description
          ) : (
            <SearchTokenList id={id} tokens={description} truncate={true} />
          )}
        </Clamp>
      </ListItemFooter>
    </ListItemContents>
  </ListItem>
);

SearchListItem.displayName = 'SearchListItem';

export default SearchListItem;
