// @flow

import Rpath from 'ramda/src/path';
import papaparse from 'papaparse';
import { JSONRefType } from '@catalytic/json-schema-validator-catalytic-web';
import { type Interval } from './IntervalTypes';
import { type App } from './StepTypes';
import {
  createTypeFormatIdentifierForField,
  displayNameToInternalFieldName,
  isArrayColumnType,
  isColumnType,
  isFileArrayType,
  usesColumnBinding
} from '../Field/FieldConfigurationHelpers';
import {
  TYPE,
  type Field as FieldType,
  type FieldFormat,
  type FieldType as FieldTypeType
} from '../Field/FieldTypes';
import {
  appActionFromAppID,
  appNameFromAppID,
  appVersionFromAppID
} from '../Integrations/integrationMappings';
import { type Integration } from '../Integrations/IntegrationTypes';
import isEmptyFile from '../utils/isEmptyFile';
import isUUID from '../utils/uuid';

const V1 = 'v1';
const V2 = 'v2';
const V3 = 'v3';

export const APP_INPUT_KEY_PREFIX = 'app-input-';

export const getAppInputKey = ({
  name,
  prefix = APP_INPUT_KEY_PREFIX
}: {
  name: string,
  prefix?: string
}): string => `${prefix}${name}`;

export const stepURI = (processID: string, stepName: string): string =>
  `step:process:${processID}:${stepName}`;

export const integrationOptionsForApp = (
  app: ?App,
  integrations: Array<Integration>
): Array<{ label: string, value: string }> => {
  const authenticators = app?.authenticators || [];
  const authAppNames = authenticators.map(({ id }) => appNameFromAppID(id));
  const filteredIntegrations = integrations.filter(integration => {
    const integrationAppID = integration?.app?.id;
    if (integrationAppID) {
      return (
        authenticators.find(
          authenticator => authenticator.id === integrationAppID
        ) !== undefined
      );
    }
    return authAppNames.includes(integration.appName);
  });
  return filteredIntegrations.map(integration => ({
    label: integration.displayName,
    value: integration.id
  }));
};

export const nodeReferenceToInterval = ({
  contextID,
  format,
  nodeReference
}: {
  contextID: string,
  format: FieldFormat,
  nodeReference: ?{
    node: ?FieldType,
    reference: ?{
      id: string
    }
  }
}): ?Interval => {
  if (!nodeReference) {
    return null;
  }
  if (nodeReference.node) {
    return nodeReference.node;
  }
  if (nodeReference.reference && nodeReference.reference.id) {
    return fieldNameToField({
      contextID,
      name: nodeReference.reference.id,
      format
    });
  }
  return null;
};

const MANUAL_APP_NAME = 'manual';
const ASSIGNMENT_ACTION = 'assignment';
const WEBFORM_ACTION = 'web-form';

export const appRequiresAuthentication = (app?: App): boolean =>
  app?.requiresAuthentication || false;

export const appSupportsAuthentication = (app?: App): boolean =>
  (app?.authenticators || []).length > 0;

export const appSupportsDeadline = (app?: App): boolean => {
  const appID = Rpath(['id'], app) || '';
  const appName = appNameFromAppID(appID);
  const appAction = appActionFromAppID(appID);
  const isHumanStep = appName === MANUAL_APP_NAME;
  const isWebformStep = appName === 'email' && appAction === WEBFORM_ACTION;
  const isEmailAssignmentStep =
    appName === 'email' && appAction === ASSIGNMENT_ACTION;
  return isHumanStep || isWebformStep || isEmailAssignmentStep;
};

const WEBFORM_VERSIONS_WITH_SEPARATE_DESCRIPTION_PROP = [V1, V2, V3];

export const appSupportsInstructions = (app?: App): boolean => {
  const appID = Rpath(['id'], app) || '';
  const appName = appNameFromAppID(appID);
  const appAction = appActionFromAppID(appID);
  const appVersion = appVersionFromAppID(appID);
  const isHumanStep = appName === MANUAL_APP_NAME;
  const isWebformStep = appAction === WEBFORM_ACTION;
  const webformHasSeparateDescriptionProp = WEBFORM_VERSIONS_WITH_SEPARATE_DESCRIPTION_PROP.includes(
    appVersion
  );
  return isHumanStep || (isWebformStep && webformHasSeparateDescriptionProp);
};

export const appSupportsUserAssignment = (app?: App): boolean => {
  const appID = Rpath(['id'], app) || '';
  const appName = appNameFromAppID(appID);
  return appName === MANUAL_APP_NAME;
};

export const appSupportsUserDefinedFields = (app?: App): boolean => {
  const configuredWithUserDefinedFields =
    Rpath(['configuredWithUserDefinedFields'], app) || false;
  return configuredWithUserDefinedFields;
};

export const MANUAL_APP_ID = `manual/assign/${V1}`;
export const HEADING_ANNOTATION_APP_ID = `pushbot/builder-heading/${V1}`;
export const NOTE_ANNOTATION_APP_ID = `pushbot/builder-note/${V1}`;
export const CONDITIONAL_BLOCK_APP_ID = `pushbot/conditional-block/${V2}`;
export const REOPEN_V1_APP_ID = `pushbot/reopen/${V1}`;
export const REOPEN_V2_APP_ID = `pushbot/reopen/${V2}`;
export const LOOPING_BLOCK_APP_ID = `pushbot/reopen/v3`;

const ANNOTATION_APP_IDS = [HEADING_ANNOTATION_APP_ID, NOTE_ANNOTATION_APP_ID];
export const appIDIsAnnotationApp = (appID?: string): boolean => {
  return ANNOTATION_APP_IDS.includes(appID);
};

const BOX_APP_NAME = 'box';
const CSV_APP_NAME = 'csv';
const DROPBOX_APP_NAME = 'dropbox';
const EMAIL_APP_NAME = 'email';
const EXCEL_APP_NAME = 'excel';
const PUSHBOT_APP_NAME = 'pushbot';
const SHAREPOINT_APP_NAME = 'sharepoint';
const SLACK_APP_NAME = 'slack';
const TABLE_APP_NAME = 'table';
const TABLES_APP_NAME = 'tables';

export const appSupportsFormPermissions = (app?: App): boolean => {
  const appID = Rpath(['id'], app) || '';
  const appName = appNameFromAppID(appID);
  const appAction = appActionFromAppID(appID);
  const appVersion = appVersionFromAppID(appID);
  const isWebformStep =
    appName === EMAIL_APP_NAME && appAction === WEBFORM_ACTION;
  const versionSupportsPermissions = [V1, V2, V3].indexOf(appVersion) === -1;
  return isWebformStep && versionSupportsPermissions;
};

export const appIDIsAssignment = (appID?: ?string): boolean => {
  const appName = appNameFromAppID(appID);
  const appAction = appActionFromAppID(appID);
  const isHumanApp = appID === null || appName === MANUAL_APP_NAME;
  const isWebformApp = appAction === WEBFORM_ACTION;
  const isAssignmentApp = appAction === ASSIGNMENT_ACTION;

  if (isHumanApp || isWebformApp || isAssignmentApp) {
    return true;
  }
  return false;
};

export const fieldNameToField = ({
  contextID,
  format,
  name
}: {
  contextID: string,
  format: FieldFormat,
  name: string
}): FieldType => {
  const internalName = displayNameToInternalFieldName(name);
  return {
    condition: null,
    context: null,
    description: null,
    displayName: internalName,
    displayOnly: false,
    enum: null,
    example: null,
    format,
    id: `field:process:${contextID}:${internalName}`,
    items: null,
    name: internalName,
    reference: null,
    required: false,
    type: TYPE.STRING,
    value: null,
    viewerRead: true,
    __typename: 'Field'
  };
};

const ADD_REMOVE_COLUMNS_ACTION_NAME = 'add-remove-columns';
const ADD_ROW_ACTION_NAME = 'add-row';
const AVERAGE_COLUMN_ACTION_NAME = 'average-column';
const COLUMN_MAX_ACTION_NAME = 'column-max';
const COLUMN_MIN_ACTION_NAME = 'column-min';
const CONVERT_TO_MARKDOWN_ACTION_NAME = 'convert-to-markdown';
const COPY_TABLE_ACTION_NAME = 'copy-table';
const CREATE_SPREADSHEET_FOR_UNIQUE_VALUES_ACTION_NAME =
  'create-spreadsheet-for-unique-values';
const CREATE_TABLES_FOR_UNIQUE_VALUES_ACTION_NAME =
  'create-tables-for-unique-values';
const DELAY_BATCH_ACTION_NAME = 'delay-batch';
const DELETE_ROW_ACTION_NAME = 'delete-row';
const FILTER_ACTION_NAME = 'filter';
const FIND_DIFFERENCE = 'find-difference';
const FUZZY_MATCH_ACTION_NAME = 'fuzzy-match';
const JOIN_ACTION_NAME = 'join';
const LOOKUP_ACTION_NAME = 'lookup';
const REMOVE_COLUMN_ACTION_NAME = 'remove-column';
const REMOVE_DUPLICATES_ACTION_NAME = 'remove-duplicates';
const RENAME_COLUMN_ACTION_NAME = 'rename-column';
const SORT_ROWS_ACTION_NAME = 'sort-rows';
const SUM_COLUMN_ACTION_NAME = 'sum-column';
const UPDATE_ACTION_NAME = 'update';
const UPDATE_ROW_ACTION_NAME = 'update-row';

const CHOICE_ACTION_NAME = 'choice';
const SEND_ACTION_NAME = 'send';
const UPLOAD_ACTION_NAME = 'upload';
const UPLOAD_FILE_ACTION_NAME = 'upload-file';

const COLUMN_1_FIELD_NAME = 'column1';
const COLUMN_2_FIELD_NAME = 'column2';
const COLUMN_FIELD_NAME = 'column';
const COLUMN_NAME_FIELD_NAME = 'columnName';
const COLUMN_NAMES_FIELD_NAME = 'columnNames';
const COLUMNS_FIELD_NAME = 'columns';
const COLUMNS_TO_RETURN_FIELD_NAME = 'columnsToReturn';
const COLUMNS_TO_SEARCH_FIELD_NAME = 'columnsToSearch';
const CURRENT_COLUMN_NAME_FIELD_NAME = 'currentColumnName';
const FILTER_COLUMNS_FIELD_NAME = 'filterColumns';
const FIRST_FILTER_COLUMN_FIELD_NAME = 'firstFilterColumn';
const INBOUND_KEY_COLUMN_FIELD_NAME = 'inboundKeyColumn';
const KEY_COLUMN_FIELD_NAME = 'keyColumn';
const LOOKUP_COLUMN_FIELD_NAME = 'lookupColumn';
const MASTER_KEY_COLUMN_FIELD_NAME = 'masterKeyColumn';
const MATCH_COLUMNS_FIELD_NAME = 'matchColumns';
const RETURN_COLUMN_FIELD_NAME = 'returnColumn';
const SECOND_FILTER_COLUMN_FIELD_NAME = 'secondFilterColumn';
const SORT_COLUMN_FIELD_NAME = 'sortColumn';
const VALUES_FIELD_NAME = 'values';

const CSV_FILE_FIELD_NAME = 'csvFile';
const FILE_FIELD_NAME = 'file';
const FILES_FIELD_NAME = 'files';
const HEADER_ROW_FIELD_NAME = 'headerRow';
const HEADER_ROW_NUMBER_FIELD_NAME = 'headerRowNumber';
const INBOUND_FILE_FIELD_NAME = 'inboundFile';
const INBOUND_HEADER_ROW_FIELD_NAME = 'inboundHeaderRow';
const INBOUND_SHEET_FIELD_NAME = 'inboundSheet';
const MASTER_FILE_FIELD_NAME = 'masterFile';
const MASTER_HEADER_ROW_FIELD_NAME = 'masterHeaderRow';
const MASTER_SHEET_FIELD_NAME = 'masterSheet';
const SHEET_FIELD_NAME = 'sheet';
const SPREADSHEET_FILE_ID_FIELD_NAME = 'spreadsheetFileID';
const TABLE1_ID_FIELD_NAME = 'table1ID';
const TABLE2_ID_FIELD_NAME = 'table2ID';
const TABLE_ID_FIELD_NAME = 'tableID';

export const STEP_INPUT_VALUES_KEYS = [
  CSV_FILE_FIELD_NAME,
  FILE_FIELD_NAME,
  HEADER_ROW_FIELD_NAME,
  HEADER_ROW_NUMBER_FIELD_NAME,
  INBOUND_FILE_FIELD_NAME,
  INBOUND_HEADER_ROW_FIELD_NAME,
  INBOUND_SHEET_FIELD_NAME,
  MASTER_FILE_FIELD_NAME,
  MASTER_HEADER_ROW_FIELD_NAME,
  MASTER_SHEET_FIELD_NAME,
  SHEET_FIELD_NAME,
  SPREADSHEET_FILE_ID_FIELD_NAME,
  TABLE1_ID_FIELD_NAME,
  TABLE2_ID_FIELD_NAME,
  TABLE_ID_FIELD_NAME
];

const HEADER_ROW_NUMBER_KEY = 'headerRowNumber';
const ID_KEY = 'id';
const SHEET_NAME_KEY = 'sheetName';
const SHEET_NUMBER_KEY = 'sheetNumber';

const HEADER_ROW_NUMBER_DEFAULT_VALUE = 1;
const ID_DEFAULT_VALUE = '';
const SHEET_NAME_DEFAULT_VALUE = '';
const SHEET_NUMBER_DEFAULT_VALUE = 0;

const FILE_COLUMNS_INPUTS_DEFAULT_VALUES = {
  [HEADER_ROW_NUMBER_KEY]: HEADER_ROW_NUMBER_DEFAULT_VALUE,
  [ID_KEY]: ID_DEFAULT_VALUE,
  [SHEET_NAME_KEY]: SHEET_NAME_DEFAULT_VALUE,
  [SHEET_NUMBER_KEY]: SHEET_NUMBER_DEFAULT_VALUE
};

export type FileColumnsFields = Array<{ [string]: string }>;
export type TableIDsFields = Array<string>;
export type StepInputValues = Array<{ name: string, value: ?string }>;
export type FileColumnsInputs = Array<{
  headerRowNumber: number,
  id: string,
  sheetName: string,
  sheetNumber: number
}>;
export type TableIDs = Array<string>;

export const getColumnInput = ({
  appID,
  input,
  inputs,
  name
}: {
  appID?: string,
  input?: FieldType,
  inputs?: Array<FieldType>,
  name: string
}): {
  fileColumnsFields?: FileColumnsFields,
  isColumnInput: boolean,
  isMulti: boolean,
  tableIDsFields?: TableIDsFields
} => {
  const appAction = appActionFromAppID(appID);
  const appName = appNameFromAppID(appID);
  const appVersion = appVersionFromAppID(appID);

  // Whitelist "CSV: Add or remove columns" action.
  if (
    CSV_APP_NAME === appName &&
    ADD_REMOVE_COLUMNS_ACTION_NAME === appAction &&
    COLUMNS_FIELD_NAME === name
  ) {
    return {
      fileColumnsFields: [{ [ID_KEY]: CSV_FILE_FIELD_NAME }],
      isColumnInput: true,
      isMulti: true
    };
  }

  // Whitelist "CSV: Create spreadsheet for each unique value" action.
  if (
    CSV_APP_NAME === appName &&
    CREATE_SPREADSHEET_FOR_UNIQUE_VALUES_ACTION_NAME === appAction &&
    COLUMN_NAME_FIELD_NAME === name
  ) {
    return {
      fileColumnsFields: [
        {
          [HEADER_ROW_NUMBER_KEY]: HEADER_ROW_NUMBER_FIELD_NAME,
          [ID_KEY]: SPREADSHEET_FILE_ID_FIELD_NAME
        }
      ],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "CSV: Update file with another file" action.
  if (
    CSV_APP_NAME === appName &&
    UPDATE_ACTION_NAME === appAction &&
    KEY_COLUMN_FIELD_NAME === name
  ) {
    return {
      fileColumnsFields: [
        { [ID_KEY]: MASTER_FILE_FIELD_NAME },
        { [ID_KEY]: INBOUND_FILE_FIELD_NAME }
      ],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Excel: Apply filters" action based on version.
  if (
    EXCEL_APP_NAME === appName &&
    FILTER_ACTION_NAME === appAction &&
    V1 !== appVersion &&
    V2 !== appVersion &&
    FIRST_FILTER_COLUMN_FIELD_NAME === name
  ) {
    return {
      fileColumnsFields: [
        {
          [HEADER_ROW_NUMBER_KEY]: HEADER_ROW_NUMBER_FIELD_NAME,
          [ID_KEY]: FILE_FIELD_NAME,
          [SHEET_NAME_KEY]: SHEET_FIELD_NAME,
          [SHEET_NUMBER_KEY]: SHEET_FIELD_NAME
        }
      ],
      isColumnInput: true,
      isMulti: false
    };
  }

  if (
    EXCEL_APP_NAME === appName &&
    FILTER_ACTION_NAME === appAction &&
    V1 !== appVersion &&
    V2 !== appVersion &&
    SECOND_FILTER_COLUMN_FIELD_NAME === name
  ) {
    return {
      fileColumnsFields: [
        {
          [HEADER_ROW_NUMBER_KEY]: HEADER_ROW_NUMBER_FIELD_NAME,
          [ID_KEY]: FILE_FIELD_NAME,
          [SHEET_NAME_KEY]: SHEET_FIELD_NAME,
          [SHEET_NUMBER_KEY]: SHEET_FIELD_NAME
        }
      ],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Excel: Look up data in columns" action based on version.
  if (
    EXCEL_APP_NAME === appName &&
    LOOKUP_ACTION_NAME === appAction &&
    V1 !== appVersion &&
    LOOKUP_COLUMN_FIELD_NAME === name
  ) {
    return {
      fileColumnsFields: [
        {
          [HEADER_ROW_NUMBER_KEY]: HEADER_ROW_NUMBER_FIELD_NAME,
          [ID_KEY]: FILE_FIELD_NAME,
          [SHEET_NAME_KEY]: SHEET_FIELD_NAME,
          [SHEET_NUMBER_KEY]: SHEET_FIELD_NAME
        }
      ],
      isColumnInput: true,
      isMulti: false
    };
  }

  if (
    EXCEL_APP_NAME === appName &&
    LOOKUP_ACTION_NAME === appAction &&
    V1 !== appVersion &&
    COLUMNS_TO_RETURN_FIELD_NAME === name
  ) {
    return {
      fileColumnsFields: [
        {
          [HEADER_ROW_NUMBER_KEY]: HEADER_ROW_NUMBER_FIELD_NAME,
          [ID_KEY]: FILE_FIELD_NAME,
          [SHEET_NAME_KEY]: SHEET_FIELD_NAME,
          [SHEET_NUMBER_KEY]: SHEET_FIELD_NAME
        }
      ],
      isColumnInput: true,
      isMulti: true
    };
  }

  // Whitelist "Excel: Remove duplicate rows" action based on version.
  if (
    EXCEL_APP_NAME === appName &&
    REMOVE_DUPLICATES_ACTION_NAME === appAction &&
    V1 !== appVersion &&
    V2 !== appVersion &&
    COLUMN_FIELD_NAME === name
  ) {
    return {
      fileColumnsFields: [
        {
          [HEADER_ROW_NUMBER_KEY]: HEADER_ROW_FIELD_NAME,
          [ID_KEY]: FILE_FIELD_NAME,
          [SHEET_NAME_KEY]: SHEET_FIELD_NAME,
          [SHEET_NUMBER_KEY]: SHEET_FIELD_NAME
        }
      ],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Excel: Join two sheets" action.
  if (
    EXCEL_APP_NAME === appName &&
    JOIN_ACTION_NAME === appAction &&
    MASTER_KEY_COLUMN_FIELD_NAME === name
  ) {
    return {
      fileColumnsFields: [
        {
          [HEADER_ROW_NUMBER_KEY]: MASTER_HEADER_ROW_FIELD_NAME,
          [ID_KEY]: MASTER_FILE_FIELD_NAME,
          [SHEET_NAME_KEY]: MASTER_SHEET_FIELD_NAME,
          [SHEET_NUMBER_KEY]: MASTER_SHEET_FIELD_NAME
        }
      ],
      isColumnInput: true,
      isMulti: false
    };
  }

  if (
    EXCEL_APP_NAME === appName &&
    JOIN_ACTION_NAME === appAction &&
    INBOUND_KEY_COLUMN_FIELD_NAME === name
  ) {
    return {
      fileColumnsFields: [
        {
          [HEADER_ROW_NUMBER_KEY]: INBOUND_HEADER_ROW_FIELD_NAME,
          [ID_KEY]: INBOUND_FILE_FIELD_NAME,
          [SHEET_NAME_KEY]: INBOUND_SHEET_FIELD_NAME,
          [SHEET_NUMBER_KEY]: INBOUND_SHEET_FIELD_NAME
        }
      ],
      isColumnInput: true,
      isMulti: false
    };
  }

  if (
    EXCEL_APP_NAME === appName &&
    JOIN_ACTION_NAME === appAction &&
    COLUMNS_TO_RETURN_FIELD_NAME === name
  ) {
    return {
      fileColumnsFields: [
        {
          [HEADER_ROW_NUMBER_KEY]: INBOUND_HEADER_ROW_FIELD_NAME,
          [ID_KEY]: INBOUND_FILE_FIELD_NAME,
          [SHEET_NAME_KEY]: INBOUND_SHEET_FIELD_NAME,
          [SHEET_NUMBER_KEY]: INBOUND_SHEET_FIELD_NAME
        }
      ],
      isColumnInput: true,
      isMulti: true
    };
  }

  // Whitelist "Tables: Add a row" action.
  if (
    TABLES_APP_NAME === appName &&
    ADD_ROW_ACTION_NAME === appAction &&
    COLUMNS_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: true
    };
  }

  // Whitelist "Tables: Average data in a column" action.
  if (
    TABLE_APP_NAME === appName &&
    AVERAGE_COLUMN_ACTION_NAME === appAction &&
    COLUMN_NAME_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Tables: Maximum data in a column" action.
  if (
    TABLE_APP_NAME === appName &&
    COLUMN_MAX_ACTION_NAME === appAction &&
    COLUMN_NAME_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Tables: Minimum data in a column" action.
  if (
    TABLE_APP_NAME === appName &&
    COLUMN_MIN_ACTION_NAME === appAction &&
    COLUMN_NAME_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Tables: Convert data table to markdown text" action based on version.
  // https://github.com/catalyticlabs/product/issues/6684
  if (
    TABLES_APP_NAME === appName &&
    CONVERT_TO_MARKDOWN_ACTION_NAME === appAction &&
    COLUMNS_FIELD_NAME === name &&
    V1 !== appVersion
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: true
    };
  }

  // Whitelist "Tables: Create a copy of a data table" action.
  // The `values` param name is too generic.
  // https://github.com/catalyticlabs/product/issues/6689
  if (
    TABLES_APP_NAME === appName &&
    COPY_TABLE_ACTION_NAME === appAction &&
    VALUES_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: true
    };
  }

  // Whitelist "Tables: Create tables for each unique value" action.
  if (
    TABLES_APP_NAME === appName &&
    CREATE_TABLES_FOR_UNIQUE_VALUES_ACTION_NAME === appAction &&
    COLUMN_NAME_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Tables: Remove a row" action.
  if (
    TABLES_APP_NAME === appName &&
    DELETE_ROW_ACTION_NAME === appAction &&
    COLUMN_NAMES_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: true
    };
  }

  // Whitelist "Tables: Apply filters" action.
  if (
    TABLES_APP_NAME === appName &&
    FILTER_ACTION_NAME === appAction &&
    COLUMN_1_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  if (
    TABLES_APP_NAME === appName &&
    FILTER_ACTION_NAME === appAction &&
    COLUMN_2_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  if (
    TABLES_APP_NAME === appName &&
    FILTER_ACTION_NAME === appAction &&
    FILTER_COLUMNS_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: true
    };
  }

  // Whitelist "Tables: Compare two tables" action.
  if (
    TABLES_APP_NAME === appName &&
    FIND_DIFFERENCE === appAction &&
    MATCH_COLUMNS_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE1_ID_FIELD_NAME, TABLE2_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: true
    };
  }

  // Whitelist "Tables: Find similar text" action.
  if (
    TABLES_APP_NAME === appName &&
    FUZZY_MATCH_ACTION_NAME === appAction &&
    COLUMNS_TO_RETURN_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: true
    };
  }

  if (
    TABLES_APP_NAME === appName &&
    FUZZY_MATCH_ACTION_NAME === appAction &&
    COLUMNS_TO_SEARCH_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: true
    };
  }

  // Whitelist "Tables: Look up data in a column" action.
  if (
    TABLES_APP_NAME === appName &&
    LOOKUP_ACTION_NAME === appAction &&
    LOOKUP_COLUMN_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  if (
    TABLES_APP_NAME === appName &&
    LOOKUP_ACTION_NAME === appAction &&
    RETURN_COLUMN_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: true
    };
  }

  // Whitelist "Tables: Remove a column" action.
  if (
    TABLES_APP_NAME === appName &&
    REMOVE_COLUMN_ACTION_NAME === appAction &&
    COLUMN_NAME_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Tables: Get unique rows" action.
  if (
    TABLES_APP_NAME === appName &&
    REMOVE_DUPLICATES_ACTION_NAME === appAction &&
    COLUMN_NAME_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Tables: Rename a column" action.
  if (
    TABLES_APP_NAME === appName &&
    RENAME_COLUMN_ACTION_NAME === appAction &&
    CURRENT_COLUMN_NAME_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Tables: Sort rows" action.
  if (
    TABLES_APP_NAME === appName &&
    SORT_ROWS_ACTION_NAME === appAction &&
    SORT_COLUMN_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Tables: Sum data in a column" action.
  if (
    TABLE_APP_NAME === appName &&
    SUM_COLUMN_ACTION_NAME === appAction &&
    COLUMN_NAME_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Tables: Update a row" action.
  if (
    TABLES_APP_NAME === appName &&
    UPDATE_ROW_ACTION_NAME === appAction &&
    COLUMN_NAMES_FIELD_NAME === name &&
    V1 !== appVersion
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: true
    };
  }

  if (
    TABLES_APP_NAME === appName &&
    UPDATE_ROW_ACTION_NAME === appAction &&
    COLUMNS_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: true
    };
  }

  if (
    TABLES_APP_NAME === appName &&
    UPDATE_ROW_ACTION_NAME === appAction &&
    COLUMN_NAME_FIELD_NAME === name &&
    V1 === appVersion
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  // Whitelist "Workflow: Wait until Workflows complete" action.
  if (
    PUSHBOT_APP_NAME === appName &&
    DELAY_BATCH_ACTION_NAME === appAction &&
    COLUMN_NAME_FIELD_NAME === name
  ) {
    return {
      tableIDsFields: [TABLE_ID_FIELD_NAME],
      isColumnInput: true,
      isMulti: false
    };
  }

  // If this parameter doesn't match one of the hard-coded ones above, rely on
  // the parameter's schema to determine if this is a column and if so, if it
  // supports selecting multiple columns.

  return {
    fileColumnsFields: getFileColumnsFieldsForInput(input, inputs),
    isColumnInput: !!input && (isColumnType(input) || isArrayColumnType(input)),
    isMulti: !!input && isArrayColumnType(input),
    tableIDsFields: getTableIDsForInput(input, inputs)
  };
};

export const getFileColumnsFieldsForInput = (
  input?: FieldType,
  inputs?: Array<FieldType>
) => {
  const bindingReferenceKey = (input?.schema?.$binding?.enum || '').split(
    '/'
  )[1];

  const reference = (inputs || []).find(f => f.name === bindingReferenceKey);
  if (!reference || reference.schema?.refType !== JSONRefType.FILE) {
    return undefined;
  }

  const headerRowNumberReference = (inputs || []).find(f => {
    const bindingProp = (f?.schema?.$binding?.enum || '').split('/')[2];
    return bindingProp === HEADER_ROW_NUMBER_KEY;
  });
  const sheetNumberReference = (inputs || []).find(f => {
    const bindingProp = (f?.schema?.$binding?.enum || '').split('/')[2];
    return bindingProp === SHEET_NUMBER_KEY;
  });
  return [
    {
      [ID_KEY]: reference.name,
      [HEADER_ROW_NUMBER_KEY]: headerRowNumberReference?.name || '',
      [SHEET_NUMBER_KEY]: sheetNumberReference?.name || ''
    }
  ];
};

export const getTableIDsForInput = (
  input?: FieldType,
  inputs?: Array<FieldType>
) => {
  const bindingReferenceKey = (input?.schema?.$binding?.enum || '').split(
    '/'
  )[1];

  const reference = (inputs || []).find(f => f.name === bindingReferenceKey);
  if (!reference || reference.schema?.refType !== JSONRefType.DATA_TABLE) {
    return undefined;
  }
  return [reference.name];
};

export const getFileColumnsInputs = ({
  fields,
  prefix,
  values
}: {
  fields?: FileColumnsFields,
  prefix?: string,
  values?: StepInputValues
}): FileColumnsInputs => {
  const fileColumnsInputs = (fields || [])
    .map(input =>
      Object.entries(input).reduce((accumulator, [key, name]) => {
        const newValue = (values || []).find(
          f =>
            f.name ===
            (typeof name === 'string' ? getAppInputKey({ name, prefix }) : name)
        )?.value;
        const value = newValue === undefined ? accumulator[key] : newValue;

        if (key === HEADER_ROW_NUMBER_KEY) {
          let numberValue =
            FILE_COLUMNS_INPUTS_DEFAULT_VALUES[HEADER_ROW_NUMBER_KEY];
          try {
            numberValue = parseInt(value);
          } catch (err) {
            // FIXME parseInt doesn't throw, it just returns NaN
          }
          numberValue =
            typeof numberValue === 'number' && numberValue > -1
              ? numberValue
              : FILE_COLUMNS_INPUTS_DEFAULT_VALUES[HEADER_ROW_NUMBER_KEY];

          return {
            ...accumulator,
            [key]: numberValue
          };
        }

        if (key === SHEET_NUMBER_KEY) {
          let numberValue =
            FILE_COLUMNS_INPUTS_DEFAULT_VALUES[SHEET_NUMBER_KEY];
          try {
            numberValue = parseInt(value);
          } catch (err) {
            // FIXME parseInt doesn't throw, it just returns NaN
          }
          numberValue =
            typeof numberValue === 'number' && numberValue > -1
              ? numberValue
              : FILE_COLUMNS_INPUTS_DEFAULT_VALUES[SHEET_NUMBER_KEY];

          return {
            ...accumulator,
            [key]: numberValue
          };
        }

        return {
          ...accumulator,
          [key]: value
        };
      }, FILE_COLUMNS_INPUTS_DEFAULT_VALUES)
    )
    .filter(({ id }) => isUUID(id));

  return fileColumnsInputs;
};

export const getTableIDs = ({
  fields,
  prefix,
  values
}: {
  fields?: TableIDsFields,
  prefix?: string,
  values?: StepInputValues
}): TableIDs => {
  const tableIDs = (fields || [])
    .map(name => {
      const prefixedName =
        typeof name === 'string' ? getAppInputKey({ name, prefix }) : name;
      const newValue = (values || []).find(f => f.name === prefixedName)?.value;
      const value = newValue == null ? name : newValue;

      return value;
    })
    .filter(id => typeof id === 'string' && isUUID(id));

  return tableIDs;
};

export const isMultipleFileType = ({
  appID,
  input
}: {
  appID?: string,
  input: FieldType
}): boolean => {
  const typeFormatIdentifier = createTypeFormatIdentifierForField(input);
  if (isFileArrayType(typeFormatIdentifier)) {
    return true;
  }

  const appAction = appActionFromAppID(appID);
  const appName = appNameFromAppID(appID);

  const name = input.name;

  // Box: Upload file, "File to upload" param
  if (
    BOX_APP_NAME === appName &&
    UPLOAD_ACTION_NAME === appAction &&
    FILES_FIELD_NAME === name
  ) {
    return true;
  }

  // Dropbox: Upload file, "File to upload" param
  if (
    DROPBOX_APP_NAME === appName &&
    UPLOAD_ACTION_NAME === appAction &&
    FILES_FIELD_NAME === name
  ) {
    return true;
  }

  // Email: Wait for reply / Email: Assign an email task, "Attachments" param
  if (
    EMAIL_APP_NAME === appName &&
    ASSIGNMENT_ACTION === appAction &&
    FILES_FIELD_NAME === name
  ) {
    return true;
  }

  // Email: Send an email, "Attachments" param
  if (
    EMAIL_APP_NAME === appName &&
    SEND_ACTION_NAME === appAction &&
    FILES_FIELD_NAME === name
  ) {
    return true;
  }

  // Email: Send a web form, "Attachments" param
  if (
    EMAIL_APP_NAME === appName &&
    WEBFORM_ACTION === appAction &&
    FILES_FIELD_NAME === name
  ) {
    return true;
  }

  // SharePoint: Upload files, "File to upload" param
  if (
    SHAREPOINT_APP_NAME === appName &&
    UPLOAD_FILE_ACTION_NAME === appAction &&
    FILE_FIELD_NAME === name
  ) {
    return true;
  }

  // Slack: Post a multiple choice question, "Attachments" param
  if (
    SLACK_APP_NAME === appName &&
    CHOICE_ACTION_NAME === appAction &&
    FILES_FIELD_NAME === name
  ) {
    return true;
  }

  return false;
};

export const getStepInputValues = ({
  configuration,
  name,
  prefix
}: {
  configuration: Array<FieldType>,
  name: Array<string>,
  prefix?: string
}): StepInputValues => {
  const value = (configuration || []).reduce((accumulator, input) => {
    if (name.includes(input.name) && input.value !== undefined) {
      accumulator.push({
        name: getAppInputKey({ name: input.name, prefix }),
        value: input.value
      });
    }

    return accumulator;
  }, []);

  return value;
};

// Gather referenced inputs across bindings
export const linkedConfigurationInputKeys = (
  configuration: Array<FieldType>
): Array<string> => {
  const bindingKeys = {};
  configuration.forEach(input => {
    const enumBinding = input?.schema?.$binding?.enum || '';
    if (!enumBinding) {
      return;
    }
    if (usesColumnBinding(input)) {
      const key = enumBinding.split('/')[1];
      bindingKeys[key] = true;
    } else {
      bindingKeys[input?.name] = true;
    }
  });
  return [...STEP_INPUT_VALUES_KEYS, ...Object.keys(bindingKeys)];
};

export const linkedConfigurationInputValues = (
  configuration: Array<FieldType>,
  prefix?: string
): StepInputValues => {
  const keys = linkedConfigurationInputKeys(configuration);
  return getStepInputValues({
    configuration,
    name: keys,
    prefix
  });
};

export const clearCarriageReturns = (text: string) =>
  text.replace(/(?:\r\n)/g, '\n');

export const INPUT_CHAIN_SELECT_SELECTION_TYPE = {
  FIELD: 'FIELD',
  ID: 'ID',
  INLINE: 'INLINE',
  STATIC_ITEM: 'STATIC_ITEM',
  TEXT: 'TEXT'
};

export type InputChainSelectSelectionType = $Values<
  typeof INPUT_CHAIN_SELECT_SELECTION_TYPE
>;
export const valueToString = ({
  isMulti,
  isColumnInput,
  selectionType,
  type,
  value
}: {
  isMulti: boolean,
  isColumnInput: boolean,
  selectionType?: InputChainSelectSelectionType,
  type?: FieldTypeType,
  value: any
}): string => {
  if (value === null) {
    return '';
  }
  if (
    isColumnInput &&
    selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM
  ) {
    if (isMulti && value && typeof value === 'string') {
      return papaparse.unparse([[clearCarriageReturns(value)]]);
    }
    if (Array.isArray(value) && value.length > 0) {
      return papaparse.unparse([value]);
    }
  }
  if (value && typeof value === 'string') {
    return clearCarriageReturns(value);
  }
  if (type === TYPE.ARRAY && Array.isArray(value)) {
    return value.toString();
  }
  if (
    (type === TYPE.BOOLEAN ||
      type === TYPE.INTEGER ||
      type === TYPE.NUMBER ||
      type === TYPE.OBJECT) &&
    typeof value !== 'string'
  ) {
    return JSON.stringify(value);
  }
  if (isEmptyFile(value)) {
    return '';
  }
  return value;
};

// We have a few actions that are mostly structural.  They share common params
// with the subprocess actions, but we can hide those params from the user to
// simplify configuration.
export const filterDisplayedInputsByAppID = ({
  appID,
  configuration
}: {
  appID: string,
  configuration: Array<FieldType>
}): Array<FieldType> => {
  // For Conditional blocks, hide all params (process ID, output field prefix)
  if (appID === CONDITIONAL_BLOCK_APP_ID) {
    return [];
  }

  // For Looping blocks, use some of its params, but hide the common ones:
  // (childRunDisplayName, childRunOwner, deadline, processID)
  if (appID === LOOPING_BLOCK_APP_ID) {
    return configuration.filter(
      ({ name }) =>
        ![
          'childRunOwner',
          'childRunDisplayName',
          'deadline',
          'processID'
        ].includes(name)
    );
  }

  return configuration;
};
