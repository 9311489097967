// @flow

import * as React from 'react';
import { Analytics } from '@catalytic/analytics-client';

export type AnalyticsContext = {
  analytics: Analytics
};

export const {
  Provider: AnalyticsProvider,
  Consumer: AnalyticsConsumer
}: React.Context<AnalyticsContext> = React.createContext({
  analytics: Analytics
});
