// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BaseText } from '@catalytic/catalytic-ui';

const ErrorMessage = () => (
  <BaseText>
    <FormattedMessage id="loading.error" defaultMessage="Error" />
  </BaseText>
);
ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
