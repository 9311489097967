// @flow

import * as React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { makeStyles } from '@material-ui/styles';
import gql from 'graphql-tag';
import { useIntl } from 'react-intl';
import { compose, pure, withHandlers } from 'recompose';
import uuid from 'uuid/v4';
import { FileInput, type ThemeType } from '@catalytic/catalytic-ui';
import {
  JSONRefType,
  type JSONSchema
} from '@catalytic/json-schema-validator-catalytic-web';
import { NodeType } from '@catalytic/view';
import { getFileUrl } from './FieldHelpers';
import isAllowedFileUploadSize from './isAllowedFileUploadSize';
import { toFullFileExtensionList } from './mimeTypeUtils';
import TopBarLoader from '../Loading/TopBarLoader';
import { type Process } from '../Process/ProcessTypes';
import { type Run } from '../Run/RunTypes';
import { type Step } from '../Step/StepTypes';
import useWebformContext from '../Webform/WebformContext';
import {
  FILE as FILE_LABEL,
  FILE_TABLE as FILE_TABLE_LABEL
} from '../const/label';
import {
  NODE_TYPE,
  type Node,
  type NodeType as NodeTypeType
} from '../shared/NodeTypes';
import Prompt from '../shared/Prompt/Prompt';
import logError from '../utils/logError';
import isUUID from '../utils/uuid';

const FILENAME = 'filename';
const ID = 'id';
const URL = 'url';

const useStyles = makeStyles((theme: ThemeType) => ({
  error: theme.mixins.inputErrorMessage
}));

const FileTableFragment = gql`
  fragment FileTableFragment on File {
    filename
    id
    url
  }
`;

const DEFAULT_TYPE = NODE_TYPE.TEAM;
export const MUTATIONS: { [key: NodeTypeType]: Object } = {
  [NODE_TYPE.ACTOR]: gql`
    ${FileTableFragment}

    mutation AddActorFiles($id: ID!, $files: [Upload]!) {
      addFiles: addActorFile(input: { id: $id, files: $files }) {
        files {
          ...FileTableFragment
          id
        }
      }
    }
  `,
  [NODE_TYPE.ACTOR]: gql`
    ${FileTableFragment}

    mutation AddContextFiles($id: ID!, $files: [Upload]!) {
      addFiles: addContextFile(input: { id: $id, files: $files }) {
        files {
          ...FileTableFragment
          id
        }
      }
    }
  `,
  [NODE_TYPE.CONTEXT_TYPE]: gql`
    ${FileTableFragment}

    mutation AddContextTypeFiles($id: ID!, $files: [Upload]!) {
      addFiles: addContextTypeFile(input: { id: $id, files: $files }) {
        files {
          ...FileTableFragment
          id
        }
      }
    }
  `,
  [NODE_TYPE.STEP]: gql`
    ${FileTableFragment}

    mutation AddStepFiles($id: ID!, $files: [Upload]!) {
      addFiles: addStepFile(input: { id: $id, files: $files }) {
        files {
          ...FileTableFragment
          id
        }
      }
    }
  `,
  [NODE_TYPE.TASK]: gql`
    ${FileTableFragment}

    mutation AddTaskFiles($id: ID!, $files: [Upload]!) {
      addFiles: addTaskFile(input: { id: $id, files: $files }) {
        files {
          ...FileTableFragment
          id
        }
      }
    }
  `,
  [DEFAULT_TYPE]: gql`
    ${FileTableFragment}

    mutation AddFiles($files: [Upload]!) {
      addFiles: addFile(input: { files: $files }) {
        files {
          ...FileTableFragment
          id
        }
      }
    }
  `
};

const FileDataTableRowFragment = gql`
  fragment FileDataTableRowFragment on DataTableRow {
    fields {
      id
      value
    }
    id
  }
`;

export const CREATE_DATA_TABLE = gql`
  mutation CreateDataTable(
    $input: DataTableUpdateInput!
    $id: CreateFieldInputV2!
    $filename: CreateFieldInputV2!
    $url: CreateFieldInputV2!
  ) {
    updateDataTable(input: $input) {
      id
    }
    id: createFieldV2(input: $id) {
      id
    }
    filename: createFieldV2(input: $filename) {
      id
    }
    url: createFieldV2(input: $url) {
      id
    }
  }
`;

export const CREATE_DATA_TABLE_ROW = gql`
  ${FileDataTableRowFragment}

  mutation CreateDataTableRow($input: CreateDataTableRowInput!) {
    createDataTableRow(input: $input) {
      ...FileDataTableRowFragment
      id
    }
  }
`;

const REMOVE_DATA_TABLE_ROW = gql`
  mutation RemoveDataTableRow($id: String!) {
    removeDataTableRow(id: $id) {
      id
    }
  }
`;

export const GET_TABLE = gql`
  ${FileDataTableRowFragment}

  query Table(
    $id: ID!
    $first: Int = 100
    $after: String
    $offset: Int
    $order: String
  ) {
    table(id: $id) {
      id
      rows(first: $first, after: $after, offset: $offset, order: $order) {
        nodes {
          ...FileDataTableRowFragment
          id
        }
      }
    }
  }
`;

type Props = {
  contentMediaType?: string,
  context?: null | Node | Run | Process | Step,
  disabled?: boolean,
  id?: string,
  name?: string,
  onBlur?: (...args: Array<any>) => any,
  onChange?: (...args: Array<any>) => any,
  readOnly?: boolean,
  ref?: React.ElementRef<*>,
  schema?: JSONSchema<>,
  value?: any
};

const FileTableField = (props: Props) => {
  const {
    contentMediaType,
    context,
    disabled,
    id,
    name,
    onChange,
    readOnly,
    ref,
    schema,
    value: tableId,
    ...other
  } = props;
  const accept = toFullFileExtensionList(contentMediaType);
  const { __typename, id: contextId } = context || {};
  const inputRef = ref || React.createRef();
  const [fileTableFieldLoading, setFileTableFieldLoading] = React.useState(
    false
  );
  const [inputError, setInputError] = React.useState(null);
  const intl = useIntl();
  const classes = useStyles();
  const webformContext = useWebformContext();
  const { webhookID } = webformContext || {};
  const [
    createDataTable,
    { error: createDataTableError, loading: createDataTableLoading }
  ] = useMutation(CREATE_DATA_TABLE);
  const [
    createDataTableRow,
    { error: createDataTableRowError, loading: createDataTableRowLoading }
  ] = useMutation(CREATE_DATA_TABLE_ROW);
  const [removeDataTableRow, { error: removeDataTableRowError }] = useMutation(
    REMOVE_DATA_TABLE_ROW
  );
  const [
    addFiles,
    { error: addFilesError, loading: addFilesLoading }
  ] = useMutation(MUTATIONS[__typename] || MUTATIONS[DEFAULT_TYPE]);
  const { error: getTableError, loading: getTableLoading, data } = useQuery(
    GET_TABLE,
    {
      onError: error => logError(error),
      skip: !(tableId && typeof tableId === 'string'),
      variables: { id: tableId }
    }
  );
  // Get files from data table
  const rowIds = (data?.table?.rows?.nodes || []).map(({ id }) => id);
  const files = (data?.table?.rows?.nodes || []).map(({ fields }) => {
    const [{ value: id }, { value: filename }, { value: url }] = fields || [];
    const name = filename || FILE_LABEL;
    const preview = url || getFileUrl(id);

    if (id && typeof id === 'string' && isUUID(id)) {
      return { id, name, value: { preview } };
    }
  });
  // The field value is considered `undefined` when data table does not contain files
  const defaultValue =
    tableId && typeof tableId === 'string' && files.length > 0
      ? tableId
      : undefined;

  const handleChange = React.useCallback(
    async (...args: Array<any>) => {
      const [event, isAcceptable = true] = args;
      const unvalidatedValue = event?.currentTarget?.value || [];

      const value = Array.isArray(unvalidatedValue)
        ? unvalidatedValue.filter(item => isAllowedFileUploadSize(item.value))
        : unvalidatedValue;
      const id = tableId || uuid();

      if (!(tableId && typeof tableId === 'string')) {
        setFileTableFieldLoading(true);

        // Create data table and fields
        await createDataTable({
          onError: error => {
            logError(error);
            setFileTableFieldLoading(false);
          },
          variables: {
            input: {
              displayName: FILE_TABLE_LABEL,
              id,
              webhookID
            },
            id: {
              context: { id, type: JSONRefType.DATA_TABLE },
              name: ID,
              schema: {
                contentMediaType,
                default: null,
                format: 'ref',
                nullable: false,
                refType: JSONRefType.FILE,
                title: intl.formatMessage({
                  id: 'fileTableField.id',
                  defaultMessage: 'ID'
                }),
                type: 'string'
              },
              view: null
            },
            filename: {
              context: { id, type: JSONRefType.DATA_TABLE },
              name: FILENAME,
              schema: {
                default: null,
                nullable: false,
                title: intl.formatMessage({
                  id: 'fileTableField.filename',
                  defaultMessage: 'Filename'
                }),
                type: 'string'
              },
              view: { type: NodeType.TEXT_VIEW, reference: '' }
            },
            url: {
              context: { id, type: JSONRefType.DATA_TABLE },
              name: URL,
              schema: {
                default: null,
                nullable: false,
                title: intl.formatMessage({
                  id: 'fileTableField.url',
                  defaultMessage: 'URL'
                }),
                type: 'string'
              },
              view: { type: NodeType.TEXT_VIEW, reference: '' }
            }
          }
        });
      }

      if (
        unvalidatedValue.length > 0 &&
        value.length !== unvalidatedValue.length
      ) {
        setInputError(
          intl.formatMessage({
            id: 'files.tooLarge',
            defaultMessage: 'Files must be under 1 GB.'
          })
        );
      } else if (accept && !isAcceptable) {
        setInputError(
          intl.formatMessage(
            {
              id: 'files.accept',
              defaultMessage:
                '{count, plural, one {Files must be of the following type: {accept}} other {Files must be of the following types: {accept}}}'
            },
            { accept, count: accept.split(',').length }
          )
        );
      } else {
        setInputError(null);
      }

      // Add files and create data table rows
      if (Array.isArray(value) && value.length > files.length) {
        const files = value
          .filter(({ value }) => value instanceof File) // eslint-disable-line no-undef
          .map(({ value }) => value);

        if (Array.isArray(files) && files.length > 0) {
          const { data } = await addFiles({
            onError: error => {
              logError(error);
              setFileTableFieldLoading(false);
            },
            variables: {
              ...(__typename === DEFAULT_TYPE ? {} : { id: contextId }),
              files
            }
          });

          for (const { filename, id: fileId, url } of data?.addFiles?.files ||
            []) {
            await createDataTableRow({
              onError: error => {
                logError(error);
                setFileTableFieldLoading(false);
              },
              ...(tableId && typeof tableId === 'string'
                ? {
                    update: (proxy, { data: { createDataTableRow } }) => {
                      const data = proxy.readQuery({
                        query: GET_TABLE,
                        variables: { id: tableId }
                      });
                      proxy.writeQuery({
                        query: GET_TABLE,
                        variables: { id: tableId },
                        data: {
                          table: {
                            ...data?.table,
                            rows: {
                              ...data?.table?.rows,
                              nodes: [
                                ...data?.table?.rows?.nodes,
                                createDataTableRow
                              ]
                            }
                          }
                        }
                      });
                    }
                  }
                : {}),
              variables: {
                input: {
                  fields: [
                    { name: ID, value: fileId },
                    { name: FILENAME, value: filename },
                    { name: URL, value: url }
                  ],
                  table: id
                }
              }
            });
          }
        }
      }

      setFileTableFieldLoading(false);

      // Remove data table rows
      if (Array.isArray(value) && value.length < files.length) {
        const index = files.findIndex(
          files => !value.some(({ id }) => files.id === id)
        );
        const rowId = rowIds[index];

        if (rowId && typeof rowId === 'string') {
          await removeDataTableRow({
            onError: error => logError(error),
            ...(tableId && typeof tableId === 'string'
              ? {
                  update: proxy => {
                    const data = proxy.readQuery({
                      query: GET_TABLE,
                      variables: { id: tableId }
                    });
                    const nodes = (data?.table?.rows?.nodes || []).filter(
                      ({ id }) => id !== rowId
                    );
                    proxy.writeQuery({
                      query: GET_TABLE,
                      variables: { id: tableId },
                      data: {
                        table: {
                          ...data?.table,
                          rows: {
                            ...data?.table?.rows,
                            nodes
                          }
                        }
                      }
                    });
                  }
                }
              : {}),
            variables: { id: rowId }
          });
        }
      }

      if (typeof onChange === 'function') {
        onChange({
          currentTarget: {
            id,
            name,
            value: id
          }
        });
      }
    },
    [
      __typename,
      accept,
      addFiles,
      contentMediaType,
      contextId,
      createDataTable,
      createDataTableRow,
      files,
      intl,
      name,
      onChange,
      removeDataTableRow,
      rowIds,
      tableId,
      webhookID
    ]
  );
  // Do not show loading state when removing a file
  const loading =
    addFilesLoading ||
    createDataTableLoading ||
    createDataTableRowLoading ||
    fileTableFieldLoading ||
    getTableLoading;
  const error =
    addFilesError ||
    createDataTableError ||
    createDataTableRowError ||
    getTableError ||
    removeDataTableRowError;

  return (
    <>
      <Prompt when={loading} />
      {loading && <TopBarLoader />}
      <FileInput
        accept={accept}
        defaultValue={defaultValue}
        // Prevent file input while the field is loading
        disabled={disabled || loading}
        files={files}
        hasError={error || !!inputError}
        id={id}
        inputRef={inputRef}
        // If files exist, do not show file input loading state
        loading={files.length > 0 ? undefined : loading}
        multiple
        name={name}
        onChange={handleChange}
        placeholder={intl.formatMessage({
          id: 'fileTableField.placeholder',
          defaultMessage: 'No file(s) chosen'
        })}
        readOnly={readOnly}
        {...other}
      />
      {inputError && <div className={classes.error}>{inputError}</div>}
    </>
  );
};

const EnhancedFileTableField: React.ComponentType<Props> = compose(
  // Prevent re-render caused by passing functions through props.
  // TODO: Parent components should memoize callbacks.
  // https://reactjs.org/docs/hooks-reference.html#usecallback
  withHandlers({
    onBlur: ({ onBlur }) => (...args) => {
      if (typeof onBlur === 'function') {
        return onBlur(...args);
      }
    },
    onChange: ({ onChange }) => (...args) => {
      if (typeof onChange === 'function') {
        return onChange(...args);
      }
    }
  }),
  pure
)(FileTableField);

export default EnhancedFileTableField;
