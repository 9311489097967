// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import useMountedState from 'react-use/lib/useMountedState';
import classNames from 'classnames';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import { makeStyles } from '@material-ui/styles';
import { type JSONSchema } from '@catalytic/json-schema-validator-catalytic-web';
import { WebformViewConsumer } from '../../View/WebformView/WebformView';
import { WAIT as DEBOUNCE_WAIT } from '../../const/debounce';
import getErrorMessage from '../../utils/getErrorMessage';
import getFieldValue from '../../utils/getFieldValue';

const useStyles = makeStyles(theme => {
  const inputMarginTop = {
    '& + $input': {
      marginTop: theme.functions.toRem(theme.variables.inputMarginTop)
    }
  };

  return {
    root: {
      ...theme.mixins.breakWord,
      marginBottom: theme.functions.toRem(theme.variables.inputMarginBottom),
      backgroundColor: theme.colors.white,
      textAlign: 'left'
    },
    errorMarginBottom: {
      marginBottom: 0
    },
    title: {
      ...theme.typography.baseText,
      ...inputMarginTop,
      display: 'block',
      color: theme.colors.black,
      fontWeight: 700
    },
    description: {
      ...theme.typography.smallText,
      ...inputMarginTop
    },
    example: {
      ...theme.typography.smallText,
      ...inputMarginTop,
      color: theme.colors.slateGrey
    },
    error: {
      ...theme.mixins.inputErrorMessage,
      ...inputMarginTop
    },
    input: theme.mixins.inputMargin
  };
});

type Context = {
  'aria-describedby'?: string,
  'aria-invalid'?: boolean,
  'aria-labelledby'?: string,
  className: string,
  disabled: boolean,
  displayErrors: boolean,
  hasError: boolean,
  inputClassName?: string,
  onBlur?: (...args: Array<any>) => any,
  onChange?: (...args: Array<any>) => any,
  readOnly: boolean,
  setDisplayErrorsState: (...args: Array<any>) => any,
  setLoadingState: (...args: Array<any>) => any
};

export const InputContext: React.Context<Context | void> = React.createContext();

type Props = {
  children: React.Node,
  className?: string,
  contextValues?: { [string]: any },
  description?: null | string | React.Element<typeof FormattedMessage>,
  descriptionClassName?: string,
  disabled?: boolean,
  displayErrors?: boolean,
  error?: string | React.Element<typeof FormattedMessage> | Error,
  errorClassName?: string,
  errorMessage?: string,
  example?: null | string | React.Element<typeof FormattedMessage>,
  exampleClassName?: string,
  fieldName?: string,
  hasError?: boolean,
  hasValidation?: boolean,
  id: string,
  inputClassName?: string,
  inputElementClassName?: string,
  name: string,
  onBlur?: (...args: Array<any>) => any,
  onChange?: (...args: Array<any>) => any,
  readOnly?: boolean,
  schema?: JSONSchema<>,
  title?: string | React.Element<typeof FormattedMessage>,
  titleClassName?: string,
  type?: string,
  value?: any
};

const getInputErrorMessage = ({
  contextValues,
  errorMessage,
  name,
  schema,
  value
}) => {
  const inputErrorMessage =
    getErrorMessage({
      contextValues,
      name,
      schema,
      value
    }) || errorMessage;

  return inputErrorMessage;
};

const Input = ({
  children,
  className,
  contextValues,
  description,
  descriptionClassName,
  disabled: disabledProp,
  displayErrors: displayErrorsInputProp,
  error: errorProp,
  errorClassName,
  errorMessage: errorMessageProp,
  example,
  exampleClassName,
  fieldName,
  hasError: hasErrorProp,
  hasValidation,
  id,
  inputClassName,
  inputElementClassName,
  name,
  onBlur,
  onChange,
  readOnly: readOnlyProp,
  schema,
  title,
  titleClassName,
  type,
  value
}: Props) => {
  const classes = useStyles();
  const getMountedState = useMountedState();
  const isMounted = getMountedState();
  const [errorMessage, setErrorMessage] = React.useState(
    getInputErrorMessage({
      contextValues,
      errorMessage: errorMessageProp,
      name: fieldName || name,
      schema,
      value
    })
  );
  const [debouncedSetErrorMessage] = useDebouncedCallback(
    value => setErrorMessage(value),
    DEBOUNCE_WAIT
  );
  const handleBlur = React.useCallback(
    (...args) => {
      const [event] = args;
      const value = event?.currentTarget?.value;
      const fieldValue = getFieldValue(value, type);

      // Update input error message when input has blurred.
      if (isMounted) {
        setErrorMessage(
          getInputErrorMessage({
            contextValues,
            errorMessage: errorMessageProp,
            name: fieldName || name,
            schema,
            value: fieldValue
          })
        );
      }

      if (typeof onBlur === 'function') {
        onBlur(...args);
      }
    },
    [
      contextValues,
      errorMessageProp,
      fieldName,
      isMounted,
      name,
      onBlur,
      schema,
      type
    ]
  );
  const handleChange = React.useCallback(
    (...args) => {
      const [event] = args;
      const value = event?.currentTarget?.value;
      const fieldValue = getFieldValue(value, type);

      // Update input error message when input has changed.
      if (isMounted) {
        debouncedSetErrorMessage(
          getInputErrorMessage({
            contextValues,
            errorMessage: errorMessageProp,
            name: fieldName || name,
            schema,
            value: fieldValue
          })
        );
      }

      if (typeof onChange === 'function') {
        onChange(...args);
      }
    },
    [
      contextValues,
      debouncedSetErrorMessage,
      errorMessageProp,
      fieldName,
      isMounted,
      name,
      onChange,
      schema,
      type
    ]
  );

  // Update input error message when props have changed.
  React.useEffect(() => {
    setErrorMessage(
      getInputErrorMessage({
        contextValues,
        errorMessage: errorMessageProp,
        name: fieldName || name,
        schema,
        value
      })
    );
  }, [contextValues, errorMessageProp, fieldName, name, schema, value]);

  return (
    <WebformViewConsumer>
      {props => {
        const {
          disabled,
          displayErrors: displayErrorsWebformProp,
          readOnly,
          setDisplayErrorsState,
          setLoadingState
        } = props || {};
        const displayErrors =
          displayErrorsWebformProp || displayErrorsInputProp || false; // Show errors if either the input or the overall webform sets displayErrors
        const shouldDisplayErrorMessage = displayErrors && hasValidation;
        const visibleErrorMessage = shouldDisplayErrorMessage
          ? errorMessage
          : undefined;
        const visibleError =
          errorProp instanceof Error ? errorProp.message : errorProp;
        const error = visibleError || visibleErrorMessage;
        const hasError = Boolean(error);
        const isDisabled = disabledProp || disabled;
        const isReadOnly = readOnlyProp || readOnly;
        const ariaDescribedby = description ? `${id}-description` : undefined;
        const ariaInvalid = hasError ? true : undefined;
        const ariaLabelledby = title ? `${id}-label` : undefined;

        return (
          <div
            className={classNames(
              classes.root,
              { [classes.errorMarginBottom]: error },
              className
            )}
            data-id={name} // Required for scroll invalid input into view logic tied to `WebformView` component
            data-error={hasValidation ? errorMessage : undefined} // Required for scroll invalid input into view logic tied to `WebformView` component
          >
            {title && (
              <label
                className={classNames(classes.title, titleClassName)}
                htmlFor={!isDisabled && !isReadOnly ? id : undefined}
                id={`${id}-label`}
              >
                {title}
              </label>
            )}
            {description && (
              <div
                className={classNames(
                  classes.description,
                  descriptionClassName
                )}
                id={`${id}-description`}
              >
                {description}
              </div>
            )}
            {example && (
              <div className={classNames(classes.example, exampleClassName)}>
                {example}
              </div>
            )}
            <InputContext.Provider
              value={{
                'aria-describedby': ariaDescribedby,
                'aria-invalid': ariaInvalid,
                'aria-labelledby': ariaLabelledby,
                className: classNames(classes.input, inputClassName),
                disabled: isDisabled,
                displayErrors,
                hasError: hasErrorProp || hasError,
                inputClassName: inputElementClassName,
                onBlur: handleBlur,
                onChange: handleChange,
                readOnly: isReadOnly,
                setDisplayErrorsState,
                setLoadingState
              }}
            >
              {children}
            </InputContext.Provider>
            {hasError && (
              <div className={classNames(classes.error, errorClassName)}>
                {error}
              </div>
            )}
          </div>
        );
      }}
    </WebformViewConsumer>
  );
};
Input.displayName = 'Input';

export default React.memo<Props>(Input);

export const InputConsumer = InputContext.Consumer;
