// @flow

import classNames from 'classnames';
import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import { injectIntl } from 'react-intl';
import Icon from '../Icon/Icon';
import Menu from '../Menu/Menu';
import { compose } from 'recompose';

import { ReactComponent as AddGlyph } from '@catalytic/catalytic-icons/lib/glyphs/add.svg';
import { ReactComponent as SubtractGlyph } from '@catalytic/catalytic-icons/lib/glyphs/subtract.svg';

export const BuilderListItemStyle = (theme: ThemeType) => {
  return {
    root: {
      padding: '.325rem 0',
      marginBottom: '0.5rem',
      backgroundColor: theme.colors.blueGreyAlpha,
      borderRadius: '0.5rem',
      cursor: 'pointer'
    },
    overview: {
      padding: '0 1rem',
      display: 'flex',
      flexWrap: 'wrap',
      '& input': {
        width: '100%',
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.lightGrey,
        borderRadius: 0
      }
    },
    overviewRow: {
      display: 'flex',
      flexGrow: 1,
      width: '100%'
    },
    overviewRight: {
      whiteSpace: 'nowrap',
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      maxHeight: '2.875rem'
    },
    icons: {
      whiteSpace: 'nowrap',
      margin: '.5rem 1rem .5rem 0',
      verticalAlign: 'sub',
      '& + $icons': {
        marginLeft: 0
      },
      display: 'none',
      [theme.breakpoints.gt.mobile.portrait]: {
        display: 'flex'
      }
    },
    header: {
      ...theme.typography.header3,
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      minWidth: 0,
      minHeight: '2.875rem',
      '& input': {
        ...theme.typography.header3,
        fontWeight: 500,
        backgroundColor: 'transparent',
        border: 'inherit',
        paddingLeft: 0,
        '&:active, &:focus, &:hover, &:required:invalid, &:required:valid': {
          backgroundColor: 'transparent',
          outline: 'inherit',
          paddingLeft: `0 !important`
        }
      }
    },
    fields: {
      display: 'flex',
      '& input': {
        margin: '0 .5rem .5rem 0',
        '&:focus, &:hover': {
          backgroundColor: theme.colors.white,
          outline: 'inherit'
        }
      }
    },
    buttons: {
      margin: '0 0.5rem',
      '& > *': {
        marginLeft: '.25rem',
        minWidth: '6rem'
      },
      [theme.breakpoints.gt.mobile.portrait]: {
        marginLeft: '0'
      }
    },
    buttonsInline: {
      display: 'none',
      [theme.breakpoints.gt.mobile.portrait]: {
        display: 'flex'
      }
    },
    buttonsOwnLine: {
      margin: '0 -0.5rem 0.5rem -0.5rem;',
      [theme.breakpoints.gt.mobile.portrait]: {
        display: 'none'
      }
    },
    focused: {
      backgroundColor: theme.colors.white,
      boxShadow: `0 .25rem 1.25rem ${theme.colors.lightGreyAlpha}, 0 0 1px 1px rgba(0, 0, 0, 0.05)`,
      cursor: 'auto'
    },
    badge: {
      marginLeft: '1rem'
    },
    children: {
      marginTop: '0.3rem',
      borderTop: `1px solid ${theme.colors.blueGrey}`,
      padding: '1rem',
      [theme.breakpoints.desktop]: {
        padding: '1.5rem 2rem 1rem 2rem'
      }
    },
    addRemove: {
      border: `1px solid ${theme.colors.lightGrey}`,
      borderRadius: theme.variables.borderRadiusSmall,
      marginLeft: '.25rem',
      cursor: 'pointer',
      padding: '.2rem',
      '& svg': {
        color: theme.colors.battleshipGrey,
        fontSize: '1.5rem'
      },
      '&:hover': {
        backgroundColor: theme.colors.white
      }
    },
    menu: {
      marginLeft: '0.25rem'
    },
    openerContainer: {
      display: 'inline'
    },
    subheader: {
      marginBottom: '0.5rem',
      [theme.breakpoints.gt.mobile.portrait]: {
        marginLeft: '2.875rem'
      }
    }
  };
};

type Props = InjectSheetProvidedProps & {
  add?: (SyntheticEvent<HTMLSpanElement>) => any,
  addRemoveClassName?: string,
  badge?: Node,
  badgeClassName?: string,
  buttons?: Node,
  children?: Node,
  childrenClassName?: string,
  fields?: Node,
  focused: boolean,
  header?: Node,
  icons?: Node,
  intl: Object,
  menuItems?: Array<Node>,
  overviewClassName?: string,
  remove?: (SyntheticEvent<HTMLSpanElement>) => any,
  subheader?: Node
};

const DEFAULT_MESSAGES = {
  status: {
    id: 'bli.status',
    defaultMessage: 'Current status'
  },
  add: {
    id: 'bli.add',
    defaultMessage: 'Add a row'
  },
  remove: {
    id: 'bli.remove',
    defaultMessage: 'Remove this row'
  }
};

const BuilderListItem = ({
  add,
  addRemoveClassName,
  badge,
  badgeClassName,
  buttons,
  children,
  childrenClassName,
  classes,
  className,
  fields,
  focused,
  header,
  icons,
  intl,
  menuItems,
  overviewClassName,
  remove,
  subheader,
  theme,
  ...props
}: Props): Node => (
  <div
    className={classNames(classes.root, className, {
      [classes.focused]: focused
    })}
    {...props}
  >
    <div className={classNames(classes.overview, overviewClassName)}>
      <div className={classes.overviewRow}>
        {icons && <Icon className={classes.icons}>{icons}</Icon>}
        {header && <span className={classes.header}>{header}</span>}
        {fields && <span className={classes.fields}>{fields}</span>}
        <div className={classes.overviewRight}>
          {buttons && (
            <span
              className={classNames(classes.buttons, classes.buttonsInline)}
            >
              {buttons}
            </span>
          )}
          {!!remove && (
            <span
              className={classNames(classes.addRemove, addRemoveClassName)}
              data-testid="remove"
              onClick={remove}
              role="presentation"
              title={intl.formatMessage(DEFAULT_MESSAGES.remove)}
            >
              {<SubtractGlyph />}
            </span>
          )}
          {!!add && (
            <span
              className={classNames(classes.addRemove, addRemoveClassName)}
              data-testid="add"
              onClick={add}
              role="presentation"
              title={intl.formatMessage(DEFAULT_MESSAGES.add)}
            >
              {<AddGlyph />}
            </span>
          )}
          {badge && (
            <span
              className={classNames(classes.badge, badgeClassName)}
              title={intl.formatMessage(DEFAULT_MESSAGES.status)}
            >
              {badge}
            </span>
          )}
          {menuItems && (
            // Trapping the click so it doesn't propagate up to the parent
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div className={classes.menu} onClick={e => e.stopPropagation()}>
              <Menu openerClassName={classes.openerContainer}>{menuItems}</Menu>
            </div>
          )}
        </div>
      </div>
      {subheader && (
        <div className={classNames(classes.overviewRow, classes.subheader)}>
          {subheader}
        </div>
      )}
      {buttons && (
        <span className={classNames(classes.buttons, classes.buttonsOwnLine)}>
          {buttons}
        </span>
      )}
    </div>
    {children && (
      // Trapping the click so it doesn't propagate up to the parent
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        className={classNames(classes.children, childrenClassName)}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </div>
    )}
  </div>
);
BuilderListItem.displayName = 'BuilderListItem';

const EnhancedBuilderListItem = compose(
  injectIntl,
  injectSheet(BuilderListItemStyle)
)(BuilderListItem);
EnhancedBuilderListItem.displayName = 'BuilderListItem';

export default EnhancedBuilderListItem;
