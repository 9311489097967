// @flow

import { type ComponentType, type Node } from 'react';
import { compose, lifecycle, withStateHandlers } from 'recompose';
import { type Type } from './SearchTypes';

export type Props = {
  mutation: ({ variables: Object }) => Promise<any>,
  query?: string,
  timeout?: number,
  type?: Type
};

const SearchCache = (): Node => null;

SearchCache.displayName = 'SearchCache';

const EnhancedSearchCache: ComponentType<Props> = compose(
  withStateHandlers(() => ({ lastCall: undefined }), {
    setLastCall: (
      { lastCall },
      { mutation, query, timeout = 4000, type }
    ) => () => {
      // Ignore empty queries
      if (typeof query !== 'string' || query.length === 0) {
        return {};
      }
      // Clear last call if one exists
      if (lastCall) clearTimeout(lastCall);
      // Set new timeout
      const id = setTimeout(
        () => mutation({ variables: { query, type } }),
        timeout
      );
      return {
        lastCall: id
      };
    }
  }),
  lifecycle({
    UNSAFE_componentWillUpdate({ query, setLastCall, type }) {
      if (this.props.query !== query || this.props.type !== type) {
        setLastCall();
      }
    }
  })
)(SearchCache);

export default EnhancedSearchCache;
