// @flow

import * as React from 'react';
import { branch, compose, renderComponent } from 'recompose';
import { Query } from 'react-apollo';
import { GlobalSearchQuery, TypeSearchQuery } from './SearchQueries';

const SearchQuery = (compose(
  branch(
    ({ type }) => typeof type === 'string',
    renderComponent(({ type, query, ...props }) => (
      <Query
        {...props}
        query={TypeSearchQuery}
        variables={{ first: 20, query, type: [type] }}
      />
    )),
    renderComponent(({ type, query, ...props }) => (
      <Query {...props} query={GlobalSearchQuery} variables={{ query }} />
    ))
  )
)(Query): React.ComponentType<Object>);

SearchQuery.displayName = 'SearchQuery';

export default SearchQuery;
