import gql from 'graphql-tag';
import { FieldFragment } from '../Field/FieldTypeDefs';
import { UserSelectActorFragment } from '../User/UserSelectTypeDefs';

export const ProcessListItemFragment = gql`
  fragment ProcessListItemFragment on ContextType {
    activeContextsCount
    ancestorID
    description
    category
    displayName
    viewerCanStart
    viewerFavorite
    id
    lastStartDate
    owner {
      id
      displayName
    }
    workspaceID
  }
`;

export const RunProcessFragment = gql`
  fragment RunProcessFragment on ContextType {
    ancestorID
    context {
      id
    }
    displayName
    id
    isActive
  }
`;

export const ProcessFieldVisibilityFragment = gql`
  ${UserSelectActorFragment}
  fragment ProcessFieldVisibilityFragment on ContextType {
    id
    fieldVisibility {
      type
      users {
        id
        ...UserSelectActorFragment
      }
    }
  }
`;

export const ProcessFavoritesQuery = gql`
  ${ProcessListItemFragment}

  query ProcessFavorites {
    viewer {
      email
      fullName
      id
      isTeamAdmin
      isTeamOwner
      thumbnailUrl
      workflowFavorites: favorites(type: CONTEXT_TYPE) {
        nodes {
          id
          ... on ContextType {
            id
            ...ProcessListItemFragment
          }
        }
      }
    }
  }
`;

export const ProcessApprovalContextFragment = gql`
  fragment ProcessApprovalContextFragment on ContextType {
    id
    approvalContext {
      id
      displayName
      startDate
      status
    }
  }
`;

export const ProcessIsActiveQuery = gql`
  ${ProcessApprovalContextFragment}
  query ProcessIsActive($id: ID!) {
    contextType(id: $id) {
      id
      isActive
      ...ProcessApprovalContextFragment
    }
  }
`;

export const ProcessUserCanEditQuery = gql`
  query ProcessUserCanEdit($id: ID!) {
    contextType(id: $id) {
      id
      permissions {
        id
        canEdit
      }
    }
  }
`;

export const UpdateProcessMetadata = gql`
  mutation UpdateContextType($input: UpdateContextTypeInput!) {
    updateContextType(input: $input) {
      category
      description
      displayName
      id
      owner {
        id
        fullName
      }
    }
  }
`;

export const UpdateProcessPermissions = gql`
  ${UserSelectActorFragment}
  mutation UpdateContextType($input: UpdateContextTypeInput!) {
    updateContextType(input: $input) {
      id
      canEditUsers {
        id
        ...UserSelectActorFragment
      }
      canViewUsers {
        id
        ...UserSelectActorFragment
      }
      runVisibility
    }
  }
`;

export const UpdateProcessFieldVisibility = gql`
  ${ProcessFieldVisibilityFragment}
  mutation UpdateContextTypeFieldVisibility($input: FieldVisibilityInput!) {
    updateContextTypeFieldVisibility(input: $input) {
      id
      ...ProcessFieldVisibilityFragment
    }
  }
`;

export const ContextTypeAvailableFieldsQuery = gql`
  ${FieldFragment}

  query ContextTypeAvailableFieldsQuery($id: ID!) {
    contextType(id: $id) {
      id
      context {
        id
        steps {
          nodes {
            id
            appID
            displayName
          }
        }
      }
      fieldsWithContext {
        nodes {
          field {
            id
            runtime
            ...FieldFragment
          }
          contexts {
            id
            displayName
            type
          }
        }
        totalCount
      }
      steps {
        nodes {
          id
          appID
          displayName
        }
      }
    }
  }
`;

export const UpdateProcessWorkspace = gql`
  mutation UpdateProcessWorkspace($input: UpdateContextTypeInput!) {
    updateContextType(input: $input) {
      id
      workspaceID
    }
  }
`;
