// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SmallText, Spinner } from '@catalytic/catalytic-ui';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    margin: '1rem 0',
    '& > *': {
      verticalAlign: 'middle'
    }
  },
  label: {
    display: 'inline-block',
    paddingLeft: '0.5rem'
  }
});

export const InlineLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.root} data-testid="inline-loader">
      <Spinner />
      <SmallText className={classes.label}>
        <FormattedMessage
          id="InlineLoader.loading"
          defaultMessage="Loading..."
        />
      </SmallText>
    </div>
  );
};
InlineLoader.displayName = 'InlineLoader';

export default InlineLoader;
