// @flow

import * as React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.functions.toRem(theme.variables.mainVerticalPadding),
    paddingBottom: theme.functions.toRem(theme.variables.mainVerticalPadding),
    paddingLeft: theme.functions.toRem(theme.variables.mainHorizontalPadding),
    paddingRight: theme.functions.toRem(theme.variables.mainHorizontalPadding),
    [theme.breakpoints.gt.mobile.portrait]: {
      paddingLeft: theme.functions.toRem(
        theme.variables.mainHorizontalPaddingMobilePortrait
      ),
      paddingRight: theme.functions.toRem(
        theme.variables.mainHorizontalPaddingMobilePortrait
      )
    },
    [theme.breakpoints.gt.tablet.portrait]: {
      paddingLeft: theme.functions.toRem(
        theme.variables.mainHorizontalPaddingTabletPortrait
      ),
      paddingRight: theme.functions.toRem(
        theme.variables.mainHorizontalPaddingTabletPortrait
      )
    },
    [theme.breakpoints.gt.tablet.landscape]: {
      paddingLeft: theme.functions.toRem(
        theme.variables.mainHorizontalPaddingTabletLandscape
      ),
      paddingRight: theme.functions.toRem(
        theme.variables.mainHorizontalPaddingTabletLandscape
      )
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.functions.toRem(theme.variables.mainMaxWidth),
    textAlign: 'left'
  }
}));

type Props = {
  className?: string,
  children?: React.Node
};

export const Main = React.forwardRef<Props, HTMLElement>(
  ({ className, children }: Props, ref) => {
    const classes = useStyles();

    return (
      <section className={classNames(classes.root, className)} ref={ref}>
        {children}
      </section>
    );
  }
);
Main.displayName = 'Main';

export default Main;
