// @flow
/* eslint-disable no-use-before-define */

import any from 'ramda/src/any';
import compose from 'ramda/src/compose';
import equals from 'ramda/src/equals';
import prop from 'ramda/src/prop';
import { type Actor } from '../User/ActorTypes';
import { type Status } from '../shared/Status/StatusTypes';
import { type TaskConnection } from '../Tasks/TaskTypes';
import { type Process } from '../Process/ProcessTypes';
import { type Field } from '../Field/FieldTypes';
import { type NodeType } from '../shared/NodeTypes';

export type Fields = Array<Field>;

export type FieldConnection = {
  nodes: Fields
};

export type Worker = {
  id: string,
  active: number,
  overdue: number,
  user: {
    email?: string,
    fullName?: string,
    id: string,
    isTeamAdmin: boolean,
    isTeamOwner: boolean,
    nickname?: string,
    context: Run,
    thumbnail?: string,
    __typename: string
  }
};

export type Comment = {
  actor: {
    id: string,
    fullName: string,
    nickname: string,
    thumbnailUrl: ?string
  },
  body: string,
  createdAt: string
};

export type CommentConnection = {
  nodes: Array<Comment>
};

export const RUN_TEST_MODE = {
  ALL: 'ALL',
  SELF: 'SELF',
  TASK: 'TASK'
};

export type RunTestMode = $Values<typeof RUN_TEST_MODE>;

export const isTestMode: Run => boolean = compose(
  isOneOf => isOneOf(Object.values(RUN_TEST_MODE)),
  any,
  equals,
  prop('testMode')
);

export type Milestone = {
  displayName: string,
  endDate: ?string,
  isCurrentMilestone: boolean,
  status: Status,
  startDate: ?string
};

export type Run = {
  __typename: NodeType,
  abortedTasks: { totalCount: number },
  actionsActiveCount: number,
  actionsCompletedCount: number,
  actionsSkippedCount: number,
  actionsTotalCount: number,
  comments: CommentConnection,
  context?: ?Run,
  deadline: ?string,
  displayName: string,
  description: string,
  endDate: ?string,
  failedTasks: { totalCount: number },
  id: string,
  isOverdue: boolean,
  fields: FieldConnection,
  milestone?: ?string,
  milestones?: {
    nodes: Array<Milestone>
  },
  owner: Actor,
  percentComplete: number,
  participants: Array<Worker>,
  rejectedTasks: { totalCount: number },
  status?: Status,
  startDate: string,
  testMode: ?RunTestMode,
  tasks: TaskConnection,
  type: ?Process,
  viewerFavorite: boolean
};

export type Runs = Array<Run>;
