import gql from 'graphql-tag';
import { PredictiveModelFragment } from '../PredictiveModel/PredictiveModelTypeDefs';

export const DataTableListItemFragment = gql`
  fragment DataTableListItemFragment on DataTable {
    displayName
    id
    modifiedBy {
      displayName
      id
    }
    modifiedDate
  }
`;

export const DataTableFieldFragment = gql`
  fragment DataTableFieldFragment on Field {
    condition
    description
    displayName
    displayOnly
    enum
    example
    format
    id
    items {
      enum
      type
    }
    name
    reference {
      id
      name
    }
    required
    schema
    type
    value
    viewConfiguration {
      default
      alternates
      views
    }
  }
`;

export const DataTableRowFragment = gql`
  fragment DataTableRowFragment on Field {
    format
    id
    name
    type
    value
    viewerRead
  }
`;

export const DataTableColumnFragment = gql`
  ${DataTableFieldFragment}
  ${PredictiveModelFragment}

  fragment DataTableColumnFragment on Field {
    ...DataTableFieldFragment
    id
    predictiveModel {
      ...PredictiveModelFragment
      id
    }
  }
`;

export const DataTableFragment = gql`
  fragment DataTableFragment on DataTable {
    columnsTotalCount
    description
    displayName
    id
    isArchived
    schema
    type
    userRestrictions
    viewConfiguration {
      default
      alternates
      views
    }
    visibility {
      type
      users {
        displayName
        id
        thumbnailUrl
      }
    }
  }
`;

export const DataTableQuery = gql`
  ${DataTableFragment}
  ${DataTableColumnFragment}

  query DataTableQuery($id: ID!) {
    table(id: $id) {
      ...DataTableFragment
      columns {
        ...DataTableColumnFragment
        id
      }
      createdBy {
        id
      }
      id
    }
  }
`;

// This is a more specific query for Data Table Fields.
// Data Table Fields do not require predictive model data
// because columns are not editable.
export const DataTableFieldQuery = gql`
  ${DataTableFragment}
  ${DataTableFieldFragment}

  query DataTableFieldQuery($id: ID!) {
    table(id: $id) {
      ...DataTableFragment
      columns {
        ...DataTableFieldFragment
        id
      }
      id
    }
  }
`;

export const DataTableRowsQuery = gql`
  ${DataTableRowFragment}

  query DataTableRowsQuery(
    $id: ID!
    $first: Int
    $after: String
    $offset: Int
    $order: String
  ) {
    dataTableRows(
      id: $id
      first: $first
      after: $after
      offset: $offset
      order: $order
    ) {
      nodes {
        fields {
          ...DataTableRowFragment
          id
        }
        id
      }
      totalCount
    }
  }
`;

export const DataTaleViewerQuery = gql`
  query DataTaleViewerQuery {
    viewer {
      id
    }
  }
`;

export const CreateDataTableRow = gql`
  ${DataTableRowFragment}

  mutation CreateDataTableRow($input: CreateDataTableRowInput!) {
    createDataTableRow(input: $input) {
      fields {
        ...DataTableRowFragment
        id
      }
      id
    }
  }
`;

export const RemoveDataTableRow = gql`
  mutation RemoveDataTableRow($id: String!) {
    removeDataTableRow(id: $id) {
      id
    }
  }
`;

export const RemoveField = gql`
  mutation RemoveField($id: ID!) {
    removeField(id: $id) {
      id
    }
  }
`;

export const UpdateFieldValue = gql`
  mutation UpdateFieldValue($input: UpdateFieldValueInput!) {
    updateFieldValue(input: $input) {
      id
      value
    }
  }
`;
