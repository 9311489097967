// @flow

import { defineMessages } from 'react-intl';
import { STATEMENT_OPERATOR } from './ConditionTypes';
import {
  ABORTED,
  COMPLETED,
  DELAYED,
  FAILED,
  REJECTED,
  RUNNING,
  SKIPPED,
  SNOOZED,
  WAITING
} from '../shared/Status/StatusTypes';

export const operatorMessages = defineMessages({
  CONTAINS: {
    id: 'ExpressionInputOperator.CONTAINS',
    defaultMessage: 'contains'
  },
  '!CONTAINS': {
    id: 'ExpressionInputOperator.!CONTAINS',
    defaultMessage: 'does not contain'
  },
  '!ENDS_WITH': {
    id: 'ExpressionInputOperator.!ENDS_WITH',
    defaultMessage: 'does not end with'
  },
  '!STARTS_WITH': {
    id: 'ExpressionInputOperator.!STARTS_WITH',
    defaultMessage: 'does not start with'
  },
  ENDS_WITH: {
    id: 'ExpressionInputOperator.ENDS_WITH',
    defaultMessage: 'ends with'
  },
  IS_EMPTY: {
    id: 'ExpressionInputOperator.IS_EMPTY',
    defaultMessage: 'is empty'
  },
  '==': {
    id: 'ExpressionInputOperator.==',
    defaultMessage: 'is equal'
  },
  '>': {
    id: 'ExpressionInputOperator.>',
    defaultMessage: 'is greater than'
  },
  '>=': {
    id: 'ExpressionInputOperator.>=',
    defaultMessage: 'is greater than or equal'
  },
  IN: {
    id: 'ExpressionInputOperator.IN',
    defaultMessage: 'is in'
  },
  '<': {
    id: 'ExpressionInputOperator.<',
    defaultMessage: 'is less than'
  },
  '<=': {
    id: 'ExpressionInputOperator.<=',
    defaultMessage: 'is less than or equal'
  },
  '!IS_EMPTY': {
    id: 'ExpressionInputOperator.!IS_EMPTY',
    defaultMessage: 'is not empty'
  },
  '!=': {
    id: 'ExpressionInputOperator.!=',
    defaultMessage: 'is not equal'
  },
  '!IN': {
    id: 'ExpressionInputOperator.!IN',
    defaultMessage: 'is not in'
  },
  STARTS_WITH: {
    id: 'ExpressionInputOperator.STARTS_WITH',
    defaultMessage: 'starts with'
  }
});

export const statementOperatorMessages = defineMessages({
  [STATEMENT_OPERATOR.AND]: {
    id: 'ExpressionInputLogicalOperator.&&',
    defaultMessage: 'and'
  },
  [STATEMENT_OPERATOR.OR]: {
    id: 'ExpressionInputLogicalOperator.||',
    defaultMessage: 'or'
  }
});

export const stepStatusMessages = defineMessages({
  [ABORTED]: {
    id: 'StepStatus.Ended',
    defaultMessage: 'Ended'
  },
  [COMPLETED]: {
    id: 'StepStatus.Completed',
    defaultMessage: 'Completed'
  },
  [DELAYED]: {
    id: 'StepStatus.Delayed',
    defaultMessage: 'Delayed'
  },
  [FAILED]: {
    id: 'StepStatus.Failed',
    defaultMessage: 'Failed'
  },
  [REJECTED]: {
    id: 'StepStatus.Rejected',
    defaultMessage: 'Rejected'
  },
  [RUNNING]: {
    id: 'StepStatus.Assigned',
    defaultMessage: 'Assigned'
  },
  [SKIPPED]: {
    id: 'StepStatus.Skipped',
    defaultMessage: 'Skipped'
  },
  [SNOOZED]: {
    id: 'StepStatus.Snoozed',
    defaultMessage: 'Snoozed'
  },
  [WAITING]: {
    id: 'StepStatus.Pending',
    defaultMessage: 'Pending'
  }
});

export const booleanMessages = defineMessages({
  true: {
    id: 'Boolean.True',
    defaultMessage: 'True'
  },
  false: {
    id: 'Boolean.False',
    defaultMessage: 'False'
  }
});
