// @flow

import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useWindowSize from 'react-use/lib/useWindowSize';
import filterGlyphUrl from '@catalytic/catalytic-icons/lib/glyphs/filter.svg';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import FilterSortItem from './FilterSortItem';
import FilterSortSearch from './FilterSortSearch';
import ModalView from '../../View/ModalView/ModalView';
import { SELECT_DISPLAY_NAME } from '../../Input/MultiSelectInput/MultiSelectInput';
import { devices } from '../../style/breakpoints';
import { type ThemeType } from '../../style/ThemeTypes';

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    marginBottom: '1.25rem'
  },
  filter: {
    display: 'none',
    [theme.breakpoints.gte.tablet.portrait]: {
      display: 'flex',
      alignItems: 'flex-end'
    }
  },
  trigger: {
    display: 'block',
    width: '100%',
    [theme.breakpoints.gte.tablet.portrait]: {
      display: 'none'
    }
  },
  triggerButton: {
    marginBottom: 0,
    [`& .${SELECT_DISPLAY_NAME}__placeholder`]: {
      ...theme.typography.smallText,
      color: theme.colors.black
    },
    [`& .${SELECT_DISPLAY_NAME}__control`]: {
      paddingLeft: '1.75rem',
      borderColor: theme.colors.blueGrey,
      backgroundColor: theme.colors.blueGrey,
      backgroundImage: `url(${filterGlyphUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '.5rem 50%',
      backgroundSize: '1rem',
      '&:hover, &:focus, &:focus-within': {
        borderColor: theme.colors.blueGrey,
        backgroundColor: theme.colors.blueGrey
      }
    }
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem'
  },
  modalButton: {
    ...theme.typography.header5,
    margin: '0 .75rem'
  }
}));

type Props = {
  children?: React.Node,
  className?: string,
  defaultValue?: string,
  isOpen?: boolean,
  mode?: React.Node,
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => any,
  onReset?: () => mixed,
  searchKey?: string,
  sidebarOpener?: React.Node,
  width?: number
};

const FilterSort = ({
  children,
  className,
  defaultValue,
  isOpen,
  mode,
  onChange,
  onReset,
  searchKey,
  sidebarOpener,
  width
}: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { width: windowWidth } = useWindowSize();
  const search = (
    <FilterSortSearch
      defaultValue={defaultValue}
      searchKey={searchKey}
      onChange={onChange}
    />
  );

  return (
    <div
      className={classNames(classes.root, className)}
      data-testid="filter-sort"
    >
      {(typeof width === 'number' ? width : windowWidth) <
      devices.tablet.portrait ? (
        <ModalView
          isOpen={isOpen}
          trigger={({ show }) => (
            <button className={classes.trigger} onClick={show}>
              <FilterSortItem
                className={classes.triggerButton}
                onClick={show}
                disabled
                placeholder={intl.formatMessage({
                  id: 'filterSort.trigger',
                  defaultMessage: 'Edit filters'
                })}
              />
            </button>
          )}
        >
          {({ hide }) => (
            <>
              <div className={classes.modalButtons}>
                <button className={classes.modalButton} onClick={hide}>
                  <FormattedMessage
                    defaultMessage="Done"
                    id="filterSort.done"
                  />
                </button>
                <button className={classes.modalButton} onClick={onReset}>
                  <FormattedMessage
                    defaultMessage="Clear filters"
                    id="filterSort.reset"
                  />
                </button>
              </div>
              {search}
              {children}
              {mode}
              {sidebarOpener}
            </>
          )}
        </ModalView>
      ) : (
        <div className={classes.filter}>
          {children}
          {search}
          {mode}
          {sidebarOpener}
        </div>
      )}
    </div>
  );
};
FilterSort.displayName = 'FilterSort';

export default FilterSort;
