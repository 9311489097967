// @flow

import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import boldIconUrl from '@catalytic/catalytic-icons/lib/glyphs/bold.svg';
import clearIconUrl from '@catalytic/catalytic-icons/lib/glyphs/clear.svg';
import imageIconUrl from '@catalytic/catalytic-icons/lib/glyphs/image.svg';
import italicIconUrl from '@catalytic/catalytic-icons/lib/glyphs/italic.svg';
import linkIconUrl from '@catalytic/catalytic-icons/lib/glyphs/link.svg';
import listOrderedIconUrl from '@catalytic/catalytic-icons/lib/glyphs/list-ordered.svg';
import listUnorderedIconUrl from '@catalytic/catalytic-icons/lib/glyphs/list-unordered.svg';
import { ReactComponent as MarkdownGlyph } from '@catalytic/catalytic-icons/lib/glyphs/markdown.svg';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import {
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  SelectionState
} from 'draft-js';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LOCALE } from '../../const/locale';
import FieldReferenceListItem from '../../Input/FieldReferenceInput/FieldReferenceListItem';
import MultiSelectInput from '../../Input/MultiSelectInput/MultiSelectInput';
import TextareaInput from '../../Input/TextareaInput/TextareaInput';
import type { ThemeType } from '../../style/ThemeTypes';
import { type FieldSuggestion } from '../FieldReferenceInput/FieldReferenceInput';

const HANDLEBAR_ENTITY_NAME = 'HANDLEBAR_ENTITY';
const HANDLEBAR_REGEX = /{{([a-zA-Z0-9-_.]+)}}/g;
const ZERO_WIDTH_SPACE_CHARACTER = '\u{200b}';

const EDITOR_MIN_HEIGHT_REM = 10;
const EDITOR_MAX_HEIGHT_REM = 25;
const DEFAULT_TOOLBAR_HEIGHT_REM = 5.5; // Height of the toolbar at 2 lines as shown by default

// react-draft-wysiwyg uses a primitive striing match to determine which set of
// translations to use. That library only has support for things like "en" and
// not "en-us", so this strips off any locale subtags, figuring that
// region-unaware translations in the right language are better than nothing.
const EDITOR_LOCALE = LOCALE.split('-')[0].toLowerCase();

const useStyles = makeStyles((theme: ThemeType) => ({
  // Update markdown rendering to match our theme's styles
  editor: {
    ...theme.mixins.inputPadding,
    ...theme.typography.baseText,
    maxHeight: `${EDITOR_MAX_HEIGHT_REM}rem`,
    minHeight: `${EDITOR_MIN_HEIGHT_REM}rem`,
    paddingTop: 0,
    whiteSpace: 'pre-line',
    '& .DraftEditor-editorContainer': {
      zIndex: 'inherit'
    },
    '& .public-DraftStyleDefault-block': {
      marginTop: '1rem'
    },
    '& h1': theme.typography.header1,
    '& h2': theme.typography.header2,
    '& h3': theme.typography.header3,
    '& h4': theme.typography.header4,
    '& h5': theme.typography.header5,
    '& h6': theme.typography.header6,
    '& h1,h2,h3,h4,h5,h6': {
      margin: '1rem 0 0.5rem',
      '& .public-DraftStyleDefault-block': {
        margin: 0
      }
    },
    '& li': {
      '& .public-DraftStyleDefault-block': {
        margin: 0
      }
    },
    '& ol, ul': {
      marginLeft: '1rem'
    }
  },
  error: {
    '&, &:focus, &:hover': {
      border: `1px solid ${theme.colors.dullRed} !important`
    }
  },
  handlebar: {
    '& > span': {
      background: `${theme.colors.highlightAlpha} !important`,
      borderRadius: '0.15rem'
    }
  },
  hidden: { display: 'none' },
  markdownContainer: {
    '& > div:last-child': {
      bottom: '0rem',
      top: 'inherit'
    }
  },
  markdownEditor: {
    maxHeight: `${EDITOR_MAX_HEIGHT_REM + DEFAULT_TOOLBAR_HEIGHT_REM}rem`,
    minHeight: `${EDITOR_MIN_HEIGHT_REM + DEFAULT_TOOLBAR_HEIGHT_REM}rem`
  },
  root: {
    position: 'relative'
  },
  toolbar: {
    backgroundColor: theme.colors.blueGrey,
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    marginBottom: 0,
    paddingBottom: '0.125rem',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    paddingTop: '0.5rem',
    '& .rdw-image-modal, & .rdw-link-modal': {
      ...theme.mixins.inputMenu,
      borderRadius: '0.25rem',
      top: '2.5rem',
      '& .rdw-image-modal-btn, & .rdw-link-modal-btn': {
        borderRadius: '0.25rem',
        '&:first-of-type': {
          backgroundColor: theme.colors.scienceBlue,
          borderColor: theme.colors.scienceBlue,
          color: theme.colors.white
        },
        '&:hover': {
          boxShadow: 'none'
        }
      }
    },
    '& .rdw-link-modal-target-option': {
      display: 'none'
    },
    '& .rdw-option-wrapper': {
      background: 'none',
      border: 'none',
      height: '2rem',
      margin: '0 0.75rem 0 0',
      width: '2rem',
      '& img': {
        height: '1rem',
        width: '1rem'
      }
    },
    '& .rdw-option-active, & .rdw-option-wrapper:hover': {
      backgroundColor: theme.colors.ghostWhite,
      border: `1px solid ${theme.colors.lightGrey}`,
      borderRadius: theme.variables.borderRadiusSmall,
      boxShadow: 'none'
    },
    '& .rdw-image-modal-size': {
      display: 'none'
    }
  },

  // Toggle to switch between markdown and editor views
  viewToggle: {
    ...theme.typography.extraExtraSmallText,
    color: theme.colors.ashGrey,
    fill: theme.colors.ashGrey,
    outline: 'none',
    position: 'absolute',
    right: 0,
    '& svg': {
      fontSize: '1.25rem',
      marginBottom: '-0.0625rem',
      paddingRight: '0.25rem',
      verticalAlign: 'bottom'
    }
  },
  viewToggleContainer: {
    height: '1rem',
    position: 'relative'
  },

  wrapper: {
    ...theme.mixins.inputStyle,
    borderWidth: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0
  }
}));

// Parse current content and replace handlebar blocks showing display names
// with the handlebar reference. E.g. Foo 1 => {{foo-1}}
const replaceBlockHandlebarLabelsWithValues = (
  contentBlock: ContentBlock,
  contentState: ContentState
): ContentState => {
  const isHandlebarEntityRange = character => {
    const entityKey = character.getEntity();
    if (
      entityKey != null &&
      contentState.getEntity(entityKey).getType() === HANDLEBAR_ENTITY_NAME
    ) {
      return true;
    }
    return false;
  };
  const blockKey = contentBlock.getKey();
  const instanceLocations = [];
  contentBlock.findEntityRanges(isHandlebarEntityRange, (start, end) => {
    instanceLocations.push({
      start,
      end
    });
  });
  instanceLocations.reverse().forEach(({ start, end }) => {
    const entityKey = contentBlock.getEntityAt(start);
    if (entityKey != null) {
      const entity = contentState.getEntity(entityKey);
      const selection = new SelectionState({
        anchorKey: blockKey,
        anchorOffset: start,
        focusKey: blockKey,
        focusOffset: end
      });
      const inlineStyle = contentBlock.getInlineStyleAt(start);
      contentState = Modifier.replaceText(
        contentState,
        selection,
        entity.data.value,
        inlineStyle,
        null
      );
    }
  });
  return contentState;
};

const selectionStateForBlock = (contentBlock: ContentBlock) => {
  const blockKey = contentBlock.getKey();
  return new SelectionState({
    anchorKey: blockKey,
    focusKey: blockKey,
    focusOffset: 0,
    hasFocus: true
  });
};

// Replace block types that aren't mapped correctly when translating to
// markdown. Currently maps code entities to code-blocks.
const adjustBlockTypeToMarkdown = (
  contentBlock: ContentBlock,
  contentState: ContentState
): ContentState => {
  if (contentBlock.getType() === 'code') {
    const selectionState = selectionStateForBlock(contentBlock);
    contentState = Modifier.setBlockType(
      contentState,
      selectionState,
      'code-block'
    );
  }
  return contentState;
};

// Replace block types that aren't mapped correctly when translating to
// editor state from markdown. Currently maps code-blocks to code entities.
const adjustBlockTypeFromMarkdown = (
  contentBlock: ContentBlock,
  contentState: ContentState
): ContentState => {
  if (contentBlock.getType() === 'code-block') {
    const selectionState = selectionStateForBlock(contentBlock);
    contentState = Modifier.setBlockType(contentState, selectionState, 'code');
    contentState = Modifier.removeInlineStyle(
      contentState,
      selectionState,
      'CODE'
    );
  }
  return contentState;
};

// Parse markdown content and replace handlebar references with blocks that
// show field display names. E.g. {{foo-1}} => Foo 1
const replaceBlockHandlebarValuesWithLabels = (
  contentBlock: ContentBlock,
  contentState: ContentState,
  fieldOptions: Array<FieldSuggestion>
): ContentState => {
  const blockKey = contentBlock.getKey();
  const text = contentBlock.getText();
  // Use exec because IE11 does not support matchAll
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/matchAll#regexp.exec_and_matchall
  const matches = [];
  let match;
  while ((match = HANDLEBAR_REGEX.exec(text)) !== null) {
    matches.push(match);
  }
  matches.reverse().forEach((match: Object) => {
    const value = match[0];
    const start = match.index;
    const end = start + value.length;
    const selection = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: start,
      focusKey: blockKey,
      focusOffset: end
    });
    const matchingField = fieldOptions.find(f => f.id === value);
    const label = matchingField?.display ? matchingField?.display : value;

    contentState = contentState.createEntity(
      HANDLEBAR_ENTITY_NAME,
      'IMMUTABLE',
      { label, value }
    );
    const inlineStyle = contentBlock.getInlineStyleAt(start);
    const entityKey = contentState.getLastCreatedEntityKey();

    contentState = Modifier.replaceText(
      contentState,
      selection,
      label || '',
      inlineStyle,
      entityKey
    );
  });
  return contentState;
};

// Translate raw markdown to draft.js EditorState structure
export const markdownToEditorState = (
  markdown: string,
  fieldOptions: Array<FieldSuggestion>
): EditorState => {
  let contentState = stateFromMarkdown(markdown, {
    parserOptions: { atomicImages: true, breaks: true }
  });
  contentState.getBlocksAsArray().forEach(contentBlock => {
    contentState = replaceBlockHandlebarValuesWithLabels(
      contentBlock,
      contentState,
      fieldOptions
    );
    contentState = adjustBlockTypeFromMarkdown(contentBlock, contentState);
  });
  return EditorState.createWithContent(contentState);
};

// Translate draft.js EditorState structure to raw markdown
export const editorStateToMarkdown = (editorState: EditorState): string => {
  let contentState = editorState.getCurrentContent();
  contentState.getBlocksAsArray().forEach(contentBlock => {
    contentState = replaceBlockHandlebarLabelsWithValues(
      contentBlock,
      contentState
    );
    contentState = adjustBlockTypeToMarkdown(contentBlock, contentState);
  });
  // Pass the `gfm: true` option to make it translate code blocks correctly.
  // This flag stands for "GitHub-Flavored Markdown" and is only currently used
  // for branching whether code blocks are expressed using tabs or ```.
  const markdown = stateToMarkdown(contentState, { gfm: true });
  return (
    markdown
      .trim()
      // Prevent underscores from being escaped with a backslash
      .replace(/\\_/g, '_')
      // Remove non-visible character artifact from markdown translation
      .replace(ZERO_WIDTH_SPACE_CHARACTER, '')
  );
};

const useSelectBlockTypeStyles = makeStyles({
  root: {
    flexBasis: '9rem',
    marginBottom: '0.375rem',
    marginRight: '0.75rem'
  }
});

const SelectBlockType = (props: {
  currentState: { blockType: string },
  onChange: string => mixed
}) => {
  const { currentState, onChange } = props;
  const classes = useSelectBlockTypeStyles();
  const options = [
    {
      label: (
        <FormattedMessage
          defaultMessage="Normal text"
          id="MarkdownInput.blockType.Normal"
        />
      ),
      value: 'Normal'
    },
    {
      label: (
        <FormattedMessage
          defaultMessage="Header 1"
          id="MarkdownInput.blockType.H1"
        />
      ),
      value: 'H1'
    },
    {
      label: (
        <FormattedMessage
          defaultMessage="Header 2"
          id="MarkdownInput.blockType.H2"
        />
      ),
      value: 'H2'
    },
    {
      label: (
        <FormattedMessage
          defaultMessage="Header 3"
          id="MarkdownInput.blockType.H3"
        />
      ),
      value: 'H3'
    },
    {
      label: (
        <FormattedMessage
          defaultMessage="Header 4"
          id="MarkdownInput.blockType.H4"
        />
      ),
      value: 'H4'
    },
    {
      label: (
        <FormattedMessage
          defaultMessage="Header 5"
          id="MarkdownInput.blockType.H5"
        />
      ),
      value: 'H5'
    },
    {
      label: (
        <FormattedMessage
          defaultMessage="Header 6"
          id="MarkdownInput.blockType.H6"
        />
      ),
      value: 'H6'
    },
    {
      label: (
        <FormattedMessage
          defaultMessage="Blockquote"
          id="MarkdownInput.blockType.Blockquote"
        />
      ),
      value: 'Blockquote'
    },
    {
      label: (
        <FormattedMessage
          defaultMessage="Code"
          id="MarkdownInput.blockType.Code"
        />
      ),
      value: 'Code'
    }
  ];

  return (
    <MultiSelectInput
      className={classes.root}
      data-testid="select-blocktype"
      embedded
      isClearable={false}
      isMulti={false}
      onChange={e => onChange(e.currentTarget.value[0])}
      options={options}
      value={
        options.find(o => o.value === currentState?.blockType) || options[0]
      }
    />
  );
};

const useInsertFieldStyles = makeStyles((theme: ThemeType) => ({
  root: {
    flexBasis: '12rem',
    flexGrow: '1',
    marginBottom: '0.375rem',
    maxWidth: '20rem',
    minWidth: '12rem'
  },
  input: {
    '& .MultiSelectInput__placeholder': {
      ...theme.mixins.truncate,
      maxWidth: '100%'
    }
  }
}));

const InsertField = (props: {
  editorState: EditorState,
  onChange: EditorState => mixed,
  fieldOptions: Array<FieldSuggestion>
}) => {
  const { editorState, onChange, fieldOptions } = props;
  const classes = useInsertFieldStyles();
  if (!fieldOptions) {
    return null;
  }
  const parseOptionFromTarget = currentTarget => {
    const fieldName = currentTarget.value[0];
    const matchingField = fieldOptions.find(f => f.id === fieldName);
    const displayName = matchingField?.display
      ? matchingField?.display
      : fieldName;
    return {
      label: displayName,
      value: fieldName
    };
  };
  return (
    <MultiSelectInput
      className={classes.root}
      inputClassName={classes.input}
      data-testid="insert-field"
      embedded
      isClearable={false}
      isMulti={false}
      onChange={e => {
        const option = parseOptionFromTarget(e.currentTarget);

        const contentStateWithEntity = editorState
          .getCurrentContent()
          .createEntity(HANDLEBAR_ENTITY_NAME, 'IMMUTABLE', option);
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        let contentState = editorState.getCurrentContent();
        let selection = editorState.getSelection();

        if (!selection.isCollapsed()) {
          contentState = Modifier.removeRange(
            contentState,
            selection,
            'backward'
          );
          selection = contentState.getSelectionAfter();
        }
        const mentionInsertedContent = Modifier.insertText(
          contentState,
          selection,
          option.label || '',
          null,
          entityKey
        );

        const newEditorState = EditorState.push(
          editorState,
          mentionInsertedContent,
          'insert-characters'
        );
        onChange(newEditorState);
      }}
      options={fieldOptions.map(suggestion => ({
        label: <FieldReferenceListItem {...suggestion} />,
        value: suggestion.id
      }))}
      placeholder={
        <FormattedMessage
          defaultMessage="Insert a Field Reference..."
          id="MarkdownInput.InsertField.label"
        />
      }
      value={null}
    />
  );
};

const makeHandlebarDecorators = classes => {
  const handlebarStrategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === HANDLEBAR_ENTITY_NAME
      );
    }, callback);
  };

  const HandlebarSpan = ({ children }: any) => {
    return <span className={classes.handlebar}>{children}</span>;
  };

  return [
    {
      strategy: handlebarStrategy,
      component: HandlebarSpan
    }
  ];
};

export type MarkdownInputType = {
  className?: string,
  defaultValue: string,
  disabled?: boolean,
  displayErrors?: boolean,
  fieldOptions: Array<FieldSuggestion>,
  hasError?: boolean,
  hasRequired?: boolean,
  id?: string,
  inputClassName?: string,
  name?: string,
  onChange?: ({
    currentTarget: { value: string }
  }) => mixed,
  placeholder?: string,
  readOnly?: boolean,
  required?: boolean,
  uploadFile?: (Array<File>) => Promise<Object>
};

function MarkdownInput(props: MarkdownInputType) {
  const {
    className,
    defaultValue,
    disabled,
    displayErrors,
    fieldOptions: fieldOptionsWithoutBraces,
    hasError,
    hasRequired,
    id,
    inputClassName,
    name: nameProp,
    onChange,
    placeholder,
    readOnly,
    required,
    uploadFile
  } = props;
  const name = nameProp || 'markdown-input';
  const fieldOptions = fieldOptionsWithoutBraces.map(f => ({
    ...f,
    id: `{{${f.id}}}`
  }));
  const classes = useStyles();
  const intl = useIntl();

  const [markdown, setMarkdown] = React.useState(defaultValue);
  const [editorState, setEditorState] = React.useState(
    markdownToEditorState(defaultValue, fieldOptions)
  );
  const [isCodeView, setIsCodeView] = React.useState(false);

  const hasValue =
    typeof markdown === 'string' &&
    markdown.trim().replace(ZERO_WIDTH_SPACE_CHARACTER, '').length > 0;
  return (
    <div className={classNames(classes.root, className)} data-testid={name}>
      {!isCodeView && (
        <Editor
          customDecorators={makeHandlebarDecorators(classes)}
          editorClassName={classes.editor}
          editorState={editorState}
          handlePastedText={(text, html, editorState) => {
            // TODO: Support copying with handlebar references intact instead
            // of copying the display name like we do today.
            // See https://github.com/facebook/draft-js/issues/787#issuecomment-382547599
            let contentState = stateFromMarkdown(text, {
              parserOptions: { atomicImages: true, breaks: true }
            });
            contentState.getBlocksAsArray().forEach(contentBlock => {
              contentState = replaceBlockHandlebarValuesWithLabels(
                contentBlock,
                contentState,
                fieldOptions
              );
            });

            const pastedBlocks = contentState.blockMap;
            const newState = Modifier.replaceWithFragment(
              editorState.getCurrentContent(),
              editorState.getSelection(),
              pastedBlocks
            );
            const newEditorState = EditorState.push(
              editorState,
              newState,
              'insert-fragment'
            );
            setEditorState(newEditorState);
            return true;
          }}
          localization={{
            locale: EDITOR_LOCALE,
            translations: {
              'components.controls.image.byURL': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.URL',
                defaultMessage: 'URL'
              }),
              'components.controls.image.dropFileText': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.DropFileText',
                defaultMessage: 'Upload an image'
              }),
              'components.controls.image.fileUpload': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.FileUpload',
                defaultMessage: 'File Upload'
              }),
              'components.controls.image.image': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.Image',
                defaultMessage: 'Image'
              }),
              'components.controls.inline.bold': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.Bold',
                defaultMessage: 'Bold'
              }),
              'components.controls.inline.italic': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.Italic',
                defaultMessage: 'Italic'
              }),
              'components.controls.link.link': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.Link',
                defaultMessage: 'Link'
              }),
              'components.controls.link.linkTarget': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.LinkTarget',
                defaultMessage: 'Link Target'
              }),
              'components.controls.link.linkTitle': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.LinkTitle',
                defaultMessage: 'Link Title'
              }),
              'components.controls.list.ordered': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.OrderedList',
                defaultMessage: 'Ordered List'
              }),
              'components.controls.list.unordered': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.UnorderedList',
                defaultMessage: 'Unordered List'
              }),
              'components.controls.remove.remove': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.ClearFormatting',
                defaultMessage: 'Clear Formatting'
              }),
              'generic.add': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.Add',
                defaultMessage: 'Add'
              }),
              'generic.cancel': intl.formatMessage({
                id: 'MarkdownInput.Toolbar.Cancel',
                defaultMessage: 'Cancel'
              })
            }
          }}
          onEditorStateChange={editorState => {
            setEditorState(editorState);
            const newMarkdown = editorStateToMarkdown(editorState);
            // Only run this if the user made some change that impacts the
            // markdown. There are cases where the translation to editorState
            // and back to markdown make non-signicant changes (adds a newliine)
            // but those shouldn't make the value dirty or trigger onChange.
            if (newMarkdown !== markdown) {
              setMarkdown(newMarkdown);
              if (onChange) {
                onChange({
                  currentTarget: {
                    value: newMarkdown
                  }
                });
              }
            }
          }}
          placeholder={placeholder}
          readOnly={disabled || readOnly}
          toolbar={{
            options: ['blockType', 'inline', 'list', 'link', 'image', 'remove'],
            blockType: {
              component: SelectBlockType
            },
            remove: {
              icon: clearIconUrl
            },
            inline: {
              bold: { icon: boldIconUrl },
              italic: { icon: italicIconUrl },
              options: ['bold', 'italic']
            },
            image: {
              urlEnabled: true,
              uploadEnabled: !!uploadFile,
              alignmentEnabled: false,
              uploadCallback: value => {
                if (uploadFile) {
                  return uploadFile(value).then(fileInfo => {
                    return {
                      data: {
                        link: fileInfo.url
                      }
                    };
                  });
                }
              },
              previewImage: true,
              icon: imageIconUrl,
              inputAccept: 'image/*',
              alt: { present: false, mandatory: false },
              defaultSize: {
                height: 'auto',
                width: 'auto'
              }
            },
            link: {
              link: { icon: linkIconUrl },
              options: ['link']
            },
            list: {
              options: ['unordered', 'ordered'],
              ordered: { icon: listOrderedIconUrl },
              unordered: { icon: listUnorderedIconUrl }
            }
          }}
          toolbarClassName={classes.toolbar}
          toolbarCustomButtons={
            fieldOptions.length > 0
              ? [
                  // $FlowIgnore
                  <InsertField key="insert-field" fieldOptions={fieldOptions} />
                ]
              : undefined
          }
          wrapperClassName={classNames(
            classes.wrapper,
            {
              [classes.error]:
                hasError || (displayErrors && required && !hasValue)
            },
            inputClassName
          )}
        />
      )}
      {/* Store markdown-encoded body, allow editing raw markdown  */}
      <TextareaInput
        className={classes.markdownContainer}
        data-testid={`${name}-textarea`}
        disabled={disabled || readOnly}
        hasRequired={hasRequired}
        id={id || name}
        inputClassName={classNames(
          classes.markdownEditor,
          {
            [classes.error]:
              hasError || (displayErrors && required && !hasValue),
            [classes.hidden]: !isCodeView
          },

          inputClassName
        )}
        name={name}
        onChange={e => {
          const newMarkdown = e.currentTarget.value;
          const newState = markdownToEditorState(newMarkdown, fieldOptions);
          setMarkdown(newMarkdown);
          setEditorState(newState);
          if (onChange) {
            onChange({
              ...e,
              currentTarget: {
                ...e.currentTarget,
                value: newMarkdown
              }
            });
          }
        }}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        value={markdown}
      />
      {/* Toggle between raw markdown and editor with toolbar */}
      <div className={classes.viewToggleContainer}>
        <button
          className={classes.viewToggle}
          data-testid={`${name}-view-toggle`}
          onClick={e => {
            e.preventDefault();
            setIsCodeView(!isCodeView);
          }}
        >
          <MarkdownGlyph />
          {isCodeView ? (
            <FormattedMessage
              defaultMessage="Show Editor"
              id="MarkdownInput.ViewToggle.Editor"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Show Markdown"
              id="MarkdownInput.ViewToggle.Markdown"
            />
          )}
        </button>
      </div>
    </div>
  );
}
MarkdownInput.displayName = 'MarkdownInput';

export default MarkdownInput;
