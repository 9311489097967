// @flow

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { InputV2, InputConsumer, TextareaInput } from '@catalytic/catalytic-ui';
import { types } from '@catalytic/react-view';
import useFieldContext from './FieldContext';
import { useHandleBlur, useHandleChange } from './ViewHelpers';

const useStyles = makeStyles(theme => ({
  inputElement: theme.typography.monospace
}));

const GroupView = ({ schema: getSchema, setValue, value }: types.GroupView) => {
  const schema = getSchema();
  const fieldContext = useFieldContext();
  const {
    className,
    contextValues,
    description,
    disabled,
    displayName,
    embedded,
    example,
    fieldName,
    hasValidation,
    id,
    name,
    onBlur,
    onChange,
    readOnly,
    required,
    type
  } = fieldContext || {};
  const handleBlur = useHandleBlur({ onBlur, setValue, type });
  const handleChange = useHandleChange({ onChange, setValue, type });
  const classes = useStyles();
  const stringifiedValue =
    value && typeof value === 'object' ? JSON.stringify(value, null, 2) : value;

  return (
    <InputV2
      className={className}
      contextValues={contextValues}
      description={description}
      disabled={disabled}
      example={example}
      fieldName={fieldName}
      hasValidation={hasValidation}
      id={id}
      inputElementClassName={classes.inputElement}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      readOnly={readOnly}
      schema={schema}
      title={displayName}
      type={type}
      value={value}
    >
      <InputConsumer>
        {props => (
          <TextareaInput
            defaultValue={stringifiedValue}
            embedded={embedded}
            id={id}
            name={name}
            required={required}
            {...props}
          />
        )}
      </InputConsumer>
    </InputV2>
  );
};
GroupView.displayName = 'GroupView';

export default React.memo<types.GroupView>(GroupView);
