// @flow

import { type Node } from 'react';
import { compose, lifecycle, withStateHandlers } from 'recompose';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import {
  IntrospectionFragmentMatcher,
  InMemoryCache
} from 'apollo-cache-inmemory';
import { CachePersistor } from 'apollo-cache-persist';
import { createUploadLink } from 'apollo-upload-client';
import Rpath from 'ramda/src/path';
import url from 'url';
import { Viewer } from './BootloaderQueries';
import * as localSchema from '../GraphQL/GraphQLSchema';
import { type Session } from '../Session/SessionContext';
import createSetContextMiddleware, {
  WEBHOOK_ID_CONTEXT_KEY,
  WEBHOOK_NAME_CONTEXT_KEY
} from '../config/SetContextMiddleware';
import createUnauthorizedLink from '../config/OnUnauthorizedLink';
import { getLocalStorage } from '../config/UserSessionLink';
import { VERSION } from '../const/env';
import { LOGOUT } from '../const/path';
import logError from '../utils/logError';

const CACHE_KEY = 'cache';
const VERSION_KEY = 'version';
const getViewerID = Rpath(['data', 'viewer', 'id']);
const storage = getLocalStorage();
const COMMIT_HEADER_KEY = 'x-catalytic-web-commit';
const VERSION_HEADER_KEY = 'x-catalytic-web-version';

export type RenderProps = Session & {
  client?: { query: (options: Object) => Promise<Object> },
  loading?: boolean
};

export type Props = RenderProps & {
  children: (props: RenderProps) => Node
};

// TODO: Type the arguments to this function
const Bootloader = ({
  children,
  cleanSession,
  client,
  isLoggedIn,
  loading,
  logout
}): Node => children({ cleanSession, client, loading, logout, isLoggedIn });

Bootloader.displayName = 'Bootloader';

export default compose(
  // TODO: Type the function returned by `compose()`
  // $FlowIgnore
  withStateHandlers(
    () => {
      const pathname = process.env.CATALYTIC_GRAPHQL_API_PATHNAME || '/graphql';
      const uploadLink = createUploadLink({
        uri:
          process.env.NODE_ENV === 'development'
            ? ({ operationName }) =>
                url.format({ pathname, query: { operationName } })
            : pathname,
        credentials: 'same-origin'
      });

      return {
        cleanSession: () => Promise.resolve(),
        client: undefined,
        isLoggedIn: false,
        loading: true,
        logout: () => Promise.resolve(),
        uploadLink
      };
    },
    {
      load: () => ({ client, logout, isLoggedIn, cleanSession }) => ({
        cleanSession,
        client,
        isLoggedIn,
        loading: false,
        logout
      })
    }
  ),
  lifecycle({
    async componentDidMount() {
      const { isLoggedIn: defaultIsLoggedIn, load, uploadLink } = this.props;

      const schema = await import(
        /* webpackChunkName: "graphql-schema" */ '@catalytic/graphql-api/schema.json'
      );

      const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData: schema
      });

      const cache = new InMemoryCache({
        fragmentMatcher
      });

      // disable caching in test environment
      const persistor =
        process.env.NODE_ENV !== 'test'
          ? new CachePersistor({
              cache,
              key: CACHE_KEY,
              maxSize: 1024 * 1024, // 1048576 (1 MB)
              storage
            })
          : undefined;

      const cleanCache = async () => {
        if (persistor) {
          persistor.pause();
          await persistor.purge();
        }
      };

      const resetCache = async () => {
        await cleanCache();
        if (persistor) {
          await persistor.persist();
        }
      };

      const restoreCache = async () => {
        if (persistor) {
          const cachedVersion = storage.getItem(VERSION_KEY);
          if (cachedVersion === VERSION) {
            try {
              await persistor.restore();
            } catch (e) {
              console.error('Error restoring Apollo cache', e);
              await persistor.purge();
            }
          } else {
            storage.setItem(VERSION_KEY, VERSION);
            await resetCache();
          }
        }
      };

      const cleanSession = async () => {
        await cleanCache();

        // Reset the Appcues session
        if (window.Appcues && typeof window.Appcues.reset === 'function') {
          window.Appcues.reset();
        }
      };

      let isLoggedIn = defaultIsLoggedIn;

      const logout = async () => {
        if (!isLoggedIn) return;
        await cleanSession();
        isLoggedIn = false;
        window.location.href = encodeURI(LOGOUT);
      };

      const unauthorizedLink = createUnauthorizedLink(logout);

      const errorLink = onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message }) => logError(new Error(message)));
        }
      });

      const webhookNameContext = createSetContextMiddleware({
        key: WEBHOOK_NAME_CONTEXT_KEY,
        url: '/form/:name'
      });
      const webhookIDContext = createSetContextMiddleware({
        key: WEBHOOK_ID_CONTEXT_KEY,
        url: '/webform/:id'
      });
      const versionHeaderContext = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            [COMMIT_HEADER_KEY]: process.env.GIT_COMMIT || 'latest',
            [VERSION_HEADER_KEY]: process.env.VERSION || 'latest'
          }
        }));
        return forward(operation);
      });

      const client = new ApolloClient({
        link: ApolloLink.from([
          unauthorizedLink,
          errorLink,
          // Sets context authentication for webhook names
          webhookNameContext,
          // Sets context authentication for webhook IDs
          webhookIDContext,
          versionHeaderContext,
          uploadLink
        ]),
        cache,
        defaultOptions: {
          errorPolicy: 'ignore'
        },
        resolvers: localSchema.resolvers,
        typeDefs: localSchema.typeDefs
      });
      cache.writeData({
        data: localSchema.defaults
      });

      await restoreCache();

      // Locate the cached viewer node
      let cachedViewer;
      try {
        cachedViewer = await client.query({
          fetchPolicy: 'cache-only',
          query: Viewer
        });
      } catch (e) {}
      const cachedViewerID = getViewerID(cachedViewer);

      try {
        // Query the current viewer node
        const viewer = await client.query({
          fetchPolicy: 'network-only',
          query: Viewer
        });
        const viewerID = getViewerID(viewer);

        if (viewer && typeof viewer === 'object') {
          // Verify the cached viewer is the same as the authenticated
          // user
          if (cachedViewerID !== viewerID) {
            await resetCache();
          }
          isLoggedIn = true;
        }
      } catch (e) {
        isLoggedIn = false;
        await cleanSession();
      }

      load({ client, logout, cleanSession, isLoggedIn });
    }
  })
)(Bootloader);
