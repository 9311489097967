// @flow

type Options = { silenceInTest?: boolean, useWarning?: boolean };

const logError = (error: ?Error, options?: Options) => {
  if (
    error &&
    // Silence logging in tests
    (process.env.NODE_ENV !== 'test' || options?.silenceInTest === false)
  ) {
    try {
      if (global.rollbar) {
        window.onerror(error);
      } else {
        if (options?.useWarning === true) {
          console.warn(error);
        } else {
          console.error(error);
        }
      }
    } catch (e) {
      if (options?.useWarning === true) {
        console.warn(error);
      } else {
        console.error(error);
      }
    }
  }
};

export default logError;
