// @flow

import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  branch,
  compose,
  defaultProps,
  renderNothing,
  setDisplayName
} from 'recompose';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';

const DISPLAY_NAME = 'InputRequired';

const Style = (theme: ThemeType) => ({
  root: theme.mixins.inputRequired
});

type Props = InjectSheetProvidedProps & {
  disabled: boolean,
  embedded: boolean,
  hasRequired: boolean,
  intl: Object,
  readOnly: boolean,
  required: boolean
};

class InputRequired extends PureComponent<Props> {
  static displayName = DISPLAY_NAME;

  static defaultProps = {
    disabled: false,
    embedded: false,
    hasRequired: true,
    readOnly: false,
    required: true
  };

  render() {
    const { classes, className } = this.props;
    return (
      <div
        className={classNames(classes.root, className)}
        data-id={`${DISPLAY_NAME}__required`}
      >
        <FormattedMessage
          defaultMessage="Required"
          description="When present, it specifies that an input field must be filled out before submitting the form."
          id="input.required"
        />
      </div>
    );
  }
}

const EnhancedInputRequired = compose(
  injectIntl,
  injectSheet(Style),
  defaultProps({
    disabled: false,
    embedded: false,
    hasRequired: true,
    readOnly: false,
    required: true
  }),
  setDisplayName(DISPLAY_NAME),
  branch(
    ({ disabled, embedded, hasRequired, readOnly, required }) =>
      disabled || embedded || !hasRequired || readOnly || !required,
    renderNothing
  )
)(InputRequired);
EnhancedInputRequired.displayName = 'InputRequired';

export default EnhancedInputRequired;
