// @flow

import * as React from 'react';
import { branch, compose } from 'recompose';
import map from 'ramda/src/map';
import pathOr from 'ramda/src/pathOr';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  AsyncPaginate,
  SmallText,
  type OptionType,
  type ValueType
} from '@catalytic/catalytic-ui';
import {
  PredictiveModelQuery,
  PredictiveModelsQuery
} from './PredictiveModelTypeDefs';
import { fieldValueIsEmpty } from '../Field/FieldHelpers';
import { type FieldPredictiveModel } from '../Field/FieldTypes';
import TopBarLoader from '../Loading/TopBarLoader';
import { graphql, withApollo } from 'react-apollo';
import { WAIT as DEBOUNCE_WAIT } from '../const/debounce';
import selectQueryPolicies from '../const/selectQueryPolicies';

export const toSelectOption = (
  predictiveModel: FieldPredictiveModel
): OptionType => ({
  label: predictiveModel.displayName,
  value: predictiveModel.id
});

type Props = {
  className?: string,
  client: { query: (options: Object) => Promise<Object> },
  defaultValue?: ValueType | string,
  error?: Object,
  intl: Object,
  loading?: boolean,
  name?: string,
  predictiveModel?: FieldPredictiveModel,
  required?: boolean
};

export function PredictiveModelSelect({
  className,
  client,
  defaultValue: defaultValueProp,
  error,
  intl,
  loading,
  predictiveModel,
  required,
  ...other
}: Props) {
  if (loading) {
    return (
      <div className={className}>
        <TopBarLoader />
        <SmallText>
          <FormattedMessage id="field.loading" defaultMessage="Loading..." />
        </SmallText>
      </div>
    );
  }

  const defaultValue = predictiveModel
    ? toSelectOption(predictiveModel)
    : fieldValueIsEmpty(defaultValueProp)
    ? undefined
    : { label: defaultValueProp, value: defaultValueProp };

  const loadOptions = async (query, loadedOptions) => {
    const after = loadedOptions?.length;
    let response;
    try {
      response = await client.query({
        errorPolicy: 'all',
        query: PredictiveModelsQuery,
        variables: {
          after: typeof after === 'number' ? String(after) : after
        }
      });
    } catch (error) {
      response = {};
    }
    return {
      options: compose(
        map(toSelectOption),
        pathOr([], ['data', 'search_v2', 'nodes'])
      )(response),
      // eslint-disable-next-line camelcase
      hasMore: response?.data?.search_v2?.pageInfo.hasNextPage || false
    };
  };

  return (
    <AsyncPaginate
      className={className}
      debounceTimeout={DEBOUNCE_WAIT}
      defaultOptions
      defaultValue={defaultValue}
      isClearable={!required}
      isSearchable={false}
      loadOptions={loadOptions}
      placeholder={intl.formatMessage({
        id: 'predictiveModel.select.placeholder',
        defaultMessage: 'Select a Predictive Model...'
      })}
      required={required}
      {...other}
    />
  );
}
PredictiveModelSelect.displayName = 'PredictiveModelSelect';

type EnhancedProps = {
  className?: string,
  defaultValue?: ValueType | string,
  name?: string
};

const EnhancedPredictiveModelSelect: React.ComponentType<EnhancedProps> = compose(
  withApollo,
  // If a default value is provided to the input, fetch the predictive model display name.
  branch(
    ({ defaultValue }) => {
      return defaultValue && typeof defaultValue === 'string';
    },
    graphql(PredictiveModelQuery, {
      options: ({ defaultValue }) => {
        return {
          ...selectQueryPolicies,
          variables: { id: defaultValue }
        };
      },
      props: ({ data }) => {
        const { loading, error, predictiveModel } = data || {};

        return { loading, error, predictiveModel };
      }
    })
  ),
  injectIntl
)(PredictiveModelSelect);

export default EnhancedPredictiveModelSelect;
