// @flow

import React from 'react';
import { withRouter, type ContextRouter } from 'react-router-dom';
import { compose } from 'recompose';
import TopBarProgress from 'react-topbar-progress-indicator';
import withAnalytics, {
  type Analytics,
  DEFAULT_ANALYTICS
} from '../Analytics/withAnalytics';

// When `window` is undefined `config` is a no-op that accepts no parameters
// $FlowIgnore
TopBarProgress.config({
  barColors: {
    '0': '#42E0D0',
    '0.25': '#28A3A3',
    '0.50': '#00A5F7',
    '0.75': '#0084C5',
    '1.0': '#AF79BD'
  },
  barThickness: 3,
  shadowBlur: 0,
  shadowColor: 'transparent'
});

type Props = ContextRouter & {
  analytics: Analytics
};

export const TopBarLoader = ({ analytics, location }: Props) => {
  React.useEffect(() => {
    const now = Date.now();

    return () => {
      if (typeof analytics?.track === 'function') {
        analytics.track('topBarLoader', {
          category: 'LOADING',
          label: location?.pathname || '/',
          value: Math.max(0, Date.now() - now)
        });
      }
    };
  });

  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // Do not display loader for iPad, iPhone, or iPod.
  if (iOS) {
    return null;
  }

  return <TopBarProgress />;
};
TopBarLoader.displayName = 'TopBarLoader';
TopBarLoader.defaultProps = {
  analytics: DEFAULT_ANALYTICS
};

export default compose(withAnalytics, withRouter)(TopBarLoader);
