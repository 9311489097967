// @flow

import classNames from 'classnames';
import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';

const Style = (theme: ThemeType) => {
  return {
    root: {
      ...theme.typography.baseText,
      ...theme.mixins.breakWord,
      color: theme.colors.black,
      marginBottom: '.1rem',
      minWidth: 0,
      width: '100%',
      [theme.breakpoints.desktop]: {
        width: '85%'
      }
    }
  };
};

type Props = InjectSheetProvidedProps & HTMLDivElement;

const ListItemBody = ({ classes, className, theme, ...props }: Props): Node => {
  return (
    <div
      className={classNames(classes.root, className)}
      data-testid="listItemBody"
      {...props}
    />
  );
};

ListItemBody.displayName = 'ListItemBody';
export default injectSheet(Style)(ListItemBody);
