// @flow

import classNames from 'classnames';
import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import chevronGlyphUrl from '@catalytic/catalytic-icons/lib/glyphs/chevron.svg';
import { SmallText } from '../../Text/Text';

const Style = (theme: ThemeType) => {
  return {
    root: {
      '& a': {
        marginLeft: 'auto',
        fontWeight: 600,
        color: theme.colors.porpoise,
        paddingRight: '1rem',
        background: `url(${chevronGlyphUrl}) 100% 100% no-repeat`,
        backgroundSize: '1rem'
      }
    }
  };
};

type Props = InjectSheetProvidedProps & HTMLElement;

const ListTitleLink = ({
  classes,
  className,
  theme,
  ...props
}: Props): Node => (
  <SmallText className={classNames(classes.root, className)} {...props} />
);

ListTitleLink.displayName = 'ListTitleLink';
export default injectSheet(Style)(ListTitleLink);
