// @flow

import withAnalytics, {
  type Analytics,
  DEFAULT_ANALYTICS
} from './withAnalytics';

type Props = {|
  action: string,
  analytics: Analytics,
  category?: string,
  label?: string,
  value?: any
|};

const Track = ({ action, analytics, category, label, value }: Props) => {
  if (typeof analytics?.track === 'function') {
    analytics.track(action, { category, label, value });
  }
  return null;
};
Track.defaultProps = {
  analytics: DEFAULT_ANALYTICS
};

type EnhancedProps = {|
  action: string,
  category?: string,
  label?: string,
  value?: any
|};

export default (withAnalytics(Track): React.ComponentType<EnhancedProps>);
