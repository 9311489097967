// @flow

import { getFieldValue, type FieldSuggestion } from '@catalytic/catalytic-ui';
import { JSONRefType } from '@catalytic/json-schema-validator-catalytic-web';
import { format, isURI } from '@catalytic/uri';
import { type Field, type FieldContext, type Inputs } from './FieldTypes';
import { getTypeLabelForField } from '../Field/FieldConfigurationHelpers';
import { NODE_TYPE, type NodeType } from '../shared/NodeTypes';
import { type FieldWithContext, type Process } from '../Process/ProcessTypes';
import { appIDIsAnnotationApp } from '../Step/StepHelpers';
import { type Step } from '../Step/StepTypes';
import isEmptyFile from '../utils/isEmptyFile';
import isUUID from '../utils/uuid';

export const FIELD_PREFIX = 'field';
export const FIELD_PREFIX_DIVIDER = '-';

export const fieldValueForKey = (
  formData: FormData,
  key: string,
  type?: string
): any => {
  const values = formData ? formData.getAll(key) : [];

  return getFieldValue(values, type);
};

export const fieldValueIsEmpty = (value: any): boolean => {
  return value === undefined || value === null || value === '';
};

/*
 * Data source of truth priority order:
 * 1. Field value specified by the user
 * 2. Field value specified by session data
 * 3. Field value specified by a URL parameter
 * 4. Field value specified by the field's default value
 * https://github.com/catalyticlabs/triage/issues/5092
 */
type Props = {
  formData: FormData,
  inputs: Array<Field>,
  params?: URLSearchParams,
  processID?: string
};

export const getFieldInputs = ({
  formData,
  inputs,
  params,
  processID
}: Props): Inputs => {
  const keys = formData ? Array.from(formData.keys()) : [];
  const formInputs = (inputs || []).filter(
    ({ name, schema, value: defaultValue }) => {
      return (
        // Include fields based on inputs within the form.
        keys.includes(name) ||
        // Include hidden "Table" and "Multiple File" fields,
        // so that we can copy their table references.
        // https://github.com/catalyticlabs/triage/issues/8292
        (schema?.refType === JSONRefType.DATA_TABLE &&
          defaultValue &&
          isURI(defaultValue))
      );
    }
  );

  return [
    // Get input values
    ...formInputs.map(
      ({ id, name, schema, type, value: defaultValue }: Field) => {
        // Field value specified by the user via form data
        const value = fieldValueForKey(formData, name, type);

        // Cleared file inputs retain a value that is an empty file blob
        // identified by having a name equal to an empty string and size
        // attribute equal to zero. We do not want to upload these empty file
        // blobs. Use a empty string value instead so it overrides any default value.
        if (isEmptyFile(value)) {
          return { id, value: '' };
        }

        // https://github.com/catalyticlabs/triage/issues/8078
        if (typeof value === 'boolean') {
          return { id, value: String(value) };
        }

        // https://github.com/catalyticlabs/triage/issues/8292
        if (
          schema?.refType === JSONRefType.DATA_TABLE &&
          defaultValue &&
          isURI(defaultValue) &&
          value === null
        ) {
          return { id, value: defaultValue };
        }

        return { id, value };
      }
    ),
    // Get param values
    ...(params && processID && isUUID(processID)
      ? Array.from(params)
      : []
    ).reduce((accumulator, [key, value]) => {
      const [prefix, ...rest] = key.split(FIELD_PREFIX_DIVIDER);
      const name = rest.join(FIELD_PREFIX_DIVIDER);

      // Field value specified by a URL parameter
      if (
        prefix === FIELD_PREFIX &&
        !formInputs.some((input: Field) => input.name === name)
      ) {
        accumulator.push({
          id: format({
            context: processID,
            contextType: 'process',
            id: name,
            type: 'field'
          }),
          value
        });
      }

      return accumulator;
    }, [])
  ];
};

export const formatSelectionFieldValue = (
  value: any
): Array<{ label: string, value: any }> => {
  // If field value is empty, return an empty array (No options).
  if (fieldValueIsEmpty(value) || Number.isNaN(value)) {
    return [];
  }

  // Convert value to a unique array of option(s) expected by the selection field.
  if (Array.isArray(value)) {
    return Array.from(new Set(value))
      .filter(value => !Number.isNaN(value))
      .map(value => ({ label: String(value), value }));
  }

  return [{ label: String(value), value }];
};

export const getFileUrl = (id: string): string => {
  return id && typeof id === 'string' ? `/api/files/${id}/download` : id;
};

export const buildFieldSuggestions = ({
  fieldsByContext,
  intl
}: {
  fieldsByContext: Array<FieldContext>,
  intl: Object
}): Array<FieldSuggestion> => {
  const mappings = buildFieldSourceMappings(fieldsByContext);
  return mappings
    .map(mapping => {
      const { name: id, displayName: display } = mapping.field;
      const source = sourceForField(mapping || {}, intl);
      const type = getTypeLabelForField(mapping.field);
      return {
        id,
        display: display || id || '',
        source,
        type
      };
    })
    .sort((a, b) => a.display.localeCompare(b.display));
};

// Creates field suggestions from process, all steps, and some runtime fields
export const buildFieldOptions = ({
  fieldsByContext,
  intl
}: {
  fieldsByContext: Array<FieldContext>,
  intl: Object
}): Array<FieldSuggestion> => {
  return buildFieldSuggestions({
    fieldsByContext,
    intl
  }).filter(f => f.source);
};

type FieldSourceMap = {
  processLevelSources: Array<string>,
  runLevelSource?: boolean,
  field: Field,
  stepSources: Array<string>,
  triggerSources: Array<string>
};

const buildFieldSourceMappings = (
  fieldsByContext: Array<FieldContext>
): Array<FieldSourceMap> => {
  const mappings = {};

  fieldsByContext.forEach(context => {
    context.fields.forEach(f => {
      if (!mappings[f.id]) {
        mappings[f.id] = { field: f };
      }
      if (context.__typename === NODE_TYPE.CONTEXT_TYPE) {
        if (!mappings[f.id].processLevelSources) {
          mappings[f.id].processLevelSources = [];
        }
        mappings[f.id].processLevelSources.push(context.displayName);
      } else if (context.__typename === NODE_TYPE.CONTEXT) {
        mappings[f.id].runLevelSource = true;
      } else if (context.__typename === NODE_TYPE.STEP) {
        if (!mappings[f.id].stepSources) {
          mappings[f.id].stepSources = [];
        }
        mappings[f.id].stepSources.push(context.displayName);
      } else if (context.__typename === NODE_TYPE.TRIGGER) {
        if (!mappings[f.id].triggerSources) {
          mappings[f.id].triggerSources = [];
        }
        mappings[f.id].triggerSources.push(context.displayName);
      }
    });
  });

  // $FlowIgnore
  return Object.values(mappings);
};

const sourceForField = (mapping: FieldSourceMap, intl: Object): string => {
  const {
    processLevelSources = [],
    runLevelSource,
    stepSources = [],
    triggerSources = []
  } = mapping;
  const specificSources = triggerSources
    .concat(processLevelSources)
    .concat(stepSources);

  if (runLevelSource) {
    return intl.formatMessage({
      id: 'stepDetailView.fieldsource.instance',
      defaultMessage: 'From the Instance'
    });
  }
  if (specificSources.length) {
    return intl.formatMessage(
      {
        id: 'stepDetailView.fieldsource.specificSources',
        defaultMessage: 'From {sources}'
      },
      {
        sources: specificSources.join(', ')
      }
    );
  }
  return '';
};

export const fieldsWithContext = ({
  contextID,
  contextNodeType,
  fields
}: {
  contextID: ?string,
  contextNodeType: ?NodeType,
  fields: ?Array<Field>
}): Array<Field> => {
  return (fields || []).map(f => ({
    ...f,
    context:
      contextID && contextNodeType
        ? { id: contextID, __typename: contextNodeType }
        : null
  }));
};

export const listFieldsByContext = ({
  process
}: {
  process?: Process
}): Array<FieldContext> => {
  const parentSteps = process?.context?.steps?.nodes || [];
  const steps = process?.steps?.nodes || [];
  return listAvailableFieldsByContext({
    fieldsWithContext: process?.fieldsWithContext?.nodes || [],
    steps: parentSteps.concat(steps)
  });
};

export const contextDisplayName = ({
  displayName,
  type
}: {
  displayName: string,
  type: string
}): string => {
  if (type === 'STEP') {
    return displayName.endsWith(' (TEST)')
      ? displayName.slice(0, -7)
      : displayName;
  }
  return displayName;
};

export const contextKey = (
  context: {
    displayName: string,
    type: string
  },
  field: Field
): string => {
  const displayName = contextDisplayName(context);
  const nodeType = contextNodeType(context);
  if (nodeType === NODE_TYPE.STEP) {
    const fieldProcessID = (field?.id || '').split(':')[2] || '';
    return `${nodeType}:${fieldProcessID}:${displayName}`;
  }
  return `${nodeType}:${displayName}`;
};

const stepContextKey = (step: Step): string => {
  const stepProcessID = (step?.id || '').split(':')[2] || '';
  return `${NODE_TYPE.STEP}:${stepProcessID}:${step?.displayName}`;
};

export const flatFieldList = ({
  fieldsByContext,
  includeRuntimeFields
}: {
  fieldsByContext: Array<FieldContext>,
  includeRuntimeFields: boolean
}): Array<Field> => {
  const fields = {};
  fieldsByContext.forEach(context => {
    if (!includeRuntimeFields && context.__typename === NODE_TYPE.CONTEXT) {
      return;
    }
    context.fields.forEach(field => {
      if (!includeRuntimeFields && field.runtime) {
        return;
      }
      if (!fields[field.id]) {
        fields[field.id] = field;
      }
    });
  });
  // $FlowIgnore
  return Object.values(fields);
};

const contextTypeMapping = {
  STEP: NODE_TYPE.STEP,
  GLOBAL: NODE_TYPE.CONTEXT,
  CONTEXT_TYPE: NODE_TYPE.CONTEXT_TYPE,
  TASK: NODE_TYPE.STEP,
  TRIGGER: NODE_TYPE.TRIGGER
};

export const contextNodeType = ({ type }: { type: string }): string => {
  return contextTypeMapping[type] || NODE_TYPE.CONTEXT;
};

export const contextID = ({
  id,
  type
}: {
  id: string,
  type: string
}): string => {
  if (type === 'GLOBAL') {
    return 'instanceFields';
  }
  return id;
};

export const sortContexts = (
  contexts: Array<FieldContext>
): Array<FieldContext> => {
  return [
    ...contexts.filter(c => c.__typename === NODE_TYPE.CONTEXT_TYPE),
    ...contexts.filter(c => c.__typename === NODE_TYPE.CONTEXT),
    ...contexts.filter(c => c.__typename === NODE_TYPE.TRIGGER),
    ...contexts.filter(c => c.__typename === NODE_TYPE.STEP)
  ];
};

const emptyContextForStep = (step: Step) => ({
  displayName: step.displayName,
  id: step.id,
  __typename: NODE_TYPE.STEP,
  fields: []
});

export const listAvailableFieldsByContext = ({
  fieldsWithContext,
  steps
}: {
  fieldsWithContext: Array<FieldWithContext>,
  steps: Array<Step>
}): Array<FieldContext> => {
  const combinedContexts = {};
  const stepContexts = {};
  const stepDisplayNameToStepContextKey = {};

  const filteredSteps = (steps || []).filter(
    s => !appIDIsAnnotationApp(s.appID)
  );

  filteredSteps.forEach(step => {
    const key = stepContextKey(step);
    stepDisplayNameToStepContextKey[step.displayName] = key;
    stepContexts[key] = emptyContextForStep(step);
  });

  fieldsWithContext.forEach(({ field, contexts }) => {
    contexts.forEach(context => {
      const key = contextKey(context, field);
      const displayName = contextDisplayName(context);
      const nodeType = contextNodeType(context);

      // Track step contexts separately so we can easily map to step order
      if (nodeType === NODE_TYPE.STEP) {
        if (stepContexts[key]) {
          stepContexts[key].fields.push(field);
          return;
        }
        // If a field doesn't match an exact process/step context
        // (likely a runtime field), try to match its step display
        // name against one of the ones in the process
        const matchedStepContextKey =
          stepDisplayNameToStepContextKey[displayName];
        if (stepContexts[matchedStepContextKey]) {
          stepContexts[matchedStepContextKey].fields.push(field);
        }
      } else {
        if (!combinedContexts[key]) {
          combinedContexts[key] = {
            displayName,
            id: contextID(context),
            fields: [],
            __typename: nodeType
          };
        }
        combinedContexts[key].fields.push(field);
      }
    });
  });

  const sortedStepContexts = filteredSteps.map(
    s => stepContexts[stepContextKey(s)]
  );

  return sortContexts(
    // $FlowIgnore
    Object.values(combinedContexts).concat(sortedStepContexts)
  );
};

const OUTPUT_FIELD_DELIMITER = '--';
const WEBFORM_ACTION_NAME = 'web-form';
const WEBFORM_FIELD_NAMES = [
  'to',
  'email-bounced',
  'bounced-email-address',
  'bounce-message',
  'bounce-type',
  'web-form-id',
  'web-form-link'
];

const isWebformAction = (appID: ?string) =>
  (appID || '').split('/')[1] === WEBFORM_ACTION_NAME;

const isWebformField = (field: Field) => {
  if (field.name.indexOf(OUTPUT_FIELD_DELIMITER) < 1) {
    return false;
  }
  const namePieces = field.name.split(OUTPUT_FIELD_DELIMITER);
  const unprefixed = namePieces[namePieces.length - 1];
  return WEBFORM_FIELD_NAMES.indexOf(unprefixed) !== -1;
};

const isGenerated = (field: Field) => field.generated;

export const includeFieldOnForm = (field: Field, appID: ?string) =>
  !(isWebformAction(appID) && isGenerated(field) && isWebformField(field));
