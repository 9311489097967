// @flow

import classNames from 'classnames';
import React from 'react';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import { Header4 } from '../../Text/Text';

const Style = (theme: ThemeType) => {
  return {
    root: {
      ...theme.typography.header4,
      color: theme.colors.black,
      fontWeight: 600,
      '& small': {
        ...theme.typography.header6,
        color: theme.colors.slateGrey,
        display: 'inline-block'
      },
      [theme.breakpoints.gte.tablet.portrait]: {
        overflowX: 'hidden',
        maxWidth: '14rem',
        height: '100%',
        whiteSpace: 'nowrap'
      }
    }
  };
};

type Props = InjectSheetProvidedProps & HTMLElement;

const InfoBarItemDetails = ({ classes, className, theme, ...props }: Props) => (
  <Header4 className={classNames(classes.root, className)} {...props} />
);

InfoBarItemDetails.displayName = 'InfoBarItemDetails';

export default injectSheet(Style)(InfoBarItemDetails);
