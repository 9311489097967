// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { branch, compose, renderNothing } from 'recompose';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Menu, MenuItem, NavButton } from '@catalytic/catalytic-ui';
import { ReactComponent as OverflowGlyph } from '@catalytic/catalytic-icons/lib/glyphs/overflow.svg';
import { withPredictiveModels, withModuleManagement } from '../Feature';
import {
  ADMIN_TEAM_CENTER as ADMIN_TEAM_CENTER_PATH,
  APP_DISCOVER as APP_DISCOVER_PATH,
  DASHBOARD as DASHBOARD_PATH,
  INSTANCES as INSTANCES_PATH,
  PREDICTIVE_MODELS as PREDICTIVE_MODELS_PATH,
  TABLES as TABLES_PATH,
  USERS as USERS_PATH
} from '../const/path';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    '&:hover, &:focus, &:active': {
      '& $button': {
        color: theme.colors.battleshipGrey
      }
    }
  },
  button: {}
}));

type Props = {
  isTeamAdmin: boolean,
  isTeamOwner: boolean,
  moduleManagement?: boolean,
  predictiveModels?: boolean
};

export const NavbarApp = ({
  isTeamAdmin,
  isTeamOwner,
  moduleManagement,
  predictiveModels
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Menu
      opener={
        // TODO: Remove `tabIndex` when Menu component supports buttons as openers
        <NavButton className={classes.button} tabIndex={-1}>
          <OverflowGlyph />
        </NavButton>
      }
      openerClassName={classes.buttonContainer}
      zIndex={theme.zIndex.nav}
    >
      <MenuItem>
        <Link to={INSTANCES_PATH}>
          <FormattedMessage
            id="navbarApp.instances"
            defaultMessage="Instances"
          />
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to={TABLES_PATH}>
          <FormattedMessage id="navbarApp.data" defaultMessage="Tables" />
        </Link>
      </MenuItem>
      {(isTeamAdmin || isTeamOwner) && (
        <MenuItem>
          <Link to={DASHBOARD_PATH}>
            <FormattedMessage
              id="navbarApp.dashboard"
              defaultMessage="Dashboard"
            />
          </Link>
        </MenuItem>
      )}
      <MenuItem>
        <Link to={APP_DISCOVER_PATH}>
          <FormattedMessage
            id="navbarApp.discoverActions"
            defaultMessage="Discover Actions"
          />
        </Link>
      </MenuItem>
      {((moduleManagement && predictiveModels) || !moduleManagement) && (
        <MenuItem>
          <Link to={PREDICTIVE_MODELS_PATH}>
            <FormattedMessage
              id="navbarApp.predictiveModels"
              defaultMessage="Predictive Models"
            />
          </Link>
        </MenuItem>
      )}
      <MenuItem>
        <Link to={USERS_PATH}>
          <FormattedMessage id="navbarApp.team" defaultMessage="Team" />
        </Link>
      </MenuItem>
      {(isTeamAdmin || isTeamOwner) && (
        <MenuItem>
          <Link to={ADMIN_TEAM_CENTER_PATH}>
            <FormattedMessage id="navbarApp.admin" defaultMessage="Admin" />
          </Link>
        </MenuItem>
      )}
    </Menu>
  );
};
NavbarApp.displayName = 'NavbarApp';

export default compose(
  withModuleManagement,
  branch(
    ({ moduleManagement }) => typeof moduleManagement === 'boolean',
    branch(({ moduleManagement }) => moduleManagement, withPredictiveModels),
    renderNothing
  )
)(NavbarApp);
