// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Spinner from '../../Component/Spinner/Spinner';
import { type ThemeType } from '../../style/ThemeTypes';

const BUTTON = 'button';

const useStyles = makeStyles((theme: ThemeType) => {
  const disabledStyles = {
    borderColor: theme.colors.lightGrey,
    backgroundColor: theme.colors.lightGrey,
    color: theme.colors.battleshipGrey,
    cursor: 'default' // not-allowed
  };
  const paddingRight = 16;
  const paddingLeft = 16;
  const marginRight = 8;

  return {
    root: {
      ...theme.typography.baseText,
      ...theme.mixins.truncate,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      verticalAlign: 'middle',
      paddingTop: '.375rem',
      paddingRight: theme.functions.toRem(paddingRight),
      paddingBottom: '.375rem',
      paddingLeft: theme.functions.toRem(paddingLeft),
      border: `1px solid ${theme.colors.pastelGrey}`,
      borderRadius: theme.variables.borderRadiusSmall,
      backgroundColor: theme.colors.white,
      color: theme.colors.black,
      fontWeight: 500,
      cursor: 'pointer',
      '&:active, &:hover': {
        opacity: 1
      },
      '&$disabled': disabledStyles,
      '&:disabled': {
        cursor: 'default', // not-allowed
        '& [mask]': {
          fill: disabledStyles.color
        }
      }
    },
    icon: {
      ...theme.typography.baseText,
      display: 'flex',
      marginRight: theme.functions.toRem(marginRight),
      fontSize: theme.functions.toRem(theme.variables.icon),
      '& [mask]': {
        fill: theme.colors.white
      }
    },
    children: {
      ...theme.mixins.truncate
    },
    compact: {
      ...theme.typography.smallText,
      borderRadius: theme.variables.borderRadiusSmall,
      paddingTop: '.125rem',
      paddingBottom: '.125rem'
    },
    compactIcon: {
      fontSize: '1rem'
    },
    colorScheme: {
      ...theme.mixins.colorScheme,
      '&$disabled': disabledStyles
    },
    invertScheme: {
      ...theme.mixins.invertScheme,
      '& [mask]': {
        fill: theme.colors.slateGrey
      },
      '&$disabled': {
        ...disabledStyles,
        '& [mask]': {
          fill: disabledStyles.color
        }
      }
    },
    disabled: {},
    usesLoading: {
      // Account for half of Spinner width and margin
      paddingLeft: theme.functions.toRem(
        paddingLeft + parseInt(theme.variables.icon) / 2 + marginRight / 2
      ),
      // Account for other half of Spinner width and margin
      paddingRight: theme.functions.toRem(
        paddingRight + parseInt(theme.variables.icon) / 2 + marginRight / 2
      )
    },
    loading: {
      paddingLeft: theme.functions.toRem(paddingLeft), // Reset padding-left when loading
      paddingRight: theme.functions.toRem(paddingRight) // Reset padding-right when loading
    }
  };
});

type Props = {
  children: string | React.Element<typeof FormattedMessage>,
  className?: string,
  colorScheme?: string,
  compact?: boolean,
  component: React.ElementType,
  disabled?: boolean,
  displayErrors?: boolean,
  hasError?: boolean,
  hasValidation?: boolean,
  icon?: React.Node,
  iconClassName?: string,
  inputClassName?: string,
  inputRef?: React.ElementRef<*>,
  invertScheme?: boolean,
  loading?: boolean,
  setDisplayErrorsState?: (displayErrors: boolean) => mixed,
  setLoadingState?: (loading: boolean) => mixed,
  theme?: ThemeType,
  type?: 'button' | 'reset' | 'submit'
};

export const ButtonInput = ({
  children,
  className,
  colorScheme,
  compact,
  component: Component,
  disabled,
  displayErrors,
  hasError,
  hasValidation,
  icon,
  iconClassName,
  inputClassName,
  inputRef: ref,
  invertScheme,
  loading,
  setDisplayErrorsState,
  setLoadingState,
  theme,
  type,
  ...props
}: Props) => {
  const classes = useStyles();

  return (
    <Component
      className={classNames(
        classes.root,
        {
          [invertScheme ? classes.invertScheme : classes.colorScheme]: true,
          [classes.compact]: compact,
          [classes.disabled]: disabled,
          [classes.usesLoading]:
            (loading === true || loading === false) && !icon,
          [classes.loading]: loading && !icon
        },
        className
      )}
      data-color={colorScheme || undefined}
      disabled={disabled || loading}
      type={Component === BUTTON ? type : undefined}
      ref={ref}
      {...props}
    >
      {(icon || loading) && (
        <span
          className={classNames(
            classes.icon,
            {
              [classes.compactIcon]: compact
            },
            iconClassName
          )}
        >
          {loading ? <Spinner /> : icon}
        </span>
      )}
      <span className={classes.children}>{children}</span>
    </Component>
  );
};
ButtonInput.displayName = 'ButtonInput';
ButtonInput.defaultProps = {
  component: BUTTON,
  type: BUTTON
};

export default ButtonInput;
