// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import { Header6 } from '../../Text/Text';

const Style = (theme: ThemeType) => {
  return {
    root: {
      textTransform: 'uppercase',
      color: theme.colors.battleshipGrey,
      paddingBottom: '.75rem',
      fontWeight: 700,
      borderBottom: `1px solid ${theme.colors.lightGrey}`
    }
  };
};

type Props = InjectSheetProvidedProps & {
  children: Node
};

const PaneTitle = ({ classes, className, theme, ...props }: Props): Node => (
  <Header6 className={classNames(classes.root, className)} {...props} />
);

PaneTitle.displayName = 'PaneTitle';
export default injectSheet(Style)(PaneTitle);
