// @flow

export const ASH_GREY = 'ashGrey';
export const ASH_GREY_ALPHA = 'ashGreyAlpha';
export const BATTLESHIP_GREY = 'battleshipGrey';
export const BATTLESHIP_GREY_ALPHA = 'battleshipGreyAlpha';
export const BLACK = 'black';
export const BLUE_GREY = 'blueGrey';
export const BLUE_GREY_ALPHA = 'blueGreyAlpha';
export const CANARY = 'canary';
export const CERULEAN = 'cerulean';
export const DENIM = 'denim';
export const DULL_RED = 'dullRed';
export const GHOST_WHITE = 'ghostWhite';
export const GHOST_WHITE_ALPHA = 'ghostWhiteAlpha';
export const HEATHER = 'heather';
export const HIGHLIGHT = 'highlight';
export const HIGHLIGHT_ALPHA = 'highlightAlpha';
export const HIGHLIGHT_BORDER = 'highlightBorder';
export const LIGHT_GREY = 'lightGrey';
export const LIGHT_GREY_ALPHA = 'lightGreyAlpha';
export const MALIBU = 'malibu';
export const MINT = 'mint';
export const NAV_BACKGROUND = 'navBackground';
export const NAVY_GREEN = 'navyGreen';
export const OLIVE = 'olive';
export const PASTEL_GREY = 'pastelGrey';
export const PASTEL_GREY_ALPHA = 'pastelGreyAlpha';
export const PEBBLE_GREY = 'pebbleGrey';
export const PEBBLE_GREY_ALPHA = 'pebbleGreyAlpha';
export const PORPOISE = 'porpoise';
export const SLATE_GREY = 'slateGrey';
export const SLATE_GREY_ALPHA = 'slateGreyAlpha';
export const SCIENCE_BLUE = 'scienceBlue';
export const TANGERINE = 'tangerine';
export const TEXT_LINK = 'textLink';
export const WHITE = 'white';
export const WHITE_ALPHA = 'whiteAlpha';

export const COLORS = {
  [CANARY]: '#f5af34',
  [CERULEAN]: '#00a5f7',
  [DENIM]: '#0c7bb9',
  [DULL_RED]: '#B01313',
  [HEATHER]: '#af79bd',
  [MALIBU]: '#66ccff',
  [MINT]: '#33cccc',
  [NAVY_GREEN]: '#3b6709',
  [OLIVE]: '#8fc056',
  [PORPOISE]: '#545874',
  [SCIENCE_BLUE]: '#0052CC',
  [TANGERINE]: '#ff894f'
};

export const ASH_GREY_OPACITY = '.298';
export const BATTLESHIP_GREY_OPACITY = '.4745';
export const GHOST_WHITE_OPACITY = '.0235';
export const LIGHT_GREY_OPACITY = '.0784';
export const PASTEL_GREY_OPACITY = '.2';
export const PEBBLE_GREY_OPACITY = '.7412';
export const SLATE_GREY_OPACITY = '.698';
export const WHITE_OPACITY = '.5';

const GREYS = {
  [ASH_GREY]: '#b3b3b3',
  [ASH_GREY_ALPHA]: `rgba(0, 0, 0, ${ASH_GREY_OPACITY})`,
  [BATTLESHIP_GREY]: '#868686',
  [BATTLESHIP_GREY_ALPHA]: `rgba(0, 0, 0, ${BATTLESHIP_GREY_OPACITY})`,
  [BLACK]: '#000000',
  [BLUE_GREY]: '#f2f3f6',
  [BLUE_GREY_ALPHA]: 'rgba(0, 20, 78, .05)',
  [GHOST_WHITE]: '#f9f9f9',
  [GHOST_WHITE_ALPHA]: `rgba(0, 0, 0, ${GHOST_WHITE_OPACITY})`,
  [HIGHLIGHT]: '#deedfe',
  [HIGHLIGHT_ALPHA]: 'rgba(0, 116, 247, .13)',
  [HIGHLIGHT_BORDER]: '#a9bfd7',
  [LIGHT_GREY]: '#ebebeb',
  [LIGHT_GREY_ALPHA]: `rgba(0, 0, 0, ${LIGHT_GREY_OPACITY})`,
  [NAV_BACKGROUND]: '#f7fafc',
  [PASTEL_GREY]: '#cccccc',
  [PASTEL_GREY_ALPHA]: `rgba(0, 0, 0, ${PASTEL_GREY_OPACITY})`,
  [PEBBLE_GREY]: '#424242',
  [PEBBLE_GREY_ALPHA]: `rgba(0, 0, 0, ${PEBBLE_GREY_OPACITY})`,
  [SLATE_GREY]: '#4d4d4d',
  [SLATE_GREY_ALPHA]: `rgba(0, 0, 0, ${SLATE_GREY_OPACITY})`,
  [TEXT_LINK]: COLORS[DENIM],
  [WHITE]: '#ffffff',
  [WHITE_ALPHA]: `rgba(255, 255, 255, ${WHITE_OPACITY})`
};

// This could be the future home of our color theme for things like
// buttons, messaging, etc using conventions like error, warning, success, etc
const THEME: {| error: string |} = {
  error: COLORS[DULL_RED]
};

export default { ...GREYS, ...COLORS, ...THEME };
