// @flow

import * as React from 'react';
import { compose, setDisplayName } from 'recompose';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import TokenPickerInput, {
  MAX_WIDTH
} from '../TokenPickerInput/TokenPickerInput';
import FieldReferenceListItem from './FieldReferenceListItem';
import { ReactComponent as FieldIcon } from '@catalytic/catalytic-icons/lib/icons/field.svg';

const DISPLAY_NAME = 'FieldReferenceInput';

// Matches a handlebar reference when typing '{{'
const HANDLEBAR_REGEX_TRIGGER = /(?:^|[^a-zA-Z0-9])(\{\{([^\s{{]*))$/;
export const HANDLEBAR_REGEX = /{{([a-zA-Z0-9-_.]+)}}/;

export const FieldReferenceInputStyle = (theme: ThemeType) => {
  return {
    content: {
      maxWidth: MAX_WIDTH
    },
    fieldIcon: {
      color: theme.colors.battleshipGrey
    }
  };
};

export type FieldSuggestion = {
  id: string,
  source?: string,
  display: string,
  type: React.Node
};

type FieldReferenceInputType = InjectSheetProvidedProps &
  HTMLTextAreaElement & {
    data: Array<FieldSuggestion>,
    defaultValue?: string,
    displayOnly?: boolean,
    hasValidation?: boolean,
    inputClassName?: string,
    setDisplayErrorsState?: Function,
    setLoadingState?: Function,
    value?: string,
    viewerRead?: boolean
  };

class FieldReferenceInput extends React.PureComponent<FieldReferenceInputType> {
  static displayName = DISPLAY_NAME;

  render() {
    const {
      classes,
      data,
      defaultValue,
      displayOnly,
      hasValidation,
      inputClassName,
      setDisplayErrorsState,
      setLoadingState,
      theme,
      value,
      viewerRead,
      ...props
    } = this.props;

    return (
      <TokenPickerInput
        data={data}
        defaultValue={defaultValue || value || ''}
        triggerElement={
          (data || []).length ? (
            <FieldIcon className={classes.fieldIcon} />
          ) : null
        }
        displayTransform={(id, display) => {
          const suggestion = (data || []).find(s => s.id === id);
          if (suggestion) {
            return suggestion.display;
          }
          return `{{${display}}}`;
        }}
        markup="{{__id__}}"
        mentionInputClassName={inputClassName}
        regex={HANDLEBAR_REGEX}
        renderSuggestion={(suggestion: FieldSuggestion) => (
          <FieldReferenceListItem
            {...suggestion}
            contentClassName={classes.content}
          />
        )}
        trigger={HANDLEBAR_REGEX_TRIGGER}
        {...props}
      />
    );
  }
}

type EnhancedProps = {
  data: Array<FieldSuggestion>,
  defaultValue?: ?string,
  displayOnly?: boolean,
  inputClassName?: string,
  setDisplayErrorsState?: Function,
  setLoadingState?: Function,
  value?: ?string,
  viewerRead?: boolean
};

const EnhancedFieldReferenceInput: React.ComponentType<EnhancedProps> = compose(
  setDisplayName(DISPLAY_NAME),
  injectSheet(FieldReferenceInputStyle)
)(FieldReferenceInput);

export default EnhancedFieldReferenceInput;
