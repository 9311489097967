// @flow

import React from 'react';
import {
  Avatar,
  injectSheet,
  type ThemeType,
  type InjectSheetProvidedProps
} from '@catalytic/catalytic-ui';

export const Style = (theme: ThemeType) => {
  return {
    root: {
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      width: '2rem',
      height: '2rem',
      minWidth: '2rem',
      minHeight: '2rem',
      fontSize: '1rem',
      [theme.breakpoints.gt.tablet.portrait]: {
        marginLeft: '0.75rem',
        marginRight: '0.75rem',
        height: '2.5rem',
        width: '2.5rem',
        minWidth: '2.5rem',
        minHeight: '2.5rem',
        fontSize: '1.5rem'
      }
    }
  };
};

type Props = InjectSheetProvidedProps;

export const CommentAvatar = ({ classes, theme, ...props }: Props) => {
  return <Avatar className={classes.root} {...props} />;
};

CommentAvatar.displayName = 'CommentAvatar';
export default injectSheet(Style)(CommentAvatar);
