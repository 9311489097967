// @flow

import { compose, readFragment, writeData } from '../GraphQL/GraphQLResolvers';
import { StoreRecordFragment } from './StoreQueries';

// Lookup a single store record
export const storeRecord = readFragment(StoreRecordFragment, 'StoreRecord');

export const updateStoreRecord = compose(
  // Create a new store record
  (root, { input: { id, value } }) => ({
    createdDate: new Date().toISOString(),
    id,
    value
  }),
  // Write the store record
  writeData('StoreRecord')
);

export default {
  Mutation: {
    updateStoreRecord
  },
  Query: {
    storeRecord
  }
};
