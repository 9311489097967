// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import { CANARY } from '../../style/colors';

const Style = (theme: ThemeType) => {
  return {
    root: {
      padding: '1rem',
      zIndex: theme.zIndex.nav
    },
    fixedPosition: {
      padding: '.5rem 2.75rem',
      [theme.breakpoints.gt.mobile.portrait]: {
        padding: '.5rem 3rem'
      },
      [theme.breakpoints.gt.tablet.portrait]: {
        padding: '1rem 4.875rem'
      },
      top: theme.functions.toRem(theme.variables.navHeight),
      left: 0,
      position: 'fixed',
      width: '100%'
    },
    clearFix: {
      height: theme.functions.toRem(theme.variables.navHeight)
    },
    colorScheme: theme.mixins.colorScheme,
    invertScheme: theme.mixins.invertScheme,
    bannerContents: {
      color: theme.colors.black,
      ...theme.typography.extraSmallText,
      [theme.breakpoints.gt.mobile.portrait]: {
        ...theme.typography.header5
      }
    }
  };
};

type Props = InjectSheetProvidedProps & {
  children: Node,
  colorScheme: string,
  fixedPosition: boolean,
  invertScheme?: boolean
};

const Banner = ({
  children,
  classes,
  className,
  colorScheme,
  fixedPosition,
  invertScheme,
  theme,
  ...props
}: Props): Node => (
  <div>
    <div
      className={classNames(
        classes.root,
        {
          [invertScheme ? classes.invertScheme : classes.colorScheme]: true,
          [classes.fixedPosition]: fixedPosition
        },
        className
      )}
      data-color={colorScheme}
      {...props}
    >
      <div className={classes.bannerContents}>{children}</div>
    </div>
    {fixedPosition && <div className={classes.clearFix} />}
  </div>
);

Banner.defaultProps = {
  colorScheme: CANARY,
  fixedPosition: false
};

Banner.displayName = 'Banner';
export default injectSheet(Style)(Banner);
