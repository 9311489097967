// @flow

import React from 'react';
import { withRouter, type ContextRouter } from 'react-router-dom';
import { compose } from 'recompose';
import TopBarLoader from './TopBarLoader';
import withAnalytics, {
  type Analytics,
  DEFAULT_ANALYTICS
} from '../Analytics/withAnalytics';
import ErrorMessage from '../Error/ErrorMessage';
import logError from '../utils/logError';

type Props = ContextRouter & {
  analytics: Analytics,
  error: ?Error,
  pastDelay: boolean
};

export const Loading = ({ analytics, error, location, pastDelay }: Props) => {
  React.useEffect(() => {
    const now = Date.now();

    return () => {
      if (typeof analytics?.track === 'function') {
        analytics.track('loadable', {
          category: 'LOADING',
          label: location?.pathname || '/',
          value: Math.max(0, Date.now() - now)
        });
      }
    };
  });

  // When the loader has errored
  if (error) {
    logError(error);

    return <ErrorMessage />;
  }

  // When the loader has taken longer than the delay
  if (pastDelay) {
    return <TopBarLoader />;
  }

  // When the loader has just started
  return null;
};
Loading.displayName = 'Loading';
Loading.defaultProps = {
  analytics: DEFAULT_ANALYTICS
};

export default compose(withAnalytics, withRouter)(Loading);
