// @flow

import * as React from 'react';
import { List, Map } from 'immutable';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { BaseText, Input, type ThemeType } from '@catalytic/catalytic-ui';
import { ReactComponent as AddGlyph } from '@catalytic/catalytic-icons/lib/glyphs/add.svg';
import {
  expressionToStatementMap,
  flattenedStatementsToStatementMap,
  removeExpressionFromStatementMap,
  spliceExpression,
  statementMapToExpression,
  statementMapToFlattenedStatements,
  updateStatementMap,
  validateFlattenedStatements
} from './ConditionHelpers';
import {
  STATEMENT_OPERATOR,
  type AddressedStatement,
  type StatementMap,
  type StatementOperator
} from './ConditionTypes';
import StepConditionStatement from './StepConditionStatement';
import whenEnterKey from '../utils/whenEnterKey';

const EMPTY_EXPRESSION = '"" == ""';
const EMPTY_STATEMENT_MAP = Map({ or: List() });

const parseStatementMap = ({
  addressedStatements,
  index,
  intl,
  setErrors,
  statementOperator,
  updatedExpression
}: {
  addressedStatements: Array<AddressedStatement>,
  index: number,
  intl: Object,
  setErrors: (Array<string>) => void,
  statementOperator: StatementOperator,
  updatedExpression: string
}): StatementMap => {
  const updatedStatements = addressedStatements.map(statement => {
    return {
      value: statement.value,
      operator: statement.isLastAnd
        ? STATEMENT_OPERATOR.OR
        : STATEMENT_OPERATOR.AND
    };
  });
  updatedStatements[index] = {
    value: updatedExpression,
    operator: statementOperator
  };
  let errors;
  try {
    errors = validateFlattenedStatements(updatedStatements);
  } catch (e) {
    errors = [intl.formatMessage(Messages.errorParsingExpression)];
  }
  if (errors.length > 0) {
    setErrors(errors);
    return EMPTY_STATEMENT_MAP;
  }
  const statementMap =
    updatedStatements.length === 1 &&
    updatedStatements[0].value === EMPTY_EXPRESSION
      ? EMPTY_STATEMENT_MAP
      : flattenedStatementsToStatementMap(updatedStatements);
  return statementMap;
};

const makeHandleExpressionChange = ({
  addressedStatements,
  intl,
  setErrors,
  setStatementMap,
  onChange
}) => (
  index: number,
  updatedExpression: string,
  statementOperator: StatementOperator,
  or: number,
  and: number
) => {
  const statementMap = parseStatementMap({
    addressedStatements,
    index,
    intl,
    setErrors,
    statementOperator,
    updatedExpression
  });
  if (statementMap) {
    setErrors([]);
    let updatedStatementMap;
    try {
      updatedStatementMap = updateStatementMap(
        statementMap,
        updatedExpression,
        or,
        and
      );
    } catch (e) {
      console.error('Error: ');
      console.error(e);
      return;
    }
    if (updatedStatementMap) {
      setStatementMap(updatedStatementMap);
      onChange(statementMapToExpression(updatedStatementMap));
    }
  }
};
const makeAddStatementAfterIndex = ({
  setErrors,
  setStatementMap,
  statementMap,
  onChange
}) => (or: number, and: number) => {
  if (statementMap) {
    setErrors([]);
    const updatedStatementMap = spliceExpression(
      statementMap,
      or,
      and + 1,
      0,
      EMPTY_EXPRESSION
    );
    setStatementMap(updatedStatementMap);
    onChange(statementMapToExpression(updatedStatementMap));
  }
};
const makeRemoveStatementAtIndex = ({
  setErrors,
  setStatementMap,
  statementMap,
  onChange
}) => (or: number, and: number) => {
  if (statementMap) {
    setErrors([]);
    const updatedStatementMap = removeExpressionFromStatementMap(
      statementMap,
      or,
      and
    );
    setStatementMap(updatedStatementMap);
    onChange(statementMapToExpression(updatedStatementMap));
  }
};

const useStyles = makeStyles((theme: ThemeType) => {
  return {
    addGlyph: {
      fontSize: '1.25rem',
      marginRight: '0.5rem',
      color: theme.colors.ashGrey
    },
    addRow: {
      display: 'flex',
      margin: '0.5rem',
      paddingLeft: '.25rem'
    },
    addText: {
      ...theme.mixins.inputPlaceholder,
      cursor: 'pointer'
    },
    inputs: {
      borderRadius: theme.variables.borderRadius,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.blueGrey,
      backgroundColor: theme.colors.blueGrey,
      boxShadow: 'none',
      marginLeft: '-0.25rem',
      marginRight: '-0.25rem',
      marginTop: theme.functions.toRem(theme.variables.inputMarginTop),
      paddingTop: '0.3125rem',
      paddingBottom: '0.3125rem'
    },
    inputShadow: {
      display: 'none'
    },
    error: {
      display: 'block'
    }
  };
});

export const Messages = defineMessages({
  errorParsingExpression: {
    id: 'stepCondition.parsing.error',
    defaultMessage: 'There was a problem parsing your expression.'
  }
});

type Props = {
  disabled: boolean,
  expression: string,
  name?: string,
  onChange(string): void
};

export function StepCondition(props: Props) {
  const { disabled, name, onChange, expression: initialExpression } = props;
  const initialStatementMap = expressionToStatementMap(initialExpression);

  const classes = useStyles();
  const intl = useIntl();
  const [rawStatementMap, setStatementMap] = React.useState<StatementMap>(
    initialStatementMap
  );

  const [errors, setErrors] = React.useState<Array<string>>([]);

  const addressedStatements = statementMapToFlattenedStatements(
    rawStatementMap
  );

  const handleExpressionChange = makeHandleExpressionChange({
    addressedStatements,
    intl,
    setErrors,
    setStatementMap,
    onChange
  });
  const addStatementAfterIndex = makeAddStatementAfterIndex({
    setErrors,
    setStatementMap,
    statementMap: rawStatementMap,
    onChange
  });
  const addStatementAtEnd = () => {
    const lastStatement =
      addressedStatements[addressedStatements.length - 1] || {};
    addStatementAfterIndex(lastStatement?.or || 0, lastStatement?.and || 0);
  };
  const removeStatementAtIndex = makeRemoveStatementAtIndex({
    setErrors,
    setStatementMap,
    statementMap: rawStatementMap,
    onChange
  });
  const expression = statementMapToExpression(rawStatementMap);

  return (
    <Input
      error={
        errors && errors.length > 0 ? (
          <>
            {errors.map((message, index) => (
              <span key={index} className={classes.error}>
                {message}
              </span>
            ))}
          </>
        ) : (
          undefined
        )
      }
    >
      <>
        <div className={classes.inputs}>
          {addressedStatements.map((statement, index) => {
            return (
              <StepConditionStatement
                disabled={disabled}
                onChange={(expression, statementOperator, or, and) => {
                  handleExpressionChange(
                    index,
                    expression,
                    statementOperator,
                    or,
                    and
                  );
                }}
                index={index}
                key={statement.id}
                numStatements={addressedStatements.length}
                removeStatementAtIndex={removeStatementAtIndex}
                statement={statement}
              />
            );
          })}
          {!disabled && (
            <div
              className={classes.addRow}
              data-testid="add-condition"
              onClick={addStatementAtEnd}
              onKeyDown={whenEnterKey(() => addStatementAtEnd())}
              role="button"
              tabIndex="0"
            >
              <AddGlyph className={classes.addGlyph} />
              <BaseText className={classes.addText}>
                <FormattedMessage
                  id="conditions.addCondition"
                  defaultMessage="Add a Condition"
                />
              </BaseText>
            </div>
          )}
        </div>
        <input
          className={classes.inputShadow}
          data-name={name}
          name={name}
          onChange={() => {}}
          value={expression || ''}
        />
      </>
    </Input>
  );
}
StepCondition.displayName = 'StepCondition';

export default StepCondition;
