// @flow

import classNames from 'classnames';
import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import {
  type InjectSheetProvidedProps,
  type ThemeType
} from '../../style/ThemeTypes';
import ListItem from './ListItem';

const Style = (theme: ThemeType) => {
  return {
    root: {
      alignItems: 'flex-end',
      borderTop: 'none',
      color: theme.colors.pebbleGrey,
      padding: '1rem 0 .5rem',
      margin: '1rem 0 .5rem',
      borderBottom: `1px solid ${theme.colors.lightGrey}`
    }
  };
};

type Props = InjectSheetProvidedProps & {
  children?: Node
};

const ListItemGroupHeader = ({
  classes,
  className,
  theme,
  ...props
}: Props): Node => (
  <ListItem className={classNames(classes.root, className)} {...props} />
);

ListItemGroupHeader.displayName = 'ListItemGroupHeader';

export default injectSheet(Style)(ListItemGroupHeader);
