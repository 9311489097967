// @flow

import * as React from 'react';
import { graphql } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import classNames from 'classnames';
import compose from 'ramda/src/compose';
import mergeLeft from 'ramda/src/mergeLeft';
import { withState } from 'recompose';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  AvatarV2,
  ExtraSmallText,
  Menu,
  NavButton,
  Tab,
  TabList,
  withTabs
} from '@catalytic/catalytic-ui';
import { ReactComponent as NotificationGlyph } from '@catalytic/catalytic-icons/lib/glyphs/notification.svg';
import Row from './NavbarNotificationsRow';
import {
  NotificationsQuery,
  ReadNotificationByTypeMutation,
  UnreadNotificationsQuery,
  UpdateNotificationMutation
} from './NavbarNotificationsTypeDefs';
import { type Node, type Nodes } from './NavbarNotificationsTypes';
import TopBarLoader from '../Loading/TopBarLoader';
import { WAIT as DEBOUNCE_WAIT } from '../const/debounce';
import {
  NOTIFICATIONS as NOTIFICATIONS_PATH,
  TASKS_V2 as TASKS_V2_PATH
} from '../const/path';
import NETWORK_STATUS, { type NetworkStatus } from '../const/networkStatus';
import logError from '../utils/logError';
import isUUID from '../utils/uuid';

// menu width and height is roughly based on the size of the `NavbarUser.js` menu.
const HEIGHT = 204;
const WIDTH = 350;
// itemSize should be equal to the height of the SmallListItem UI component.
const ITEM_SIZE = 48;
// poll for notifications every 5 minutes.
const POLL_INTERVAL = 1000 * 60 * 5;
// See InfiniteLoader prop types
// https://github.com/bvaughn/react-virtualized/blob/master/docs/InfiniteLoader.md#prop-types
const THRESHOLD = 15;
const OVERSCAN_COUNT = 5;
// overscanCount + threshold, the minimumBatchSize is the number of rows
// we will request the first time the menu is opened.
const MINIMUM_BATCH_SIZE = 20;
// batchSize is the number of rows we will request on scroll.
const BATCH_SIZE = 100;

const useStyles = makeStyles(theme => ({
  avatar: {
    padding: 0,
    borderRadius: 0
  },
  buttonContainer: {
    '&:hover, &:focus, &:active': {
      '& $button': {
        color: theme.colors.battleshipGrey,
        opacity: 1
      }
    }
  },
  button: {},
  tabList: {
    marginBottom: 0
  },
  tabContainer: {
    '& + $tabContainer': {
      marginLeft: 0
    }
  },
  tab: {
    padding: '0.75rem 1rem'
  },
  loading: {
    display: 'block',
    padding: '1rem',
    maxWidth: '100%',
    width: theme.functions.toRem(WIDTH),
    height: theme.functions.toRem(HEIGHT),
    color: theme.colors.battleshipGrey,
    textAlign: 'left'
  },
  list: {
    maxWidth: '100%',
    overscrollBehavior: 'contain'
  },
  viewAll: {
    ...theme.mixins.viewAll,
    textAlign: 'left',
    '&:active': {
      opacity: 1
    }
  }
}));

type ReadNotificationByType = ({
  updateQueries: any,
  variables: { id: string }
}) => Promise<void | { [key: string]: any }>;

type UpdateNotification = ({
  updateQueries: any,
  variables: { id: string }
}) => Promise<void | { [key: string]: any }>;

type Notifications = {
  nodes: Nodes,
  totalCount: number
};

type UnreadNotifications = {
  totalCount: number
};

type Variables = { after: string, first: number, id: string };

type UpdateQuery = {
  fetchMoreResult: {
    notifications: Notifications,
    unreadNotifications: UnreadNotifications
  },
  variables: Variables
};

type FetchMore = ({
  updateQuery: (
    prev: {
      notifications: Notifications,
      unreadNotifications: UnreadNotifications
    },
    UpdateQuery
  ) => mixed,
  variables: Variables
}) => Promise<void | { [key: string]: any }>;

export const fetchMoreFactory = ({
  fetchMore,
  id,
  totalCount
}: {
  fetchMore: FetchMore,
  id: string,
  totalCount: number
}) => async (startIndex: number, stopIndex: number) => {
  // The number of requested items is equal to the number of visible
  // rows in the list, if greater than the minimum batch size.
  // At this time, the minimum batch size will always be greater,
  // so this logic is only acting as a fail-safe.
  const first = Math.max(
    Math.min(BATCH_SIZE, stopIndex - startIndex + 1),
    BATCH_SIZE
  );
  // The offset of requested items is equal to the first visible row in the list.
  // The offset may be adjusted based on the total number of items and the number of requested items,
  // so that we do not request data that does not exist.
  const after = String(Math.max(Math.min(startIndex, totalCount - first), 0));

  await fetchMore({
    updateQuery: updateQueryFunction,
    variables: { after, first, id }
  });
};

export const isItemLoadedFactory = (nodes: Nodes) => (index: number) => {
  const itemData = nodes[index];
  const id = itemData?.id;

  return isUUID(id);
};

// If ID is not a valid UUID, we know the notification is a placeholder.
export const notificationFactory = (prevNodesCount: number) => (
  _: any,
  index: number
) => {
  const createdDate = new Date().toISOString();
  const description = null;
  const displayName = null;
  const id = String(index + prevNodesCount);
  const node = null;
  const read = false;

  return {
    __typename: 'Notification',
    contextReference: {
      __typename: 'NodeReferenceEdge',
      node
    },
    createdDate,
    description,
    displayName,
    id,
    read
  };
};

export const updateNotificationsFunction = (
  prev: {
    notifications: Notifications,
    unreadNotifications: UnreadNotifications
  },
  {
    mutationResult
  }: {
    mutationResult: {
      data: {
        readNotificationByType: {
          updatedCount: number
        }
      }
    }
  }
) => {
  if (!mutationResult) {
    return prev;
  }

  const prevNotifications = prev?.notifications;
  const prevNodes = prevNotifications?.nodes || [];
  const prevUnreadNotifications = prev?.unreadNotifications;
  // Update the notification node, mark as read.
  const nodes: Nodes = prevNodes.map((node: Node) => ({ ...node, read: true }));

  return {
    ...prev,
    notifications: {
      ...prevNotifications,
      nodes
    },
    unreadNotifications: {
      ...prevUnreadNotifications,
      totalCount: 0
    }
  };
};

export const updateUnreadNotificationsFunction = (
  prev: {
    unreadNotifications: UnreadNotifications
  },
  {
    mutationResult
  }: {
    mutationResult: {
      data: {
        readNotificationByType: {
          updatedCount: number
        }
      }
    }
  }
) => {
  if (!mutationResult) {
    return prev;
  }

  const prevUnreadNotifications = prev?.unreadNotifications;

  return {
    ...prev,
    unreadNotifications: {
      ...prevUnreadNotifications,
      totalCount: 0
    }
  };
};

export const updateNotificationsFactory = (id: string) => (
  prev: {
    notifications: Notifications,
    unreadNotifications: UnreadNotifications
  },
  {
    mutationResult
  }: {
    mutationResult: {
      data: {
        updateNotification: {
          node: {
            id: string,
            read: boolean
          }
        }
      }
    }
  }
) => {
  if (!mutationResult) {
    return prev;
  }

  const prevNotifications = prev?.notifications;
  const prevNodes = prevNotifications?.nodes || [];
  const prevUnreadNotifications = prev?.unreadNotifications;
  const prevUnreadTotalCount = prevUnreadNotifications?.totalCount || 0;
  // Find the notification node index by id.
  const nodeIndex = prevNodes.findIndex(node => node.id === id);
  // Update the notification node, mark as read.
  const nodes = [
    ...prevNodes.slice(0, nodeIndex),
    { ...prevNodes[nodeIndex], read: true },
    ...prevNodes.slice(nodeIndex + 1)
  ];
  // Update the unread total count. Count all unread nodes in the cache.
  // Compare against the previously known unread total count.
  const totalCount = Math.max(
    nodes.reduce(
      (accumulator, currentValue) =>
        currentValue.read === false ? ++accumulator : accumulator,
      0
    ),
    prevUnreadTotalCount - 1
  );

  return {
    ...prev,
    notifications: {
      ...prevNotifications,
      nodes
    },
    unreadNotifications: {
      ...prevUnreadNotifications,
      totalCount
    }
  };
};

export const updateUnreadNotificationsFactory = (id: string) => (
  prev: {
    notifications: Notifications,
    unreadNotifications: UnreadNotifications
  },
  {
    mutationResult
  }: {
    mutationResult: {
      data: {
        updateNotification: {
          node: {
            id: string,
            read: boolean
          }
        }
      }
    }
  }
) => {
  if (!mutationResult) {
    return prev;
  }

  const prevNotifications = prev?.notifications;
  const prevNodes = prevNotifications?.nodes || [];
  const prevUnreadNotifications = prev?.unreadNotifications;
  const prevUnreadTotalCount = prevUnreadNotifications?.totalCount || 0;
  // Find the notification node index by id.
  const nodeIndex = prevNodes.findIndex(node => node.id === id);
  // Update the notification node, mark as read.
  const nodes = [
    ...prevNodes.slice(0, nodeIndex),
    { ...prevNodes[nodeIndex], read: true },
    ...prevNodes.slice(nodeIndex + 1)
  ];
  // Update the unread total count. Count all unread nodes in the cache.
  // Compare against the previously known unread total count.
  const totalCount = Math.max(
    nodes.reduce(
      (accumulator, currentValue) =>
        currentValue.read === false ? ++accumulator : accumulator,
      0
    ),
    prevUnreadTotalCount - 1
  );

  return {
    ...prev,
    notifications: {
      ...prevNotifications,
      nodes
    },
    unreadNotifications: {
      ...prevUnreadNotifications,
      totalCount
    }
  };
};

export const updateQueryFunction = (
  prev: {
    notifications: Notifications,
    unreadNotifications: UnreadNotifications
  },
  { fetchMoreResult, variables }: UpdateQuery
) => {
  if (!fetchMoreResult) {
    return prev;
  }

  const prevNotifications = prev?.notifications;
  const prevNodes = prevNotifications?.nodes || [];
  const prevNodesCount = prevNodes.length;
  const prevTotalCount = prevNotifications?.totalCount || 0;
  const nextNotifications = fetchMoreResult?.notifications;
  const nextNodes = nextNotifications?.nodes || [];
  const nextNodesCount = nextNodes.length;
  const nextTotalCount = nextNotifications?.totalCount;
  const after = parseInt(variables?.after || 0);
  const length = Math.max(0, prevTotalCount - prevNodesCount);
  // If the number of nodes does not equal the total count,
  // we need to fill the cache with placeholder nodes.
  const nodes =
    prevNodesCount === prevTotalCount
      ? prevNodes
      : [
          ...prevNodes,
          // Fill the browser cache with placeholder notifications.
          ...Array.from({ length }, notificationFactory(prevNodesCount))
        ];
  const totalCount =
    nextTotalCount === undefined ? prevTotalCount : nextTotalCount;

  return {
    ...prev,
    notifications: {
      ...prevNotifications,
      nodes: [
        ...nodes.slice(0, after),
        // Only replace placeholder nodes. To prevent flash of unexpected content.
        // Nodes are considered placeholders if they do not have a valid UUID.
        ...nodes.slice(after, after + nextNodesCount).map((node, index) => {
          const id = node?.id;

          return isUUID(id) ? node : nextNodes[index];
        }),
        ...nodes.slice(after + nextNodesCount)
      ],
      totalCount
    }
  };
};

type Props = {|
  activeTabClassName: string,
  activeTabIndex: number,
  id: string,
  isVisible: boolean,
  loading: boolean,
  loadMoreItems: (totalCount: number) => Promise<void | { [key: string]: any }>,
  networkStatus: NetworkStatus,
  nodes: Nodes,
  readNotificationByType: ReadNotificationByType,
  refetch: () => Promise<void | Object>,
  setActiveTabIndex: (activeTabIndex: number) => mixed,
  setIsOpen: (isOpen: boolean) => mixed,
  setShowOnInit: (showOnInit: boolean) => mixed,
  showOnInit: boolean,
  tabClassName: string,
  totalCount: number,
  unreadTotalCount: number,
  updateNotification: UpdateNotification
|};

export const NavbarNotifications = ({
  activeTabClassName,
  activeTabIndex,
  id,
  loading,
  loadMoreItems,
  networkStatus,
  nodes,
  readNotificationByType,
  refetch,
  setActiveTabIndex,
  setIsOpen,
  setShowOnInit,
  showOnInit,
  tabClassName,
  totalCount,
  unreadTotalCount,
  updateNotification
}: Props) => {
  const classes = useStyles();
  const infiniteLoaderRef = React.useRef(null);
  const theme = useTheme();
  const isItemLoaded = isItemLoadedFactory(nodes);
  // Bundle additional data to list items using the "itemData" prop.
  // It will be accessible to item renderers as props.data.
  // Memoize this data to avoid bypassing shouldComponentUpdate().
  // https://github.com/bvaughn/react-window/issues/238
  const itemData = React.useMemo(
    () => ({
      nodes,
      updateNotification: ({ id }) =>
        updateNotification({
          updateQueries: {
            Notifications: updateNotificationsFactory(id),
            UnreadNotifications: updateUnreadNotificationsFactory(id)
          },
          variables: { id }
        })
    }),
    [nodes, updateNotification]
  );
  const hasUnreadNotifications = unreadTotalCount > 0;

  return (
    <>
      {loading && networkStatus === NETWORK_STATUS.fetchMore && (
        <TopBarLoader />
      )}
      <Menu
        onHidden={() => {
          // Set is open when the user opens the menu, this will toggle the unread notifications query.
          if (typeof setIsOpen === 'function') {
            setIsOpen(false);
          }

          // Bulk mark all notifications as read.
          if (hasUnreadNotifications) {
            readNotificationByType({
              updateQueries: {
                Notifications: updateNotificationsFunction,
                UnreadNotifications: updateUnreadNotificationsFunction
              },
              variables: { id }
            });
          }
        }}
        onTrigger={() => {
          // Set has opened state, this will toggle the notifications query.
          if (typeof setShowOnInit === 'function' && !showOnInit) {
            setShowOnInit(true);
          }

          // Set is open when the user opens the menu, this will toggle the unread notifications query.
          if (typeof setIsOpen === 'function') {
            setIsOpen(true);
          }

          // https://github.com/bvaughn/react-window/issues/463
          // Reset the infinite loader scroll offset when the menu is opened.
          // Otherwise, the list does not render correctly.
          if (
            infiniteLoaderRef.current &&
            infiniteLoaderRef.current?._listRef?.state?.scrollOffset > 0 &&
            typeof infiniteLoaderRef.current?._listRef?.scrollToItem ===
              'function'
          ) {
            infiniteLoaderRef.current._listRef.scrollToItem(0);
          }

          // Refetch data when the user opens the menu.
          if (typeof refetch === 'function' && hasUnreadNotifications) {
            refetch();
          }
        }}
        opener={
          <AvatarV2
            appearance="square"
            borderColor={theme.colors.navBackground}
            className={classes.avatar}
            component={({ className }) => (
              // TODO: Remove `tabIndex` when Menu component supports buttons as openers
              <NavButton
                className={classNames(classes.button, className)}
                tabIndex={-1}
              >
                <NotificationGlyph />
              </NavButton>
            )}
            presence={hasUnreadNotifications ? 'online' : undefined}
            size="large"
            theme={(current, props) =>
              compose(
                mergeLeft({
                  backgroundColor: 'transparent',
                  borderRadius: 0,
                  dimensions: {
                    width: 'auto',
                    height: 'auto'
                  },
                  presence: {
                    bottom: '50%',
                    left: '50%',
                    right: 'auto',
                    top: 'auto',
                    height: '.75rem',
                    width: '.75rem'
                  }
                }),
                current
              )(props)
            }
          />
        }
        openerClassName={classes.buttonContainer}
        showOnInit={showOnInit}
        zIndex={theme.zIndex.nav}
      >
        <TabList className={classes.tabList} role="tablist">
          <Tab
            aria-controls="tabpanel-tasks"
            aria-selected={activeTabIndex === 0}
            className={classes.tabContainer}
            href="#tab-tasks"
            id="tab-tasks"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              setActiveTabIndex(0);
            }}
            role="tab"
            tabClassName={classes.tab}
          >
            <FormattedMessage
              defaultMessage="Tasks"
              id="navbarNotifications.tasks"
            />
          </Tab>
        </TabList>
        <div
          aria-labelledby="tab-tasks"
          className={classNames(tabClassName, {
            [activeTabClassName]: activeTabIndex === 0
          })}
          id="tabpanel-tasks"
          role="tabpanel"
        >
          {loading &&
          (networkStatus === NETWORK_STATUS.loading ||
            networkStatus === NETWORK_STATUS.refetch) ? (
            <ExtraSmallText className={classes.loading}>
              <FormattedMessage
                defaultMessage="Loading..."
                id="navbarNotifications.loading"
              />
            </ExtraSmallText>
          ) : (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={totalCount}
              loadMoreItems={loadMoreItems}
              minimumBatchSize={MINIMUM_BATCH_SIZE}
              ref={infiniteLoaderRef}
              threshold={THRESHOLD}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  className={classes.list}
                  height={HEIGHT}
                  itemCount={totalCount}
                  itemData={itemData}
                  itemSize={ITEM_SIZE}
                  onItemsRendered={onItemsRendered}
                  overscanCount={OVERSCAN_COUNT}
                  ref={ref}
                  width={WIDTH}
                >
                  {Row}
                </List>
              )}
            </InfiniteLoader>
          )}
          <Link className={classes.viewAll} to={TASKS_V2_PATH}>
            <FormattedMessage
              defaultMessage="View all tasks"
              id="navbarNotifications.tasks.viewAll"
            />
          </Link>
        </div>
      </Menu>
    </>
  );
};
NavbarNotifications.displayName = 'NavbarNotifications';
NavbarNotifications.defaultProps = {
  nodes: [],
  totalCount: 0,
  unreadTotalCount: 0
};

type EnhancedProps = {|
  id: string,
  isVisible: boolean
|};

export default compose(
  withRouter,
  withTabs,
  // Show the menu on mount when referred from "/notifications"
  withState(
    'showOnInit',
    'setShowOnInit',
    ({ location }) => location.pathname === NOTIFICATIONS_PATH
  ),
  withState('isOpen', 'setIsOpen', false),
  graphql(UnreadNotificationsQuery, {
    options: ({ id, isVisible }: EnhancedProps) => ({
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      onError: error => logError(error),
      pollInterval: isVisible ? POLL_INTERVAL : 0,
      variables: { id }
    }),
    props: ({ data: { unreadNotifications } }) => ({
      unreadTotalCount: unreadNotifications?.totalCount
    }),
    skip: ({ id, isOpen }) => !id || isOpen
  }),
  graphql(NotificationsQuery, {
    options: ({ id }: EnhancedProps) => ({
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: error => logError(error),
      variables: { after: '0', first: MINIMUM_BATCH_SIZE, id }
    }),
    props: ({
      data: {
        fetchMore,
        loading,
        networkStatus,
        notifications,
        refetch,
        unreadNotifications
      },
      ownProps: { id, unreadTotalCount }
    }) => {
      const totalCount = notifications?.totalCount || 0;

      return {
        // There will always be at least one notification.
        loading: loading || totalCount === 0,
        loadMoreItems: AwesomeDebouncePromise(
          fetchMoreFactory({ fetchMore, id, totalCount }),
          DEBOUNCE_WAIT,
          { key: () => `notifications-${id}` }
        ),
        networkStatus,
        nodes: notifications?.nodes || [],
        refetch,
        totalCount,
        unreadTotalCount:
          // Unread total count is undefined when unread notifications query is skipped.
          unreadTotalCount === undefined
            ? unreadNotifications?.totalCount || 0
            : unreadTotalCount
      };
    },
    skip: ({ id, showOnInit }) => !id || !showOnInit
  }),
  graphql(ReadNotificationByTypeMutation, { name: 'readNotificationByType' }),
  graphql(UpdateNotificationMutation, { name: 'updateNotification' })
)(NavbarNotifications);
