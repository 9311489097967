// @flow

import * as React from 'react';
import { InputV2, InputConsumer } from '@catalytic/catalytic-ui';
import { type JSONSchema } from '@catalytic/json-schema-validator-catalytic-web';
import { types } from '@catalytic/react-view';
import { type View } from '@catalytic/view';
import useFieldContext from './FieldContext';
import { useHandleBlur, useHandleChange } from './ViewHelpers';
import TableFieldLoading from '../Field/TableFieldLoading';

const LazyTableInput = React.lazy(() =>
  import(/* webpackChunkName: "InlineTableField" */ '../Field/InlineTableField')
);

type Props = {
  id: string,
  name: string,
  required?: boolean,
  schema?: JSONSchema<>,
  value?: any,
  view: View<>
};

const TableInput = (props: Props) => (
  <React.Suspense fallback={<TableFieldLoading />}>
    <LazyTableInput {...props} />
  </React.Suspense>
);
TableInput.displayName = 'TableInput';

const TableView = ({
  node: getNode,
  schema: getSchema,
  setValue,
  value
}: types.CollectionView) => {
  const node = getNode();
  const schema = getSchema();
  const fieldContext = useFieldContext();
  const {
    className,
    contextValues,
    description,
    disabled,
    displayErrors,
    displayName,
    example,
    fieldName,
    hasValidation,
    id,
    name,
    onBlur,
    onChange,
    readOnly,
    required,
    type
  } = fieldContext || {};
  const handleBlur = useHandleBlur({ onBlur, setValue, type });
  const handleChange = useHandleChange({ onChange, setValue, type });

  // Should not render a table input if the value is not an object.
  if (value && typeof value !== 'object') {
    return null;
  }

  return (
    <InputV2
      className={className}
      contextValues={contextValues}
      description={description}
      disabled={disabled}
      displayErrors={displayErrors}
      example={example}
      fieldName={fieldName}
      hasValidation={hasValidation}
      id={id}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      readOnly={readOnly}
      schema={schema}
      title={displayName}
      type={type}
      value={value}
    >
      <InputConsumer>
        {props => (
          <TableInput
            id={id}
            name={name}
            required={required}
            schema={schema}
            value={value}
            view={node}
            {...props}
          />
        )}
      </InputConsumer>
    </InputV2>
  );
};
TableView.displayName = 'TableView';

export default React.memo<types.CollectionView>(TableView);
