// @flow

import React, { PureComponent } from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import { type InjectSheetProvidedProps } from '../../style/ThemeTypes';

const Style = () => {
  return {
    root: {
      margin: '0 .5rem 0 0',
      position: 'relative',
      width: '100%',
      minWidth: 0
    }
  };
};

type Props = InjectSheetProvidedProps & HTMLDivElement;

export class ListItemContents extends PureComponent<Props> {
  render() {
    const { classes, className, theme, ...props } = this.props;
    return <div className={classNames(classes.root, className)} {...props} />;
  }
}

ListItemContents.displayName = 'ListItemContents';
export default injectSheet(Style)(ListItemContents);
