// @flow

import React, { type Node } from 'react';

type Props = {
  url?: string,
  title?: string,
  alt?: string
};

export default function MarkdownImage(props: Props): Node {
  const { title, url, alt } = props;

  return <img src={url} title={title} alt={alt} />;
}

MarkdownImage.displayName = 'MarkdownImage';
