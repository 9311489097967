// @flow

import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { withAppcues } from '../../Feature';
import useTeamContext from '../../Team/TeamContext';
import useViewerContext from '../../Viewer/ViewerContext';

export const trackAppcuesEvent = (
  eventName: string,
  metadata: { [string]: any }
) => {
  if (typeof window.Appcues?.track === 'function') {
    window.Appcues.track(eventName, metadata);
  }
};

export const Appcues = (props: { appcues: boolean }) => {
  const { appcues } = props;
  const location = useLocation();
  const team = useTeamContext() || {};
  const viewer = useViewerContext() || {};

  React.useEffect(() => {
    if (appcues && typeof window.Appcues?.page === 'function') {
      window.Appcues.page();
    }
  }, [appcues, location]);

  if (
    team.id &&
    viewer.id &&
    appcues &&
    typeof window.Appcues?.identify === 'function'
  ) {
    const {
      displayName: teamDisplayName,
      id: teamName,
      isTrialTeam: isTrial
    } = team;
    const { canBuild, createdDate, guid, isTeamAdmin, isTeamOwner } = viewer;
    const role = isTeamAdmin || isTeamOwner ? 'Admin' : 'User';
    window.Appcues.identify(guid, {
      canBuild,
      createdDate,
      isTrial,
      role,
      teamDisplayName,
      teamName
    });
  }
  return null;
};

export default withAppcues(Appcues);
