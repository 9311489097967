// @flow

import * as React from 'react';
import { componentFromProp, defaultProps } from 'recompose';

export default function withComponentProp(
  defaultProp: React.Node,
  propName: string = 'component'
) {
  return (defaultProps({ [propName]: defaultProp })(
    componentFromProp(propName)
  ): React.ComponentType<*>);
}
