// @flow

import * as React from 'react';
import { type Field, type FieldContext } from '../Field/FieldTypes';
import { type Process } from '../Process/ProcessTypes';
import { type Team } from '../Team/TeamTypes';

type Context = {|
  availableFields: Array<Field>,
  basePath: string,
  fieldsByContext: Array<FieldContext>,
  fields: Array<Field>,
  process: Process,
  team: Team
|};

type Props = {|
  children?: React.Node,
  value: Context
|};

const ContextTypeContext = React.createContext<Context | void>();

const useContextTypeContext = () => React.useContext(ContextTypeContext);

export const ContextTypeProvider = ({ children, value }: Props) => (
  <ContextTypeContext.Provider value={value}>
    {children}
  </ContextTypeContext.Provider>
);

export const ContextTypeConsumer = ContextTypeContext.Consumer;

export default useContextTypeContext;
