// @flow

import React, { createContext, useContext, type Node } from 'react';

type Context = {|
  webhookID?: string
|};

type Props = {|
  children?: Node,
  value: Context
|};

export const WebformContext = createContext<Context | void>();

const useWebformContext = () => useContext(WebformContext);

export const WebformProvider = ({ children, value }: Props) => (
  <WebformContext.Provider value={value}>{children}</WebformContext.Provider>
);

export const WebformConsumer = WebformContext.Consumer;

export default useWebformContext;
