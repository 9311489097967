// @flow

import classNames from 'classnames';
import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import { type InjectSheetProvidedProps } from '../../style/ThemeTypes';
import { SmallText } from '../../Text/Text';

const Style = () => {
  return {
    root: {
      flex: '1',
      textTransform: 'uppercase',
      verticalAlign: 'bottom'
    }
  };
};

type Props = InjectSheetProvidedProps & {
  children?: Node
};

const ListItemGroupHeaderTitle = ({
  classes,
  className,
  theme,
  ...props
}: Props): Node => (
  <SmallText className={classNames(classes.root, className)} {...props} />
);

ListItemGroupHeaderTitle.displayName = 'ListItemGroupHeaderTitle';

export default injectSheet(Style)(ListItemGroupHeaderTitle);
