// @flow

import React, { type Node } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ListItemGroupHeader,
  ListItemGroupHeaderTitle,
  ListView
} from '@catalytic/catalytic-ui';
import SearchCategoryListItem from './SearchCategoryListItem';
import { TYPE, type Type } from './SearchTypes';

type Props = {
  onCategory?: (type: Type) => any
};

const SearchCategoryList = ({
  onCategory = () => {},
  ...props
}: Props): Node => (
  <ListView {...props}>
    <ListItemGroupHeader>
      <ListItemGroupHeaderTitle>
        <FormattedMessage
          id="searchCategoryList.groupHeader"
          defaultMessage="Advanced Search"
        />
      </ListItemGroupHeaderTitle>
    </ListItemGroupHeader>
    <SearchCategoryListItem
      type={TYPE.CONTEXT_TYPE}
      onClick={() => onCategory(TYPE.CONTEXT_TYPE)}
    >
      <FormattedMessage
        id="searchCategoryList.contextType"
        defaultMessage="Search Workflows"
      />
    </SearchCategoryListItem>
    <SearchCategoryListItem
      type={TYPE.CONTEXT}
      onClick={() => onCategory(TYPE.CONTEXT)}
    >
      <FormattedMessage
        id="searchCategoryList.context"
        defaultMessage="Search Instances"
      />
    </SearchCategoryListItem>
    <SearchCategoryListItem
      type={TYPE.TASK}
      onClick={() => onCategory(TYPE.TASK)}
    >
      <FormattedMessage
        id="searchCategoryList.task"
        defaultMessage="Search Tasks"
      />
    </SearchCategoryListItem>
    <SearchCategoryListItem
      type={TYPE.DATA_TABLE}
      onClick={() => onCategory(TYPE.DATA_TABLE)}
    >
      <FormattedMessage
        id="searchCategoryList.dataTable"
        defaultMessage="Search Tables"
      />
    </SearchCategoryListItem>
    <SearchCategoryListItem
      type={TYPE.ACTOR}
      onClick={() => onCategory(TYPE.ACTOR)}
    >
      <FormattedMessage
        id="searchCategoryList.actor"
        defaultMessage="Search Users"
      />
    </SearchCategoryListItem>
    <SearchCategoryListItem
      type={TYPE.HELP}
      onClick={() => onCategory(TYPE.HELP)}
    >
      <FormattedMessage
        id="searchCategoryList.help"
        defaultMessage="Search Help"
      />
    </SearchCategoryListItem>
  </ListView>
);

SearchCategoryList.displayName = 'SearchCategoryList';

export default SearchCategoryList;
