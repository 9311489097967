// @flow

import useContextTypeContext from '../ProcessDetailEdit/ContextTypeContext';
import useStepContext from '../Step/StepContext';

export function useConditionConfigurationContext() {
  const contextTypeContext = useContextTypeContext();
  const stepContext = useStepContext();

  if (stepContext !== undefined) {
    return {
      availableFields: stepContext.availableFields,
      fieldsByContext: stepContext.fieldsByContext,
      fields: stepContext.fields,
      node: stepContext.step,
      steps: stepContext.steps
    };
  }

  if (contextTypeContext !== undefined) {
    return {
      availableFields: contextTypeContext.availableFields,
      fieldsByContext: contextTypeContext.fieldsByContext,
      fields: contextTypeContext.fields,
      node: contextTypeContext.process,
      steps: contextTypeContext.process.steps.nodes
    };
  }

  console.warn(
    'useFieldConfigurationContext must be used within ContextTypeContext or StepContext.'
  );
  return {
    availableFields: [],
    fieldsByContext: [],
    fields: [],
    node: null,
    steps: []
  };
}

export default useConditionConfigurationContext;
