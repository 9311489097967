// @flow

import breakpoints from './breakpoints';
import colors from './colors';
import functions from './functions';
import mixins from './mixins';
import typography from './typography';
import variables from './variables';
import zIndex from './zIndex';
import { type ThemeType } from './ThemeTypes';

export const theme: ThemeType = {
  breakpoints,
  colors,
  functions,
  mixins,
  typography,
  variables,
  zIndex
};

export default theme;
