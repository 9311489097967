// @flow

import React, { PureComponent, type ComponentType } from 'react';
import { compose, setDisplayName } from 'recompose';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import Avatar from '../../Component/Avatar/Avatar';
import TokenPickerInput, {
  MAX_WIDTH
} from '../TokenPickerInput/TokenPickerInput';

const DISPLAY_NAME = 'MentionInput';

export const MentionStyle = (theme: ThemeType) => {
  return {
    icon: {
      width: '1.5rem',
      height: '1.5rem',
      position: 'relative',
      marginRight: '.5rem'
    },
    fullName: {
      ...theme.mixins.truncate,
      display: 'inline-block',
      marginRight: '.15rem',
      fontWeight: 700,
      maxWidth: MAX_WIDTH
    },
    email: {
      ...theme.mixins.truncate,
      maxWidth: MAX_WIDTH
    }
  };
};

export type MentionSuggestion = {
  display: string,
  email?: string,
  icon?: string
};

export type MentionInputType = InjectSheetProvidedProps &
  HTMLTextAreaElement & {
    data: Array<MentionSuggestion>,
    mentionInputClassName?: string
  };

export class MentionInput extends PureComponent<MentionInputType> {
  static displayName = DISPLAY_NAME;

  render() {
    const { classes, ...props } = this.props;

    return (
      <TokenPickerInput
        appendSpaceOnAdd={true}
        markup="[@__display__](/profile/__id__)"
        displayTransform={(id, display) => `@${display}`}
        renderSuggestion={(suggestion: MentionSuggestion) => {
          return (
            <>
              {suggestion.icon && (
                <Avatar image={suggestion.icon} className={classes.icon} />
              )}
              <span className={classes.fullName}>{suggestion.display}</span>
              {suggestion.email && (
                <span className={classes.email}>({suggestion.email})</span>
              )}
            </>
          );
        }}
        trigger="@"
        {...props}
      />
    );
  }
}

type EnhancedProps = {
  data: Function | Array<MentionSuggestion>,
  mentionInputClassName?: string
};

const EnhancedMentionInput: ComponentType<EnhancedProps> = compose(
  setDisplayName(DISPLAY_NAME),
  injectSheet(MentionStyle)
)(MentionInput);

export default EnhancedMentionInput;
