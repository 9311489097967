import gql from 'graphql-tag';

export const UserSelectActorFragment = gql`
  fragment UserSelectActorFragment on ActorInterface {
    id
    displayName
    thumbnailUrl
    __typename
  }
`;

const SearchFragment = gql`
  fragment SearchFragment on SearchConnectionV2 {
    nodes {
      id
      ...UserSelectActorFragment
    }
  }

  ${UserSelectActorFragment}
`;

export const UserQuery = gql`
  ${SearchFragment}

  query User($searchTerm: String, $after: String) {
    search_v2(
      filter: [
        { property: IS_DEACTIVATED, type: ACTOR, operator: EQ, value: "false" }
        { property: IS_INTEGRATION, type: ACTOR, operator: EQ, value: "false" }
        { property: IS_GROUP, type: ACTOR, operator: EQ, value: "false" }
      ]
      first: 100
      after: $after
      query: $searchTerm
      type: ACTOR
    ) {
      ...SearchFragment
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const UserGroupQuery = gql`
  ${SearchFragment}

  query UserGroup($searchTerm: String, $after: String) {
    search_v2(
      filter: [
        { property: IS_DEACTIVATED, type: ACTOR, operator: EQ, value: "false" }
        { property: IS_INTEGRATION, type: ACTOR, operator: EQ, value: "false" }
      ]
      first: 100
      after: $after
      query: $searchTerm
      type: ACTOR
    ) {
      ...SearchFragment
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
