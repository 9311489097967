// @flow

import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import TaskWebform from './TaskWebform';
import { isTestMode } from '../Run/RunTypes';
import RunComments from '../Run/RunComments';
import HtmlTitle from '../shared/Helmet/HtmlTitle';
import RunTestModeBanner from '../Run/RunTestModeBanner';
import FourOFour from '../FourOFour/FourOFour';
import TopBarLoader from '../Loading/TopBarLoader';
import logError from '../utils/logError';
import { FieldFragment } from '../Field/FieldTypeDefs';

export const TaskQuery = gql`
  ${FieldFragment}
  query TaskQuery($id: ID!) {
    task(id: $id) {
      id
      displayName
      description
      deadline
      startDate
      delayStartUntil
      status
      viewerCanComplete
      viewerCanReopen
      viewerCanReassign
      viewerCanOpen
      viewerCanSnooze
      app {
        id
      }
      context {
        id
        fields {
          nodes {
            id
            ...FieldFragment
          }
        }
        owner {
          id
          displayName
          thumbnailUrl
        }
        displayName
        status
        testMode
      }
      assignedTo {
        id
        displayName
        thumbnailUrl
      }
      inputs {
        id
        ...FieldFragment
      }
      relatedFailedTask {
        appID
        configuration {
          id
          ...FieldFragment
        }
        id
        step {
          id
        }
      }
    }
  }
`;

const runTestModeBanner = <RunTestModeBanner />;

export type OnTaskCompleted = () => mixed;

export function TaskDetail(props: {|
  banner?: React.Node,
  onTaskCompleted?: OnTaskCompleted
|}) {
  const { banner = runTestModeBanner, onTaskCompleted } = props;
  const match = useRouteMatch();
  const id = match.params.id;

  return (
    <Query
      errorPolicy="all"
      fetchPolicy="cache-and-network"
      query={TaskQuery}
      variables={{ id }}
      onError={error => logError(error, { silenceInTest: false })}
    >
      {({ data, loading }) => {
        const task = data?.task;
        const shouldShowLoader = loading && !task;
        return (
          <>
            {task && isTestMode(task?.context) && banner}
            {shouldShowLoader ? (
              <TopBarLoader />
            ) : !task ? (
              <FourOFour />
            ) : (
              <>
                <HtmlTitle>{task?.context?.displayName}</HtmlTitle>

                <TaskWebform
                  {...task}
                  fieldValues={(task?.context?.fields?.nodes || []).reduce(
                    (accumulator, currentValue) => {
                      accumulator[currentValue?.name] = currentValue?.value;
                      return accumulator;
                    },
                    {}
                  )}
                  loading={loading}
                  onTaskCompleted={onTaskCompleted}
                />
                <RunComments id={task?.context?.id} />
              </>
            )}
          </>
        );
      }}
    </Query>
  );
}

export default TaskDetail;
