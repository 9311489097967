// @flow

import React from 'react';
import Icon from '../Icon/Icon';

type Props = {
  className?: string
};

/**
 * A simple wrapper to apply `ListItem` styles to an svg icon.
 */
function ListItemIcon({ className, ...props }: Props) {
  return <Icon className={className} {...props} />;
}
ListItemIcon.displayName = 'ListItemIcon';

export default ListItemIcon;
