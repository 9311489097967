// @flow

import React, { type ElementType } from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import { COLORS, SCIENCE_BLUE } from '../../style/colors';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';

export const TabStyle = (theme: ThemeType) => {
  const ACTIVE_BORDER_WIDTH = '2px';
  const keys = Object.keys(COLORS);

  return {
    root: {
      flex: 'none',
      '& + $root': {
        marginLeft: '2rem'
      }
    },
    tab: {
      ...theme.typography.extraSmallText,
      display: 'block',
      padding: '1rem 0',
      color: theme.colors.pebbleGrey,
      textTransform: 'uppercase',
      letterSpacing: '1px',
      cursor: 'pointer',
      '&:active, &:hover': {
        color: theme.colors.pebbleGrey,
        opacity: 1
      },
      '&[aria-selected="true"], &[aria-current="page"]': {
        borderBottom: `${ACTIVE_BORDER_WIDTH} solid ${theme.colors.black}`,
        color: theme.colors.black,
        '&:active, &:hover': {
          color: theme.colors.black,
          opacity: 1
        }
      }
    },
    colorScheme: keys.reduce((accumulator, color) => {
      const hex = COLORS[color];
      return {
        ...accumulator,
        [`&[aria-selected="true"][data-color="${color}"], &[aria-current="page"][data-color="${color}"]`]: {
          borderBottom: `${ACTIVE_BORDER_WIDTH} solid ${hex}`
        }
      };
    }, {})
  };
};

type Props = InjectSheetProvidedProps & {
  children?: string,
  colorScheme?: string,
  component: ElementType,
  tabClassName?: string
};

export const Tab = (props: Props) => {
  const {
    children,
    classes,
    className,
    colorScheme = SCIENCE_BLUE,
    component: Component = 'a',
    tabClassName,
    theme,
    ...other
  } = props;

  return (
    <li className={classNames(classes.root, className)} role="presentation">
      <Component
        className={classNames(classes.tab, classes.colorScheme, tabClassName)}
        data-color={colorScheme}
        {...other}
      >
        {children}
      </Component>
    </li>
  );
};
Tab.displayName = 'Tab';

export default compose(injectSheet(TabStyle))(Tab);
