// @flow

import React, { useCallback } from 'react';
import { FormattedDate, FormattedList, FormattedTime } from 'react-intl';
import { types, View, ViewContext, type ViewMap } from '@catalytic/react-view';
import { type JSONValue } from '@catalytic/json-schema-validator-catalytic-web';
import { jsonToView } from '@catalytic/view-util-from-json-catalytic-web';
import {
  viewFromMarkdown,
  type ViewReferenceMap
} from '@catalytic/view-util-from-markdown';
import BaseText from './BaseText';
import Code from './Code';
import CodeSpan from './CodeSpan';
import Embolden from './Embolden';
import Heading from './Heading';
import Image from './Image';
import Link from './Link';
import List from './List';
import ListItem from './ListItem';
import Rule from './Rule';
import Table from './Table';
import TableCell from './TableCell';
import TableRow from './TableRow';
import sanitizer from '../utils/sanitizer';
const DISPLAY_NAME = 'Markdown';

type Props = {
  /**
   * The string to be parsed
   */
  context?: JSONValue,
  /**
   * Remove dangerous HTML
   */
  sanitizeMarkdown?: boolean,
  /**
   * Remove Markdown formatting
   */
  stripMarkdown?: boolean,
  /**
   * The string to be parsed
   */
  value?: string,
  /**
   * Map of JSON pointer data for a view
   */
  views?: ViewReferenceMap,

  /**
   * Custom view map
   */
  map?: ViewMap
};

const VIEW_MAP: ViewMap = (({
  blockquote: ({ children }: types.Blockquote) => (
    <blockquote>{children()}</blockquote>
  ),
  break: () => <br />,
  code: ({ value }: types.Code) => <Code>{value}</Code>,
  dateView: ({ node, value }: types.DateView) => (
    <FormattedDate {...node()} value={Date.parse(value)} />
  ),
  dateTimeView: ({ node, value }: types.DateTimeView) => (
    <FormattedDate {...node()} value={Date.parse(value)} />
  ),
  definition: () => <span />,
  delete: ({ children }: types.Delete) => <del>{children()}</del>,
  emailView: ({ value }: types.Link) => (
    <Link url={`mailto:${value}`}>{value}</Link>
  ),
  emphasis: ({ children }: types.Emphasis) => <em>{children()}</em>,
  heading: ({ children, node }: types.Heading) => (
    <Heading depth={node().depth}>{children()}</Heading>
  ),
  html: ({ value }: types.HTML) =>
    typeof value === 'string' ? (
      // https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
      <span dangerouslySetInnerHTML={{ __html: sanitizer(value) }} />
    ) : (
      <span>{value}</span>
    ),
  image: ({ node, value }: types.Image) => (
    <Image alt={node().alt} title={node().title} url={node().url}>
      {value}
    </Image>
  ),
  inlineCode: ({ value }: types.InlineCode) => <CodeSpan>{value}</CodeSpan>,
  integerView: ({ node, value }: types.IntegerView) => (
    <span>{value.toLocaleString(undefined, node())}</span>
  ),
  link: ({ children, node }: types.Link) => (
    <Link title={node().title} url={node().url}>
      {children()}
    </Link>
  ),
  list: ({ children, node }: types.List) => (
    <List ordered={node().ordered}>{children()}</List>
  ),
  listItem: ({ children }: types.ListItem) => <ListItem>{children()}</ListItem>,
  multipleSelectionView: ({ value }: types.MultipleSelectionView) => (
    <FormattedList type="conjunction" value={value} />
  ),
  numberView: ({ node, value }: types.NumberView) => (
    <span>{value.toLocaleString(undefined, node())}</span>
  ),
  paragraph: ({ children }: types.Paragraph) => (
    <BaseText>{children()}</BaseText>
  ),
  groupView: ({ value }: types.GroupView) => (
    <CodeSpan>{JSON.stringify(value, null, 2)}</CodeSpan>
  ),
  referenceView: ({ value }: types.ReferenceView) => (
    <Link url={`/users/${value}`}>@{value}</Link>
  ),
  root: ({ children }: types.Root) => <div>{children()}</div>,
  strong: ({ children }: types.Strong) => <Embolden>{children()}</Embolden>,
  table: ({ children }: types.Table) => <Table>{children()}</Table>,
  tableCell: ({ children, node }: types.TableCell) => (
    <TableCell align={node().align}>{children()}</TableCell>
  ),
  tableRow: ({ children }: types.TableRow) => <TableRow>{children()}</TableRow>,
  text: ({ value }: types.Text) => <span>{value}</span>,
  textAreaView: ({ value }: types.TextAreaView) => <span>{value}</span>,
  textView: ({ value }: types.TextView) => <span>{value}</span>,
  thematicBreak: () => <Rule />,
  timeView: ({ node, value }: types.TimeView) => (
    <FormattedTime {...node()} value={Date.parse(`1970-01-01T${value}`)} />
  ),
  toggleSwitchView: ({ value }: types.ToggleSwitchView) =>
    typeof value !== 'undefined' ? <span>{value.toString()}</span> : null
}: any): ViewMap);

export const MarkdownViewContextValue = {
  editor: VIEW_MAP,
  reader: VIEW_MAP
};

export const Markdown = ({
  context,
  sanitizeMarkdown = true,
  stripMarkdown = false,
  value,
  views,
  map
}: Props) => {
  const getViews = useCallback(() => {
    if (views || !context || typeof context !== 'object') {
      return;
    }
    return Object.entries(context).reduce((map, [key, value]) => {
      const reference = `/${key}`;
      map[`/${key}`] = {
        ...jsonToView((value: any)),
        reference
      };
      return map;
    }, ({}: ViewReferenceMap));
  }, [context, views]);

  const getNode = useCallback(
    () =>
      viewFromMarkdown(value || '', {
        sanitize: sanitizeMarkdown,
        strip: stripMarkdown,
        views: (getViews(): any)
      }),
    [sanitizeMarkdown, stripMarkdown, getViews, value]
  );

  const getViewMap = useCallback(() => {
    const viewMap = {
      ...VIEW_MAP,
      ...(map || {})
    };
    return {
      editor: viewMap,
      reader: viewMap
    };
  }, [map]);

  return (
    <ViewContext.Provider value={getViewMap()}>
      <View node={getNode()} value={context} />
    </ViewContext.Provider>
  );
};

Markdown.displayName = DISPLAY_NAME;

export default Markdown;
