// @flow

import gql from 'graphql-tag';

export const UnreadNotificationsQuery = gql`
  query UnreadNotifications($id: String!) {
    unreadNotifications: search_v2(
      first: 1
      filter: [
        {
          property: NOTIFICATION_ACTOR_NAME
          type: NOTIFICATION
          operator: EQ
          value: $id
        }
        {
          property: NOTIFICATION_TYPE
          type: NOTIFICATION
          operator: EQ
          value: "assignmentNotification"
        }
        {
          property: NOTIFICATION_READ
          type: NOTIFICATION
          operator: EQ
          value: "false"
        }
      ]
      sort: [{ key: CREATED_DATE, direction: DESC }]
      type: NOTIFICATION
    ) {
      totalCount
    }
  }
`;

export const NotificationsQuery = gql`
  query Notifications($after: String, $first: Int = 100, $id: String!) {
    notifications: search_v2(
      after: $after
      first: $first
      filter: [
        {
          property: NOTIFICATION_ACTOR_NAME
          type: NOTIFICATION
          operator: EQ
          value: $id
        }
        {
          property: NOTIFICATION_TYPE
          type: NOTIFICATION
          operator: EQ
          value: "assignmentNotification"
        }
      ]
      sort: [{ key: NOTIFICATION_DATE, direction: DESC }]
      type: NOTIFICATION
    ) {
      nodes {
        ... on Notification {
          contextReference {
            node {
              ... on Task {
                context {
                  displayName
                  id
                }
                displayName
                id
                status
              }
              id
            }
          }
          createdDate
          description
          displayName
          id
          read
        }
        id
      }
      totalCount
    }
    unreadNotifications: search_v2(
      first: 1
      filter: [
        {
          property: NOTIFICATION_ACTOR_NAME
          type: NOTIFICATION
          operator: EQ
          value: $id
        }
        {
          property: NOTIFICATION_TYPE
          type: NOTIFICATION
          operator: EQ
          value: "assignmentNotification"
        }
        {
          property: NOTIFICATION_READ
          type: NOTIFICATION
          operator: EQ
          value: "false"
        }
      ]
      sort: [{ key: CREATED_DATE, direction: DESC }]
      type: NOTIFICATION
    ) {
      totalCount
    }
  }
`;

export const UpdateNotificationMutation = gql`
  mutation UpdateNotification($id: ID!) {
    updateNotification(input: { id: $id, read: true }) {
      node {
        id
        read
      }
    }
  }
`;

export const ReadNotificationByTypeMutation = gql`
  mutation ReadNotificationByType($id: ID!) {
    readNotificationByType(input: { id: $id, type: ASSIGNMENT_NOTIFICATION }) {
      updatedCount
    }
  }
`;
