// @flow

import * as React from 'react';
import { graphql, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { type Viewer } from '@catalytic/graphql-api';

export const ViewerQuery = gql`
  query Viewer {
    viewer {
      canBuild
      createdDate
      displayName
      email
      fullName
      guid
      id
      isTeamAdmin
      isTeamOwner
      thumbnailUrl
    }
  }
`;

export const useViewer = () => useQuery(ViewerQuery);

const withViewer = graphql(ViewerQuery, {
  props: ({ data: { viewer } }) => viewer
});

const ViewerContext: React.Context<Viewer | void> = React.createContext();

const useViewerContext = () => React.useContext(ViewerContext);

export const ViewerProvider = withViewer(({ children, ...value }) => (
  <ViewerContext.Provider value={value}>{children}</ViewerContext.Provider>
));

export const ViewerConsumer = ViewerContext.Consumer;

export default useViewerContext;
