// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.smallText,
    minWidth: '10rem',
    display: 'block',
    padding: '.5rem 1rem',
    paddingTop: theme.functions.toRem(theme.variables.menuItemPaddingTop),
    paddingRight: theme.functions.toRem(theme.variables.menuItemPaddingRight),
    paddingBottom: theme.functions.toRem(theme.variables.menuItemPaddingBottom),
    paddingLeft: theme.functions.toRem(theme.variables.menuItemPaddingLeft),
    width: '100%',
    color: theme.colors.black,
    listStyle: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    verticalAlign: 'bottom',
    '&:first-child': {
      borderTopLeftRadius: theme.variables.borderRadiusSmall,
      borderTopRightRadius: theme.variables.borderRadiusSmall
    },
    '&:last-child': {
      borderBottomLeftRadius: theme.variables.borderRadiusSmall,
      borderBottomRightRadius: theme.variables.borderRadiusSmall
    },
    '&:hover': {
      backgroundColor: theme.colors.blueGrey,
      '& *': {
        backgroundColor: 'inherit'
      }
    },
    '&[disabled], &[disabled] *': theme.mixins.disabled
  }
}));

export type MenuItemProps = {
  className?: string,
  disabled?: boolean,
  onClick?: (...args: Array<any>) => any,
  onKeyPress?: (...args: Array<any>) => any
};

type Props = MenuItemProps & {
  children: Node
};

const MenuItem = ({
  children,
  className,
  disabled: disabledProp,
  onKeyPress,
  ...props
}: Props): Node => {
  const classes = useStyles();

  return React.Children.map(children, child => {
    // string-typed children do not have props, therefore use optional chaining
    const disabled = disabledProp || child?.props?.disabled;

    return React.isValidElement(child)
      ? React.cloneElement(child, {
          className: classNames(classes.root, className),
          disabled,
          onKeyPress: !disabled ? onKeyPress : undefined,
          ...props
        })
      : child;
  });
};
MenuItem.defaultProps = {
  disabled: false
};

export default MenuItem;
