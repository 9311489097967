// @flow

import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import toPath from '../Router/toPath';
import { type SearchListItemProps } from './SearchListItem';
import { TYPENAME, type Typename } from './SearchTypes';

export type SearchToken = {
  type: 'TEXT' | 'MARK',
  value: string
};

export type SearchMatch = {
  property: string,
  tokens: Array<SearchToken>
};

export type SearchNode = {
  __typename: string,
  description?: string,
  displayName: string,
  email?: string,
  id: string,
  nickname?: string,
  status?: string
};

export type SearchEdge = {
  node: SearchNode,
  match?: Array<SearchMatch>
};

const findDisplayNameProp = (
  displayName: string,
  map: { [key: string]: any }
): string => {
  const properties = Object.entries(map).map(([key, value]) => ({
    key,
    value
  }));
  const { key } = find(propEq('value', displayName))(properties) || {};
  return key;
};

export const toSearchItemProps = ({
  node: {
    __typename: type,
    displayName,
    description,
    email,
    id,
    nickname,
    status
  },
  match
}: SearchEdge): SearchListItemProps => {
  const [{ property, tokens } = {}] = match || [];
  const hasMatch = Array.isArray(tokens);
  const props = ({
    displayName: property === 'displayName' ? tokens : displayName,
    description:
      property !== 'displayName' && hasMatch ? tokens : description || '',
    id,
    status,
    type: ((type: any): Typename),
    url: toPath(type, { id })
  }: SearchListItemProps);

  switch (type) {
    case TYPENAME.ACTOR:
    case TYPENAME.BOT:
    case TYPENAME.GROUP:
      const displayNameProp =
        findDisplayNameProp(displayName, { email, nickname, username: id }) ||
        'fullName';
      return {
        ...props,
        description:
          hasMatch && property !== displayNameProp && property === 'username'
            ? [{ type: 'TEXT', value: '@' }, ...tokens]
            : hasMatch && property !== displayNameProp
            ? tokens
            : `@${id}`,
        displayName:
          hasMatch && property === displayNameProp && property === 'username'
            ? [{ type: 'TEXT', value: '@' }, ...tokens]
            : hasMatch && property === displayNameProp
            ? tokens
            : displayNameProp === 'username'
            ? `@${id}`
            : props.displayName
      };
    case TYPENAME.HELP:
      return {
        ...props,
        target: '_blank'
      };
    default:
      return props;
  }
};
