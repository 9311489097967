// @flow

import * as React from 'react';
import { graphql } from 'react-apollo';
import { injectIntl } from 'react-intl';
import gql from 'graphql-tag';
import intersectionWith from 'lodash/intersectionWith';
import equals from 'ramda/src/equals';
import map from 'ramda/src/map';
import pathOr from 'ramda/src/pathOr';
import { compose } from 'recompose';
import {
  Creatable as CreatableSelect,
  type OptionType
} from '@catalytic/catalytic-ui';
import { formatSelectionFieldValue } from '../Field/FieldHelpers';
import { type Field } from '../Field/FieldTypes';
import { type FileColumnsInputs, type TableIDs } from '../Step/StepHelpers';

export const DataTablesQuery = gql`
  query DataTablesQuery($ids: [ID!]) {
    tables(ids: $ids) {
      columns {
        displayName
        id
      }
      id
    }
  }
`;

export const FilesColumnsQuery = gql`
  query FilesColumnsQuery($inputs: [FileColumnsInput!]) {
    filesColumns(inputs: $inputs) {
      columns {
        displayName
        type
      }
      id
    }
  }
`;

export const toSelectOption = (column: Field): OptionType => ({
  label: column.displayName,
  value: column.displayName
});

type FileColumn = { displayName: string, id: string };

type Props = {
  columns?: Array<{ columns: Array<FileColumn>, id: string }>,
  defaultValue?: any,
  error?: Object,
  fileColumnsInputs: FileColumnsInputs,
  intl: Object,
  loading?: boolean,
  tableIDs: TableIDs
};

export function ColumnSelect({
  columns,
  defaultValue: defaultValueProp,
  error,
  fileColumnsInputs,
  intl,
  loading,
  tableIDs,
  ...other
}: Props) {
  const defaultValue = formatSelectionFieldValue(defaultValueProp);
  const columnsOptions = map(
    compose(map(toSelectOption), pathOr([], ['columns']))
  )(columns || []);
  const options =
    Array.isArray(columns) && columns.length > 0
      ? intersectionWith(...columnsOptions, equals)
      : [];

  return (
    <CreatableSelect
      defaultValue={defaultValue}
      isLoading={loading}
      options={options}
      placeholder={intl.formatMessage({
        id: 'table.select.placeholder',
        defaultMessage: 'Select a Column...'
      })}
      {...other}
    />
  );
}
ColumnSelect.displayName = 'ColumnSelect';

type EnhancedProps = {
  defaultValue?: any,
  fileColumnsInputs: FileColumnsInputs,
  tableIDs: TableIDs
};

const EnhancedColumnSelect: React.ComponentType<EnhancedProps> = compose(
  graphql(FilesColumnsQuery, {
    options: ({ fileColumnsInputs }) => {
      return {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: { inputs: fileColumnsInputs }
      };
    },
    props: ({ data }) => {
      const { loading, error, filesColumns: columns } = data || {};

      return { loading, error, columns };
    },
    skip: ({ fileColumnsInputs, tableIDs }) =>
      (Array.isArray(fileColumnsInputs) && fileColumnsInputs.length === 0) ||
      (Array.isArray(tableIDs) && tableIDs.length > 0)
  }),
  graphql(DataTablesQuery, {
    options: ({ tableIDs }) => {
      return {
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: { ids: tableIDs }
      };
    },
    props: ({ data }) => {
      const { loading, error, tables: columns } = data || {};

      return { loading, error, columns };
    },
    skip: ({ fileColumnsInputs, tableIDs }) =>
      (Array.isArray(tableIDs) && tableIDs.length === 0) ||
      (Array.isArray(fileColumnsInputs) && fileColumnsInputs.length > 0)
  }),
  injectIntl
)(ColumnSelect);

export default EnhancedColumnSelect;
