// @flow
/* eslint-disable security/detect-non-literal-fs-filename */

import * as React from 'react';
import cssVendor from 'css-vendor';
import Dotdotdot from 'react-dotdotdot';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { type ThemeType } from '../style/ThemeTypes';

type Props = {
  // It would be nice if `children: string | React.Element<typeof FormattedMessage>`
  // but `ProcessVersionList.js` and `SearchListItem.js` requires a `React.Node` type
  children: React.Node,
  clamp: number,
  className?: string
};

const useStyles = makeStyles((theme: ThemeType) => ({
  truncate: {
    ...theme.mixins.truncate,
    ...theme.mixins.breakWord,
    display: 'inline-block',
    maxWidth: '100%',
    verticalAlign: 'top'
  },
  lineClamp: ({ clamp }: { clamp: number }) => ({
    ...theme.mixins.breakWord,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-inline-box',
    '-webkit-line-clamp': clamp,
    maxWidth: '100%',
    verticalAlign: 'top'
  }),
  dotdotdot: {
    ...theme.mixins.breakWord,
    display: 'inline-block',
    maxWidth: '100%',
    verticalAlign: 'top'
  }
}));

const Clamp = ({ children, className, clamp }: Props) => {
  const classes = useStyles({ clamp });
  const title = typeof children === 'string' ? children : undefined;

  // Use CSS solution if number of lines is less than 2.
  if (clamp < 2) {
    return (
      <span className={classNames(classes.truncate, className)} title={title}>
        {children}
      </span>
    );
  }

  // Use CSS3 solution if supported.
  if (cssVendor.supportedProperty('-webkit-line-clamp')) {
    return (
      <span className={classNames(classes.lineClamp, className)} title={title}>
        {children}
      </span>
    );
  }

  // The number of lines displayed in IE/Firefox is 1 less than clamp property value.
  // https://github.com/CezaryDanielNowak/React-dotdotdot/issues/10
  return (
    <Dotdotdot
      clamp={clamp + 1}
      className={classNames(classes.dotdotdot, className)}
      tagName="span"
      title={title}
    >
      {children}
    </Dotdotdot>
  );
};
Clamp.displayName = 'Clamp';
Clamp.defaultProps = { clamp: 2 };

export default Clamp;
