// @flow

import * as React from 'react';
import Loading from './Loading';

// Suspense component with Loading component fallback used by all screens.
type Props = { children: React.Node };

const SuspenseLoader = ({ children }: Props) => (
  <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
);

export default SuspenseLoader;
