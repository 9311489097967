// @flow

import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import path from 'path';
import {
  Clamp,
  ListItem,
  ListItemContents as ListItemContent,
  ListItemFooter,
  ListItemHeader,
  ListItemIcon,
  Menu,
  MenuItem,
  showToast,
  TOAST_TYPE,
  type ThemeType
} from '@catalytic/catalytic-ui';
import { FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ReactComponent as RunIcon } from '@catalytic/catalytic-icons/lib/icons/run.svg';
import { type Viewer } from '@catalytic/graphql-api';
import parenthesize from '../utils/parenthesize';
import {
  ProcessApprovalContextFragment,
  ProcessIsActiveQuery
} from './ProcessTypeDefs';
import ProcessStatusBadge from './ProcessStatusBadge';
import { type Process } from './ProcessTypes';
import { isWorkflowApprovalPending } from './ProcessUtils';
import { PROCESS as PROCESS_PATH, STEPS as STEPS_PATH } from '../const/path';
import { type Team } from '../Team/TeamTypes';

const DIVIDER = '|';

export const ProcessVersionListItemFragment = gql`
  ${ProcessApprovalContextFragment}
  fragment ProcessVersionListItemFragment on ContextType {
    ...ProcessApprovalContextFragment
    ancestorID
    displayName
    id
    isActive
    modifiedBy {
      id
      displayName
    }
    publishedDate
    updatedAt
    createdDate
  }
`;

export const PublishProcessVersionMutation = gql`
  ${ProcessVersionListItemFragment}

  mutation PublishProcessVersion($input: UpdateVersionInput!) {
    updateContextTypeVersion(input: $input) {
      id
      ...ProcessVersionListItemFragment
    }
  }
`;

export const ArchiveProcessVersionMutation = gql`
  ${ProcessVersionListItemFragment}

  mutation UpdateContextType($input: UpdateContextTypeInput!) {
    updateContextType(input: $input) {
      id
      isArchived
      ...ProcessVersionListItemFragment
    }
  }
`;

export const CreateProcessVersionMutation = gql`
  mutation CreateProcessVersion($input: CreateVersionInput!) {
    createContextTypeVersion(input: $input) {
      id
    }
  }
`;

export const RequestPublishApprovalMutation = gql`
  ${ProcessApprovalContextFragment}
  mutation RequestPublishApproval($input: RequestPublishApprovalInput!) {
    requestContextTypePublishApproval(input: $input) {
      id
      isActive
      ...ProcessApprovalContextFragment
    }
  }
`;

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    cursor: 'pointer'
  },
  clamp: {
    marginRight: '0.5rem'
  },
  context: {
    color: theme.colors.pebbleGrey,
    fontWeight: 'normal'
  },
  separator: {
    margin: '0 .25rem'
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  main: {
    flex: '1',
    textAlign: 'left',
    width: '100%'
  },
  menu: {
    flex: '1',
    flexBasis: '1rem',
    flexGrow: '0'
  }
}));

type Props = Process & {
  hide: void => void,
  onClick?: (SyntheticEvent<HTMLDivElement>) => mixed,
  pathVersionID: string,
  refetch?: void => any,
  team: Team,
  viewer: Viewer
};

const ProcessVersionListItem = (props: Props) => {
  const {
    hide,
    onClick,
    pathVersionID,
    refetch,
    team,
    viewer,
    ...process
  } = props;

  const {
    ancestorID,
    displayName,
    id,
    isActive,
    modifiedBy,
    publishedDate,
    createdDate,
    updatedAt
  } = process;

  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const [publishProcessVersion] = useMutation(PublishProcessVersionMutation);
  const [archiveProcessVersion] = useMutation(ArchiveProcessVersionMutation);
  const [createProcessVersion] = useMutation(CreateProcessVersionMutation);
  const [requestPublishApproval] = useMutation(RequestPublishApprovalMutation);
  const wasLastModifiedByViewer =
    typeof modifiedBy === 'object' && viewer && viewer.id === modifiedBy.id;
  const requirePublishApproval = team?.requirePublishApproval;
  const isVersionApprovalPending = isWorkflowApprovalPending(process);

  return (
    <ListItem className={classes.root} data-testid={id}>
      <ListItemIcon>
        <RunIcon />
      </ListItemIcon>
      <ListItemContent className={classes.content}>
        <button className={classes.main} onClick={onClick}>
          <ListItemHeader>
            <Clamp clamp={1} className={classes.clamp}>
              {displayName}&nbsp;
              <span className={classes.context}>
                {!isActive && publishedDate && (
                  <FormattedDate
                    id="process.version.published.date"
                    value={publishedDate}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  >
                    {parenthesize}
                  </FormattedDate>
                )}
              </span>
            </Clamp>
            <ProcessStatusBadge process={process} />
          </ListItemHeader>
          <ListItemFooter>
            {typeof createdDate === 'string' && (
              <>
                <FormattedMessage
                  id="process.created.label"
                  defaultMessage="Created "
                />
                <FormattedDate
                  id="process.created.date"
                  value={createdDate}
                  year="numeric"
                  month="long"
                  day="2-digit"
                />
              </>
            )}
            {typeof modifiedBy === 'object' && (
              <>
                <span className={classes.separator}>{DIVIDER}</span>
                <span data-testid="modified-by-label">
                  {wasLastModifiedByViewer && (
                    <FormattedMessage
                      id="process.modified.by.viewer"
                      defaultMessage={`Last updated by You${
                        typeof updatedAt === 'string' ? ' on' : ''
                      }`}
                    />
                  )}
                  {!wasLastModifiedByViewer && (
                    <FormattedMessage
                      id="process.modified.by.user"
                      defaultMessage={`Last updated by {name}${
                        typeof updatedAt === 'string' ? ' on' : ''
                      }`}
                      values={{ name: modifiedBy.displayName }}
                    />
                  )}
                  {typeof updatedAt === 'string' && (
                    <>
                      {' '}
                      <FormattedDate
                        id="process.version.updated.date"
                        value={updatedAt}
                        year="numeric"
                        month="long"
                        day="2-digit"
                      />
                    </>
                  )}
                </span>
              </>
            )}
          </ListItemFooter>
        </button>
        <div className={classes.menu}>
          <Menu>
            {!requirePublishApproval && (
              <MenuItem
                data-testid="publish"
                disabled={isActive}
                onClick={e => {
                  e.preventDefault();
                  publishProcessVersion({
                    awaitRefetchQueries: true,
                    refetchQueries: [
                      'StepQuery',
                      'ContextTypeStepsQuery',
                      'ContextTypeAvailableFieldsQuery',
                      {
                        query: ProcessIsActiveQuery,
                        variables: { id }
                      }
                    ],
                    variables: {
                      input: { id: ancestorID, version: id }
                    }
                  })
                    .then(() => {
                      showToast(
                        TOAST_TYPE.success,
                        <FormattedMessage
                          id="process.publish.success"
                          defaultMessage="Published version: {versionName}"
                          values={{ versionName: displayName }}
                        />
                      );
                    })
                    .then(() => {
                      if (pathVersionID === ancestorID) {
                        hide();
                      } else {
                        history.replace(
                          path.join(PROCESS_PATH, ancestorID, STEPS_PATH)
                        );
                      }
                    })
                    .catch(() => {
                      showToast(
                        TOAST_TYPE.error,
                        <FormattedMessage
                          id="process.publish.error"
                          defaultMessage="There was a problem publishing this Workflow. Please try again."
                        />
                      );
                    });
                }}
              >
                <button>
                  <FormattedMessage
                    id="ProcessVersionListItem.Publish"
                    defaultMessage="Publish"
                  />
                </button>
              </MenuItem>
            )}
            {requirePublishApproval && (
              <MenuItem
                data-testid="requestpublish"
                disabled={isActive || isVersionApprovalPending}
                onClick={e => {
                  e.preventDefault();
                  requestPublishApproval({
                    variables: {
                      input: { id }
                    }
                  })
                    .then(() => {
                      showToast(
                        TOAST_TYPE.success,
                        <FormattedMessage
                          id="process.requestpublish.success"
                          defaultMessage="Submitted request to publish: {versionName}"
                          values={{ versionName: displayName }}
                        />
                      );
                    })
                    .catch(() => {
                      showToast(
                        TOAST_TYPE.error,
                        <FormattedMessage
                          id="process.publish.error"
                          defaultMessage="There was a problem requesting to publish this Workflow. Please try again."
                        />
                      );
                    });
                }}
              >
                <button>
                  <FormattedMessage
                    id="ProcessVersionListItem.RequestPublish"
                    defaultMessage="Request to Publish"
                  />
                </button>
              </MenuItem>
            )}
            <MenuItem
              data-testid="duplicate-version"
              onClick={e => {
                e.preventDefault();
                createProcessVersion({
                  variables: {
                    input: {
                      id
                    }
                  }
                })
                  .then(result => {
                    showToast(
                      TOAST_TYPE.success,
                      <FormattedMessage
                        id="process.duplicate-version.success"
                        defaultMessage="Duplicated version: {versionName}"
                        values={{ versionName: displayName }}
                      />
                    );
                    return result;
                  })
                  .then(({ data }) => {
                    const versionID = data?.createContextTypeVersion?.id;
                    history.replace(
                      path.join(PROCESS_PATH, versionID, STEPS_PATH)
                    );
                  })
                  .catch(() => {
                    showToast(
                      TOAST_TYPE.error,
                      <FormattedMessage
                        id="process.duplicate-version.error"
                        defaultMessage="There was a problem duplicating this version. Please try again."
                      />
                    );
                  });
              }}
            >
              <button>
                <FormattedMessage
                  id="ProcessVersionListItem.DuplicateVersion"
                  defaultMessage="Duplicate Version"
                />
              </button>
            </MenuItem>
            <MenuItem
              data-testid="delete"
              disabled={isActive || isVersionApprovalPending}
              onClick={e => {
                e.preventDefault();
                if (
                  window.confirm(
                    intl.formatMessage({
                      id: 'process.delete.prompt',
                      defaultMessage:
                        'Are you sure you want to delete this version? This action cannot be undone.'
                    })
                  )
                ) {
                  archiveProcessVersion({
                    awaitRefetchQueries: true,
                    refetchQueries: ['LocalSearchQuery'],
                    variables: {
                      input: {
                        id,
                        isArchived: true
                      }
                    }
                  })
                    .then(() => {
                      showToast(
                        TOAST_TYPE.success,
                        <FormattedMessage
                          id="process.delete.success"
                          defaultMessage="Deleted version: {versionName}"
                          values={{ versionName: displayName }}
                        />
                      );
                    })
                    .then(() => {
                      if (pathVersionID === id) {
                        history.replace(
                          path.join(PROCESS_PATH, ancestorID, STEPS_PATH)
                        );
                      } else if (typeof refetch === 'function') {
                        refetch();
                      }
                    })
                    .catch(() => {
                      showToast(
                        TOAST_TYPE.error,
                        <FormattedMessage
                          id="process.delete.error"
                          defaultMessage="There was a problem deleting this version. Please try again."
                        />
                      );
                    });
                }
              }}
            >
              <button>
                <FormattedMessage
                  id="ProcessVersionListItem.Delete"
                  defaultMessage="Delete"
                />
              </button>
            </MenuItem>
          </Menu>
        </div>
      </ListItemContent>
    </ListItem>
  );
};
ProcessVersionListItem.displayName = 'ProcessVersionListItem';

export default ProcessVersionListItem;
