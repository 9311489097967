// @flow

import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { type ThemeType } from '../../style/ThemeTypes';

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    ...theme.typography.extraSmallText,
    ...theme.mixins.truncate,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.15rem .5rem',
    background: theme.colors.ashGrey,
    borderRadius: theme.variables.borderRadiusSmall,
    color: theme.colors.white,
    textAlign: 'center',
    textTransform: 'capitalize',
    verticalAlign: 'top',
    fontWeight: 500
  },
  icon: {
    display: 'inline-flex',
    marginRight: '.25rem',
    fontSize: '1.25em' // Icon size relative 1.25em relative to badge parent
  },
  colorScheme: theme.mixins.colorScheme,
  invertScheme: theme.mixins.invertScheme,
  compact: {
    padding: '.05rem .5rem',
    fontSize: '0.685rem'
  }
}));

type Props = {
  children: React.Node,
  className?: string,
  colorScheme?: string,
  compact?: boolean,
  icon?: React.Node,
  invertScheme?: boolean
};

const Badge = ({
  children,
  className,
  colorScheme,
  compact,
  icon,
  invertScheme,
  ...props
}: Props) => {
  const classes = useStyles();

  return (
    <span
      className={classNames(
        classes.root,
        {
          [invertScheme ? classes.invertScheme : classes.colorScheme]: true
        },
        {
          [classes.compact]: compact
        },
        className
      )}
      data-color={colorScheme || undefined}
      {...props}
    >
      {icon && <span className={classes.icon}>{icon}</span>}
      {children}
    </span>
  );
};
Badge.displayName = 'Badge';
Badge.defaultProps = {
  compact: false
};

export default Badge;
