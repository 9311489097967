// @flow

import React from 'react';
import classNames from 'classnames';
import { COLORS } from '../../style/colors';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';

const Style = (theme: ThemeType) => {
  const keys = Object.keys(COLORS);
  const PROGRESS_BACKGROUND_COLOR = theme.colors.lightGrey;
  const PROGRESS_VALUE_COLOR = theme.colors.black;

  const PROGRESS_STYLES = (value, background) => {
    return {
      backgroundColor: background,
      color: value,
      '&::-webkit-progress-bar': {
        backgroundColor: background
      },
      '&::-webkit-progress-value': {
        backgroundColor: value
      },
      '&::-moz-progress-bar': {
        backgroundColor: value
      }
    };
  };

  return {
    root: {
      ...PROGRESS_STYLES(PROGRESS_VALUE_COLOR, PROGRESS_BACKGROUND_COLOR),
      appearance: 'none', // Reset the default appearance
      overflow: 'hidden',
      display: 'block',
      width: '100%',
      height: '.75rem',
      border: 'none', // Get rid of default border in Firefox
      borderRadius: '0.125em',
      '&::-ms-fill': {
        border: 'none'
      }
    },
    colorScheme: keys.reduce((accumulator, color) => {
      const hex = COLORS[color];

      return {
        ...accumulator,
        [`&[data-color="${color}"]`]: {
          color: hex,
          '&::-webkit-progress-value': {
            backgroundColor: hex
          },
          '&::-moz-progress-bar': {
            backgroundColor: hex
          }
        }
      };
    }, {}),
    complete: {
      ...PROGRESS_STYLES(theme.colors.olive, theme.colors.olive)
    }
  };
};

type Props = InjectSheetProvidedProps &
  HTMLProgressElement & {
    colorScheme?: string
  };

const Progress = ({
  classes,
  className,
  colorScheme,
  max,
  theme,
  title,
  value,
  ...props
}: Props) => {
  return (
    <progress
      className={classNames(
        classes.root,
        {
          [value === 100 ? classes.complete : classes.colorScheme]: true
        },
        className
      )}
      data-color={colorScheme || undefined}
      title={title || `${value}%`}
      max={max} // Must declare `max` before `value` prop in IE11
      value={value}
      {...props}
    >
      {title}
    </progress>
  );
};

Progress.displayName = 'Progress';
Progress.defaultProps = {
  max: 100,
  value: 0
};

export default injectSheet(Style)(Progress);
