// @flow

import { compile } from '@catalytic/url-to-regexp';
import * as PATH from '../const/path';
import { HELP } from '../const/url';

// Render application path to an actor
export const toActor = compile(`${PATH.USERS}/:id`);
// Render application path to a data table
export const toDataTable = compile(`${PATH.DATATABLE}/:id`);
// Render application path to a group
export const toGroup = compile(`${PATH.GROUPS}/:id`);
// Render application path to a help
export const toHelp = ({ id }: { id: string }) => new URL(id, HELP).toString();
// Render application path to a process
export const toProcess = compile(`${PATH.PROCESS}/:id`);
// Render application path to a run
export const toRun = compile(`${PATH.RUNS}/:id`);

const toTask = compile(`${PATH.TASKS_V2}/:id`);

export const TYPE = {
  ACTOR: 'Actor',
  BOT: 'Bot',
  CONTEXT_TYPE: 'ContextType',
  CONTEXT: 'Context',
  DATA_TABLE: 'DataTable',
  GROUP: 'Group',
  HELP: 'Help',
  TASK: 'Task'
};

export type Type = $Values<typeof TYPE>;

const toPath = (type: Type, params: { id: string }) =>
  type === TYPE.CONTEXT_TYPE
    ? toProcess(params)
    : type === TYPE.CONTEXT
    ? toRun(params)
    : type === TYPE.DATA_TABLE
    ? toDataTable(params)
    : type === TYPE.HELP
    ? toHelp(params)
    : type === TYPE.TASK
    ? toTask(params)
    : toActor(params);

export default toPath;
