// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.header6,
    marginLeft: '.5rem',
    fontWeight: 600,
    color: theme.colors.black,
    '&:hover, &:focus, &:active': {
      color: theme.colors.battleshipGrey
    },
    [theme.breakpoints.gte.mobile.portrait]: {
      ...theme.typography.header5,
      marginLeft: '1.125rem'
    }
  },
  active: {
    fontWeight: 700
  }
}));

type Props = {
  to: string,
  children: string | React.Element<typeof FormattedMessage>
};

const NavLogo = ({ children, to }: Props) => {
  const classes = useStyles();

  return (
    <NavLink to={to} className={classes.root} activeClassName={classes.active}>
      {children}
    </NavLink>
  );
};
NavLogo.displayName = 'NavLogo';
NavLogo.defaultProps = {
  to: '/'
};

export default NavLogo;
