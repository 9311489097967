// @flow

import React from 'react';
import keycode from 'keycode';
import path from 'path';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import AuthenticatedWebApp from './AuthenticatedWebApp';
import withPageTracking from '../Analytics/withPageTracking';
import SuspenseLoader from '../Loading/SuspenseLoader';
import AuthenticatedRoute from '../Router/AuthenticatedRoute';
import ExternalRedirect from '../Router/ExternalRedirect';
import {
  AUTH as AUTH_PATH,
  BUILD as BUILD_PATH,
  DATATABLES as DATATABLES_PATH,
  EMAIL as EMAIL_PATH,
  EMAIL_CONFIRM as EMAIL_CONFIRM_PATH,
  EMAIL_CONFIRMATION as EMAIL_CONFIRMATION_PATH,
  FIND_TEAMS as FIND_TEAMS_PATH,
  FLOWCHART as FLOWCHART_PATH,
  FORM as FORM_PATH,
  FORMS as FORMS_PATH,
  HOME as HOME_PATH,
  INTEGRATIONS as INTEGRATIONS_PATH,
  INVALID as INVALID_PATH,
  JOIN as JOIN_PATH,
  LOGIN as LOGIN_PATH,
  MODELS as MODELS_PATH,
  PROFILE as PROFILE_PATH,
  PUSHBOTS as PUSHBOTS_PATH,
  REQUEST_INVITE as REQUEST_INVITE_PATH,
  ROOT as ROOT_PATH,
  START as START_PATH,
  SUDO as SUDO_PATH,
  TASKS as TASKS_PATH,
  TEAM as TEAM_PATH,
  TRIGGERS as TRIGGERS_PATH,
  WEBFORM as WEBFORM_PATH,
  WEBFORMS as WEBFORMS_PATH
} from '../const/path';
import { HELP as HELP_URL } from '../const/url';

const useStyles = makeStyles({
  noFocus: {
    '&:focus, & *:focus': {
      outline: 'none'
    }
  }
});

// Paths used for dynamic breadcrumbs
export const formPath = path.join(FORM_PATH, ':name');
export const webformPath = path.join(WEBFORM_PATH, ':id');

const LazyEmailConfirmation = React.lazy(() =>
  import(
    /* webpackChunkName: "EmailConfirmation" */ '../Login/EmailConfirmation'
  )
);
const EmailConfirmation = props => (
  <SuspenseLoader>
    <LazyEmailConfirmation {...props} />
  </SuspenseLoader>
);
const LazyFindTeams = React.lazy(() =>
  import(/* webpackChunkName: "FindTeams" */ '../Login/FindTeams')
);
const FindTeams = props => (
  <SuspenseLoader>
    <LazyFindTeams {...props} />
  </SuspenseLoader>
);
const LazyJoin = React.lazy(() =>
  import(/* webpackChunkName: "Join" */ '../Login/Join')
);
const Join = props => (
  <SuspenseLoader>
    <LazyJoin {...props} />
  </SuspenseLoader>
);
const LazyJoinInvalid = React.lazy(() =>
  import(/* webpackChunkName: "JoinInvalid" */ '../Login/JoinInvalid')
);
const JoinInvalid = props => (
  <SuspenseLoader>
    <LazyJoinInvalid {...props} />
  </SuspenseLoader>
);
const LazyLogin = React.lazy(() =>
  import(/* webpackChunkName: "Login" */ '../Login/Login')
);
const Login = props => (
  <SuspenseLoader>
    <LazyLogin {...props} />
  </SuspenseLoader>
);
const LazyLoginEmail = React.lazy(() =>
  import(/* webpackChunkName: "LoginEmail" */ '../Login/LoginEmail')
);
const LoginEmail = props => (
  <SuspenseLoader>
    <LazyLoginEmail {...props} />
  </SuspenseLoader>
);
const LazyLoginEmailConfirm = React.lazy(() =>
  import(
    /* webpackChunkName: "LoginEmailConfirm" */ '../Login/LoginEmailConfirm'
  )
);
const LoginEmailConfirm = props => (
  <SuspenseLoader>
    <LazyLoginEmailConfirm {...props} />
  </SuspenseLoader>
);
const LazyRequestInvite = React.lazy(() =>
  import(/* webpackChunkName: "RequestInvite" */ '../Login/RequestInvite')
);
const RequestInvite = props => (
  <SuspenseLoader>
    <LazyRequestInvite {...props} />
  </SuspenseLoader>
);
const LazySudo = React.lazy(() =>
  import(/* webpackChunkName: "Sudo" */ '../Login/Sudo')
);
const Sudo = props => (
  <SuspenseLoader>
    <LazySudo {...props} />
  </SuspenseLoader>
);
const LazyWebformContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "WebformContainer" */ '../Webform/WebformContainer'
  )
);
const WebformContainer = props => (
  <SuspenseLoader>
    <LazyWebformContainer {...props} />
  </SuspenseLoader>
);

// Add a class to document body after mouse events
// Remove the class after keyboard events
function useMouseIntent(mouseIntentClassName: string): void {
  const [mouseIntent, setMouseIntent] = React.useState<boolean>(true);

  // Add and remove mouse intent listeners
  React.useEffect(() => {
    const addMouseIntent = () => {
      setMouseIntent(true);
    };
    const removeMouseIntent = (event: KeyboardEvent) => {
      if (keycode.isEventKey(event, 'tab')) setMouseIntent(false);
    };
    document.addEventListener('mousedown', addMouseIntent);
    document.addEventListener('keydown', removeMouseIntent);
    return () => {
      document.removeEventListener('mousedown', addMouseIntent);
      document.removeEventListener('keydown', removeMouseIntent);
    };
  }, []);

  // Toggle mouse intent class
  React.useEffect(() => {
    if (document.body !== null) {
      if (mouseIntent === true)
        document.body.classList.add(mouseIntentClassName);
      if (mouseIntent === false)
        document.body.classList.remove(mouseIntentClassName);
    }
  }, [mouseIntent, mouseIntentClassName]);
}

export const WebApp = () => {
  const classes = useStyles();
  useMouseIntent(classes.noFocus);

  return (
    <Switch>
      <Route exact path={ROOT_PATH}>
        <ExternalRedirect path={HOME_PATH} />
      </Route>
      <ExternalRedirect exact path={LOGIN_PATH} />
      <ExternalRedirect path={DATATABLES_PATH} />
      <ExternalRedirect path={FLOWCHART_PATH} />
      <ExternalRedirect path={FORMS_PATH} />
      <ExternalRedirect path={INTEGRATIONS_PATH} />
      <ExternalRedirect path={path.join(BUILD_PATH, ':id')} />
      <ExternalRedirect path={new URL('*', HELP_URL).toString()} />
      <ExternalRedirect path={path.join(START_PATH, ':id')} />
      <ExternalRedirect path={MODELS_PATH} />
      <ExternalRedirect path={PROFILE_PATH} />
      <ExternalRedirect path={PUSHBOTS_PATH} />
      <ExternalRedirect path={TASKS_PATH} />
      <ExternalRedirect path={TEAM_PATH} />
      <ExternalRedirect path={TRIGGERS_PATH} />
      <ExternalRedirect path={WEBFORMS_PATH} />
      <Route path={formPath} exact component={WebformContainer} />
      <Route path={webformPath} exact component={WebformContainer} />
      <Route path={AUTH_PATH} exact component={Login} />
      <Route
        path={path.join(AUTH_PATH, EMAIL_PATH)}
        exact
        component={LoginEmail}
      />
      <Route
        path={path.join(AUTH_PATH, EMAIL_CONFIRM_PATH)}
        exact
        component={LoginEmailConfirm}
      />
      <Route path={path.join(AUTH_PATH, JOIN_PATH)} exact component={Join} />
      <Route
        path={path.join(AUTH_PATH, JOIN_PATH, INVALID_PATH)}
        exact
        component={JoinInvalid}
      />
      <Route path={path.join(AUTH_PATH, SUDO_PATH)} exact component={Sudo} />
      <Route
        path={path.join(AUTH_PATH, EMAIL_CONFIRMATION_PATH)}
        exact
        component={EmailConfirmation}
      />
      <Route
        path={path.join(AUTH_PATH, REQUEST_INVITE_PATH)}
        exact
        component={RequestInvite}
      />
      <Route
        path={path.join(AUTH_PATH, FIND_TEAMS_PATH)}
        exact
        component={FindTeams}
      />
      <AuthenticatedRoute component={AuthenticatedWebApp} />
    </Switch>
  );
};
WebApp.displayName = 'WebApp';

export default withPageTracking(WebApp);
