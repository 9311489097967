// @flow

import React, { type Node } from 'react';
import { ReactComponent as DataTableIcon } from '@catalytic/catalytic-icons/lib/icons/data-table.svg';
import { ReactComponent as HelpIcon } from '@catalytic/catalytic-icons/lib/icons/help.svg';
import { ReactComponent as InstanceIcon } from '@catalytic/catalytic-icons/lib/icons/instance.svg';
import { ReactComponent as PersonCardIcon } from '@catalytic/catalytic-icons/lib/icons/person-card.svg';
import { ReactComponent as RunIcon } from '@catalytic/catalytic-icons/lib/icons/run.svg';
import { ReactComponent as TaskIcon } from '@catalytic/catalytic-icons/lib/icons/task.svg';
import { ReactComponent as WorkflowIcon } from '@catalytic/catalytic-icons/lib/icons/workflow.svg';

import { TYPE, type Type } from './SearchTypes';

type Props = {
  type?: Type
};

const SearchTypeIcon = ({ type, ...props }: Props): Node => {
  switch (type) {
    case TYPE.ACTOR:
      return <PersonCardIcon {...props} />;
    case TYPE.BOT:
      return <RunIcon {...props} />;
    case TYPE.CONTEXT:
      return <InstanceIcon {...props} />;
    case TYPE.DATA_TABLE:
      return <DataTableIcon {...props} />;
    case TYPE.HELP:
      return <HelpIcon {...props} />;
    case TYPE.TASK:
      return <TaskIcon {...props} />;
    case TYPE.CONTEXT_TYPE:
      return <WorkflowIcon {...props} />;
    default:
      return <WorkflowIcon {...props} />;
  }
};

SearchTypeIcon.displayName = 'SearchTypeIcon';

export default SearchTypeIcon;
