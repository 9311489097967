// @flow

import * as React from 'react';
import StatusBadge from '../Status/StatusBadge';
import { type Status } from '../Status/StatusTypes';
import logError from '../../utils/logError';

type Props = {
  customStatus?: string,
  status: Status
};

export class DetailBadge extends React.Component<Props> {
  componentDidCatch(error: Error) {
    logError(new Error(`[Component-caught error]: ${error.toString()}`));
  }

  shouldComponentUpdate(nextProps: Props) {
    if (!nextProps.status) {
      return false;
    }

    return true;
  }

  render() {
    return <StatusBadge {...this.props} />;
  }
}
DetailBadge.displayName = 'DetailBadge';

export default DetailBadge;
