// @flow

import { ApolloLink } from 'apollo-link';
import jsCookie from 'js-cookie';
import localForage from 'localforage';
import logError from '../utils/logError';

interface Store {
  get(key: string): string;
  set(key: string, value: string): void;
  remove(key: string): void;
}

export type Configuration = {
  // Storage key used to save user's session info
  sessionKey: string,
  // Storage key used to save user's application cache
  cacheKey: string
};

export class MockStorage {
  _storage: Map<any, any>;

  get length() {
    if (this && this._storage) {
      return this._storage.size;
    } else {
      return 0;
    }
  }

  constructor() {
    this._storage = new Map();
  }

  getItem(keyName: any) {
    return this._storage.get(keyName);
  }

  setItem(keyName: any, keyValue: any) {
    this._storage.set(keyName, keyValue);
  }

  removeItem(keyName: any) {
    this._storage.delete(keyName);
  }

  clear() {
    this._storage.clear();
  }

  key(keyNumber: number) {
    return Array.from(this._storage.keys())[keyNumber];
  }
}

export type Options = {
  // Storage used to save session info. Defaults to localStorage.
  sessionStore?: Store,
  // Storage used to save session info. Defaults to localStorage.
  cacheStore?: Store
};

export function getLocalStorage() {
  let storage;
  try {
    storage = localForage;
  } catch (error) {
    logError(error);
    storage = new MockStorage();
  }

  return storage;
}

export const createStorage = (store: Object = getLocalStorage()): Store => ({
  get(key: string) {
    return store[key];
  },
  set(key: string, value: string) {
    store[key] = value;
  },
  remove(key: string) {
    delete store[key];
  }
});

export const createMemoryStorage = (store: Object = {}): Store =>
  createStorage(store);

export default class UserSessionLink extends ApolloLink {
  constructor(
    { sessionKey, cacheKey }: Configuration,
    { sessionStore, cacheStore }: Options
  ) {
    super();
    this.sessionKey = sessionKey;
    this.cacheKey = cacheKey;
    this.sessionStore = sessionStore || jsCookie;
    this.cacheStore = cacheStore || createStorage();
  }

  getKey() {
    return this.sessionStore.get(this.sessionKey);
  }

  hasKey() {
    return !!this.getKey();
  }

  invalidateSession() {
    this.sessionStore.remove(this.sessionKey);
    this.cacheStore.remove(this.cacheKey);
  }

  request(operation: Object, forward: Function) {
    return forward(operation);
  }
}
