// @flow

import theme from '../style/theme';
import type { StylesheetClasses, Creator } from '../style/ThemeTypes';

const trasformClassNamesArrayToObject = (
  classNames: Array<string>
): StylesheetClasses =>
  classNames.reduce(
    (classes, className) => ({
      ...classes,
      [className]: className
    }),
    {}
  );

/**
 * Creates a mock classes object, which is a stub for the `classes` prop that would
 * come from `injectSheet(Style)`. Used for asserting that a shallow rendered
 * component snapshot applies classes as expected.
 */
export const mockClasses = (Style: Creator): StylesheetClasses => {
  const classNames = Object.keys(Style(theme));
  return trasformClassNamesArrayToObject(classNames);
};

export default mockClasses;
