// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import path from 'path';
import { type Run } from '../Run/RunTypes';
import { RUNS as RUNS_PATH } from '../const/path';

const RunLink = (props: {| prefix?: React.Node, run: Run |}) => {
  const { prefix = ' | ', run } = props;
  return (
    <>
      {prefix}
      <Link to={path.join(RUNS_PATH, run.id)}>{run.displayName}</Link>
    </>
  );
};

export default RunLink;
