// @flow

import { withRouter } from 'react-router-dom';
import { compose, lifecycle, type HOC } from 'recompose';
import withAnalytics, { type Props } from './withAnalytics';
import { type History } from '../Router/BrowserRouter';

const withPageTracking: HOC<*, {| history?: History |} & Props> = compose(
  withRouter,
  withAnalytics,
  lifecycle({
    UNSAFE_componentWillMount() {
      const { analytics, history } = this.props;

      if (
        typeof analytics?.page === 'function' &&
        typeof history?.listen === 'function'
      ) {
        this.unsubscribe = history.listen(() => {
          analytics.page();
        });
      }
    },
    componentWillUnmount() {
      if (typeof this.unsubscribe === 'function') {
        this.unsubscribe();
        delete this.unsubscribe;
      }
    }
  })
);

export default withPageTracking;
