// @flow

import type { FontRule, Typography } from './ThemeTypes';
import breakpoints from './breakpoints';

export const FONT_FAMILY =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

export const FONT_STYLES = {
  base: {
    fontFamily: FONT_FAMILY,
    fontSize: '1rem', // 16px
    // Depends on the user agent. Desktop browsers (including Firefox) use a default value of roughly `1.2`, depending on the element's `font-family`.
    // https://developer.mozilla.org/en-US/docs/Web/CSS/line-height
    lineHeight: 'normal',
    fontWeight: 400,
    letterSpacing: 'normal',
    textRendering: 'optimizeLegibility'
  },
  header1: {
    fontSize: '1.8125rem', // 29px
    fontWeight: 700,
    letterSpacing: '-0.018rem'
  },
  header2: {
    fontSize: '1.3145rem', // 21px
    fontWeight: 700,
    letterSpacing: '-0.018rem'
  },
  header3: {
    fontSize: '1.19rem', // 19px
    fontWeight: 700,
    letterSpacing: '-0.018rem'
  },
  header4: {
    fontSize: '1.068rem', // 17px
    fontWeight: 600,
    letterSpacing: '-0.012rem'
  },
  header5: {
    fontSize: '.938rem', // 15px
    fontWeight: 500,
    letterSpacing: '-0.006rem'
  },
  header6: {
    fontSize: '.8125rem', // 13px
    fontWeight: 500,
    letterSpacing: '-0.006rem'
  },
  baseText: {
    fontSize: '1.063rem', // 17px
    lineHeight: '1.43rem',
    letterSpacing: '-0.012rem',
    fontWeight: 400
  },
  smallText: {
    fontSize: '.9375rem', // 15px
    lineHeight: '1.31rem',
    letterSpacing: '-0.012rem',
    fontWeight: 400
  },
  extraSmallText: {
    fontSize: '.818rem', // 13px
    lineHeight: '1.18rem',
    letterSpacing: '-0.006rem',
    fontWeight: 400
  },
  extraExtraSmallText: {
    fontSize: '.6875rem', // 11px
    lineHeight: '1.18rem',
    letterSpacing: '-0.01375rem',
    fontWeight: 400
  },
  monospace: {
    fontFamily:
      '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
    fontSize: '1.063rem', // 17px
    lineHeight: '1.43rem',
    letterSpacing: '-0.012rem',
    fontWeight: 400
  }
};

export const BASE: FontRule = FONT_STYLES.base;

export const HEADER1: FontRule = {
  ...BASE,
  ...FONT_STYLES.header1,
  display: 'block',
  margin: 0
};

export const HEADER2: FontRule = {
  ...BASE,
  ...FONT_STYLES.header2,
  display: 'block',
  margin: 0
};

export const HEADER3: FontRule = {
  ...BASE,
  ...FONT_STYLES.header3,
  display: 'block',
  margin: 0
};

export const HEADER4: FontRule = {
  ...BASE,
  ...FONT_STYLES.header4,
  display: 'block',
  margin: 0
};

export const HEADER5: FontRule = {
  ...BASE,
  ...FONT_STYLES.header5,
  display: 'block',
  margin: 0
};

export const HEADER6: FontRule = {
  ...BASE,
  ...FONT_STYLES.header6,
  display: 'block',
  margin: 0
};

export const BASETEXT: FontRule = {
  ...BASE,
  ...FONT_STYLES.baseText
};

export const SMALLTEXT: FontRule = {
  ...BASE,
  ...FONT_STYLES.smallText
};

export const EXTRASMALLTEXT: FontRule = {
  ...BASE,
  ...FONT_STYLES.extraSmallText
};

export const EXTRAEXTRASMALLTEXT: FontRule = {
  ...BASE,
  ...FONT_STYLES.extraExtraSmallText
};

export const MONOSPACE: FontRule = {
  ...BASE,
  ...FONT_STYLES.monospace,
  display: 'block',
  whiteSpace: 'normal',
  [breakpoints.gt.tablet.landscape]: {
    whiteSpace: 'pre-wrap'
  }
};

const TYPOGRAPHY: Typography = ({
  base: BASE,
  header1: HEADER1,
  header2: HEADER2,
  header3: HEADER3,
  header4: HEADER4,
  header5: HEADER5,
  header6: HEADER6,
  baseText: BASETEXT,
  smallText: SMALLTEXT,
  extraSmallText: EXTRASMALLTEXT,
  extraExtraSmallText: EXTRAEXTRASMALLTEXT,
  monospace: MONOSPACE
}: Typography);

export default TYPOGRAPHY;
