// @flow

import { Map } from 'immutable';

export type ExpressionType = 'binary' | 'is' | 'function' | 'simple';

export const EXPRESSION_TYPE: { [key: string]: ExpressionType } = {
  BINARY: 'binary',
  IS: 'is',
  FUNCTION: 'function',
  SIMPLE: 'simple'
};

export type StatementOperator = '&&' | '||';

export const STATEMENT_OPERATOR: { [key: string]: StatementOperator } = {
  AND: '&&',
  OR: '||'
};

export type AddressedStatement = {
  and: number,
  isFirst: boolean,
  id: number,
  isLastAnd: boolean,
  isOnly: boolean,
  or: number,
  value: string
};

export type DeconstructedStatement = {
  primary: string,
  secondary: string,
  tertiary?: string,
  type?: ExpressionType
};

export type StatementMap = Map<any, any>;
