// @flow

const BASE = 16;
const INPUT_MULTI_PADDING = 5;
const INPUT_PADDING = 12;
const NAMESPACE = 'catalytic';

const variables = {
  /* Base
  /* ----------------------------------- */
  base: BASE,
  /* Namespace
  /* ----------------------------------- */
  namespace: NAMESPACE,
  /* Animation Style Defaults
  /* ----------------------------------- */
  animationDuration: '1s',
  animationFillMode: 'both',
  /* Animation Styles
  /* ----------------------------------- */
  fadeIn: `${NAMESPACE}_fadeIn`,
  fadeOut: `${NAMESPACE}_fadeOut`,
  /* Base Styles
  /* ----------------------------------- */
  borderRadius: '.5rem',
  borderRadiusSmall: '.25rem',
  /* Button
   * General Layout Considerations - Provide ample touch targets for interactive elements.
   * https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/adaptivity-and-layout/
  /* ----------------------------------- */
  button: 44,
  icon: 18,
  /* Input
  /* ----------------------------------- */
  inputMarginBottom: 24,
  inputMarginLeft: 4,
  inputMarginRight: 4,
  inputMarginTop: 8,
  inputMultiPadding: INPUT_MULTI_PADDING,
  inputMultiPaddingBottom: INPUT_MULTI_PADDING,
  inputMultiPaddingTop: INPUT_MULTI_PADDING,
  inputPadding: INPUT_PADDING,
  inputPaddingBottom: INPUT_PADDING,
  inputPaddingLeft: BASE,
  inputPaddingRight: INPUT_PADDING,
  inputPaddingTop: INPUT_PADDING,
  inputRequiredPaddingRight: '4.5rem',
  inputSwitchHeight: 24,
  inputSwitchWidth: 48,
  /* Line
  /* ----------------------------------- */
  lineMargin: 20,
  /* Main
  /* ----------------------------------- */
  mainHorizontalPadding: 12,
  mainHorizontalPaddingMobilePortrait: 16,
  mainHorizontalPaddingTabletPortrait: 32,
  mainHorizontalPaddingTabletLandscape: 40,
  mainVerticalPadding: 56,
  mainMaxWidth: 1144,
  /* Menu
  /* ----------------------------------- */
  menuItemPaddingTop: 8,
  menuItemPaddingRight: 16,
  menuItemPaddingBottom: 8,
  menuItemPaddingLeft: 16,
  menuOpenerWidthEmbedded: 24,
  /* Modal View
  /* ----------------------------------- */
  modalTop: 32,
  modalWidthMedium: 736,
  modalWidthLarge: 1152,
  modalMobilePadding: 16,
  modalDesktopPadding: 32,
  /* Nav
  /* ----------------------------------- */
  navHeight: 56,
  /* Banner
  /* ----------------------------------- */
  bannerHeight: 50,
  bannerHeightMobilePortrait: 34
};

export default variables;
