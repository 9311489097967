// @flow

import * as React from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { DEFAULT_LOCALE, LOCALE } from '../const/locale';

const cache = createIntlCache();

export const intl = createIntl(
  { locale: LOCALE, defaultLocale: DEFAULT_LOCALE },
  cache
);

type Props = {
  children: React.Node
};

export const IntlProvider = ({ children }: Props) => (
  <RawIntlProvider value={intl}>{children}</RawIntlProvider>
);
