// @flow

import { devices } from '../style/breakpoints';

// Sorted ASC by size. That's important.
export const keys = ['xs', 'sm', 'md', 'lg', 'xl'];

// Keep in mind that @media is inclusive by the CSS specification.
export default (breakpoints: any) => {
  const {
    // The breakpoint **start** at this value.
    // For instance with the first breakpoint xs: [xs, sm].
    values = {
      xs: 0,
      sm: devices.mobile.portrait + 1,
      md: devices.tablet.portrait + 1,
      lg: devices.mobile.landscape + 1,
      xl: devices.tablet.landscape + 1
    },
    unit = 'px',
    step = 1,
    ...other
  } = breakpoints;

  const up = key => {
    const value = typeof values[key] === 'number' ? values[key] : key;
    return `@media (min-width:${value}${unit})`;
  };

  const down = key => {
    const endIndex = keys.indexOf(key) + 1;
    const upperbound = values[keys[endIndex]];

    if (endIndex === keys.length) {
      // xl down applies to all sizes
      return up('xs');
    }

    const value =
      typeof upperbound === 'number' && endIndex > 0 ? upperbound : key;
    return `@media (max-width:${value - step / 100}${unit})`;
  };

  const between = (start, end) => {
    const endIndex = keys.indexOf(end) + 1;

    if (endIndex === keys.length) {
      return up(start);
    }

    return (
      `@media (min-width:${values[start]}${unit}) and ` +
      `(max-width:${values[keys[endIndex]] - step / 100}${unit})`
    );
  };

  const only = key => {
    return between(key, key);
  };

  const width = key => {
    return values[key];
  };

  return {
    keys,
    values,
    up,
    down,
    between,
    only,
    width,
    ...other
  };
};
