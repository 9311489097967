// @flow

import { type Element } from 'react';
import { type StylesheetClasses } from '@catalytic/catalytic-ui';

export type SearchQuery = { query: string };

export type CreateRenderListProps = SearchQuery & {
  classes: StylesheetClasses,
  cache: Object,
  onUnrestrictFilters?: () => void
};

export type ChildFunction = (props: CreateRenderListProps) => Element<*>;

export type RenderProps = { children: ChildFunction };

export const SEARCH_SORT_TYPE = {
  CREATED_DATE: 'CREATED_DATE',
  DEADLINE: 'DEADLINE',
  DISPLAY_NAME: 'DISPLAY_NAME',
  FAVORITE: 'FAVORITE',
  END_DATE: 'END_DATE',
  MODIFIED_DATE: 'MODIFIED_DATE',
  PROCESS_DISPLAY_NAME: 'PROCESS_DISPLAY_NAME',
  RUN_START_DATE: 'RUN_START_DATE',
  START_DATE: 'START_DATE',
  TASK_NUMBER: 'TASK_NUMBER',
  WAITING_COUNT: 'WAITING_COUNT'
};

export type SearchSortType = $Keys<typeof SEARCH_SORT_TYPE>;

export const SEARCH_SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export type SearchSortDirection = $Keys<typeof SEARCH_SORT_DIRECTION>;

export type SearchSortOption = {
  key: SearchSortType,
  direction: SearchSortDirection
};

export const SEARCH_FILTER_PROPERTY = {
  ACTION: 'ACTION',
  ADMIN: 'IS_TEAM_ADMIN',
  ANCESTOR_ID: 'ANCESTOR_ID',
  APP_ID: 'APP_ID',
  ARCHIVED: 'ARCHIVED',
  ASSIGNED_TO: 'ASSIGNED_TO',
  CATEGORY: 'CATEGORY',
  CONTEXT_ID: 'CONTEXT_ID',
  CONTEXT_TYPE_ID: 'CONTEXT_TYPE_ID',
  CREATED_DATE: 'CREATED_DATE',
  DEADLINE: 'DEADLINE',
  EDITABLE: 'EDITABLE',
  END_DATE: 'END_DATE',
  HELP_ARTICLE: 'HELP_ARTICLE',
  HELP_LABEL: 'HELP_LABEL',
  HELP_TITLE: 'HELP_TITLE',
  INTEGRATION_DEFINITION: 'INTEGRATION_DEFINITION',
  IS_ACTIVE: 'IS_ACTIVE',
  IS_DEACTIVATED: 'IS_DEACTIVATED',
  MODIFIED_BY: 'MODIFIED_BY',
  MODULE_NAMES: 'MODULE_NAMES',
  OWNER: 'OWNER',
  PARTICIPANT: 'PARTICIPANT',
  PUBLISHED_DATE: 'PUBLISHED_DATE',
  START_DATE: 'START_DATE',
  STATUS: 'STATUS',
  TABLE_ID: 'TABLE_ID',
  TABLE_TYPE: 'TABLE_TYPE',
  TASK_ID: 'TASK_ID',
  TEST_MODE: 'TEST_MODE',
  UPDATED_DATE: 'UPDATED_DATE',
  WORKSPACE_ID: 'WORKSPACE_ID'
};

export type SearchFilterProperty = $Values<typeof SEARCH_FILTER_PROPERTY>;

export const SEARCH_FILTER_RANGE_KEY = {
  EQ: 'EQ',
  GT: 'GT',
  GTE: 'GTE',
  LT: 'LT',
  LTE: 'LTE'
};

export type SearchFilterOperator = $Values<typeof SEARCH_FILTER_RANGE_KEY>;

export const TYPE = {
  ACTOR: 'ACTOR',
  BOT: 'BOT',
  CONTEXT_TYPE: 'CONTEXT_TYPE',
  CONTEXT: 'CONTEXT',
  DATA_TABLE: 'DATA_TABLE',
  HELP: 'HELP',
  TASK: 'TASK',
  RUN: 'RUN'
};

export type SearchType = $Keys<typeof TYPE>;

export const TYPENAME = {
  ACTOR: 'Actor',
  BOT: 'Bot',
  CONTEXT_TYPE: 'ContextType',
  CONTEXT: 'Context',
  DATA_TABLE: 'DataTable',
  GROUP: 'Group',
  HELP: 'Help',
  TASK: 'Task',
  RUN: 'Run'
};

export type Typename = $Values<typeof TYPENAME>;

export type Type = $Keys<typeof TYPE>;

export type SearchFilter = {
  property: SearchFilterProperty,
  type: SearchType,
  operator: SearchFilterOperator,
  value: string
};

export type SearchVariables = {
  filter?: Array<SearchFilter>,
  sort?: Array<SearchSortOption>,
  query?: string
};

export type SearchVariablesProp = { variables: SearchVariables };

export type ClientSearch = {
  createdDate: string,
  query: string
};

export type ClientSearchConnection = {
  nodes?: Array<ClientSearch>,
  totalCount: number
};

export type ClientSearchInput = {
  input: {
    query: string
  }
};

export type TransformToConnectionDataItem = ({
  [string]: ClientSearchConnection
}) => ClientSearchConnection;

export type TransformFromConnectionDataItem = ClientSearchConnection => {
  [string]: ClientSearchConnection
};
