// @flow

import * as React from 'react';
import { compose } from 'recompose';
import filter from 'ramda/src/filter';
import map from 'ramda/src/map';
import pathOr from 'ramda/src/pathOr';
import { FormattedMessage } from 'react-intl';
import {
  AsyncPaginate,
  type OptionType,
  type ValueType
} from '@catalytic/catalytic-ui';
import { ReactComponent as GroupIcon } from '@catalytic/catalytic-icons/lib/icons/group.svg';
import { withApollo } from 'react-apollo';
import UserAvatar from './UserAvatar';
import { UserQuery } from './UserSelectTypeDefs';
import { type Actor } from './ActorTypes';
import selectQueryPolicies from '../const/selectQueryPolicies';
import { TYPENAME } from '../Search/SearchTypes';
import useViewerContext from '../Viewer/ViewerContext';
import { WAIT as DEBOUNCE_WAIT } from '../const/debounce';
import logError from '../utils/logError';

export const toSelectOption = (actor: Actor): OptionType => {
  const displayName = actor?.displayName || actor?.fullName || '';
  const id = actor?.id;
  const thumbnailUrl = actor?.thumbnailUrl || '';

  // Can be extended to other assignee types
  switch (actor?.__typename) {
    case TYPENAME.GROUP:
      return {
        icon: <GroupIcon />,
        label: displayName,
        value: id
      };
    case TYPENAME.ACTOR:
    default:
      return {
        icon: <UserAvatar id={id} image={thumbnailUrl} title={displayName} />,
        label: displayName,
        value: id
      };
  }
};

type Props = {
  // Apollo client
  client: { query: (options: Object) => Promise<Object> },
  defaultValue?: null | ?Array<OptionType>,
  includeAdministratorsOption?: boolean,
  includeAllTeamMembersOption: boolean,
  // GraphQL query for users. Can pass query that includes groups, etc.
  query?: string,
  value?: null | ?Array<OptionType>
};

export const ALL_TEAM_MEMBERS_ID = 'ALL_TEAM_MEMBERS_ID';
export const ADMINISTRATORS_GROUP_ID = 'g_admins';

export const allTeamMembersOption = {
  icon: <GroupIcon />,
  label: (
    <FormattedMessage
      id="user.select.allTeamMembers"
      defaultMessage="All Team Members"
    />
  ),
  value: ALL_TEAM_MEMBERS_ID
};
export const administratorGroupOption = {
  icon: <GroupIcon />,
  label: (
    <FormattedMessage
      id="user.select.administrators"
      defaultMessage="Administrators"
    />
  ),
  value: ADMINISTRATORS_GROUP_ID
};

export function UserSelect({
  client,
  defaultValue: defaultValueProp,
  includeAdministratorsOption,
  includeAllTeamMembersOption,
  query,
  value: valueProp,
  ...other
}: Props) {
  const viewer = useViewerContext() || {};
  const displayName = viewer?.displayName || '';
  const id = viewer?.id;
  const thumbnailUrl = viewer?.thumbnailUrl || '';

  const defaultValue =
    includeAllTeamMembersOption && defaultValueProp === null
      ? allTeamMembersOption
      : defaultValueProp;
  const value =
    includeAllTeamMembersOption && valueProp === null
      ? allTeamMembersOption
      : valueProp;
  const loadOptions = async (searchTerm, loadedOptions, additional) => {
    const previousEndCursor = additional?.endCursor || 0;
    const after = loadedOptions?.length;
    let response;

    try {
      response = await client.query({
        ...selectQueryPolicies,
        query,
        variables: {
          searchTerm,
          after: typeof after === 'number' ? String(after) : after
        }
      });
    } catch (error) {
      logError(error, { silenceInTest: true });
      response = {};
    }

    const options = compose(
      map(toSelectOption),
      // Filter viewer from options
      filter(option => option.id !== id),
      pathOr([], ['data', 'search_v2', 'nodes'])
    )(response);
    if (includeAllTeamMembersOption && previousEndCursor === 0) {
      options.unshift(allTeamMembersOption);
    }
    if (includeAdministratorsOption && previousEndCursor === 0) {
      options.unshift(administratorGroupOption);
    }
    // Add viewer to the top of the list of options
    if (id && previousEndCursor === 0) {
      options.unshift({
        icon: <UserAvatar id={id} image={thumbnailUrl} title={displayName} />,
        label: displayName,
        value: id
      });
    }

    // eslint-disable-next-line camelcase
    const pageInfo = response?.data?.search_v2?.pageInfo || {};
    return {
      additional: { endCursor: pageInfo.endCursor },
      options,
      // eslint-disable-next-line camelcase
      hasMore: pageInfo.hasNextPage || false
    };
  };
  return (
    <AsyncPaginate
      debounceTimeout={DEBOUNCE_WAIT}
      defaultOptions
      defaultValue={defaultValue}
      isMulti
      loadOptions={loadOptions}
      placeholder={
        <FormattedMessage
          id="user.select.placeholder"
          defaultMessage="Select a user..."
        />
      }
      value={value}
      {...other}
    />
  );
}
UserSelect.defaultProps = {
  includeAdministratorsOption: false,
  includeAllTeamMembersOption: false,
  query: UserQuery
};
UserSelect.displayName = 'UserSelect';

type EnhancedProps = {
  defaultValue?: ValueType,
  includeAdministratorsOption?: boolean,
  includeAllTeamMembersOption?: boolean,
  name?: string,
  query?: string
};

const EnhancedUserSelect: React.ComponentType<EnhancedProps> = withApollo(
  UserSelect
);

export default EnhancedUserSelect;
