// @flow

import classNames from 'classnames';
import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';

import withComponentProp from '../../HOC/withComponentProp';
const Component = withComponentProp('div');

const Style = (theme: ThemeType) => {
  return {
    root: {
      padding: '.75rem 0',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row'
    },
    disabled: theme.mixins.disabled
  };
};

type Props = InjectSheetProvidedProps &
  HTMLDivElement & {
    disabled?: boolean,
    onClick?: (event: SyntheticEvent<HTMLDivElement>) => mixed,
    role?: string
  };

const ListItem = ({
  classes,
  className,
  disabled,
  onClick,
  role,
  theme,
  ...props
}: Props): Node => (
  <Component
    className={classNames(
      classes.root,
      {
        [classes.disabled]: disabled
      },
      className
    )}
    onClick={onClick}
    role={role || (typeof onClick === 'function' ? 'button' : undefined)}
    {...props}
  />
);

ListItem.displayName = 'ListItem';
export default injectSheet(Style)(ListItem);
