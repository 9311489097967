// @flow

const zIndex = {
  base: 100,
  menu: 200,
  nav: 300,
  modal: 400,
  toast: 500,
  dragging: 600,
  topBarLoader: 100001 // https://github.com/buunguyen/topbar/blob/master/topbar.js#L75
};

export default zIndex;
