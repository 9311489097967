// @flow

import * as React from 'react';
import path from 'path';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  type Location
} from 'react-router-dom';
import Main from '@catalytic/catalytic-ui/dist/Layout/Main/Main';
import ModalView from '@catalytic/catalytic-ui/dist/View/ModalView/ModalView';
import Toast from '@catalytic/catalytic-ui/dist/Component/Toast/Toast';
import SuspenseLoader from '../Loading/SuspenseLoader';
import Navbar from '../NavbarV2/Navbar';
import RouterConnectedModal from '../Router/RouterConnectedModal';
import ScrollToTop from '../Router/ScrollToTop';
import { ViewerProvider } from '../Viewer/ViewerContext';
import {
  ACCESS_GRANTED as ACCESS_GRANTED_PATH,
  ACCOUNT_NOTIFICATIONS as ACCOUNT_NOTIFICATIONS_PATH,
  ACCOUNT_PROFILE as ACCOUNT_PROFILE_PATH,
  ACCOUNT_SETTINGS as ACCOUNT_SETTINGS_PATH,
  ACCOUNT_USER_TOKENS as ACCOUNT_USER_TOKENS_PATH,
  ACTION_MANAGER as ACTION_MANAGER_PATH,
  ADMIN_TEAM_CENTER as ADMIN_TEAM_CENTER_PATH,
  APP_DISCOVER as APP_DISCOVER_PATH,
  AUDIT_LOGS as AUDIT_LOGS_PATH,
  DASHBOARD as DASHBOARD_PATH,
  DATATABLES as DATATABLES_PATH,
  EDIT as EDIT_PATH,
  EMAIL_SETTINGS as EMAIL_SETTINGS_PATH,
  EMBED as EMBED_PATH,
  FAVORITES as FAVORITES_PATH,
  FIELDS as FIELDS_PATH,
  FLOWCHART as FLOWCHART_PATH,
  GROUPS as GROUPS_PATH,
  HOME as HOME_PATH,
  INSIGHTS as INSIGHTS_PATH,
  INSTANCES as INSTANCES_PATH,
  INTEGRATIONS_V2 as INTEGRATIONS_V2_PATH,
  MESSAGE_CUSTOMIZATION as MESSAGE_CUSTOMIZATION_PATH,
  NEW as NEW_PATH,
  NOTIFICATIONS as NOTIFICATIONS_PATH,
  PREDICTIVE_MODELS as PREDICTIVE_MODELS_PATH,
  PROCESS as PROCESS_PATH,
  PROCESSES as PROCESSES_PATH,
  RUNS as RUNS_PATH,
  SECURITY as SECURITY_PATH,
  SETTINGS as SETTINGS_PATH,
  SHARE as SHARE_PATH,
  SSO_SETTINGS as SSO_SETTINGS_PATH,
  START as START_PATH,
  STEPS as STEPS_PATH,
  TABLES as TABLES_PATH,
  TASKS_V2 as TASKS_V2_PATH,
  TEAM_SETTINGS as TEAM_SETTINGS_PATH,
  TEST as TEST_PATH,
  TRIGGERS_V2 as TRIGGERS_V2_PATH,
  USER_TOKEN_APPROVAL as USER_TOKEN_APPROVAL_PATH,
  USERS as USERS_PATH,
  WEBFORM_SETTINGS as WEBFORM_SETTINGS_PATH,
  WORKFLOWS as WORKFLOWS_PATH,
  WORKFLOW_SETTINGS as WORKFLOW_SETTINGS_PATH,
  WORKSPACES as WORKSPACES_PATH
} from '../const/path';
import Appcues from '../shared/Appcues/Appcues';

// Paths used for dynamic breadcrumbs
export const dataTableDetailPath = path.join(TABLES_PATH, ':id');
export const groupDetailPath = path.join(GROUPS_PATH, ':id');
export const runDetailPath = path.join(RUNS_PATH, ':id');
export const runDetailFieldPath = path.join(runDetailPath, FIELDS_PATH);
export const startPath = path.join(PROCESS_PATH, ':id', START_PATH);
export const taskDetailPath = path.join(TASKS_V2_PATH, ':id');
export const testPath = path.join(PROCESS_PATH, ':id', TEST_PATH);

// Paths used for step list
const STEPNAME_PARAM = ':stepname+';
export const stepListFlowchartPath = path.join(
  PROCESS_PATH,
  ':id',
  FLOWCHART_PATH
);
export const stepListFlowchartStepPath = path.join(
  stepListFlowchartPath,
  STEPNAME_PARAM
);
export const stepListStepsPath = path.join(PROCESS_PATH, ':id', STEPS_PATH);
export const stepListStepsStepPath = path.join(
  stepListStepsPath,
  STEPNAME_PARAM
);

const LazyAccessGranted = React.lazy(() =>
  import(/* webpackChunkName: "AccessGranted" */ '../Login/AccessGranted')
);
const AccessGranted = props => (
  <SuspenseLoader>
    <LazyAccessGranted {...props} />
  </SuspenseLoader>
);
const LazyAppLibrary = React.lazy(() =>
  import(/* webpackChunkName: "AppLibrary" */ '../App/AppLibrary')
);
const AppLibrary = props => (
  <SuspenseLoader>
    <LazyAppLibrary {...props} />
  </SuspenseLoader>
);
const LazyAdminTeamCenter = React.lazy(() =>
  import(/* webpackChunkName: "AdminTeamCenter" */ '../Team/AdminTeamCenter')
);
const AdminTeamCenter = props => (
  <SuspenseLoader>
    <LazyAdminTeamCenter {...props} />
  </SuspenseLoader>
);
const LazyDashboard = React.lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ '../Dashboard/Dashboard')
);
const Dashboard = props => (
  <SuspenseLoader>
    <LazyDashboard {...props} />
  </SuspenseLoader>
);
const LazyDataTableDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "DataTableDetail" */ '../DataTableV2/DataTableDetail'
  )
);
const DataTableDetail = props => (
  <SuspenseLoader>
    <LazyDataTableDetail {...props} />
  </SuspenseLoader>
);
const LazyDataTableList = React.lazy(() =>
  import(/* webpackChunkName: "DataTableList" */ '../DataTableV2/DataTableList')
);
const DataTableList = props => (
  <SuspenseLoader>
    <LazyDataTableList {...props} />
  </SuspenseLoader>
);
const LazyApproveUserTokenCreation = React.lazy(() =>
  import(
    /* webpackChunkName: "ApproveUserTokenCreation" */ '../UserToken/ApproveUserTokenCreation'
  )
);
const ApproveUserTokenCreation = props => (
  <SuspenseLoader>
    <LazyApproveUserTokenCreation {...props} />
  </SuspenseLoader>
);
const LazyEmailSettings = React.lazy(() =>
  import(/* webpackChunkName: "EmailSettings" */ '../Team/EmailSettings')
);
const EmailSettings = props => (
  <SuspenseLoader>
    <LazyEmailSettings {...props} />
  </SuspenseLoader>
);
const LazyEmbeddedWebform = React.lazy(() =>
  import(/* webpackChunkName: "EmbeddedWebform" */ '../Webform/EmbeddedWebform')
);
const EmbeddedWebform = props => (
  <SuspenseLoader>
    <LazyEmbeddedWebform {...props} />
  </SuspenseLoader>
);
const LazyFavoriteList = React.lazy(() =>
  import(/* webpackChunkName: "FavoriteList" */ '../Favorite/FavoriteList')
);
const FavoriteList = props => (
  <SuspenseLoader>
    <LazyFavoriteList {...props} />
  </SuspenseLoader>
);
const LazyFourOFour = React.lazy(() =>
  import(/* webpackChunkName: "FourOFour" */ '../FourOFour/FourOFour')
);
const FourOFour = props => (
  <SuspenseLoader>
    <LazyFourOFour {...props} />
  </SuspenseLoader>
);
const LazyGallery = React.lazy(() =>
  import(/* webpackChunkName: "Gallery" */ '../Gallery/Gallery')
);
const Gallery = props => (
  <SuspenseLoader>
    <LazyGallery {...props} />
  </SuspenseLoader>
);
const LazyGalleryPickerContent = React.lazy(() =>
  import(
    /* webpackChunkName: "GalleryPickerContent" */ '../Gallery/GalleryPickerContent'
  )
);
const GalleryPickerContent = props => (
  <SuspenseLoader>
    <LazyGalleryPickerContent {...props} />
  </SuspenseLoader>
);
const LazyGroupDetail = React.lazy(() =>
  import(/* webpackChunkName: "GroupDetail" */ '../Group/GroupDetail')
);
const GroupDetail = props => (
  <SuspenseLoader>
    <LazyGroupDetail {...props} />
  </SuspenseLoader>
);
const LazyHome = React.lazy(() =>
  import(/* webpackChunkName: "Home" */ '../Home/Home')
);
const Home = props => (
  <SuspenseLoader>
    <LazyHome {...props} />
  </SuspenseLoader>
);
const LazyIntegrationsV2Container = React.lazy(() =>
  import(
    /* webpackChunkName: "IntegrationsV2Container" */ '../Integrations/IntegrationsV2Container'
  )
);
const IntegrationsV2Container = props => (
  <SuspenseLoader>
    <LazyIntegrationsV2Container {...props} />
  </SuspenseLoader>
);
const LazySSOSettings = React.lazy(() =>
  import(
    /* webpackChunkName: "SSOSettings" */ '../Team/SSOSettings/SSOSettings'
  )
);
const SSOSettings = props => (
  <SuspenseLoader>
    <LazySSOSettings {...props} />
  </SuspenseLoader>
);
const LazyMessageCustomization = React.lazy(() =>
  import(
    /* webpackChunkName: "MessageCustomization" */ '../Team/MessageCustomization'
  )
);
const MessageCustomization = props => (
  <SuspenseLoader>
    <LazyMessageCustomization {...props} />
  </SuspenseLoader>
);
const LazyMyAccount = React.lazy(() =>
  import(/* webpackChunkName: "MyAccount" */ '../MyAccount/MyAccount')
);
const MyAccount = props => (
  <SuspenseLoader>
    <LazyMyAccount {...props} />
  </SuspenseLoader>
);
const LazyAuditTrail = React.lazy(() =>
  import(/* webpackChunkName: "AuditTrail" */ '../Team/AuditTrail/AuditTrail')
);
const AuditTrail = props => (
  <SuspenseLoader>
    <LazyAuditTrail {...props} />
  </SuspenseLoader>
);
const LazyActionManager = React.lazy(() =>
  import(
    /* webpackChunkName: "ActionManager" */ '../Team/ActionManager/ActionManager'
  )
);
const ActionManager = props => (
  <SuspenseLoader>
    <LazyActionManager {...props} />
  </SuspenseLoader>
);
const LazyPredictiveModelList = React.lazy(() =>
  import(
    /* webpackChunkName: "PredictiveModelList" */ '../PredictiveModel/PredictiveModelList'
  )
);
const PredictiveModelList = props => (
  <SuspenseLoader>
    <LazyPredictiveModelList {...props} />
  </SuspenseLoader>
);
const LazyProcessDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "ProcessDetail" */ '../ProcessDetail/ProcessDetail'
  )
);
const ProcessDetail = props => (
  <SuspenseLoader>
    <LazyProcessDetail {...props} />
  </SuspenseLoader>
);
const LazyProcessSearchList = React.lazy(() =>
  import(
    /* webpackChunkName: "ProcessSearchList" */ '../Process/ProcessSearchList'
  )
);
const ProcessSearchList = props => (
  <SuspenseLoader>
    <LazyProcessSearchList {...props} />
  </SuspenseLoader>
);
const LazyProcessSecurityEdit = React.lazy(() =>
  import(
    /* webpackChunkName: "ProcessSecurityEdit" */ '../ProcessDetailEdit/ProcessSecurityEdit'
  )
);
const ProcessSecurityEdit = props => (
  <SuspenseLoader>
    <LazyProcessSecurityEdit {...props} />
  </SuspenseLoader>
);

const LazyRunDetail = React.lazy(() =>
  import(/* webpackChunkName: "RunDetail" */ '../Run/RunDetail')
);
const RunDetail = props => (
  <SuspenseLoader>
    <LazyRunDetail {...props} />
  </SuspenseLoader>
);
const LazyRunList = React.lazy(() =>
  import(/* webpackChunkName: "RunList" */ '../Run/RunList')
);
const RunList = props => (
  <SuspenseLoader>
    <LazyRunList {...props} />
  </SuspenseLoader>
);
const LazyStartPushbot = React.lazy(() =>
  import(/* webpackChunkName: "StartPushbot" */ '../StartPushbot/StartPushbot')
);
const StartPushbot = props => (
  <SuspenseLoader>
    <LazyStartPushbot {...props} />
  </SuspenseLoader>
);
const LazyStepList = React.lazy(() =>
  import(/* webpackChunkName: "StepList" */ '../Step/StepList')
);
const StepList = props => (
  <SuspenseLoader>
    <LazyStepList {...props} />
  </SuspenseLoader>
);
const LazyTaskDetail = React.lazy(() =>
  import(/* webpackChunkName: "TaskDetail" */ '../Tasks/TaskDetail')
);
const TaskDetail = props => (
  <SuspenseLoader>
    <LazyTaskDetail {...props} />
  </SuspenseLoader>
);
const LazyTaskList = React.lazy(() =>
  import(/* webpackChunkName: "TaskList" */ '../Tasks/TaskList')
);
const TaskList = props => (
  <SuspenseLoader>
    <LazyTaskList {...props} />
  </SuspenseLoader>
);
const LazyTeam = React.lazy(() =>
  import(/* webpackChunkName: "Team" */ '../Team/Team')
);
const Team = props => (
  <SuspenseLoader>
    <LazyTeam {...props} />
  </SuspenseLoader>
);
const LazyTeamSettings = React.lazy(() =>
  import(/* webpackChunkName: "TeamSettings" */ '../Team/TeamSettings')
);
const TeamSettings = props => (
  <SuspenseLoader>
    <LazyTeamSettings {...props} />
  </SuspenseLoader>
);
const LazyTriggerList = React.lazy(() =>
  import(/* webpackChunkName: "TriggerList" */ '../Trigger/TriggerList')
);
const TriggerList = props => (
  <SuspenseLoader>
    <LazyTriggerList {...props} />
  </SuspenseLoader>
);
const LazyUserProfile = React.lazy(() =>
  import(/* webpackChunkName: "UserProfile" */ '../UserProfile/UserProfile')
);
const UserProfile = props => (
  <SuspenseLoader>
    <LazyUserProfile {...props} />
  </SuspenseLoader>
);
const LazyWebformSettings = React.lazy(() =>
  import(/* webpackChunkName: "WebformSettings" */ '../Team/WebformSettings')
);
const WebformSettings = props => (
  <SuspenseLoader>
    <LazyWebformSettings {...props} />
  </SuspenseLoader>
);
const LazyWorkflowSettings = React.lazy(() =>
  import(/* webpackChunkName: "WorkflowSettings" */ '../Team/WorkflowSettings')
);
const WorkflowSettings = props => (
  <SuspenseLoader>
    <LazyWorkflowSettings {...props} />
  </SuspenseLoader>
);
const LazyWorkspaceList = React.lazy(() =>
  import(/* webpackChunkName: "WorkspaceList" */ '../Workspace/WorkspaceList')
);
const WorkspaceList = props => (
  <SuspenseLoader>
    <LazyWorkspaceList {...props} />
  </SuspenseLoader>
);
const LazyWorkspaceDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceDetail" */ '../Workspace/WorkspaceDetail'
  )
);
const WorkspaceDetail = props => (
  <SuspenseLoader>
    <LazyWorkspaceDetail {...props} />
  </SuspenseLoader>
);

/*

  Flattens and reverses nested location state backgrounds. The most deeply nested
  (or tail) location is always the one that should be handled by the first Switch
  component. The remaining `n` Switch components handle rendering a stack of modals.

 Input (similiar to a linked list):
  {
    pathname: '/foo',
    state: {
      background: {
        pathname: '/bar',
        state: {
          background: {
            pathname: '/baz',
            state: undefined
          }
        }
      }
    }
  }

  Output:
  [
    {
      pathname: '/baz',
      state: undefined
    },
    {
      pathname: '/bar',
      background: {
        state: {...}
      }
    },
    {
      pathname: '/foo',
      background: {
        state: {...}
      }
    },
  ]

*/

export const unnestAndReverseLocationStateBackgrounds = (
  location: Location
): Array<Location> => {
  const background = location?.state?.background;
  if (!background) {
    return [location];
  } else {
    return unnestAndReverseLocationStateBackgrounds(background).concat(
      location
    );
  }
};

export const AuthenticatedWebApp = () => {
  const history = useHistory();
  const location = useLocation();
  const [
    tailLocation,
    ...nestedLocations
  ] = unnestAndReverseLocationStateBackgrounds(location);

  return (
    <ViewerProvider>
      <Toast />
      <Navbar />
      <Appcues />
      <Switch location={tailLocation}>
        <Route path={HOME_PATH} exact render={Home} />
        <Redirect from={NOTIFICATIONS_PATH} exact to={HOME_PATH} />
        <Route path={FAVORITES_PATH} exact component={FavoriteList} />
        <Route path={ACCESS_GRANTED_PATH} exact component={AccessGranted} />
        <Route
          path={INTEGRATIONS_V2_PATH}
          component={IntegrationsV2Container}
        />
        <Route path={TRIGGERS_V2_PATH} component={TriggerList} />
        <Route
          path={path.join(PREDICTIVE_MODELS_PATH, ':predictiveModelId')}
          exact
          component={PredictiveModelList}
        />
        <Route
          path={PREDICTIVE_MODELS_PATH}
          exact
          component={PredictiveModelList}
        />
        <Route path={PROCESSES_PATH} exact component={ProcessSearchList} />
        <Route
          path={path.join(PROCESS_PATH, NEW_PATH)}
          exact
          component={Gallery}
        />
        <Route
          path={path.join(
            PROCESS_PATH,
            ':id',
            DATATABLES_PATH,
            PREDICTIVE_MODELS_PATH,
            ':predictiveModelId'
          )}
          exact
          component={ProcessDetail}
        />
        <Route
          path={path.join(PROCESS_PATH, ':id', DATATABLES_PATH)}
          exact
          component={ProcessDetail}
        />
        <Route
          path={path.join(PROCESS_PATH, ':id', INSIGHTS_PATH)}
          exact
          component={ProcessDetail}
        />
        <Route
          path={path.join(PROCESS_PATH, ':id', INSTANCES_PATH)}
          exact
          component={ProcessDetail}
        />
        <Redirect
          from={path.join(PROCESS_PATH, ':id')}
          exact
          to={path.join(PROCESS_PATH, ':id', INSTANCES_PATH)}
        />
        <Route path={INSTANCES_PATH} exact component={RunList} />
        <Route
          path={path.join(USERS_PATH, ':id')}
          exact
          component={UserProfile}
        />
        <Route path={USERS_PATH} exact component={Team} />
        <Route
          path={ADMIN_TEAM_CENTER_PATH}
          exact
          component={AdminTeamCenter}
        />
        <Route path={GROUPS_PATH} exact component={Team} />
        <Route path={groupDetailPath} exact component={GroupDetail} />
        <Route path={TEAM_SETTINGS_PATH} exact component={TeamSettings} />
        <Route path={EMAIL_SETTINGS_PATH} exact component={EmailSettings} />
        <Route path={SSO_SETTINGS_PATH} exact component={SSOSettings} />
        <Route
          path={MESSAGE_CUSTOMIZATION_PATH}
          exact
          component={MessageCustomization}
        />
        <Route path={WEBFORM_SETTINGS_PATH} exact component={WebformSettings} />
        <Route
          path={WORKFLOW_SETTINGS_PATH}
          exact
          component={WorkflowSettings}
        />
        <Route path={ACCOUNT_PROFILE_PATH} exact component={MyAccount} />
        <Route path={ACCOUNT_SETTINGS_PATH} exact component={MyAccount} />
        <Route path={ACCOUNT_NOTIFICATIONS_PATH} exact component={MyAccount} />
        <Route path={ACCOUNT_USER_TOKENS_PATH} component={MyAccount} />
        <Route
          path={path.join(ADMIN_TEAM_CENTER_PATH, AUDIT_LOGS_PATH)}
          component={AuditTrail}
        />
        <Route
          path={path.join(ADMIN_TEAM_CENTER_PATH, ACTION_MANAGER_PATH)}
          component={ActionManager}
        />
        <Route path={runDetailPath}>
          <Main>
            <RunDetail />
          </Main>
        </Route>
        <Route path={TASKS_V2_PATH} exact component={TaskList} />
        <Route exact path={taskDetailPath}>
          <Main>
            <ScrollToTop />
            <TaskDetail />
          </Main>
        </Route>
        <Route path={DASHBOARD_PATH} exact component={Dashboard} />
        <Route
          path={path.join(EMBED_PATH, ':id', ':webformName')}
          exact
          component={EmbeddedWebform}
        />
        <Route
          path={path.join(PROCESSES_PATH, NEW_PATH)}
          exact
          component={Gallery}
        />
        <Route
          path={path.join(PROCESS_PATH, ':id', SECURITY_PATH)}
          component={ProcessSecurityEdit}
        />
        <Redirect
          from={path.join(PROCESS_PATH, ':id', SETTINGS_PATH)}
          to={path.join(PROCESS_PATH, ':id', STEPS_PATH)}
        />
        <Redirect
          from={path.join(PROCESS_PATH, ':id', NEW_PATH)}
          to={path.join(PROCESS_PATH, ':id', EDIT_PATH)}
        />
        <Route
          path={path.join(SHARE_PATH, ':id', ':webformName')}
          exact
          component={EmbeddedWebform}
        />
        <Route path={stepListFlowchartPath} exact component={StepList} />
        <Route path={stepListFlowchartStepPath} exact component={StepList} />
        <Route path={stepListStepsPath} exact component={StepList} />
        <Route path={stepListStepsStepPath} exact component={StepList} />
        <Route path={TABLES_PATH} exact component={DataTableList} />
        <Route path={dataTableDetailPath} exact component={DataTableDetail} />
        <Route exact path={startPath}>
          <Main>
            <StartPushbot />
          </Main>
        </Route>
        <Route exact path={testPath}>
          <Main>
            <StartPushbot />
          </Main>
        </Route>
        <Route
          path={USER_TOKEN_APPROVAL_PATH}
          exact
          component={ApproveUserTokenCreation}
        />
        <Route path={APP_DISCOVER_PATH} exact component={AppLibrary} />
        <Route
          path={path.join(APP_DISCOVER_PATH, ':id+')}
          exact
          component={AppLibrary}
        />
        <Route path={WORKFLOWS_PATH} exact component={WorkspaceDetail} />
        <Route path={WORKSPACES_PATH} exact component={WorkspaceList} />
        <Route
          path={path.join(WORKSPACES_PATH, ':id')}
          exact
          component={WorkspaceDetail}
        />
        <Route
          path={path.join(WORKSPACES_PATH, ':id', PROCESS_PATH, NEW_PATH)}
          exact
          component={Gallery}
        />
        <Route
          path={path.join(WORKSPACES_PATH, ':id', WORKSPACES_PATH)}
          exact
          component={WorkspaceList}
        />
        <Route component={FourOFour} />
      </Switch>
      {/*
       * Support a stack of modals
       * e.g. Workflow detail (screen) > Run detail (modal) > Task Detail (modal)
       */}

      {nestedLocations.map((location, index) => {
        // Note: the key must be an index in this case and not a stable
        // identifier in order to prevent the modal from closing and
        // reopening when reopening a task.
        return (
          <Switch key={index} location={location}>
            <Route path={taskDetailPath}>
              <RouterConnectedModal>
                <TaskDetail
                  banner={null}
                  onTaskCompleted={() => history.goBack()}
                />
              </RouterConnectedModal>
            </Route>
            <Route path={startPath}>
              <RouterConnectedModal>
                <StartPushbot />
              </RouterConnectedModal>
            </Route>
            <Route path={testPath}>
              <RouterConnectedModal>
                <StartPushbot />
              </RouterConnectedModal>
            </Route>
            <Route path={runDetailPath}>
              <RouterConnectedModal>
                <RunDetail banner={null} />
              </RouterConnectedModal>
            </Route>
            <Route path={path.join(PROCESS_PATH, NEW_PATH)}>
              <ModalView
                width="large"
                isOpen
                onRequestClose={() => history.goBack()}
              >
                <GalleryPickerContent />
              </ModalView>
            </Route>
            <Route path={path.join(PROCESSES_PATH, NEW_PATH)}>
              <ModalView
                width="large"
                isOpen
                onRequestClose={() => history.goBack()}
              >
                <GalleryPickerContent />
              </ModalView>
            </Route>
            <Route
              path={path.join(WORKSPACES_PATH, ':id', PROCESS_PATH, NEW_PATH)}
            >
              <ModalView
                width="large"
                isOpen
                onRequestClose={() => history.goBack()}
              >
                <GalleryPickerContent />
              </ModalView>
            </Route>
          </Switch>
        );
      })}
    </ViewerProvider>
  );
};

export default AuthenticatedWebApp;
