// @flow

export type NodeType =
  | 'Actor'
  | 'Bot'
  | 'Context'
  | 'ContextType'
  | 'Field'
  | 'Group'
  | 'Guest'
  | 'Step'
  | 'Task'
  | 'Team'
  | 'Trigger';

export const NODE_TYPE = {
  ACTOR: 'Actor',
  BOT: 'Bot',
  CONTEXT: 'Context',
  CONTEXT_TYPE: 'ContextType',
  FIELD: 'Field',
  GROUP: 'Group',
  GUEST: 'Guest',
  STEP: 'Step',
  TASK: 'Task',
  TEAM: 'Team',
  TRIGGER: 'Trigger'
};

export type Node = {
  id: string,
  __typename: NodeType
};
