import { withAsyncPaginate, wrapMenuList } from 'react-select-async-paginate';
import MultiSelectInput, {
  MultiValueLabel,
  Option,
  SingleValue
} from './MultiSelectInput';
import React, { type ComponentType, type ElementRef } from 'react';

type Props = {
  components: ComponentType<*>,
  ref: ElementRef<*>
};
export const Style = {
  loadingIndicator: () => {
    return {
      display: 'none'
    };
  }
};

const AsyncPaginateWrapper = withAsyncPaginate(MultiSelectInput);
const AsyncPaginate = ({ ref, components, ...props }: Props) => {
  if (components?.MenuList) {
    const MenuList = wrapMenuList(components.MenuList);
    // Indicator still used for wrapped Menu List select components
    return (
      <AsyncPaginateWrapper
        selectRef={ref}
        components={{
          MenuList,
          MultiValueLabel,
          Option,
          SingleValue
        }}
        {...props}
      />
    );
  } else {
    return (
      <AsyncPaginateWrapper
        styles={Style}
        selectRef={ref}
        components={{
          MultiValueLabel,
          Option,
          SingleValue
        }}
        {...props}
      />
    );
  }
};
export default AsyncPaginate;
