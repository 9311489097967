// @flow

import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  children: string
};

const APP_NAME = 'PagerDuty Workflow Automation';

export const HtmlTitle = ({ children }: Props) =>
  typeof children === 'string' ? (
    <Helmet title={`${children} | ${APP_NAME}`} />
  ) : null;
HtmlTitle.displayName = 'HtmlTitle';

export default HtmlTitle;
