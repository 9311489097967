// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import PageVisibility from 'react-page-visibility';
import { Nav, NavLink, NavLogo, NavMenu } from '@catalytic/catalytic-ui';
import NavbarApp from './NavbarApp';
import NavbarNotifications from './NavbarNotifications';
import NavbarSearch from './NavbarSearch';
import NavbarUser from './NavbarUser';
import useTeamContext from '../Team/TeamContext';
import useViewerContext from '../Viewer/ViewerContext';
import {
  HOME as HOME_PATH,
  TASKS_V2 as TASKS_V2_PATH,
  WORKFLOWS as WORKFLOWS_PATH
} from '../const/path';

const Navbar = () => {
  const team = useTeamContext();
  const viewer = useViewerContext();
  const id = viewer?.id || '';
  const email = viewer?.email || '';
  const fullName = viewer?.fullName || id;
  const isTeamAdmin = viewer?.isTeamAdmin || false;
  const isTeamOwner = viewer?.isTeamOwner || false;
  const samlConfig = team?.samlConfig;
  const samlConfigEnabled = (samlConfig || {})?.enabled || false;
  const thumbnailUrl = viewer?.thumbnailUrl;

  return (
    <Nav>
      <NavLogo to={HOME_PATH} />
      <NavLink to={WORKFLOWS_PATH}>
        <FormattedMessage id="navbar.workflows" defaultMessage="Workflows" />
      </NavLink>
      <NavLink to={TASKS_V2_PATH}>
        <FormattedMessage id="navbar.tasks" defaultMessage="Tasks" />
      </NavLink>
      <NavMenu>
        <NavbarSearch />
        <NavbarApp isTeamAdmin={isTeamAdmin} isTeamOwner={isTeamOwner} />
        <PageVisibility>
          {isVisible => <NavbarNotifications id={id} isVisible={isVisible} />}
        </PageVisibility>
        <NavbarUser
          email={email}
          fullName={fullName}
          isTeamAdmin={isTeamAdmin}
          isTeamOwner={isTeamOwner}
          nullThumbnail={thumbnailUrl === null}
          samlConfigEnabled={samlConfigEnabled}
          thumbnailUrl={thumbnailUrl || ''}
        />
      </NavMenu>
    </Nav>
  );
};

export default Navbar;
