// @flow

import * as React from 'react';

export type Session = {
  cleanSession: () => Promise<any>,
  isLoggedIn: boolean,
  logout: () => Promise<any>
};

// https://github.com/gajus/eslint-plugin-flowtype/issues/295
/* eslint-disable no-redeclare */
export const {
  Provider: SessionProvider,
  Consumer: SessionConsumer
}: React.Context<Session> = React.createContext({
  cleanSession: () => Promise.resolve(),
  isLoggedIn: false,
  logout: () => Promise.resolve()
});
/* eslint-enable no-redeclare */
