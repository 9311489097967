// @flow

import path from 'ramda/src/path';
import Rreject from 'ramda/src/reject';
import {
  type Context,
  type ContextQuery,
  type ContextTypeContextQuery,
  type ExpressionScope,
  type WebformContextQuery,
  type WebhookContextQuery
} from './ExpressionTypes';

const reject = Rreject((v: any) => v === undefined || v === null);

const toScope = (context: Context): ExpressionScope => {
  const owner = path(['owner'], context);
  const fields = path(['fields', 'nodes'], context) || [];
  const tasks = path(['tasks', 'nodes'], context) || [];

  return {
    fields: reject(
      fields.reduce((o, { name, value }) => reject({ ...o, [name]: value }), {})
    ),
    run: reject({
      owner
    }),
    tasks: reject(
      tasks.reduce(
        (o, { name, status }) => reject({ ...o, [name]: status }),
        {}
      )
    )
  };
};

export const toExpressionScope = (data: ContextQuery): ExpressionScope => {
  const context = path(['context'], data);
  return toScope(context);
};

export const toContextTypeExpressionScope = (
  data: ContextTypeContextQuery
): ExpressionScope => {
  const context = path(['contextType'], data);
  return toScope(context);
};

export const toWebformExpressionScope = (
  data: WebformContextQuery
): ExpressionScope => {
  const context = path(['webform'], data);
  return toScope(context);
};

export const toWebhookExpressionScope = (
  data: WebhookContextQuery
): ExpressionScope => {
  const context = path(['webhook'], data);
  return toScope(context);
};
