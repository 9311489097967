// @flow

import * as React from 'react';
import {
  LayoutManagerWithViewController,
  withNavigationViewController
} from '@atlaskit/navigation-next';
import { makeStyles } from '@material-ui/styles';
import { ModalViewContext } from '../View/ModalView/ModalView';

const useStyles = makeStyles(theme => ({
  topLevelContainer: {
    // Fix double scrollbars when rendering a search list
    '& .css-11f6q49': {
      overflowY: 'hidden !important'
    },
    // Account for navbar height in fixed content section, so scrollbars don't
    // extend off the end of the screen
    //
    // LayoutContainer
    '&>div:first-child': {
      height: ({ modalViewContext }) =>
        modalViewContext
          ? `calc(100vh - ${theme.functions.toRem(
              theme.variables.modalTop +
                theme.variables.modalMobilePadding +
                theme.variables.button
            )})`
          : `calc(100vh - ${theme.functions.toRem(theme.variables.navHeight)})`,
      [theme.breakpoints.desktop]: {
        height: ({ modalViewContext }) =>
          modalViewContext
            ? `calc(100vh - ${theme.functions.toRem(
                theme.variables.modalTop +
                  theme.variables.modalDesktopPadding +
                  theme.variables.button
              )})`
            : `calc(100vh - ${theme.functions.toRem(
                theme.variables.navHeight
              )})`
      },
      // Override Atlaskit's `display: "fixed"` for the Navigation pane so
      // it can be used without being docked to the left edge of the browser
      // window
      //
      // Navigation
      '&>div:nth-child(1)': {
        flex: '0 1 20rem',
        minWidth: '20rem',
        position: 'absolute',
        marginLeft: '1rem'
      },
      // Content
      '&>div:nth-child(2)': {
        flex: '1 1 auto',
        marginLeft: ({ modalViewContext }) => (modalViewContext ? '1rem' : 0)
      }
    }
  }
}));

/*
 * `withMasterDetail`
 *
 * Usage:
 *
 *   const MyMasterDetailComponent withMasterDetail(MyComponent); // injects `navigationViewController`
 *
 *   const MyComponent = ({ navigationViewController }) => {
 *     // Call navigationViewController methods to control what appears in the master (left) nav pane
 *     navigationViewController.addView({ ... })
 *     navigationViewController.setView();
 *
 *     // The nodes returned here will appear in the detail (right) pane
 *     return <div>{...}</div>;
 *   }
 *
 *
 * Master Detail implementation requirements:
 *   - AtlaskitProviders must be rendered higher up the DOM tree than `withNavigationViewController` (or alternatively `ViewControllerSubscriber`)
 *   - Make `navigationViewController` prop available to web app consumer
 *
 * Reference:
 *   - View state controllers: https://atlaskit.atlassian.com/packages/core/navigation-next/docs/state-controllers#view-controller
 *
 * Note: This doesn't necessarily have to be an HOC.
 */

// 'product' is that Atlaskit navigation type that most closely meets the needs of our design:
// https://atlaskit.atlassian.com/packages/core/navigation-next/docs/controlling-navigation-views#managing-the-navigation-state
type MasterDetailNavigationType = 'product';

// See Atlaskit documentation for a more complete description of NavigationViewController:
// https://atlaskit.atlassian.com/packages/core/navigation-next/docs/state-controllers
type NavigationViewController = {
  addView: ({
    id: string,
    type: MasterDetailNavigationType,
    getItems: (...args: Array<any>) => Array<Object> | Promise<Array<Object>>
  }) => void,
  setView: (id?: string) => void
};

export type MasterDetailInjectedProps = {|
  navigationViewController: NavigationViewController
|};

// Flow HOC annotation reference: https://flow.org/en/docs/react/hoc/#toc-injecting-props
export function withMasterDetail<Config>(
  WrappedComponent: React.AbstractComponent<{|
    ...Config,
    ...MasterDetailInjectedProps
  |}>
): React.AbstractComponent<Config> {
  return withNavigationViewController(function MasterDetail(props: Config) {
    const modalViewContext = React.useContext(ModalViewContext);
    const classes = useStyles({ modalViewContext });
    return (
      <div className={classes.topLevelContainer}>
        <LayoutManagerWithViewController globalNavigation={() => null}>
          <div className={classes.detailContainer}>
            <WrappedComponent {...props} />
          </div>
        </LayoutManagerWithViewController>
      </div>
    );
  });
}

export default withMasterDetail;
