// @flow

import React, {
  createRef,
  PureComponent,
  type ComponentType,
  type ElementRef,
  type Node,
  type Ref
} from 'react';
import { devices } from '../../style/breakpoints';
import classNames from 'classnames';
import { compose, setDisplayName } from 'recompose';
import { MentionsInput, Mention } from 'react-mentions';
import injectSheet from '../../style/injectSheet';
import {
  type CSS,
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import theme from '../../style/theme';
import InputRequired from '../InputRequired/InputRequired';

const DISPLAY_NAME = 'TokenPickerInput';

export const MAX_WIDTH = '20rem'; // 320px

export const Style = (theme: ThemeType) => {
  return {
    root: {
      ...theme.mixins.inputStyle,
      ...theme.mixins.breakWord,
      paddingTop: 0,
      paddingBottom: 0,
      position: 'relative',
      minHeight: '3rem',
      width: 'auto'
    },
    mentionInput: {
      hyphens: 'none',
      margin: 0,
      '& li': {
        borderTop: `1px solid ${theme.colors.lightGrey}`,
        '&:first-child': {
          borderTop: 0
        }
      },
      '& textarea, & input': {
        paddingTop: theme.functions.toRem(theme.variables.inputPaddingTop),
        paddingBottom: theme.functions.toRem(theme.variables.inputPaddingTop),
        paddingLeft: '.125rem',
        paddingRight: '.125rem',
        backgroundColor: 'inherit',
        outline: 'none',
        border: 0,
        width: '100%!important'
      },
      marginRight: '1rem'
    },
    menuItem: {
      ...theme.typography.extraSmallText,
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      minWidth: '10rem',
      minHeight: '2.5rem',
      padding: '.5rem 1rem',
      color: theme.colors.black,
      listStyle: 'none',
      textAlign: 'left',
      cursor: 'pointer',
      verticalAlign: 'bottom',
      '&:focus, &:hover': {
        backgroundColor: theme.colors.blueGrey
      },
      '& > div': {
        width: '100%',
        '& > span': {
          display: 'inline-block',
          overflow: 'hidden'
        }
      }
    },
    focused: {
      backgroundColor: theme.colors.blueGrey
    },
    hiddenTextarea: {
      display: 'none'
    },
    displayErrors: {
      '&:valid': theme.mixins.inputSuccess,
      '&:invalid': theme.mixins.inputError
    },
    success: theme.mixins.inputSuccess,
    error: theme.mixins.inputError,
    required: {
      right: '2.5rem'
    },
    helpOpener: {
      color: theme.colors.battleshipGrey,
      height: '1.25rem',
      width: '1.25rem'
    },
    descriptionContainer: {
      bottom: '0.8rem',
      display: 'inline-flex',
      position: 'absolute',
      right: '0.8rem',
      '& > .tippy-popper': {
        minWidth: '15rem'
      }
    },
    description: {
      ...theme.typography.smallText,
      wordBreak: 'break-word',
      padding: '1rem',
      textAlign: 'left',
      maxWidth: '15rem'
    }
  };
};

const makeStyles = ({
  renderFromClick,
  screenSize
}: {
  renderFromClick: boolean,
  screenSize: number
}) => {
  const styles: {
    highlighter: CSS,
    suggestions: CSS,
    mentionHighlight: CSS,
    ['&multiLine']: CSS
  } = {
    highlighter: {
      border: 0,
      paddingTop: theme.functions.toRem(theme.variables.inputPaddingTop),
      paddingBottom: theme.functions.toRem(theme.variables.inputPaddingTop),
      paddingLeft: '.125rem',
      paddingRight: '.125rem'
    },
    suggestions: {
      ...theme.mixins.inputMenu,
      maxHeight: '12.25rem',
      marginTop: '1.875rem',
      overflow: 'auto',
      border: `1px solid ${theme.colors.blueGrey}`,
      borderRadius: theme.variables.borderRadiusSmall,
      boxShadow: `0 .25rem .5rem ${theme.colors.pastelGreyAlpha}`,
      backgroundColor: theme.colors.white,
      maxWidth: MAX_WIDTH,
      zIndex: theme.zIndex.menu
    },
    mentionHighlight: {
      backgroundColor: theme.colors.highlightAlpha,
      borderRadius: '.15rem'
    },
    '&multiLine': {
      input: {
        '&[readonly]': {
          '&, &:focus, &:hover': theme.mixins.inputReadOnly
        },
        '&:disabled': {
          '&, &:focus, &:hover': theme.mixins.inputDisabled
        }
      }
    }
  };

  if (renderFromClick && screenSize > devices.mobile.landscape) {
    styles.suggestions.position = 'absolute';
    styles.suggestions.top = '100%';
    styles.suggestions.right = 0;
    styles.suggestions.left = 'auto';
    styles.suggestions.marginTop = '.5rem';
  }

  return styles;
};

type TokenPickerInputType = InjectSheetProvidedProps &
  HTMLTextAreaElement & {
    'data-testid'?: string,
    appendSpaceOnAdd?: boolean,
    suggestionLocationOnClick: ElementRef<*>,
    data: Array<any>,
    defaultValue?: any,
    description?: Node,
    displayErrors: boolean,
    displayTransform?: (id: string, display: string) => string,
    hasError: boolean,
    hasRequired: boolean,
    hasSuccess: boolean,
    hasValidation?: boolean,
    inputRef?: Ref<*>,
    markup: string,
    mentionInputClassName?: string,
    onChange: (event: Object) => mixed,
    regex?: RegExp,
    renderSuggestion: (suggestion: any) => Node,
    trigger: string | RegExp,
    triggerElement?: Node
  };

type State = {
  inputValue: string,
  renderFromClick: boolean
};

// Helper to determine cursor location to insert field
export const calculateTrueCursorPosition = (
  currentValue: string,
  currentCursorPlacement: number,
  displayStarterText: string
) => {
  const displayText = displayStarterText.slice(0, currentCursorPlacement) || '';
  const displayedDoubleBracketsMatches = displayText.match(/{{|}}/g) || [];
  const displayedDoubleBrackets =
    displayedDoubleBracketsMatches.length * 2 || 0;
  let trueCursor = currentCursorPlacement;
  while (
    currentValue.slice(0, trueCursor).replace(/{{|}}/g, '').length +
      displayedDoubleBrackets <
      currentCursorPlacement &&
    trueCursor < currentValue.length
  ) {
    trueCursor++;
  }
  return trueCursor;
};

// Helper to determine if inserting field would split a pre-existing field
export const adjustTrueCursorOutsideFieldBoundary = (
  trueCursor: number,
  currentValue: string
): number => {
  let inBetweenFields;
  trueCursor === 0 ? (inBetweenFields = false) : (inBetweenFields = true);
  while (inBetweenFields) {
    const starterText = currentValue.slice(0, trueCursor);
    const leftBrackets = starterText.match(/}}/g)?.length || 0;
    const rightBrackets = starterText.match(/{{/g)?.length || 0;
    if (leftBrackets === rightBrackets) {
      inBetweenFields = false;
    } else {
      trueCursor++;
    }
  }
  return trueCursor;
};

export const matchingBracketsInput = (currentValue: string): boolean => {
  return (
    currentValue.match(/}}/g)?.length === currentValue.match(/{{/g)?.length
  );
};

class TokenPickerInput extends PureComponent<TokenPickerInputType, State> {
  state = {
    inputValue:
      this.props.defaultValue === null || this.props.defaultValue === undefined
        ? ''
        : typeof this.props.defaultValue === 'string'
        ? this.props.defaultValue.replace(/\r\n/gi, '\n')
        : this.props.defaultValue.toString(),
    renderFromClick: false
  };

  static displayName = DISPLAY_NAME;

  static defaultProps = {
    disabled: false,
    displayErrors: false,
    hasError: false,
    hasRequired: true,
    hasSuccess: false,
    readOnly: false,
    required: false
  };

  textInput = createRef();
  suggestionLocationOnClick = createRef();

  handleClick = e => {
    const textInput = this.textInput.current;
    const displayedText = this.textInput.current?.defaultValue || '';

    if (!textInput) {
      return null;
    }

    e.preventDefault();
    const currentValue = this.state.inputValue || '';
    const currentCursorPlacement = textInput?.selectionEnd || 0;
    let displayStarterText = displayedText.slice(0, currentCursorPlacement);
    if (matchingBracketsInput(currentValue)) {
      let trueCursor = calculateTrueCursorPosition(
        currentValue,
        currentCursorPlacement,
        displayStarterText
      );
      const adjustedCursor = adjustTrueCursorOutsideFieldBoundary(
        trueCursor,
        currentValue
      );
      const displayTextOffSetOfCursor = adjustedCursor - trueCursor;
      trueCursor = adjustedCursor;
      const starterText = currentValue.slice(0, trueCursor);
      const amountOfBrackets = starterText.match(/{{|}}/g)?.length || 0;
      // Adjust Display text to match offset from adjusting cursor within fields
      displayStarterText = displayedText.slice(
        0,
        currentCursorPlacement + displayTextOffSetOfCursor
      );

      const displayedDoubleBrackets =
        displayStarterText.match(/{{|}}/g)?.length || 0;
      const hiddenBrackets = (amountOfBrackets - displayedDoubleBrackets) * 2;
      const firstHalf =
        (starterText && starterText.slice(-1) !== ' '
          ? starterText + ' '
          : starterText) + '{{';
      const updatedText = firstHalf + currentValue.slice(trueCursor);
      textInput.focus();
      this.setState(
        {
          inputValue: updatedText
        },
        () => {
          textInput.selectionStart = firstHalf.length - hiddenBrackets;
          textInput.selectionEnd = firstHalf.length - hiddenBrackets;
        }
      );
    } else {
      textInput.focus();
      const updatedText =
        (this.state.inputValue && this.state.inputValue.slice(-1) !== ' '
          ? this.state.inputValue + ' '
          : this.state.inputValue) + '{{';
      this.setState({
        inputValue: updatedText
      });
      textInput.selectionStart = textInput.value.length;
      textInput.selectionEnd = textInput.value.length;
    }
  };

  handleChange = (event: Object) => {
    const { id, name, onChange } = this.props;
    const inputValue = (event.target.value || '').replace(/\r\n/gi, '\n');
    this.setState({
      inputValue,
      renderFromClick: false
    });
    if (onChange) {
      onChange({ currentTarget: { id, name, value: inputValue } });
    }
  };

  render() {
    const {
      'data-testid': dataTestID,
      appendSpaceOnAdd,
      classes,
      className,
      data,
      defaultValue,
      description,
      disabled,
      displayErrors,
      displayTransform,
      hasError,
      hasRequired,
      hasSuccess,
      hasValidation,
      inputRef,
      markup,
      mentionInputClassName,
      name,
      onChange,
      readOnly,
      regex,
      renderSuggestion,
      required,
      theme,
      trigger,
      triggerElement,
      ...props
    } = this.props;
    const { inputValue, renderFromClick } = this.state;
    const hasValue =
      inputValue && typeof inputValue === 'string' && inputValue.length > 0;
    const isRequired = required && !hasValue;
    const mentionDataTestID = dataTestID
      ? `${dataTestID}-tokenPickerInput`
      : 'tokenPickerInput';
    const screenSize = document.body?.clientWidth || 0;
    const style = makeStyles({
      renderFromClick,
      screenSize,
      disabled,
      readOnly
    });

    return (
      <div
        className={classNames(
          classes.root,
          {
            [classes.error]: hasError || (displayErrors && isRequired),
            [classes.displayErrors]: displayErrors,
            [classes.success]: hasSuccess || (displayErrors && !isRequired)
          },
          className
        )}
        data-testid={mentionDataTestID}
      >
        <MentionsInput
          suggestionsPortalHost={
            renderFromClick && screenSize > devices.mobile.landscape
              ? this.suggestionLocationOnClick.current
              : null
          }
          className={classNames(classes.mentionInput, mentionInputClassName)}
          data-testid={dataTestID || 'mention-input'}
          disabled={disabled}
          onChange={this.handleChange}
          readOnly={readOnly}
          required={required}
          style={style}
          value={inputValue}
          inputRef={this.textInput}
          {...props}
        >
          <Mention
            appendSpaceOnAdd={appendSpaceOnAdd}
            data={data}
            displayTransform={displayTransform}
            markup={markup}
            trigger={trigger}
            regex={regex}
            renderSuggestion={(
              /* This order is important */
              suggestion,
              search,
              highlightedDisplay,
              index,
              focused
            ) => {
              return (
                <div
                  className={classNames(classes.menuItem, {
                    [classes.focused]: focused
                  })}
                >
                  {renderSuggestion(suggestion)}
                </div>
              );
            }}
            style={style.mentionHighlight}
          />
        </MentionsInput>
        {/* Store markdown encoded body  */}
        <textarea
          className={classes.hiddenTextarea}
          data-testid="mention-hidden-textarea"
          id={name}
          name={name}
          readOnly
          ref={inputRef}
          required={required}
          value={inputValue}
        />
        {isRequired && (
          <InputRequired
            className={classes.required}
            {...{ disabled, hasRequired, readOnly, required }}
          />
        )}
        {triggerElement && !disabled && !readOnly && (
          <>
            <div className={classes.descriptionContainer}>
              <div
                role="button"
                tabIndex={0}
                onKeyPress={e => {
                  this.setState({ renderFromClick: true });
                  this.handleClick(e);
                }}
                onClick={e => {
                  this.setState({ renderFromClick: true });
                  this.handleClick(e);
                }}
              >
                {triggerElement}
              </div>
            </div>
            <div ref={this.suggestionLocationOnClick}> </div>
          </>
        )}
      </div>
    );
  }
}

type EnhancedProps = {
  appendSpaceOnAdd?: boolean,
  data: Array<any>,
  'data-testid'?: string,
  defaultValue?: any,
  description?: Node,
  displayErrors?: boolean,
  displayTransform?: (id: string, display: string) => string,
  hasError?: boolean,
  hasRequired?: boolean,
  hasSuccess?: boolean,
  inputRef?: Ref<*>,
  markup: string,
  mentionInputClassName?: string,
  regex?: RegExp,
  renderSuggestion: (suggestion: any) => Node,
  trigger: string | RegExp,
  triggerElement?: Node
};

const EnhancedTokenPickerInput: ComponentType<EnhancedProps> = compose(
  setDisplayName(DISPLAY_NAME),
  injectSheet(Style)
)(TokenPickerInput);

export default EnhancedTokenPickerInput;
