// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import compose from 'ramda/src/compose';
import mergeLeft from 'ramda/src/mergeLeft';
import { ReactComponent as ChevronGlyph } from '@catalytic/catalytic-icons/lib/glyphs/chevron.svg';
import { makeStyles } from '@material-ui/styles';
import AvatarV2 from '../AvatarV2/AvatarV2';
import Grid from '../../Grid/Grid';
import { ExtraSmallText } from '../../Text/Text';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0.375rem 1rem',
    height: '3rem',
    textAlign: 'left'
  },
  clickable: {
    '&:active, &:focus, &:hover': {
      backgroundColor: theme.colors.blueGrey
    }
  },
  active: {
    backgroundColor: theme.colors.blueGrey,
    '&:active, &:focus, &:hover': {
      backgroundColor: theme.colors.blueGrey
    }
  },
  disabled: theme.mixins.disabled,
  skeleton: {
    backgroundColor: theme.colors.white,
    '&:active, &:focus, &:hover': {
      backgroundColor: theme.colors.white
    },
    '& > div': {
      borderRadius: theme.variables.borderRadiusSmall,
      backgroundColor: theme.colors.lightGrey,
      '& $avatar, & $displayName, & $description, & $angleBracket': {
        visibility: 'hidden'
      }
    }
  },
  avatarContainer: {
    marginRight: '0.75rem',
    '&>div': {
      // override inline-block so the icon aligns vertically with the item text
      display: 'block!important'
    }
  },
  avatar: {
    '&, &:active, &:focus, &:hover, &>svg': {
      padding: 0,
      width: '1.5rem',
      height: '1.5rem',
      borderRadius: 0
    }
  },
  displayName: {
    ...theme.mixins.truncate,
    display: 'block',
    color: theme.colors.pebbleGrey,
    fontWeight: '500'
  },
  description: {
    ...theme.mixins.truncate,
    display: 'block',
    color: theme.colors.battleshipGrey
  },
  angleBracketContainer: {
    marginLeft: '0.75rem'
  },
  angleBracket: {}
}));

type Props = {|
  active?: boolean,
  angleBracket?: boolean,
  backgroundColor?: string,
  className?: string,
  clickable?: boolean,
  description?: string | React.Element<typeof FormattedMessage>,
  // Applies grayscale, reduced opacity styles for displayName and description,
  // but leaves icon colors as-is
  disabled?: boolean,
  displayName: string | React.Element<typeof FormattedMessage>,
  icon?: React.Node,
  iconSrc?: string,
  skeleton?: boolean,
  unread?: boolean
|};

const SmallListItem = ({
  active,
  angleBracket,
  backgroundColor,
  className,
  clickable = true,
  description,
  disabled,
  displayName,
  icon,
  iconSrc,
  skeleton,
  unread
}: Props) => {
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      className={classNames(
        classes.container,
        {
          [classes.clickable]: clickable,
          [classes.active]: active && !skeleton,
          [classes.skeleton]: skeleton
        },
        className
      )}
      container
    >
      <Grid className={classes.avatarContainer} item>
        <AvatarV2
          appearance="square"
          borderColor="transparent"
          className={classes.avatar}
          component={({ className, children }) => {
            return <span className={className}>{icon || children}</span>;
          }}
          presence={unread && !skeleton ? 'online' : undefined}
          size="small"
          src={iconSrc}
          theme={(current, props) =>
            compose(
              mergeLeft({
                backgroundColor: backgroundColor || 'transparent',
                borderRadius: 0,
                dimensions: {
                  width: 'auto',
                  height: 'auto'
                },
                presence: {
                  bottom: 'auto',
                  left: 'auto',
                  right: '-.25rem',
                  top: '-.25rem',
                  height: '.75rem',
                  width: '.75rem'
                }
              }),
              current
            )(props)
          }
        />
      </Grid>
      <Grid
        className={classNames({ [classes.disabled]: disabled && !skeleton })}
        item
        xs
        zeroMinWidth
      >
        <ExtraSmallText className={classes.displayName}>
          {displayName}
        </ExtraSmallText>
        {description && (
          <ExtraSmallText className={classes.description}>
            {description}
          </ExtraSmallText>
        )}
      </Grid>
      {angleBracket && (
        <Grid className={classes.angleBracketContainer} item>
          <ChevronGlyph className={classes.angleBracket} />
        </Grid>
      )}
    </Grid>
  );
};
SmallListItem.displayName = 'SmallListItem';

export default SmallListItem;
