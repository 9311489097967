// @flow

import React, { type ElementType } from 'react';
import classNames from 'classnames';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../style/ThemeTypes';
import injectSheet from '../style/injectSheet';

const Style = (theme: ThemeType) => ({
  root: {
    ...theme.typography.baseText,
    whiteSpace: 'pre-line',
    '& + $root': {
      marginTop: '1rem'
    }
  }
});

type Props = InjectSheetProvidedProps & {
  /**
   * Component to be used as the heading
   */
  component: ElementType
};

function MarkdownBaseText(props: Props) {
  const {
    classes,
    className: classNameProp,
    component: Component,
    theme,
    ...other
  } = props;
  const className = classNames(classes.root, classNameProp);

  return <Component {...{ ...other, className }} />;
}

MarkdownBaseText.displayName = 'MarkdownBaseText';
MarkdownBaseText.defaultProps = {
  component: 'div'
};

export default injectSheet(Style)(MarkdownBaseText);
