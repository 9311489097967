import gql from 'graphql-tag';
import { RunProcessFragment } from '../Process/ProcessTypeDefs';
import { FieldFragment } from '../Field/FieldTypeDefs';

export const RunListItemFragment = gql`
  fragment RunListItemFragment on Context {
    actionsActiveCount
    actionsCompletedCount
    displayName
    endDate
    id
    isOverdue
    milestone
    owner {
      id
      displayName
    }
    participants {
      active
      id
      overdue
      user {
        id
        __typename
      }
    }
    percentComplete
    runStatus: status
    startDate
  }
`;

export const RunListItemCompletedFragment = gql`
  fragment RunListItemCompletedFragment on Context {
    displayName
    endDate
    id
    owner {
      id
      displayName
    }
    percentComplete
    startDate
    status
  }
`;

export const RunCommentFragment = gql`
  fragment RunCommentFragment on Comment {
    actor {
      id
      fullName
      nickname
      thumbnailUrl
    }
    id
    body(input: { format: MARKDOWN })
    createdAt
  }
`;

export const RunDetailFragment = gql`
  ${RunProcessFragment}
  ${FieldFragment}

  fragment RunDetailFragment on Context {
    actionsActiveCount
    actionsCompletedCount
    actionsSkippedCount
    actionsTotalCount
    context {
      id
      displayName
    }
    deadline
    description
    displayName
    fields {
      nodes {
        id
        ...FieldFragment
      }
    }
    id
    isOverdue
    milestone
    milestones {
      nodes {
        displayName
        endDate
        isCurrentMilestone
        startDate
        status
      }
    }
    owner {
      id
      displayName
      thumbnailUrl
    }
    percentComplete
    status
    startDate
    abortedTasks: tasks(status: ABORTED, first: 1) {
      totalCount
    }
    failedTasks: tasks(status: FAILED, first: 1) {
      totalCount
    }
    rejectedTasks: tasks(status: REJECTED, first: 1) {
      totalCount
    }
    testMode
    type {
      id
      ...RunProcessFragment
    }
  }
`;

export const ContextQuery = gql`
  ${RunDetailFragment}

  query ContextQuery($id: String!) {
    context(id: $id) {
      id
      ...RunDetailFragment
    }
  }
`;

export const UPDATE_CONTEXT = gql`
  ${RunDetailFragment}

  mutation UpdateContext($input: ContextInput!) {
    updateContext(input: $input) {
      context {
        id
        ...RunDetailFragment
      }
    }
  }
`;

export const ContextFieldsQuery = gql`
  ${FieldFragment}
  query ContextFieldsQuery($id: String!) {
    context(id: $id) {
      id
      fields {
        nodes {
          id
          ...FieldFragment
        }
      }
      pinnedFields {
        id
        ...FieldFragment
      }
      status
    }
  }
`;
