// @flow

import React from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';

const Style = (theme: ThemeType) => ({
  root: {
    backgroundColor: theme.colors.blueGrey,
    borderRadius: theme.variables.borderRadius
  }
});

type Props = InjectSheetProvidedProps &
  HTMLElement & {
    displayErrors?: boolean,
    hasError?: boolean,
    hasValidation?: boolean,
    inputClassName?: string,
    setDisplayErrorsState?: (displayErrors: boolean) => mixed,
    setLoadingState?: (loading: boolean) => mixed
  };

const BuilderListView = ({
  classes,
  className,
  displayErrors,
  hasError,
  hasValidation,
  inputClassName,
  setDisplayErrorsState,
  setLoadingState,
  theme,
  ...props
}: Props) => <div className={classNames(classes.root, className)} {...props} />;

BuilderListView.displayName = 'BuilderListView';

export default injectSheet(Style)(BuilderListView);
