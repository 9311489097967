// @flow

import * as React from 'react';
import { InputConsumer, InputV2, TextInput } from '@catalytic/catalytic-ui';
import {
  JSONRefType,
  JSONType,
  type JSONSchema
} from '@catalytic/json-schema-validator-catalytic-web';
import { types } from '@catalytic/react-view';
import useFieldContext from './FieldContext';
import { useHandleBlur, useHandleChange } from './ViewHelpers';
import TableFieldLoading from '../Field/TableFieldLoading';

type Props = {
  className?: string,
  contextValues?: { [string]: any },
  description?: null | string | React.Element<typeof FormattedMessage>,
  disabled?: boolean,
  displayErrors?: boolean,
  displayName: string | React.Element<typeof FormattedMessage>,
  example?: null | string | React.Element<typeof FormattedMessage>,
  fieldName?: string,
  hasValidation?: boolean,
  id: string,
  name: string,
  onBlur: (...args: Array<any>) => any,
  onChange: (...args: Array<any>) => any,
  readOnly?: boolean,
  required?: boolean,
  schema?: JSONSchema<>,
  type?: $Keys<typeof JSONType>,
  value?: any
};

const LazyTableFieldLoading = ({
  className,
  contextValues,
  description,
  disabled,
  displayName,
  example,
  fieldName,
  hasValidation,
  id,
  name,
  onBlur,
  onChange,
  readOnly,
  schema,
  type,
  value
}: Props) => (
  <InputV2
    className={className}
    contextValues={contextValues}
    description={description}
    disabled={disabled}
    example={example}
    fieldName={fieldName}
    hasValidation={hasValidation}
    id={id}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    readOnly={readOnly}
    schema={schema}
    title={displayName}
    type={type}
    value={value}
  >
    <TableFieldLoading />
  </InputV2>
);
LazyTableFieldLoading.displayName = 'LazyTableFieldLoading';

const LazyTableInput = React.lazy(() =>
  import(/* webpackChunkName: "TableFieldV2" */ '../Field/TableFieldV2')
);

const TableInput = (props: Props) => (
  <React.Suspense fallback={<LazyTableFieldLoading {...props} />}>
    <LazyTableInput {...props} />
  </React.Suspense>
);
TableInput.displayName = 'TableInput';

const ReferenceView = ({
  schema: getSchema,
  setValue,
  value
}: types.ReferenceView) => {
  const schema = getSchema();
  const fieldContext = useFieldContext();
  const {
    className,
    description,
    disabled,
    displayErrors,
    displayName,
    embedded,
    example,
    hasValidation,
    id,
    fieldName,
    contextValues,
    name,
    onBlur,
    onChange,
    readOnly,
    required,
    type
  } = fieldContext || {};
  const handleBlur = useHandleBlur({ onBlur, setValue, type });
  const handleChange = useHandleChange({ onChange, setValue, type });
  const refType = schema?.refType;

  // Should not render a Data Table reference type input if the value is not a string.
  if (refType === JSONRefType.DATA_TABLE && typeof value !== 'string') {
    return null;
  }

  // Should render a Data Table reference type input if the value is a string.
  if (refType === JSONRefType.DATA_TABLE && typeof value === 'string') {
    return (
      <TableInput
        className={className}
        contextValues={contextValues}
        data-testid="referenceDataTableView"
        description={description}
        disabled={disabled}
        displayName={displayName}
        example={example}
        fieldName={fieldName}
        hasValidation={hasValidation}
        id={id}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onUpdate={handleChange}
        readOnly={readOnly}
        required={required}
        schema={schema}
        type={type}
        value={value}
      />
    );
  }

  // Should render a default reference type input.
  return (
    <InputV2
      className={className}
      contextValues={contextValues}
      description={description}
      disabled={disabled}
      displayErrors={displayErrors}
      example={example}
      fieldName={fieldName}
      hasValidation={hasValidation}
      id={id}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      readOnly={readOnly}
      schema={schema}
      title={displayName}
      type={type}
      value={value}
    >
      <InputConsumer>
        {props => (
          <TextInput
            data-testid="referenceView"
            defaultValue={value}
            embedded={embedded}
            id={id}
            name={name}
            required={required}
            {...props}
          />
        )}
      </InputConsumer>
    </InputV2>
  );
};
ReferenceView.displayName = 'ReferenceView';

export default React.memo<types.ReferenceView>(ReferenceView);
