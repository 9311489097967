// @flow

import * as React from 'react';
import { useApolloClient, useQuery } from 'react-apollo';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import gql from 'graphql-tag';
import RPath from 'ramda/src/path';
import { makeStyles } from '@material-ui/styles';
import { FileInput, showToast, TOAST_TYPE } from '@catalytic/catalytic-ui';
import { ADD_FILE, MUTATION } from './FileField';
import { TYPE, type FieldType } from './FieldTypes';
import { FILE as FILE_LABEL } from '../const/label';
import TopBarLoader from '../Loading/TopBarLoader';
import { NODE_TYPE, type Node } from '../shared/NodeTypes';
import logError from '../utils/logError';
import isUUID from '../utils/uuid';

const FileFragment = gql`
  fragment FileFragment on File {
    filename
    id
    url
  }
`;

export const FILES_QUERY = gql`
  ${FileFragment}

  query Files($ids: [ID]!) {
    files(ids: $ids) {
      id
      ...FileFragment
    }
  }
`;

const useStyles = makeStyles({
  inputShadow: {
    display: 'none'
  }
});

const Messages = defineMessages({
  placeholder: {
    id: 'FileArrayField.placeholder',
    defaultMessage: 'No file(s) chosen'
  }
});

type FileArrayValue = null | string | Array<string>;

export const valueForType = (
  type: FieldType,
  files: Array<{ id: string }>
): FileArrayValue => {
  const fileIDArray = files.map(f => f.id);
  if (fileIDArray.length === 0) {
    return null;
  }
  if (type === TYPE.ARRAY) {
    return fileIDArray;
  }
  return fileIDArray.join(',');
};

export const handleChangeFactory = (props: {
  client: { mutate: (options: Object) => Promise<Object> },
  context: ?Node,
  onChange: (...args: Array<any>) => any,
  name: string,
  refetch: ({ ids: Array<string> }) => Promise<void | Object>,
  setValue: FileArrayValue => any,
  type: FieldType
}) => ({ currentTarget }: { currentTarget: { value: any } }) => {
  const { client, context, onChange, name, refetch, setValue, type } = props;
  const { __typename, id: contextId } = context || {};

  const mutation = MUTATION[__typename || NODE_TYPE.TEAM];

  const files = currentTarget.value || [];
  const newFiles = files.filter(f => !isUUID(f.id));
  const existingFiles = files.filter(f => isUUID(f.id));

  if (newFiles.length === 0) {
    const newValue = valueForType(type, existingFiles);
    setValue(newValue);
    onChange({ currentTarget: { name, value: newValue } });
    return;
  }

  client
    .mutate(
      ADD_FILE(mutation, {
        id: contextId,
        files: newFiles.map(f => f.value)
      })
    )
    .then(({ data }) => {
      const files = RPath([mutation, 'files'], data);
      const mergedFiles = [...existingFiles, ...files];
      return refetch({
        ids: mergedFiles.map(f => f.id)
      }).finally(() => {
        const newValue = valueForType(type, mergedFiles);
        setValue(newValue);
        onChange({ currentTarget: { name, value: newValue } });
      });
    })
    .catch(error => {
      logError(error);
      showToast(
        TOAST_TYPE.error,
        <FormattedMessage
          id="FileArrayField.uploadFile.error"
          defaultMessage="There was a problem uploading the file."
        />
      );
    });
};

const FileArrayField = (props: {
  appID?: string,
  context: ?Node,
  'data-testid'?: string,
  defaultValue: FileArrayValue,
  disabled?: boolean,
  displayErrors?: boolean,
  embedded?: boolean,
  id: string,
  onChange: (...args: Array<any>) => any,
  name: string,
  required: boolean,
  type: FieldType
}) => {
  const {
    context,
    'data-testid': dataTestID,
    defaultValue,
    disabled,
    name,
    onChange,
    type,
    ...other
  } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const intl = useIntl();
  const [value, setValue] = React.useState<FileArrayValue>(defaultValue);

  const fileIDs =
    typeof value === 'string'
      ? value.split(',')
      : Array.isArray(value)
      ? value
      : [];
  const validFileIDs = fileIDs.filter(id => isUUID(id));

  const { data, loading, refetch } = useQuery(FILES_QUERY, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    onError: error => {
      logError(error);
    },
    skip: validFileIDs.length === 0,
    variables: {
      ids: validFileIDs
    }
  });
  const files = (data?.files || []).map(file => {
    return {
      id: file?.id,
      name: file?.filename || FILE_LABEL,
      value: { preview: file?.url }
    };
  });

  if (loading && files.length === 0) {
    return (
      <div data-testid={dataTestID}>
        <TopBarLoader />
        <FileInput
          loading
          multiple
          placeholder={intl.formatMessage(Messages.placeholder)}
          {...other}
        />
      </div>
    );
  }
  return (
    <div data-testid={dataTestID}>
      {loading && <TopBarLoader />}
      <FileInput
        defaultValue={files.map(f => f.id).join(',') || ''}
        files={files}
        multiple
        onChange={handleChangeFactory({
          client,
          context,
          onChange,
          name,
          refetch,
          setValue,
          type
        })}
        placeholder={intl.formatMessage(Messages.placeholder)}
        {...other}
      />
      <input
        className={classes.inputShadow}
        data-name={name}
        name={name}
        onChange={() => {}}
        value={value || ''}
      />
    </div>
  );
};

export default FileArrayField;
