// @flow

import classNames from 'classnames';
import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import { type InjectSheetProvidedProps } from '../../style/ThemeTypes';
import { Header2 } from '../../Text/Text';

const Style = () => {
  return {
    root: {}
  };
};

type Props = InjectSheetProvidedProps & HTMLElement;

const ListTitleHeader = ({
  classes,
  className,
  theme,
  ...props
}: Props): Node => (
  <Header2 className={classNames(classes.root, className)} {...props} />
);

ListTitleHeader.displayName = 'ListTitleHeader';
export default injectSheet(Style)(ListTitleHeader);
