// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { BaseText } from '@catalytic/catalytic-ui';
import { makeStyles } from '@material-ui/styles';
import Track from '../Analytics/Track';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    padding: '6em'
  }
});

const FourOFour = () => {
  const classes = useStyles();
  const location = useLocation();
  const pathname = location?.pathname || 'error:404';

  return (
    <div className={classes.root} data-testid="404">
      <BaseText>
        <FormattedMessage
          id="404"
          defaultMessage="We're sorry, the page you were looking for does not exist."
        />
      </BaseText>
      <Track action="error" category="404" label={pathname} />
    </div>
  );
};
FourOFour.displayName = 'FourOFour';

export default FourOFour;
