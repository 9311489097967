// @flow

import React, { type ElementType } from 'react';
import classNames from 'classnames';
import { type InjectSheetProvidedProps } from '../style/ThemeTypes';
import injectSheet from '../style/injectSheet';

const Style = {
  root: {
    height: 'auto',
    padding: 0,
    minWidth: 0,
    fontSize: 'inherit',
    userSelect: 'auto'
  }
};

type Props = InjectSheetProvidedProps & {
  /**
   * Component to be used as the heading
   */
  component: ElementType,
  /**
   * The link's target window
   */
  target: string,
  /**
   * The link's url
   */
  url: string
};

function MarkdownLink(props: Props) {
  const {
    classes,
    className: classNameProp,
    component: Component = 'button',
    target,
    theme,
    url,
    ...other
  } = props;
  const className = classNames(classes.root, classNameProp);
  const componentProps = Object.assign({}, other);
  delete componentProps.referenceType;

  return <Component {...{ ...componentProps, className, href: url, target }} />;
}

MarkdownLink.displayName = 'MarkdownLink';
MarkdownLink.defaultProps = {
  component: 'a',
  target: '_blank'
};

export default injectSheet(Style)(MarkdownLink);
