// @flow

import { intl } from '../Locale/LocaleContext';

export const ACTOR = intl.formatMessage({
  defaultMessage: 'User',
  id: 'actor'
});
export const CHECKBOX = intl.formatMessage({
  defaultMessage: 'Checkbox',
  id: 'checkbox'
});
export const CODE = intl.formatMessage({
  defaultMessage: 'JSON',
  id: 'code'
});
export const CONTEXT = intl.formatMessage({
  defaultMessage: 'Instance',
  id: 'context'
});
export const CONTEXT_TYPE = intl.formatMessage({
  defaultMessage: 'Workflow',
  id: 'contextType'
});
export const DATE = intl.formatMessage({
  defaultMessage: 'Date',
  id: 'date'
});
export const DATE_TIME = intl.formatMessage({
  defaultMessage: 'Date and Time',
  id: 'dateTime'
});
export const EMAIL = intl.formatMessage({
  defaultMessage: 'Email',
  id: 'email'
});
export const FILE = intl.formatMessage({
  defaultMessage: 'File',
  id: 'file'
});
export const FILE_TABLE = intl.formatMessage({
  defaultMessage: 'Multiple File',
  id: 'fileTable'
});
export const GROUP = intl.formatMessage({
  defaultMessage: 'JSON',
  id: 'group'
});
export const INSTRUCTIONS = intl.formatMessage({
  defaultMessage: 'Instructions',
  id: 'instructions'
});
export const INTEGER = intl.formatMessage({
  defaultMessage: 'Integer',
  id: 'integer'
});
export const MULTIPLE_SELECTION = intl.formatMessage({
  defaultMessage: 'Multiple Choice',
  id: 'multipleSelection'
});
export const NUMBER = intl.formatMessage({
  defaultMessage: 'Decimal',
  id: 'number'
});
export const PASSWORD = intl.formatMessage({
  defaultMessage: 'Password',
  id: 'password'
});
export const REFERENCE = intl.formatMessage({
  defaultMessage: 'Reference',
  id: 'reference'
});
export const SELECTION = intl.formatMessage({
  defaultMessage: 'Single Choice',
  id: 'selection'
});
export const TABLE = intl.formatMessage({
  defaultMessage: 'Table',
  id: 'table'
});
export const TEXT_AREA = intl.formatMessage({
  defaultMessage: 'Long Text',
  id: 'textArea'
});
export const TEXT = intl.formatMessage({
  defaultMessage: 'Short Text',
  id: 'text'
});
export const TIMEZONE = intl.formatMessage({
  defaultMessage: 'Timezone',
  id: 'timezone'
});
export const TOGGLE_SWITCH = intl.formatMessage({
  defaultMessage: 'True or False',
  id: 'toggleSwitch'
});
