// @flow

import * as React from 'react';
import { useIntl } from 'react-intl';
import TableFieldPlaceholder from './TableFieldPlaceholder';

export const TableFieldLoading = () => {
  const intl = useIntl();
  const loadingMessage = intl.formatMessage({
    id: 'table.loading',
    defaultMessage: 'Loading...'
  });

  return (
    <TableFieldPlaceholder data-error={loadingMessage}>
      {loadingMessage}
    </TableFieldPlaceholder>
  );
};
TableFieldLoading.displayName = 'TableFieldLoading';

export default TableFieldLoading;
