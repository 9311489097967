// @flow

import * as React from 'react';
import { compose, setDisplayName } from 'recompose';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import { type FieldSuggestion } from './FieldReferenceInput';

type BaseProps = FieldSuggestion &
  InjectSheetProvidedProps & { contentClassName?: string };

export const FieldReferenceListItemStyle = (theme: ThemeType) => {
  return {
    source: {
      ...theme.mixins.truncate,
      ...theme.typography.extraSmallText,
      fontSize: '.6875rem',
      lineHeight: '.9375rem',
      color: theme.colors.battleshipGrey
    },
    content: theme.mixins.truncate,
    displayName: {
      ...theme.mixins.truncate,
      ...theme.typography.header5,
      fontWeight: '600',
      marginRight: '.2rem',
      maxWidth: '75%'
    },
    type: {
      ...theme.typography.header5,
      fontWeight: 400
    }
  };
};

export function BaseFieldReferenceListItem({
  classes,
  contentClassName,
  theme,
  ...suggestion
}: BaseProps) {
  return (
    <>
      {suggestion.source && (
        <div className={classes.source}>{suggestion.source}</div>
      )}
      <div className={classNames(classes.content, contentClassName)}>
        <span title={suggestion.display} className={classes.displayName}>
          {suggestion.display}
        </span>
        <span className={classes.type}>({suggestion.type})</span>
      </div>
    </>
  );
}
BaseFieldReferenceListItem.displayName = 'BaseFieldReferenceListItem';

type EnhancedProps = FieldSuggestion & { contentClassName?: string };

export default (compose(
  setDisplayName('FieldReferenceListItem'),
  injectSheet(FieldReferenceListItemStyle)
)(BaseFieldReferenceListItem): React.ComponentType<EnhancedProps>);
