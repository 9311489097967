// @flow

import variables from './variables';

const functions = {
  /* PX-to-REM
  /* ----------------------------------- */
  toRem: (px: number): string => {
    return `${px / variables.base}rem`;
  }
};

export default functions;
