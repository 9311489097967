// @flow

import React from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import { injectIntl, FormattedDate } from 'react-intl';
import { compose } from 'recompose';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import { ExtraSmallText, Header1 } from '../../Text/Text';

const Style = (theme: ThemeType) => {
  return {
    /* Due to the precise nature of the component,
     * pixel measurements are desired */
    root: {
      borderRadius: '.5rem',
      backgroundColor: theme.colors.dullRed,
      display: 'inline-block',
      padding: 0,
      position: 'relative'
    },
    calendar: {
      display: 'inline-block',
      textAlign: 'center'
    },
    text: {
      position: 'absolute',
      width: '100%',
      left: 0,
      top: '.35em',
      textAlign: 'center'
    },
    textDay: {
      fontWeight: 400,
      fontSize: '60%',
      color: theme.colors.pebbleGrey,
      textTransform: 'uppercase'
    },
    textDate: {
      fontWeight: 400,
      lineHeight: '1.5rem',
      [theme.breakpoints.gt.tablet.landscape]: {
        lineHeight: '1.25rem' /* Account for font scale */
      }
    }
  };
};

type Props = InjectSheetProvidedProps & {
  calDate: string,
  intl: Object
};

const CalendarIcon = ({
  calDate,
  classes,
  className,
  intl,
  theme,
  ...props
}: Props) => (
  <div className={classNames(classes.root, className)} {...props}>
    <div className={classes.calendar}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        className={classes.svg}
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M10.54 11.022h42.92c1.03 0 1.866.837 1.866 1.867v40.156c0 1.03-.836 1.866-1.866 1.866H10.54a1.867 1.867 0 0 1-1.866-1.866V12.889c0-1.03.836-1.867 1.866-1.867z"
          />
          <path
            fill="#F2F2F2"
            fillRule="nonzero"
            d="M8.674 53.045c0 1.03.836 1.866 1.866 1.866h42.92c1.03 0 1.866-.836 1.866-1.866V33.434H8.674v19.61z"
          />
          <path
            fill="#2B414D"
            fillRule="nonzero"
            d="M8.674 30.632a.933.933 0 0 0-.933.934v2.802a.933.933 0 1 0 1.866 0v-2.802a.933.933 0 0 0-.933-.934zm46.652 0a.933.933 0 0 0-.933.934v2.802a.933.933 0 1 0 1.866 0v-2.802a.933.933 0 0 0-.933-.934z"
          />
          <path
            fill="#E5E5E5"
            fillRule="nonzero"
            d="M9.607 32.5h44.786v.934H9.607z"
          />
          <path
            fill="#B5B5B6"
            fillRule="nonzero"
            d="M53.46 53.978H10.54a1.867 1.867 0 0 1-1.866-1.867v.934c0 1.03.836 1.866 1.866 1.866h42.92c1.03 0 1.866-.836 1.866-1.866v-.934c0 1.03-.836 1.867-1.866 1.867z"
          />
        </g>
      </svg>
      <span className={classes.text}>
        <ExtraSmallText className={classes.textDay}>
          <FormattedDate value={calDate} weekday="short" />
        </ExtraSmallText>
        <Header1 className={classes.textDate}>
          <FormattedDate value={calDate} day="numeric" />
        </Header1>
      </span>
    </div>
  </div>
);
CalendarIcon.displayName = 'CalendarIcon';

const EnhancedCalendarIcon = compose(
  injectIntl,
  injectSheet(Style)
)(CalendarIcon);
EnhancedCalendarIcon.displayName = 'CalendarIcon';

export default EnhancedCalendarIcon;
