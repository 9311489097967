// @flow

import React, { type Ref } from 'react';
import classNames from 'classnames';
import { compose, lifecycle, withProps, withStateHandlers } from 'recompose';
import path from 'ramda/src/path';
import { ReactComponent as CloseGlyph } from '@catalytic/catalytic-icons/lib/glyphs/close.svg';
import { ReactComponent as SearchGlyph } from '@catalytic/catalytic-icons/lib/glyphs/search.svg';
import SearchCategoryChip from './SearchCategoryChip';
import { type Type } from './SearchTypes';
import debounce from '../utils/debounce';

import {
  Spinner,
  injectSheet,
  type InjectSheetProvidedProps,
  type ThemeType
} from '@catalytic/catalytic-ui';

const getValue = (ref: Ref<*>) => path(['current', 'value'], ref);

const hasValue = (ref: Ref<*>) => {
  const value = getValue(ref);
  return typeof value === 'string' && value.length !== 0;
};

const setNativeValue = (el, value) => {
  const { set: valueSetter } =
    Object.getOwnPropertyDescriptor(el, 'value') || {};
  const prototype = Object.getPrototypeOf(el);
  const { set: prototypeValueSetter } =
    Object.getOwnPropertyDescriptor(prototype, 'value') || {};

  if (prototypeValueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(el, value);
  } else if (valueSetter) {
    valueSetter.call(el, value);
  }
};

const setValue = (ref: Ref<*>, nextValue: string) => {
  const value = getValue(ref);
  const el = path(['current'], ref);
  if (
    value !== nextValue &&
    el &&
    typeof el === 'object' &&
    typeof el.dispatchEvent === 'function'
  ) {
    setNativeValue(el, nextValue);
    el.dispatchEvent(new Event('input', { bubbles: true })); // eslint-disable-line no-undef
  }
};

const Style = (theme: ThemeType) => ({
  root: {
    ...theme.mixins.inputStyle,
    ...theme.mixins.truncate,
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    minHeight: '3rem',
    maxHeight: '3rem',
    paddingLeft: '0.625rem',
    paddingRight: '0.625rem',
    '& > * + *': {
      marginLeft: '0.4rem'
    }
  },
  button: {
    alignSelf: 'center',
    background: 'transparent',
    border: 'none',
    marginRight: '-.625rem',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    '& [mask]': {
      fill: theme.colors.battleshipGrey
    }
  },
  input: {
    background: 'transparent',
    border: 'none',
    flex: '1 1',
    '&::placeholder': theme.mixins.inputPlaceholder,
    '&::-webkit-input-placeholder': theme.mixins.inputPlaceholder,
    '&:-ms-input-placeholder': theme.mixins.inputPlaceholder,
    '&::-ms-input-placeholder': theme.mixins.inputPlaceholder,
    '&::-moz-placeholder': theme.mixins.inputPlaceholder,
    '&:-moz-placeholder': theme.mixins.inputPlaceholder,
    width: '100%'
  },
  spinner: {
    borderRightColor: theme.colors.battleshipGrey,
    borderLeftColor: theme.colors.battleshipGrey,
    pointerEvents: 'none'
  },
  search: {
    alignSelf: 'center',
    fontSize: '1.125rem',
    pointerEvents: 'none',
    color: theme.colors.battleshipGrey,
    '& [mask]': {
      fill: theme.colors.battleshipGrey
    }
  }
});

type Props = InjectSheetProvidedProps & {
  inputRef: Ref<*>,
  hasValue?: boolean,
  loading: boolean,
  type?: Type,
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => any,
  onClear?: (event: SyntheticEvent<HTMLButtonElement>) => any,
  value?: string
};

const SearchInput = ({
  classes,
  className,
  loading,
  hasValue,
  inputRef,
  onChange,
  onClear,
  theme,
  type,
  value,
  ...props
}: Props) => (
  <div className={classNames(classes.root, className)}>
    {loading ? (
      <Spinner className={classes.spinner} />
    ) : (
      <SearchGlyph className={classes.search} />
    )}
    {type ? <SearchCategoryChip type={type} /> : null}
    <input
      {...props}
      className={classes.input}
      ref={inputRef}
      onChange={event => {
        if (typeof onChange === 'function') {
          debounce(() => onChange(event), { key: 'globalSearchQueryChanged' });
        }
      }}
      defaultValue={value}
    />
    {hasValue || type ? (
      <button className={classes.button} onClick={onClear}>
        <CloseGlyph />
      </button>
    ) : null}
  </div>
);
SearchInput.displayName = 'SearchInput';

export default compose(
  injectSheet(Style),
  withProps(({ inputRef }) => ({
    inputRef: inputRef || React.createRef()
  })),
  lifecycle({
    UNSAFE_componentWillUpdate({ inputRef, value }) {
      if (this.props.value !== value) {
        setValue(inputRef, value);
      }
    }
  }),
  withStateHandlers(
    ({ value }) => ({
      hasValue: typeof value === 'string' && value.length,
      value: value || ''
    }),
    {
      onChange: (state, { inputRef, onChange }) => (
        event: SyntheticEvent<HTMLInputElement>
      ) => {
        // Call onChange prop if provided
        if (typeof onChange === 'function') {
          onChange(event);
        }

        return {
          hasValue: hasValue(inputRef),
          value: getValue(inputRef)
        };
      },
      onClear: (state, { onClear }) => (
        event: SyntheticEvent<HTMLInputElement>
      ) => {
        // Call onClear prop if provided
        if (typeof onClear === 'function') {
          onClear(event);
        }

        return {
          hasValue: false,
          value: ''
        };
      }
    }
  )
)(SearchInput);
