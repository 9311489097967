// @flow

import * as React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as MoreGlyph } from '@catalytic/catalytic-icons/lib/glyphs/more.svg';
import { makeStyles, useTheme } from '@material-ui/styles';
// https://atomiks.github.io/tippyjs/#all-options
import Tippy from '@tippy.js/react';
import 'tippy.js/themes/light.css';
import { ModalViewContext } from '../../View/ModalView/ModalView';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    borderRadius: theme.variables.borderRadiusSmall
  },
  openerContainer: {
    position: 'relative',
    display: 'inline-flex',
    cursor: 'pointer'
  },
  /**
   * Fix for Tippy/IE11 spotty support of the "contains" method on SVG nodes.
   * This gives a friendly tap-zone of IE11 browsers.
   * https://github.com/atomiks/tippyjs/issues/148
   */
  opener: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.base
  },
  openerGlyph: {
    fontSize: theme.functions.toRem(theme.variables.menuOpenerWidthEmbedded)
  }
}));

type Props = {
  children?: React.Node,
  className?: string,
  opener?: React.Node,
  openerClassName?: string,
  zIndex?: number
};

const Menu = ({
  children,
  className,
  opener: openerProp,
  openerClassName,
  zIndex,
  ...props
}: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const modalViewContext = React.useContext(ModalViewContext);
  const theme = useTheme();
  const tippyInstance = React.useRef();
  const opener = openerProp || (
    <MoreGlyph className={classes.openerGlyph} data-testid="opener-glyph" />
  );

  // Force menu to close on pathname change
  // https://github.com/catalyticlabs/triage/issues/7271
  React.useEffect(() => {
    if (
      tippyInstance &&
      typeof tippyInstance === 'object' &&
      tippyInstance.current &&
      typeof tippyInstance.current === 'object' &&
      typeof tippyInstance.current.hide === 'function'
    ) {
      tippyInstance.current.hide();
    }
  }, [location.pathname]);

  return (
    <Tippy
      a11y
      animateFill={false}
      animation="shift-away"
      arrow
      className={classNames(classes.root, className)}
      content={React.Children.map(children, child =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              onClick: event => {
                if (
                  tippyInstance &&
                  typeof tippyInstance === 'object' &&
                  tippyInstance.current &&
                  typeof tippyInstance.current === 'object' &&
                  typeof tippyInstance.current.hide === 'function'
                ) {
                  tippyInstance.current.hide();
                }
                if (
                  child.props.onClick &&
                  typeof child.props.onClick === 'function'
                ) {
                  child.props.onClick(event);
                }
              }
            })
          : child
      )}
      distance={12}
      interactive
      interactiveBorder={10}
      onCreate={instance => (tippyInstance.current = instance)}
      theme="light"
      trigger="click"
      zIndex={
        typeof zIndex === 'number'
          ? zIndex
          : theme &&
            typeof theme === 'object' &&
            theme.zIndex &&
            typeof theme.zIndex === 'object'
          ? modalViewContext
            ? theme.zIndex.modal
            : theme.zIndex.menu
          : undefined
      }
      {...props}
    >
      <span
        className={classNames(classes.openerContainer, openerClassName)}
        data-testid="opener"
        onClick={event => {
          // Trapping the click so it doesn't propagate up to the parent
          event.stopPropagation();
          event.preventDefault();
        }}
        onKeyDown={event => {
          // Show menu on 'Enter'
          if (event.keyCode === 13) {
            event.target.click();
          }
        }}
        role="button"
        tabIndex={0}
      >
        {opener}
        <span className={classes.opener} />
      </span>
    </Tippy>
  );
};
Menu.defaultProps = {
  placement: 'bottom'
};

export default Menu;
