// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'devextreme-react/tooltip';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { makeStyles } from '@material-ui/styles';
import ClipboardJS from 'clipboard';
import { ReactComponent as CopyToClipboardGlyph } from '@catalytic/catalytic-icons/lib/glyphs/copy-to-clipboard.svg';
import useTextInputContext from '../TextInput/TextInputContext';
import { ExtraSmallText } from '../../Text/Text';
import { ModalViewContext } from '../../View/ModalView/ModalView';
import { type ThemeType } from '../../style/ThemeTypes';

// Styles mostly copied from PasswordInput
// We might move these styles to the theme or a dedicated layout component
// for rendering icons on the right of inputs
const useStyles = makeStyles((theme: ThemeType) => ({
  button: {
    display: 'flex',
    backgroundColor: 'transparent',
    padding: `0 ${theme.functions.toRem(theme.variables.inputPaddingRight)}`,
    color: theme.colors.battleshipGrey,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.black
    },
    '&:disabled': theme.mixins.disabled
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0
  },
  copyToClipboardGlyph: {
    height: '1.5rem',
    width: '1.5rem'
  },
  tooltip: {
    zIndex: ({ modalViewContext }) =>
      `${modalViewContext ? theme.zIndex.modal : theme.zIndex.menu} !important`
  }
}));

export function Copy() {
  const modalViewContext = React.useContext(ModalViewContext);
  const textInputContext = useTextInputContext();
  if (textInputContext === undefined)
    throw new Error(
      `Copy must be used within a TextInputContext.Provider
      Please notify component library team if this component needs to handle a new use case`
    );
  const classes = useStyles({ modalViewContext });
  // Keeps track of tooltip visibility due to copy success
  const [didCopy, setDidCopy] = React.useState<boolean>(false);
  // Keeps track of tooltip visibility due to mouse events
  const [tooltipIsVisible, setTooltipIsVisible] = React.useState<boolean>(
    false
  );
  // Keeps track of when to change the tooltip message. Note: it can not be based on the tooltip visibility
  // states above. The Tooltip component won't unmount the tooltip for about a second after we've passed
  // visibility false, so the message will flip back to the default for the last second before the
  // tooltip umounts.
  const [
    isTooltipSuccessMessage,
    setIsTooltipSuccessMessage
  ] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (didCopy) {
      if (isTooltipSuccessMessage !== true) setIsTooltipSuccessMessage(true);
      // The tooltip success message should linger for 3 seconds after a successful copy
      const timeout = setTimeout(() => setDidCopy(false), 1000 * 3);
      return () => clearTimeout(timeout);
    }
  }, [didCopy, isTooltipSuccessMessage]);

  const buttonRef = React.useRef(null);
  // Using a callback ref rather than useRef hook since we need to be notified when the ref has
  // been attached to the DOM node so we can configure ClipboardJS.
  // https://reactjs.org/docs/hooks-reference.html#useref
  const buttonRefCallback = React.useCallback(
    buttonNode => {
      if (buttonNode) {
        const clipboard = new ClipboardJS(buttonNode, {
          target: () => {
            // I don't understand why ref.current is not readable according to the built-in type def
            // https://github.com/facebook/flow/commit/aa19606d9fcab63185acf1e05270820690fea4d7#r35549743
            // $FlowIgnore
            return textInputContext?.inputRef?.current;
          }
        });
        clipboard.on('success', () => {
          setDidCopy(true);
        });
        // Make ref accessible outside this function so it can be passed as the target node for <Tooltip />
        buttonRef.current = buttonNode;
      }
    },
    [textInputContext]
  );

  return (
    <>
      <div className={classes.buttonContainer}>
        <button
          className={classes.button}
          onMouseEnter={() => setTooltipIsVisible(true)}
          onMouseLeave={() => setTooltipIsVisible(false)}
          ref={buttonRefCallback}
          type="button"
        >
          <CopyToClipboardGlyph className={classes.copyToClipboardGlyph} />
        </button>
      </div>

      <Tooltip
        className={classes.tooltip}
        target={buttonRef.current}
        visible={tooltipIsVisible || didCopy}
        closeOnOutsideClick={false}
        // Don't change the tooltip message back to the default until it has hidden
        onHidden={() => setIsTooltipSuccessMessage(false)}
      >
        <ExtraSmallText>
          {isTooltipSuccessMessage === true ? (
            <FormattedMessage
              defaultMessage="Copied!"
              id="copy.success.tooltip"
            />
          ) : (
            <FormattedMessage defaultMessage="Copy" id="copy.default.tooltip" />
          )}
        </ExtraSmallText>
      </Tooltip>
    </>
  );
}
Copy.displayName = 'Copy';

export default Copy;
