// @flow

import React, { createRef, type ElementRef } from 'react';
import SelectCreatable from 'react-select/creatable';
import { type MultiSelectInputProps } from './MultiSelectInput';

import SortableMultiSelectInput from './SortableMultiSelectInput';
const NEWLINE_REGEX = /\r?\n/;

const valueFromRef = (ref: ElementRef<*>): Array<any> => {
  const rawValue = ref.current.state.value;
  return rawValue ? (Array.isArray(rawValue) ? rawValue : [rawValue]) : [];
};

export type OptionType = { [string]: any };

export type OptionsType = Array<OptionType>;

export const parseOptionsFromClipboardEvent = (
  event: SyntheticClipboardEvent<HTMLElement>
): OptionsType => {
  const clipboardValues = event.clipboardData.getData('Text');
  if (!clipboardValues) {
    return [];
  }
  return clipboardValues
    .split(NEWLINE_REGEX)
    .reduce((unique, item) => {
      const trimmed = item.trim();
      return unique.indexOf(trimmed) !== -1 ? unique : [...unique, trimmed];
    }, [])
    .map(value => ({
      label: value,
      value,
      __isNew__: true
    }));
};

export type CreatableType = {
  ...MultiSelectInputProps,
  inputRef?: ElementRef<*>
};

export function Creatable({ inputRef: ref, ...props }: CreatableType) {
  const inputRef = ref || createRef();
  return (
    <SortableMultiSelectInput
      defaultValue={props.value}
      component={SelectCreatable}
      inputRef={inputRef}
      onInputChange={(newValue, { action }) => {
        // When leaving the field, and the user entered a new item in a
        // creatable, select that new option.
        if (inputRef.current != null && action === 'input-blur') {
          // $FlowIgnore
          const select = inputRef.current.select;
          const newOption = select?.state?.newOption;
          if (newOption) {
            select.select.selectOption(newOption);
          }
        }
      }}
      onPaste={event => {
        // This lets us pull in a list of newline-delimited options from the
        // user's clipboard.
        if (inputRef.current != null && props.isMulti) {
          // $FlowIgnore
          const select = inputRef.current.select;
          event.preventDefault();
          const defaultValue = valueFromRef(inputRef);
          const isValidNewOption = select.props.isValidNewOption;
          const options = select.props.options || [];
          const newOptions = parseOptionsFromClipboardEvent(
            event
          ).filter(({ value }) =>
            isValidNewOption(value, defaultValue, options)
          );
          if (newOptions.length > 0) {
            const mergedValues = [...defaultValue, ...newOptions];
            select.select.setValue(mergedValues);
          }
        }
      }}
      {...props}
    />
  );
}
Creatable.displayName = 'Creatable';
export default Creatable;
