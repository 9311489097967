// @flow

import * as React from 'react';
import {
  NavigationProvider,
  modeGenerator,
  ThemeProvider
} from '@atlaskit/navigation-next';
import { colors as atlaskitColors } from '@atlaskit/theme';
import colors from '../style/colors';

const customThemeMode = modeGenerator({
  product: {
    text: colors.black,
    background: atlaskitColors.background()
  }
});

export function MasterDetailProvider(props: {| children: React.Node |}) {
  const { children } = props;
  return (
    <ThemeProvider
      theme={theme => ({
        ...theme,
        mode: customThemeMode
      })}
    >
      <NavigationProvider
        initialUIController={{
          isResizeDisabled: true,
          productNavWidth: 320
        }}
      >
        {children}
      </NavigationProvider>
    </ThemeProvider>
  );
}
MasterDetailProvider.displayName = 'MasterDetailProvider';

export default MasterDetailProvider;
