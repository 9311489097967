// @flow

import * as React from 'react';
import { branch, compose } from 'recompose';
import map from 'ramda/src/map';
import pathOr from 'ramda/src/pathOr';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  AsyncPaginate,
  SmallText,
  type OptionType,
  type ValueType
} from '@catalytic/catalytic-ui';
import { type DataTable } from './DataTableTypes';
import { fieldValueIsEmpty } from '../Field/FieldHelpers';
import TopBarLoader from '../Loading/TopBarLoader';
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { WAIT as DEBOUNCE_WAIT } from '../const/debounce';
import selectQueryPolicies from '../const/selectQueryPolicies';

export const TableFragment = gql`
  fragment TableFragment on DataTable {
    displayName
    id
  }
`;

export const DataTableQuery = gql`
  ${TableFragment}

  query DataTableQuery($id: ID!) {
    table(id: $id) {
      id
      ...TableFragment
    }
  }
`;

const TableQuery = gql`
  ${TableFragment}

  query Table($query: String, $after: String) {
    search_v2(
      type: DATA_TABLE
      first: 100
      after: $after
      query: $query
      sort: [{ key: DISPLAY_NAME, direction: ASC }]
      filter: [
        { property: ARCHIVED, type: DATA_TABLE, operator: EQ, value: "false" }
      ]
    ) {
      nodes {
        id
        ...TableFragment
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const toSelectOption = (table: DataTable): OptionType => ({
  label: table.displayName,
  value: table.id
});

type Props = {
  intl: Object,
  className?: string,
  client: { query: (options: Object) => Promise<Object> },
  defaultValue?: ValueType | string,
  error?: Object,
  loading?: boolean,
  name?: string,
  table?: DataTable
};

export function TableSelect({
  className,
  client,
  defaultValue: defaultValueProp,
  error,
  intl,
  loading,
  table,
  ...other
}: Props) {
  if (loading) {
    return (
      <div className={className}>
        <TopBarLoader />
        <SmallText>
          <FormattedMessage id="field.loading" defaultMessage="Loading..." />
        </SmallText>
      </div>
    );
  }

  const defaultValue = table
    ? toSelectOption(table)
    : fieldValueIsEmpty(defaultValueProp)
    ? undefined
    : { label: defaultValueProp, value: defaultValueProp };

  const loadOptions = async (query, loadedOptions) => {
    const after = loadedOptions?.length;
    let response;
    try {
      response = await client.query({
        query: TableQuery,
        variables: {
          query,
          after: typeof after === 'number' ? String(after) : after
        }
      });
    } catch (error) {
      response = {};
    }
    return {
      options: compose(
        map(toSelectOption),
        pathOr([], ['data', 'search_v2', 'nodes'])
      )(response),
      // eslint-disable-next-line camelcase
      hasMore: response?.data?.search_v2?.pageInfo.hasNextPage || false
    };
  };

  return (
    <AsyncPaginate
      className={className}
      debounceTimeout={DEBOUNCE_WAIT}
      defaultOptions
      defaultValue={defaultValue}
      loadOptions={loadOptions}
      placeholder={intl.formatMessage({
        id: 'table.select.placeholder',
        defaultMessage: 'Select a Table...'
      })}
      {...other}
    />
  );
}
TableSelect.displayName = 'TableSelect';

type EnhancedProps = {
  className?: string,
  defaultValue?: ValueType | string,
  name?: string
};

const EnhancedTableSelect: React.ComponentType<EnhancedProps> = compose(
  withApollo,
  // If a default value is provided to the input, fetch the table display name.
  branch(
    ({ defaultValue }) => {
      return defaultValue && typeof defaultValue === 'string';
    },
    graphql(DataTableQuery, {
      options: ({ defaultValue }) => {
        return {
          ...selectQueryPolicies,
          variables: { id: defaultValue }
        };
      },
      props: ({ data }) => {
        const { loading, error, table } = data || {};

        return { loading, error, table };
      }
    })
  ),
  injectIntl
)(TableSelect);

export default EnhancedTableSelect;
