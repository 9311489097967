// @flow

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as SlideLeftGlyph } from '@catalytic/catalytic-icons/lib/glyphs/slide-left.svg';
import { ReactComponent as SlideRightGlyph } from '@catalytic/catalytic-icons/lib/glyphs/slide-right.svg';
import FilterSortItem from './FilterSortItem';
import { type ThemeType } from '../../style/ThemeTypes';

const useStyles = makeStyles((theme: ThemeType) => {
  return {
    root: {
      [theme.breakpoints.gte.tablet.portrait]: {
        marginLeft: 'auto',
        marginRight: 0 // Override right margin so this is flush with the right boundary
      }
    },
    icon: {
      color: theme.colors.pebbleGrey,
      fontSize: '1.875rem',
      marginRight: '-0.375rem',
      padding: '0.375rem'
    }
  };
});

type Props = {
  isOpen: boolean,
  setIsOpen: boolean => void
};

const FilterSortSidebarOpener = (props: Props) => {
  const { isOpen, setIsOpen } = props;
  const classes = useStyles();

  return (
    <FilterSortItem className={classes.root}>
      <button onClick={() => setIsOpen(!isOpen)}>
        {isOpen && (
          <SlideRightGlyph
            className={classes.icon}
            data-testid="sidebar-opener-slide-right"
          />
        )}
        {!isOpen && (
          <SlideLeftGlyph
            className={classes.icon}
            data-testid="sidebar-opener-slide-left"
          />
        )}
      </button>
    </FilterSortItem>
  );
};
FilterSortSidebarOpener.displayName = 'FilterSortSidebarOpener';

export default FilterSortSidebarOpener;
