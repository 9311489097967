// @flow

import * as React from 'react';
import {
  SmallText,
  injectSheet,
  type ThemeType
} from '@catalytic/catalytic-ui';

export const Style = (theme: ThemeType) => ({
  placeholder: {
    marginTop: theme.functions.toRem(theme.variables.inputMarginTop),
    minHeight: '4.5rem'
  }
});

type Props = {|
  children: React.Node,
  classes: {| placeholder: string |},
  'data-error'?: string,
  'data-testid'?: string
|};

export const TableFieldPlaceholder = ({
  children,
  classes,
  'data-error': dataError,
  'data-testid': dataTestId
}: Props) => {
  return (
    <SmallText
      className={classes.placeholder}
      // `data-error` attribute will prevent form submission while table field is loading or when table field has an error
      // https://github.com/catalyticlabs/catalytic-web/blob/master/packages/catalytic-ui/src/View/WebformView/WebformView.js#L111
      data-error={dataError}
      data-testid={dataTestId}
    >
      {children}
    </SmallText>
  );
};
TableFieldPlaceholder.displayName = 'TableFieldPlaceholder';

export default injectSheet(Style)(TableFieldPlaceholder);
