/* eslint-disable prettier/prettier */

import withFeature from './Feature';
import withVariation from './Variation';
import withModule from './ModuleFeature';

export const FEATURE = {
  STEP_METRICS: 'step-metrics'
};

// LaunchDarkly feature flags
export const withAppcues = withVariation('appcues', false);
export const withFieldExpressionValidation = withVariation('field-expression-validation', false);
export const withInsightsDashboard = withVariation('insights-dashboard', false);
export const withIsEmptyConditions = withVariation(
  'is-empty-conditions',
  false
);
export const withLinksInTables = withVariation('links-in-tables', false);
// Do not provide a default value for `module-management` because we
// need to know definitively whether or not this is enabled or disabled
export const withModuleManagement = withVariation('module-management');
export const withRunPermissions = withVariation('run-permissions', false);
export const withUserTokensUI = withVariation('user-tokens-ui', false);
export const withWebformTableClientSideFeatures = withVariation(
  'webform-table-client-side-features',
  false
);

// pushbot-api feature flags
export const withStepMetrics = withFeature(FEATURE.STEP_METRICS, false);
export const withTriggers = withFeature('triggers', false);

// module feature flags
// Do not provide a default value for `integrations` because we
// need to know definitively whether or not this is enabled or disabled.
export const withIntegrations = withModule('integrations');
export const withPredictiveModels = withModule('predictive-models', false);
export const withPredictThisField = withModule('predict-this-field', false);
// Do not provide a default value for `single-sign-on` because we
// need to know definitively whether or not this is enabled or disabled.
export const withSingleSignOn = withModule('single-sign-on');
