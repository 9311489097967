// @flow

import { useMemo } from 'react';
import createJSONSchemaValidator, {
  type JSONSchema
} from '@catalytic/json-schema-validator-catalytic-web';

// Ajv types copied from https://github.com/flow-typed/flow-typed/blob/master/definitions/npm/ajv_v6.x.x/flow_v0.75.x-/ajv_v6.x.x.js
type $npm$ajv$ComparisonParams = {|
  comparison: string,
  limit: number | string,
  exclusive: boolean
|};

type $npm$ajv$DependenciesParams = {|
  property: string,
  missingProperty: string,
  depsCount: number,
  deps: string
|};

type AjvErrorObject = {
  dataPath: string,
  keyword: string,
  schemaPath: string,
  params:
    | {| ref: string |} // RefParams
    | {| limit: number |} // LimitParams
    | {| additionalProperty: string |} // AdditionalPropertiesParams
    | $npm$ajv$DependenciesParams // DependenciesParams
    | {| format: string |} // FormatParams
    | $npm$ajv$ComparisonParams // ComparisonParams
    | {| multipleOf: number |} // MultipleOfParams
    | {| pattern: string |} // PatternParams
    | {| missingProperty: string |} // RequiredParams
    | {| type: string |} // TypeParams
    | {| i: number, j: number |} // UniqueItemsParams
    | {| keyword: string |} // CustomParams
    | {| missingPattern: string |} // PatternRequiredParams
    | {| propertyName: string |} // PropertyNamesParams
    | {| failingKeyword: string |} // IfParams
    | {| caseIndex: number |} // SwitchParams
    | {} // NoParams
    | {| allowedValues: Array<mixed> |}, // EnumParams
  propertyName?: string,
  message?: string,
  schema?: mixed,
  parentSchema?: JSONSchema<>,
  data?: mixed
};

export type ValidationErrors = Array<AjvErrorObject> | null | void;

export type Validation = {
  errors: ValidationErrors,
  isValidSchema: boolean,
  schemaError: string | void
};

export type ValidationType = {
  schema: JSONSchema<>,
  value?: any
};

export const createValidation = ({
  schema,
  value
}: ValidationType): Validation => {
  const ajv = createJSONSchemaValidator();
  let schemaError;
  let validator;

  try {
    validator = ajv.compile(schema);
  } catch (e) {
    schemaError = e.message;
    if (process.env.NODE_ENV !== 'test') {
      console.error(e);
    }
  }

  // `validator` may throw an Error when evaluating invalid Expression syntax
  // See packages/json-schema-validator/lib/createJSONSchemaValidator.ts
  try {
    // Coercion of nullable empty string
    // https://github.com/epoberezkin/ajv/issues/919
    if (typeof validator === 'function') {
      validator(schema?.nullable === false && value === '' ? null : value);
    }
  } catch (e) {}

  const isValidSchema = typeof validator === 'function';
  const errors = typeof validator === 'function' ? validator.errors : undefined;
  return { errors, isValidSchema, schemaError };
};

const useValidation = ({ schema, value }: ValidationType): Validation =>
  useMemo(() => createValidation({ schema, value }), [schema, value]);

export default useValidation;
