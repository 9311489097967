import gql from 'graphql-tag';
import { PredictiveModelFragment } from '../PredictiveModel/PredictiveModelTypeDefs';

export const FieldFragment = gql`
  fragment FieldFragment on Field {
    condition
    description
    displayName
    displayOnly
    enum
    example
    format
    id
    items {
      enum
      type
    }
    name
    reference {
      id
      name
    }
    required
    schema
    type
    value
    viewConfiguration {
      default
      alternates
      views
    }
    viewerRead
  }
`;

export const FieldInputFragment = gql`
  fragment FieldInputFragment on Field {
    condition
    description
    displayName
    displayOnly
    enum
    example
    format
    id
    items {
      enum
      type
    }
    name
    reference {
      id
      name
    }
    required
    schema
    type
    value
    visibility {
      type
      users {
        displayName
        id
        thumbnailUrl
      }
    }
  }
`;

// V2 of FieldInputFragment above
// Renamed to fit its purpose which is to describe
// the data needed by the field configuration form
export const FieldConfigurationFragment = gql`
  ${PredictiveModelFragment}

  fragment FieldConfigurationFragment on Field {
    id
    condition
    name
    predictiveModel {
      ...PredictiveModelFragment
      id
    }
    schema
    viewConfiguration {
      views
    }
    visibility {
      type
      users {
        displayName
        id
        thumbnailUrl
      }
    }
  }
`;

// Supports FieldConfigurationHelpers.fieldToFieldTypeFormat and related
// builder logic that classifies fields based on these properties
export const FieldToFieldTypeFormatFragment = gql`
  fragment FieldToFieldTypeFormatFragment on Field {
    displayOnly
    enum
    format
    id
    schema
    type
  }
`;
