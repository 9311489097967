// @flow

import * as React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { type Team } from '@catalytic/graphql-api';

export const TeamQuery = gql`
  query Team {
    team {
      displayName
      id
      isTrialTeam
      samlConfig {
        enabled
        id
      }
    }
  }
`;

const withTeam = graphql(TeamQuery, {
  props: ({ data: { team } }) => team
});

const TeamContext: React.Context<Team | void> = React.createContext();

const useTeamContext = () => React.useContext(TeamContext);

export const TeamProvider = withTeam(({ children, ...value }) => (
  <TeamContext.Provider value={value}>{children}</TeamContext.Provider>
));

export const TeamConsumer = TeamContext.Consumer;

export default useTeamContext;
