// @flow

import * as React from 'react';
import { compose, withProps } from 'recompose';
import { withRouter, type ContextRouter } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import papaparse from 'papaparse';
import classNames from 'classnames';
import {
  BuilderListItem,
  BuilderListView,
  CodeInput,
  Creatable as CreatableSelect,
  FieldReferenceInput,
  Input,
  Markdown,
  MultiSelectInput,
  TextInput,
  type FieldSuggestion,
  type ThemeType
} from '@catalytic/catalytic-ui';
import { NodeType as ViewNodeType } from '@catalytic/view';
import StepCondition from './StepCondition';
import {
  getAppInputKey,
  getColumnInput,
  APP_INPUT_KEY_PREFIX,
  INPUT_CHAIN_SELECT_SELECTION_TYPE,
  isMultipleFileType,
  type FileColumnsInputs,
  type TableIDs
} from './StepHelpers';
import Field from '../FieldV2/Field';
import {
  createTypeFormatIdentifierForField,
  displayNameToInternalFieldName,
  getDefaultViewForField,
  isArrayColumnType,
  isArrayType,
  isBooleanType,
  isColumnType,
  isConditionType,
  isFileTableType,
  isFileType,
  isProcessType,
  isStringChoiceType,
  isStringCodeType,
  isStringTimezoneType,
  isStringType,
  isTableType,
  getTypeLabelForField
} from '../Field/FieldConfigurationHelpers';
import { type Field as FieldType } from '../Field/FieldTypes';
import FileArrayField from '../Field/FileArrayField';
import TableSelect from '../DataTableV2/DataTableSelect';
import ColumnSelect from '../DataTableV2/ColumnSelect';
import ProcessSelect from '../Process/ProcessSelect';
import PredictiveModelSelect from '../PredictiveModel/PredictiveModelSelect';
import { type Node } from '../shared/NodeTypes';
import { isHandlebar } from '../utils/handlebar';
import isUUID from '../utils/uuid';

export const SELECTION_TYPE_PREFIX = 'selection-type-';

const PREDICTIVE_MODEL_ID_FIELD_NAME = 'predictiveModelID';

const getIsRecognizedType = (
  typeFormatIdentifier: string,
  input: FieldType,
  appID?: string
): boolean =>
  isProcessType(typeFormatIdentifier) ||
  isTableType(typeFormatIdentifier) ||
  isBooleanType(typeFormatIdentifier) ||
  isStringChoiceType(typeFormatIdentifier) ||
  isStringTimezoneType(typeFormatIdentifier) ||
  isArrayType(typeFormatIdentifier) ||
  isFileType(typeFormatIdentifier) ||
  isConditionType(typeFormatIdentifier) ||
  isArrayColumnType(input) ||
  isColumnType(input) ||
  isMultipleFileType({ appID, input });

export const nameToHandlebarReference = (name: string): string => {
  if (!name || isHandlebar(name)) {
    return name;
  }
  return `{{${displayNameToInternalFieldName(name)}}}`;
};

export const pickSelectionType = ({
  appID,
  input,
  isInline,
  selectionTypes
}: {
  appID?: string,
  input: FieldType,
  isInline: boolean,
  selectionTypes: Array<{ label: any, value: string }>
}): string => {
  const value = input?.value;
  if (isHandlebar(value || '')) {
    return INPUT_CHAIN_SELECT_SELECTION_TYPE.FIELD;
  }
  if (input?.enum) {
    return value && !(input.enum || []).includes(value)
      ? INPUT_CHAIN_SELECT_SELECTION_TYPE.TEXT
      : INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM;
  }

  const hasInlineOption = selectionTypes.find(
    t => t.value === INPUT_CHAIN_SELECT_SELECTION_TYPE.INLINE
  );
  if (hasInlineOption && (isInline || value === null)) {
    return INPUT_CHAIN_SELECT_SELECTION_TYPE.INLINE;
  }

  if (isMultipleFileType({ appID, input })) {
    const valueAsArray =
      typeof value === 'string'
        ? value.split(',')
        : Array.isArray(value)
        ? value
        : [];

    // If the value does not consist entirely of empty strings, but contains
    // non-empty non-ID elements, treat this as a TEXT item
    if (
      !valueAsArray.every(v => v === '') &&
      valueAsArray.some(v => v === '' || !isUUID(v))
    ) {
      return INPUT_CHAIN_SELECT_SELECTION_TYPE.TEXT;
    }
  }

  return INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM;
};

export const pickTypeAppropriateFieldOptions = ({
  fields,
  fieldOptions,
  typeFormatIdentifier
}: {
  fields: Array<FieldType>,
  fieldOptions: Array<FieldSuggestion>,
  typeFormatIdentifier: string
}): Array<{ label: string, value: string }> => {
  const typedFields = (fields || []).filter(f => {
    const thisTypeFormatIdentifier = createTypeFormatIdentifierForField(f);
    return (
      typeFormatIdentifier === thisTypeFormatIdentifier ||
      isStringType(thisTypeFormatIdentifier) ||
      isStringChoiceType(thisTypeFormatIdentifier) ||
      isStringTimezoneType(thisTypeFormatIdentifier) ||
      (isTableType(typeFormatIdentifier) &&
        isFileTableType(thisTypeFormatIdentifier))
    );
  });
  return fieldOptions
    .filter(fieldOption =>
      typedFields.find(typedField => typedField.name === fieldOption.id)
    )
    .map(fieldOption => ({
      label: fieldOption.display,
      value: `{{${fieldOption.id}}}`
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const adjustValueByType = ({
  appID,
  input,
  inputs,
  value,
  name,
  selectionType,
  isInline,
  isSelectType,
  typeFormatIdentifier
}: {
  appID?: string,
  input: FieldType,
  inputs: Array<FieldType>,
  value: ?any,
  name: string,
  selectionType: string,
  isInline: boolean,
  isSelectType: boolean,
  typeFormatIdentifier: string
}): any => {
  // If not one of the special selection types, allow the value as-is
  if (
    !isSelectType &&
    selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM &&
    !isFileType(typeFormatIdentifier)
  ) {
    return value;
  }

  const { isColumnInput, isMulti } = getColumnInput({
    appID,
    input,
    inputs,
    name
  });

  // Multi-select input is a column type and the value is a string
  if (
    isColumnInput &&
    selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM &&
    isMulti &&
    value &&
    typeof value === 'string'
  ) {
    // Having whitespace before or after a quote-escaped value will either
    // break the parser or yield incorrect results. This is to remove those
    // spaces, allowing us to parse the comma delimited values according to
    // RFC 4180.
    // Also, clear new lines. Based on `parseColumnNames` table helper:
    // https://github.com/catalyticlabs/pushbot-api/blob/master/lib/api/apps/built-in/tables/utils/tableHelpers.js#L193-L201
    //
    // Note: The implementation here is different from the API because the
    // regex lookbehind syntax is not supported in all browsers. This regex
    // matches a quoted text term with whitespace before or after and trims it.
    // E.g.  `A,B, "Red, Blue" ,C`  =>  `A,B,"Red, Blue",C`
    const regex = RegExp(', *("[^"]*") *', 'g');
    const text = value.replace(regex, ',$1').replace(/\n/g, '');
    const result = papaparse.parse(text) || {};
    const data = result.data || [];
    return (data[0] || []).map(c => c.trim());
  }

  if (
    // Input is a column type and the value is a string
    (isColumnInput &&
      selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM &&
      value &&
      typeof value === 'string') ||
    // Input is column type and the value is an array
    (isColumnInput &&
      selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM &&
      Array.isArray(value) &&
      value.length > 0) ||
    // Input is multiple file type
    (isMultipleFileType({ appID, input }) &&
      selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM) ||
    // Input is a text type
    selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.TEXT ||
    // Input is a field type and the value is a handlebar reference
    (isHandlebar(value || '') &&
      selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.FIELD) ||
    // Value is a static UUID
    (isUUID(value || '') &&
      !isInline &&
      selectionType !== INPUT_CHAIN_SELECT_SELECTION_TYPE.FIELD) ||
    // Input is condition type
    (isConditionType(typeFormatIdentifier) &&
      selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM)
  ) {
    return value;
  }

  return '';
};

export const useStyles = makeStyles((theme: ThemeType) => ({
  view: {
    ...theme.mixins.inputMargin,
    marginTop: theme.functions.toRem(theme.variables.inputMarginTop)
  },
  builderListItem: {
    borderTop: 'none',
    margin: 0,
    backgroundColor: theme.colors.blueGrey,
    padding: 0,
    '&:hover': {
      backgroundColor: theme.colors.blueGrey,
      boxShadow: 'none',
      borderRadius: theme.variables.borderRadius
    },
    '& > div': {
      padding: '0 0.5rem',
      '& > div:first-child': {
        padding: '0.25rem 0',
        '& > span:first-child': {
          ...theme.mixins.inputMargin,
          flexWrap: 'wrap',
          flex: 1
        },
        '& > div:last-child': {
          display: 'none'
        }
      }
    }
  },
  builderListItemField: {
    margin: '0.25rem',
    backgroundColor: 'inherit',
    padding: '0 0.25rem',
    flexGrow: 0,
    flexShrink: 1,
    '& > div:first-child': {
      marginBottom: 0
    }
  },
  selectionTypeField: {
    flexBasis: '11rem',
    flexGrow: 1,
    [theme.breakpoints.gt.mobile.portrait]: {
      flexGrow: 0
    }
  },
  selectionTypeFieldSelect: {
    flexBasis: '13.625rem' // Based on the widest option, "Enter Column manually"
  },
  selectionField: {
    flexBasis: '18rem',
    flexGrow: 100
  },
  selectionFieldSelect: {
    '& > span': {
      display: 'inline-block',
      margin: '0.375rem 1.063rem'
    }
  },
  selectionFieldCondition: {
    flexBasis: '100%',
    marginTop: 0,
    paddingTop: 0
  }
}));

export const Messages = defineMessages({
  objectSelectionTypeInline: {
    id: 'stepconfigurationinput.object.selectiontype.inline',
    defaultMessage: 'Build Inline'
  },
  objectSelectionTypeName: {
    id: 'stepconfigurationinput.object.selectiontype.name',
    defaultMessage: 'Use {type} by name'
  },
  objectSelectionTypeID: {
    id: 'stepconfigurationinput.object.selectiontype.id',
    defaultMessage: 'Use {type} by ID'
  },
  objectSelectionTypeField: {
    id: 'stepconfigurationinput.object.selectiontype.field',
    defaultMessage: 'Use {type} via field'
  },
  fieldTypeSelectionTypeField: {
    id: 'stepconfigurationinput.fieldtype.selectiontype.field',
    defaultMessage: 'Choose via field'
  },
  chooseOneSelectionTypeName: {
    id: 'stepconfigurationinput.choice.selectiontype.name',
    defaultMessage: 'Choose one'
  },
  chooseOneSelectionTypeText: {
    id: 'stepconfigurationinput.choice.selectiontype.text',
    defaultMessage: 'Other'
  },
  predictiveModelSelectionTypeName: {
    id: 'stepconfigurationinput.predictiveModel.selectiontype.name',
    defaultMessage: 'Use Model by name'
  },
  predictiveModelSelectionTypeID: {
    id: 'stepconfigurationinput.predictiveModel.selectiontype.id',
    defaultMessage: 'Use Model by ID'
  },
  predictiveModelSelectionTypeField: {
    id: 'stepconfigurationinput.predictiveModel.selectiontype.field',
    defaultMessage: 'Use Model via field'
  },
  columnSelectionTypeName: {
    id: 'stepconfigurationinput.column.selectiontype.name',
    defaultMessage: 'Use Column by name'
  },
  columnSelectionTypeField: {
    id: 'stepconfigurationinput.column.selectiontype.field',
    defaultMessage: 'Use Column via field'
  },
  columnSelectionTypeText: {
    id: 'stepconfigurationinput.column.selectiontype.text',
    defaultMessage: 'Enter Column manually'
  },
  selectFieldPlaceholder: {
    id: 'stepconfigurationinput.field.placeholder',
    defaultMessage: 'Select a Field...'
  },
  fileArraySelectionTypeName: {
    id: 'stepconfigurationinput.fieldType.fileArray.name',
    defaultMessage: 'Multiple File'
  },
  fileArraySelectionTypeText: {
    id: 'stepconfigurationinput.fieldType.fileArray.text',
    defaultMessage: 'Other'
  },
  conditionSelectionTypeName: {
    id: 'stepconfigurationinput.fieldType.condition.name',
    defaultMessage: 'Condition'
  }
});

type BaseProps = ContextRouter & {
  appID?: string,
  context: Node,
  disabled: boolean,
  fieldOptions: Array<FieldSuggestion>,
  fields: Array<FieldType>,
  fileColumnsInputs: FileColumnsInputs,
  id: string,
  input: FieldType,
  intl: Object,
  isInline: boolean,
  isRecognizedType: boolean,
  onChange: (...args: Array<any>) => any,
  selectionTypes: any,
  tableIDs: TableIDs,
  typeFormatIdentifier: string
};

export function StepConfigurationInput({
  appID,
  context,
  disabled,
  fieldOptions,
  fields,
  fileColumnsInputs,
  id,
  input,
  intl,
  isInline,
  isRecognizedType,
  onChange,
  selectionTypes,
  tableIDs,
  typeFormatIdentifier
}: BaseProps) {
  const {
    __typename,
    description,
    displayName,
    example,
    format,
    id: idProp,
    name,
    reference,
    required,
    schema,
    type,
    value: valueProp,
    viewConfiguration,
    ...props
  } = input || {};
  const { format: schemaFormat } = schema || {};
  const classes = useStyles();

  const [selectionType, setSelectionType] = React.useState<string>(
    pickSelectionType({ appID, input, isInline, selectionTypes })
  );

  const { isColumnInput, isMulti } = getColumnInput({
    appID,
    input,
    inputs: fields,
    name
  });

  const isSelectType =
    isProcessType(typeFormatIdentifier) ||
    isColumnInput ||
    isTableType(typeFormatIdentifier) ||
    isConditionType(typeFormatIdentifier) ||
    name === PREDICTIVE_MODEL_ID_FIELD_NAME ||
    isMultipleFileType({ appID, input });

  const value = adjustValueByType({
    appID,
    input,
    inputs: fields,
    value: valueProp,
    name,
    selectionType,
    isInline,
    isSelectType,
    typeFormatIdentifier
  });

  const typeAppropriateFieldOptions = pickTypeAppropriateFieldOptions({
    fields,
    fieldOptions,
    typeFormatIdentifier
  });

  if (selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.FIELD) {
    const referencedField = reference
      ? fields.find(f => f.name === reference.name)
      : null;
    if (
      referencedField &&
      !typeAppropriateFieldOptions.find(
        option => option.value === `{{${referencedField.name}}}`
      )
    ) {
      typeAppropriateFieldOptions.push({
        label: referencedField.displayName,
        value: `{{${referencedField.name}}}`
      });
    }
    if (!reference && value) {
      typeAppropriateFieldOptions.push({
        label: value,
        value
      });
    }
  }
  const selectedFieldValue = typeAppropriateFieldOptions.find(
    f => f.value === value
  );
  const isRichTextType =
    getDefaultViewForField(input) === ViewNodeType.RICH_TEXT_VIEW;
  const isCodeType = isStringCodeType(typeFormatIdentifier);
  return (
    <Input
      data-testid={id}
      label={displayName}
      placeholder={example}
      description={
        description && (
          <div className={classes.description}>
            <Markdown value={description} />
          </div>
        )
      }
      showDescriptionInTooltip
    >
      {!isRecognizedType && !isRichTextType && !isCodeType && (
        <FieldReferenceInput
          data={fieldOptions}
          disabled={disabled}
          id={id}
          key={id}
          name={id}
          onChange={onChange}
          required={required}
          value={valueProp != null ? valueProp.toString() : valueProp}
          {...props}
        />
      )}
      {isRichTextType && (
        <Field
          context={context}
          embedded
          {...{
            ...(input: any),
            disabled,
            displayName: '',
            description: '',
            example: '',
            id,
            key: id,
            name: id
          }}
        />
      )}
      {isCodeType && (
        <CodeInput
          id={id}
          name={id}
          defaultValue={valueProp}
          data={fieldOptions}
          onChange={onChange}
          format={schemaFormat}
        />
      )}
      {isRecognizedType && (
        <BuilderListView className={classes.view} data-testid={id}>
          <BuilderListItem
            className={classes.builderListItem}
            fields={
              <>
                <Input
                  className={classNames(
                    classes.builderListItemField,
                    classes.selectionTypeField,
                    {
                      [classes.selectionTypeFieldSelect]: isSelectType
                    }
                  )}
                >
                  <MultiSelectInput
                    data-testid="selection-type"
                    disabled={disabled}
                    embedded
                    id={`${SELECTION_TYPE_PREFIX}${id}`}
                    isClearable={false}
                    name={`${SELECTION_TYPE_PREFIX}${id}`}
                    onChange={e => {
                      setSelectionType(e.currentTarget.value[0]);
                    }}
                    options={selectionTypes}
                    value={selectionTypes.find(t => t.value === selectionType)}
                  />
                </Input>
                {selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.FIELD && (
                  <Input
                    className={classNames(
                      classes.builderListItemField,
                      classes.selectionField
                    )}
                  >
                    <CreatableSelect
                      data-testid="selection-field-input"
                      defaultValue={
                        selectedFieldValue ? [selectedFieldValue] : undefined
                      }
                      disabled={disabled}
                      embedded
                      getNewOptionData={inputValue => {
                        const value = nameToHandlebarReference(inputValue);
                        return {
                          label: inputValue,
                          value
                        };
                      }}
                      id={id}
                      isClearable={!required}
                      isValidNewOption={value => !!value}
                      name={id}
                      onChange={onChange}
                      options={typeAppropriateFieldOptions}
                      placeholder={intl.formatMessage(
                        Messages.selectFieldPlaceholder
                      )}
                      required={required}
                    />
                  </Input>
                )}
                {selectionType ===
                  INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM && (
                  <>
                    {isSelectType ? (
                      <>
                        {isTableType(typeFormatIdentifier) && (
                          <Input
                            className={classNames(
                              classes.builderListItemField,
                              classes.selectionField
                            )}
                          >
                            <TableSelect
                              className={classes.selectionFieldSelect}
                              data-testid="value-input"
                              defaultValue={value}
                              disabled={disabled}
                              embedded
                              id={id}
                              name={id}
                              onChange={onChange}
                              required={required}
                            />
                          </Input>
                        )}
                        {isProcessType(typeFormatIdentifier) && (
                          <Input
                            className={classNames(
                              classes.builderListItemField,
                              classes.selectionField
                            )}
                          >
                            <ProcessSelect
                              className={classes.selectionFieldSelect}
                              data-testid="value-input"
                              defaultValue={value}
                              disabled={disabled}
                              embedded
                              id={id}
                              isMulti={false}
                              name={id}
                              onChange={onChange}
                              required={required}
                            />
                          </Input>
                        )}
                        {isConditionType(typeFormatIdentifier) && (
                          <Input
                            className={classNames(
                              classes.builderListItemField,
                              classes.selectionField,
                              classes.selectionFieldCondition
                            )}
                          >
                            <StepCondition
                              disabled={disabled}
                              expression={value}
                              name={id}
                              onChange={value =>
                                onChange({
                                  currentTarget: {
                                    name: id,
                                    value
                                  }
                                })
                              }
                            />
                          </Input>
                        )}
                        {name === PREDICTIVE_MODEL_ID_FIELD_NAME && (
                          <Input
                            className={classNames(
                              classes.builderListItemField,
                              classes.selectionField
                            )}
                          >
                            <PredictiveModelSelect
                              className={classes.selectionFieldSelect}
                              data-testid="value-input"
                              defaultValue={value}
                              disabled={disabled}
                              embedded
                              id={id}
                              name={id}
                              onChange={onChange}
                              required={required}
                            />
                          </Input>
                        )}
                        {isColumnInput && (
                          <Input
                            className={classNames(
                              classes.builderListItemField,
                              classes.selectionField
                            )}
                          >
                            <ColumnSelect
                              className={classes.selectionFieldSelect}
                              data-testid="value-input"
                              defaultValue={value}
                              disabled={disabled}
                              embedded
                              fileColumnsInputs={fileColumnsInputs}
                              id={id}
                              isMulti={isMulti}
                              name={id}
                              onChange={onChange}
                              required={required}
                              tableIDs={tableIDs}
                            />
                          </Input>
                        )}
                        {isMultipleFileType({ appID, input }) && (
                          <>
                            <Input
                              className={classNames(
                                classes.builderListItemField,
                                classes.selectionField
                              )}
                            >
                              <FileArrayField
                                context={context}
                                data-testid="value-input"
                                defaultValue={value}
                                disabled={disabled}
                                embedded
                                id={id}
                                name={id}
                                onChange={onChange}
                                required={required}
                                type={type}
                              />
                            </Input>
                          </>
                        )}
                      </>
                    ) : (
                      <Field
                        className={classNames(
                          classes.builderListItemField,
                          classes.selectionField
                        )}
                        context={context}
                        embedded
                        onChange={onChange}
                        {...{
                          ...(input: any),
                          disabled,
                          displayName: '',
                          description: '',
                          example: '',
                          id,
                          key: id,
                          name: id,
                          value
                        }}
                      />
                    )}
                  </>
                )}
                {(selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.ID ||
                  selectionType === INPUT_CHAIN_SELECT_SELECTION_TYPE.TEXT) && (
                  <Input
                    className={classNames(
                      classes.builderListItemField,
                      classes.selectionField
                    )}
                  >
                    <TextInput
                      data-testid="selection-input"
                      disabled={disabled}
                      embedded
                      id={id}
                      name={id}
                      onChange={onChange}
                      required={required}
                      defaultValue={value}
                    />
                  </Input>
                )}
              </>
            }
          />
        </BuilderListView>
      )}
    </Input>
  );
}
StepConfigurationInput.displayName = 'StepConfigurationInput';

type EnhancedProps = {|
  'data-testid'?: string,
  appID?: string,
  context: Node,
  disabled: boolean,
  fieldOptions: Array<FieldSuggestion>,
  fields: Array<FieldType>,
  fileColumnsInputs: FileColumnsInputs,
  input: FieldType,
  isInline: boolean,
  onChange: (...args: Array<any>) => any,
  supportsInlineWorkflow: boolean,
  tableIDs: TableIDs
|};

const EnhancedStepConfigurationInput: React.ComponentType<EnhancedProps> = compose(
  withRouter,
  injectIntl,
  withProps(({ appID, fields, input, intl, supportsInlineWorkflow }) => {
    const { name } = input || {};
    const typeFormatIdentifier = createTypeFormatIdentifierForField(input);
    const label = getTypeLabelForField(input);
    let selectionTypes = [];
    const { isColumnInput } = getColumnInput({
      appID,
      input,
      inputs: fields,
      name
    });
    const isRecognizedType =
      getIsRecognizedType(typeFormatIdentifier, input, appID) ||
      name === PREDICTIVE_MODEL_ID_FIELD_NAME ||
      isColumnInput;
    const objectSelectionTypeStatic = {
      label: intl.formatMessage(Messages.objectSelectionTypeName, {
        type: label
      }),
      value: INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM
    };
    const objectSelectionTypeID = {
      label: intl.formatMessage(Messages.objectSelectionTypeID, {
        type: label
      }),
      value: INPUT_CHAIN_SELECT_SELECTION_TYPE.ID
    };
    const objectSelectionTypeField = {
      label: intl.formatMessage(Messages.objectSelectionTypeField, {
        type: label
      }),
      value: INPUT_CHAIN_SELECT_SELECTION_TYPE.FIELD
    };
    const fieldTypeSelectionTypeField = {
      label: intl.formatMessage(Messages.fieldTypeSelectionTypeField),
      value: INPUT_CHAIN_SELECT_SELECTION_TYPE.FIELD
    };
    if (isProcessType(typeFormatIdentifier)) {
      selectionTypes = [
        objectSelectionTypeStatic,
        objectSelectionTypeID,
        objectSelectionTypeField
      ];
      if (supportsInlineWorkflow) {
        selectionTypes.unshift({
          label: intl.formatMessage(Messages.objectSelectionTypeInline),
          value: INPUT_CHAIN_SELECT_SELECTION_TYPE.INLINE
        });
      }
    } else if (isTableType(typeFormatIdentifier)) {
      selectionTypes = [
        objectSelectionTypeStatic,
        objectSelectionTypeID,
        objectSelectionTypeField
      ];
    } else if (name === PREDICTIVE_MODEL_ID_FIELD_NAME) {
      const predictiveModelSelectionTypeStatic = {
        label: intl.formatMessage(Messages.predictiveModelSelectionTypeName),
        value: INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM
      };
      const predictiveModelSelectionTypeID = {
        label: intl.formatMessage(Messages.predictiveModelSelectionTypeID),
        value: INPUT_CHAIN_SELECT_SELECTION_TYPE.TEXT
      };
      const predictiveModelSelectionTypeField = {
        label: intl.formatMessage(Messages.predictiveModelSelectionTypeField),
        value: INPUT_CHAIN_SELECT_SELECTION_TYPE.FIELD
      };
      selectionTypes = [
        predictiveModelSelectionTypeStatic,
        predictiveModelSelectionTypeID,
        predictiveModelSelectionTypeField
      ];
    } else if (isColumnInput) {
      const columnSelectionTypeStatic = {
        label: intl.formatMessage(Messages.columnSelectionTypeName),
        value: INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM
      };
      const columnSelectionTypeText = {
        label: intl.formatMessage(Messages.columnSelectionTypeText),
        value: INPUT_CHAIN_SELECT_SELECTION_TYPE.TEXT
      };
      const columnSelectionTypeField = {
        label: intl.formatMessage(Messages.columnSelectionTypeField),
        value: INPUT_CHAIN_SELECT_SELECTION_TYPE.FIELD
      };
      selectionTypes = [
        columnSelectionTypeStatic,
        columnSelectionTypeField,
        columnSelectionTypeText
      ];
    } else if (
      isStringChoiceType(typeFormatIdentifier) ||
      isStringTimezoneType(typeFormatIdentifier)
    ) {
      const chooseOneSelectionTypeName = {
        label: intl.formatMessage(Messages.chooseOneSelectionTypeName),
        value: INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM
      };
      const chooseOneSelectionTypeText = {
        label: intl.formatMessage(Messages.chooseOneSelectionTypeText),
        value: INPUT_CHAIN_SELECT_SELECTION_TYPE.TEXT
      };
      selectionTypes = [
        chooseOneSelectionTypeName,
        fieldTypeSelectionTypeField,
        chooseOneSelectionTypeText
      ];
    } else if (isMultipleFileType({ appID, input })) {
      const fileArraySelectionTypeName = {
        label: intl.formatMessage(Messages.fileArraySelectionTypeName),
        value: INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM
      };
      const fileArraySelectionTypeText = {
        label: intl.formatMessage(Messages.fileArraySelectionTypeText),
        value: INPUT_CHAIN_SELECT_SELECTION_TYPE.TEXT
      };
      selectionTypes = [
        fileArraySelectionTypeName,
        fieldTypeSelectionTypeField,
        fileArraySelectionTypeText
      ];
    } else if (isConditionType(typeFormatIdentifier)) {
      const chooseOneSelectionTypeName = {
        label: intl.formatMessage(Messages.conditionSelectionTypeName),
        value: INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM
      };
      selectionTypes = [
        chooseOneSelectionTypeName,
        fieldTypeSelectionTypeField
      ];
    } else if (isRecognizedType) {
      const selectionTypeName = {
        label,
        value: INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM
      };
      selectionTypes = [selectionTypeName, fieldTypeSelectionTypeField];
    } else {
      selectionTypes = [
        {
          label,
          value: INPUT_CHAIN_SELECT_SELECTION_TYPE.STATIC_ITEM
        }
      ];
    }
    return {
      id: getAppInputKey({ name: input?.name, prefix: APP_INPUT_KEY_PREFIX }),
      isRecognizedType,
      selectionTypes,
      typeFormatIdentifier
    };
  })
)(StepConfigurationInput);

export default EnhancedStepConfigurationInput;
