// @flow

import * as React from 'react';
import AtlaskitEmptyState from '@atlaskit/empty-state';
import { Link } from 'react-router-dom';
import { BLACK, SCIENCE_BLUE } from '../../style/colors';
import { FormattedMessage } from 'react-intl';
import { BaseText, Header3 } from '../../Text/Text';
import { makeStyles } from '@material-ui/styles';
import ButtonInput from '../../Input/ButtonInput/ButtonInput';
import { type ThemeType } from '../../style/ThemeTypes';
import logoSquare from '@catalytic/catalytic-icons/lib/icons/logo-square.svg';

type ButtonInputType = React.Element<typeof ButtonInput>;

const useStyles = makeStyles((theme: ThemeType) => ({
  headerText: {
    color: theme.colors.black
  },
  descriptionText: {
    color: theme.colors.pebbleGrey
  }
}));

function EmptyState(props: {|
  description?: string | React.Element<typeof FormattedMessage>,
  header?: string | React.Element<typeof FormattedMessage>,
  imageUrl?: string | null,
  primaryAction?: ButtonInputType,
  secondaryAction?: ButtonInputType,
  tertiaryAction?: React.Element<typeof Link>
|}) {
  const {
    description,
    header,
    imageUrl = logoSquare,
    primaryAction,
    secondaryAction,
    tertiaryAction
  } = props;
  const classes = useStyles();

  const primaryButton =
    primaryAction != null
      ? React.cloneElement(primaryAction, {
          colorScheme: SCIENCE_BLUE
        })
      : null;
  const secondaryButton =
    primaryAction && secondaryAction != null
      ? React.cloneElement(secondaryAction, {
          invertScheme: true,
          colorScheme: BLACK
        })
      : null;
  return (
    <AtlaskitEmptyState
      description={
        <BaseText className={classes.descriptionText} component="span">
          {description}
        </BaseText>
      }
      header={
        <Header3 className={classes.headerText} component="span">
          {header}
        </Header3>
      }
      imageUrl={imageUrl}
      maxImageWidth={'54'}
      maxImageHeight={'48'}
      primaryAction={primaryButton}
      secondaryAction={secondaryButton}
      tertiaryAction={<BaseText component="span">{tertiaryAction}</BaseText>}
    />
  );
}

export default EmptyState;
