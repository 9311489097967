// @flow

import React, {
  PureComponent,
  type ComponentType,
  type ElementRef
} from 'react';
import { compose, setDisplayName } from 'recompose';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';
import { ReactComponent as EyeDisabledGlyph } from '@catalytic/catalytic-icons/lib/glyphs/eye-disabled.svg';
import { ReactComponent as EyeGlyph } from '@catalytic/catalytic-icons/lib/glyphs/eye.svg';
import InputRequired from '../InputRequired/InputRequired';

const DISPLAY_NAME = 'PasswordInput';

const Style = (theme: ThemeType) => {
  return {
    root: {
      position: 'relative'
    },
    input: {
      ...theme.mixins.inputStyle,
      ...theme.mixins.truncate,
      '&:required:invalid': {
        paddingRight: theme.variables.inputRequiredPaddingRight,
        '& ~ $button': {
          display: 'none'
        }
      },
      '&:focus:required:invalid, &:required:valid': {
        ...theme.mixins.inputPadding,
        '& ~ $required': {
          display: 'none'
        }
      },
      '&:valid, &:required:valid': {
        paddingRight: theme.variables.inputRequiredPaddingRight
      }
    },
    displayErrors: {
      '&:valid': theme.mixins.inputSuccess,
      '&:invalid': theme.mixins.inputError
    },
    success: theme.mixins.inputSuccess,
    error: theme.mixins.inputError,
    required: {},
    button: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      padding: `0 ${theme.functions.toRem(theme.variables.inputPaddingRight)}`,
      backgroundColor: 'transparent',
      color: theme.colors.battleshipGrey,
      fontSize: theme.typography.baseText.lineHeight,
      cursor: 'pointer',
      '&:hover': {
        color: theme.colors.black
      },
      '&:disabled': theme.mixins.disabled
    }
  };
};

export type PasswordInputType = InjectSheetProvidedProps &
  HTMLInputElement & {
    displayErrors: boolean,
    hasError: boolean,
    hasRequired: boolean,
    hasSuccess: boolean,
    hasValidation?: boolean,
    inputClassName?: string,
    inputRef?: ElementRef<*>,
    setDisplayErrorsState?: (displayErrors: boolean) => mixed,
    setLoadingState?: (loading: boolean) => mixed
  };

type State = {
  type: string
};

class PasswordInput extends PureComponent<PasswordInputType, State> {
  static displayName = DISPLAY_NAME;

  static defaultProps = {
    autoComplete: 'new-password',
    disabled: false,
    displayErrors: false,
    hasError: false,
    hasRequired: true,
    hasSuccess: false,
    readOnly: false,
    required: false,
    type: 'password'
  };

  state = {
    type: this.props.type || 'password'
  };

  handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { type } = this.state;

    return this.setState({
      type: type === 'password' ? 'text' : 'password'
    });
  };

  render() {
    const {
      classes,
      className,
      disabled,
      displayErrors,
      hasError,
      hasRequired,
      hasSuccess,
      hasValidation,
      inputClassName,
      inputRef: ref,
      name,
      readOnly,
      required,
      setDisplayErrorsState,
      setLoadingState,
      theme,
      title,
      type: typeProp,
      ...props
    } = this.props;
    const { type } = this.state;

    return (
      <div className={classNames(classes.root, className)} title={title}>
        <input
          className={classNames(
            classes.input,
            {
              [classes.displayErrors]: displayErrors,
              [classes.error]: hasError,
              [classes.success]: hasSuccess
            },
            inputClassName
          )}
          data-name={name}
          data-testid={name || 'password-input'}
          disabled={disabled || readOnly}
          {...{ name, readOnly, ref, required, type, ...props }}
        />
        <InputRequired
          className={classes.required}
          {...{ disabled, hasRequired, readOnly, required }}
        />
        <button
          className={classes.button}
          data-testid="password-eye"
          disabled={disabled || readOnly}
          onClick={this.handleClick}
          type="button"
        >
          {type === 'password' ? <EyeDisabledGlyph /> : <EyeGlyph />}
        </button>
      </div>
    );
  }
}

// TODO: Type props to component
type EnhancedProps = Object;

export default (compose(
  setDisplayName(DISPLAY_NAME),
  injectSheet(Style)
)(PasswordInput): ComponentType<EnhancedProps>);
