// @flow

import React, { type Node } from 'react';
import { ReactComponent as DataTableIcon } from '@catalytic/catalytic-icons/lib/icons/data-table.svg';
import { ReactComponent as HelpIcon } from '@catalytic/catalytic-icons/lib/icons/help.svg';
import { ReactComponent as PersonCardIcon } from '@catalytic/catalytic-icons/lib/icons/person-card.svg';
import { ReactComponent as RunIcon } from '@catalytic/catalytic-icons/lib/icons/run.svg';
import { ReactComponent as InstanceIcon } from '@catalytic/catalytic-icons/lib/icons/instance.svg';
import { ReactComponent as TaskIcon } from '@catalytic/catalytic-icons/lib/icons/task.svg';
import { ReactComponent as TaskCompleteIcon } from '@catalytic/catalytic-icons/lib/icons/task-complete.svg';
import { ReactComponent as WorkflowIcon } from '@catalytic/catalytic-icons/lib/icons/workflow.svg';

import SearchListItemAvatar from './SearchListItemAvatar';
import { TYPENAME, type Typename } from './SearchTypes';
import { ABORTED, COMPLETED, FAILED } from '../shared/Status/StatusTypes';

type Props = {
  id?: string,
  status?: string,
  type: Typename
};

const SearchIcon = ({ id, status, type, ...props }: Props): Node => {
  switch (type) {
    case TYPENAME.ACTOR:
      return <PersonCardIcon {...props} />;
    case TYPENAME.BOT:
      return <RunIcon {...props} />;
    case TYPENAME.GROUP:
      return <SearchListItemAvatar id={id} {...props} />;
    case TYPENAME.CONTEXT:
      return <InstanceIcon {...props} />;
    case TYPENAME.DATA_TABLE:
      return <DataTableIcon {...props} />;
    case TYPENAME.HELP:
      return <HelpIcon {...props} />;
    case TYPENAME.TASK:
      switch (status) {
        case ABORTED:
        case COMPLETED:
        case FAILED:
          return <TaskCompleteIcon {...props} />;
        default:
          return <TaskIcon {...props} />;
      }
    default:
      return <WorkflowIcon {...props} />;
  }
};

SearchIcon.displayName = 'SearchIcon';

export default SearchIcon;
