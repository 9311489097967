// @flow

import * as React from 'react';
import { graphql, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import {
  branch,
  compose,
  mapProps,
  renderNothing,
  withProps,
  type HOC
} from 'recompose';
import { withRouter } from 'react-router-dom';
import path from 'ramda/src/path';
import pathEq from 'ramda/src/pathEq';
import { FormattedMessage } from 'react-intl';
import { CERULEAN, showToast, TOAST_TYPE } from '@catalytic/catalytic-ui';
import { withIDParam } from '../Router/fromRoute';
import { ProcessUserCanEditQuery } from './ProcessTypeDefs';
import { type Process, type Trigger } from './ProcessTypes';
import { type Step } from '../Step/StepTypes';
import {
  appNameFromAppID,
  appNameToIcon
} from '../Integrations/integrationMappings';
import { NODE_TYPE } from '../shared/NodeTypes';
import { isCompleted } from '../shared/Status/StatusTypes';
import { ReactComponent as EmailGlyph } from '@catalytic/catalytic-icons/lib/glyphs/email.svg';
import { ReactComponent as ScheduledIcon } from '@catalytic/catalytic-icons/lib/icons/scheduled.svg';

export const isNotActive = pathEq(['isActive'], false);
export const canEdit = pathEq(['permissions', 'canEdit'], true);

const CAN_EDIT = 'canEdit';

// Must be used in a `/process/:id` path or with a contextNode in order to
// pull process id from `match` prop
export const withProcessUserCanEdit: HOC<
  *,
  {| contextNode?: Process | Step |}
> = compose(
  withRouter,
  graphql(ProcessUserCanEditQuery, {
    name: CAN_EDIT,
    options: ({ contextNode, match }) => {
      return {
        variables: {
          id: !contextNode
            ? path(['params', 'id'])(match)
            : contextNode?.__typename === NODE_TYPE.STEP
            ? path(['context', 'id'])(contextNode)
            : path(['id'])(contextNode)
        }
      };
    },
    skip: ({ contextNode, match }) =>
      !match?.params?.id && !contextNode?.context?.id && !contextNode?.id
  }),
  mapProps(({ [CAN_EDIT]: data, ...props }) =>
    data?.loading
      ? props
      : {
          ...props,
          processUserCanEdit: canEdit(data?.contextType)
        }
  )
);

export const withProcessEditDisabled: HOC<
  *,
  {| contextNode?: Process | Step |}
> = compose(
  withProcessUserCanEdit,
  mapProps(({ processUserCanEdit, ...props }) => ({
    ...props,
    disabled: processUserCanEdit === false
  }))
);

export const ANCESTOR = 'ancestor';
const ancestorLoading = path([ANCESTOR, 'loading']);
const ancestorID = path([ANCESTOR, 'contextType', 'ancestorID']);

export const ProcessAncestorQuery = gql`
  query ProcessAncestor($id: ID!) {
    contextType(id: $id) {
      ancestorID
      id
    }
  }
`;

export const withAncestorID: HOC<*, {}> = compose(
  withIDParam,
  graphql(ProcessAncestorQuery, {
    name: ANCESTOR,
    options: ({ id }) => ({
      errorPolicy: 'all',
      fetchPolicy: 'cache-first',
      variables: {
        id
      }
    })
  }),
  // Note: These render nothing branches may not be the right choice for
  // all use cases.
  branch(ancestorLoading, renderNothing),
  branch(props => typeof ancestorID(props) !== 'string', renderNothing),
  withProps(props => ({
    ancestorID: ancestorID(props)
  }))
);

export const useAncestorIDQuery = (id: string) => {
  const { data, error, loading } = useQuery(ProcessAncestorQuery, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    variables: {
      id
    }
  });
  return {
    data:
      error || loading || !data?.contextType
        ? undefined
        : { ancestorID: data.contextType.ancestorID },
    error,
    loading
  };
};

const toastProcessUserCannotEdit = (options?: Object = {}) =>
  showToast(
    TOAST_TYPE.info,
    <FormattedMessage
      id="steplist.usercannotedit.description"
      defaultMessage="You do not have permission to edit this Workflow. Contact the Workflow owner if you need to make changes."
    />,
    <FormattedMessage
      id="steplist.usercannotedit.title"
      defaultMessage="Workflow is Read-Only"
    />,
    { autoClose: false, closeButton: true, ...options }
  );

const toastPublishedProcessEditsDisabled = (options?: Object = {}) =>
  showToast(
    TOAST_TYPE.info,
    <FormattedMessage
      id="steplist.publishedProcessEditsDisabled.description"
      defaultMessage="Publish Control is enabled. To edit this Workflow, create a new version or edit an existing draft. Then, submit a publish request."
    />,
    <FormattedMessage
      id="steplist.publishedProcessEditsDisabled.title"
      defaultMessage="Create a New Version to Edit"
    />,
    { autoClose: false, closeButton: true, ...options }
  );

export const showEditDisabledToast = ({
  contextType,
  requirePublishApproval,
  viewerIsTeamAdmin
}: {
  contextType: {
    id: string,
    isActive: boolean
  },
  requirePublishApproval: boolean,
  viewerIsTeamAdmin: boolean
}) => {
  const { id, isActive } = contextType;
  if (requirePublishApproval && isActive && !viewerIsTeamAdmin) {
    toastPublishedProcessEditsDisabled({ toastId: id });
  } else {
    toastProcessUserCannotEdit({ toastId: id });
  }
};

export const ROUTED_FROM_BUILDER = 'ROUTED_FROM_BUILDER';

export const wasRoutedFromBuilder = path([
  'location',
  'state',
  ROUTED_FROM_BUILDER
]);

export const TRIGGER_TYPES = {
  EMAIL: {
    name: 'Email',
    description: 'once an email is received',
    icon: EmailGlyph
  },
  SCHEDULED: {
    name: 'Scheduled',
    description: 'on a specific date and time or interval',
    icon: ScheduledIcon,
    iconColor: CERULEAN
  }
};

export const triggerInfo = (
  trigger: Trigger
): {
  color: ?string,
  description?: string,
  icon?: React.Node,
  name?: string
} => {
  const { type, app } = trigger;
  const { id: appID = '' } = app || {};
  let name = appNameFromAppID(appID);
  let description = '';
  let typeOverride;
  let { color, icon } = appNameToIcon(name, appID);

  if (type === 'SCHEDULED') {
    typeOverride = TRIGGER_TYPES.SCHEDULED;
  } else if (app === null) {
    typeOverride = TRIGGER_TYPES.EMAIL;
  }

  if (typeOverride) {
    name = typeOverride.name;
    description = typeOverride.description;
    icon = typeOverride.icon;
    if (typeOverride.iconColor) {
      color = typeOverride.iconColor;
    }
  } else if (trigger.app) {
    name = trigger.app.displayName;
    description = trigger.app.description;
  }
  return {
    color,
    description,
    icon,
    name
  };
};

export const TRIGGER_TYPES_V2 = {
  EMAIL: {
    name: 'Email',
    description: 'Starts when an email is received',
    icon: EmailGlyph
  },
  SCHEDULED: {
    name: 'Scheduled',
    description: 'Starts a process on a schedule',
    icon: ScheduledIcon,
    iconColor: CERULEAN
  }
};

export const triggerInfoV2 = (
  trigger: Trigger
): {
  appIcon: ?string,
  color: ?string,
  description?: string,
  icon?: React.Node,
  name?: string
} => {
  const { type, app } = trigger;
  const { icon: appIcon, id: appID = '' } = app || {};
  let name = appNameFromAppID(appID);
  let description = '';
  let typeOverride;
  let { color, icon } = appNameToIcon(name, appID);

  if (type === 'SCHEDULED') {
    typeOverride = TRIGGER_TYPES_V2.SCHEDULED;
  } else if (app === null) {
    typeOverride = TRIGGER_TYPES_V2.EMAIL;
  }

  if (typeOverride) {
    name = typeOverride.name;
    description = typeOverride.description;
    icon = typeOverride.icon;
    if (typeOverride.iconColor) {
      color = typeOverride.iconColor;
    }
  } else if (trigger.app) {
    name = trigger.app.displayName;
    description = trigger.app.description;
  }
  return {
    appIcon,
    color,
    description,
    icon,
    name
  };
};

export const isPublishedVersion = (process: ?Process): boolean => {
  return !!process?.isActive;
};

export const isWorkflowApprovalPending = (process: ?Process): boolean => {
  const approvalContext = process?.approvalContext || {};
  return (
    !isPublishedVersion(process) &&
    approvalContext.status !== undefined &&
    !isCompleted(approvalContext.status)
  );
};
