// @flow

import React from 'react';
import { useIntl } from 'react-intl';
import {
  InputV2,
  InputConsumer,
  MultiSelectInput
} from '@catalytic/catalytic-ui';
import { types } from '@catalytic/react-view';
import useFieldContext from './FieldContext';
import { useHandleBlur, useHandleChange } from './ViewHelpers';
import { formatSelectionFieldValue } from '../Field/FieldHelpers';

const MultipleSelectionView = ({
  optionValues: getOptionValues,
  schema: getSchema,
  setValue,
  value
}: types.MultipleSelectionView) => {
  const optionValues = getOptionValues();
  const schema = getSchema();
  const fieldContext = useFieldContext();
  const {
    className,
    contextValues,
    description,
    disabled,
    displayErrors,
    displayName,
    embedded,
    example,
    fieldName,
    hasValidation,
    id,
    name,
    onBlur,
    onChange,
    readOnly,
    required,
    type
  } = fieldContext || {};
  const handleBlur = useHandleBlur({ onBlur, setValue, type });
  const handleChange = useHandleChange({ onChange, setValue, type });
  const intl = useIntl();
  const defaultValue = formatSelectionFieldValue(value);
  const options = formatSelectionFieldValue(optionValues);

  return (
    <InputV2
      className={className}
      contextValues={contextValues}
      description={description}
      disabled={disabled}
      displayErrors={displayErrors}
      example={example}
      fieldName={fieldName}
      hasValidation={hasValidation}
      id={id}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      readOnly={readOnly}
      schema={schema}
      title={displayName}
      type={type}
      value={value}
    >
      <InputConsumer>
        {props => (
          <MultiSelectInput
            defaultValue={defaultValue}
            embedded={embedded}
            id={id}
            isMulti
            name={name}
            options={options}
            placeholder={intl.formatMessage({
              id: 'multipleSelectionView.placeholder',
              defaultMessage: 'Select...'
            })}
            required={required}
            {...props}
          />
        )}
      </InputConsumer>
    </InputV2>
  );
};
MultipleSelectionView.displayName = 'MultipleSelectionView';

export default React.memo<types.MultipleSelectionView>(MultipleSelectionView);
