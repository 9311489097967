// @flow

import * as React from 'react';

// https://github.com/mui-org/material-ui/blob/617674a1ff24c5da981a63ef6c5290f53ed1e919/packages/material-ui/src/utils/useControlled.js
export function useControlled({
  value: valueProp,
  defaultValue
}: {
  value?: mixed,
  defaultValue?: mixed,
  name: string
}) {
  const { current: isControlled } = React.useRef(
    typeof valueProp !== 'undefined'
  );
  const [valueState, setValue] = React.useState(defaultValue);
  const setValueIfUncontrolled = React.useCallback(
    (newValue: mixed) => {
      if (!isControlled) {
        setValue(newValue);
      }
    },
    [isControlled]
  );
  const value = isControlled ? valueProp : valueState;

  return [value, setValueIfUncontrolled];
}

export default useControlled;
