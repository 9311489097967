// @flow

import React from 'react';
import { useLocation } from 'react-router-dom';
import { NavButton } from '@catalytic/catalytic-ui';
import { ReactComponent as SearchGlyph } from '@catalytic/catalytic-icons/lib/glyphs/search.svg';
import SearchModal from '../Search/SearchModal';

export const NavbarSearch = () => {
  const location = useLocation();

  return (
    <SearchModal
      // The key will force a re-render on pathname change closing the modal.
      // https://github.com/catalyticlabs/triage/issues/7271
      key={location.pathname}
      trigger={({ show }) => (
        <NavButton onClick={show}>
          <SearchGlyph />
        </NavButton>
      )}
    />
  );
};

export default NavbarSearch;
