// @flow

import React from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';

import {
  injectSheet,
  type InjectSheetProvidedProps
} from '@catalytic/catalytic-ui';

const Style = () => ({
  root: {
    background: 'rgba(0, 165, 247, 0.25)'
  }
});

type Props = InjectSheetProvidedProps;

const SearchMark = ({ classes, className, theme, ...props }: Props) => (
  <mark {...props} className={classNames(classes.root, className)} />
);
SearchMark.displayName = 'SearchMark';

export default compose(injectSheet(Style))(SearchMark);
