// @flow

import React, {
  createRef,
  PureComponent,
  type ComponentType,
  type ElementRef
} from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { compose, setDisplayName, withProps } from 'recompose';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import keycode from 'keycode';
import { ReactComponent as AttachGlyph } from '@catalytic/catalytic-icons/lib/glyphs/attach.svg';
import { ReactComponent as ErrorIcon } from '@catalytic/catalytic-icons/lib/icons/error.svg';
import mixins from '../../style/mixins';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';
import InputRequired from '../InputRequired/InputRequired';

const FILES_DEFAULT_VALUE = [];
const DISPLAY_NAME = 'FileInput';
const NOOP = () => {};
let INDEX = 0;
const messages = {
  loading: {
    defaultMessage: 'Loading...',
    description: 'File input loading',
    id: 'file.loading'
  }
};
const definedMessages = defineMessages(messages);

const Style = (theme: ThemeType) => {
  return {
    root: {
      position: 'relative'
    },
    input: {
      ...theme.mixins.inputStyle,
      ...theme.mixins.inputMultiPadding,
      paddingLeft: '2.5rem',
      '&:required:valid': {
        '& ~ $required': {
          display: 'none'
        }
      }
    },
    inputFocus: theme.mixins.inputFocus,
    inputShadow: {
      display: 'none'
    },
    placeholder: {
      ...theme.mixins.inputPlaceholder,
      margin: '.438rem 0 .438rem .25rem',
      cursor: 'default', // not-allowed
      pointerEvents: 'none',
      userSelect: 'none'
    },
    success: theme.mixins.inputSuccess,
    error: {
      ...theme.mixins.inputError,
      paddingRight: '2.875rem'
    },
    errorMessage: theme.mixins.inputInlineErrorMessage,
    errorIcon: theme.mixins.inputInlineErrorIcon,
    required: {},
    disabled: {
      '&, &:focus, &:hover': theme.mixins.inputDisabled,
      '& $label': theme.mixins.inputMultiLabelDisabled,
      '& $remove': {
        '&, &:focus, &:hover': theme.mixins.inputMultiRemoveDisabled
      }
    },
    readOnly: {
      '& $label': {
        color: theme.colors.black,
        pointerEvents: 'auto',
        '&:active, &:hover': {
          color: theme.colors.pebbleGrey
        }
      },
      '& $remove': {
        display: 'none'
      }
    },
    container: theme.mixins.inputContainer,
    value: theme.mixins.inputMultiValue,
    label: {
      ...theme.mixins.inputMultiLabel,
      '&:active, &:hover': {
        color: theme.colors.pebbleGrey
      }
    },
    remove: theme.mixins.inputMultiRemove,
    icon: {
      ...theme.mixins.verticalAlign,
      left: '.563rem',
      color: theme.colors.battleshipGrey,
      fontSize: '1.875rem'
    },
    embedded: {
      borderRadius: theme.variables.borderRadiusSmall,
      paddingLeft: '2rem',
      paddingTop: '0',
      paddingBottom: '0',
      '& svg': {
        fontSize: '1.6875rem',
        left: '0.25rem'
      }
    },
    embeddedContainer: {
      minHeight: '1.875rem'
    },
    embeddedItem: {
      ...theme.typography.smallText,
      marginTop: 0,
      marginBottom: 0
    }
  };
};

type Files = Array<{
  id: string,
  name: string,
  value: any
}>;

export type FileInputType = InjectSheetProvidedProps &
  HTMLInputElement & {
    accept?: string,
    autoFocus: boolean,
    disabledClassName?: string,
    displayErrors: boolean,
    embedded?: boolean,
    filename: ?string,
    files?: Files,
    hasError: boolean,
    hasRequired: boolean,
    hasSuccess: boolean,
    hasValidation?: boolean,
    inputClassName?: string,
    inputRef?: ElementRef<*>,
    intl: Object,
    loading: boolean,
    maxFileSize?: number,
    onBlur?: ({
      currentTarget: { id: string, name: string, value: any }
    }) => mixed,
    onChange?: (
      {
        currentTarget: { id: string, name: string, value: any }
      },
      isAcceptable?: boolean
    ) => mixed,
    readOnlyClassName?: string,
    setDisplayErrorsState?: (displayErrors: boolean) => mixed,
    setLoadingState?: (loading: boolean) => mixed,
    url: ?string
  };

type State = {
  files: Files
};

class FileInput extends PureComponent<FileInputType, State> {
  static displayName = DISPLAY_NAME;

  static defaultProps = {
    autoComplete: 'off',
    autoFocus: false,
    disabled: false,
    displayErrors: false,
    embedded: false,
    filename: null,
    hasError: false,
    hasRequired: true,
    hasSuccess: false,
    loading: false,
    multiple: false,
    readOnly: false,
    required: false,
    type: 'file',
    url: null
  };

  state = {
    files: Array.isArray(this.props.files)
      ? this.props.files
      : FILES_DEFAULT_VALUE
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;

    const { inputRef: ref, intl, loading, setLoadingState } = this.props;

    if (
      ref &&
      typeof ref === 'object' &&
      ref.current &&
      typeof ref.current === 'object' &&
      ref.current.fileInputEl &&
      typeof ref.current.fileInputEl === 'object' &&
      typeof ref.current.fileInputEl.setCustomValidity === 'function'
    ) {
      ref.current.fileInputEl.setCustomValidity(
        loading ? intl.formatMessage(definedMessages.loading) : ''
      );
    }

    if (setLoadingState) {
      setLoadingState(loading);
    }
  }

  componentDidUpdate(prevProps) {
    const { files, inputRef: ref, intl, loading, setLoadingState } = this.props;

    if (
      ref &&
      typeof ref === 'object' &&
      ref.current &&
      typeof ref.current === 'object' &&
      ref.current.fileInputEl &&
      typeof ref.current.fileInputEl === 'object' &&
      typeof ref.current.fileInputEl.setCustomValidity === 'function'
    ) {
      ref.current.fileInputEl.setCustomValidity(
        loading ? intl.formatMessage(definedMessages.loading) : ''
      );
    }

    if (setLoadingState && loading !== prevProps.loading) {
      setLoadingState(loading);
    }

    if (Array.isArray(files) && files !== prevProps.files) {
      this.setState({ files });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    const { inputRef: ref, intl, loading, setLoadingState } = this.props;

    if (
      ref &&
      typeof ref === 'object' &&
      ref.current &&
      typeof ref.current === 'object' &&
      ref.current.fileInputEl &&
      typeof ref.current.fileInputEl === 'object' &&
      typeof ref.current.fileInputEl.setCustomValidity === 'function'
    ) {
      ref.current.fileInputEl.setCustomValidity(
        loading ? intl.formatMessage(definedMessages.loading) : ''
      );
    }

    if (setLoadingState) {
      setLoadingState(loading);
    }
  }

  handleChange = (data: Array<any>, dataRejections: Array<any>) => {
    const { id, multiple, name, onChange } = this.props;
    const { files: prevFiles } = this.state;
    const files = [
      ...(multiple ? prevFiles : FILES_DEFAULT_VALUE),
      ...data.map(file => {
        const { name: fileName } = file;

        return { id: `${name}-${INDEX++}`, name: fileName, value: file };
      })
    ];
    const isAcceptable = dataRejections.length === 0;

    if (this._isMounted) {
      this.setState({ files });
    }

    if (onChange) {
      return onChange(
        { currentTarget: { id, name, value: files } },
        isAcceptable
      );
    }
  };

  handleClick = (event: SyntheticEvent<HTMLLinkElement>) => {
    event.stopPropagation();
  };

  handleDelete = (event: SyntheticEvent<HTMLDivElement>, fileId: string) => {
    event.stopPropagation();

    const { disabled, id, inputRef: ref, name, onChange } = this.props;

    if (disabled) {
      return;
    }

    if (
      ref &&
      typeof ref === 'object' &&
      ref.current &&
      typeof ref.current === 'object' &&
      ref.current.fileInputEl &&
      typeof ref.current.fileInputEl === 'object'
    ) {
      // The recommended way to clear an HTML file input
      // https://stackoverflow.com/a/24608023
      // For modern browsers
      ref.current.fileInputEl.value = '';

      // For older browsers and testing library
      if (
        ref.current.fileInputEl.files &&
        ref.current.fileInputEl.files.length &&
        ref.current.fileInputEl.parentNode
      ) {
        ref.current.fileInputEl.parentNode.replaceChild(
          ref.current.fileInputEl.cloneNode(true),
          ref.current.fileInputEl
        );
      }
    }

    const { files: prevFiles } = this.state;
    const files = prevFiles.filter(({ id }) => id !== fileId);

    if (this._isMounted) {
      this.setState({ files });
    }

    if (onChange) {
      return onChange({ currentTarget: { id, name, value: files } });
    }
  };

  render() {
    const {
      accept,
      autoFocus,
      classes,
      className,
      defaultValue,
      disabled,
      disabledClassName,
      displayErrors,
      embedded,
      filename,
      files: filesProps,
      hasError,
      hasRequired,
      hasSuccess,
      hasValidation,
      inputClassName,
      inputRef: ref,
      intl,
      loading,
      maxFileSize,
      multiple,
      name,
      onBlur,
      onChange,
      placeholder,
      readOnly,
      readOnlyClassName,
      required,
      setDisplayErrorsState,
      setLoadingState,
      theme,
      title,
      type,
      url,
      ...props
    } = this.props;
    const { files } = this.state;
    const hasValue =
      ((defaultValue && typeof defaultValue === 'string') ||
        (files && files.length > 0)) &&
      !hasError;
    const isRequired = required && !hasValue;

    return (
      <div
        className={classNames(classes.root, className)}
        data-testid={name || 'file-input'}
        tabIndex="-1"
        title={title}
      >
        <Dropzone
          activeClassName={classes.inputFocus}
          className={classNames(
            classes.input,
            {
              [classes.success]: hasSuccess || (displayErrors && !isRequired),
              [classes.error]: hasError || (displayErrors && isRequired),
              [classes.embedded]: embedded
            },
            inputClassName
          )}
          data-id={`${DISPLAY_NAME}__control`}
          data-testid={name ? `${name}-dropzone` : 'file-input-dropzone'}
          disabled={disabled || readOnly}
          disabledClassName={classNames(classes.disabled, disabledClassName, {
            [classes.readOnly]: readOnly,
            ...(readOnlyClassName ? { [readOnlyClassName]: readOnly } : {})
          })}
          inputProps={{
            'data-name': name,
            disabled,
            readOnly,
            style: mixins.visuallyHidden,
            ...props
          }}
          maxSize={maxFileSize}
          onDrop={this.handleChange}
          preventDropOnDocument={false}
          {...{ accept, multiple, readOnly, ref }}
        >
          <AttachGlyph
            className={classes.icon}
            data-id={`${DISPLAY_NAME}__icon`}
          />
          <div
            className={classNames(classes.container, {
              [classes.embeddedContainer]: embedded
            })}
          >
            {loading && (
              <div
                className={classNames(classes.placeholder, {
                  [classes.embeddedItem]: embedded
                })}
                data-id={`${DISPLAY_NAME}__placeholder`}
              >
                <FormattedMessage {...messages.loading} />
              </div>
            )}
            {!hasValue && !loading && (
              <div
                className={classNames(classes.placeholder, {
                  [classes.embeddedItem]: embedded
                })}
                data-id={`${DISPLAY_NAME}__placeholder`}
              >
                {placeholder ? (
                  <span>{placeholder}</span>
                ) : (
                  <FormattedMessage
                    defaultMessage="No file chosen"
                    description="File input placeholder"
                    id="file.placeholder"
                  />
                )}
              </div>
            )}
            {hasValue &&
              !loading &&
              files.map(({ id, name, value }, index) => {
                const { preview } = value;

                return (
                  <div
                    key={`${id}-${index}`}
                    className={classNames(classes.value, {
                      [classes.embeddedItem]: embedded
                    })}
                  >
                    <a
                      className={classes.label}
                      data-id={`${DISPLAY_NAME}__multi-value__label`}
                      download={name}
                      href={preview}
                      onClick={this.handleClick}
                      title={name}
                    >
                      {name}
                    </a>
                    <div
                      className={classes.remove}
                      data-id={`${DISPLAY_NAME}__remove`}
                      onClick={event => this.handleDelete(event, id)}
                      onKeyDown={event => {
                        if (keycode(event) === 'enter') {
                          return this.handleDelete(event, id);
                        }
                      }}
                      role="button"
                      tabIndex={disabled ? -1 : 0}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        height="14"
                        viewBox="0 0 20 20"
                        width="14"
                      >
                        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
                      </svg>
                    </div>
                  </div>
                );
              })}
          </div>
        </Dropzone>
        {/*
         * The shadow input is responsible for providing the necessary data to the form.
         * For "File" fields, it provides a file ID, and for "Multiple File" fields, it provides a table ID.
         * This ensures that the correct data is associated with each type of file field. No file blobs!
         */}
        <input
          className={classes.inputShadow}
          data-name={name}
          onChange={NOOP}
          value={
            defaultValue && typeof defaultValue === 'string' ? defaultValue : ''
          }
          {...{ name, required }}
        />
        {!hasError && !hasValue && !loading && (
          <InputRequired
            className={classes.required}
            {...{ disabled, embedded, hasRequired, readOnly, required }}
          />
        )}
        {hasError && (
          <div
            className={classes.errorMessage}
            data-id={`${DISPLAY_NAME}__indicators`}
          >
            <ErrorIcon className={classes.errorIcon} />
          </div>
        )}
      </div>
    );
  }
}

// TODO: Type props to component
type EnhancedProps = Object;

export default (compose(
  setDisplayName(DISPLAY_NAME),
  injectIntl,
  injectSheet(Style),
  withProps(({ inputRef }) => ({ inputRef: inputRef || createRef() }))
)(FileInput): ComponentType<EnhancedProps>);
