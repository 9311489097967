// @flow

import Blob from './blob';
import File from './file';

let FormData = window.FormData;

type Entry = Array<any>;

const normalize = (name: any, value: any, filename: any): Entry => {
  const nextName = name + '';
  const nextFilename =
    filename !== undefined
      ? filename + ''
      : value && typeof value === 'object' && typeof value.name === 'string'
      ? value.name
      : 'blob';
  const nextValue =
    value instanceof Blob
      ? new File([value], nextFilename, {
          lastModified: value.lastModified,
          preview: value.preview,
          type: value.type
        })
      : value + '';
  return [nextName, nextValue, nextFilename];
};

export class FormDataPolyfill {
  static map = (new WeakMap(): WeakMap<FormDataPolyfill, Array<Entry>>);

  static stack(formdata: FormDataPolyfill): Array<Entry> {
    let list = FormDataPolyfill.map.get(formdata);
    if (!Array.isArray(list)) {
      list = [];
      FormDataPolyfill.map.set(formdata, list);
    }
    return list;
  }

  static blob(formdata: FormDataPolyfill) {
    const boundary = `----formdata-polyfill-${Math.random()}`;
    const chunks = [];

    for (const [name, value] of formdata) {
      chunks.push(`--${boundary}\r\n`);

      if (value instanceof Blob) {
        chunks.push(
          `Content-Disposition: form-data; name="${name}"; filename="${value.name}"\r\n`,
          `Content-Type: ${value.type || 'application/octet-stream'}\r\n\r\n`,
          value,
          '\r\n'
        );
      } else {
        chunks.push(
          `Content-Disposition: form-data; name="${name}"\r\n\r\n${value}\r\n`
        );
      }
    }

    chunks.push(`--${boundary}--`);

    return new Blob(chunks, {
      type: `multipart/form-data; boundary=${boundary}`
    });
  }

  constructor(form?: HTMLFormElement) {
    if (!form) return this;

    for (const element of Array.from(form.elements)) {
      // Skip elements without a name or disabled
      if (
        !element ||
        !(element instanceof window.HTMLElement) ||
        !element.name ||
        element.disabled
      ) {
        continue;
      }

      switch (element.type) {
        case 'file':
          for (const file of Array.from(element.files || [])) {
            this.append(element.name, file);
          }
          continue;
        case 'select-multiple':
        case 'select-one':
          for (const option of Array.from(element.options)) {
            if (!option.disabled && option.selected) {
              this.append(element.name, option.value);
            }
          }
          continue;
        case 'checkbox':
        case 'radio':
          if (element.checked) {
            this.append(element.name, element.value);
          }
          continue;
        default:
          this.append(element.name, element.value);
      }
    }
  }

  append(name: any, value: any, filename: any) {
    FormDataPolyfill.stack(this).push(normalize(name, value, filename));
  }

  delete(name: any) {
    const key = name + '';
    FormDataPolyfill.map.set(
      this,
      FormDataPolyfill.stack(this).filter(([name]) => name !== key)
    );
  }

  *entries(): Iterator<Entry> {
    for (const entry of FormDataPolyfill.stack(this)) {
      yield entry;
    }
  }

  forEach(callback: Function, context: Object) {
    for (const [name, value] of this) {
      callback.call(context, value, name, this);
    }
  }

  get(name: any) {
    const key = name + '';
    const [, value] = FormDataPolyfill.stack(this).find(
      ([name]) => name === key
    ) || [key, null]; // return null if nonexistent
    return value;
  }

  getAll(name: any): Array<any> {
    const key = name + '';
    return FormDataPolyfill.stack(this)
      .filter(([name]) => name === key)
      .map(([, value]) => value);
  }

  has(name: any): boolean {
    const key = name + '';
    return (
      FormDataPolyfill.stack(this).find(([name]) => name === key) !== undefined
    );
  }

  *keys(): Iterator<any> {
    for (const [name] of this) {
      yield name;
    }
  }

  set(name: any, value: any, filename?: string) {
    const key = name + '';
    const index = FormDataPolyfill.stack(this).findIndex(
      ([name]) => name === key
    );

    if (index < 0) {
      this.append(name, value, filename);
    } else {
      this.delete(name);
      FormDataPolyfill.stack(this).splice(
        index,
        0,
        normalize(name, value, filename)
      );
    }
  }

  *values(): Iterator<any> {
    for (const [, value] of this) {
      yield value;
    }
  }

  /*::
     @@iterator(): Iterator<any> {
     // $FlowIgnore
     return this[Symbol.iterator]()
     }
   */

  // $FlowIgnore
  [Symbol.iterator](): Iterator<Entry> {
    return this.entries();
  }

  toString() {
    return '[object FormData]';
  }
}

if (
  typeof FormData === 'undefined' ||
  !FormData.prototype.keys ||
  // There is a bug with the formData getAll function in Edge 18: https://github.com/jimmywarting/FormData/issues/80
  window.navigator.userAgent.indexOf('Edge') > -1
) {
  FormData = FormDataPolyfill;
}

window.FormData = FormData;

export default FormData;
