// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';

export const TabListStyle = (theme: ThemeType) => {
  return {
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      marginBottom: '1.25rem',
      boxShadow: `inset 0 -1px 0 0 ${theme.colors.lightGrey}`,
      overflow: 'auto'
    }
  };
};

type Props = InjectSheetProvidedProps & { children?: Node };

export const TabList = ({
  children,
  classes,
  className,
  theme,
  ...props
}: Props) => (
  <ul className={classNames(classes.root, className)} {...props}>
    {children}
  </ul>
);
TabList.displayName = 'TabList';

export default injectSheet(TabListStyle)(TabList);
