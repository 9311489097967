// @flow

import React, { type Ref } from 'react';
import injectSheet from '../../style/injectSheet';
import { type InjectSheetProvidedProps } from '../../style/ThemeTypes';

const Style = () => ({
  root: {}
});

type Props = InjectSheetProvidedProps &
  HTMLElement & {
    innerRef?: Ref<*>
  };

const ListView = ({ classes, className, theme, innerRef, ...props }: Props) => (
  <div className={className} {...props} ref={innerRef} />
);

ListView.displayName = 'ListView';

export default injectSheet(Style)(ListView);
