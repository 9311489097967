// @flow

import React from 'react';
import { InputV2, InputConsumer, TextareaInput } from '@catalytic/catalytic-ui';
import { types } from '@catalytic/react-view';
import useFieldContext from './FieldContext';
import { useHandleBlur, useHandleChange } from './ViewHelpers';

const TextAreaView = ({
  schema: getSchema,
  setValue,
  value
}: types.TextAreaView) => {
  const schema = getSchema();
  const fieldContext = useFieldContext();
  const {
    className,
    contextValues,
    description,
    disabled,
    displayErrors,
    displayName,
    embedded,
    example,
    fieldName,
    hasValidation,
    id,
    name,
    onBlur,
    onChange,
    readOnly,
    required,
    type
  } = fieldContext || {};
  const handleBlur = useHandleBlur({ onBlur, setValue, type });
  const handleChange = useHandleChange({ onChange, setValue, type });

  return (
    <InputV2
      className={className}
      contextValues={contextValues}
      description={description}
      disabled={disabled}
      displayErrors={displayErrors}
      example={example}
      fieldName={fieldName}
      hasValidation={hasValidation}
      id={id}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      readOnly={readOnly}
      schema={schema}
      title={displayName}
      type={type}
      value={value}
    >
      <InputConsumer>
        {props => (
          <TextareaInput
            defaultValue={value}
            embedded={embedded}
            id={id}
            name={name}
            required={required}
            {...props}
          />
        )}
      </InputConsumer>
    </InputV2>
  );
};
TextAreaView.displayName = 'TextAreaView';

export default React.memo<types.TextAreaView>(TextAreaView);
