// @flow

import type { ComponentType, ElementType, Node } from 'react';
import {
  componentFromProp,
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  withProps
} from 'recompose';
import classNames from 'classnames';
import injectSheet from '../style/injectSheet';
import {
  type ThemeType,
  type typography,
  TYPOGRAPHY
} from '../style/ThemeTypes';

export type TextType = {
  children?: Node,
  component?: ElementType
};

export const createFontStyle = (
  style: typography,
  component: string,
  DISPLAY_NAME: string
): ComponentType<TextType> =>
  compose(
    setDisplayName(DISPLAY_NAME),
    defaultProps({ component }),
    injectSheet((theme: ThemeType) => ({
      fontStyle: { ...theme.typography[style] }
    })),
    mapProps(({ classes, className, theme, ...props }) => ({
      className: classNames(classes.fontStyle, className),
      ...props
    })),
    withProps(props => ({ 'data-displayname': DISPLAY_NAME, ...props }))
  )(componentFromProp('component'));

export const Base = createFontStyle(TYPOGRAPHY.BASE, 'span', 'Base');
export const Header1 = createFontStyle(TYPOGRAPHY.HEADER1, 'h1', 'Header1');
export const Header2 = createFontStyle(TYPOGRAPHY.HEADER2, 'h2', 'Header2');
export const Header3 = createFontStyle(TYPOGRAPHY.HEADER3, 'h3', 'Header3');
export const Header4 = createFontStyle(TYPOGRAPHY.HEADER4, 'h4', 'Header4');
export const Header5 = createFontStyle(TYPOGRAPHY.HEADER5, 'h5', 'Header5');
export const Header6 = createFontStyle(TYPOGRAPHY.HEADER6, 'h6', 'Header6');
export const BaseText = createFontStyle(TYPOGRAPHY.BASETEXT, 'p', 'BaseText');
export const SmallText = createFontStyle(
  TYPOGRAPHY.SMALLTEXT,
  'span',
  'SmallText'
);
export const ExtraSmallText = createFontStyle(
  TYPOGRAPHY.EXTRASMALLTEXT,
  'span',
  'ExtraSmallText'
);
export const ExtraExtraSmallText = createFontStyle(
  TYPOGRAPHY.EXTRAEXTRASMALLTEXT,
  'span',
  'ExtraExtraSmallText'
);
export const Monospace = createFontStyle(
  TYPOGRAPHY.MONOSPACE,
  'pre',
  'Monospace'
);
