// @flow

import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { SmallText, type ThemeType } from '@catalytic/catalytic-ui';
import { ReactComponent as RemoveGlyph } from '@catalytic/catalytic-icons/lib/glyphs/remove.svg';
import {
  deconstructedStatementToExpression,
  expressionToDeconstructedStatement,
  expressionTypeUsesSecondTerm,
  inferStatementType
} from './ConditionHelpers';
import {
  STATEMENT_OPERATOR,
  type AddressedStatement,
  type ExpressionType,
  type StatementOperator
} from './ConditionTypes';
import StepConditionTerm from './StepConditionTerm';
import StepConditionOperator from './StepConditionOperator';
import StepConditionStatementOperator from './StepConditionStatementOperator';
import { useConditionConfigurationContext } from './useConditionConfigurationContext';
import { NODE_TYPE } from '../shared/NodeTypes';

const useStyles = makeStyles((theme: ThemeType) => ({
  inputRow: {
    display: 'flex',
    minHeight: '2rem',
    padding: '0.5rem',
    borderTop: `1px solid ${theme.colors.lightGrey}`,
    '&:first-of-type': {
      borderTop: 'inherit'
    },
    '&:last-of-type': {
      borderTop: 'inherit'
    }
  },
  if: {
    alignSelf: 'flex-start',
    lineHeight: '2.375rem',
    flexBasis: '1.25rem',
    flexGrow: 0,
    flexShrink: 0,
    textAlign: 'center'
  },
  fieldContainer: {
    flexGrow: 1
  },
  remove: {
    alignSelf: 'flex-start',
    background: 'none',
    flexBasis: '1.25rem',
    flexGrow: 0,
    flexShrink: 0,
    lineHeight: '2.25rem',
    textAlign: 'center'
  },
  fieldRow: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  field: {
    margin: '0.25rem',
    backgroundColor: 'inherit',
    padding: '0 0.25rem'
  },
  termField: {
    flexBasis: '12rem',
    flexGrow: 1,
    flexShrink: 1
  },
  operatorField: {
    flexBasis: '12rem',
    flexGrow: 0,
    flexShrink: 1
  },
  statementOperatorField: {
    flexBasis: '6rem',
    flexGrow: 0,
    flexShrink: 1
  }
}));

type Props = {
  disabled: boolean,
  index: number,
  numStatements: number,
  onChange: (
    newExpression: string,
    newStatementOperator: StatementOperator,
    or: number,
    and: number
  ) => void,
  removeStatementAtIndex: (or: number, and: number) => void,
  statement: AddressedStatement
};

export function StepConditionStatement(props: Props) {
  const {
    disabled,
    onChange,
    index,
    numStatements,
    removeStatementAtIndex,
    statement
  } = props;

  const classes = useStyles();

  const [expression, setExpression] = React.useState<string>(statement.value);

  const {
    availableFields,
    fieldsByContext,
    node,
    steps
  } = useConditionConfigurationContext();

  const { and, isLastAnd, or } = statement;
  const {
    primary,
    secondary,
    tertiary,
    type
  } = expressionToDeconstructedStatement(expression).toJS();

  const isLastItem = index === numStatements - 1;
  const isProcessNode = node?.__typename === NODE_TYPE.CONTEXT_TYPE;

  const [
    statementOperator,
    setStatementOperator
  ] = React.useState<StatementOperator>(
    isLastAnd && !isLastItem ? STATEMENT_OPERATOR.OR : STATEMENT_OPERATOR.AND
  );

  const updateStatement = ({
    primary: newPrimary = primary,
    secondary: newSecondary = secondary,
    tertiary: newTertiary = tertiary,
    type: newType = type,
    statementOperator: newStatementOperator = statementOperator
  }: {
    primary?: string,
    secondary?: string,
    tertiary?: string,
    type?: ExpressionType,
    statementOperator?: StatementOperator
  }) => {
    const newExpression = deconstructedStatementToExpression({
      primary: newPrimary,
      secondary: newSecondary,
      tertiary: newTertiary,
      type: newType
    });
    setExpression(newExpression);
    setStatementOperator(newStatementOperator);
    if (
      newExpression !== expression ||
      statementOperator !== newStatementOperator
    ) {
      onChange(newExpression, newStatementOperator, or, and);
    }
  };
  return (
    <div className={classes.inputRow} data-testid="condition-statement">
      <SmallText className={classes.if}>
        {statement.isFirst && (
          <FormattedMessage id="conditions.if" defaultMessage="If" />
        )}
      </SmallText>
      <div className={classes.fieldContainer}>
        <div className={classes.fieldRow}>
          <StepConditionTerm
            availableFields={availableFields}
            className={classNames(classes.field, classes.termField)}
            contextTerm={tertiary}
            fieldsByContext={fieldsByContext}
            disabled={disabled}
            isProcessNode={isProcessNode}
            onChange={primary => {
              updateStatement({
                primary
              });
            }}
            steps={steps}
            value={primary}
          />
        </div>
        <div className={classes.fieldRow}>
          <StepConditionOperator
            className={classNames(classes.field, classes.operatorField)}
            disabled={disabled}
            onChange={operator => {
              const type = inferStatementType({
                primary,
                secondary: operator
              });
              const statementPieces = {
                type,
                primary,
                secondary: operator,
                tertiary: tertiary !== undefined ? tertiary : 'false'
              };
              updateStatement(statementPieces);
            }}
            value={secondary}
          />
        </div>
        <div className={classes.fieldRow}>
          {expressionTypeUsesSecondTerm(type) && (
            <StepConditionTerm
              availableFields={availableFields}
              className={classNames(classes.field, classes.termField)}
              contextTerm={primary}
              fieldsByContext={fieldsByContext}
              isProcessNode={isProcessNode}
              disabled={disabled}
              onChange={tertiary => {
                updateStatement({
                  tertiary
                });
              }}
              steps={steps}
              value={tertiary}
            />
          )}
        </div>
        <div className={classes.fieldRow}>
          {numStatements > 1 && (
            <StepConditionStatementOperator
              className={classNames(
                classes.field,
                classes.statementOperatorField
              )}
              disabled={disabled}
              onChange={statementOperator => {
                updateStatement({
                  statementOperator
                });
              }}
              value={statementOperator}
              visible={!isLastItem}
            />
          )}
        </div>
      </div>
      <button
        className={classes.remove}
        onClick={disabled ? undefined : () => removeStatementAtIndex(or, and)}
      >
        <RemoveGlyph />
      </button>
    </div>
  );
}
StepConditionStatement.displayName = 'StepConditionStatement';

export default StepConditionStatement;
