// @flow

import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { type ThemeType } from '../../style/ThemeTypes';

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    padding: '1rem 0',
    [theme.breakpoints.gte.tablet.portrait]: {
      display: 'flex',
      justifyContent: 'flex-start'
    }
  }
}));

export const InfoBar = ({ className, ...props }: { className?: string }) => {
  const classes = useStyles();
  return <section className={classNames(classes.root, className)} {...props} />;
};

export default InfoBar;
