// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';
import injectSheet from '../../style/injectSheet';
import {
  type ThemeType,
  type InjectSheetProvidedProps
} from '../../style/ThemeTypes';
import { Link } from 'react-router-dom';
import chevronGlyphUrl from '@catalytic/catalytic-icons/lib/glyphs/chevron.svg';

const Style = (theme: ThemeType) => {
  return {
    root: {
      ...theme.typography.header5,
      color: theme.colors.porpoise,
      float: 'right',
      textTransform: 'none',
      paddingRight: '1rem',
      background: `url(${chevronGlyphUrl}) 100% 100% no-repeat`,
      backgroundSize: '1rem'
    }
  };
};

type Props = InjectSheetProvidedProps & {
  children: Node,
  to: string
};

const PaneLink = ({ classes, className, to, theme, ...props }: Props): Node => (
  <Link to={to} className={classNames(classes.root, className)} {...props} />
);

PaneLink.displayName = 'PaneLink';
export default injectSheet(Style)(PaneLink);
