// @flow

import * as React from 'react';
import { compose, pure, withHandlers } from 'recompose';
import {
  JSONType,
  type JSONSchema
} from '@catalytic/json-schema-validator-catalytic-web';
import ViewContext, { View, type ViewMap } from '@catalytic/react-view';
import {
  NodeType,
  type Node as ViewNode,
  type View as ViewType
} from '@catalytic/view';
import CheckboxView from './CheckboxView';
import CodeView from './CodeView';
import ConfidentialView from './ConfidentialView';
import DateTimeView from './DateTimeView';
import DateView from './DateView';
import EmailView from './EmailView';
import FileReferenceView from './FileReferenceView';
import FileTableReferenceView from './FileTableReferenceView';
import GroupView from './GroupView';
import IntegerView from './IntegerView';
import MarkdownView from './MarkdownView';
import MultipleSelectionView from './MultipleSelectionView';
import NumberView from './NumberView';
import PasswordView from './PasswordView';
import ReferenceView from './ReferenceView';
import RichTextView from './RichTextView';
import SelectionView from './SelectionView';
import TableView from './TableView';
import TextAreaView from './TextAreaView';
import TextView from './TextView';
import ToggleSwitchView from './ToggleSwitchView';
import { FieldProvider } from './FieldContext';
import { type Update } from '../Expression/ExpressionContext';
import { type Process } from '../Process/ProcessTypes';
import { type Run } from '../Run/RunTypes';
import { type Step } from '../Step/StepTypes';
import { type Node } from '../shared/NodeTypes';
import { withFieldExpressionValidation } from '../Feature';

// The ViewContext type def is not allowing truly optional object properties
// packages/react-view/dist/context.js.flow
// Possibly flow library bug: https://github.com/joarwilk/flowgen/issues/99
const types: ViewMap = ({
  [NodeType.CHECKBOX_VIEW]: CheckboxView,
  [NodeType.CODE_VIEW]: CodeView,
  [NodeType.DATE_TIME_VIEW]: DateTimeView,
  [NodeType.DATE_VIEW]: DateView,
  [NodeType.EMAIL_VIEW]: EmailView,
  [NodeType.FILE_REFERENCE_VIEW]: FileReferenceView,
  [NodeType.FILE_TABLE_REFERENCE_VIEW]: FileTableReferenceView,
  [NodeType.GROUP_VIEW]: GroupView,
  [NodeType.INTEGER_VIEW]: IntegerView,
  [NodeType.MULTIPLE_SELECTION_VIEW]: MultipleSelectionView,
  [NodeType.NUMBER_VIEW]: NumberView,
  [NodeType.PASSWORD_VIEW]: PasswordView,
  [NodeType.REFERENCE_VIEW]: ReferenceView,
  [NodeType.RICH_TEXT_VIEW]: RichTextView,
  [NodeType.SELECTION_VIEW]: SelectionView,
  [NodeType.TABLE_VIEW]: TableView,
  [NodeType.TEXT_AREA_VIEW]: TextAreaView,
  [NodeType.TEXT_VIEW]: TextView,
  [NodeType.TOGGLE_SWITCH_VIEW]: ToggleSwitchView
}: any);

// TODO: Add more specific editor components.
const editor = { ...types };

// TODO: Add more specific reader components.
const reader = { ...types };

type Props = {
  className?: string,
  context?: null | Node | Run | Process | Step,
  contextValues?: { [string]: any },
  defaultViewConfigurationNode?: ViewNode<>,
  description?: null | string | React.Element<typeof FormattedMessage>,
  disabled?: boolean,
  displayErrors?: boolean,
  displayName: string | React.Element<typeof FormattedMessage>,
  displayOnly?: boolean,
  embedded?: boolean,
  example?: null | string | React.Element<typeof FormattedMessage>,
  fieldExpressionValidation?: boolean,
  fieldName?: string,
  id: string,
  name: string,
  onBlur?: (...args: Array<any>) => any,
  onChange?: (...args: Array<any>) => any,
  readOnly?: boolean,
  required?: boolean,
  schema: JSONSchema<>,
  type?: $Keys<typeof JSONType>,
  update?: Update,
  value?: any,
  viewConfiguration?: ViewType<>,
  viewerRead?: boolean
};

export const Field = (props: Props) => {
  const {
    className,
    context,
    contextValues,
    defaultViewConfigurationNode,
    description,
    disabled,
    displayErrors,
    displayName,
    displayOnly,
    embedded,
    example,
    fieldExpressionValidation,
    fieldName,
    id,
    name,
    onBlur,
    onChange,
    readOnly,
    required,
    schema,
    type,
    update,
    value,
    viewConfiguration,
    viewerRead
  } = props;
  // Update conditional fields on mount.
  // Subsequent conditional fields will update on change and onblur.
  React.useEffect(() => {
    if (typeof update === 'function') {
      update(name, value);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Set dirty value flag to true whenever the user interacts with the control in a way that changes the value.
  // https://www.w3.org/TR/2011/WD-html5-20110525/the-input-element.html#concept-input-value-dirty-flag
  // We still need validation to occur during form submission, regardless of
  // whether the input was touched since the value may have been set prior to
  // rendering the form, i.e. by an automated action or previously saved field
  // interaction. https://github.com/catalyticlabs/triage/issues/7196
  const [hasValidation, setHasValidation] = React.useState(value != null);
  const handleBlur = React.useCallback(
    (...args) => {
      if (typeof onBlur === 'function') {
        onBlur(...args);
      }
    },
    [onBlur]
  );
  const handleChange = React.useCallback(
    (...args) => {
      if (!hasValidation) {
        setHasValidation(true);
      }

      if (typeof onChange === 'function') {
        onChange(...args);
      }
    },
    [hasValidation, onChange]
  );

  // Should not render a view if field is `displayOnly`, this will be
  // improved in a future TODO to be handled by the View component.
  if (displayOnly) {
    return <MarkdownView name={name} value={value} />;
  }

  // Get default view configuration node.
  const node =
    defaultViewConfigurationNode !== undefined
      ? defaultViewConfigurationNode
      : (viewConfiguration?.views || []).find(
          view => view?.id === viewConfiguration?.default
        );

  // Should not render a view if default view configuration node is undefined.
  if (node === undefined) {
    return null;
  }

  const { label } = node || {};

  return (
    <FieldProvider
      value={{
        className,
        context,
        contextValues: fieldExpressionValidation ? contextValues : undefined,
        description,
        disabled,
        displayErrors,
        displayName,
        embedded,
        example,
        // `name` is the default, but can be overridden by `fieldName`.
        // `fieldName` is used by FieldConfigurationViewV2 component.
        fieldName: fieldName || name,
        hasValidation,
        id,
        label,
        name,
        onBlur: handleBlur,
        onChange: handleChange,
        readOnly,
        required,
        type
      }}
    >
      {/*
       * Should not render a view if field is not `viewerRead`, this will be
       * improved in a future TODO to be handled by the View component.
       */}
      {!viewerRead ? (
        <ConfidentialView />
      ) : (
        <ViewContext.Provider value={{ editor, reader }}>
          {/* The node is about presentation, schema is about validation. */}
          <View defaultValue={value} node={node} schema={schema} />
        </ViewContext.Provider>
      )}
    </FieldProvider>
  );
};
Field.displayName = 'Field';
Field.defaultProps = {
  viewerRead: true
};

const EnhancedField: React.ComponentType<Props> = compose(
  withFieldExpressionValidation,
  // Prevent re-render caused by passing functions through props.
  // TODO: Parent components should memoize callbacks.
  // https://reactjs.org/docs/hooks-reference.html#usecallback
  withHandlers({
    onBlur: ({ onBlur }) => (...args) => {
      if (typeof onBlur === 'function') {
        return onBlur(...args);
      }
    },
    onChange: ({ onChange }) => (...args) => {
      if (typeof onChange === 'function') {
        return onChange(...args);
      }
    }
  }),
  pure
)(Field);

export default EnhancedField;
