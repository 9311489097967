// @flow

import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { ReactComponent as BoxArrow } from '@catalytic/catalytic-icons/lib/glyphs/open-prev-editor.svg';
import { compose, setDisplayName } from 'recompose';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { getDisplayName } from '@material-ui/utils';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';
import { ExtraSmallText } from '../../Text/Text';

export const CLOSE_TIMEOUT_MS = 200;
const DISPLAY_NAME = 'ModalView';
const IS_OPEN = false;

const Style = (theme: ThemeType) => {
  return {
    '@keyframes slideInUp': {
      from: {
        transform: 'translate3d(0, 12rem, 0)'
      },
      to: {
        transform: 'translate3d(0, 0, 0)'
      }
    },
    '@keyframes slideOutDown': {
      from: {
        transform: 'translate3d(0, 0, 0)'
      },
      to: {
        transform: 'translate3d(0, 12rem, 0)'
      }
    },
    bodyOpen: theme.mixins.bodyOpen,
    modal: {
      position: 'absolute',
      top: theme.functions.toRem(theme.variables.modalTop),
      right: 0,
      bottom: 0,
      left: 0,
      paddingBottom: theme.functions.toRem(theme.variables.modalMobilePadding),
      borderTopLeftRadius: theme.variables.borderRadius,
      borderTopRightRadius: theme.variables.borderRadius,
      backgroundColor: theme.colors.white,
      boxShadow: `0 .125rem .25rem ${theme.colors.battleshipGreyAlpha}`,
      animationDuration: ({ closeTimeoutMS = CLOSE_TIMEOUT_MS }) =>
        `${closeTimeoutMS}ms`,
      animationTimingFunction: 'ease-in-out',
      animationFillMode: 'both',
      transform: 'translate3d(0, 0, 0)',
      [theme.breakpoints.desktop]: {
        margin: 'auto',
        paddingBottom: theme.functions.toRem(
          theme.variables.modalDesktopPadding
        ),
        maxWidth: '100%',
        width: ({ width = 'medium' }) =>
          theme.functions.toRem(
            width === 'large'
              ? theme.variables.modalWidthLarge
              : theme.variables.modalWidthMedium
          )
      }
    },
    modalOpen: {
      animationName: '$slideInUp'
    },
    modalClose: {
      animationName: '$slideOutDown'
    },
    modalButton: {
      display: 'block',
      margin: '0 auto',
      padding: 0,
      width: theme.functions.toRem(theme.variables.button),
      height: theme.functions.toRem(theme.variables.button),
      backgroundColor: 'transparent',
      '&:before': {
        content: '""',
        display: 'block',
        margin: '0 auto',
        width: theme.functions.toRem(theme.variables.modalTop),
        height: theme.variables.borderRadiusSmall,
        borderRadius: theme.variables.borderRadiusSmall,
        backgroundColor: theme.colors.pastelGrey
      }
    },
    modalContent: {
      overflow: 'auto',
      height: '100%',
      padding: `0 ${theme.functions.toRem(
        theme.variables.modalMobilePadding
      )} ${theme.functions.toRem(theme.variables.button)}`,
      [theme.breakpoints.desktop]: {
        padding: `0 ${theme.functions.toRem(
          theme.variables.modalDesktopPadding
        )} ${theme.functions.toRem(theme.variables.button)}`
      }
    },
    overlay: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: theme.colors.battleshipGreyAlpha,
      animationDuration: ({ closeTimeoutMS = CLOSE_TIMEOUT_MS }) =>
        `${closeTimeoutMS}ms`,
      animationTimingFunction: 'ease-in-out',
      animationFillMode: 'both',
      opacity: 1
    },
    overlayOpen: {
      animationName: theme.variables.fadeIn
    },
    overlayClose: {
      animationName: theme.variables.fadeOut
    },
    portal: {
      position: 'fixed',
      zIndex: theme.zIndex.modal
    },
    portalOpen: {
      '& ~ $portalOpen': {
        '& $modal': {
          marginTop: '0.5rem',
          [theme.breakpoints.desktop]: {
            width: theme.functions.toRem(
              theme.variables.modalWidt + theme.variables.base
            )
          }
        }
      }
    },
    portalClose: {}
  };
};

export type Hide = (event: SyntheticEvent<*> | void) => void;
export type Show = (event: SyntheticEvent<*> | void) => void;
export type ModalViewTriggerProps = {|
  hide: Hide,
  isClosing: boolean,
  isOpening: boolean,
  isOpen: boolean,
  show: Show
|};

export type ModalViewTrigger =
  | React.Node
  | ((props: ModalViewTriggerProps) => React.Node);

export type ModalViewProps = {
  appElement?: HTMLElement | null,
  bodyOpenClassName?: string,
  children?: React.Node | ((props: ModalViewTriggerProps) => React.Node),
  closeTimeoutMS?: number,
  contentClassName?: string,
  data?: { [string]: string },
  isOpen?: boolean,
  modalButtonRef?: React.ElementRef<*>,
  onAfterOpen?: (event: SyntheticEvent<*> | void) => void,
  openElement?: React.Element<typeof Link> | React.Element<'a'>,
  onRequestClose?: (event: SyntheticEvent<*> | void) => boolean | void,
  overlayClassName?: string,
  overlayRef?: React.ElementRef<*>,
  portalClassName?: string,
  shouldCloseOnOverlayClick?: boolean,
  shouldCloseOnOverlayClickPrompt?: string,
  trigger?: ModalViewTrigger,
  width?: 'medium' | 'large'
};

type BaseProps = InjectSheetProvidedProps & ModalViewProps & { intl: Object };

type DefaultProps = {
  appElement: HTMLBodyElement,
  closeTimeoutMS: number,
  isOpen: boolean
};

type State = {
  isClosing: boolean,
  isOpen: boolean,
  isOpening: boolean
};

export const ModalViewContext: React.Context<boolean> = React.createContext(
  false
);

export const withModalViewContext = (
  WrappedComponent: React.ComponentType<*>
) => {
  // Convention: Wrap the Display Name for Easy Debugging
  // https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
  class WithModalViewContext extends React.Component<*> {
    render() {
      return (
        <ModalViewContext.Consumer>
          {context => (
            <WrappedComponent {...this.props} modalViewContext={context} />
          )}
        </ModalViewContext.Consumer>
      );
    }
  }
  WithModalViewContext.displayName = `WithModalViewContext(${getDisplayName(
    WrappedComponent
  )})`;

  return WithModalViewContext;
};

export class ModalView extends React.PureComponent<BaseProps, State> {
  static displayName = DISPLAY_NAME;
  static defaultProps: DefaultProps = {
    appElement: window.document.body,
    closeTimeoutMS: CLOSE_TIMEOUT_MS,
    isOpen: IS_OPEN
  };

  state = {
    isClosing: false,
    isOpen: this.props.isOpen || IS_OPEN,
    isOpening: false
  };

  _isMounted = false;
  _timeout = null;
  modalButtonRef: ?HTMLButtonElement;
  overlayRef: ?HTMLDivElement;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this._timeout) {
      clearTimeout(this._timeout);
    }
  }

  handleAfterOpen = (event: SyntheticEvent<*> | void) => {
    const { closeTimeoutMS, onAfterOpen } = this.props;

    if (this._isMounted) {
      this.setState({ isOpen: true, isOpening: true });
    }

    this._timeout = setTimeout(() => {
      this._timeout = null;

      if (this._isMounted) {
        this.setState({ isOpening: false });
      }

      if (onAfterOpen) {
        onAfterOpen(event);
      }
    }, closeTimeoutMS);
  };

  handleRequestClose = (event: SyntheticEvent<*> | void) => {
    const {
      closeTimeoutMS,
      onRequestClose,
      shouldCloseOnOverlayClickPrompt
    } = this.props;

    if (
      event &&
      typeof event === 'object' &&
      (event.target === this.overlayRef ||
        event.target === this.modalButtonRef) &&
      shouldCloseOnOverlayClickPrompt &&
      window.confirm(shouldCloseOnOverlayClickPrompt) === false
    ) {
      return;
    }

    if (onRequestClose) {
      const shouldCloseOnOverlayClickPrompt = onRequestClose(event);

      if (shouldCloseOnOverlayClickPrompt === false) {
        return;
      }
    }

    if (this._isMounted) {
      this.setState({ isClosing: true });
    }

    this._timeout = setTimeout(() => {
      this._timeout = null;

      if (this._isMounted) {
        this.setState({ isClosing: false, isOpen: false });
      }
    }, closeTimeoutMS);
  };

  render() {
    const {
      appElement,
      bodyOpenClassName,
      children,
      classes,
      className,
      closeTimeoutMS,
      contentClassName,
      data,
      intl,
      isOpen: isOpenProp,
      onAfterOpen,
      openElement,
      onRequestClose,
      overlayClassName,
      overlayRef,
      portalClassName,
      shouldCloseOnOverlayClick,
      shouldCloseOnOverlayClickPrompt,
      theme,
      trigger,
      ...other
    } = this.props;
    const { isClosing, isOpen: isOpenState, isOpening } = this.state;
    const isOpen = isOpenState || isOpenProp || IS_OPEN;
    const isAnimating = isClosing || isOpening;
    const triggerProps: ModalViewTriggerProps = {
      hide: this.handleRequestClose,
      isClosing,
      isOpen,
      isOpening,
      show: this.handleAfterOpen
    };

    return (
      <>
        {typeof trigger === 'function' ? trigger(triggerProps) : trigger}
        <Modal
          aria={{ modal: true }}
          bodyOpenClassName={classNames(classes.bodyOpen, bodyOpenClassName)}
          className={classNames(
            classes.modal,
            {
              [classes.modalOpen]: isOpen,
              [classes.modalClose]: isClosing || !isOpen
            },
            className
          )}
          onRequestClose={this.handleRequestClose}
          overlayClassName={classNames(
            classes.overlay,
            {
              [classes.overlayOpen]: isOpen,
              [classes.overlayClose]: isClosing || !isOpen
            },
            overlayClassName
          )}
          overlayRef={node => (this.overlayRef = node)}
          shouldCloseOnOverlayClick={!isAnimating}
          portalClassName={classNames(
            classes.portal,
            {
              [classes.portalOpen]: isOpen,
              [classes.portalClose]: isClosing || !isOpen
            },
            portalClassName
          )}
          tabIndex={-1}
          testId="modal"
          {...{
            appElement,
            closeTimeoutMS,
            data,
            isOpen,
            ...other
          }}
        >
          {isOpen && !isAnimating && (
            <>
              <button
                aria-label={intl.formatMessage({
                  id: 'modal.hide',
                  defaultMessage: 'Close'
                })}
                className={classes.modalButton}
                data-testid="close"
                onClick={this.handleRequestClose}
                ref={node => (this.modalButtonRef = node)}
              />
              <div
                className={classNames(classes.modalContent, contentClassName)}
              >
                {openElement &&
                  React.cloneElement(openElement, {
                    children: <OpenAsPage />
                  })}
                <ModalViewContext.Provider value={true}>
                  {typeof children === 'function'
                    ? children(triggerProps)
                    : children}
                </ModalViewContext.Provider>
              </div>
            </>
          )}
        </Modal>
      </>
    );
  }
}

const useOpenAsPageStyles = makeStyles((theme: ThemeType) => ({
  root: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'inline-flex',
    position: 'absolute',
    top: '0.875rem',
    color: theme.colors.battleshipGrey
  },
  icon: {
    marginRight: '0.375rem'
  }
}));

const OpenAsPage = () => {
  const classes = useOpenAsPageStyles();
  return (
    <ExtraSmallText className={classes.root}>
      <BoxArrow className={classes.icon} />
      <FormattedMessage defaultMessage="Open as page" id="open.button" />
    </ExtraSmallText>
  );
};

export default (compose(
  setDisplayName(DISPLAY_NAME),
  injectIntl,
  injectSheet(Style)
)(ModalView): React.ComponentType<ModalViewProps>);
