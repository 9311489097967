// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose, setDisplayName } from 'recompose';
import scrollIntoView from 'scroll-into-view-if-needed';
import classNames from 'classnames';
import ButtonInput from '../../Input/ButtonInput/ButtonInput';
import { BaseText } from '../../Text/Text';
import injectSheet from '../../style/injectSheet';
import type {
  InjectSheetProvidedProps,
  ThemeType
} from '../../style/ThemeTypes';
import { SCIENCE_BLUE } from '../../style/colors';

const Style = (theme: ThemeType) => ({
  errorMessage: theme.mixins.inputErrorMessage,
  submit: theme.mixins.inputMargin,
  button: theme.mixins.formButton
});

type ContextValue = {
  disabled: boolean,
  displayErrors: boolean,
  loading: boolean,
  readOnly: boolean,
  setDisplayErrorsState: (...args: Array<any>) => any,
  setLoadingState: (...args: Array<any>) => any
};

const defaultContextValue = {
  disabled: false,
  displayErrors: false,
  loading: false,
  readOnly: false,
  setDisplayErrorsState() {},
  setLoadingState() {}
};

export const WebformViewContext: React.Context<ContextValue> = React.createContext(
  defaultContextValue
);

type Props = InjectSheetProvidedProps & {
  autoComplete: string,
  buttonClassName?: string,
  buttonColorScheme: string,
  children: React.Node,
  disabled: boolean,
  displayErrors: boolean,
  error?: Error,
  hasSubmit: boolean,
  id: string,
  implicitSubmission?: boolean,
  loading: boolean,
  noValidate: boolean,
  onSubmit?: (
    event: SyntheticEvent<HTMLFormElement>,
    formData: FormData
  ) => mixed,
  readOnly: boolean,
  rootRef?: React.Ref<*>,
  submit?: string | React.Element<typeof FormattedMessage>
};

type State = {
  displayErrors: boolean,
  loading: boolean
};

class WebformView extends React.PureComponent<Props, State> {
  static displayName = 'WebformView';

  static defaultProps = {
    autoComplete: 'on',
    disabled: false,
    displayErrors: false,
    hasSubmit: true,
    loading: false,
    noValidate: true,
    readOnly: false
  };

  state = {
    displayErrors: this.props.displayErrors,
    loading: this.props.loading
  };

  componentDidUpdate({
    displayErrors: prevDisplayErrors,
    loading: prevLoading
  }: Props) {
    const { displayErrors, loading } = this.props;

    if (prevDisplayErrors !== displayErrors) {
      this.setState({ displayErrors });
    }

    if (prevLoading !== loading) {
      this.setState({ loading });
    }
  }

  handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { currentTarget } = event;
    const form = currentTarget || {};
    const invalidInput = form.querySelector('[data-error], :invalid');
    const { disabled, onSubmit } = this.props;
    const { loading } = this.state;

    if (disabled || loading) {
      // Don't call `onSubmit` if form is disabled or loading
      return;
    }

    if (invalidInput) {
      const name = invalidInput.getAttribute('data-name');

      // Scroll invalid input into view
      scrollIntoView(
        (name ? form.querySelector(`[data-id="${name}"]`) : invalidInput) ||
          form,
        {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
          scrollMode: 'if-needed'
        }
      );

      this.setState({ displayErrors: true });

      // Don't call `onSubmit` if input is invalid
      return;
    }

    this.setState({ displayErrors: false });

    if (typeof onSubmit === 'function') {
      return onSubmit(event, new FormData(form));
    }
  };

  setDisplayErrorsState = (displayErrors: boolean) => {
    return this.setState({ displayErrors });
  };

  setLoadingState = (loading: boolean) => {
    return this.setState({ loading });
  };

  render() {
    const {
      autoComplete,
      buttonClassName,
      buttonColorScheme,
      children,
      classes,
      className,
      disabled,
      displayErrors: displayErrorsProp,
      error: errorProp,
      hasSubmit,
      id,
      implicitSubmission,
      loading: loadingProp,
      noValidate,
      onSubmit,
      readOnly,
      rootRef,
      submit,
      theme,
      ...other
    } = this.props;
    const { displayErrors, loading } = this.state;
    const error =
      errorProp && errorProp.message ? errorProp.message : errorProp;

    return (
      <form
        autoComplete={autoComplete}
        className={className}
        data-testid="webformview"
        id={id}
        noValidate={noValidate}
        onSubmit={this.handleSubmit}
        ref={rootRef}
        {...other}
      >
        {/**
         * Prevents implicit form submission via Enter key entry on inputs in some browsers.
         * */}
        {!implicitSubmission && (
          <button
            type="submit"
            data-testid="button-false-submit"
            disabled
            style={{ display: 'none' }}
            aria-hidden="true"
          />
        )}
        {error && (
          <div className={classes.errorMessage}>
            <BaseText>{String(error)}</BaseText>
          </div>
        )}
        <WebformViewContext.Provider
          value={{
            disabled,
            displayErrors,
            loading,
            readOnly,
            setDisplayErrorsState: this.setDisplayErrorsState,
            setLoadingState: this.setLoadingState
          }}
        >
          {children}
        </WebformViewContext.Provider>
        {hasSubmit && !disabled && (
          <div className={classes.submit}>
            <ButtonInput
              className={classNames(classes.button, buttonClassName)}
              colorScheme={buttonColorScheme || SCIENCE_BLUE}
              disabled={disabled}
              loading={loading}
              readOnly={readOnly}
              data-testid="button-webform-submit"
              type="submit"
            >
              {submit || (
                <FormattedMessage
                  defaultMessage="Submit"
                  description="Webform submit button"
                  id="webform.submit"
                />
              )}
            </ButtonInput>
          </div>
        )}
      </form>
    );
  }
}

// TODO: type props to withForwardRef component
type EnhancedProps = Object;

const withForwardRef = Component =>
  (compose(
    // TODO: Fix Flow error
    // Possibly related: https://medium.com/flow-type/asking-for-required-annotations-64d4f9c1edf8
    setDisplayName(`withForwardRef(${Component.displayName || 'WebformView'}`),
    React.forwardRef
  )((props, ref) => (
    <Component rootRef={ref} {...props} />
  )): React.ComponentType<EnhancedProps>);

export default compose(withForwardRef, injectSheet(Style))(WebformView);

export const WebformViewConsumer = WebformViewContext.Consumer;
