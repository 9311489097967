'use strict';

// This will emulate a full ES2015+ environment (no < Stage 4 proposals).
require('core-js/stable');
require('regenerator-runtime/runtime');

// Cross-browser element.classList.
require('classlist-polyfill');

// CustomEvent polyfill for the CustomEvent() constructor functionality.
require('custom-event-polyfill');

// Closest polyfill for traversing the Element and its parents.
require('element-closest-polyfill');

// URLSearchParams polyfill for the URLSearchParams API.
require('url-search-params-polyfill');

// FormData polyfill for the FormData API.
require('@catalytic/formdata-polyfill').default; // eslint-disable-line no-unused-expressions

// A fetch() polyfill for making API calls.
require('whatwg-fetch');

// Plural rules polyfill.
require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/dist/locale-data/en');

// Relative time polyfill.
require('@formatjs/intl-relativetimeformat/polyfill');
require('@formatjs/intl-relativetimeformat/dist/locale-data/en');

// A polyfill for the Resize Observer API.
require('resize-observer-polyfill');

if (typeof Headers === 'undefined') {
  global.Headers = require('whatwg-fetch').Headers;
}
