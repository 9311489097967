// @flow

import React, { type Node } from 'react';
import { Query } from 'react-apollo';
import { compose, mapProps, withStateHandlers } from 'recompose';
import gql from 'graphql-tag';
import pbx from '@catalytic/pushbot-expression';

import { toExpressionScope } from './ExpressionTransform';
import {
  type ExpressionScope,
  type ExpressionScopeFields
} from './ExpressionTypes';
import { withIsEmptyConditions } from '../Feature';
import logError from '../utils/logError';

const DEFAULT_CONDITION = true;

export const Context = gql`
  query Context($context: String!) {
    context(id: $context) {
      id
      owner {
        id
      }
      tasks {
        nodes {
          id
          name
          status
        }
      }
      fields {
        nodes {
          id
          name
          value
        }
      }
    }
  }
`;

export type Evaluate = (condition: string) => boolean;
export type Update = (name: string, value: any) => void;

export type Props = {
  children: ({
    evaluate: Evaluate,
    loading: boolean,
    update: Update
  }) => Node,
  context: string,
  evaluate: Evaluate,
  fetchPolicy?: string,
  fields: ExpressionScopeFields,
  loading: boolean,
  query?: Object,
  transform?: (data: Object) => ExpressionScope,
  update: Update,
  useCorrectIsEmpty: boolean
};

export const isValidCondition = (condition?: string): boolean => {
  return typeof condition === 'string' && condition !== '';
};

export const createEvaluate = (
  scope: ExpressionScope,
  useCorrectIsEmpty: boolean
): Evaluate => (condition?: string) => {
  if (isValidCondition(condition)) {
    try {
      return pbx.eval(condition, scope, { useCorrectIsEmpty });
    } catch (error) {
      console.error(error);

      return DEFAULT_CONDITION;
    }
  } else {
    return DEFAULT_CONDITION;
  }
};

export const mergeScopeWithState = (
  scope: ExpressionScope,
  fields: ExpressionScopeFields
): ExpressionScope => ({
  ...scope,
  fields: {
    ...scope.fields,
    ...fields
  }
});

const ExpressionContext = ({
  children,
  context,
  fetchPolicy,
  fields,
  query,
  transform,
  update,
  useCorrectIsEmpty
}: Props) => {
  return (
    <Query
      errorPolicy="all"
      fetchPolicy={fetchPolicy || 'network-only'}
      query={query || Context}
      variables={{ context }}
    >
      {({ data, error, loading }) => {
        logError(error);

        return children({
          contextLoading: loading && !data?.context,
          evaluate: createEvaluate(
            mergeScopeWithState(
              (transform || toExpressionScope)(data || {}),
              fields
            ),
            useCorrectIsEmpty
          ),
          loading,
          update
        });
      }}
    </Query>
  );
};

ExpressionContext.displayName = 'ExpressionContext';

export default compose(
  withIsEmptyConditions,
  mapProps(({ isEmptyConditions, ...props }) => ({
    useCorrectIsEmpty: isEmptyConditions,
    ...props
  })),
  withStateHandlers(() => ({ fields: {} }), {
    update: ({ fields }) => (name: string, value: any) => ({
      fields: {
        ...fields,
        [name]: value
      }
    })
  })
)(ExpressionContext);
