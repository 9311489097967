// @flow

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import equals from 'ramda/src/equals';
import objOf from 'ramda/src/objOf';
import omit from 'ramda/src/omit';
import path from 'ramda/src/path';
import camelCase from 'voca/camel_case';
import { compose, mapProps, pure, withProps, type HOC } from 'recompose';

export const ModuleQuery = gql`
  query Module($id: ID!) {
    moduleFeature(id: $id) {
      id
      value
    }
  }
`;

type QueryDataProp = { [dataID: string]: { variation?: { value: boolean } } };

type CreateFeatureProp = (
  featureID: string,
  dataID: string,
  defaultValue?: boolean
) => (
  props: QueryDataProp
) => {|
  [propName: string]: boolean | void
|};

export const toVariationProp: CreateFeatureProp = (
  featureID,
  dataID,
  defaultValue
) =>
  compose(
    objOf(camelCase(featureID)),
    value =>
      // `value` is `undefined` while query is loading.
      value === undefined
        ? defaultValue === undefined
          ? undefined
          : equals(true, defaultValue)
        : equals(true, value),
    path([dataID, 'moduleFeature', 'value'])
  );
export const withModule = (featureID: string, defaultValue?: boolean) => {
  // `dataID` is needed so we don't omit the resulting `{ [propName: string]: boolean }`
  // prop if its camel-cased prop name happens to be identical to its module id,
  // like in the case of variation `flowchart`.
  const dataID = `data-${featureID}`;

  const moduleHOC: HOC<*, Object> = compose(
    graphql(ModuleQuery, {
      name: dataID,
      options: {
        fetchPolicy: 'cache-and-network',
        variables: { id: featureID }
      }
    }),
    // Create camel cased key/value pair as module prop.
    withProps(toVariationProp(featureID, dataID, defaultValue)),
    // Omit nested data object from props.
    mapProps(omit([dataID])),
    // Important: Don't update component unless value changed otherwise
    // unneeded re-rendering may occur whereever feature flags are used.
    pure
  );

  return moduleHOC;
};

export default withModule;
