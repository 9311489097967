// @flow

import propEq from 'ramda/src/propEq';
import reject from 'ramda/src/reject';

import {
  connection,
  compose,
  fork,
  readQuery,
  withArgs,
  writeData
} from '../GraphQL/GraphQLResolvers';
import { ClientSearch, ClientSearches } from './SearchQueries';

const rejectDuplicateID = (id: string, nodes: Array<Object>) =>
  reject(propEq('id', id), nodes);

// Lookup a single client search entry
export const clientSearch = readQuery(ClientSearch);

// Client search entry connection
export const clientSearches = connection(
  readQuery(ClientSearches, ({ clientSearches }) => clientSearches),
  // Default sort by createdBy in descending order
  { sort: [{ key: 'createdBy', direction: 'desc' }] }
);

export const addClientSearch = compose(
  // Create a new client search
  (root, { input: { query: id, type = null } }) => ({
    createdDate: new Date().toISOString(),
    id,
    type
  }),
  // Write the client search
  writeData('ClientSearch'),
  // Update the client searches data
  fork(
    compose(
      withArgs(
        // Add new search
        (root, clientSearch, context, clientSearches) => ({
          nodes: [
            clientSearch,
            ...rejectDuplicateID(clientSearch.id, clientSearches.nodes || [])
          ]
        }),
        // Read all the client searches
        clientSearches
      ),
      // Write the client searches data
      writeData('ClientSearchConnection', data => ({ clientSearches: data }))
    )
  )
);

export default {
  ClientSearchSortDirection: {
    ASC: 'asc',
    DESC: 'desc'
  },
  Mutation: {
    addClientSearch
  },
  Query: {
    clientSearch,
    clientSearches
  }
};
