// @flow

import classNames from 'classnames';
import React, { type Node } from 'react';
import injectSheet from '../../style/injectSheet';
import {
  type InjectSheetProvidedProps,
  type ThemeType
} from '../../style/ThemeTypes';
import { SmallText } from '../../Text/Text';

const Style = (theme: ThemeType) => {
  return {
    root: {
      ...theme.mixins.breakWord,
      marginBottom: '.1rem',
      color: theme.colors.pebbleGrey,
      display: 'block',
      width: '100%'
    }
  };
};

type Props = InjectSheetProvidedProps & HTMLElement;

const ListItemFooter = ({
  classes,
  className,
  theme,
  ...props
}: Props): Node => (
  <SmallText className={classNames(classes.root, className)} {...props} />
);

ListItemFooter.displayName = 'ListItemFooter';

export default injectSheet(Style)(ListItemFooter);
